import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { headers, UpdateStock1 } from "../../Api/ApiCalls";
import { Constent } from "../../Assets/Constants/Constent";
import { AcknowledgeApiCallValidation } from "../../Components/Common/Validation";
import Footer from "../../Components/Footer/Footer";
import { Header } from "../../Components/Header/Header";
import InventoryAcknowledgeStockBodyContent from "../../Components/MainBody/BodyContent/Supervisor/InventoryAcknowledgeStockBodyContent";
import InventoryStockDetailsBodyHeader from "../../Components/MainBody/BodyHeader/Supervisor/InventoryStockDetailsBodyHeader";
import SupervisorSidebar from "../../Components/MainBody/Sidebar/SupervisorSidebar";
import AcknowledgeStockConfirmationModal from "../../Components/Modals/AcknowledgeStockConfirmationModal";
import { ToastContainer, toast } from "react-toastify";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { setTrackStockFieldValue } from "../../ReduxFeature/StateFeatures/InventoryDataState";
import { useNavigate } from "react-router-dom";
import { ApiActionLoader } from "../../Components/Loaders/ApiActionLoader";

const TrakStockAcknowledgePage = ({ globalState, setGlobalState }) => {
  const userDataObj = useSelector((state) => state.userDataInfo);
  const invDataObj = useSelector((state) => state.inventoryDataInfo);

  const {
    selectedTrackStockItemDetails,
    acknowledgeTotalAvailableItems,
    acknowledgeSelectedSerialNumbersList,
  } = invDataObj;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageState, setPageState] = useState({
    dataLoader: false,
    activeTab: Constent.INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE1,

    apiCallAction: false,
    apiCallActionMsg: "Progressing...",
  });

  function ErrorMessageSetUp({ error, message1, message2 }) {
    let errorMessage;
    if (error.response.status === 500 || error.response.status === 503) {
      errorMessage = message1;
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    } else if (error.response.status === 404) {
      errorMessage = message2;
      toast.warning(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    } else {
      errorMessage = error.response.statusText;
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    }
    return errorMessage;
  }

  function acknowledgeStockApiCall() {
    setPageState({ ...pageState, apiCallAction: true });
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);
    /*----- validation need to implement------*/
    if (
      AcknowledgeApiCallValidation({
        acknowledgeTotalAvailableItems: acknowledgeTotalAvailableItems,
        acknowledgeSelectedSerialNumbersList:
          acknowledgeSelectedSerialNumbersList,
      })
    ) {
      const aclItemList = [];
      Object.keys(acknowledgeSelectedSerialNumbersList)?.map((mcode) => {
        if (acknowledgeSelectedSerialNumbersList[mcode]?.length > 0) {
          const itemDetailsIndex =
            selectedTrackStockItemDetails?.itemDetails?.findIndex(
              (obj) => obj.sapMaterialCode === mcode
            );
          const selecetedItemDetails =
            selectedTrackStockItemDetails?.itemDetails[itemDetailsIndex];
          aclItemList.push({
            approvedQuantity: selecetedItemDetails?.approvedQuantity,
            matchedUin: acknowledgeSelectedSerialNumbersList[mcode],
            receivedQuantity:
              acknowledgeSelectedSerialNumbersList[mcode]?.length,
            requestedQuantity: selecetedItemDetails?.requestedQuantity,
            sapMaterialCode: mcode,
            transferQuantity: selecetedItemDetails?.transferQuantity,
          });
        } else {
          aclItemList.push({
            approvedQuantity: null,
            matchedUin: null,
            receivedQuantity: null,
            requestedQuantity: null,
            sapMaterialCode: null,
            transferQuantity: null,
          });
        }
        return null;
      });

      const acknowledgeDetails = {
        acknowledgeItems: aclItemList,
        comments: "Stock unmatched",
        unMatchedUin: null,
      };

      const ackRequest = {
        acknowledgeDetails: acknowledgeDetails,
        action: "ACKNOWLEDGE",
        receiverStore: ApiConstant.INVENTORY_REQUESTING_STORE_ID,
        senderStore: userDataObj?.userData?.activeUserData?.userData?.outletId,
        approvalUser: selectedTrackStockItemDetails?.approvalUser || "-",
        shipUser: selectedTrackStockItemDetails?.shipUser || "-",
        receiveUser:
          userDataObj?.userData?.activeUserData?.userData?.userId || "-",
        sourceSystem: "CFMP",
        storeId:
          selectedTrackStockItemDetails.transferFromStoreId ===
          ApiConstant.INVENTORY_REQUESTING_STORE_ID
            ? userDataObj?.userData?.activeUserData?.userData?.outletId
            : selectedTrackStockItemDetails?.transferToStoreId,
        transactionNo: "-",
        transferId: selectedTrackStockItemDetails?.transferId,
        transferFromStoreAddress:
          selectedTrackStockItemDetails?.transferFromStoreId,
        transferToStoreAddress:
          selectedTrackStockItemDetails?.transferToStoreId,
      };

      UpdateStock1("/fibre/inventory/updateStockTransfer", ackRequest, header)
        .then((response) => {
          setPageState({ ...pageState, apiCallAction: false });
          dispatch(
            setTrackStockFieldValue({
              type: ApiConstant.SUPERVISOR_TRACK_STOCK_CONFIRMATION_MSG,
              value: Constent.ACKNOWLEDGE_CONFIRM_MSG,
            })
          ) && navigate("/inventory/trackstock/acknowledge/confirm");
        })
        .catch((error) => {
          setPageState({ ...pageState, apiCallAction: false });
          ErrorMessageSetUp({
            error: error,
            message1: ApiConstant.ACKNOWLEDGE_ERROR_MSG,
            message2: ApiConstant.ACKNOWLEDGE_ERROR_MSG,
          });
        });
    } else {
      setPageState({ ...pageState, apiCallAction: false });
      toast.warning(ApiConstant.ACKNOWLEDGE_VALIDATION_WARNING_MSG, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    }
  }

  return (
    <div className="page-start-section">
      {pageState?.apiCallAction && (
        <ApiActionLoader msg={pageState?.apiCallActionMsg} />
      )}
      <Header
        userDataObj={userDataObj}
        globalState={globalState}
        setGlobalState={setGlobalState}
        tab4={true}
      />
      <div
        className={
          globalState?.sidebarState
            ? "body-start-section sidebar-active-section"
            : "body-start-section"
        }
      >
        {/* Sidebar */}
        <SupervisorSidebar
          userDataObj={userDataObj}
          globalState={globalState}
          setGlobalState={setGlobalState}
        />
        <div className="body-full-content-section">
          <InventoryStockDetailsBodyHeader
            bannarTitle={Constent.BODY_HEADER_INVENTORY_ACKNOWLEDGE_BANNAR_TEXT}
            activeItem={Constent.BODY_HEADER_ACKNOWLEDGE_PATH}
          />
          <InventoryAcknowledgeStockBodyContent
            pageState={pageState}
            acknowledgeTotalAvailableItems={acknowledgeTotalAvailableItems}
            selectedTrackStockItemDetails={selectedTrackStockItemDetails}
            acknowledgeSelectedSerialNumbersList={
              acknowledgeSelectedSerialNumbersList
            }
            setPageState={setPageState}
            globalState={globalState}
            acknowledgeStockApiCall={acknowledgeStockApiCall}
          />
        </div>
        <Footer />
        <AcknowledgeStockConfirmationModal
          acknowledgeStockApiCall={acknowledgeStockApiCall}
          selectedTrackStockItemDetails={selectedTrackStockItemDetails}
          acknowledgeTotalAvailableItems={acknowledgeTotalAvailableItems}
          acknowledgeSelectedSerialNumbersList={
            acknowledgeSelectedSerialNumbersList
          }
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default TrakStockAcknowledgePage;
