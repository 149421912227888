import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Constent } from "../../Assets/Constants/Constent";
import { HeaderNavChild4 } from "../../Components/Common/HeaderNav";
import Footer from "../../Components/Footer/Footer";
import { Header } from "../../Components/Header/Header";
import InstallerUpdateWorkOrderBodyContent from "../../Components/MainBody/BodyContent/Installer/InstallerUpdateWorkOrderBodyContent";

import "../../Assets/CSS/InstallerUpdateWorkOrderPage.css";
import "../../Assets/CSS/NextPageConfirmationModal.css";
import UploadDocumentModal from "../../Components/Modals/UploadDocumentModal";
import NextPageConfirmationModal from "../../Components/Modals/NextPageConfirmationModal";
import { useNavigate } from "react-router-dom";
import UpdateWorkOrderEnabledLocationModal from "../../Components/Modals/UpdateWorkOrderEnabledLocationModal";
import { saveLovs } from "../../ReduxFeature/StateFeatures/LovDataState";
import {
  DirectLovCalls,
  DocumentManagement,
  headers,
  UpdateWorkOrderDetails,
} from "../../Api/ApiCalls";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { updateWorkOrderDetailsData } from "../../ReduxFeature/StateFeatures/WorkOrderDataState";
import { ApiActionLoader } from "../../Components/Loaders/ApiActionLoader";
const InstallerUpdateWorkOrderPage = ({ globalState, setGlobalState }) => {
  const userDataObj = useSelector((state) => state.userDataInfo);
  const workOrderDataObj = useSelector((state) => state.workOrderInfo);
  const lovDataObj = useSelector((state) => state.lovDataInfo);
  const dispatch = useDispatch();

  const {
    locationBtnEnabled,
    workOrderStatus,
    locationCoordinates,
    returnReasonValue,
    returnRemarksValue,
    returnRemarksFinalValue,
    deviceSLValidation,
    activeTab,
    btuId,
    btuPortNumber,
    infrastructureLovValue,
    desiredinstallationLovValue,
    workOrderDetails,
    additionalCableLength,
    ostElementValue,
    ostResolutionValue,
    ostProblemCauseValue,
    btuDeviceCollected,
    isModelChangeForAnySwapDevices,
    devicesWithExistingSerialNo,
  } = workOrderDataObj;
  const { ownOrderDetails, installationDetail, selectedWorkOrderItem } =
    workOrderDataObj?.workOrderDetails !== null &&
    workOrderDataObj?.workOrderDetails;
  const navigate = useNavigate();

  const [pageState, setPageState] = useState({
    dataLoader: false,
    activityStep: 1,
    locationEnabled: false,
    workOrderStatus: null,
    workOrderStatusChanged: false,
    orderReturnd: false,
    returnReasonsValue: "select",
    returnReasonsStatusChanged: false,
    returnReasonsLovs: {
      "Due to API's": [
        {
          display: "System issue",
          value: "System issue",
        },
        {
          display: "Others",
          value: "Others",
        },
      ],
      "Due to Celcom": [
        {
          display: "Others",
          value: "Others",
        },
      ],
      "Due to Customer": [
        {
          display: "Others",
          value: "Others",
        },
      ],
    },
    returnRemarksValue: "select",
    returnRemarksStatusChanged: false,
    returnRemarksLovs: [],

    uploadedFileList: [],
    documentTypeLovValue: "",

    saveAndContinueBtnEnabled: false,
    saveAndContinueBtnValidation: false,

    submitBtnEnabled: false,

    apiCallAction: false,
    apiCallActionMsg: "Processing...",
  });

  /*------- Defualt Use Effect -------*/
  useEffect(() => {
    dispatch(
      updateWorkOrderDetailsData({
        type: ApiConstant.INSTALLER_CLOSE_WORK_ORDER_TYPE,
        value: "",
      })
    );
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);

    if (
      (workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OST_TYPE &&
        lovDataObj?.elements === null) ||
      lovDataObj?.elements === undefined ||
      lovDataObj?.elements?.length === 0
    ) {
      DirectLovCalls("/fibre/getlovs/ostresolution", header)
        .then((response) => {
          dispatch(
            saveLovs({
              key: ApiConstant.LOV_STORE_UPDATE_WORK_ORDER_OST_ELEMENT_LOV_KEY,
              value: response?.element,
            })
          );
        })
        .catch((err) => {
          // TODO document why this arrow function is empty
        });

      DirectLovCalls("/fibre/getlovs/deviceresolution", header)
        .then((innerResponse) => {
          dispatch(
            saveLovs({
              key: ApiConstant.LOV_STORE_UPDATE_WORK_ORDER_OST_DEVICE_ELEMENT_LOV_KEY,
              value: innerResponse?.element,
            })
          );
        })
        .catch((err) => {
          // TODO document why this arrow function is empty
        });
    }
    if (
      lovDataObj?.infrastructure === null ||
      lovDataObj?.infrastructure === undefined ||
      lovDataObj?.infrastructure?.length === 0 ||
      lovDataObj?.desiredinstallation?.length === 0
    ) {
      DirectLovCalls("/fibre/getlovs/infrastructure", header).then(
        (response) => {
          dispatch(
            saveLovs({
              key: ApiConstant.LOV_STORE_UPDATE_WORK_ORDER_DISIRED_INSTALLATION_KEY,
              value: response?.DesiredInstallation,
            })
          );
          dispatch(
            saveLovs({
              key: ApiConstant.LOV_STORE_UPDATE_WORK_ORDER_INFRASTRUCTURE_KEY,
              value: response?.Infrastructure,
            })
          );
        }
      );
    }
    if (
      lovDataObj?.reaturnreason === null ||
      lovDataObj?.reaturnreason === undefined ||
      lovDataObj?.reaturnreason?.length === 0 ||
      lovDataObj?.reaturnremarks?.length === 0
    ) {
      DirectLovCalls("/fibre/getlovs/getreturnremarks", header).then(
        (response) => {
          const reasons = Object.keys(response);
          dispatch(
            saveLovs({
              key: ApiConstant.LOV_STORE_UPDATE_WORK_ORDER_RETURN_REASON_KEY,
              value: reasons,
            })
          );
          dispatch(
            saveLovs({
              key: ApiConstant.LOV_STORE_UPDATE_WORK_ORDER_RETURN_REMARKS_KEY,
              value: response,
            })
          );
        }
      );
    }

    /* ----- Calling Document List to get the previously uploaded document ------ */
    const docListRequest = {
      workOrder_Id: workOrderDetails?.itemID,
    };
    DocumentManagement("/fibre/file/list", docListRequest, header)
      .then((response) => {
        const docList = [];
        response?.map((docItem, index) => {
          docList.push({
            id: index,
            name: docItem?.attachmentName,
            docReferenceId: docItem?.docReferenceId,
            uploadStatus: ApiConstant.UPLOAD_DOCUMENT_ITEM_COMPLETE_STATUS,
          });
        });
        setPageState({ ...pageState, uploadedFileList: docList });
      })
      .catch(() => {
        setPageState({ ...pageState, uploadedFileList: [] });
      });
  }, []);
  /* ------- Use Effect For Save and Continue Validation---------- */
  useEffect(() => {
    // console.log("{}{}", (workOrderStatus === ApiConstant.WOKR_ORDER_INST_COMPLETE) || (workOrderStatus === ApiConstant.WOKR_ORDER_RETURNED
    //     && returnReasonValue?.length > 0 && returnRemarksValue?.length > 0
    //     && returnRemarksFinalValue?.length > 0))
    if (locationBtnEnabled && locationCoordinates?.length > 0) {
      if (
        workOrderStatus === ApiConstant.WOKR_ORDER_INST_COMPLETE ||
        (workOrderStatus === ApiConstant.WOKR_ORDER_RETURNED &&
          returnReasonValue?.length > 0 &&
          returnRemarksValue?.length > 0 &&
          returnRemarksFinalValue?.length > 0)
      ) {
        dispatch(
          updateWorkOrderDetailsData({
            type: ApiConstant.UPDATE_WORK_ORDER_SAVE_AND_CONTINUE_BTN_VALUE,
            value: true,
          })
        );
      } else {
        dispatch(
          updateWorkOrderDetailsData({
            type: ApiConstant.UPDATE_WORK_ORDER_SAVE_AND_CONTINUE_BTN_VALUE,
            value: false,
          })
        );
      }
    } else {
      dispatch(
        updateWorkOrderDetailsData({
          type: ApiConstant.UPDATE_WORK_ORDER_SAVE_AND_CONTINUE_BTN_VALUE,
          value: false,
        })
      );
    }
  }, [
    locationBtnEnabled,
    workOrderStatus,
    returnReasonValue,
    returnRemarksValue,
    returnRemarksFinalValue,
  ]);

  /* ------- Use Effect For Submit Validation OSI/ OST---------- */
  useEffect(() => {
    if (workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OST_TYPE) {
      OSTValidationForConfirmationBtn();
    } else if (
      workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OSI_TYPE
    ) {
      OSIValidationForConfirmationBtn();
    } else if (
      workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_BTU_TYPE
    ) {
      BTUValidationForConfirmationBtn();
    }
  }, [
    workOrderStatus,
    activeTab,
    deviceSLValidation,
    btuId,
    btuPortNumber,
    infrastructureLovValue,
    desiredinstallationLovValue,
    ostElementValue,
    ostResolutionValue,
    ostProblemCauseValue,
    btuDeviceCollected,
  ]);

  /* ----------- OSI Submit Validation -------------- */
  function OSIValidationForConfirmationBtn() {
    /* -- 
            work order status === work completed
            All Input filed should be valid 
            All Product + 2 btu (id and port) + 2 infracture and disered ins
        -- */
    if (
      activeTab === 2 &&
      workOrderStatus === ApiConstant.WOKR_ORDER_WO_COMPLETE
    ) {
      let validationProductCount = 0;
      Object.keys(deviceSLValidation)?.map((productId) => {
        if (
          deviceSLValidation[productId]?.valid === ApiConstant.VALID_INP &&
          !(
            ownOrderDetails?.deviceDetails?.btuDeviceList?.filter(
              (btuDevice) => {
                if (btuDevice?.productID === productId) {
                  return btuDevice;
                }
              }
            )?.length > 0
          )
        ) {
          validationProductCount += 1;
        }
      });

      if (
        validationProductCount ===
          ownOrderDetails?.deviceDetails?.totalDeviceCount -
            ownOrderDetails?.deviceDetails?.btuDeviceList?.length &&
        // && btuId?.length > 0 && btuPortNumber?.length > 0
        infrastructureLovValue?.length > 0 &&
        desiredinstallationLovValue?.length > 0
      ) {
        dispatch(
          updateWorkOrderDetailsData({
            type: ApiConstant.UPDATE_WORK_ORDER_SUBMIT_BTN_VALUE,
            value: true,
          })
        );
      } else {
        dispatch(
          updateWorkOrderDetailsData({
            type: ApiConstant.UPDATE_WORK_ORDER_SUBMIT_BTN_VALUE,
            value: false,
          })
        );
      }
    } else {
      dispatch(
        updateWorkOrderDetailsData({
          type: ApiConstant.UPDATE_WORK_ORDER_SUBMIT_BTN_VALUE,
          value: false,
        })
      );
    }
  }

  /* ----------- OST Submit Validation -------------- */
  function OSTValidationForConfirmationBtn() {
    /* -- 
            work order status === work completed
            if any device is selected
                - serial number will be vaild
                - Other field should not be null or ""
        -- */
    if (
      activeTab === 2 &&
      workOrderStatus === ApiConstant.WOKR_ORDER_WO_COMPLETE
    ) {
      let validationProductCount = 0;
      Object.keys(deviceSLValidation)?.map((productId) => {
        if (
          deviceSLValidation[productId]?.valid === ApiConstant.VALID_INP &&
          deviceSLValidation[productId]?.elementValue?.length > 0 &&
          deviceSLValidation[productId]?.resolutionValue?.length > 0 &&
          deviceSLValidation[productId]?.problemCauseValue?.length > 0
        ) {
          validationProductCount += 1;
        }
      });

      let ostParentLovValueValidation = false;

      if (
        validationProductCount === 0 &&
        Object.keys(deviceSLValidation)?.length === 0 &&
        ostElementValue?.length > 0 &&
        ostResolutionValue?.length > 0 &&
        ostProblemCauseValue?.length > 0
      ) {
        ostParentLovValueValidation = true;
      } else {
        if (
          validationProductCount !== 0 &&
          validationProductCount === Object.keys(deviceSLValidation)?.length
        ) {
          ostParentLovValueValidation = true;
        }
      }

      if (ostParentLovValueValidation) {
        dispatch(
          updateWorkOrderDetailsData({
            type: ApiConstant.UPDATE_WORK_ORDER_SUBMIT_BTN_VALUE,
            value: true,
          })
        );
      } else {
        dispatch(
          updateWorkOrderDetailsData({
            type: ApiConstant.UPDATE_WORK_ORDER_SUBMIT_BTN_VALUE,
            value: false,
          })
        );
      }
    } else {
      dispatch(
        updateWorkOrderDetailsData({
          type: ApiConstant.UPDATE_WORK_ORDER_SUBMIT_BTN_VALUE,
          value: false,
        })
      );
    }
  }
  /* ----------- BTU Submit Validation -------------- */
  function BTUValidationForConfirmationBtn() {
    /* -- 
            work order status === work completed
            and serial number should be valid and device colleted should be yes 
        -- */

    if (
      activeTab === 2 &&
      workOrderStatus === ApiConstant.WOKR_ORDER_WO_COMPLETE
    ) {
      // btuId should be match with given id which is missing
      if (
        btuDeviceCollected?.length > 0 ||
        // btuId?.length >= Constent.BTU_SERIAL_NUMBER_MIN &&
        // (btuDeviceCollected === "Yes" ||
        workOrderDetails?.itemSubTypeId
          ?.toLowerCase()
          ?.includes("Relocation"?.toLowerCase())
      ) {
        // )
        dispatch(
          updateWorkOrderDetailsData({
            type: ApiConstant.UPDATE_WORK_ORDER_SUBMIT_BTN_VALUE,
            value: true,
          })
        );
      } else {
        dispatch(
          updateWorkOrderDetailsData({
            type: ApiConstant.UPDATE_WORK_ORDER_SUBMIT_BTN_VALUE,
            value: false,
          })
        );
      }
    } else {
      dispatch(
        updateWorkOrderDetailsData({
          type: ApiConstant.UPDATE_WORK_ORDER_SUBMIT_BTN_VALUE,
          value: false,
        })
      );
    }
  }

  /* ----------- Submit BTN api Call -------------- */
  function submitBtnConfirmation() {
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);
    const activityType =
      workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OSI_TYPE
        ? ApiConstant.WOKR_ORDER_OSI_SORT_TYPE
        : workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OST_TYPE
        ? ApiConstant.WOKR_ORDER_OST_SORT_TYPE
        : workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_BTU_TYPE
        ? ApiConstant.WOKR_ORDER_BTU_SORT_TYPE
        : null;

    if (workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OSI_TYPE) {
      dispatch(
        updateWorkOrderDetailsData({
          type: "isUpload",
          value:
          true,
        })
      );
      OSISubmitBtnConfirmation({
        activityType: activityType,
        header: header,
      });
    } else if (
      workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OST_TYPE
    ) {
      dispatch(
        updateWorkOrderDetailsData({
          type: "isUpload",
          value:
          true,
        })
      );
      OSTSubmitBtnConfirmation({
        activityType: activityType,
        header: header,
      });
    } else if (
      workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_BTU_TYPE
    ) {
      BTUSubmitBtnConfirmation({
        activityType: activityType,
        header: header,
      });
    }
  }

  function OSISubmitBtnConfirmation({ activityType, header }) {
    setPageState({ ...pageState, apiCallAction: true });
    const { rgwDeviceList, btuDeviceList, meshDeviceList } =
      ownOrderDetails?.deviceDetails;

    const comboList = rgwDeviceList?.concat(btuDeviceList, meshDeviceList);
    const deviceDetailsList = [];
    Object.keys(deviceSLValidation)?.map((productId) => {
      const deviceIndex = comboList?.findIndex(
        (obj) => obj?.productID === productId
      );
      const deviceInfo = comboList[deviceIndex];

      if (deviceSLValidation[productId]?.valid === ApiConstant.VALID_INP) {
        if (!devicesWithExistingSerialNo.includes(productId)) {
          deviceDetailsList.push({
            deviceActive: true,
            deviceProductId: productId,
            deviceSerialNo: deviceSLValidation[productId]?.serialnumber,
            deviceType: deviceInfo?.deviceType,
            devicePreviousSerialNo: null,
            deviceProblemCause: null,
            deviceResolution: null,
          });
        }
      }
    });

    const workOrderCompleteRequest = {
      activityId: workOrderDetails?.itemID,
      activitySubType: workOrderDetails?.itemSubTypeId,
      activityType: activityType,
      meshDevice:
        workOrderDetails?.ownOrderDetails?.deviceDetails?.meshDeviceList
          ?.length > 0,
      installerContact:
        userDataObj?.userData?.activeUserData?.userData?.contactNumber,
      installerId: userDataObj?.userData?.activeUserData?.userData?.userId,
      installerName: userDataObj?.userData?.activeUserData?.userData?.fullName,
      status: workOrderStatus,
      deviceDetailList: deviceDetailsList,
      storeId: userDataObj?.userData?.activeUserData?.userData?.outletId,
      userId: userDataObj?.userData?.activeUserData?.userData?.userId,
      statusUpdateDateTime: moment().format("YYYY-MM-DD HH:mm"),
      reservationId: installationDetail?.reservationId,
      btuId: btuId,
      btuPortNo: btuPortNumber,
      cableLength:
        additionalCableLength?.length > 0 ? additionalCableLength : null,
      desiredInstallation: desiredinstallationLovValue,
      infrastructure: infrastructureLovValue,
    };
    //To call the Fibre Update API
    UpdateWorkOrderDetails(
      "/fibre/update/updateactivitydetails",
      workOrderCompleteRequest,
      header
    )
      .then((response) => {
        setPageState({ ...pageState, apiCallAction: false });
        /*-- Move to confiramtion Page --*/
        if (response?.overallUpdateStatus === "SUCCESS") {
          dispatch(
            updateWorkOrderDetailsData({
              type: ApiConstant.INSTALLER_CLOSE_WORK_ORDER_TYPE,
              value: moment().format("YYYY-MM-DD HH:mm"),
            })
          );
          navigate("/workorder/statusupdate/confirm");
        } else {
          toast.warning(ApiConstant.INSTALLER_INST_COMPLETE_ERROR_MESSAGE, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        setPageState({ ...pageState, apiCallAction: false });
        if (error.response.status === 500 || error.response.status === 503) {
          toast.error(ApiConstant.INSTALLER_INST_COMPLETE_ERROR_MESSAGE, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        } else if (error.response.status === 404) {
          toast.warning(ApiConstant.INSTALLER_INST_COMPLETE_ERROR_MESSAGE, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        }
      });
  }

  function OSTSubmitBtnConfirmation({ activityType, header }) {
    setPageState({ ...pageState, apiCallAction: true });
    const { rgwDeviceList, meshDeviceList, btuDeviceList } =
      ownOrderDetails?.deviceDetails;
    const comboList = rgwDeviceList?.concat(meshDeviceList, btuDeviceList);

    let isOstDeviceSwapActiveDevice = false;
    const deviceDetailsList = [];
    Object.keys(deviceSLValidation)?.map((productId) => {
      const deviceIndex = comboList?.findIndex(
        (obj) => obj?.productID === productId
      );
      const deviceInfo = comboList[deviceIndex];

      if (deviceSLValidation[productId]?.valid === ApiConstant.VALID_INP) {
        deviceDetailsList.push({
          deviceActive:
            deviceInfo?.productStatus === ApiConstant.OST_DEVICE_ACTIVE,
          deviceProductId: productId,
          deviceSerialNo: deviceSLValidation[productId]?.serialnumber,
          deviceType: deviceInfo?.deviceType,
          devicePreviousSerialNo: deviceInfo?.deviceSerialNo,
          deviceProblemCause: deviceSLValidation[productId]?.problemCauseValue,
          deviceResolution: deviceSLValidation[productId]?.resolutionValue,
          isSwapDeviceWithModelChange:
            deviceSLValidation[productId]?.isSwapDeviceWithModelChange,
          newDeviceBrand: deviceSLValidation[productId]?.newDeviceBrand,
          newDeviceModel: deviceSLValidation[productId]?.newDeviceModel,
          newMaterialCode: deviceSLValidation[productId]?.newMaterialCode,
        });
        isOstDeviceSwapActiveDevice =
          deviceInfo?.productStatus !== ApiConstant.OST_DEVICE_INACTIVE;
      }
    });

    const workOrderCompleteRequest = {
      activityId: workOrderDetails?.itemID,
      activitySubType: workOrderDetails?.itemSubTypeId,
      activityType: activityType,
      meshDevice:
        workOrderDetails?.ownOrderDetails?.deviceDetails?.meshDeviceList
          ?.length > 0,
      installerContact:
        userDataObj?.userData?.activeUserData?.userData?.contactNumber,
      installerId: userDataObj?.userData?.activeUserData?.userData?.userId,
      installerName: userDataObj?.userData?.activeUserData?.userData?.fullName,
      status: workOrderStatus,
      deviceDetailList: deviceDetailsList,
      storeId: userDataObj?.userData?.activeUserData?.userData?.outletId,
      userId: userDataObj?.userData?.activeUserData?.userData?.userId,
      statusUpdateDateTime: moment().format("YYYY-MM-DD HH:mm"),
      reservationId: installationDetail?.reservationId,
      cableLength:
        additionalCableLength?.length > 0 ? additionalCableLength : null,
      ostDeviceSwapRequired: Object.keys(deviceSLValidation)?.length > 0,
      ostDeviceSwapActiveDevice: isOstDeviceSwapActiveDevice,
      ostProblemCause:
        Object?.keys(deviceSLValidation)?.length > 0
          ? deviceDetailsList[0].deviceProblemCause
          : ostProblemCauseValue,
      ostResolution:
        Object?.keys(deviceSLValidation)?.length > 0
          ? deviceDetailsList[0].deviceResolution
          : ostResolutionValue,
      customerId: workOrderDetails?.customerDetails?.customerId,
      caseNumber: workOrderDetails?.caseDetail?.caseNO,
      customerAccountId: workOrderDetails?.customerDetails?.customerAccountId,
      subscriberId: workOrderDetails?.subscriberId,
    };
    UpdateWorkOrderDetails(
      "/fibre/update/updateactivitydetails",
      workOrderCompleteRequest,
      header
    )
      .then((response) => {
        setPageState({ ...pageState, apiCallAction: false });
        /*-- Move to confiramtion Page --*/
        if (response?.overallUpdateStatus === "SUCCESS") {
          // console.log(response?.orderModifyResponseMsg)
          if (
            response?.orderModifyResponseMsg !== null &&
            response?.orderModifyResponseMsg?.length > 0 &&
            response?.orderModifyResponseMsg?.includes("::")
          ) {
            const orderIdList = response?.orderModifyResponseMsg?.split("::");
            // console.log(orderIdList, (orderIdList?.length > 0 && orderIdList[1]?.length > 0) ? orderIdList[1]?.replace(/\s+/g, '') : "")
            dispatch(
              updateWorkOrderDetailsData({
                type: ApiConstant.INSTALLER_WORK_ORDER_OST_ORDER_ID,
                value:
                  orderIdList?.length > 0 && orderIdList[1]?.length > 0
                    ? orderIdList[1]?.replace(/\s+/g, "")
                    : "",
              })
            );
          }
          dispatch(
            updateWorkOrderDetailsData({
              type: ApiConstant.INSTALLER_CLOSE_WORK_ORDER_TYPE,
              value: moment().format("YYYY-MM-DD HH:mm"),
            })
          );
          navigate("/workorder/statusupdate/confirm");
        } else {
          toast.warning(ApiConstant.INSTALLER_INST_COMPLETE_ERROR_MESSAGE, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        setPageState({ ...pageState, apiCallAction: false });
        if (error.response.status === 500 || error.response.status === 503) {
          toast.error(ApiConstant.INSTALLER_INST_COMPLETE_ERROR_MESSAGE, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        } else if (error.response.status === 404) {
          toast.warning(ApiConstant.INSTALLER_INST_COMPLETE_ERROR_MESSAGE, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        }
      });
  }

  function BTUSubmitBtnConfirmation({ activityType, header }) {
    setPageState({ ...pageState, apiCallAction: true });
    const deviceDetailsList = [];

    const workOrderCompleteRequest = {
      activityId: workOrderDetails?.itemID,
      activitySubType: workOrderDetails?.itemSubTypeId,
      activityType: activityType,
      btuId: btuId,
      btuCollected: btuDeviceCollected === "Yes",
      meshDevice:
        workOrderDetails?.ownOrderDetails?.deviceDetails?.meshDeviceList
          ?.length > 0,
      installerContact:
        userDataObj?.userData?.activeUserData?.userData?.contactNumber,
      installerId: userDataObj?.userData?.activeUserData?.userData?.userId,
      installerName: userDataObj?.userData?.activeUserData?.userData?.fullName,
      status: workOrderStatus,
      deviceDetailList: deviceDetailsList,
      storeId: userDataObj?.userData?.activeUserData?.userData?.outletId,
      userId: userDataObj?.userData?.activeUserData?.userData?.userId,
      statusUpdateDateTime: moment().format("YYYY-MM-DD HH:mm"),
      reservationId: installationDetail?.reservationId,
      cableLength:
        additionalCableLength?.length > 0 ? additionalCableLength : null,
      customerId: workOrderDetails?.customerDetails?.customerId,
      caseNumber: workOrderDetails?.caseDetail?.caseNO,
      customerAccountId: workOrderDetails?.customerDetails?.customerAccountId,
      subscriberId: workOrderDetails?.subscriberId,
      btuProductId:
        workOrderDetails?.ownOrderDetails?.btuNonReturnDeviceId?.length > 0
          ? workOrderDetails?.ownOrderDetails?.btuNonReturnDeviceId
          : "",
    };
    UpdateWorkOrderDetails(
      "/fibre/update/updateactivitydetails",
      workOrderCompleteRequest,
      header
    )
      .then((response) => {
        setPageState({ ...pageState, apiCallAction: false });
        /*-- Move to confiramtion Page --*/
        if (response?.overallUpdateStatus === "SUCCESS") {
          dispatch(
            updateWorkOrderDetailsData({
              type: ApiConstant.INSTALLER_CLOSE_WORK_ORDER_TYPE,
              value: moment().format("YYYY-MM-DD HH:mm"),
            })
          );
          navigate("/workorder/statusupdate/confirm");
        } else {
          toast.warning(ApiConstant.INSTALLER_INST_COMPLETE_ERROR_MESSAGE, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        setPageState({ ...pageState, apiCallAction: false });
        if (error.response.status === 500 || error.response.status === 503) {
          toast.error(ApiConstant.INSTALLER_INST_COMPLETE_ERROR_MESSAGE, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        } else if (error.response.status === 404) {
          toast.warning(ApiConstant.INSTALLER_INST_COMPLETE_ERROR_MESSAGE, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        }
      });
  }

  return (
    <div className="page-start-section">
      {pageState?.apiCallAction && (
        <ApiActionLoader msg={pageState?.apiCallActionMsg} />
      )}
      {/* Header - common for all the users */}
      <Header
        userDataObj={userDataObj}
        globalState={globalState}
        setGlobalState={setGlobalState}
        installerTabFlag={false}
      />
      {/* Body */}
      <div className="body-start-section">
        <div className="body-full-content-section">
          <HeaderNavChild4
            secondItem={Constent.HEADER_MENU_ITEM1}
            secondTab={"/workorder"}
            thirdItem={Constent.BODY_HEADER_WORKORDER_DETAILS_PATH}
            thirdTab={"/workorderdetails"}
            activeItem={Constent.BODY_HEADER_UPDATE_ACTIVITY}
          />
          <InstallerUpdateWorkOrderBodyContent
            pageState={pageState}
            userDataObj={userDataObj}
            setPageState={setPageState}
            workOrderDataObj={workOrderDataObj}
          />
        </div>
        <Footer />
      </div>
      <UpdateWorkOrderEnabledLocationModal
        pageState={pageState}
        setPageState={setPageState}
      />
      <UploadDocumentModal
        pageState={pageState}
        setPageState={setPageState}
        workOrderDetails={workOrderDetails}
        userDataObj={userDataObj}
      />
      {isModelChangeForAnySwapDevices ? (
        <NextPageConfirmationModal
          submitBtnConfirmation={submitBtnConfirmation}
          message={
            <>
              Swap Device with model change has been identified. Do you want to{" "}
              <strong>Close the Work Order?</strong>
            </>
          }
          btnName1={Constent.EDIT_BTN}
          btnName2={Constent.CONFIRMATION_BTN}
        />
      ) : (
        <NextPageConfirmationModal
          submitBtnConfirmation={submitBtnConfirmation}
          message={
            <>
              Do you want to <strong>Close the Work Order?</strong>
            </>
          }
          btnName1={Constent.EDIT_BTN}
          btnName2={Constent.CONFIRMATION_BTN}
        />
      )}

      <ToastContainer />
    </div>
  );
};

export default InstallerUpdateWorkOrderPage;
