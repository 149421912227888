import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../../Components/Footer/Footer';
import { Header } from '../../Components/Header/Header';
import ManageInstallerBodyHeader from '../../Components/MainBody/BodyHeader/Supervisor/ManageInstallerBodyHeader';
import SupervisorSidebar from '../../Components/MainBody/Sidebar/SupervisorSidebar';

import '../../Assets/CSS/ManageInstallerPage.css';
import ManageInstallerBodyContent from '../../Components/MainBody/BodyContent/Supervisor/ManageInstallerBodyContent';
import InstallerDetailsInfoModal from '../../Components/Modals/InstallerDetailsInfoModal';
import AddInstallerModal from '../../Components/Modals/AddInstallerModal';
import EditInstallerModal from '../../Components/Modals/EditInstallerModal';
import DeleteInstallerModal from '../../Components/Modals/DeleteInstallerModal';
import { GenericLovCalls, GetInsatller, headers, UpdateInsatller } from '../../Api/ApiCalls';

import { ToastContainer, toast } from 'react-toastify';
import { ApiConstant } from '../../Assets/Constants/ApiConstants';
import { saveLovs } from '../../ReduxFeature/StateFeatures/LovDataState';
import { ApiActionLoader } from '../../Components/Loaders/ApiActionLoader';

const ManageInstallerPage = ({ globalState, setGlobalState }) => {
    const userDataObj = useSelector((state) => state.userDataInfo);
    const lovDataObj = useSelector((state) => state.lovDataInfo);

    const installerDataObj = useSelector((state) => state.installerDataInfo);

    const { installerNameOrContact } = installerDataObj;
    const dispatch = useDispatch();

    const [pageState, setPageState] = useState({
        totalItems: 0,
        getDataLoader: false,
        installerList: [],
        cardView: "List",
        installerNameOrContact: "",

        selectedInstallerData: null,

        // Create insatller Data
        addInstallerFirstName: "",
        addInstallerLastName: "",
        addInstallerIdType: "",
        addInstallerUserName: "",
        addInstallerIdNo: "",
        addInstallerContact: "",
        addInstallerEmail: "",
        addInstallerDistrict: "",

        // Error Part
        getDataError: false,
        getDataErrorMsg: "",

        apiCallAction: false,
        apiCallActionMsg: "Progressing..."
    });

    function GetInstallerApiCall() {
        setPageState({ ...pageState, getDataLoader: true });
        //  Header
        const header = headers(
            userDataObj?.userData?.activeUserData?.authToken
        );
        const installerRequest = {
            supervisorId: (userDataObj?.userData?.activeUserData?.userData?.userId || null),
            outletId: (userDataObj?.userData?.activeUserData?.userData?.outletId || null)
        }
        GetInsatller("/fibre/installer/list", installerRequest, header).then(response => {
            setPageState({
                ...pageState, getDataLoader: false,
                getDataError: false,
                installerList: response?.installerList
            });
        }).catch(error => {
            let errorMessage;
            errorMessage = ApiConstant.GET_INSTALLER_ERROR_MESSAGE_WRONG;
            if (error.response.status === 500) {
                errorMessage = ApiConstant.GET_INSTALLER_ERROR_MESSAGE;
            }
            else {
                errorMessage = ApiConstant.GET_INSTALLER_ERROR_MESSAGE_NO_DATA;
            }
            toast.error(errorMessage,
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 10000,
                    theme: 'colored'
                }
            );
            setPageState({
                ...pageState, getDataLoader: false,
                getDataError: true,
                getDataErrorMsg: errorMessage,
                installerList: []
            });
        });
    }
    function InstallerPageLovApiCalls() {
        //  Header
        const header = headers(
            userDataObj?.userData?.activeUserData?.authToken
        );
        if (lovDataObj?.district === null || lovDataObj?.district === undefined ||
            lovDataObj?.district?.length === 0) {

            const districtLovRequest = {
                searchBy: ApiConstant.LOV_DISTRICT_KEY,
            }

            GenericLovCalls("/fibre/getLovs", districtLovRequest, header).then(response => {
                dispatch(saveLovs({
                    key: ApiConstant.LOV_STORE_DISTRICT_KEY,
                    value: response?.wrapperLovItemDataList
                }));
            });

        }
        if (lovDataObj?.idtype === null || lovDataObj?.idtype === undefined ||
            lovDataObj?.idtype?.length === 0 || lovDataObj?.state?.length === 0) {

            const installerIDTypeLovRequest = {
                searchBy: ApiConstant.LOV_INSTALLER_ID_TYPE_KEY,
            }
            GenericLovCalls("/fibre/getlovs/installer", installerIDTypeLovRequest, header).then(response => {
                dispatch(saveLovs({
                    key: ApiConstant.LOV_STORE_INSTALLER_ID_TYPE_KEY,
                    value: response?.idtypeList
                }));
                dispatch(saveLovs({
                    key: ApiConstant.LOV_STORE_STATE_DISTRICT,
                    value: response?.stateList
                }));
            });
        }
    }

    useEffect(() => {
        GetInstallerApiCall();
        InstallerPageLovApiCalls();
    }, []);
    const filterList = pageState?.installerList?.filter(item => {

        if (installerNameOrContact === ""
            || item?.userId?.toLowerCase()?.includes(installerNameOrContact?.toLowerCase())
            // || item?.firstName?.toLowerCase()?.includes(installerNameOrContact?.toLowerCase())
            // || item?.lastName?.toLowerCase()?.includes(installerNameOrContact?.toLowerCase())
            || item?.mobileNo?.toLowerCase()?.includes(installerNameOrContact?.toLowerCase())) {
            return item;
        }
    });

    return (
        <div className='page-start-section'>
            {
                pageState?.apiCallAction && <ApiActionLoader msg={pageState?.apiCallActionMsg} />
            }
            <Header userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} tab3={true} />
            <div className={globalState?.sidebarState ? "body-start-section sidebar-active-section" : "body-start-section"}>
                {/* Sidebar */}
                <SupervisorSidebar userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} />
                <div className="body-full-content-section">
                    <ManageInstallerBodyHeader userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState}
                        pageState={pageState} setPageState={setPageState} GetInstallerApiCall={GetInstallerApiCall}
                        filterList={filterList || []} installerDataObj={installerDataObj} />
                    <ManageInstallerBodyContent userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState}
                        pageState={pageState} setPageState={setPageState} filterList={filterList || []} />
                </div>
                <Footer />

            </div>
            <InstallerDetailsInfoModal installerDetails={pageState?.selectedInstallerData} />
            <EditInstallerModal installerDetails={pageState?.selectedInstallerData} pageState={pageState} setPageState={setPageState}
                userDataObj={userDataObj} />
            <AddInstallerModal lovDataObj={lovDataObj} userDataObj={userDataObj} globalState={globalState}
                pageState={pageState} setPageState={setPageState} setGlobalState={setGlobalState} />
            <DeleteInstallerModal userDataObj={userDataObj} pageState={pageState} setPageState={setPageState}
                installerDetails={pageState?.selectedInstallerData} setGlobalState={setGlobalState} />
            <ToastContainer />
        </div>
    )
}

export default ManageInstallerPage;