import React, { useState } from "react";
import { DownloadDocument, headers } from "../../../../../Api/ApiCalls";
import { ReturnStaticFileDownloadIcon } from "../../../../../Assets/Images/CelcomSvgImages";

import { PagenationIndexPositionCal } from "../../../../Common/GenericFunctions";
import Pagination from "../../../../Common/Pagination";
import { CardLoader } from "../../../../Loaders/Loaders";
import ManageStockCard from "../../../Cards/Supervisor/ManageStockCard";
import { toast } from "react-toastify";
import { ApiConstant } from "../../../../../Assets/Constants/ApiConstants";
import { NoDataFoundItemGroup } from "../../../../Common/CardContent";

const InventoryViewStockContent = ({
  globalState,
  pageState,
  setPageState,
  invDataObj,
  userDataObj,
}) => {
  const { manageStockList, viewStockInputValue } = invDataObj;

  const [currentPage, setCurrentPage] = useState(1);
  const postPerPage = Math.min(manageStockList?.length, 10);

  const { indexOfLastPost, indexOfFirstPost, displayPosts } =
    PagenationIndexPositionCal(currentPage, postPerPage, manageStockList);

  const filterList = manageStockList?.filter((item) => {
    if (
      viewStockInputValue === "" ||
      item?.sAPMaterialCode
        ?.toLowerCase()
        ?.includes(viewStockInputValue?.toLowerCase()) ||
      item?.itemName
        ?.toLowerCase()
        ?.includes(viewStockInputValue?.toLowerCase())
    ) {
      return item;
    }
  });

  const filterListDisplay = displayPosts?.filter((displayItem) => {
    if (
      viewStockInputValue === "" ||
      displayItem?.sAPMaterialCode
        ?.toLowerCase()
        ?.includes(viewStockInputValue?.toLowerCase()) ||
      displayItem?.itemName
        ?.toLowerCase()
        ?.includes(viewStockInputValue?.toLowerCase())
    ) {
      return displayItem;
    }
  });

  function downloadDocumentFile({ data, filename, type }) {
    const linkSource = `data:application/${type};base64,${data}`;
    const downloadLink = document.createElement("a");
    const fileName = filename;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <>
      {pageState?.dataLoader ? (
        [0, 1].map((val) => {
          return <CardLoader key={`viewStcokList${val}`} />;
        })
      ) : pageState?.getViewStockDataError ? (
        <NoDataFoundItemGroup message={pageState?.getViewStockDataErrorMsg} />
      ) : (
        <>
          {filterList?.length > 0 ? (
            <div className="inventory-filter-section">
              <div className="filter-item-info-section">
                <strong>{`${indexOfFirstPost + 1} - ${
                  indexOfLastPost > filterList?.length
                    ? filterList?.length
                    : indexOfLastPost
                } of ${filterList?.length}`}</strong>{" "}
                Items
              </div>
              <div className="filter-item-info-section">
                <Pagination
                  lastPageNumber={Math.ceil(filterList?.length / postPerPage)}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
              <div className="filter-item-info-section">
                <div
                  className="filter-item-info-group"
                  onClick={() => {
                    //  Header
                    const header = headers(
                      userDataObj?.userData?.activeUserData?.authToken
                    );
                    const request = {
                      documentId: "6b71548a-9372-410f-b647-485f42c93cc8",
                    };

                    DownloadDocument("/fibre/file/download", request, header)
                      .then((response) => {
                        downloadDocumentFile({
                          data: response.data,
                          filename: "ReturnStockSupervisorForms",
                          type: "zip",
                        });
                      })
                      .catch(() => {
                        toast.warning(
                          ApiConstant.DOWNLOAD_DOCUMENT_WARNING_MESSAGE,
                          {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 10000,
                            theme: "colored",
                          }
                        );
                      });
                  }}
                >
                  <div className="filter-item-ingo-content">Download Forms</div>
                  <ReturnStaticFileDownloadIcon />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {filterListDisplay?.map((data, index) => {
            return (
              <ManageStockCard
                key={`viewManageInv${data?.sAPMaterialCode + index}`}
                globalState={globalState}
                pageState={pageState}
                setPageState={setPageState}
                data={data}
              />
            );
          })}
        </>
      )}
    </>
  );
};

export default InventoryViewStockContent;
