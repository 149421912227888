import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../../Components/Footer/Footer";
import { Header } from "../../Components/Header/Header";
import InventoryBodyHeader from "../../Components/MainBody/BodyHeader/Supervisor/InventoryBodyHeader";
import SupervisorSidebar from "../../Components/MainBody/Sidebar/SupervisorSidebar";

import "../../Assets/CSS/InventoryPage.css";
import InventoryBodyContent from "../../Components/MainBody/BodyContent/Supervisor/InventoryBodyContent";
import {
  DirectLovCalls,
  GenericLovCalls,
  headers,
  TrackStockList,
  ViewStockList,
} from "../../Api/ApiCalls";
import { Constent } from "../../Assets/Constants/Constent";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { saveLovs } from "../../ReduxFeature/StateFeatures/LovDataState";
import { validNumberInput } from "../../Components/Common/GenericFunctions";
import { ToastContainer, toast } from "react-toastify";
import { viewStockListRequestValidation } from "../../Components/Common/Validation";
import {
  setStockListData,
  setTrackStockFieldValue,
} from "../../ReduxFeature/StateFeatures/InventoryDataState";
import { setCartRequestStockQuntData } from "../../ReduxFeature/StateFeatures/CartDataState";

const InventoryPage = ({ globalState, setGlobalState }) => {
  const userDataObj = useSelector((state) => state.userDataInfo);
  const lovDataObj = useSelector((state) => state.lovDataInfo);
  const invDataObj = useSelector((state) => state.inventoryDataInfo);
  const cartDataObj = useSelector((state) => state.cartDataInfo);

  const dispatch = useDispatch();

  const {
    manageStockList,
    requestStockList,
    trackRequestStockList,
    trackReturnStockList,
    trackTransferStockList,
    viewStockCategoryValue,
    viewStockBrandValue,
    viewStockInputValue,
    selectedTrackStockItem,
    selectedTrackStockItemDetails,
    trackStockRequestId,
    trackStockFormData,
    trackStockToData,
    trackStockRequestStatus,
  } = invDataObj;

  const { confirmationPageTransferId, confirmationPageMessage } = cartDataObj;
  const [pageState, setPageState] = useState({
    totalItems: 0,
    activePill:
      globalState?.inventoryActivePill ||
      Constent.BODY_HEADER_INVENTORY_PILL_ITEM1,
    activeRadioBtn: Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1,

    dataLoader: false,
    trackStockRequestDataLoader: false,
    trackStockReturnDataLoader: false,
    trackStockTransferDataLoader: false,

    currentPage: 1,
    postPerPage: 10,

    // Error Part
    getViewStockDataError: false,
    getViewStockDataErrorMsg: "",
    // Error Part
    getRequestStockDataError: false,
    getRequestStockDataErrorMsg: "",

    // Error Part
    getTrackStockRequestDataError: false,
    getTrackStockRequestDataErrorMsg: "",

    // Error Part
    getTrackStockReturnDataError: false,
    getTrackStockReturnkDataErrorMsg: "",

    // Error Part
    getTrackStockTransferDataError: false,
    getTrackStockTransferDataErrorMsg: "",
  });

  function ErrorMessageSetUp({ error, message1, message2 }) {
    let errorMessage;
    if (error.response.status === 500 || error.response.status === 503) {
      errorMessage = message1;
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    } else if (error.response.status === 404) {
      errorMessage = message2;
      toast.warning(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    } else {
      errorMessage = error.response.statusText;
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    }
    return errorMessage;
  }

  function inventoryApiCalls() {
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);

    // View Stock Part
    if (pageState?.activePill === Constent.BODY_HEADER_INVENTORY_PILL_ITEM1) {
      // View Stock Request -
      const viewStockRequest = {
        deviceTypeId:
          viewStockCategoryValue?.length > 0 ? viewStockCategoryValue : null,
        itemDesc: validNumberInput(viewStockInputValue)
          ? null
          : viewStockInputValue?.length > 0
          ? viewStockInputValue
          : null,
        sapMaterialCode: validNumberInput(viewStockInputValue)
          ? viewStockInputValue
          : null,
        inventoryItemTypeId: ApiConstant.INVENTORY_ITEM_TYPE_ID,
        itemLocationId:
          pageState?.activeRadioBtn ===
          Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1
            ? userDataObj?.userData?.activeUserData?.userData?.outletId
            : ApiConstant.INVENTORY_REQUESTING_STORE_ID,
        manufacturerId:
          viewStockBrandValue?.length > 0 ? viewStockBrandValue : null,
        userId: userDataObj?.userData?.activeUserData?.userData?.userId,
      };

      /*---------------- If Request Pass the Validation Pass -----------------------*/
      if (viewStockListRequestValidation(viewStockRequest)) {
        setPageState({ ...pageState, dataLoader: true });
        ViewStockList("/fibre/inventory/generic", viewStockRequest, header)
          .then((response) => {
            if (
              pageState?.activeRadioBtn ===
              Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1
            ) {
              dispatch(
                setStockListData({
                  type: ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE1,
                  list: response?.wrapperViewStockResponseList,
                })
              );

              setPageState({
                ...pageState,
                dataLoader: false,
                getViewStockDataError: false,
                getViewStockDataErrorMsg: "",
              });
            } else {
              dispatch(
                setStockListData({
                  type: ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE2,
                  list: response?.wrapperViewStockResponseList,
                })
              );
              setPageState({
                ...pageState,
                dataLoader: false,
                getRequestStockDataError: false,
                getRequestStockDataErrorMsg: "",
              });
            }
          })
          .catch((error) => {
            const errorMessage = ErrorMessageSetUp({
              error: error,
              message1: ApiConstant.VIEW_STOCK_LIST_ERROR_MESSAGE,
              message2: ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE,
            });
            if (
              pageState?.activeRadioBtn ===
              Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1
            ) {
              dispatch(
                setStockListData({
                  type: ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE1,
                  list: [],
                })
              );
              /* ------------- Set up the Page State ------------- */
              setPageState({
                ...pageState,
                dataLoader: false,
                getViewStockDataError: true,
                getViewStockDataErrorMsg: errorMessage,
              });
            } else {
              dispatch(
                setStockListData({
                  type: ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE2,
                  list: [],
                })
              );
              /* ------------- Set up the Page State ------------- */
              setPageState({
                ...pageState,
                dataLoader: false,
                getRequestStockDataError: true,
                getRequestStockDataErrorMsg: errorMessage,
              });
            }

            // PageStateSetUp({ type: Constent.BODY_HEADER_INVENTORY_PILL_ITEM1, errorMessage: errorMessage });
          });
      } else {
        /*---------------- If Request Fails the Validation Pass -----------------------*/
        let errorMessage = ApiConstant.INVENTORY_VALID_INPUT_WARING_MESSAGE;
        toast.warning(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 10000,
          theme: "colored",
        });
        /* ------------- Set up the Page State ------------- */
        if (
          pageState?.activeRadioBtn ===
          Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1
        ) {
          dispatch(
            setStockListData({
              type: ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE1,
              list: [],
            })
          );
          /* ------------- Set up the Page State ------------- */
          setPageState({
            ...pageState,
            dataLoader: false,
            getViewStockDataError: true,
            getViewStockDataErrorMsg: errorMessage,
          });
        } else {
          dispatch(
            setStockListData({
              type: ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE2,
              list: [],
            })
          );
          /* ------------- Set up the Page State ------------- */
          setPageState({
            ...pageState,
            dataLoader: false,
            getRequestStockDataError: true,
            getRequestStockDataErrorMsg: errorMessage,
          });
        }
        // PageStateSetUp({ type: Constent.BODY_HEADER_INVENTORY_PILL_ITEM1, errorMessage: errorMessage });
      }
    }
    // Track Stock Part
    else {
      /*-------- If Request Stock Radio Btn Selected ---------*/
      if (
        pageState?.activeRadioBtn === Constent.REQUEST_STOCK_RADIO_BTN_LABLE
      ) {
        setPageState({ ...pageState, trackStockRequestDataLoader: true });
        const trackRequestStockRequest = {
          approverName: null,
          dateFrom: trackStockFormData?.length > 0 ? trackStockFormData : null,
          dateTo: trackStockToData?.length > 0 ? trackStockToData : null,
          requestType: "RequestFromHSBB",
          requestingStoreId:
            userDataObj?.userData?.activeUserData?.userData?.outletId,
          storeId: ApiConstant.INVENTORY_REQUESTING_STORE_ID,
          transferId:
            trackStockRequestId?.length > 0 ? trackStockRequestId : null,
          transferStatus:
            trackStockRequestStatus?.length > 0
              ? trackStockRequestStatus
              : null,
          userId: null,
        };

        TrackStockList(
          "/fibre/inventory/getTransferListByDate",
          trackRequestStockRequest,
          header
        )
          .then((response) => {
            if (
              response?.status
                ?.toLowerCase()
                ?.includes("No data found"?.toLowerCase())
            ) {
              toast.warning(ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 10000,
                theme: "colored",
              });
            } else {
              /*-------- Set The List in Redux ---------*/
              dispatch(
                setStockListData({
                  type: ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE4,
                  list: response?.transferListByDate,
                })
              );
              /*-------- Set the other Stafs in State ---------*/
              setPageState({
                ...pageState,
                trackStockRequestDataLoader: false,
                getTrackStockRequestDataError: false,
                getTrackStockRequestDataErrorMsg: "",
              });
            }
          })
          .catch((error) => {
            /*-------- Set The List in Redux ---------*/
            dispatch(
              setStockListData({
                type: ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE4,
                list: [],
              })
            );
            const errorMessage = ErrorMessageSetUp({
              error: error,
              message1: ApiConstant.VIEW_STOCK_LIST_ERROR_MESSAGE,
              message2: ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE,
            });
            setPageState({
              ...pageState,
              trackStockRequestDataLoader: false,
              getTrackStockRequestDataError: true,
              getTrackStockRequestDataErrorMsg: errorMessage,
            });
          });
      } else if (
        pageState?.activeRadioBtn === Constent.RETURN_STOCK_RADIO_BTN_LABLE
      ) {
        /*-------- If Return Stock Radio Btn Selected ---------*/
        setPageState({ ...pageState, trackStockReturnDataLoader: true });
        const trackReturnStockRequest = {
          approverName: null,
          dateFrom: trackStockFormData?.length > 0 ? trackStockFormData : null,
          dateTo: trackStockToData?.length > 0 ? trackStockToData : null,
          requestType: "ReturnToHQ",
          requestingStoreId: ApiConstant.INVENTORY_REQUESTING_STORE_ID,
          storeId: userDataObj?.userData?.activeUserData?.userData?.outletId,
          transferId:
            trackStockRequestId?.length > 0 ? trackStockRequestId : null,
          transferStatus:
            trackStockRequestStatus?.length > 0
              ? trackStockRequestStatus
              : null,
          userId: null,
        };
        TrackStockList(
          "/fibre/inventory/getTransferListByDate",
          trackReturnStockRequest,
          header
        )
          .then((response) => {
            if (
              response?.status
                ?.toLowerCase()
                ?.includes("No data found"?.toLowerCase())
            ) {
              toast.warning(ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 10000,
                theme: "colored",
              });
            } else {
              /*-------- Set The List in Redux ---------*/
              dispatch(
                setStockListData({
                  type: ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE5,
                  list: response?.transferListByDate,
                })
              );
              /*-------- Set the other Stafs in State ---------*/
              setPageState({
                ...pageState,
                trackStockReturnDataLoader: false,
                getTrackStockReturnDataError: false,
                getTrackStockReturnDataErrorMsg: "",
              });
            }
          })
          .catch((error) => {
            /*-------- Set The List in Redux ---------*/
            dispatch(
              setStockListData({
                type: ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE5,
                list: [],
              })
            );
            const errorMessage = ErrorMessageSetUp({
              error: error,
              message1: ApiConstant.VIEW_STOCK_LIST_ERROR_MESSAGE,
              message2: ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE,
            });
            setPageState({
              ...pageState,
              trackStockReturnDataLoader: false,
              getTrackStockReturnDataError: true,
              getTrackStockReturnDataErrorMsg: errorMessage,
            });
          });
      } else {
        /*-------- If Transfer Stock Radio Btn Selected ---------*/
        setPageState({ ...pageState, trackStockTransferDataLoader: true });
        const trackTransferStockRequest = {
          approverName: null,
          dateFrom: trackStockFormData?.length > 0 ? trackStockFormData : null,
          dateTo: trackStockToData?.length > 0 ? trackStockToData : null,
          requestType: "TransferForHSBB",
          requestingStoreId:
            userDataObj?.userData?.activeUserData?.userData?.outletId,
          storeId: userDataObj?.userData?.activeUserData?.userData?.outletId,
          fetchFromAndToStore: "Y",
          transferId:
            trackStockRequestId?.length > 0 ? trackStockRequestId : null,
          transferStatus:
            trackStockRequestStatus?.length > 0
              ? trackStockRequestStatus
              : null,
          userId: null,
        };

        TrackStockList(
          "/fibre/inventory/getTransferListByDate",
          trackTransferStockRequest,
          header
        )
          .then((response) => {
            if (
              response?.status
                ?.toLowerCase()
                ?.includes("No data found"?.toLowerCase())
            ) {
              toast.warning(ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 10000,
                theme: "colored",
              });
            } else {
              /*-------- Set The List in Redux ---------*/
              dispatch(
                setStockListData({
                  type: ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE6,
                  list: response?.transferListByDate,
                })
              );
              /*-------- Set the other Stafs in State ---------*/
              setPageState({
                ...pageState,
                trackStockTransferDataLoader: false,
                getTrackStockTransferDataError: false,
                getTrackStockTransferDataErrorMsg: "",
              });
            }
          })
          .catch((error) => {
            /*-------- Set The List in Redux ---------*/
            dispatch(
              setStockListData({
                type: ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE6,
                list: [],
              })
            );
            const errorMessage = ErrorMessageSetUp({
              error: error,
              message1: ApiConstant.VIEW_STOCK_LIST_ERROR_MESSAGE,
              message2: ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE,
            });
            setPageState({
              ...pageState,
              trackStockTransferDataLoader: false,
              getTrackStockTransferDataError: true,
              getTrackStockTransferDataErrorMsg: errorMessage,
            });
          });
      }
    }
  }

  useEffect(() => {
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);

    if (
      confirmationPageTransferId?.length > 0 &&
      confirmationPageMessage?.length > 0
    ) {
      dispatch(
        setCartRequestStockQuntData({
          type: ApiConstant.REMOVE_CART_CONFIRMATION_MSG,
          value: null,
        })
      );
    }
    if (
      lovDataObj?.invcategorybrand === null ||
      lovDataObj?.invcategorybrand === undefined ||
      lovDataObj?.invcategorybrand?.length === 0
    ) {
      DirectLovCalls("/fibre/getlovs/category", header).then((response) => {
        dispatch(
          saveLovs({
            key: ApiConstant.LOV_STORE_CATEGORY_AND_BRAND_KEY,
            value: response,
          })
        );
      });
    }
    if (
      lovDataObj?.invrequeststatus === null ||
      lovDataObj?.invrequeststatus === undefined ||
      lovDataObj?.invrequeststatus?.length === 0
    ) {
      GenericLovCalls(
        "/fibre/getLovs",
        { searchBy: ApiConstant.LOV_INVENTORY_REQUEST_STATUS_KEY },
        header
      ).then((response) => {
        dispatch(
          saveLovs({
            key: ApiConstant.LOV_STORE_REQUEST_STATUS_KEY,
            value: response?.wrapperLovItemDataList,
          })
        );
      });
    }
    if (
      selectedTrackStockItem !== null &&
      selectedTrackStockItemDetails !== null
    ) {
      dispatch(
        setTrackStockFieldValue({
          type: ApiConstant.INVENTORY_TRACK_STOCK_RESET_SELECTED_DATA_ITEM,
          value: null,
        })
      );
    }
  }, []);

  return (
    <div className="page-start-section">
      <Header
        userDataObj={userDataObj}
        globalState={globalState}
        setGlobalState={setGlobalState}
        tab4={true}
      />
      <div
        className={
          globalState?.sidebarState
            ? "body-start-section sidebar-active-section"
            : "body-start-section"
        }
      >
        {/* Sidebar */}
        <SupervisorSidebar
          userDataObj={userDataObj}
          globalState={globalState}
          setGlobalState={setGlobalState}
        />
        <div className="body-full-content-section">
          <InventoryBodyHeader
            userDataObj={userDataObj}
            globalState={globalState}
            setGlobalState={setGlobalState}
            invDataObj={invDataObj}
            pageState={pageState}
            setPageState={setPageState}
            inventoryApiCalls={inventoryApiCalls}
            lovDataObj={lovDataObj}
          />
          <InventoryBodyContent
            userDataObj={userDataObj}
            globalState={globalState}
            setGlobalState={setGlobalState}
            pageState={pageState}
            setPageState={setPageState}
            invDataObj={invDataObj}
            manageStockList={manageStockList}
            requestStockList={requestStockList}
            trackRequestStockList={trackRequestStockList}
            trackReturnStockList={trackReturnStockList}
            trackTransferStockList={trackTransferStockList}
          />
        </div>
        <Footer />
        <ToastContainer />
      </div>
    </div>
  );
};

export default InventoryPage;
