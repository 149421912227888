import React from "react";
import {
  DocumentManagement,
  DocumentManagement1,
  headers,
} from "../../Api/ApiCalls";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { Constent } from "../../Assets/Constants/Constent";
import { CloseModalIcon } from "../../Assets/Images/CelcomSvgImages";
import FileUploadModalDragAndDropSection from "./FileUploadModalHelper/FileUploadModalDragAndDropSection";
import FileUploadModalViewSection from "./FileUploadModalHelper/FileUploadModalViewSection";
import { toast } from "react-toastify";
import { AllowedFileTypes, FileConversion } from "../Common/GenericFunctions";
import axios from "axios";

const UploadDocumentModal = ({
  pageState,
  setPageState,
  userDataObj,
  workOrderDetails,
}) => {
  // function attachUploadedDocument1({ target: { files } }) {
  //     let file = files[0];
  //     const fileName = file?.name;
  //     if (AllowedFileTypes({ extention: fileName?.split('.').pop() })) {

  //         const fileSize = FileConversion({ size: file?.size });
  //         if (fileSize <= 2) {
  //             let fileReader = new FileReader();

  //             if (file) {
  //                 fileReader.readAsDataURL(file);
  //                 fileReader.onload = () => {
  //                     let encoderContent = fileReader.result?.split(",")[1];
  //                     setPageState({
  //                         ...pageState, uploadedFileList: [...pageState?.uploadedFileList, {
  //                             id: pageState?.uploadedFileList?.length,
  //                             name: file?.name,
  //                             base64Document: encoderContent,
  //                             docReferenceId: "",
  //                             uploadStatus: ApiConstant.UPLOAD_DOCUMENT_ITEM_NOT_COMPLETE_STATUS,
  //                             progress: 0
  //                         }]
  //                     });
  //                 };
  //                 fileReader.onerror = (error) => {
  //                     // console.log(error);
  //                 }
  //             }
  //         } else {
  //             toast.warning(ApiConstant.FILE_SIZE_EXIDED_WARNING_MESSAGE,
  //                 {
  //                     position: toast.POSITION.BOTTOM_RIGHT,
  //                     autoClose: 10000,
  //                     theme: 'colored'
  //                 }
  //             );
  //         }
  //     }
  //     else {
  //         toast.warning(ApiConstant.UNSUPPORTED_FILE_WARNING_MESSAGE,
  //             {
  //                 position: toast.POSITION.BOTTOM_RIGHT,
  //                 autoClose: 10000,
  //                 theme: 'colored'
  //             }
  //         );
  //     }
  // }

  // function uploadDocuments() {
  //     //  Header
  //     const header = headers(
  //         userDataObj?.userData?.activeUserData?.authToken
  //     );

  //     if (pageState?.documentTypeLovValue?.length > 0 && pageState?.uploadedFileList?.length > 0) {
  //         // Call the api one by one
  //         pageState?.uploadedFileList?.map((filterdDocument, index) => {
  //             if (filterdDocument?.uploadStatus === ApiConstant.UPLOAD_DOCUMENT_ITEM_NOT_COMPLETE_STATUS) {
  //                 const request = {
  //                     attachmentName: filterdDocument?.name || "",
  //                     fileType: filterdDocument?.name?.split(".")[1],
  //                     documentType: pageState?.documentTypeLovValue,
  //                     workOrder_Id: workOrderDetails?.itemID,
  //                     comments: "Uploaded By " + userDataObj?.userData?.activeUserData?.userData?.userId + " From frontend",
  //                     UploadedBy: userDataObj?.userData?.activeUserData?.userData?.userId,
  //                     base64Document: filterdDocument?.base64Document
  //                 };
  //                 const tempListState1 = [...pageState?.uploadedFileList];
  //                 const tempListElement1 = { ...tempListState1[index] };

  //                 tempListElement1.uploadStatus = ApiConstant.UPLOAD_DOCUMENT_ITEM_PROGRESS_STATUS;

  //                 tempListState1[index] = tempListElement1;

  //                 setPageState({ ...pageState, uploadedFileList: tempListState1 });
  //                 const options = (progressEvent) => {
  //                     const { loaded, total } = progressEvent;
  //                     let percent = Math.floor(loaded * 100 / total);

  //                     // console.log(loaded, total, percent)
  //                     const tempListState2 = [...pageState?.uploadedFileList];
  //                     const tempListElement2 = { ...tempListState2[index] };

  //                     tempListElement2.uploadStatus = ApiConstant.UPLOAD_DOCUMENT_ITEM_PROGRESS_STATUS;
  //                     tempListElement2.progress = percent;

  //                     tempListState2[index] = tempListElement2;

  //                     setPageState({ ...pageState, uploadedFileList: tempListState2 });

  //                 }

  //                 DocumentManagement("/fibre/file/upload", request, header, options).then(response => {
  //                     if (response?.length === 1) {
  //                         const tempListState = [...pageState?.uploadedFileList];
  //                         const tempListElement = { ...tempListState[index] };

  //                         tempListElement.uploadStatus = ApiConstant.UPLOAD_DOCUMENT_ITEM_COMPLETE_STATUS;
  //                         tempListElement.docReferenceId = response[0]?.docReferenceId;

  //                         tempListState[index] = tempListElement;

  //                         setPageState({ ...pageState, uploadedFileList: tempListState })
  //                     }
  //                 }).catch(error => {
  //                     const tempListState = [...pageState?.uploadedFileList];
  //                     const tempListElement = { ...tempListState[index] };

  //                     tempListElement.uploadStatus = ApiConstant.UPLOAD_DOCUMENT_ITEM_NOT_COMPLETE_STATUS;

  //                     tempListState[index] = tempListElement;

  //                     setPageState({ ...pageState, uploadedFileList: tempListState })
  //                 })
  //             }
  //         });
  //     } else {
  //         toast.warning(ApiConstant.UPLOAD_FILE_VALIDATION_WARNING_MESSAGE,
  //             {
  //                 position: toast.POSITION.TOP_CENTER,
  //                 autoClose: 10000,
  //                 theme: 'colored'
  //             }
  //         );
  //     }
  // }

  //const [uploadedfilesList, setUploadedfilesList] = useState([])

  function attachUploadedDocument({ target: { files } }) {
    let file = files[0];
    const fileName = file?.name;
    const checkfileName = [...pageState?.uploadedFileList].filter(
      (item) => item.name === fileName
    );

    if (checkfileName.length > 0) {
      toast.warning(ApiConstant.FILE_NAME_DUPLICATE, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    } else {
      if (AllowedFileTypes({ extention: fileName?.split(".").pop() })) {
        const fileSize = FileConversion({ size: file?.size });
        if (fileSize <= 50) {
          setPageState({
            ...pageState,
            uploadedFileList: [
              ...pageState?.uploadedFileList,
              {
                id: pageState?.uploadedFileList?.length,
                name: file?.name,
                // base64Document: encoderContent,
                file: file,
                docReferenceId: "",
                uploadStatus:
                  ApiConstant.UPLOAD_DOCUMENT_ITEM_NOT_COMPLETE_STATUS,
                progress: 0,
              },
            ],
          });
        } else {
          toast.warning(ApiConstant.FILE_SIZE_EXIDED_WARNING_MESSAGE, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        }
      } else {
        toast.warning(ApiConstant.UNSUPPORTED_FILE_WARNING_MESSAGE, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 10000,
          theme: "colored",
        });
      }
    }
  }
  function uploadDocuments() {
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);

    // console.log(header, pageState?.uploadedFileList);
    header["Content-Type"] = "multipart/form-data";
    // console.log(header);

    let uploadedfilesList = [];
    const updFiles = [...pageState?.uploadedFileList].filter(
      (item) => item.uploadStatus === "Done"
    );
    uploadedfilesList = updFiles;
    if (
      pageState?.documentTypeLovValue?.length > 0 &&
      pageState?.uploadedFileList?.length > 0
    ) {
      // Call the api one by one
      pageState?.uploadedFileList?.map((filterdDocument, index) => {
        if (
          filterdDocument?.uploadStatus ===
          ApiConstant.UPLOAD_DOCUMENT_ITEM_NOT_COMPLETE_STATUS
        ) {
          const tempListState1 = [...pageState?.uploadedFileList];
          const tempListElement1 = { ...tempListState1[index] };

          tempListElement1.uploadStatus =
            ApiConstant.UPLOAD_DOCUMENT_ITEM_PROGRESS_STATUS;

          tempListState1[index] = tempListElement1;

          setPageState({ ...pageState, uploadedFileList: tempListState1 });
          const options = (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);

            // console.log(loaded, total, percent)
            const tempListState2 = [...pageState?.uploadedFileList];
            const tempListElement2 = { ...tempListState2[index] };

            tempListElement2.uploadStatus =
              ApiConstant.UPLOAD_DOCUMENT_ITEM_PROGRESS_STATUS;
            tempListElement2.progress = percent;

            tempListState2[index] = tempListElement2;

            setPageState({ ...pageState, uploadedFileList: tempListState2 });
          };

          let fromDate = new FormData();
          fromDate.append("files", filterdDocument?.file);

          // const request = {
          //     attachmentName: filterdDocument?.name || "",
          //     fileType: filterdDocument?.name?.split(".")[1],
          //     documentType: pageState?.documentTypeLovValue,
          //     workOrder_Id: workOrderDetails?.itemID,
          //     comments: "Uploaded By " + userDataObj?.userData?.activeUserData?.userData?.userId + " From frontend",
          //     UploadedBy: userDataObj?.userData?.activeUserData?.userData?.userId,
          //     // base64Document: filterdDocument?.base64Document
          //     file: filterdDocument?.file
          // };
          const request = {
            fromDate: fromDate,
            orderId: workOrderDetails?.itemID,
            documentType: pageState?.documentTypeLovValue,
          };

          DocumentManagement1(
            "/fibre/file/sftpupload",
            request,
            header,
            options
          )
            .then((response) => {
              const tempListState = [...pageState?.uploadedFileList];
              const tempListElement = { ...tempListState[index] };

              tempListElement.uploadStatus =
                ApiConstant.UPLOAD_DOCUMENT_ITEM_COMPLETE_STATUS;
              tempListElement.docReferenceId = response[0]?.docReferenceId;

              tempListState[index] = tempListElement;
              uploadedfilesList.push(tempListElement);

              setPageState({
                ...pageState,
                uploadedFileList: uploadedfilesList,
              });
            })
            .catch((error) => {
              const tempListState = [...pageState?.uploadedFileList];
              const tempListElement = { ...tempListState[index] };

              tempListElement.uploadStatus =
                ApiConstant.UPLOAD_DOCUMENT_ITEM_NOT_COMPLETE_STATUS;

              tempListState[index] = tempListElement;

              setPageState({ ...pageState, uploadedFileList: tempListState });
            });
        }
      });
    } else {
      toast.warning(ApiConstant.UPLOAD_FILE_VALIDATION_WARNING_MESSAGE, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 10000,
        theme: "colored",
      });
    }
  }
  return (
    <div
      className="modal fade upload-document-modal"
      id="uploadDocumentModal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby="uploadDocumentModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pb-0">
            <div className="wrapper-modal-header pt-4">
              <div className="modal-header-title-section">
                <h5 className="modal-title" id="uploadDocumentModalLabel">
                  {Constent.UPLOAD_DOCUMENT_MODAL_TITLE}
                </h5>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <CloseModalIcon />
                </span>
              </button>
            </div>
          </div>
          <div className="modal-body pt-0">
            <FileUploadModalDragAndDropSection
              pageState={pageState}
              setPageState={setPageState}
              attachUploadedDocument={attachUploadedDocument}
            />
            <FileUploadModalViewSection
              pageState={pageState}
              setPageState={setPageState}
            />
          </div>
          <div className="modal-footer">
            <div className="wrapper-modal-footer-section">
              <div className="wrapper-modal-footer-note-title">
                {Constent.UPDATE_WORK_ORDER_MODAL_NOTE_TITLE1}
              </div>
              <div className="wrapper-modal-footer-note-msg">
                {Constent.UPDATE_WORK_ORDER_UPLOAD_DOUMENT_MODAL_NOTE_MSG}
              </div>
            </div>
            <div className="wrapper-modal-footer-section">
              <div className="refresh-btn-section" data-dismiss="modal">
                {Constent.CANCEL_BTN}
              </div>
              <div
                className="save-btn-section active-save-btn-section"
                onClick={uploadDocuments}
              >
                {Constent.UPLOAD_BTN1}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDocumentModal;
