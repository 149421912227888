import moment from 'moment';
import React from 'react';
import DatePicker from 'react-date-picker';

import { useSelector, useDispatch } from 'react-redux';
import { ApiConstant } from '../../Assets/Constants/ApiConstants';
import { Constent } from '../../Assets/Constants/Constent';
import { CalendarIcon, CloseModalIcon, SearchIcon } from '../../Assets/Images/CelcomSvgImages';
import { setTrackOrderFieldValues } from '../../ReduxFeature/StateFeatures/TrackOrderDataState';

const TrackOrderAdvanceSearchModal = ({ pageState, trackOrderApiCalls, trackOrderDataObj, lovDataObj, inputBtnDisabled }) => {
    const dispatch = useDispatch();
    const { ordercontext, orderstatus, ordersearchtype } = lovDataObj;
    const { searchType, fromDateValue, toDateValue, orderStatusValue, orderContextValue, orderNumberValue, customerIdValue, srvcAttrValValue } = trackOrderDataObj;
    const searchTypeIndex = (ordersearchtype?.findIndex(obj => obj?.value === searchType) || 0);

    const textFieldValue = ordersearchtype[searchTypeIndex]?.value === ApiConstant.TRACK_ORDER_ORDER_ID_VALUE ? orderNumberValue :
        ordersearchtype[searchTypeIndex]?.value === ApiConstant.TRACK_ORDER_CUSTOMER_ID_VALUE ? customerIdValue :
            ordersearchtype[searchTypeIndex]?.value === ApiConstant.TRACK_ORDER_SERVICE_VAL_VALUE && srvcAttrValValue;


    return (
        <div className="modal fade advance-search-work-order-modal" id="trackOrderAdvanceSearch" data-backdrop="static"
            data-keyboard="false" tabIndex="-1" aria-labelledby="trackOrderAdvanceSearchLabel"
            aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header pb-0">
                        <div className="wrapper-modal-header pt-5">
                            <div className="modal-header-title-section">
                                <h5 className="modal-title" id="trackOrderAdvanceSearchLabel">
                                    {Constent.WORK_ORDER_ADVANCED_SEARCH_MODAL_TITLE}
                                </h5>
                                <SearchIcon stroke={"#00000080"} />
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <CloseModalIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="wrapper-modal-body">
                            <div className="mobal-body-section-group">
                                <div className="card-item-group">
                                    <div className="form-group m-0 custom-drop-down-form-group">
                                        <select className="custom-select" id="orderContext" value={orderContextValue} onChange={(e) => {
                                            dispatch(setTrackOrderFieldValues({
                                                type: ApiConstant.TRACK_ORDER_CONTEXT_VALUE,
                                                value: e.target.value
                                            }));
                                        }}>
                                            <option defaultValue="" value="">-- Select --</option>
                                            {
                                                ordercontext?.map((data, index) => {
                                                    return <option key={`advanceSearchLovs${index}`} value={`${data?.value}`}>
                                                        {data?.display}
                                                    </option>
                                                })
                                            }
                                        </select>
                                        <label htmlFor="orderContext">{Constent.TRACK_ORDER_CARD_ITEM_TITLE2}<span>*</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="mobal-body-section-group">
                                <div className="card-item-group">
                                    <div className="form-group m-0 custom-drop-down-form-group">
                                        <select className="custom-select" value={searchType} disabled={inputBtnDisabled}
                                            onChange={(e) => {
                                                dispatch(setTrackOrderFieldValues({
                                                    type: ApiConstant.TRACK_ORDER_SEARCH_TYPE_VALUE,
                                                    value: e.target.value
                                                }));
                                            }}>
                                            <option defaultValue="" value="">-- Select --</option>
                                            {
                                                ordersearchtype?.map((data, index) => {
                                                    return <option key={`selectSearchType${index}`}
                                                        value={`${data?.value}`}>
                                                        {data?.display}
                                                    </option>
                                                })
                                            }
                                        </select>
                                        <label htmlFor="searchType">{Constent.SELECT_OPTION_DROP_DOWN}</label>
                                    </div>
                                </div>
                            </div>
                            {
                                searchType === "ORDERSTATUS" ?
                                    <div className="mobal-body-section-group">
                                        <div className="card-item-group">
                                            <div className="form-group m-0 custom-drop-down-form-group">
                                                <select className="custom-select" id="orderStatus" value={orderStatusValue}
                                                    disabled={inputBtnDisabled} onChange={(e) => {
                                                        dispatch(setTrackOrderFieldValues({
                                                            type: ApiConstant.TRACK_ORDER_STATUS_VALUE,
                                                            value: e.target.value
                                                        }));
                                                    }}>
                                                    <option defaultValue="" value="">-- Select --</option>
                                                    {
                                                        orderstatus?.map((data, index) => {
                                                            return <option key={`advanceSearchLovs${index}`} value={`${data?.value}`}>
                                                                {data?.display}
                                                            </option>
                                                        })
                                                    }
                                                </select>
                                                <label htmlFor="orderStatus">{Constent.TRACK_ORDER_CARD_ITEM_TITLE7}</label>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="mobal-body-section-group">
                                        <div className="card-item-group">
                                            <div className="form-group mb-0 custom-input-form-group ">
                                                <input type="text" className="form-control" value={textFieldValue}
                                                    disabled={inputBtnDisabled} onChange={e => {
                                                        dispatch(setTrackOrderFieldValues({
                                                            type: ordersearchtype[searchTypeIndex]?.value,
                                                            value: e.target.value
                                                        }));
                                                    }}
                                                    id="trackOrderInput" placeholder=" " />
                                                <label htmlFor="trackOrderInput">{ordersearchtype[searchTypeIndex]?.display}</label>
                                                <span className='invalid-msg-text'>{Constent.INVALID_INPUT}</span>
                                            </div>
                                        </div>
                                    </div>
                            }
                            <div className="mobal-body-section-group">
                                {Constent.FREE_CONTENT_TEXT2}
                            </div>
                            <div className="mobal-body-section-group">
                                <div className="card-item-group p-0">
                                    <div className="form-group mb-0 custom-input-form-group">
                                        <div className="input-group">
                                            <input type="text" className="form-control" id="formDate" disabled={inputBtnDisabled}
                                                onChange={e => {
                                                    dispatch(setTrackOrderFieldValues({
                                                        type: ApiConstant.TRACK_ORDER_FORM_DATE_VALUE,
                                                        value: e.target.value
                                                    }));
                                                }} value={fromDateValue} placeholder=" " />
                                            <label htmlFor="formDate">{Constent.DATE_RANGE_INPUT_ITEM_LABEL1}</label>
                                            <div className="input-group-append">
                                                <span className={`input-group-text ${inputBtnDisabled ? "custom-input-group-disabled" : ""}`}>
                                                    <CalendarIcon fill={'#333333'} />
                                                </span>
                                                <div className="input-group-append-date-picker-section">
                                                    <DatePicker selected={moment()} disableCalendar={inputBtnDisabled} onChange={date => {
                                                        dispatch(setTrackOrderFieldValues({
                                                            type: ApiConstant.TRACK_ORDER_FORM_DATE_VALUE,
                                                            value: moment(date)?.format("YYYY-MM-DD")
                                                        }));
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                        <span className='invalid-msg-text'>{Constent.INVALID_INPUT}</span>
                                    </div>
                                </div>
                                <div className="card-item-group p-0">
                                    <div className="form-group mb-0 custom-input-form-group">
                                        <div className="input-group">
                                            <input type="text" className="form-control" id="toDate" value={toDateValue}
                                                disabled={inputBtnDisabled} onChange={e => {
                                                    dispatch(setTrackOrderFieldValues({
                                                        type: ApiConstant.TRACK_ORDER_TO_DATE_VALUE,
                                                        value: e.target.value
                                                    }));
                                                }}
                                                placeholder=" " />
                                            <label htmlFor="toDate">{Constent.DATE_RANGE_INPUT_ITEM_LABEL2}</label>
                                            <div className="input-group-append">
                                                <span className={`input-group-text ${inputBtnDisabled ? "custom-input-group-disabled" : ""}`}>
                                                    <CalendarIcon fill={'#333333'} />
                                                </span>
                                                <div className="input-group-append-date-picker-section">
                                                    <DatePicker selected={moment()} disableCalendar={inputBtnDisabled} onChange={date => {
                                                        dispatch(setTrackOrderFieldValues({
                                                            type: ApiConstant.TRACK_ORDER_TO_DATE_VALUE,
                                                            value: moment(date)?.format("YYYY-MM-DD")
                                                        }));
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                        <span className='invalid-msg-text'>{Constent.INVALID_INPUT}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="refresh-btn-section" onClick={() => {
                            dispatch(setTrackOrderFieldValues({
                                type: ApiConstant.TRACK_ORDER_RESET_VALUE,
                                value: null
                            }));
                        }}>
                            <span>{Constent.REFRESH_BTN}</span>
                        </div>
                        <div className={`search-btn-section ${inputBtnDisabled ? "custom-disable-btn": ""}`} data-dismiss={inputBtnDisabled ? null : "modal"} onClick={() => inputBtnDisabled ? null : trackOrderApiCalls()}>
                            <SearchIcon />
                            {Constent.SEARCH_BTN}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrackOrderAdvanceSearchModal;