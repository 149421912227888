import moment from "moment";
import React from "react";
import DatePicker from "react-date-picker";
import { Constent } from "../../../Assets/Constants/Constent";
import {
  CalendarIcon,
  DownloadIcon,
  SearchIcon,
} from "../../../Assets/Images/CelcomSvgImages";
import { BodyBreadcrumbs2 } from "../../Common/BodyBreadcrumbs";
import {
  DonloadExcelFile,
  EmptyValueHandler,
  SupervisorWorkOrderStatusCal,
} from "../../Common/GenericFunctions";

export default function ViewWorkorderReportBodyHearder({
  globalState,
  pageState,
  setPageState,
  reportExtractionAPICall,
  userDataObj,
}) {
  const filterList = [1, 2];

  const downloadExcelFun = () => {
    // const fileName =
    //   "ReportList_" +
    //   (pageState?.workOrderFromDate || moment().format("YYYY-MM-DD")) +
    //   "-to-" +
    //   (pageState?.workOrderToDate || moment().format("YYYY-MM-DD"));
    let fileName = "";
    if (
      userDataObj?.userData?.activeUserData?.userRole === Constent.ADMIN_ROLE
    ) {
      fileName =
        "CFMP_SuperAdmin_" +
        (pageState?.workOrderFromDate || moment().format("YYYY-MM-DD")) +
        "-to-" +
        (pageState?.workOrderToDate || moment().format("YYYY-MM-DD"));
    } else {
      fileName =
        "CFMP_Supervisor_" +
        (pageState?.workOrderFromDate || moment().format("YYYY-MM-DD")) +
        "-to-" +
        (pageState?.workOrderToDate || moment().format("YYYY-MM-DD"));
    }
    // Request Stock
    //Sl, Material Code, Item Name, Status, Type, Category, Brand, Request Qty, Approve Qty, Received Qty, Transferred Qty
    const downloadData = [];
    pageState?.workOrderListData?.map((data, index) => {
      const { status } = SupervisorWorkOrderStatusCal(data) || { status: "" };
      const downloadContent = {
        SNo: EmptyValueHandler({ item: data?.slNo }),
        access_provider: EmptyValueHandler({ item: data?.accessProvider }),
        // outlet_id: EmptyValueHandler({ item: data?.accessProvider }),
        appointment_date: EmptyValueHandler({ item: data?.appointmentDate }),
        order_no: EmptyValueHandler({ item: data?.orderNo }),

        work_order_id: EmptyValueHandler({ item: data?.workOrderId }),
        status: EmptyValueHandler({ item: data?.workOrderStatus }),
        appointment_type: EmptyValueHandler({ item: data?.appointmentType }),
        appointment_sub_type: EmptyValueHandler({
          item: data?.appointmentSubType,
        }),

        customer_name: EmptyValueHandler({ item: data?.customerName }),
        customer_id: EmptyValueHandler({ item: data?.customerId }),
        customer_contact_number: EmptyValueHandler({
          item: data?.customerContactNo,
        }),
        customer_address_type: EmptyValueHandler({
          item: data?.customerAddressType,
        }),
        customer_address: EmptyValueHandler({ item: data?.customerAddress }),
        plan: EmptyValueHandler({ item: data?.plan }),
        hsi_username: EmptyValueHandler({ item: data?.hsiUsername }),
        notes_for_installer: EmptyValueHandler({ item: data?.installerNotes }),
        installer_name: EmptyValueHandler({ item: data?.installerName }),
        installer_contact_number: EmptyValueHandler({
          item: data?.installerContactNo,
        }),
        installer_closing_date_and_time: EmptyValueHandler({
          item: data?.installationCloseTimestamp,
        }),
        activity_closing_date_and_time: EmptyValueHandler({
          item: data?.installerClosingDateAndTime,
        }),
        rgw_serial_number: EmptyValueHandler({ item: data?.rgwSlNo }),
        mesh_serial_number: EmptyValueHandler({ item: data?.meshSerialNumber }),
        btu_serial_number: EmptyValueHandler({ item: data?.butSlNo }),
        desired_installation: EmptyValueHandler({
          item: data?.desiredInstallation,
        }),
        additional_cabling: EmptyValueHandler({
          item: data?.additionalCabling,
        }),
        return_remarks: EmptyValueHandler({ item: data?.returnRemarks }),
        return_remarks_additional_info: EmptyValueHandler({
          item: data?.returnRemarksAdditionalInfo,
        }),
        ost_element: EmptyValueHandler({ item: data?.ostElement }),
        ost_resolution: EmptyValueHandler({ item: data?.ostResolution }),
        ost_problem_cause: EmptyValueHandler({ item: data?.ostProblemCause }),
      };
      if (
        userDataObj?.userData?.activeUserData?.userRole === Constent.ADMIN_ROLE
      ) {
        downloadContent["company_name"] = EmptyValueHandler({
          item: data?.companyName,
        });
      }
      downloadData.push(downloadContent);
    });

    /* Download Excel Function */
    DonloadExcelFile({
      fileName: fileName,
      downloadData: downloadData,
    });
  };

  return (
    <div className="workorder-header-start-section">
      <div className="wrapper-workorder-header-section">
        <BodyBreadcrumbs2
          secondItem={Constent.HEADER_MENU_ITEM1}
          activeItem={Constent.BODY_HEADER_VIEW_WORKORDER_REPORT}
          secondTab="/workorder"
        />
        <div
          className="inventory-header-pill-section mb-3"
          data-aos="fade-right"
          data-aos-easing="ease-in-out"
          data-aos-delay="100"
        >
          {userDataObj?.userData?.activeUserData?.userRole ===
          Constent.INSTALLER_ROLE ? (
            <></>
          ) : (
            <div className="nav-responsive">
              <div className="nav nav-pills nav-child-2 position-relative text-center defualt-custom-pill-transition">
                <a
                  className={
                    pageState?.activePill ===
                    Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM1
                      ? "nav-link col active"
                      : "nav-link col"
                  }
                  onClick={() => {
                    setPageState({
                      ...pageState,
                      activePill:
                        Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM1,
                    });
                  }}
                  data-toggle="tab"
                  href={"#" + Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM1}
                >
                  {Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM1}
                </a>
                <a
                  className={
                    pageState?.activePill ===
                    Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM2
                      ? "nav-link col active"
                      : "nav-link col"
                  }
                  onClick={() => {
                    setPageState({
                      ...pageState,
                      activePill:
                        Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM2,
                    });
                  }}
                  data-toggle="tab"
                  href={"#" + Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM2}
                >
                  {Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM2}
                </a>
                <hr className="tab-active defualt-custom-pill-transition" />
              </div>
            </div>
          )}
        </div>
        <div className="workorder-header-search-group-section">
          <div
            className={`${
              globalState?.sidebarState ? "col-xxl-12" : "col-xxl-10"
            } col-xl-12 wrapper-workorder-header-search-group`}
          >
            <div className="workorder-header-search-group-row-section">
              {Constent.BODY_HEADER_VIEW_WORKORDER_REPORT_SEARCHCARD}
              <SearchIcon stroke={"#CCCCCC"} />
            </div>

            <div className="workorder-header-search-group-row-section view-workorder-report-header-search-group-row-section">
              <div className="view-workorder-report-search-group-section">
                <div className="view-workorder-report-serch-item-section">
                  <div className="workorder-search-inner-group-section">
                    <div
                      className={`form-group mb-0 custom-input-form-group ${
                        pageState?.formDateError?.length > 0 &&
                        "custom-invalid-form-group"
                      }`}
                    >
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="formDate"
                          placeholder=" "
                          value={pageState?.workOrderFromDate}
                          onChange={(e) => {
                            setPageState({
                              ...pageState,
                              workOrderFromDate: e.target.value,
                            });
                          }}
                        />
                        <label htmlFor="formDate">
                          {Constent.DATE_RANGE_INPUT_ITEM_LABEL1}
                        </label>
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <CalendarIcon fill={"#333333"} />
                          </span>
                          <div className="input-group-append-date-picker-section">
                            <DatePicker
                              selected={moment()}
                              onChange={(date) => {
                                setPageState({
                                  ...pageState,
                                  workOrderFromDate:
                                    moment(date)?.format("YYYY-MM-DD"),
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <span className="invalid-msg-text">
                        {pageState?.formDateError}
                      </span>
                    </div>
                  </div>
                  <div className="workorder-search-inner-group-section">
                    <div
                      className={`form-group mb-0 custom-input-form-group ${
                        pageState?.toDateError?.length > 0 &&
                        "custom-invalid-form-group"
                      }`}
                    >
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="toDate"
                          placeholder=" "
                          pattern="[0-9]{4,6}"
                          value={pageState?.workOrderToDate}
                          onChange={(e) => {
                            setPageState({
                              ...pageState,
                              workOrderToDate: e.target.value,
                            });
                          }}
                        />
                        <label htmlFor="toDate">
                          {Constent.DATE_RANGE_INPUT_ITEM_LABEL2}
                        </label>
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <CalendarIcon fill={"#333333"} />
                          </span>
                          <div className="input-group-append-date-picker-section">
                            <DatePicker
                              selected={moment()}
                              onChange={(date) => {
                                setPageState({
                                  ...pageState,
                                  workOrderToDate:
                                    moment(date)?.format("YYYY-MM-DD"),
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <span className="invalid-msg-text">
                        {pageState?.toDateError}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="workorder-search-group-section"
                onClick={reportExtractionAPICall}
              >
                <SearchIcon />
                {Constent.SEARCH_BTN}
              </div>
            </div>
            <div className="workorder-header-search-group-row-section">
              <div className="workorder-header-advance-search-section">
                <span
                  data-toggle="modal"
                  data-target="#viewWorkOrderReportAdvanceSearch"
                >
                  {Constent.BODY_HEADER_DASHBORD_BANNAR_SUB_TEXT1}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pageState?.workOrderListData?.length > 0 && (
        <div className="dashbord-header-filter-section">
          <div className="dashbord-header-filter-itme-info d-flex">
            <div className="order-header-filter-item-info">
              {pageState?.workOrderListData?.length > 0 && (
                <strong>{`${pageState?.workOrderListData?.length} - ${Constent.REPORT}`}</strong>
              )}
            </div>
            <div className="order-header-download-item-section">
              <div
                className="item-list-download-btn-section"
                onClick={downloadExcelFun}
              >
                <span>{Constent.DOWNLOAD_BTN}</span>
                <DownloadIcon />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
