import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Constent } from "../../Assets/Constants/Constent";
import { HeaderNavChild3 } from "../../Components/Common/HeaderNav";
import Footer from "../../Components/Footer/Footer";
import { Header } from "../../Components/Header/Header";
import TrackOrderDetailsBodyContent from "../../Components/MainBody/BodyContent/Supervisor/TrackOrderDetailsBodyContent";
import SupervisorSidebar from "../../Components/MainBody/Sidebar/SupervisorSidebar";
import { ToastContainer, toast } from "react-toastify";

import "../../Assets/CSS/TrackOrderDetailsPage.css";
import { headers, TrackOrderDetails } from "../../Api/ApiCalls";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";

const TrackOrderDetailsPage = ({ globalState, setGlobalState }) => {
  const userDataObj = useSelector((state) => state.userDataInfo);
  const trackOrderDataObj = useSelector((state) => state.trackOrderDataInfo);

  const { selectedTrackOrderData } = trackOrderDataObj;

  const [pageState, setPageState] = useState({
    dataLoader: false,

    trackOrderDetailsData: null,
    // Error Part
    getTrackOrderDetailsDataError: false,
    getTrackOrderDetailsDataErrorMsg: "",
  });

  function ErrorMessageSetUp({ error, message1, message2 }) {
    let errorMessage;
    if (error.response.status === 500 || error.response.status === 503) {
      errorMessage = message1;
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    } else if (error.response.status === 404) {
      errorMessage = message2;
      toast.warning(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    } else {
      errorMessage = error.response.statusText;
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    }
    return errorMessage;
  }
  function trackOrderDetailsApiCall() {
    setPageState({ ...pageState, dataLoader: true });
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);
    /*------validation need to implement-------*/
    const request = {
      orderId: selectedTrackOrderData?.orderId,
      outletId: userDataObj?.userData?.activeUserData?.userData?.outletId,
      userId: userDataObj?.userData?.activeUserData?.userData?.userId,
    };

    TrackOrderDetails("/fibre/order/details", request, header)
      .then((response) => {
        setPageState({
          ...pageState,
          trackOrderDetailsData: response,
          dataLoader: false,
          getTrackOrderDetailsDataError: false,
          getTrackOrderDetailsDataErrorMsg: "",
        });
      })
      .catch((error) => {
        const errorMessage = ErrorMessageSetUp({
          error: error,
          message1: ApiConstant.TRACK_ORDER_LIST_ERROR_MESSAGE,
          message2: ApiConstant.TRACK_ORDER_WARNING_MESSAGE,
        });
        setPageState({
          ...pageState,
          dataLoader: false,
          trackOrderDetailsData: null,
          getTrackOrderDetailsDataError: true,
          getTrackOrderDetailsDataErrorMsg: errorMessage,
        });
      });
  }

  useEffect(() => {
    trackOrderDetailsApiCall();
  }, []);

  return (
    <div className="page-start-section">
      <Header
        userDataObj={userDataObj}
        globalState={globalState}
        setGlobalState={setGlobalState}
        tab2={true}
      />
      <div
        className={
          globalState?.sidebarState
            ? "body-start-section sidebar-active-section"
            : "body-start-section"
        }
      >
        {/* Sidebar */}
        <SupervisorSidebar
          userDataObj={userDataObj}
          globalState={globalState}
          setGlobalState={setGlobalState}
        />
        <div className="body-full-content-section">
          <HeaderNavChild3
            secondItem={Constent.HEADER_MENU_ITEM2}
            secondTab={"/trackorder"}
            activeItem={Constent.BODY_HEADER_TRACKORDER_DETAILS_PATH}
          />
          <TrackOrderDetailsBodyContent
            userDataObj={userDataObj}
            globalState={globalState}
            pageState={pageState}
            setPageState={setPageState}
            selectedTrackOrderData={selectedTrackOrderData}
          />
        </div>
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};

export default TrackOrderDetailsPage;
