import { createSlice } from "@reduxjs/toolkit";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";

const initialState = {
    searchType: "ORDERID",
    fromDateValue: "",
    toDateValue: "",
    orderStatusValue: "",
    orderContextValue: "",
    orderNumberValue: "",
    customerIdValue: "",
    srvcAttrKeyValue: "",
    srvcAttrValValue: "",

    trackOrderListData: [],
    selectedTrackOrderData: null
}
export const TrackOrderDataState = createSlice({
    name: 'TrackOrderDataState',
    initialState,
    reducers: {
        // Creating the Actions
        setTrackOrderFieldValues: (state, action) => {
            const { type, value } = action?.payload;
            switch (type) {
                case ApiConstant.TRACK_ORDER_SEARCH_TYPE_VALUE:
                    return { ...state, searchType: value };
                case ApiConstant.TRACK_ORDER_STATUS_VALUE:
                    return {
                        ...state, orderStatusValue: value, orderNumberValue: "", customerIdValue: "", srvcAttrKeyValue: "",
                        srvcAttrValValue: "",
                    };
                case ApiConstant.TRACK_ORDER_FORM_DATE_VALUE:
                    return { ...state, fromDateValue: value };
                case ApiConstant.TRACK_ORDER_TO_DATE_VALUE:
                    return { ...state, toDateValue: value };
                case ApiConstant.TRACK_ORDER_CONTEXT_VALUE:
                    return { ...state, orderContextValue: value };
                case ApiConstant.TRACK_ORDER_CUSTOMER_ID_VALUE:
                    return {
                        ...state, customerIdValue: value, orderNumberValue: "", orderStatusValue: "", srvcAttrKeyValue: "",
                        srvcAttrValValue: "",
                    };
                case ApiConstant.TRACK_ORDER_ORDER_ID_VALUE:
                    return {
                        ...state, orderNumberValue: value, customerIdValue: "", orderStatusValue: "", srvcAttrKeyValue: "",
                        srvcAttrValValue: "",
                    };
                case ApiConstant.TRACK_ORDER_SERVICE_KEY_VALUE:
                    return { ...state, srvcAttrKeyValue: value };
                case ApiConstant.TRACK_ORDER_SERVICE_VAL_VALUE:
                    return { ...state, srvcAttrValValue: value };

                case ApiConstant.TRACK_ORDER_RESET_VALUE:
                    return {
                        ...state, searchType: "ORDERID",
                        fromDateValue: "",
                        toDateValue: "",
                        orderStatusValue: "",
                        orderContextValue: "",
                        orderNumberValue: "",
                        customerIdValue: "",
                        srvcAttrKeyValue: "",
                        srvcAttrValValue: "",
                    };

                default:
                    return { ...state };
            }
        },
        setTrackOrderData: (state, action) => {
            const { type, data } = action.payload;
            switch (type) {
                case ApiConstant.TRACK_ORDER_LIST_DATA:
                    return { ...state, trackOrderListData: data };
                case ApiConstant.TRACK_ORDER_SELECTED_DATA:
                    return { ...state, selectedTrackOrderData: data };
                case ApiConstant.TRACK_ORDER_RESET_LIST_DATA:
                    return { ...state, trackOrderListData: null, selectedTrackOrderData: null };
                case ApiConstant.TRACK_ORDER_RESET_SELECTED_DATA:
                    return { ...state, selectedTrackOrderData: null };
                default:
                    return { ...state }

            }
        },
        /* ---- Reset Data ---- */
        resetTrackOrderData: () => initialState
    }
});

export const { setTrackOrderFieldValues, setTrackOrderData, resetTrackOrderData } = TrackOrderDataState.actions;

export default TrackOrderDataState.reducer;
