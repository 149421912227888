import React from 'react';
import { useSelector } from 'react-redux';
import { Constent } from '../../../../../Assets/Constants/Constent';
import { ClockIcon, LocationIcon } from '../../../../../Assets/Images/CelcomSvgImages';

const UpdateLiveLocationContent = () => {

    const workOrderDataObj = useSelector((state) => state.workOrderInfo);

    const { locationAddress, locationCaptureTime } = workOrderDataObj;


    return (
        <div className="update-work-order-content-item-group">
            <div className="wrapper-update-work-order-content-item-group">
                <div className="update-work-order-content-item">
                    <div className="update-work-order-content-inner-item-section">
                        <LocationIcon />
                    </div>
                    <div className="update-work-order-content-inner-item-section">
                        <div className="update-work-order-content-inner-item">
                            {Constent.UPDATE_WORK_ORDER_LONATION_LABLE}
                        </div>
                        <div className="update-work-order-content-inner-item">
                            {locationAddress || "-"}
                        </div>
                    </div>
                </div>
                <div className="update-work-order-content-item">
                    <div className="update-work-order-content-inner-item-section"><ClockIcon /></div>
                    <div className="update-work-order-content-inner-item-section">
                        <div className="update-work-order-content-inner-item">
                            {Constent.UPDATE_WORK_ORDER_TIME_TITLE}
                        </div>
                        <div className="update-work-order-content-inner-item">
                            {locationCaptureTime?.split(" ")[1] || "-"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateLiveLocationContent;