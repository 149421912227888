import React from "react";
import { NoDataFoundItemGroup } from "../../../Common/CardContent";
import { CardLoader } from "../../../Loaders/Loaders";
import WorkOrderCard from "../../Cards/Supervisor/WorkOrderCard";

const SearchActivityBodyContent = ({
  setGlobalState,
  globalState,
  pageState,
  setPageState,
  workOrderDataObj,
  filterList,
}) => {
  return (
    <div className="body-content-section custom-scroll-bar-section">
      {
        <div className="wrapper-workorder-body-content-section">
          {pageState?.dataLoader ? (
            [0, 1].map((val) => {
              return <CardLoader key={`getInstallerCard${val}`} />;
            })
          ) : pageState?.getDataError ? (
            <NoDataFoundItemGroup message={pageState?.getDataErrorMsg} />
          ) : (
            filterList?.map((workOrderData, index) => {
              return (
                <WorkOrderCard
                  globalState={globalState}
                  setGlobalState={setGlobalState}
                  key={`workOrderList${
                    workOrderData?.itemId +
                    workOrderData?.resolutionDate +
                    index
                  }`}
                  pageState={pageState}
                  setPageState={setPageState}
                  workOrderData={workOrderData}
                />
              );
            })
          )}
        </div>
      }
    </div>
  );
};

export default SearchActivityBodyContent;
