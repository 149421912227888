import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Header } from '../../Components/Header/Header';
import SwitchUserBodyHeader from '../../Components/MainBody/BodyHeader/Admin/SwitchUserBodyHeader';
import SupervisorSidebar from '../../Components/MainBody/Sidebar/SupervisorSidebar';

import "../../Assets/CSS/SwitchUserPage.css"
import SwitchUserBodyContent from '../../Components/MainBody/BodyContent/Admin/SwitchUserBodyContent';
import SwitchUserModal from '../../Components/Modals/SwitchUserModal';
import Footer from '../../Components/Footer/Footer';
import { ApiActionLoader } from '../../Components/Loaders/ApiActionLoader';
import { Constent } from '../../Assets/Constants/Constent';
import { headers, SwitchSupervisorUser } from '../../Api/ApiCalls';

const SwitchUserPage = ({ globalState, setGlobalState }) => {
    const userDataObj = useSelector((state) => state.userDataInfo);

    const [pageState, setPageState] = useState({
        dataLoader: false,
        supervisorList: [],
        switchUserData: null,
        filterUserName: "",

        apiCallAction: false,
        apiCallActionMsg: "Switching..."
    });

    function switchUserApiCall(data) {
        setPageState({ ...pageState, switchUserData: data });
    }

    function switchSupervisorListApiCall() {
        setPageState({
            ...pageState, dataLoader: true,
            supervisorList: []
        });

        //  Header
        const header = headers(
            userDataObj?.userData?.activeUserData?.authToken
        );

        const switchSupervisorListRequest = {
            username: userDataObj?.userData?.loginUserData?.userData?.userId,
            outletId: userDataObj?.userData?.loginUserData?.userData?.outletId
        }

        SwitchSupervisorUser("/fibre/switch/supervisor", switchSupervisorListRequest, header).then(res => {
            setPageState({
                ...pageState, dataLoader: false,
                supervisorList: res?.filter(item => {
                    if (item?.userId !== userDataObj?.userData?.loginUserData?.userData?.userId) {
                        return item;
                    }
                })
            });
        }).catch(err => {
            setPageState({
                ...pageState, dataLoader: false,
                supervisorList: []
            });
        })
    }

    useEffect(() => {

        if (Constent.SUPER_ROLE === userDataObj?.userData?.loginUserData?.userRole) {
            switchSupervisorListApiCall()
        }
        else if (Constent.ADMIN_ROLE === userDataObj?.userData?.loginUserData?.userRole) {
            setPageState({ ...pageState, supervisorList: userDataObj?.userData?.loginUserData?.userData?.listOfSupervisors })
        }
    }, [])

    return (
        <div className='page-start-section'>
            {
                pageState?.apiCallAction && <ApiActionLoader msg={pageState?.apiCallActionMsg} />
            }
            <Header userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} tab6={true} />
            <div className={globalState?.sidebarState ? "body-start-section sidebar-active-section" : "body-start-section"}>
                {/* Sidebar */}
                <SupervisorSidebar userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} />
                {/* Body full content section */}
                <div className="body-full-content-section">
                    <SwitchUserBodyHeader globalState={globalState} setPageState={setPageState} pageState={pageState} />
                    <SwitchUserBodyContent pageState={pageState} switchUserApiCall={switchUserApiCall}
                        setPageState={setPageState} userDataObj={userDataObj} />
                </div>
                <Footer />
            </div>
            <SwitchUserModal userDataObj={userDataObj} pageState={pageState} setPageState={setPageState} />
        </div>
    )
}

export default SwitchUserPage;