import React from "react";
import { Constent } from "../../../../Assets/Constants/Constent";
import { BodyBreadcrumbs2 } from "../../../Common/BodyBreadcrumbs";

const TrackStockRequestDetailsBodyHeader = () => {
  return (
    <div className="body-header-start-section">
      <div className="wrapper-body-header-section pb-4 track-request-details-body-header-section">
        <BodyBreadcrumbs2
          activeItem={Constent.BODY_HEADER_TRACK_REQUEST_DETAILS}
          secondItem={Constent.HEADER_MENU_ITEM4}
          secondTab={"/inventory"}
        />
      </div>
    </div>
  );
};

export default TrackStockRequestDetailsBodyHeader;
