import React from 'react';
import InstallerDashbordBodyCalendar from './DashBordHalper/InstallerDashbordBodyCalendar';

const InstallerDashbordBodyContent = ({ pageState, workOrderDataObj, installerCalendatData }) => {
    return (
        <div className='installer-dashbord-body-content-start-section'>
            <div className="wrapper-installer-dashbord-body-content-start-section">
                <InstallerDashbordBodyCalendar pageState={pageState} workOrderDataObj={workOrderDataObj} installerCalendatData={installerCalendatData} />
            </div>
        </div>
    )
}

export default InstallerDashbordBodyContent;