import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { headers, UpdateStock1, UpdateStockEmail1 } from "../../Api/ApiCalls";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { Constent } from "../../Assets/Constants/Constent";
import {
  CloseModalIcon,
  ConfirmationIcon,
} from "../../Assets/Images/CelcomSvgImages";
import {
  ReturnPlaceOrderTable,
  TransferPlaceOrderTable,
} from "../Common/Table";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmationPageData } from "../../ReduxFeature/StateFeatures/CartDataState";
import {
  removeSelectedTransferStockData,
  removeTransferStockData,
} from "../../ReduxFeature/StateFeatures/InventoryDataState";

const TransferStockOrderPlaceSerialNo = ({
  selectedTransferStockList,
  transferSerialNumberObj,
  selectedTrackStockItem,
  pageState,
  setPageState,
  requestorOutletName,
  destinationOutletContact,
  destinationOutletName,
  requestorOutletContactNo,
  destinationOutletAdd,
  requestorOutletAdd,
  toggleStatus,
  setToggleStatus,
}) => {
  const userDataObj = useSelector((state) => state.userDataInfo);
  const invDataObj = useSelector((state) => state.inventoryDataInfo);

  const [flagQuantity, setFlagQuantity] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function transferStockSerialApiCall() {
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);
    let stockItem = [];
    selectedTransferStockList.itemDetails?.map((selectedItemNumberObj) => {
      let flag = true;
      let serialNumberList = [];
      transferSerialNumberObj[selectedItemNumberObj?.sapMaterialCode]?.map(
        (serialNumberItem) => {
          if (
            !(
              serialNumberItem?.valid === "valid" &&
              serialNumberItem?.serialNumber?.length > 0
            )
          ) {
            flag = false;
          } else {
            serialNumberList.push(serialNumberItem?.serialNumber);
          }
        }
      );
      if (flag) {
        stockItem.push({
          approvedQuantity: serialNumberList.length,
          receivedQuantity: serialNumberList.length,
          requestedQuantity: serialNumberList.length,
          sapMaterialCode: selectedItemNumberObj?.sapMaterialCode,
          serialNumber: serialNumberList,
          transferQuantity: serialNumberList.length,
        });
      }
    });

    let ItemDetailEmail = [];
    selectedTransferStockList.itemDetails?.map((selectedItemNumberObj) => {
      let flag = true;
      let serialNumberList = [];
      transferSerialNumberObj[selectedItemNumberObj?.sapMaterialCode]?.map(
        (serialNumberItem) => {
          if (
            !(
              serialNumberItem?.valid === "valid" &&
              serialNumberItem?.serialNumber?.length > 0
            )
          ) {
            flag = false;
          } else {
            serialNumberList.push(serialNumberItem?.serialNumber);
          }
        }
      );
      if (flag) {
        ItemDetailEmail.push({
          materialCode: selectedItemNumberObj?.sapMaterialCode,
          itemName: selectedItemNumberObj.deviceModelDesc,
          brand: selectedItemNumberObj.manufacturer,
          category: selectedItemNumberObj?.category,
          serialNumber: serialNumberList,
        });
      }
    });

    const request = {
      action: "UPDATE",
      approvalUser: ApiConstant.RETURN_STOCK_CART_REQUEST_BLANK_VALUE,
      approverComments: "Reason",
      receiveUser: null,
      receiverStore: "-",
      senderStore: userDataObj?.userData?.activeUserData?.userData?.outletId,
      shipUser: ApiConstant.RETURN_STOCK_CART_REQUEST_BLANK_VALUE,
      sourceSystem: ApiConstant.RETURN_STOCK_CART_REQUEST_SOURCE_SYSTEM,
      starterPackFlag: null,
      listOfItemDetailsRequest: stockItem,
      storeId: userDataObj?.userData?.activeUserData?.userData?.outletId,
      transactionNo: null,
      transferId: selectedTransferStockList.transferId,
      acknowledgeDetails: null,
      requestorOutletName: toggleStatus === true ? requestorOutletName : null,
      destinationOutletContact:
        toggleStatus === true ? destinationOutletContact : null,
      destinationOutletName:
        toggleStatus === true ? destinationOutletName : null,
      requestorOutletContactNo:
        toggleStatus === true ? requestorOutletContactNo : null,
      destinationOutletAdd: toggleStatus === true ? destinationOutletAdd : null,
      requestorOutletAdd: toggleStatus === true ? requestorOutletAdd : null,
      storeToStoreTransferViaSamart: toggleStatus === true ? "Y" : null,
      transferFromStoreAddress:
        toggleStatus === true ? requestorOutletAdd : null,
      transferToStoreAddress:
        toggleStatus === true ? destinationOutletAdd : null,
    };

    const request1 = {
      requestDate: selectedTransferStockList.createDate,
      plantNo: "NA",
      requestId: selectedTransferStockList.transferId,
      customerName: toggleStatus === true ? requestorOutletName : null,
      customerContact: toggleStatus === true ? requestorOutletContactNo : null,
      fromAddress: toggleStatus === true ? requestorOutletAdd : null,
      recipientContact: toggleStatus === true ? destinationOutletContact : null,
      recipientName: toggleStatus === true ? destinationOutletName : null,
      toAddress: toggleStatus === true ? destinationOutletAdd : null,
      itemDetails: ItemDetailEmail,
      quantity: ItemDetailEmail.reduce((prev, current) => {
        return prev + current.serialNumber.length;
      }, 0),
    };

    const flag = stockItem.reduce((prev, current) => {
      if (prev === false || current?.serialNumber?.length === 0) {
        return false;
      }
    }, true);

    if (flag !== false) {
      if (toggleStatus === true) {
        if (
          requestorOutletName?.length > 0 &&
          destinationOutletContact?.length > 0 &&
          destinationOutletName?.length > 0 &&
          requestorOutletContactNo?.length > 0 &&
          destinationOutletAdd?.length > 0 &&
          requestorOutletAdd?.length > 0 &&
          destinationOutletAdd?.length <= 250 &&
          requestorOutletAdd?.length <= 250
        ) {
          setPageState({ ...pageState, apiCallAction: true });
          UpdateStock1("/fibre/inventory/updateStock", request, header)
            .then((respose) => {
              setPageState({ ...pageState, apiCallAction: false });
              const errorCode = respose?.errorCode;
              if (errorCode === "Success") {
                dispatch(
                  setConfirmationPageData({
                    comment: Constent.UPDATE_TRANSFER_STOCK_SUCESSFULL_MSG,
                    listOfIds: [respose?.transferId],
                  })
                );
                setPageState({ ...pageState, apiCallAction: true });
                UpdateStockEmail1(
                  "/fibre/emailwithattachment",
                  request1,
                  header
                )
                  .then((respose) => {
                    setPageState({ ...pageState, apiCallAction: false });
                    const errorCode = respose?.emailSuccess;
                    if (errorCode === true) {
                      toast.success("Email Sent");
                    } else {
                      toast.error("Email Not Sent", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 10000,
                        theme: "colored",
                      });
                    }
                  })
                  .catch((error) => {
                    setPageState({ ...pageState, apiCallAction: false });
                    let errorMessage;
                    if (
                      error.response.status === 500 ||
                      error.response.status === 503
                    ) {
                      errorMessage = error.message;
                      toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 10000,
                        theme: "colored",
                      });
                    } else {
                      errorMessage = error.message;
                      toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 10000,
                        theme: "colored",
                      });
                    }
                  }) &&
                  selectedTransferStockList?.itemDetails?.map((data) => {
                    dispatch(removeSelectedTransferStockData(data));
                    dispatch(removeTransferStockData(data));
                  }) &&
                  navigate("/cart/confirm");
              } else {
                toast.error(respose?.errorMessage, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 10000,
                  theme: "colored",
                });
              }
            })
            .catch((error) => {
              setPageState({ ...pageState, apiCallAction: false });
              let errorMessage;
              if (
                error.response.status === 500 ||
                error.response.status === 503
              ) {
                errorMessage = error?.response?.data?.message;
                toast.error(errorMessage, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 10000,
                  theme: "colored",
                });
              } else {
                errorMessage = error?.response?.data?.message;
                toast.error(errorMessage, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 10000,
                  theme: "colored",
                });
              }
            });
        } else {
          toast.error(
            "Please Enter the SAMART Pick-up and Delivery Address should be less than 250 characters",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 10000,
              theme: "colored",
            }
          );
        }
      } else {
        setPageState({ ...pageState, apiCallAction: true });
        UpdateStock1("/fibre/inventory/updateStock", request, header)
          .then((respose) => {
            setPageState({ ...pageState, apiCallAction: false });
            const errorCode = respose?.errorCode;
            if (errorCode === "Success") {
              dispatch(
                setConfirmationPageData({
                  comment: Constent.UPDATE_TRANSFER_STOCK_SUCESSFULL_MSG,
                  listOfIds: [respose?.transferId],
                })
              );
              selectedTransferStockList?.itemDetails?.map((data) => {
                dispatch(removeSelectedTransferStockData(data));
                dispatch(removeTransferStockData(data));
              }) && navigate("/cart/confirm");
              toast.success("success");
            } else {
              toast.error(respose?.errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 10000,
                theme: "colored",
              });
            }
          })
          .catch((error) => {
            setPageState({ ...pageState, apiCallAction: false });
            let errorMessage;
            if (
              error.response.status === 500 ||
              error.response.status === 503
            ) {
              errorMessage = error?.response?.data?.message;
              toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 10000,
                theme: "colored",
              });
            } else {
              errorMessage = error?.response?.data?.message;
              toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 10000,
                theme: "colored",
              });
            }
          });
      }
    } else {
      toast.error(
        "Please Provide Serial Numbers Matching With The Requested Quantity",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 10000,
          theme: "colored",
        }
      );
    }
  }

  return (
    <div
      className="modal fade return-stock-place-order-modal"
      id="TransferStockOrderPlaceSerialNo"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby="TransferStockOrderPlaceSerialNoLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pb-0">
            <div className="wrapper-modal-header pt-5">
              <div className="modal-header-title-section">
                <ConfirmationIcon />
                <h5
                  className="modal-title"
                  id="TransferStockOrderPlaceSerialNoLabel"
                >
                  {Constent.CONFIRMATION_MODAL_TITLE}
                </h5>
                <h5 className="modal-title">
                  {Constent.TRANSFER_STOCK_PLACE_ORDER_MODAL_TITLE}
                </h5>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <CloseModalIcon />
                </span>
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="wrapper-modal-body">
              <TransferPlaceOrderTable
                title1={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE1}
                title2={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE2}
                title3={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE3}
                title4={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE4}
                title5={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE9}
                data={selectedTransferStockList}
                returnedSerialNumberList={transferSerialNumberObj}
              />
            </div>
          </div>
          <div className="modal-footer">
            <div className="refresh-btn-section" data-dismiss="modal">
              <span>{Constent.EDIT_QUANTITY_BTN}</span>
            </div>
            <div
              className="search-btn-section"
              data-dismiss="modal"
              onClick={transferStockSerialApiCall}
            >
              {Constent.CONFIRMATION_BTN}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferStockOrderPlaceSerialNo;
