const ADMIN_ROLE = "ADMIN";
const SUPER_ROLE = "SUPER";
const INSTALLER_ROLE = "INSTALLER";

/*Session Expire Time */
const SESSION_EXPIRE = 15 * 60 * 1000;
const SESSION_EXPIRE_TIMEOUT_MESSAGE = 2 * 60 * 1000;

/*BTU Serial Number Max */
const BTU_SERIAL_NUMBER_MIN = 12;
const BTU_SERIAL_NUMBER_MAX = 100;

/*Row End in Retrieve Inventory Details*/
const ROW_END_RETRIEVE_DETAILS = 3000;

/*---- Dummy Values for Mail and SMS for  Revert Activity  Superadmin---*/
const SUPERADMINGMAIL = "dummy";
const SUPERADMINMOBILE = "dummy";

/* -------- Login Welcome Message ---------- */
const LOGIN_MESSAGE1 = "Welcome to";
const LOGIN_MESSAGE2 = "Celcom Fibre Management Portal";

/* -------- Header Section Constent ---------- */
const HEADER_MENU_ITEM1 = "Work Order";
const HEADER_MENU_ITEM2 = "Track Order";
const HEADER_MENU_ITEM3 = "Manage Installer";
const HEADER_MENU_ITEM4 = "Manage Inventory";
const HEADER_MENU_ITEM5 = "SWITCH";
const HEADER_MENU_ITEM5_SUB1 = "USER";
const HEADER_MENU_ITEM5_SUB2 = "BACK";
const HEADER_MENU_ITEM56 = "Logout";
const HEADER_MENU_ITEM6 = "Partner Installer Details Upload";
const HEADER_MENU_ITEM7 = "Search Partner Installer Details Upload"
const HEADER_MENU_ITEM67= "Upload Notes"
const HEADER_MENU_NOTES= "Notes"


/* -------- Body Section Constent ---------- */
/* -------- Body Header ---------- */
/* -------- Path ---------- */
const BODY_HEADER_DEFUALT_PATH = "Home";
const BODY_HEADER_CART_PATH = "Cart";
const BODY_HEADER_INVENTORY_SUB_PATH1 = "View Stock";
const BODY_HEADER_WORKORDER_DETAILS_PATH = "View Work Order Detail";
const BODY_HEADER_TRACKORDER_DETAILS_PATH = "Track Order Detail";
const BODY_HEADER_ON_SITE_SUMMARY = "On Site Summary";
const BODY_HEADER_SWITCH_USER = "Switch User";
const BODY_HEADER_TRACK_REQUEST_DETAILS = "Track Request Detail";
const BODY_HEADER_UPDATE_ACTIVITY = "Update Work Order";
const BODY_HEADER_ACKNOWLEDGE_PATH = "Acknowledge Stock";
const BODY_HEADER_VIEW_WORKORDER_REPORT = "View Report";

/* -------- UnderLine Nav ---------- */
const UNDERLINE_NAV_CART_ITEM1 = "Request Stock";
const UNDERLINE_NAV_CART_ITEM2 = "Return Stock";
const UNDERLINE_NAV_CART_ITEM3 = "Transfer Stock";

/* -------- Bannar ---------- */
const BODY_HEADER_DASHBORD_BANNAR_SUB_TEXT = "Hi";
const BODY_HEADER_DASHBORD_BANNAR_SUB_TEXT1 = "Advanced Search";
const BODY_HEADER_DASHBORD_BANNAR_TEXT =
  "Welcome to Celcom Fibre Management Portal";
const BODY_HEADER_INVENTORY_VIEW_STOCK_BANNAR_TEXT =
  "View Stock Inventory Detail";
const BODY_HEADER_ADMIN_DASHBORD_BANNAR_TEXT1 = "You are currently viewing";
const BODY_HEADER_ADMIN_DASHBORD_BANNAR_TEXT2 =
  "Account. Click ‘Switch Back’ to go back to your account.";
const BODY_HEADER_INVENTORY_ACKNOWLEDGE_BANNAR_TEXT = "Search by Serial Number";

const BODY_HEADER_UPDATE_WORKORDER_TEXT = "Update Work Order ID:";
const BODY_HEADER_UPDATE_WORKORDER_STEP_TEXT1 = "Capture Detail";
const BODY_HEADER_UPDATE_WORKORDER_STEP_TEXT2 = "Submit Work Order";
const BODY_HEADER_VIEW_WORKORDER_REPORT_SEARCHCARD = "Search Report by:";
const BODY_HEADER_PARTNER_UPLOAD = "Partner Installer Details Upload";

/* -------- Pill ---------- */
const BODY_HEADER_INVENTORY_PILL_ITEM1 = "Manage Stock";
const BODY_HEADER_INVENTORY_PILL_ITEM2 = "Track Request";
const BODY_HEADER_WORKORDERREPORT_PILL_ITEM1 = "Workorder ";
const BODY_HEADER_WORKORDERREPORT_PILL_ITEM2 = "View Report";
const Body_HEADER_PARTNERUPLOAD_PILL_ITEM1 = "Upload";
const Body_HEADER_PARTNERUPLOAD_PILL_ITEM2 = "View Notes";


const BODY_HEADER_SUMMARY_PILL_ITEM1 = "Installation";
const BODY_HEADER_SUMMARY_PILL_ITEM2 = "Troubleshooting";
const BODY_HEADER_SUMMARY_PILL_ITEM3 = "BTU Collections";

/* ---------- Search Box ---------- */
const BODY_HEADER_INVENTORY_SEARCH_TITLE1 = "Search Stock by:";
const BODY_HEADER_INVENTORY_SEARCH_TITLE2 = "Track Request by:";
const BODY_HEADER_MANAGE_INSTALLER_TITLE1 = "Available Installer";
const BODY_HEADER_MANAGE_INSTALLER_TITLE2 = "Add Installer";

const BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1 = "View Stock";
const BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM2 = "Request Stock";

/* -------- SideBar Content ---------- */
const CUSTOM_MONTH_PICKER_LABLE = "Select Month";

const SUPERVISOR_SIDEBAR_TITLE1 = "Onsite";
const SUPERVISOR_SIDEBAR_TITLE2 = "Summary";

const SUPERVISOR_SIDEBAR_OSI_TITLE = "Installation";
const SUPERVISOR_SIDEBAR_OST_TITLE = "Troubleshooting / Re-Configurations";
const SUPERVISOR_SIDEBAR_BTU_TITLE = "BTU Collections";

const SUPERVISOR_SIDEBAR_OSI_VALUE = "installation";
const SUPERVISOR_SIDEBAR_OST_VALUE = "troubleshooting";
const SUPERVISOR_SIDEBAR_BTU_VALUE = "btu_collection";

const SUPERVISOR_SIDEBAR_SUBTYPE1 = "In Progress";
const SUPERVISOR_SIDEBAR_SUBTYPE2 = "Aging";
const SUPERVISOR_SIDEBAR_SUBTYPE3 = "Done";
const SUPERVISOR_SIDEBAR_SUBTYPE4 = "Returned";
const SUPERVISOR_SIDEBAR_SUBTYPE5 = "Open";
const SUPERVISOR_SIDEBAR_SUBTYPE5_TEXT = "Not Started";

/* -------- Body Content ---------- */
/* --------  FREE Content ---------- */
const PROFILE_INFO_PERSONAL_DETAILS = "Personal Detail";
const PROFILE_INFO_USER_NAME = "User Name";
const PROFILE_INFO_USERID = "Id";
const PROFILE_INFO_OUTLET = "Outlet Id";
const PROFILE_INFO_EMAIL = "Outlet Id";
const PROFILE_INFO_ADDRESS = "Address";
const PROFILE_INFO_CONTACT = "Contact";
const REPORT = "Reports";
const INVENTORY_TRANSFER_STORE_ID_LABLE = "Request transfer from Store ID:";

const UPDATE_WORK_ORDER_CONTENT_TITLE1 = "Capture Work Order Detail";
const UPDATE_WORK_ORDER_CONTENT_TITLE2 = "Submit Work Order Detail";

const UPDATE_WORK_ORDER_LOCATION_BTN_LAVEL1 =
  "* Turn on your live Location & Check in Time";
const UPDATE_WORK_ORDER_LOCATION_BTN_PART_LAVEL1 =
  "Please update the detail and click";
const UPDATE_WORK_ORDER_LOCATION_BTN_PART_LAVEL2 = "Submit";
const UPDATE_WORK_ORDER_LOCATION_BTN_PART_LAVEL3 = "to close the work order.";

const UPDATE_WORK_ORDER_LOCATION_BTN_LAVEL3 =
  "Live Location and Check in time have been captured on";

const UPDATE_WORK_ORDER_LONATION_LABLE = "Live Location";
const UPDATE_WORK_ORDER_TIME_TITLE = "Check-in Time";
const COMMENTS = "Comments";
const CART_REQUEST_STOCK_SUCESSFULL_MSG =
  "You have successfully placed Add Stock request.";
const CART_RETURN_STOCK_SUCESSFULL_MSG =
  "You have successfully submitted a Return Stock request.";
const CART_TRANSFER_STOCK_SUCESSFULL_MSG =
  "You have successfully submitted a Transfer Stock request.";
const UPDATE_TRANSFER_STOCK_SUCESSFULL_MSG =
  "You have successfully updated the Transfer Stock request.";

const FREE_CONTENT_TEXT1 = "ID:";
const FREE_CONTENT_TEXT2 = "OR";
const WORK_ORDER_DETAILS_CONTENT_TITLE1 = "Work Order ID:";
const TRACK_ORDER_DETAILS_CONTENT_TITLE1 = "Order Number:";
const CART_CONFIRMARION_CONTENT_TITLE1 = "Track your request ID:";
const WORK_ORDER_DETAILS_CONTENT_TITLE2 = " Order Id";
const PARTNER_VIEW_NOTES_TEXT1 = "Notes";


const WORK_ORDER_DETAILS_CONTENT_TIMELINE_ITEM1 = "Order Created";
const WORK_ORDER_DETAILS_CONTENT_TIMELINE_ITEM2 = "Appointment";
const WORK_ORDER_DETAILS_CONTENT_TIMELINE_ITEM3 = "Installer Arrived";
const WORK_ORDER_DETAILS_CONTENT_TIMELINE_ITEM4 = "Installation Complete";
const WORK_ORDER_DETAILS_CONTENT_TIMELINE_ITEM5 = "Work Order Close";
const WORK_ORDER_DETAILS_CONTENT_TIMELINE_ITEM6 = "Returned Date";
const UPDATE_WORK_ORDER_CONFIRMATION_CONTENT_ITEM1 =
  "You have successfully updated the Work Order.";
const UPDATE_WORK_ORDER_CONFIRMATION_CONTENT_ITEM2 = "Work order Closure time";
const UPDATE_WORK_ORDER_CONFIRMATION_CONTENT_ITEM3 =
  "You have successfully Returned the Work Order.";
const CART_REQUEST_STOCK_PLACE_ORDER_MSG = "Items selected for Request Stock.";
const CART_RETURN_STOCK_PLACE_ORDER_MSG = "Items selected for Return Stock.";
const CART_TRANSFER_STOCK_PLACE_ORDER_MSG =
  "Items selected for Transfer Stock.";
const CART_RETURN_STOCK_VALIDATION =
  "Please provide * Mandatory information for selected items and proceed.";

/* -------- Filter Section ---------- */
const MANAGE_INSTALLER_FILTER_ITEM_TITLE1 = "List of available Installer";
const INVENTORY_FILTER_ITEM_TITLE1 = "Items";
const INVENTORY_FILTER_ITEM_TITLE2 = "Request transfer to Store ID:";

const INVENTORY_FILTER_SUB_ITEM_TITLE1 = "Filters";

/* ---------- Big Calendar ---------- */
const BIG_CALENDAR_ASSIGN_TYPE_LABEL = "Assign";
const BIG_CALENDAR_UNASSIGN_TYPE_LABEL = "Unassign";

const BIG_CALENDAR_OSI_LABEL = "Installation";
const BIG_CALENDAR_OST_LABEL = "Troubleshooting";
const BIG_CALENDAR_BTU_LABEL = "BTU Collections";

/* -------- Card item title ---------- */
const WORK_ORDER_REQUEST_TYPE1 = "Request Type";
const ACCESS_PROVIDER = "Access Provider";
const WORK_ORDER_CARD_ITEM_TITLE1 = "Work Order Id";

const WORK_ORDER_CARD_ITEM_TITLE2 = "Appointment Type";
const WORK_ORDER_CARD_ITEM_TITLE3 = "Sub Type";

const WORK_ORDER_CARD_ITEM_TITLE4 = "Date & Time";
const WORK_ORDER_CARD_ITEM_TITLE5 = "Section";
const WORK_ORDER_CARD_ITEM_TITLE6 = "Assign To";
const WORK_ORDER_CARD_ITEM_TITLE7 = "Activity Status";
const WORK_ORDER_CARD_ITEM_TITLE8 = "Address";
const WORK_ORDER_CARD_ITEM_TITLE9 = "Currently Assign";
const WORK_ORDER_CARD_ITEM_TITLE10 = "Status";
const WORK_ORDER_CARD_ITEM_TITLE11 = "Type";
const WORK_ORDER_CARD_ITEM_TITLE12 = "Return Remarks";
const WORK_ORDER_CARD_ITEM_TITLE13 = "Appointment Assignment";

const SWITCH_USER_CARD_ITEM_TITLE1 = "User ID";
const SWITCH_USER_CARD_ITEM_TITLE2 = "Name";
const SWITCH_USER_CARD_ITEM_TITLE3 = "Role";
const SWITCH_USER_CARD_ITEM_TITLE4 = "District";
const SWITCH_USER_CARD_ITEM_TITLE5 = "Email Id";
const SWITCH_USER_CARD_ITEM_TITLE6 = "Contact number";

/* ----------- Manage Installer Card -------------*/
const MANAGE_INSTALLER_CARD_ITEM_TITLE1 = "Installer Name";
const MANAGE_INSTALLER_CARD_ITEM_TITLE2 = "Email";
const MANAGE_INSTALLER_CARD_ITEM_TITLE3 = "User Name";
const MANAGE_INSTALLER_CARD_ITEM_TITLE4 = "District";
const MANAGE_INSTALLER_CARD_ITEM_TITLE5 = "Contact Number";
const MANAGE_INSTALLER_CARD_ITEM_TITLE6 = "Identity Type";
const MANAGE_INSTALLER_CARD_ITEM_TITLE7 = "Identity Number";
const MANAGE_INSTALLER_CARD_ITEM_TITLE10 = "Email Address";
const MANAGE_INSTALLER_CARD_ITEM_TITLE11 = "First Name";
const MANAGE_INSTALLER_CARD_ITEM_TITLE12 = "Last Name";
const MANAGE_INSTALLER_CARD_ITEM_TITLE13 = "State";

const WORK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE1 = "Work Order Detail";
const WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE1 = "Activity Type:";
const WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE2 = "Subscriber Id:";
const WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE3 = "Address Type:";
const WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE4 = "Building Name:";
const WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE5 = "Floor Number:";
const WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE6 = "House/Unit/Lot:";
const WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE7 = "Street Name:";
const WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE8 = "Postcode:";
const WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE9 = "Start Date:";
const WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE10 = "End Date & Time:";
const WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE11 = "Postcode:";
const WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE12 = "City:";
const WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE13 = "State:";
const WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE14 = "Remarks:";
const WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE15 = "Rescheduled:";
const WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE16 = "Activity Sub Type:";
const WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE17 = "Access Partner:";
const WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE18 = "Return Remarks:";

const WORK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE2 = "Customer Detail";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE1 = "Customer Id:";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE2 = "Status:";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE3 = "Type:";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE4 = "Sub Type:";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE5 = "Segment:";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE6 = "Category:";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE7 = "First Name:";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE8 = "Last Name:";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE9 = "Gender:";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE10 = "Email:";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE11 = "Contact No.:";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE12 = "City:";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE13 = "Id Type.:";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE14 = "Identification:";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE15 = "Documents:";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE16 = "Town:";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE17 = "Building Type:";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE18 = "Postcode:";
const CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE19 = "Alternative No.:";

const WORK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE3 = "Order Detail";
const ORDER_DETAILS_CARD_SUB_ITEM_TITLE1 = "Order No:";
const ORDER_DETAILS_CARD_SUB_ITEM_TITLE2 = "Order Type:";
const ORDER_DETAILS_CARD_SUB_ITEM_TITLE3 = "Order Status:";
const ORDER_DETAILS_CARD_SUB_ITEM_TITLE4 = "Request Date:";
const ORDER_DETAILS_CARD_SUB_ITEM_TITLE5 = "Plan Name:";
const ORDER_DETAILS_CARD_SUB_ITEM_TITLE6 = "Order Channel:";
const ORDER_DETAILS_CARD_SUB_ITEM_TITLE7 = "Account Id:";
const ORDER_DETAILS_CARD_SUB_ITEM_TITLE8 = "Devices:";
const ORDER_DETAILS_CARD_SUB_ITEM_TITLE9 = "Returned:";
const ORDER_DETAILS_CARD_SUB_ITEM_TITLE10 = "HSI Username:";
const ORDER_DETAILS_CARD_SUB_ITEM_TITLE11 = "HSI Password:";
const ORDER_DETAILS_CARD_SUB_ITEM_TITLE12 = "Note To Installer:";
const ORDER_DETAILS_CARD_SUB_ITEM_TITLE13 = "Access Partner Order ID:";
const ORDER_DETAILS_CARD_SUB_ITEM_TITLE14 = "BU ID:";

const WORK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE4 = "Case Detail";
const CASE_DETAILS_CARD_SUB_ITEM_TITLE1 = "Case No:";
const CASE_DETAILS_CARD_SUB_ITEM_TITLE2 = "Outlet Id:";
const CASE_DETAILS_CARD_SUB_ITEM_TITLE3 = "Category:";
const CASE_DETAILS_CARD_SUB_ITEM_TITLE4 = "Type:";
const CASE_DETAILS_CARD_SUB_ITEM_TITLE5 = "Owner:";
const CASE_DETAILS_CARD_SUB_ITEM_TITLE6 = "Status:";
const CASE_DETAILS_CARD_SUB_ITEM_TITLE7 = "Source:";
const CASE_DETAILS_CARD_SUB_ITEM_TITLE8 = "Service No.:";
const CASE_DETAILS_CARD_SUB_ITEM_TITLE9 = "Created Date:";
const CASE_DETAILS_CARD_SUB_ITEM_TITLE10 = "Case Aging:";
const CASE_DETAILS_CARD_SUB_ITEM_TITLE11 = "Creator Full Name:";
const CASE_DETAILS_CARD_SUB_ITEM_TITLE12 = "Customer Id Type:";

const WORK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE5 = "Documents Uploaded"; 
const WORK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE6 = "Device Details"; 

const WORK_ORDER_OST_DEVICE_ITEM_TITLE1 = "Brand and Model";
const WORK_ORDER_OST_DEVICE_ITEM_TITLE2 = "Device Status";
const WORK_ORDER_OST_DEVICE_ITEM_TITLE3 = "Category";
const WORK_ORDER_OST_DEVICE_ITEM_TITLE4 = "Serial Number";
const WORK_ORDER_OST_DEVICE_ITEM_TITLE5 = "Device Brand";

/* ----------- Track Order Details -------------*/
const TRACK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE1 = "ORDER DETAIL";
const TRACK_ORDER_DETAILS_CARD_ITEM_SECTION_TITLE1 = "Order Information:";
const TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE1 = "Order No:";
const TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE2 = "Customer Name:";
const TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE3 = "Order Channel:";
const TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE4 = "Order Subtype:";
const TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE5 = "Order Created Date:";
const TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE6 = "Created By Store ID:";
const TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE7 = "Reason Code:";
const TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE8 = "Status:";
const TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE9 = "Order Type:";

const TRACK_ORDER_DETAILS_CARD_ITEM_SECTION_TITLE2 = "Customer Details:";
const TRACK_ORDER_DETAILS_CARD_SUB_ITEM2_TITLE1 = "Name:";
const TRACK_ORDER_DETAILS_CARD_SUB_ITEM2_TITLE2 = "Customer No:";
const TRACK_ORDER_DETAILS_CARD_SUB_ITEM2_TITLE3 = "Date Of Birth:";
const TRACK_ORDER_DETAILS_CARD_SUB_ITEM2_TITLE4 = "ID Type:";
const TRACK_ORDER_DETAILS_CARD_SUB_ITEM2_TITLE5 = "Email:";
const TRACK_ORDER_DETAILS_CARD_SUB_ITEM2_TITLE6 = "Contact Mobile Number:";

const TRACK_ORDER_DETAILS_CARD_ITEM_SECTION_TITLE3 = "Billing Details:";
const TRACK_ORDER_DETAILS_CARD_SUB_ITEM3_TITLE1 = "Bill Media:";
const TRACK_ORDER_DETAILS_CARD_SUB_ITEM3_TITLE2 = "Bill Type:";
const TRACK_ORDER_DETAILS_CARD_SUB_ITEM3_TITLE3 = "Email:";

const TRACK_ORDER_DETAILS_CARD_ITEM_SECTION_TITLE4 = "Shipping Address:";
const TRACK_ORDER_DETAILS_CARD_ITEM_SECTION_TITLE5 = "Billing Address";

const TRACK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE2 = "ORDER SUMMARY";

const TRACK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE3 = "ORDER PROGRESS";

const TRACK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE4 =
  "SERVICE FULFILMENT DETAILS";

const TRACK_ORDER_CARD_ITEM_TITLE1 = "Order Number";

const TRACK_ORDER_CARD_ITEM_TITLE2 = "Order Type";
const TRACK_ORDER_CARD_ITEM_TITLE3 = "Order Sub-Type";

const TRACK_ORDER_CARD_ITEM_TITLE4 = "Create Date";
const TRACK_ORDER_CARD_ITEM_TITLE5 = "Received Date";
const TRACK_ORDER_CARD_ITEM_TITLE6 = "Customer ID";
const TRACK_ORDER_CARD_ITEM_TITLE7 = "Order Status";

const INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE1 = "Material Code";
const INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE2 = "Item Name";
const INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE3 = "Brand";
const INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE4 = "Category";
const INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE5 = "Quantity";
const INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE6 = "In Stock";
const INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE7 = "Faulty";
const INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE8 = "Add Quantity";
const INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE9 = "Serial Number(s)";

const INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE1 = "Request ID";
const INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE2 = "Request Type";
const INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE3 = "Request Date";
const INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE4 = "Origin Store ID";
const INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE5 = "Distribution Outlet";
const INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE6 = "Store Name";
const INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE7 = "Status";
const INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE8 = "Distributor Code";
const INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE9 = "Request Quantity";
const INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE10 = "Approve Quantity";
const INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE11 = "Reject Quantity";

const INVENTORY_CARD_TRACK_STOCK_ITEM_DETAILS_TITLE1 = "Request Qty";
const INVENTORY_CARD_TRACK_STOCK_ITEM_DETAILS_TITLE2 = "Approve Qty";
const INVENTORY_CARD_TRACK_STOCK_ITEM_DETAILS_TITLE3 = "Received Qty";
const INVENTORY_CARD_TRACK_STOCK_ITEM_DETAILS_TITLE4 = "Transferred Qty";
const INVENTORY_CARD_TRACK_STOCK_ITEM_DETAILS_TITLE5 = "Sl. No.";

const INVENTORY_CARD_ACKNOWLEDGEED_ITEM_DETAILS_TITLE1 = "Requested Qty";
const INVENTORY_CARD_ACKNOWLEDGEED_ITEM_DETAILS_TITLE2 = "Approved Qty";
const INVENTORY_CARD_ACKNOWLEDGEED_ITEM_DETAILS_TITLE3 = "Ack.. Qty";
const INVENTORY_CARD_ACKNOWLEDGEED_ITEM_DETAILS_TITLE4 = "Transfer Qty";

const INVENTORY_CARD_VIEW_STOCK_ITEM_TITLE1 = "Total Quantity";
const INVENTORY_CARD_VIEW_STOCK_ITEM_TITLE2 = "In Stock";
const INVENTORY_CARD_VIEW_STOCK_ITEM_TITLE3 = "Unavailable";
const INVENTORY_CARD_VIEW_STOCK_ITEM_TITLE4 = "Sold";
const INVENTORY_CARD_VIEW_STOCK_ITEM_TITLE5 = "Faulty";
const INVENTORY_CARD_VIEW_STOCK_ITEM_TITLE6 = "Total Qty";

const INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE1 = "Total Items";
const INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE2 = "Received";
const INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE3 = "Not Received";
const INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE4 = "Unmatched";
const ACKNOWLEDGE_STOCK_TABLE_ITEM_TITLE1 = "Item Serial Number";
const ACKNOWLEDGE_STOCK_TABLE_ITEM_TITLE2 = "Material Code";
const ACKNOWLEDGE_STOCK_TABLE_ITEM_TITLE3 = "Item Name";

const CART_CARD_RETURN_SOTOCK_ITEM_TITLE1 = "Store ID";
/* ----------- Track Order Details -------------*/
const UPDATE_WORK_ORDER_OSI_CONTENT_ACCORDION_TITLE1 = "Device Detail:";
const UPDATE_WORK_ORDER_OSI_CONTENT_ACCORDION_TITLE2 = "BTU Detail:";
const UPDATE_WORK_ORDER_OSI_CONTENT_ACCORDION_TITLE3 = "Installation Detail:";
const UPDATE_WORK_ORDER_OST_CONTENT_ACCORDION_TITLE2 = "Restoration Detail:";

const UPDATE_WORK_ORDER_OST_CONTENT_ACCORDION_TITLE1 = "Device Swap Detail:";

const UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_TITLE1 = "BTU Detail:";
const UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_TITLE1 = "- BU ID";
const UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_TITLE2 =
  "- BTU Serial Number";
const UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_TITLE3 =
  "- BTU Port Number";
  const UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_TITLE4 = "BTU Device Brand";
const UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL1 = "ID";
const UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL2 = "Serial Number";
const UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL3 = "Port Number";
const UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL4 = "Brand Details"

const UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_DEVICE_COLLECTED_LABEL =
  "BTU Collected";

/* -------- Modal Content ---------- */
const ASSIGN_WORK_ORDER_MODAL_TITLE = "Assign Installer";
const ASSIGN_WORK_ORDER_MODAL_SUPERADMIN_TITLE = "Assign Supervisor";
const RETURNED_WORK_ORDER_MODAL_TITLE = "Order Returned";
const WORK_ORDER_DETAILS_DEVICE_LIST_MODAL_TITLE = "Device List";
const WORK_ORDER_ADVANCED_SEARCH_MODAL_TITLE = "Advanced Search";

const INSTALLER_DETAILS_MODAL_TITLE = "Installer Detail";
const ADD_INSTALLER_MODAL_TITLE = "Add Installer";
const EDIT_INSTALLER_MODAL_TITLE = "Edit Installer detail";
const VIEW_TIMELINE_MODAL_TITLE = "Timeline";

const UPLOAD_DOCUMENT_MODAL_TITLE = "Upload Documents";
const VIEW_DOCUMENTS_MODAL_TITLE = "View Documents";

const ASSIGN_WORK_ORDER_MODAL_REVERT_BACK_LABEL = "Revert back to admin";

const REQUEST_STOCK_PLACE_ORDER_MODAL_TITLE =
  "Do you want to place Add Stock request?";
const TRANSFER_STOCK_PLACE_ORDER_MODAL_TITLE =
  "Do you want to place Transfer Stock request?";
const RETURN_STOCK_PLACE_ORDER_MODAL_TITLE =
  "Do you want to place Return Stock request?";
const DELETE_INSTALLER_MODAL_TITLE =
  "Do you want to Delete the Installer detail from the list?";
const ADD_SERIAL_NUMBER_MODAL_TITLE = "Serial Number/IMEI";
const APPROVE_STOCK_MODAL_TITLE = "Do you want to Approve the Stock Request?";
const REJECT_STOCK_MODAL_TITLE = "Do you want to Reject the Stock Request?";
const ACKNOWLEDGE_STOCK_MODAL_TITLE = "Do you want to Acknowledge the Request?";

const ACKNOWLEDGE_CONFIRM_MSG =
  "You have successfully acknowledged Inventory Request.";
const SWITCH_TO_ANOTHER_ACCOUNT_TITLE1 = "Do you want to";
const SWITCH_TO_ANOTHER_ACCOUNT_TITLE2 = "to another account?";
const SWITCH_FROM_ANOTHER_ACCOUNT_TITLE2 = "to your personal account?";

const SWITCH_TO_TITLE = "Switch";
const SWITCH_FROM_TITLE = "Switch Back";

const SWITCH_USER_MODAL_TO_TITLE = "To:";
const SWITCH_USER_MODAL_FROM_TITLE = "From:";

const SWITCH_ADMIN_USER_ROLE_MODAL = "Super Admin";
const SWITCH_SUPER_USER_ROLE_MODAL = "Supervisor";

const UPDATE_WORK_ORDER_MODAL_TITLE1 =
  "You have successfully saved the activity detail.";
const UPDATE_WORK_ORDER_MODAL_NOTE_TITLE1 = "Note:";
const UPDATE_WORK_ORDER_MODAL_NOTE_MSG =
  "You have partially completed the activity update process. Please revisit and complete the process within 48 hours.";
const UPDATE_WORK_ORDER_UPLOAD_DOUMENT_MODAL_NOTE_MSG =
  "Maximum file size for each document is 50MB, Supported file formats is .pdf, .jpg, .jpeg, .png, .mp4, .mov, .mkv, .doc, .docx, .xlsx";
const UPDATE_WORK_ORDER_MODAL_NAME_TITLE1 = "(Model Name)";
const UPLOAD_DOCUMENT_MODAL_BODY_TITLE1 =
  "Select document type and click Upload.";
const UPLOAD_DOCUMENT_MODAL_BODY_TITLE2 = "Uploaded Files";
const UPLOAD_DOCUMENT_MODAL_BODY_WARNING_MSG1 = "Please upload the ";
const UPLOAD_DOCUMENT_MODAL_BODY_WARNING_MSG2 =
  " type of document/ document's, then upload other type of document's, or remove respctive wrong attached document's";
const UPLOAD_DOCUMENT_MODAL_BODY_TEXT_MSG1 = "Drag & Drop file here…";
const UPLOAD_DOCUMENT_MODAL_BODY_TEXT_MSG2 = "Or";
const UPLOAD_DOCUMENT_MODAL_BODY_TEXT_MSG3 = "Release the file…";
const UPLOAD_DOCUMENT_MODAL_BODY_VIEW_TITLE1 = "Document Name";
const UPLOAD_DOCUMENT_MODAL_BODY_VIEW_TITLE2 = "Action";
const UPLOAD_DOCUMENT_MODAL_BODY_FILE_UPLOAD_LABEL = "Uploading…";

const CONFIRMATION_MODAL_TITLE = "Are you sure!";

const LOGOUT_MODAL_TITLE1 = "Log Out";
const LOGOUT_MODAL_TITLE2 = "Are you sure you want to logout?";

const LOGOUT_MODAL_MESSAGE =
  "Your items will be removed from the Cart once you log out.";

const WORK_ORDER_DETAILS_MODAL_DEVICE_NAME = "Device Name";
const WORK_ORDER_DETAILS_MODAL_DEVICE_TYPE = "Device Type";

/* -------- Buttons ---------- */
const TODAY_BTN = "Today";
const SEARCH_BTN = "Search";
const BACK_BTN = "Back";
const DOWNLOAD_BTN = "Download";
const ASSIGN_BTN = "Assign";
const UPDATE_BTN = "Update";
const REFRESH_BTN = "Refresh";
const REVERT_BTN = "Revert";
const OKAY_BTN = "OK";
const ENABLE_BTN = "Enable";
const CART_PLACE_ORDER_BTN = "Place Order";
const EDIT_QUANTITY_BTN = "Edit Quantity";
const CONFIRMATION_BTN = "Confirm";
const ADD_INSTALLER_BTN = "Add Installer";
const DELETE_BTN = "Delete";
const EDIT_BTN = "Edit";
const ADD_BTN = "Add";
const SAVE_BTN = "Save";
const ADD_ROW_BTN = "Add Row";
const CANCEL_BTN = "Cancel";
const GO_TO_CART_BTN = "Go to Cart";
const APPLY_BTN = "Apply";
const APPROVE_BTN = "Approve";
const ACKNOWLEDGE_BTN = "Acknowledge";
const CLEAR_BTN = "Clear";
const SWITCH_BTN = "Switch";
const UPLOAD_BTN = "Upload Documents";
const UPLOAD_BTN1 = "Upload";
const BROWSE_FILE_BTN = "Browse file";
const SAVE_AND_CONTINUE_BTN = "Save & Continue";
const SUBMIT_BTN = "Submit";
const REJECT_BTN = "Reject";
const LOGOUT_BTN = "Logout";
const CONTINUE_BTN = "Continue";

const SELECT_ALL_BTN = "Select all";
const UNSELECT_ALL_BTN = "Unselect all";

const SERIAL_NUMBER_BTN = "Serial Number";

const INVENTORY_CARD_ITEM_BTN1_TEXT = "Return Stock";
const INVENTORY_CARD_ITEM_BTN2_TEXT = "Add Stock";
const INVENTORY_CARD_ITEM_BTN3_TEXT = "Transfer Stock";

/* -------- Radio Button ---------- */
const REQUEST_STOCK_RADIO_BTN_LABLE = "Request Stock";
const RETURN_STOCK_RADIO_BTN_LABLE = "Return Stock";
const TRANSFER_STOCK_RADIO_BTN_LABLE = "Transfer Stock";

const OST_DEVICE_CHECKBOX_LABLE = "Device Swap";

/* -------- Table Item Title Report Extraction ---------- */
const REPORT_EXT_SN_LABLE = "SL. No.";
const REPORT_EXT_ACCESS_LABLE = "Access Provider";
const REPORT_EXT_COM_NAM_LABLE = "Company Name";
const REPORT_EXT_APPDATE_LABLE = "Appointment Date";
const REPORT_EXT_ORD_NO_LABLE = "Order No.";
const REPORT_EXT_WO_ID_LABLE = "Work Order ID";
const REPORT_EXT_APP_TYP_LABLE = "Appointment Type";
const REPORT_EXT_APP_SUB_TYPE_LABLE = "Appointment Sub Type";
const REPORT_EXT_STATUS_LABLE = "Status";
const REPORT_EXT_CUS_NAM_LABLE = "Customer Name";
const REPORT_EXT_CUS_ID_LABLE = "CustomerID";
const REPORT_EXT_CUS_CNO_LABLE = "Customer Contact No";
const REPORT_EXT_ADDRESS_LABLE = "Address";
const REPORT_EXT_ADDRESS_TYPE_LABLE = "Customer Address Type";
const REPORT_EXT_PLAN_LABLE = "Plan";
const REPORT_EXT_HSI_USER_NAME_LABLE = "HSI Username";
const REPORT_EXT_INSTL_NAME_LABLE = "Installer Name";
const REPORT_EXT_INSTL_CLS_TIME_LABLE = "Installation Close Timestamp";
const REPORT_EXT_NOTeS_FOR_INSTL_LABLE = "Notes for Installer";
const REPORT_EXT_INSTL_CON_NO_LABLE = "Installer Contact Number";
const REPORT_EXT_RGW_IMEI_LABLE = "RGW IMEI";
const REPORT_EXT_BTU_IMEI_LABLE = "BTU IMEI";
const REPORT_EXT_DES_INTL_LABLE = "Desired Installation";
const REPORT_EXT_ADD_CAB_LABLE = "Additional Cabling";
const REPORT_EXT_RETUN_REMARK_LABLE = "Return Remark";
const REPORT_EXT_WO_CLOSE_LABLE = "WO. Close Date & Time";
const REPORT_EXT_ACT_CLOSE_LABLE = "Activity Close Date & Time";
const REPORT_EXT_MESH_IMEI_LABLE = "MESH Serial No";
const REPORT_EXT_RETUN_REMARK_ADD_LABLE = "Return Remarks Additional Info";
const REPORT_EXT_OST_ELEM_LABLE = "OST Element";
const REPORT_EXT_OST_RESOLUTION_LABLE = "OST Resulution";
const REPORT_EXT_OST_PROB_LABLE = "OST Problem Caused";

/* -------- Table Item Title ---------- */
const VIEW_STOCK_TABLE_ITEM_TITLE1 = "No.";
const VIEW_STOCK_TABLE_ITEM_TITLE2 = "Item Serial Number";
const VIEW_STOCK_TABLE_ITEM_TITLE3 = "Expiry Date";
const VIEW_STOCK_TABLE_ITEM_TITLE4 = "Status";

/* -------- Not Found Page Constent -----------*/
const NOT_FOUND_PAGE_MESSAGE1 = "The page Not Found.";
const NOT_FOUND_PAGE_MESSAGE2 =
  "You can research for the page you want here or return to the home page.";

/* -------- Footer Section Constent ---------- */

/* -------- Input box Section Constent ---------- */
const DATE_RANGE_INPUT_ITEM_LABEL1 = "From Date";
const DATE_RANGE_INPUT_ITEM_LABEL2 = "To Date";

const INVENTORY_FILTER_INPUT_ITEM_LABEL1 = "Serial Number";
const INVENTORY_FILTER_INPUT_ITEM_LABEL2 = "Service ID";
const INVENTORY_FILTER_INPUT_ITEM_LABEL3 = "Expiry Date";

const WORK_ORDER_INPUT_ITEM_LABEL1 = "Work order ID";
const TRACK_ORDER_INPUT_ITEM_LABEL1 = "Order Number";
const WORK_ORDER_INPUT_ITEM_LABEL2 = "Customer ID";

const WORK_ORDER_REPORT_ITEM_LABEL1 = "TM";
const WORK_ORDER_REPORT_ITEM_LABEL2 = "CTSB";
const WORK_ORDER_REPORT_ITEM_LABEL3 = "SACOFA";
const WORK_ORDER_REPORT_ITEM_LABEL4 = "CFC";
const WORK_ORDER_REPORT_ITEM_LABEL5 = "ALLO";

const MANAGE_INSTALLER_INPUT_ITEM_LABEL1 = "User Name/ Contact Number";

const MATERIAL_CODE_OR_ITEM_NAME_INPUT_ITEM_LABEL = "Material Code / Item Name";
const REQUEST_ID_INPUT_ITEM_LABEL = "Request ID";
const DISTRIBUTOR_CODE_INPUT_ITEM_LABEL = "Distributor code";

const SERIAL_NUMBER_INPUT_ITEM_LABEL1 = "Serial Number";

const COMMENT_INPUT_ITEM_LABEL = "Any other comments…";
const UPDATE_WORK_ORDER_RETURN_REMARKS_COMMENT_INPUT_ITEM_LABEL =
  "Any Return Remarks comments…";

/* -------- Input box Section Drop Down Constent ---------- */
const SELECT_OPTION_DROP_DOWN = "Select Option";

const PRODUCT_CATAGORY_INPUT_ITEM_LABEL = "Product Category";
const BRAND_INPUT_ITEM_LABEL = "Brand";
const REQUEST_STATUS_INPUT_ITEM_LABEL = "Request Status";

const UPDATE_WORK_ORDER_STATUS_INPUT_ITEM_LABEL = "Work Order Status";
const UPDATE_WORK_ORDER_RETURN_REASON_INPUT_ITEM_LABEL = "Return Reason";
const UPDATE_WORK_ORDER_RETURN_REMARKS_INPUT_ITEM_LABEL = "Return Remarks";
const UPDATE_WORK_ORDER_INFRASTRUCTURE_INPUT_ITEM_LABEL = "Infrastructure";
const UPDATE_WORK_ORDER_DESIRED_INSTALLATION_INPUT_ITEM_LABEL =
  "Desired Installation";
const UPDATE_WORK_ORDER_ADDITIONAL_CABLE_LENGTH_INPUT_ITEM_LABEL =
  "Total Cable Length from DP to BTU (in meters)";
const UPLOAD_DOCUMENT_TYPE_INPUT_ITEM_LABEL = "Document Type";
const UPDATE_WORK_ORDER_DOWNLOAD_SPEED_LAN_INPUT_ITEM_LABEL =
"Download/Upload Speed Test - LAN (Mbps)";
const UPDATE_WORK_ORDER_DOWNLOAD_SPEED_WIFI_INPUT_ITEM_LABEL =
"Download/Upload Speed Test - WiFi (Mbps)";
const INSTALLER_NAME = "Installer Name";
const CUSTOMER_NAME = "Customer Name";
const SERIAL_NUMMER_OR_IMEI = "Serial Number/IMEI";
const RETURN_REASON = "Return Reason";
const REMARKS = "Remarks";
const TRANSFER_TO_STORE = "Select Store To Transfer To";
const STATES_INPUT_ITEM_LABEL = "State";
const DISTRICT_INPUT_ITEM_LABEL = "District";
const ASSIGN_TO_INPUT_ITEM_LABEL = "Assign To";
const REVERT_BACK_REASON_INPUT_ITEM_LABEL = "Reason to revert";
const REVERT_BACK_TO_ADMIN = "Revert back to Admin";
const REASSIGN_BACK_TO_SUPERVISOR = "Reassign To Supervisor";
const SELECT_OUTLET = "Select Outlet";
const USER_TYPE_INPUT_ITEM_LABEL = "User Type";
const SELECT_SUPERVISOR_INPUT_ITEM_LABEL = "Supervisor Id";

const UPDATE_WORK_ORDER_ELEMENT_INPUT_ITEM_LABEL = "Elements";
const UPDATE_WORK_ORDER_RESOLUTION_INPUT_ITEM_LABEL = "Resolution";
const UPDATE_WORK_ORDER_PROBLEM_CAUSE_INPUT_ITEM_LABEL = "Problem Cause";

const SELECT_SUPERVISOR_INPUT_ITEM_VALUE = "Supervisor";

const UPDATE_WORK_ORDER_OTHER_REMARS_LABEL = "Others";

const INVALID_MSG = "Invalid";
const INVALID_INPUT = "Invalid Input";
const INVALID_SERIAL_NUMBER = "Invalid Serial Number";

const INSTALLER_TIMELINE = [
  ["8:00", "8:10", "8:20", "8:30", "8:40", "8:50"],
  ["9:00", "9:10", "9:20", "9:30", "9:40", "9:50"],
  ["10:00", "10:10", "10:20", "10:30", "10:40", "10:50"],
  ["11:00", "11:10", "11:20", "11:30", "11:40", "11:50"],
  ["12:00", "12:10", "12:20", "12:30", "12:40", "12:50"],
  ["13:00", "13:10", "13:20", "13:30", "13:40", "13:50"],
  ["14:00", "14:10", "14:20", "14:30", "14:40", "14:50"],
  ["15:00", "15:10", "15:20", "15:30", "15:40", "15:50"],
  ["16:00", "16:10", "16:20", "16:30", "16:40", "16:50"],
  ["17:00", "17:10", "17:20", "17:30", "17:40", "17:50"],
  ["18:00", "18:10", "18:20", "18:30", "18:40", "18:50"],
  ["19:00", "19:10", "19:20", "19:30", "19:40", "19:50"],
  ["20:00", "20:10", "20:20", "20:30", "20:40", "20:50"],
];

const VIEW_WORKORDER_REPORTEXTRACTION_TABLE_COL_NAME_LIST = [
  "SL. No.",
  "Access Provider",
  "Appointment Date",
  "Order No.",
  "Work Order ID",
  "Appointment Type",
  "Appointment Sub Type",
  "Status",
  "Customer Name",
  "CustomerID",
  "Customer Contact No",
  "Address",
  "Plan",
  "HSI Username",
  "Notes for Installer",
  "Installer Contact Number",
  "RGW IMEI",
  "BTU IMEI",
  "Desired Installation",
  "Additional Cabling",
  "Return Remark",
  "WO. Close Date & Time",
  "Customer Address Type",
  "Activity Close Date & Time",
  "MESH Serial No",
  "Return Remarks Additional Info",
  "OST Element",
  "OST Resulution",
  "OST Problem Caused",
];

export const Constent = {
  ADMIN_ROLE,
  VIEW_WORKORDER_REPORTEXTRACTION_TABLE_COL_NAME_LIST,
  SUPER_ROLE,
  INSTALLER_ROLE,
  SESSION_EXPIRE,
  SESSION_EXPIRE_TIMEOUT_MESSAGE,
  BTU_SERIAL_NUMBER_MIN,
  BTU_SERIAL_NUMBER_MAX,
  ROW_END_RETRIEVE_DETAILS,
  LOGIN_MESSAGE1,
  LOGIN_MESSAGE2,
  BODY_HEADER_TRACK_REQUEST_DETAILS,
  HEADER_MENU_ITEM1,
  HEADER_MENU_ITEM2,
  HEADER_MENU_ITEM3,
  HEADER_MENU_ITEM4,
  HEADER_MENU_ITEM5,
  HEADER_MENU_ITEM56,
  HEADER_MENU_ITEM6,
  HEADER_MENU_ITEM7,
  HEADER_MENU_ITEM67,
  HEADER_MENU_NOTES,
  HEADER_MENU_ITEM5_SUB1,
  HEADER_MENU_ITEM5_SUB2,
  BODY_HEADER_DEFUALT_PATH,
  SEARCH_BTN,
  APPROVE_BTN,
  UPDATE_WORK_ORDER_ELEMENT_INPUT_ITEM_LABEL,
  UPDATE_WORK_ORDER_RESOLUTION_INPUT_ITEM_LABEL,
  SELECT_OUTLET,
  UPDATE_WORK_ORDER_PROBLEM_CAUSE_INPUT_ITEM_LABEL,
  WORK_ORDER_OST_DEVICE_ITEM_TITLE1,
  RETURN_STOCK_PLACE_ORDER_MODAL_TITLE,
  WORK_ORDER_OST_DEVICE_ITEM_TITLE2,
  WORK_ORDER_OST_DEVICE_ITEM_TITLE3,
  WORK_ORDER_OST_DEVICE_ITEM_TITLE4,
  WORK_ORDER_OST_DEVICE_ITEM_TITLE5,
  BODY_HEADER_DASHBORD_BANNAR_SUB_TEXT,
  BODY_HEADER_DASHBORD_BANNAR_TEXT,
  BODY_HEADER_VIEW_WORKORDER_REPORT,
  BODY_HEADER_VIEW_WORKORDER_REPORT_SEARCHCARD,
  WORK_ORDER_DETAILS_CONTENT_TITLE2,
  PARTNER_VIEW_NOTES_TEXT1,
  BODY_HEADER_PARTNER_UPLOAD,
  SAVE_BTN,
  APPLY_BTN,
  CONTINUE_BTN,
  WORK_ORDER_CARD_ITEM_TITLE1,
  WORK_ORDER_CARD_ITEM_TITLE2,
  WORK_ORDER_CARD_ITEM_TITLE3,
  ACKNOWLEDGE_BTN,
  WORK_ORDER_CARD_ITEM_TITLE4,
  WORK_ORDER_CARD_ITEM_TITLE5,
  WORK_ORDER_CARD_ITEM_TITLE6,
  REJECT_BTN,
  WORK_ORDER_CARD_ITEM_TITLE7,
  UPDATE_TRANSFER_STOCK_SUCESSFULL_MSG,
  WORK_ORDER_CARD_ITEM_TITLE8,
  WORK_ORDER_CARD_ITEM_TITLE9,
  ENABLE_BTN,
  DATE_RANGE_INPUT_ITEM_LABEL1,
  DATE_RANGE_INPUT_ITEM_LABEL2,
  WORK_ORDER_INPUT_ITEM_LABEL1,
  WORK_ORDER_REPORT_ITEM_LABEL1,
  WORK_ORDER_REPORT_ITEM_LABEL2,
  WORK_ORDER_REPORT_ITEM_LABEL3,
  WORK_ORDER_REPORT_ITEM_LABEL4,
  WORK_ORDER_REPORT_ITEM_LABEL5,
  INVALID_INPUT,
  BODY_HEADER_INVENTORY_PILL_ITEM1,
  BODY_HEADER_INVENTORY_PILL_ITEM2,
  BODY_HEADER_WORKORDERREPORT_PILL_ITEM1,
  BODY_HEADER_WORKORDERREPORT_PILL_ITEM2,
  Body_HEADER_PARTNERUPLOAD_PILL_ITEM1,
  Body_HEADER_PARTNERUPLOAD_PILL_ITEM2,
  VIEW_TIMELINE_MODAL_TITLE,
  BODY_HEADER_INVENTORY_SEARCH_TITLE1,
  BODY_HEADER_INVENTORY_SEARCH_TITLE2,
  CLEAR_BTN,
  UPLOAD_BTN1,
  BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1,
  BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM2,
  MATERIAL_CODE_OR_ITEM_NAME_INPUT_ITEM_LABEL,
  PRODUCT_CATAGORY_INPUT_ITEM_LABEL,
  BRAND_INPUT_ITEM_LABEL,
  REQUEST_ID_INPUT_ITEM_LABEL,
  REQUEST_STATUS_INPUT_ITEM_LABEL,
  INVENTORY_CARD_ITEM_BTN1_TEXT,
  INVENTORY_CARD_ITEM_BTN2_TEXT,
  INVENTORY_CARD_ITEM_BTN3_TEXT,
  INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE1,
  INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE2,
  INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE3,
  INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE4,
  INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE5,
  INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE6,
  INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE7,
  INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE8,
  INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE1,
  INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE2,
  INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE3,
  INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE4,
  INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE5,
  INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE6,
  INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE7,
  BODY_HEADER_CART_PATH,
  INVENTORY_FILTER_ITEM_TITLE1,
  INVENTORY_FILTER_ITEM_TITLE2,
  UNDERLINE_NAV_CART_ITEM1,
  UNDERLINE_NAV_CART_ITEM2,
  UNDERLINE_NAV_CART_ITEM3,
  SELECT_ALL_BTN,
  UNSELECT_ALL_BTN,
  CART_CARD_RETURN_SOTOCK_ITEM_TITLE1,
  SERIAL_NUMMER_OR_IMEI,
  RETURN_REASON,
  TRANSFER_TO_STORE,
  REMARKS,
  BODY_HEADER_INVENTORY_SUB_PATH1,
  BACK_BTN,
  GO_TO_CART_BTN,
  BODY_HEADER_INVENTORY_VIEW_STOCK_BANNAR_TEXT,
  INVENTORY_CARD_VIEW_STOCK_ITEM_TITLE1,
  INVENTORY_CARD_VIEW_STOCK_ITEM_TITLE2,
  INVENTORY_CARD_VIEW_STOCK_ITEM_TITLE3,
  INVENTORY_CARD_VIEW_STOCK_ITEM_TITLE4,
  INVENTORY_CARD_VIEW_STOCK_ITEM_TITLE5,
  INVENTORY_CARD_VIEW_STOCK_ITEM_TITLE6,
  DOWNLOAD_BTN,
  INVENTORY_FILTER_SUB_ITEM_TITLE1,
  INVENTORY_FILTER_INPUT_ITEM_LABEL1,
  INVENTORY_FILTER_INPUT_ITEM_LABEL2,
  INVENTORY_FILTER_INPUT_ITEM_LABEL3,
  VIEW_STOCK_TABLE_ITEM_TITLE1,
  REASSIGN_BACK_TO_SUPERVISOR,
  WORK_ORDER_REQUEST_TYPE1,
  ACCESS_PROVIDER,
  NOT_FOUND_PAGE_MESSAGE1,
  REVERT_BACK_TO_ADMIN,
  WORK_ORDER_INPUT_ITEM_LABEL2,
  NOT_FOUND_PAGE_MESSAGE2,
  VIEW_STOCK_TABLE_ITEM_TITLE2,
  VIEW_STOCK_TABLE_ITEM_TITLE3,
  VIEW_STOCK_TABLE_ITEM_TITLE4,
  FREE_CONTENT_TEXT1,
  BODY_HEADER_WORKORDER_DETAILS_PATH,
  ASSIGN_BTN,
  WORK_ORDER_DETAILS_CONTENT_TITLE1,
  WORK_ORDER_DETAILS_CONTENT_TIMELINE_ITEM1,
  WORK_ORDER_DETAILS_CONTENT_TIMELINE_ITEM2,
  WORK_ORDER_DETAILS_CONTENT_TIMELINE_ITEM3,
  WORK_ORDER_DETAILS_CONTENT_TIMELINE_ITEM4,
  WORK_ORDER_DETAILS_CONTENT_TIMELINE_ITEM5,
  WORK_ORDER_DETAILS_CONTENT_TIMELINE_ITEM6,
  WORK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE1,
  WORK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE2,
  WORK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE3,
  WORK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE5,
  WORK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE6,
  WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE1,
  WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE2,
  WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE3,
  WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE4,
  WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE5,
  WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE6,
  WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE7,
  WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE8,
  WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE9,
  WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE10,
  WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE11,
  WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE12,
  WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE13,
  WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE14,
  WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE15,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE1,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE2,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE3,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE4,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE5,
  ORDER_DETAILS_CARD_SUB_ITEM_TITLE1,
  ORDER_DETAILS_CARD_SUB_ITEM_TITLE2,
  ORDER_DETAILS_CARD_SUB_ITEM_TITLE3,
  ORDER_DETAILS_CARD_SUB_ITEM_TITLE4,
  ORDER_DETAILS_CARD_SUB_ITEM_TITLE5,
  ORDER_DETAILS_CARD_SUB_ITEM_TITLE6,
  ORDER_DETAILS_CARD_SUB_ITEM_TITLE7,
  ORDER_DETAILS_CARD_SUB_ITEM_TITLE8,
  ORDER_DETAILS_CARD_SUB_ITEM_TITLE9,
  ORDER_DETAILS_CARD_SUB_ITEM_TITLE10,
  ORDER_DETAILS_CARD_SUB_ITEM_TITLE11,
  ORDER_DETAILS_CARD_SUB_ITEM_TITLE12,
  ORDER_DETAILS_CARD_SUB_ITEM_TITLE13,
  ORDER_DETAILS_CARD_SUB_ITEM_TITLE14,
  SERIAL_NUMBER_BTN,
  ASSIGN_WORK_ORDER_MODAL_TITLE,
  ASSIGN_WORK_ORDER_MODAL_SUPERADMIN_TITLE,
  OKAY_BTN,
  VIEW_DOCUMENTS_MODAL_TITLE,
  ASSIGN_WORK_ORDER_MODAL_REVERT_BACK_LABEL,
  REFRESH_BTN,
  STATES_INPUT_ITEM_LABEL,
  DISTRICT_INPUT_ITEM_LABEL,
  ASSIGN_TO_INPUT_ITEM_LABEL,
  REVERT_BACK_REASON_INPUT_ITEM_LABEL,
  COMMENT_INPUT_ITEM_LABEL,
  REVERT_BTN,
  RETURNED_WORK_ORDER_MODAL_TITLE,
  WORK_ORDER_ADVANCED_SEARCH_MODAL_TITLE,
  FREE_CONTENT_TEXT2,
  SERIAL_NUMBER_INPUT_ITEM_LABEL1,
  CART_REQUEST_STOCK_PLACE_ORDER_MSG,
  CART_RETURN_STOCK_PLACE_ORDER_MSG,
  CART_TRANSFER_STOCK_PLACE_ORDER_MSG,
  CART_PLACE_ORDER_BTN,
  WORK_ORDER_DETAILS_DEVICE_LIST_MODAL_TITLE,
  EDIT_QUANTITY_BTN,
  CONFIRMATION_BTN,
  REQUEST_STOCK_PLACE_ORDER_MODAL_TITLE,
  TRANSFER_STOCK_PLACE_ORDER_MODAL_TITLE,
  CONFIRMATION_MODAL_TITLE,
  CART_CONFIRMARION_CONTENT_TITLE1,
  TRACK_ORDER_CARD_ITEM_TITLE1,
  TRACK_ORDER_CARD_ITEM_TITLE2,
  REJECT_STOCK_MODAL_TITLE,
  TRACK_ORDER_CARD_ITEM_TITLE3,
  TRACK_ORDER_CARD_ITEM_TITLE4,
  TRACK_ORDER_CARD_ITEM_TITLE5,
  APPROVE_STOCK_MODAL_TITLE,
  TRACK_ORDER_CARD_ITEM_TITLE6,
  TRACK_ORDER_CARD_ITEM_TITLE7,
  TRACK_ORDER_INPUT_ITEM_LABEL1,
  ACKNOWLEDGE_CONFIRM_MSG,
  BODY_HEADER_TRACKORDER_DETAILS_PATH,
  TRACK_ORDER_DETAILS_CONTENT_TITLE1,
  TRACK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE1,
  TRACK_ORDER_DETAILS_CARD_ITEM_SECTION_TITLE1,
  TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE1,
  TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE2,
  TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE3,
  TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE4,
  TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE5,
  TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE6,
  TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE7,
  TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE8,
  TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE9,
  TRACK_ORDER_DETAILS_CARD_ITEM_SECTION_TITLE2,
  TRACK_ORDER_DETAILS_CARD_SUB_ITEM2_TITLE1,
  TRACK_ORDER_DETAILS_CARD_SUB_ITEM2_TITLE2,
  TRACK_ORDER_DETAILS_CARD_SUB_ITEM2_TITLE3,
  TRACK_ORDER_DETAILS_CARD_SUB_ITEM2_TITLE4,
  TRACK_ORDER_DETAILS_CARD_SUB_ITEM2_TITLE5,
  TRACK_ORDER_DETAILS_CARD_SUB_ITEM2_TITLE6,
  TRACK_ORDER_DETAILS_CARD_ITEM_SECTION_TITLE3,
  TRACK_ORDER_DETAILS_CARD_SUB_ITEM3_TITLE1,
  TRACK_ORDER_DETAILS_CARD_SUB_ITEM3_TITLE2,
  TRACK_ORDER_DETAILS_CARD_SUB_ITEM3_TITLE3,
  TRACK_ORDER_DETAILS_CARD_ITEM_SECTION_TITLE4,
  TRACK_ORDER_DETAILS_CARD_ITEM_SECTION_TITLE5,
  TRACK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE2,
  ADD_INSTALLER_BTN,
  TRACK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE3,
  TRACK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE4,
  SELECT_OPTION_DROP_DOWN,
  MANAGE_INSTALLER_INPUT_ITEM_LABEL1,
  BODY_HEADER_MANAGE_INSTALLER_TITLE1,
  BODY_HEADER_MANAGE_INSTALLER_TITLE2,
  MANAGE_INSTALLER_FILTER_ITEM_TITLE1,
  DELETE_BTN,
  EDIT_BTN,
  MANAGE_INSTALLER_CARD_ITEM_TITLE1,
  MANAGE_INSTALLER_CARD_ITEM_TITLE2,
  MANAGE_INSTALLER_CARD_ITEM_TITLE3,
  MANAGE_INSTALLER_CARD_ITEM_TITLE4,
  MANAGE_INSTALLER_CARD_ITEM_TITLE5,
  INSTALLER_DETAILS_MODAL_TITLE,
  MANAGE_INSTALLER_CARD_ITEM_TITLE6,
  MANAGE_INSTALLER_CARD_ITEM_TITLE7,
  ADD_INSTALLER_MODAL_TITLE,
  ADD_BTN,
  MANAGE_INSTALLER_CARD_ITEM_TITLE10,
  EDIT_INSTALLER_MODAL_TITLE,
  CUSTOM_MONTH_PICKER_LABLE,
  SUPERVISOR_SIDEBAR_TITLE1,
  SUPERVISOR_SIDEBAR_TITLE2,
  CART_RETURN_STOCK_VALIDATION,
  SUPERVISOR_SIDEBAR_OSI_TITLE,
  SUPERVISOR_SIDEBAR_OST_TITLE,
  SUPERVISOR_SIDEBAR_BTU_TITLE,
  SUPERVISOR_SIDEBAR_SUBTYPE1,
  SUPERVISOR_SIDEBAR_SUBTYPE2,
  SUPERVISOR_SIDEBAR_SUBTYPE3,
  SUPERVISOR_SIDEBAR_SUBTYPE4,
  ADD_SERIAL_NUMBER_MODAL_TITLE,
  ADD_ROW_BTN,
  DELETE_INSTALLER_MODAL_TITLE,
  CANCEL_BTN,
  SWITCH_BTN,
  PROFILE_INFO_PERSONAL_DETAILS,
  PROFILE_INFO_OUTLET,
  PROFILE_INFO_EMAIL,
  PROFILE_INFO_ADDRESS,
  PROFILE_INFO_CONTACT,
  LOGOUT_BTN,
  REPORT,
  BODY_HEADER_ON_SITE_SUMMARY,
  BODY_HEADER_SUMMARY_PILL_ITEM1,
  BODY_HEADER_SUMMARY_PILL_ITEM2,
  BODY_HEADER_SUMMARY_PILL_ITEM3,
  SUPERVISOR_SIDEBAR_OSI_VALUE,
  SUPERVISOR_SIDEBAR_OST_VALUE,
  SUPERVISOR_SIDEBAR_BTU_VALUE,
  BODY_HEADER_SWITCH_USER,
  BODY_HEADER_INVENTORY_ACKNOWLEDGE_BANNAR_TEXT,
  USER_TYPE_INPUT_ITEM_LABEL,
  SELECT_SUPERVISOR_INPUT_ITEM_LABEL,
  SELECT_SUPERVISOR_INPUT_ITEM_VALUE,
  SWITCH_USER_CARD_ITEM_TITLE1,
  SWITCH_USER_CARD_ITEM_TITLE2,
  SWITCH_USER_CARD_ITEM_TITLE3,
  ACKNOWLEDGE_STOCK_MODAL_TITLE,
  SWITCH_USER_CARD_ITEM_TITLE4,
  SWITCH_USER_CARD_ITEM_TITLE5,
  SWITCH_USER_CARD_ITEM_TITLE6,
  SWITCH_TO_ANOTHER_ACCOUNT_TITLE1,
  SWITCH_TO_ANOTHER_ACCOUNT_TITLE2,
  SWITCH_FROM_ANOTHER_ACCOUNT_TITLE2,
  SWITCH_TO_TITLE,
  SWITCH_FROM_TITLE,
  BODY_HEADER_ADMIN_DASHBORD_BANNAR_TEXT1,
  COMMENTS,
  UPLOAD_DOCUMENT_MODAL_BODY_WARNING_MSG1,
  UPLOAD_DOCUMENT_MODAL_BODY_WARNING_MSG2,
  OST_DEVICE_CHECKBOX_LABLE,
  BODY_HEADER_ADMIN_DASHBORD_BANNAR_TEXT2,
  SWITCH_USER_MODAL_TO_TITLE,
  SWITCH_USER_MODAL_FROM_TITLE,
  SWITCH_ADMIN_USER_ROLE_MODAL,
  SWITCH_SUPER_USER_ROLE_MODAL,
  REQUEST_STOCK_RADIO_BTN_LABLE,
  TRANSFER_STOCK_RADIO_BTN_LABLE,
  RETURN_STOCK_RADIO_BTN_LABLE,
  DISTRIBUTOR_CODE_INPUT_ITEM_LABEL,
  INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE8,
  INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE9,
  INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE10,
  BODY_HEADER_UPDATE_ACTIVITY,
  LOGOUT_MODAL_TITLE1,
  LOGOUT_MODAL_TITLE2,
  LOGOUT_MODAL_MESSAGE,
  SUPERVISOR_SIDEBAR_SUBTYPE5,
  SUPERVISOR_SIDEBAR_SUBTYPE5_TEXT,
  WORK_ORDER_CARD_ITEM_TITLE10,
  INSTALLER_TIMELINE,
  TODAY_BTN,
  WORK_ORDER_CARD_ITEM_TITLE11,
  WORK_ORDER_CARD_ITEM_TITLE12,
  WORK_ORDER_CARD_ITEM_TITLE13,
  UPDATE_BTN,
  BODY_HEADER_UPDATE_WORKORDER_TEXT,
  BODY_HEADER_UPDATE_WORKORDER_STEP_TEXT1,
  BODY_HEADER_ACKNOWLEDGE_PATH,
  BODY_HEADER_UPDATE_WORKORDER_STEP_TEXT2,
  UPDATE_WORK_ORDER_CONTENT_TITLE1,
  UPDATE_WORK_ORDER_CONTENT_TITLE2,
  UPDATE_WORK_ORDER_LOCATION_BTN_LAVEL1,
  UPDATE_WORK_ORDER_MODAL_NOTE_MSG,
  UPDATE_WORK_ORDER_LOCATION_BTN_PART_LAVEL1,
  UPDATE_WORK_ORDER_LOCATION_BTN_LAVEL3,
  UPDATE_WORK_ORDER_MODAL_TITLE1,
  UPDATE_WORK_ORDER_MODAL_NOTE_TITLE1,
  UPDATE_WORK_ORDER_OSI_CONTENT_ACCORDION_TITLE1,
  UPDATE_WORK_ORDER_OSI_CONTENT_ACCORDION_TITLE2,
  WORK_ORDER_DETAILS_MODAL_DEVICE_NAME,
  WORK_ORDER_DETAILS_MODAL_DEVICE_TYPE,
  UPDATE_WORK_ORDER_OST_CONTENT_ACCORDION_TITLE2,
  UPDATE_WORK_ORDER_OSI_CONTENT_ACCORDION_TITLE3,
  UPDATE_WORK_ORDER_OST_CONTENT_ACCORDION_TITLE1,
  UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_TITLE1,
  UPDATE_WORK_ORDER_MODAL_NAME_TITLE1,
  BROWSE_FILE_BTN,
  UPDATE_WORK_ORDER_LOCATION_BTN_PART_LAVEL2,
  UPDATE_WORK_ORDER_LOCATION_BTN_PART_LAVEL3,
  UPDATE_WORK_ORDER_LONATION_LABLE,
  UPDATE_WORK_ORDER_TIME_TITLE,
  UPDATE_WORK_ORDER_OTHER_REMARS_LABEL,
  UPDATE_WORK_ORDER_STATUS_INPUT_ITEM_LABEL,
  UPDATE_WORK_ORDER_RETURN_REASON_INPUT_ITEM_LABEL,
  UPDATE_WORK_ORDER_RETURN_REMARKS_INPUT_ITEM_LABEL,
  UPDATE_WORK_ORDER_RETURN_REMARKS_COMMENT_INPUT_ITEM_LABEL,
  UPLOAD_BTN,
  SAVE_AND_CONTINUE_BTN,
  SUBMIT_BTN,
  INVALID_SERIAL_NUMBER,
  UPDATE_WORK_ORDER_UPLOAD_DOUMENT_MODAL_NOTE_MSG,
  UPDATE_WORK_ORDER_INFRASTRUCTURE_INPUT_ITEM_LABEL,
  UPDATE_WORK_ORDER_DESIRED_INSTALLATION_INPUT_ITEM_LABEL,
  UPDATE_WORK_ORDER_ADDITIONAL_CABLE_LENGTH_INPUT_ITEM_LABEL,
  UPDATE_WORK_ORDER_DOWNLOAD_SPEED_LAN_INPUT_ITEM_LABEL,
  UPDATE_WORK_ORDER_DOWNLOAD_SPEED_WIFI_INPUT_ITEM_LABEL,
  UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_TITLE1,
  UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_TITLE2,
  UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_TITLE3,
  UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_TITLE4,
  UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL1,
  UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL2,
  UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL3,
  UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL4,
  INVALID_MSG,
  INSTALLER_NAME,
  CUSTOMER_NAME,
  UPLOAD_DOCUMENT_MODAL_TITLE,
  UPLOAD_DOCUMENT_MODAL_BODY_TITLE1,
  UPLOAD_DOCUMENT_MODAL_BODY_TITLE2,
  UPLOAD_DOCUMENT_TYPE_INPUT_ITEM_LABEL,
  UPLOAD_DOCUMENT_MODAL_BODY_TEXT_MSG1,
  UPLOAD_DOCUMENT_MODAL_BODY_TEXT_MSG2,
  UPLOAD_DOCUMENT_MODAL_BODY_TEXT_MSG3,
  UPLOAD_DOCUMENT_MODAL_BODY_VIEW_TITLE1,
  UPLOAD_DOCUMENT_MODAL_BODY_VIEW_TITLE2,
  INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE11,
  UPLOAD_DOCUMENT_MODAL_BODY_FILE_UPLOAD_LABEL,
  UPDATE_WORK_ORDER_CONFIRMATION_CONTENT_ITEM1,
  UPDATE_WORK_ORDER_CONFIRMATION_CONTENT_ITEM2,
  PROFILE_INFO_USER_NAME,
  PROFILE_INFO_USERID,
  BIG_CALENDAR_OSI_LABEL,
  BIG_CALENDAR_OST_LABEL,
  BIG_CALENDAR_BTU_LABEL,
  INVENTORY_TRANSFER_STORE_ID_LABLE,
  BIG_CALENDAR_ASSIGN_TYPE_LABEL,
  BIG_CALENDAR_UNASSIGN_TYPE_LABEL,
  BODY_HEADER_DASHBORD_BANNAR_SUB_TEXT1,
  MANAGE_INSTALLER_CARD_ITEM_TITLE11,
  MANAGE_INSTALLER_CARD_ITEM_TITLE12,
  MANAGE_INSTALLER_CARD_ITEM_TITLE13,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE6,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE7,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE8,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE9,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE10,
  WORK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE4,
  UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_DEVICE_COLLECTED_LABEL,
  CASE_DETAILS_CARD_SUB_ITEM_TITLE1,
  CASE_DETAILS_CARD_SUB_ITEM_TITLE2,
  CASE_DETAILS_CARD_SUB_ITEM_TITLE3,
  CASE_DETAILS_CARD_SUB_ITEM_TITLE4,
  CASE_DETAILS_CARD_SUB_ITEM_TITLE5,
  CASE_DETAILS_CARD_SUB_ITEM_TITLE6,
  CASE_DETAILS_CARD_SUB_ITEM_TITLE7,
  CASE_DETAILS_CARD_SUB_ITEM_TITLE8,
  CASE_DETAILS_CARD_SUB_ITEM_TITLE9,
  CASE_DETAILS_CARD_SUB_ITEM_TITLE10,
  CASE_DETAILS_CARD_SUB_ITEM_TITLE11,
  CASE_DETAILS_CARD_SUB_ITEM_TITLE12,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE11,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE12,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE13,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE14,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE15,
  INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE9,
  CART_REQUEST_STOCK_SUCESSFULL_MSG,
  CART_TRANSFER_STOCK_SUCESSFULL_MSG,
  CART_RETURN_STOCK_SUCESSFULL_MSG,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE16,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE17,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE18,
  CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE19,
  UPDATE_WORK_ORDER_CONFIRMATION_CONTENT_ITEM3,
  WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE16,
  INVENTORY_CARD_TRACK_STOCK_ITEM_DETAILS_TITLE1,
  WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE17,
  WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE18,
  INVENTORY_CARD_TRACK_STOCK_ITEM_DETAILS_TITLE2,
  INVENTORY_CARD_TRACK_STOCK_ITEM_DETAILS_TITLE3,
  INVENTORY_CARD_TRACK_STOCK_ITEM_DETAILS_TITLE4,
  INVENTORY_CARD_TRACK_STOCK_ITEM_DETAILS_TITLE5,
  INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE1,
  INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE2,
  INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE3,
  INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE4,
  ACKNOWLEDGE_STOCK_TABLE_ITEM_TITLE1,
  ACKNOWLEDGE_STOCK_TABLE_ITEM_TITLE2,
  ACKNOWLEDGE_STOCK_TABLE_ITEM_TITLE3,
  INVENTORY_CARD_ACKNOWLEDGEED_ITEM_DETAILS_TITLE1,
  INVENTORY_CARD_ACKNOWLEDGEED_ITEM_DETAILS_TITLE2,
  INVENTORY_CARD_ACKNOWLEDGEED_ITEM_DETAILS_TITLE3,
  INVENTORY_CARD_ACKNOWLEDGEED_ITEM_DETAILS_TITLE4,
  SUPERADMINGMAIL,
  SUPERADMINMOBILE,
  REPORT_EXT_SN_LABLE,
  REPORT_EXT_ACCESS_LABLE,
  REPORT_EXT_INSTL_NAME_LABLE,
  REPORT_EXT_COM_NAM_LABLE,
  REPORT_EXT_INSTL_CLS_TIME_LABLE,
  REPORT_EXT_APPDATE_LABLE,
  REPORT_EXT_ORD_NO_LABLE,
  REPORT_EXT_WO_ID_LABLE,
  REPORT_EXT_APP_TYP_LABLE,
  REPORT_EXT_APP_SUB_TYPE_LABLE,
  REPORT_EXT_STATUS_LABLE,
  REPORT_EXT_CUS_NAM_LABLE,
  REPORT_EXT_CUS_ID_LABLE,
  REPORT_EXT_CUS_CNO_LABLE,
  REPORT_EXT_ADDRESS_LABLE,
  REPORT_EXT_PLAN_LABLE,
  REPORT_EXT_HSI_USER_NAME_LABLE,
  REPORT_EXT_NOTeS_FOR_INSTL_LABLE,
  REPORT_EXT_INSTL_CON_NO_LABLE,
  REPORT_EXT_RGW_IMEI_LABLE,
  REPORT_EXT_BTU_IMEI_LABLE,
  REPORT_EXT_DES_INTL_LABLE,
  REPORT_EXT_ADD_CAB_LABLE,
  REPORT_EXT_RETUN_REMARK_LABLE,
  REPORT_EXT_WO_CLOSE_LABLE,
  REPORT_EXT_ADDRESS_TYPE_LABLE,
  REPORT_EXT_ACT_CLOSE_LABLE,
  REPORT_EXT_MESH_IMEI_LABLE,
  REPORT_EXT_RETUN_REMARK_ADD_LABLE,
  REPORT_EXT_OST_ELEM_LABLE,
  REPORT_EXT_OST_RESOLUTION_LABLE,
  REPORT_EXT_OST_PROB_LABLE,
};
