import React from "react";
import { useNavigate } from "react-router-dom";
import { headers, UpdateStock1 } from "../../Api/ApiCalls";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { Constent } from "../../Assets/Constants/Constent";
import {
  CloseModalIcon,
  ConfirmationIcon,
} from "../../Assets/Images/CelcomSvgImages";
import { ReturnPlaceOrderTable } from "../Common/Table";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  removeReturnStockData,
  removeSelectedReturnStockData,
  setConfirmationPageData,
} from "../../ReduxFeature/StateFeatures/CartDataState";

const ReturnStockPlaceOrderModal = ({
  selectedReturnStockList,
  returndSerialNumberObj,
  userDataObj,
  returnReasonValue,
  pageState,
  setPageState,
  returnRemarkValue,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function returnStockApiCall() {
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);

    let stockItem = [];
    selectedReturnStockList?.map((selectedItemNumberObj) => {
      returndSerialNumberObj[selectedItemNumberObj?.sAPMaterialCode]?.map(
        (serialNumberItem) => {
          if (
            serialNumberItem?.valid === "valid" &&
            serialNumberItem?.serialNumber?.length > 0
          ) {
            stockItem.push({
              approvedQuantity: "1",
              receivedQuantity: "1",
              requestedQuantity: "1",
              sapMaterialCode: selectedItemNumberObj?.sAPMaterialCode,
              serialNumber: serialNumberItem?.serialNumber,
              transferQuantity: "1",
            });
          }
        }
      );
    });

    const request = {
      action: ApiConstant.RETURN_STOCK_CART_REQUEST_ACTION,
      approvalUser: ApiConstant.RETURN_STOCK_CART_REQUEST_BLANK_VALUE,
      approverComments:
        "Reason: " + returnReasonValue + ", Remarks:" + returnRemarkValue,
      receiveUser: null,
      receiverStore: ApiConstant.INVENTORY_REQUESTING_STORE_ID,
      senderStore: userDataObj?.userData?.activeUserData?.userData?.outletId,
      shipUser: ApiConstant.RETURN_STOCK_CART_REQUEST_BLANK_VALUE,
      sourceSystem: ApiConstant.RETURN_STOCK_CART_REQUEST_SOURCE_SYSTEM,
      starterPackFlag: null,
      stockItem: stockItem,
      storeId: userDataObj?.userData?.activeUserData?.userData?.outletId,
      transactionNo: null,
      transferId: null,
    };
    if (returnReasonValue?.length > 0 && returnRemarkValue?.length > 0) {
      setPageState({ ...pageState, apiCallAction: true });
      UpdateStock1("/fibre/inventory/updateStockTransfer", request, header)
        .then((respose) => {
          setPageState({ ...pageState, apiCallAction: false });
          const errorCode = respose["header"][0]?.errorCode;
          if (errorCode === "Success") {
            dispatch(
              setConfirmationPageData({
                comment: Constent.CART_RETURN_STOCK_SUCESSFULL_MSG,
                listOfIds: [respose["header"][0]?.transferId],
              })
            );
            selectedReturnStockList?.map((data) => {
              dispatch(removeSelectedReturnStockData(data));
              dispatch(removeReturnStockData(data));
            }) && navigate("/cart/confirm");
          } else {
            toast.error("errorMessage", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 10000,
              theme: "colored",
            });
          }
        })
        .catch((error) => {
          setPageState({ ...pageState, apiCallAction: false });
          let errorMessage;
          if (error.response.status === 500 || error.response.status === 503) {
            errorMessage =
              ApiConstant.SUBMIT_RETURN_SERIAL_NUMBERS_ERROR_MESSAGE;
            toast.error(errorMessage, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 10000,
              theme: "colored",
            });
          } else {
            errorMessage = error.response.statusText;
            toast.error(errorMessage, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 10000,
              theme: "colored",
            });
          }
        });
    } else {
      toast.warning(ApiConstant.RETURN_LOV_ERROR_MESSAGE, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    }
  }
  return (
    <div
      className="modal fade return-stock-place-order-modal"
      id="returnStockPlaceOrderModal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby="returnStockPlaceOrderModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pb-0">
            <div className="wrapper-modal-header pt-5">
              <div className="modal-header-title-section">
                <ConfirmationIcon />
                <h5
                  className="modal-title"
                  id="returnStockPlaceOrderModalLabel"
                >
                  {Constent.CONFIRMATION_MODAL_TITLE}
                </h5>
                <h5 className="modal-title">
                  {Constent.RETURN_STOCK_PLACE_ORDER_MODAL_TITLE}
                </h5>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <CloseModalIcon />
                </span>
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="wrapper-modal-body">
              <ReturnPlaceOrderTable
                title1={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE1}
                title2={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE2}
                title3={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE3}
                title4={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE4}
                title5={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE9}
                data={selectedReturnStockList}
                returnedSerialNumberList={returndSerialNumberObj}
              />
            </div>
          </div>
          <div className="modal-footer">
            <div className="refresh-btn-section" data-dismiss="modal">
              <span>{Constent.EDIT_QUANTITY_BTN}</span>
            </div>
            <div
              className="search-btn-section"
              data-dismiss="modal"
              onClick={returnStockApiCall}
            >
              {Constent.CONFIRMATION_BTN}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnStockPlaceOrderModal;
