import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { headers, InactiveInsatller } from '../../Api/ApiCalls';
import { ApiConstant } from '../../Assets/Constants/ApiConstants';
import { Constent } from '../../Assets/Constants/Constent';
import { CloseModalIcon, ConfirmationIcon } from '../../Assets/Images/CelcomSvgImages';
import { setInstallerData } from '../../ReduxFeature/StateFeatures/InstallerDataState';
import { CardItemGroup } from '../Common/ModalHelper';

import { toast } from 'react-toastify';
const DeleteInstallerModal = ({ userDataObj, installerDetails, setGlobalState, pageState, setPageState }) => {
    const [deleteInstallerComment, setDeleteInstallerComment] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function DeleteInstallerApiCall() {
        setPageState({...pageState, apiCallAction: true, apiCallActionMsg: "Deleting..."});
        
        const header = headers(
            userDataObj?.userData?.activeUserData?.authToken
        );

        const deleteInstallerRequest = {
            userId: installerDetails?.userNbr,
            requestBy: userDataObj?.userData?.activeUserData?.userData?.userId,
            comment: deleteInstallerComment
        }
        InactiveInsatller("/fibre/installer/inactive", deleteInstallerRequest, header).then(response => {
            setPageState({...pageState, apiCallAction: false});
            if (response.status!=200) {
                toast.error(ApiConstant.GET_INSTALLER_ERROR_MESSAGE_WRONG,
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 10000,
                        theme: 'colored'
                    }
                );
            }
            dispatch(setInstallerData({
                type: ApiConstant.CREATE_INSTALLER_RESPONSE,
                value: installerDetails?.userId
            }));
            dispatch(setInstallerData({
                type: ApiConstant.MANAGE_INSTALLER_INPUT,
                value: installerDetails?.userId
            }));
            navigate("/manageinstaller/confirm");
        }).catch(error => {
            setPageState({...pageState, apiCallAction: false});
            toast.error(ApiConstant.GET_INSTALLER_ERROR_MESSAGE_WRONG || error.response.statusText,
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 10000,
                    theme: 'colored'
                }
            );
        });
    }

    return (
        <div className="modal fade delete-installer-details-modal"
            id="deleteInstallerModal" data-backdrop="static"
            data-keyboard="false" tabIndex="-1" aria-labelledby="deleteInstallerModalLabel"
            aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header pb-0">
                        <div className="wrapper-modal-header pt-5">
                            <div className="modal-header-title-section">
                                <ConfirmationIcon />
                                <div className="modal-header-title-item">
                                    {Constent.CONFIRMATION_MODAL_TITLE}
                                </div>
                                <div className="modal-header-title-item">
                                    {Constent.DELETE_INSTALLER_MODAL_TITLE}
                                </div>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <CloseModalIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="wrapper-modal-body">
                            <div className="mobal-body-section-group">
                                <CardItemGroup title={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE3}
                                    value={installerDetails?.userId || "-"} />
                                <CardItemGroup title={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE1}
                                    value={installerDetails?.firstName || "-"} />
                                <CardItemGroup title={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE2}
                                    value={installerDetails?.email || "-"} />
                                <CardItemGroup title={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE5}
                                    value={installerDetails?.mobileNo || "-"} />
                                <CardItemGroup title={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE6}
                                    value={installerDetails?.idType || "-"} />
                                <CardItemGroup title={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE7}
                                    value={installerDetails?.id || "-"} />
                                <CardItemGroup title={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE4}
                                    value={installerDetails?.district || "-"} />

                            </div>
                            <div className="mobal-body-section-group">
                                <div className="form-group m-0">
                                    <textarea className="form-control" id="deleteInstallerCommentArea"
                                        placeholder=" " spellCheck="false"
                                        onChange={(e) => setDeleteInstallerComment(e.target.value)}></textarea>
                                    <label htmlFor="deleteInstallerCommentArea">{Constent.COMMENT_INPUT_ITEM_LABEL}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="refresh-btn-section" data-dismiss="modal">
                            <span>{Constent.CANCEL_BTN}</span>
                        </div>
                        <div className="okay-btn-section" data-dismiss="modal" onClick={DeleteInstallerApiCall}>
                            <span>{Constent.CONFIRMATION_BTN}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteInstallerModal;