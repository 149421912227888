import React, { useState, useEffect } from 'react';
import { Header } from '../../Components/Header/Header';
import { useSelector, useDispatch } from 'react-redux';
import SupervisorSidebar from '../../Components/MainBody/Sidebar/SupervisorSidebar';
import Footer from '../../Components/Footer/Footer';
import { HeaderNavChild2 } from '../../Components/Common/HeaderNav';
import { Constent } from '../../Assets/Constants/Constent';
import WorkOrderDetailsBodyContent from '../../Components/MainBody/BodyContent/Supervisor/WorkOrderDetailsBodyContent';

import '../../Assets/CSS/WorkOrderDetailsPage.css';
import AssignWorkOrderModal from '../../Components/Modals/AssignWorkOrderModal';
import '../../Assets/CSS/AssignWorkOrderModal.css';
import '../../Assets/CSS/WorkOrderReturndModal.css';
import WorkOrderReturnedModal from '../../Components/Modals/WorkOrderReturnedModal';
import WorkOrderDetailsTimelineModal from '../../Components/Modals/WorkOrderDetailsTimelineModal';
import { useNavigate } from 'react-router-dom';
import { AssignInstallerLovCalls, DocumentManagement, headers, InstallerNotes, ReassignWorkOrder, WorkOrderDetails, DirectLovCalls } from '../../Api/ApiCalls';
import { resetWorkOrderDetailsData, setDocumentManagementData, setSearchWorkOrderDetailsData, setInstallerNotesData } from '../../ReduxFeature/StateFeatures/WorkOrderDataState';
import { ApiConstant } from '../../Assets/Constants/ApiConstants';
import { ToastContainer, toast } from 'react-toastify';
import { saveLovs } from '../../ReduxFeature/StateFeatures/LovDataState';
import moment from 'moment';
import WorkOrderDocumentsListModal from '../../Components/Modals/WorkOrderDocumentsListModal';
import { ApiActionLoader } from '../../Components/Loaders/ApiActionLoader';
import WorkOrderDetailsDeviceListModal from '../../Components/Modals/WorkOrderDetailsDeviceListModal';

const WorkOrderDetailsPage = ({ globalState, setGlobalState }) => {
    const userDataObj = useSelector((state) => state.userDataInfo);
    const workOrderObj = useSelector((state) => state.workOrderInfo);
    const lovDataObj = useSelector((state) => state.lovDataInfo);


    const { selectedWorkOrderItem, documentList, workOrderDetails } = workOrderObj;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [pageState, setPageState] = useState({
        dataLoader: false,
        isRevertBack: false,

        state: "",
        district: "",
        assignTo: "",
        comment: "",
        supervisor: "",


        districtList: [],
        installerList: [],
        installerNotes: [],

        // Error Part
        getDataError: false,
        getDataErrorMsg: "",

        apiCallAction: false,
        selectedOutletId: "",
        listOfSupervisorsForAdmin: []
    });

    const [supervisorListWithUserGroup, setSupervisorListWithUserGroup] = useState({})

    function ErrorMessageSetUp({ error, message1, message2 }) {
        let errorMessage;
        if (error.response.status === 500 || error.response.status === 503) {
            errorMessage = message1;
            toast.error(errorMessage,
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 10000,
                    theme: 'colored'
                }
            );
        }
        else if (error.response.status === 404) {
            errorMessage = message2;
            toast.warning(errorMessage,
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 10000,
                    theme: 'colored'
                }
            );
        }
        else {
            errorMessage = error.response.statusText;
            toast.error(errorMessage,
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 10000,
                    theme: 'colored'
                }
            );
        }
        return errorMessage;
    }
    function workOrderDetailsApiCall() {
        dispatch(resetWorkOrderDetailsData());
        dispatch(setInstallerNotesData([]));

        setPageState({ ...pageState, dataLoader: true });
        //  Header
        const header = headers(
            userDataObj?.userData?.activeUserData?.authToken
        );
        const workOrderDetailsRequest = {
            "activityId": selectedWorkOrderItem?.itemId || null,
            "userId": userDataObj?.userData?.activeUserData?.userData?.userId,
            "outletId": userDataObj?.userData?.activeUserData?.userData?.outletId
        }

        const documentListRequest = {
            workOrder_Id: selectedWorkOrderItem?.itemId
        }

        WorkOrderDetails("/fibre/activity/details", workOrderDetailsRequest, header).then(response => {
            dispatch(setSearchWorkOrderDetailsData(response));

            setPageState({
                ...pageState, dataLoader: false,
                getDataError: false
            });
            getInstallerNotes(header, response?.orderId)
        }).catch(error => {
            dispatch(resetWorkOrderDetailsData());
            let errorMessage;
            if (error.response.status === 500 || error.response.status === 503) {
                errorMessage = ApiConstant.WORK_ORDER_LIST_ERROR_MESSAGE;
                toast.error(errorMessage,
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 10000,
                        theme: 'colored'
                    }
                );
            }
            else if (error.response.status === 404) {
                errorMessage = ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE;
                toast.warning(errorMessage,
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 10000,
                        theme: 'colored'
                    }
                );
            }
            else {
                errorMessage = error.response.statusText;
                toast.error(errorMessage,
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 10000,
                        theme: 'colored'
                    }
                );
            }
            setPageState({
                ...pageState, dataLoader: false,
                getDataError: true,
                getDataErrorMsg: errorMessage
            });
        });


        DocumentManagement("/fibre/file/list", documentListRequest, header).then(response => {
            dispatch(setDocumentManagementData({
                type: ApiConstant.WORK_ORDER_DOCUMENT_LIST,
                value: response
            }));
        }).catch(() => {
            // TODO document why this arrow function is empty
        });


    }
    // Reassign activity Call
    function reassignActivityApiCall() {
        //  Header       
        setPageState({ ...pageState, apiCallAction: true });
        const header = headers(
            userDataObj?.userData?.activeUserData?.authToken
        );
        const installerIndex = pageState?.installerList?.findIndex(obj => obj?.userId === pageState?.assignTo);

        const installerInfo = pageState?.installerList[installerIndex];

        const isAdmin = userDataObj?.userData?.activeUserData?.userRole === Constent.ADMIN_ROLE;

        const request = {
            itemId: selectedWorkOrderItem?.itemId,
            reassignRemarks: pageState?.comment,
            trigerredBy: moment().format("YYYY-MM-DD") + "T" + moment().format("HH:mm:ss") + ".450Z",
            trigerredOn: moment().format("YYYY-MM-DD") + "T" + moment().format("HH:mm:ss") + ".450Z",
            userGroup: pageState?.isRevertBack ? "Super_Admin" :
                isAdmin ? pageState?.supervisor : userDataObj?.userData?.activeUserData?.userData?.userId,
            userId: (isAdmin || pageState?.isRevertBack) ? pageState?.supervisor : pageState?.assignTo,
            telNumber: pageState?.isRevertBack ? Constent.SUPERADMINMOBILE : installerInfo?.mobileNumber,
            firstName: installerInfo?.firstName,
            email: pageState?.isRevertBack ? Constent.SUPERADMINGMAIL : installerInfo?.email,
            outletId: userDataObj?.userData?.activeUserData?.userData?.outletId,
            revertBy: userDataObj?.userData?.activeUserData?.userData?.userId,
            orderType: selectedWorkOrderItem?.workOrderSubType + " " + selectedWorkOrderItem?.workOrderType,
            assignType: pageState?.isRevertBack ? "REVERT" : "ASSIGN",
            appointmentDate: selectedWorkOrderItem?.resolutionDate,
            orderId: workOrderObj?.selectedWorkOrderItem?.orderId,

        }

        ReassignWorkOrder("/fibre/update/reassignInstaller", request, header).then(response => {
            setPageState({ ...pageState, apiCallAction: false });
            if (response?.status?.length > 0
                && response?.status?.includes("SUCCESS")) {
                navigate("/workorder/confirm");
            } else {
                toast.error(ApiConstant.WORK_ORDER_REASSIGN_INSTALLER_WORKING_MESSAGE,
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 10000,
                        theme: 'colored'
                    }
                );
            }
        }).catch(error => {
            setPageState({ ...pageState, apiCallAction: false });
            ErrorMessageSetUp({
                error: error,
                message1: ApiConstant.WORK_ORDER_REASSIGN_INSTALLER_WORKING_MESSAGE,
                message2: ApiConstant.WORK_ORDER_REASSIGN_INSTALLER_WORKING_MESSAGE
            });
        });
    }
    // List of supervisor Group by Outlet/ Access Partner
    function getSupervisorListByOutletApiCall() {
        //  Header
        const header = headers(
            userDataObj?.userData?.activeUserData?.authToken
        );
        DirectLovCalls("/fibre/getadminlovs/supervisorlist", header).then(response => {
            setSupervisorListWithUserGroup(response);
        }).catch(err => {
            setSupervisorListWithUserGroup({});
        })
    }

    function getInstallerNotes(header, orderId) {

        const installerNotesRequest = {
            "orderId": orderId
        }
        InstallerNotes("/fibre/activity/getnotes", installerNotesRequest, header).then((response) => {

            dispatch(setInstallerNotesData(response));
            // setPageState({
            //     ...pageState, 
            //     installerNotes: response?.orderNotesList

            // });
        }).catch(error => {
            dispatch(setInstallerNotesData([]));
        })
    }

    useEffect(() => {
        if (userDataObj?.userData?.activeUserData?.userRole === Constent.ADMIN_ROLE) {
            getSupervisorListByOutletApiCall();
        }
        if (globalState?.selectedWorkOrderId === null) {
            navigate("/workorder");
        }
        else {
            workOrderDetailsApiCall();
        }
        if (lovDataObj?.assigninstaller === null || lovDataObj?.assigninstaller === undefined ||
            lovDataObj?.assigninstaller?.length === 0 || (userDataObj?.userData?.activeUserData?.userData?.userId) !== (userDataObj?.userData?.loginUserData?.userData?.userId)) {
            //  Header
            const header = headers(
                userDataObj?.userData?.activeUserData?.authToken
            );

            const assignInstallerLovRequest = {
                supervisorId: userDataObj?.userData?.activeUserData?.userData?.userId,
                outletId: userDataObj?.userData?.activeUserData?.userData?.outletId
            }

            AssignInstallerLovCalls("/fibre/installer/listByDistrict", assignInstallerLovRequest, header).then(response => {
                dispatch(saveLovs({
                    key: ApiConstant.LOV_STORE_STATE_DIST_INSTALLER_KEY,
                    value: response?.stateList
                }));
            });
        }
    }, [userDataObj?.userData?.activeUserData?.userData?.userId]);


    return (
        <div className="page-start-section">
            {
                pageState?.apiCallAction && <ApiActionLoader msg={"Assigning..."} />
            }
            <Header userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} tab1={true} />
            <div className={globalState?.sidebarState ? "body-start-section sidebar-active-section" : "body-start-section"}>
                {/* Sidebar */}
                <SupervisorSidebar userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} />
                <div className="body-full-content-section">
                    <HeaderNavChild2 activeItem={Constent.BODY_HEADER_WORKORDER_DETAILS_PATH} backTab={"/workorder"} />
                    <WorkOrderDetailsBodyContent userDataObj={userDataObj}
                        globalState={globalState} setGlobalState={setGlobalState} documentList={documentList}
                        pageState={pageState} setPageState={setPageState} selectedWorkOrder={workOrderObj?.selectedWorkOrderItem}
                        workOrderDetailsObj={workOrderObj?.workOrderDetails} />
                </div>
                <Footer />
            </div>
            <AssignWorkOrderModal pageState={pageState} setPageState={setPageState}
                selectedWorkOrderItem={workOrderObj?.selectedWorkOrderItem} workOrderDetails={workOrderDetails}
                assignInstallerLov={lovDataObj?.assigninstaller} reassignActivityApiCall={reassignActivityApiCall} userDataObj={userDataObj}
                supervisorListWithUserGroup={supervisorListWithUserGroup} setSupervisorListWithUserGroup={setSupervisorListWithUserGroup}
            />
            <WorkOrderReturnedModal returnWorkOrderList={workOrderDetails?.relatedWorkOrderDetails?.relatedWorkOrder || []} />
            <WorkOrderDetailsDeviceListModal ownOrderDetails={workOrderDetails?.ownOrderDetails} />
            <WorkOrderDetailsTimelineModal workOrderDetailsObj={workOrderObj?.workOrderDetails} />
            {
                documentList?.length > 0 && <WorkOrderDocumentsListModal documentList={documentList} userDataObj={userDataObj} />
            }
            <ToastContainer />
        </div>
    )
}

export default WorkOrderDetailsPage;