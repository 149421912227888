import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { ApiConstant } from '../../../../Assets/Constants/ApiConstants';
import { Constent } from '../../../../Assets/Constants/Constent';
import { setTrackStockFieldValue } from '../../../../ReduxFeature/StateFeatures/InventoryDataState';
import { TableRowUpDownArrow } from '../../../Common/Table';
import AcknowledgeStockDetailsCardContent from './InventoryHelper/AcknowledgeStockDetailsCardContent';

const InventoryAcknowledgeStockBodyContent = ({ pageState, setPageState, globalState, acknowledgeSelectedSerialNumbersList,
    selectedTrackStockItemDetails, acknowledgeTotalAvailableItems, acknowledgeStockApiCall }) => {
    const dispatch = useDispatch();

    const [serialInput, setSerialInput] = useState("");
    const [count, setCount] = useState(0);
    
    useEffect(() => {
        setCount(0)
        Object.keys(acknowledgeSelectedSerialNumbersList)?.map(mCode => {
            setCount(count+acknowledgeSelectedSerialNumbersList[mCode]?.length)
        });
        
    }, [acknowledgeSelectedSerialNumbersList]);

    return (
        <div className='acknowledge-stock-details-body-content-section'>
            <div className="acknowledge-stock-content-row-section">
                <AcknowledgeStockDetailsCardContent pageState={pageState} acknowledgeTotalAvailableItems={acknowledgeTotalAvailableItems}
                    setPageState={setPageState} globalState={globalState} serialInput={serialInput} setSerialInput={setSerialInput} />
            </div>
            <div className={`acknowledge-stock-content-row-section ${globalState?.sidebarState ? "active-sidebar" : ""}`}>
                <div className="acknowledge-stock-col-item-section"></div>
                <div className="acknowledge-stock-col-item-section">
                    <div className="acknowledge-stock-col-item">
                        <table className='custom-table'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>
                                        <span>{Constent.VIEW_STOCK_TABLE_ITEM_TITLE1}</span>
                                        <TableRowUpDownArrow />
                                    </th>
                                    <th>
                                        <span>{Constent.ACKNOWLEDGE_STOCK_TABLE_ITEM_TITLE1}</span>
                                        <TableRowUpDownArrow />
                                    </th>
                                    <th>
                                        <span>{Constent.ACKNOWLEDGE_STOCK_TABLE_ITEM_TITLE2}</span>
                                        <TableRowUpDownArrow />
                                    </th>
                                    <th>
                                        <span>{Constent.ACKNOWLEDGE_STOCK_TABLE_ITEM_TITLE3}</span>
                                        <TableRowUpDownArrow />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    selectedTrackStockItemDetails?.itemDetails?.map((itemDetails, index) => {
                                        const filterItem = itemDetails?.serialNumbers?.filter(item => {
                                            if (item?.toLowerCase().includes(serialInput.toLowerCase())) {
                                                return item;
                                            }
                                        });
                                        return (
                                            filterItem?.map((serialNumberItem, itemIndex) => {
                                                const checkboxValue = acknowledgeSelectedSerialNumbersList[itemDetails?.sapMaterialCode]?.includes(serialNumberItem);
                                                return (
                                                    <tr className={"className"} key={serialNumberItem + itemIndex + index} 
                                                        hidden={(pageState.activeTab == Constent.INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE2 && checkboxValue == false) ||
                                                        (pageState.activeTab == Constent.INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE3 && checkboxValue == true) ||
                                                        (pageState.activeTab == Constent.INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE4)}>
                                                        {/* hidden for user when switch tab */}
                                                        <th data-title={Constent.VIEW_STOCK_TABLE_ITEM_TITLE1}>
                                                            <div className="custom-control custom-checkbox">
                                                                <input className="custom-control-input" value=""
                                                                    checked={checkboxValue}
                                                                    type="checkbox" onChange={e => {
                                                                        if (e.target.checked && !(acknowledgeSelectedSerialNumbersList[itemDetails?.sapMaterialCode]?.includes(serialNumberItem))) {
                                                                            dispatch(setTrackStockFieldValue({
                                                                                type: ApiConstant.ACKNOWLEDGE_CHECKBOX_SELECTION,
                                                                                value: {
                                                                                    materialCode: itemDetails?.sapMaterialCode,
                                                                                    serialNumber: serialNumberItem
                                                                                }
                                                                            }))
                                                                        } else {
                                                                            dispatch(setTrackStockFieldValue({
                                                                                type: ApiConstant.ACKNOWLEDGE_CHECKBOX_UNSELECTION,
                                                                                value: {
                                                                                    materialCode: itemDetails?.sapMaterialCode,
                                                                                    serialNumber: serialNumberItem
                                                                                }
                                                                            }))
                                                                        }
                                                                    }}
                                                                    id={`serialNumberCheckBox${serialNumberItem + itemIndex + index}`} />
                                                                <label className="custom-control-label"
                                                                    htmlFor={`serialNumberCheckBox${serialNumberItem + itemIndex + index}`}></label>
                                                            </div>
                                                        </th>
                                                        <td data-title={Constent.VIEW_STOCK_TABLE_ITEM_TITLE2}>{(index + 1 + itemIndex) || "-"}</td>
                                                        <td data-title={Constent.VIEW_STOCK_TABLE_ITEM_TITLE3}>{serialNumberItem || "-"}</td>
                                                        <td data-title={Constent.VIEW_STOCK_TABLE_ITEM_TITLE4}><span>{itemDetails?.sapMaterialCode || "-"}</span></td>
                                                        <td data-title={Constent.VIEW_STOCK_TABLE_ITEM_TITLE4}><span>{itemDetails?.deviceModelDesc || "-"}</span></td>
                                                    </tr>
                                                )
                                            })
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="acknowledge-stock-col-item">
                        <div className="acknowledge-stock-col-inner-item-section">
                            <div className="refresh-btn-section">
                                {Constent.CANCEL_BTN}
                            </div>
                        </div>
                        <div className="acknowledge-stock-col-inner-item-section">
                            <div className={`search-btn-section ${count ? '' : 'inactive-btn'}`} data-toggle="modal" 
                            data-target={count ? "#requestStockPlaceOrderModal" : ""}>
                                {Constent.SUBMIT_BTN}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="acknowledge-stock-col-item-section"></div>
            </div>
        </div>
    )
}

export default InventoryAcknowledgeStockBodyContent;