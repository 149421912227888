import { createSlice } from "@reduxjs/toolkit";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";

const initialState = {
  requestStockList: [],
  returnStockList: [],
  transferStockList: [],
  returndSerialNumberObj: {},

  returnReasonValue: "",
  returnRemarkValue: "",
  storeTransferValue: "",

  selectedRequestStockList: [],
  selectedReturnStockList: [],
  selectedTransferStockList: [],

  confirmationPageTransferId: [],
  confirmationPageMessage: "",
};

export const CartDataState = createSlice({
  name: "cartDataState",
  initialState,
  reducers: {
    // Creating the Actions
    /* ---- Save Request Stock Data ---- */
    saveRequestStockData: (state, action) => {
      let recivedData = action.payload;

      const index = state.requestStockList?.findIndex(
        (obj) => obj?.sAPMaterialCode === recivedData?.sAPMaterialCode
      );

      if (index === -1) {
        // Data is not Present
        state.requestStockList?.push(recivedData);
      } else {
        // Data is present
        state.requestStockList[index].qnt = 1;
      }

      return state;
    },
    /* ---- Save Transfer Stock Data ---- */
    saveTransferStockData: (state, action) => {
      let recivedData = action.payload;

      const index = state.transferStockList?.findIndex(
        (obj) => obj?.sAPMaterialCode === recivedData?.sAPMaterialCode
      );

      if (index === -1) {
        // Data is not Present
        state.transferStockList?.push(recivedData);
      } else {
        // Data is present
        state.transferStockList[index].qnt = 1;
      }

      return state;
    },
    /* ---- Save Return Stock Data ---- */
    saveReturnStockData: (state, action) => {
      let recivedData = action.payload;

      const index = state.returnStockList?.findIndex(
        (obj) => obj?.sAPMaterialCode === recivedData?.sAPMaterialCode
      );
      if (index === -1) {
        // Data is not Present
        state.returnStockList?.push(recivedData);
        state.returndSerialNumberObj[recivedData?.sAPMaterialCode] = [
          {
            serialNumber: "",
            valid: "",
          },
        ];
      }

      return state;
    },
    setCartRequestStockQuntData: (state, action) => {
      const { type, value } = action.payload;
      switch (type) {
        case ApiConstant.ADD_STOCK_QUNT_UPDATE: {
          const { materialCode, qunt, totalAvailableQty } = value;

          const index = state?.requestStockList?.findIndex(
            (obj) => obj?.sAPMaterialCode === materialCode
          );

          if (index !== -1) {
            state.requestStockList[index] = {
              ...state.requestStockList[index],
              reqQuantity:
                totalAvailableQty > parseInt(qunt)
                  ? parseInt(qunt)
                  : totalAvailableQty,
            };
          }
          break;
        }
        case ApiConstant.SELECTED_STOCK_QUNT_UPDATE: {
          const { materialCode, qunt, totalAvailableQty } = value;

          const index = state?.selectedRequestStockList?.findIndex(
            (obj) => obj?.sAPMaterialCode === materialCode
          );

          if (index !== -1) {
            state.selectedRequestStockList[index] = {
              ...state.selectedRequestStockList[index],
              reqQuantity:
                totalAvailableQty > parseInt(qunt)
                  ? parseInt(qunt)
                  : totalAvailableQty,
            };
          }
          break;
        }
        case ApiConstant.REMOVE_CART_CONFIRMATION_MSG:
          return {
            ...state,
            confirmationPageTransferId: [],
            confirmationPageMessage: "",
          };
        default:
          break;
      }
    },
    setCartTransferStockQuntData: (state, action) => {
      const { type, value } = action.payload;
      switch (type) {
        case ApiConstant.ADD_STOCK_QUNT_UPDATE: {
          const { materialCode, qunt, totalAvailableQty } = value;

          const index = state?.transferStockList?.findIndex(
            (obj) => obj?.sAPMaterialCode === materialCode
          );

          if (index !== -1) {
            state.transferStockList[index] = {
              ...state.transferStockList[index],
              reqQuantity:
                totalAvailableQty > parseInt(qunt)
                  ? parseInt(qunt)
                  : totalAvailableQty,
            };
          }
          break;
        }
        case ApiConstant.SELECTED_STOCK_QUNT_UPDATE: {
          const { materialCode, qunt, totalAvailableQty } = value;

          const index = state?.selectedTransferStockList?.findIndex(
            (obj) => obj?.sAPMaterialCode === materialCode
          );

          if (index !== -1) {
            state.selectedTransferStockList[index] = {
              ...state.selectedTransferStockList[index],
              reqQuantity:
                totalAvailableQty > parseInt(qunt)
                  ? parseInt(qunt)
                  : totalAvailableQty,
            };
          }
          break;
        }
        case ApiConstant.REMOVE_CART_CONFIRMATION_MSG:
          return {
            ...state,
            confirmationPageTransferId: [],
            confirmationPageMessage: "",
          };
        default:
          break;
      }
    },
    /* ---- Save and Remove Return Stock Serial ---- */
    returnStockSerialNumberData: (state, action) => {
      const { materialCode, data } = action.payload;
      // switch (type) {
      // case ApiConstant.CART_REDUX_RETURN_SERIAL_NUMBER_ADD_VALUE:

      return {
        ...state,
        returndSerialNumberObj: {
          ...state.returndSerialNumberObj,
          [materialCode]: [
            ...new Map(
              data
                ?.filter((item) => {
                  if (item?.valid === "valid") {
                    return item;
                  }
                })
                .map((item) => [item["serialNumber"], item])
            ).values(),
          ],
        },
      };
      //     default:
      //         return { ...state }
      // }
    },
    /* ---- Return Stock Lov Value Change Data ---- */
    setReturnStockLovData: (state, action) => {
      const { type, value } = action.payload;
      switch (type) {
        case ApiConstant.CART_REDUX_RETURN_REASON_VALUE:
          return { ...state, returnReasonValue: value };
        case ApiConstant.CART_REDUX_RETURN_REMARK_VALUE:
          return { ...state, returnRemarkValue: value };
        default:
          return { ...state };
      }
    },
    setStoreTransferLovData: (state, action) => {
      const { type, value } = action.payload;
      switch (type) {
        case ApiConstant.CART_REDUX_TRANSFER_REMARK_VALUE:
          return { ...state, storeTransferValue: value };
        default:
          return { ...state };
      }
    },
    /* ---- Save Return Stock Data ---- */
    saveReturnStockData: (state, action) => {
      let recivedData = action.payload;

      const index = state.returnStockList?.findIndex(
        (obj) => obj?.sAPMaterialCode === recivedData?.sAPMaterialCode
      );
      if (index === -1) {
        // Data is not Present
        state.returnStockList?.push(recivedData);
        state.returndSerialNumberObj[recivedData?.sAPMaterialCode] = [
          {
            serialNumber: "",
            valid: "",
          },
        ];
      }

      return state;
    },
    /* ---- Save Selected Request Stock Data (select All) ---- */
    saveAllSelectedRequestStockData: (state) => {
      return { ...state, selectedRequestStockList: state?.requestStockList };
    },
    /* ---- Save Selected Transfer Stock Data (select All) ---- */
    saveAllSelectedTransferStockData: (state) => {
      return { ...state, selectedTransferStockList: state?.transferStockList };
    },
    /* ---- Save Selected Request Stock Data ---- */
    saveSelectedRequestStockData: (state, action) => {
      let selectedData = action.payload;

      // Cheking is the item is present or not
      const index = state.selectedRequestStockList?.findIndex(
        (obj) => obj?.sAPMaterialCode === selectedData?.sAPMaterialCode
      );

      if (index === -1) {
        // Data is not Present

        // state.selectedRequestStockList?.push(selectedData);
        return {
          ...state,
          selectedRequestStockList:
            state?.selectedRequestStockList?.concat(selectedData),
        };
      }

      return state;
    },
    /* ---- Save Selected Request Stock Data ---- */
    saveSelectedTransferStockData: (state, action) => {
      let selectedData = action.payload;

      // Cheking is the item is present or not
      const index = state.selectedTransferStockList?.findIndex(
        (obj) => obj?.sAPMaterialCode === selectedData?.sAPMaterialCode
      );

      if (index === -1) {
        // Data is not Present

        // state.selectedTransferStockList?.push(selectedData);
        return {
          ...state,
          selectedTransferStockList:
            state?.selectedTransferStockList?.concat(selectedData),
        };
      }

      return state;
    },
    /* ---- Save Selected Request Stock Data (select All) ---- */
    saveAllSelectedReturnStockData: (state) => {
      return { ...state, selectedReturnStockList: state?.returnStockList };
    },
    /* ---- Save Selected Returned Stock Data ---- */
    saveSelectedReturnStockData: (state, action) => {
      let selectedData = action.payload;

      // Cheking is the item is present or not
      const index = state.selectedReturnStockList?.findIndex(
        (obj) => obj?.sAPMaterialCode === selectedData?.sAPMaterialCode
      );

      if (index === -1) {
        // Data is not Present

        return {
          ...state,
          selectedReturnStockList:
            state?.selectedReturnStockList?.concat(selectedData),
        };
      }

      return state;
    },
    /* ---- Remove Request Stock Data ---- */
    removeRequestStockData: (state, action) => {
      let recivedData = action.payload;

      const index = state.requestStockList?.findIndex(
        (obj) => obj?.sAPMaterialCode === recivedData?.sAPMaterialCode
      );
      // const selectedListIndex = state.requestStockList?.findIndex(obj => obj?.id === recivedData?.id);
      // Data is present
      state.requestStockList?.splice(index, 1);
      // if(selectedListIndex !== -1){
      //     state.selectedRequestStockList?.splice(index, 1);
      // }

      return state;
    },
    /* ---- Remove Transfer Stock Data ---- */
    removeTransferStockData: (state, action) => {
      let recivedData = action.payload;

      const index = state.transferStockList?.findIndex(
        (obj) => obj?.sapMaterialCode === recivedData?.sapMaterialCode
      );
      // const selectedListIndex = state.transferStockList?.findIndex(obj => obj?.id === recivedData?.id);
      // Data is present
      state.transferStockList?.splice(index, 1);
      // if(selectedListIndex !== -1){
      //     state.selectedTransferStockList?.splice(index, 1);
      // }

      return state;
    },
    /* ---- Remove Return Stock Data ---- */
    removeReturnStockData: (state, action) => {
      let recivedData = action.payload;

      const index = state.returnStockList?.findIndex(
        (obj) => obj?.sAPMaterialCode === recivedData?.sAPMaterialCode
      );

      // Data is present
      state.returnStockList?.splice(index, 1);
      delete state?.returndSerialNumberObj[recivedData?.sAPMaterialCode];

      return state;
    },
    /* ---- Remove Selected Request Stock Data (deselect all)---- */
    removeAllSelectedRequestStockData: (state) => {
      return { ...state, selectedRequestStockList: [] };
    },
    /* ---- Remove Selected Transfer Stock Data (deselect all)---- */
    removeAllSelectedTransferStockData: (state) => {
      return { ...state, selectedTransferStockList: [] };
    },
    /* ---- Remove Selected Request Stock Data ---- */
    removeSelectedRequestStockData: (state, action) => {
      let recivedData = action.payload;

      const index = state.selectedRequestStockList?.findIndex(
        (obj) => obj?.sAPMaterialCode === recivedData?.sAPMaterialCode
      );

      // Data is present
      index !== -1 && state.selectedRequestStockList?.splice(index, 1);

      return state;
    },

    /* ---- Remove Selected Request Stock Data ---- */
    removeSelectedTransferStockData: (state, action) => {
      let recivedData = action.payload;

      const index = state.selectedTransferStockList?.findIndex(
        (obj) => obj?.sapMaterialCode === recivedData?.sapMaterialCode
      );

      // Data is present
      index !== -1 && state.selectedTransferStockList?.splice(index, 1);

      return state;
    },

    /* ---- Remove Selected Return Stock Data (deselect all)---- */
    removeAllSelectedReturnStockData: (state) => {
      return { ...state, selectedReturnStockList: [] };
    },
    /* ---- Remove Selected Return Stock Data ---- */
    removeSelectedReturnStockData: (state, action) => {
      let recivedData = action.payload;

      const index = state.selectedReturnStockList?.findIndex(
        (obj) => obj?.sAPMaterialCode === recivedData?.sAPMaterialCode
      );

      // Data is present
      index !== -1 && state.selectedReturnStockList?.splice(index, 1);

      return state;
    },
    setConfirmationPageData: (state, action) => {
      const { comment, listOfIds } = action.payload;

      return {
        ...state,
        confirmationPageTransferId: listOfIds,
        confirmationPageMessage: comment,
      };
    },
    /* ---- Reset Data ---- */
    resetCartData: () => initialState,
  },
});

export const {
  saveRequestStockData,
  saveReturnStockData,
  saveTransferStockData,
  removeAllSelectedTransferStockData,
  removeSelectedTransferStockData,
  saveSelectedTransferStockData,
  saveAllSelectedTransferStockData,
  setCartTransferStockQuntData,
  saveAllSelectedRequestStockData,
  removeTransferStockData,
  saveAllSelectedReturnStockData,
  saveSelectedRequestStockData,
  saveSelectedReturnStockData,
  removeRequestStockData,
  setReturnStockLovData,
  returnStockSerialNumberData,
  setConfirmationPageData,
  setStoreTransferLovData,
  setCartRequestStockQuntData,
  removeReturnStockData,
  removeAllSelectedRequestStockData,
  removeSelectedRequestStockData,
  removeSelectedReturnStockData,
  removeAllSelectedReturnStockData,
  resetCartData,
} = CartDataState.actions;

export default CartDataState.reducer;
