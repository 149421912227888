import { Constent } from "../../Assets/Constants/Constent";

export const AssignWorkOrderModalBodyTextContent = ({ title1, title2, title3, title4, value1, value2, value3, value4 }) => {
    return (
        <div className="assign-work-order-modal-card-item-group">
            <div className="card-item-group">
                <div className="card-item-title">
                    {title1}
                </div>
                <div className="card-item-value">
                    {value1}
                </div>
            </div>
            <div className="card-item-group">
                <div className="card-item-title">
                    {title2}
                </div>
                <div className="card-item-value">
                    {value2}
                </div>
            </div>
            <div className="card-item-group">
                <div className="card-item-title">
                    {title3}
                </div>
                <div className="card-item-value">
                    {value3}
                </div>
            </div>
            <div className="card-item-group">
                <div className="card-item-title">
                    {title4}
                </div>
                <div className="card-item-value">
                    {value4}
                </div>
            </div>
        </div>
    )
}


export const ReturnWorkOrderModalBodyContent = ({ value1, value2, value3, value4,
    value5, value6, value7, value8 }) => {
    return (
        <div className="returnd-work-order-modal-card-item-group">
            <div className="returnd-work-order-modal-card-item-inner-group">
                <div className="card-item-group">
                    <div className="card-item-title">
                        {Constent.WORK_ORDER_CARD_ITEM_TITLE1}
                    </div>
                    <div className="card-item-value">
                        {value1}
                    </div>
                </div>
                <div className="card-item-group">
                    <div className="card-item-title">
                        {Constent.WORK_ORDER_CARD_ITEM_TITLE2}
                    </div>
                    <div className="card-item-value">
                        {value2}
                    </div>
                </div>
                <div className="card-item-group">
                    <div className="card-item-title">
                        {Constent.WORK_ORDER_CARD_ITEM_TITLE3}
                    </div>
                    <div className="card-item-value">
                        {value3}
                    </div>
                </div>
                <div className="card-item-group">
                    <div className="card-item-title">
                        {Constent.WORK_ORDER_CARD_ITEM_TITLE7}
                    </div>
                    <div className="card-item-value">
                        {value4}
                    </div>
                </div>
            </div>
            <div className="returnd-work-order-modal-card-item-inner-group">
                <div className="card-item-group">
                    <div className="card-item-title">
                        {Constent.WORK_ORDER_CARD_ITEM_TITLE12}
                    </div>
                    <div className="card-item-value">
                        {value5}
                    </div>
                </div>
                {/* <div className="card-item-group">
                    <div className="card-item-title">
                        {Constent.WORK_ORDER_CARD_ITEM_TITLE5}
                    </div>
                    <div className="card-item-value">
                        {value6}
                    </div>
                </div>
                <div className="card-item-group">
                    <div className="card-item-title">
                        {Constent.WORK_ORDER_CARD_ITEM_TITLE8}
                    </div>
                    <div className="card-item-value">
                        {value7}
                    </div>
                </div>
                <div className="card-item-group">
                    <div className="card-item-title">
                        {Constent.WORK_ORDER_CARD_ITEM_TITLE6}
                    </div>
                    <div className="card-item-value">
                        {value8}
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export const InseallerDetailsModalBodyContent = ({ installerNameVal, userNameVal, emailVal, contactNumVal,
    idTypeVal, idNumberVal, districtVal }) => {
    return (
        <div className="installer-details-modal-card-item-group">
            <div className="installer-details-modal-card-item-inner-group">
                <div className="card-item-group">
                    <div className="card-item-title">
                        {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE3}
                    </div>
                    <div className="card-item-value">
                        {installerNameVal || "-"}
                    </div>
                </div>
                <div className="card-item-group">
                    <div className="card-item-title">
                        {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE1}
                    </div>
                    <div className="card-item-value">
                        {userNameVal || "-"}
                    </div>
                </div>
                <div className="card-item-group">
                    <div className="card-item-title">
                        {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE2}
                    </div>
                    <div className="card-item-value">
                        {emailVal || "-"}
                    </div>
                </div>
                <div className="card-item-group">
                    <div className="card-item-title">
                        {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE5}
                    </div>
                    <div className="card-item-value">
                        {contactNumVal || "-"}
                    </div>
                </div>
            </div>
            <div className="installer-details-modal-card-item-inner-group">
                <div className="card-item-group">
                    <div className="card-item-title">
                        {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE6}
                    </div>
                    <div className="card-item-value">
                        {idTypeVal || "-"}
                    </div>
                </div>
                <div className="card-item-group">
                    <div className="card-item-title">
                        {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE7}
                    </div>
                    <div className="card-item-value">
                        {idNumberVal || "-"}
                    </div>
                </div>
                <div className="card-item-group">
                    <div className="card-item-title">
                        {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE4}
                    </div>
                    <div className="card-item-value">
                        {districtVal || "-"}
                    </div>
                </div>
                <div className="card-item-group"></div>
            </div>
        </div>
    );
}

export const CardItemGroup = ({ title, value}) => {
    return (
        <div className="card-item-group">
            <div className="card-item-title">
                {title}
            </div>
            <div className="card-item-value">
                {value}
            </div>
        </div>
    );
}