import React from 'react'
import { Constent } from '../../../../Assets/Constants/Constent';
import { DeleteIcon, EdditUserIcon } from '../../../../Assets/Images/CelcomSvgImages';

const ManageInstallerCard = ({ data, setPageState, pageState, globalState }) => {
    return (
        <div className={`manage-installer-card-section ${globalState?.sidebarState ? "sidebar-active": ""}`}>
            <div className="wrapper-manage-installer-card-section" onClick={() => {
                setPageState({ ...pageState, selectedInstallerData: data });
            }}>
                <div className="normal-card-item-section"
                    data-toggle="modal" data-target="#manageInstallerModal">
                    <div className="card-item-group">
                        <div className="card-item-title">
                            {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE3}
                        </div>
                        <div className="card-item-value">
                            {data?.userId || "-"}
                        </div>
                    </div>
                </div>
                <div className="normal-card-item-section" data-toggle="modal" data-target="#manageInstallerModal">
                    <div className="card-item-group">
                        <div className="card-item-title">
                            {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE1}
                        </div>
                        <div className="card-item-value">
                            {data?.firstName || "-"}
                        </div>
                    </div>
                    <div className="card-item-group">
                        <div className="card-item-title">
                            {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE2}
                        </div>
                        <div className="card-item-value">
                            {data?.email || "-"}
                        </div>
                    </div>
                    <div className="card-item-group">
                        <div className="card-item-title">
                            {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE4}
                        </div>
                        <div className="card-item-value">
                            {data?.district || "-"}
                        </div>
                    </div>
                    <div className="card-item-group">
                        <div className="card-item-title">
                            {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE5}
                        </div>
                        <div className="card-item-value">
                            {data?.mobileNo || "-"}
                        </div>
                    </div>
                </div>
                <div className="normal-card-item-section">
                    <div className="normal-card-item-inner-section">
                        <div className="normal-card-item-btn-section" data-toggle="modal" data-target="#deleteInstallerModal">
                            <DeleteIcon />
                            {Constent.DELETE_BTN}
                        </div>
                        <div className="normal-card-item-btn-section" data-toggle="modal" data-target="#editInstallerModal">
                            <EdditUserIcon />
                            {Constent.EDIT_BTN}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageInstallerCard;