import React from "react";
import { Constent } from "../../../../Assets/Constants/Constent";
import AdminInventoryTrackRequestStockContent from "./InventoryHelper/AdminInventoryTrackRequestStockContent";
import AdminInventoryTrackReturnStockContent from "./InventoryHelper/AdminInventoryTrackReturnStockContent";

const AdminInventoryBodyContent = ({
  userDataObj,
  globalState,
  pageState,
  setPageState,
  invDataObj,
}) => {
  const adminUser =
    userDataObj?.userData?.loginUserData?.userRole === Constent.ADMIN_ROLE &&
    userDataObj?.userData?.activeUserData?.userRole === Constent.ADMIN_ROLE;


  return (
    <div className="body-content-section">
      {/* Paggination */}
      {/* Body Content - Card */}
      <div className="wrapper-body-content-section">
        {pageState?.trackStockActiveRadioBtn ===
        Constent.REQUEST_STOCK_RADIO_BTN_LABLE ? (
          <AdminInventoryTrackRequestStockContent
            globalState={globalState}
            pageState={pageState}
            setPageState={setPageState}
            invDataObj={invDataObj}
          />
        ) : (
          <AdminInventoryTrackReturnStockContent
            globalState={globalState}
            pageState={pageState}
            setPageState={setPageState}
            invDataObj={invDataObj}
          />
        )}
      </div>
    </div>
  );
};

export default AdminInventoryBodyContent;
