import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ApiConstant } from '../../../../Assets/Constants/ApiConstants';
import { Constent } from '../../../../Assets/Constants/Constent';
import { saveSummaryData } from '../../../../ReduxFeature/StateFeatures/WorkOrderDataState';
import { NoDataFoundItemGroup } from '../../../Common/CardContent';
import WorkOrderSummaryCard from '../../Cards/Supervisor/WorkOrderSummaryCard';

const WorkOrderSummaryDetailsBodyContent = ({ pageState, setPageState, workOrderObj }) => {
    const allWorkOrderObj = useSelector((state) => state.workOrderInfo);
    const dispatch = useDispatch();

    const { summaryActivePillStatus, summarySctivePill } = allWorkOrderObj;
    return (
        <div className='body-content-section'>
            <div className="wrapper-body-content-section">
                <div className="work-order-summary-details-nav-section">
                    <div className={`work-order-summary-details-nav-item-section summary-status-yellow 
                    ${summaryActivePillStatus === Constent.SUPERVISOR_SIDEBAR_SUBTYPE1
                        && "work-order-summary-details-nav-item-active"}`} onClick={() => {
                            dispatch(saveSummaryData({
                                type: ApiConstant.SUMMARY_PILL_STATUS_ITEM,
                                value: Constent.SUPERVISOR_SIDEBAR_SUBTYPE1
                            }));
                            setPageState({ ...pageState, activePillStatus: Constent.SUPERVISOR_SIDEBAR_SUBTYPE1 });
                        }}>
                        <div className="summary-details-nav-item-title">
                            {Constent.SUPERVISOR_SIDEBAR_SUBTYPE1}
                        </div>
                        <div className="summary-details-nav-item-value">
                            {
                                workOrderObj[summarySctivePill]?.progress[0]?.toLocaleString('en-US', {
                                    minimumIntegerDigits: 2,
                                    useGrouping: false
                                }) || "00"
                            }
                        </div>
                    </div>
                    <div className={`work-order-summary-details-nav-item-section summary-status-red 
                    ${summaryActivePillStatus === Constent.SUPERVISOR_SIDEBAR_SUBTYPE2 && "work-order-summary-details-nav-item-active"}`}
                        onClick={() => {
                            dispatch(saveSummaryData({
                                type: ApiConstant.SUMMARY_PILL_STATUS_ITEM,
                                value: Constent.SUPERVISOR_SIDEBAR_SUBTYPE2
                            }));
                            setPageState({ ...pageState, activePillStatus: Constent.SUPERVISOR_SIDEBAR_SUBTYPE2 });
                        }}>
                        <div className="summary-details-nav-item-title">
                            {Constent.SUPERVISOR_SIDEBAR_SUBTYPE2}
                        </div>
                        <div className="summary-details-nav-item-value">
                            {
                                workOrderObj[summarySctivePill]?.aging[0]?.toLocaleString('en-US', {
                                    minimumIntegerDigits: 2,
                                    useGrouping: false
                                }) || "00"
                            }
                        </div>
                    </div>
                    <div className={`work-order-summary-details-nav-item-section summary-status-green
                    ${summaryActivePillStatus === Constent.SUPERVISOR_SIDEBAR_SUBTYPE3 && "work-order-summary-details-nav-item-active"}`}
                        onClick={() => {
                            dispatch(saveSummaryData({
                                type: ApiConstant.SUMMARY_PILL_STATUS_ITEM,
                                value: Constent.SUPERVISOR_SIDEBAR_SUBTYPE3
                            }));
                            setPageState({ ...pageState, activePillStatus: Constent.SUPERVISOR_SIDEBAR_SUBTYPE3 });
                        }}>
                        <div className="summary-details-nav-item-title">
                            {Constent.SUPERVISOR_SIDEBAR_SUBTYPE3}
                        </div>
                        <div className="summary-details-nav-item-value">
                            {
                                workOrderObj[summarySctivePill]?.done[0]?.toLocaleString('en-US', {
                                    minimumIntegerDigits: 2,
                                    useGrouping: false
                                }) || "00"
                            }
                        </div>
                    </div>
                    <div className={`work-order-summary-details-nav-item-section summary-status-blue 
                    ${summaryActivePillStatus === Constent.SUPERVISOR_SIDEBAR_SUBTYPE4 && "work-order-summary-details-nav-item-active"}`}
                        onClick={() => {
                            dispatch(saveSummaryData({
                                type: ApiConstant.SUMMARY_PILL_STATUS_ITEM,
                                value: Constent.SUPERVISOR_SIDEBAR_SUBTYPE4
                            }));
                            setPageState({ ...pageState, activePillStatus: Constent.SUPERVISOR_SIDEBAR_SUBTYPE4 })
                        }}>
                        <div className="summary-details-nav-item-title">
                            {Constent.SUPERVISOR_SIDEBAR_SUBTYPE4}
                        </div>
                        <div className="summary-details-nav-item-value">
                            {
                                workOrderObj[summarySctivePill]?.returned[0]?.toLocaleString('en-US', {
                                    minimumIntegerDigits: 2,
                                    useGrouping: false
                                }) || "00"
                            }
                        </div>
                    </div>
                </div>
                <div className="work-order-summary-details-content-section mt-2" >
                    {
                        summaryActivePillStatus === Constent.SUPERVISOR_SIDEBAR_SUBTYPE1 &&
                        <div className="wrapper-work-order-summary-details-content-section summary-card-status-yellow"
                            data-aos={workOrderObj[summarySctivePill]?.progress[2]?.length > 0 ? "fade-left" : null} data-aos-duration="700">
                            {
                                workOrderObj[summarySctivePill]?.progress[2]?.length > 0 ?
                                    workOrderObj[summarySctivePill]?.progress[2]?.map((data, index) => {
                                        return (
                                            <WorkOrderSummaryCard data={data} status={summaryActivePillStatus}
                                                key={`${summarySctivePill + Constent.SUPERVISOR_SIDEBAR_SUBTYPE1 + data?.itemId || index}`} />
                                        )
                                    })
                                    :
                                    <NoDataFoundItemGroup />
                            }
                        </div>
                    }
                    {
                        summaryActivePillStatus === Constent.SUPERVISOR_SIDEBAR_SUBTYPE2 &&
                        <div className="wrapper-work-order-summary-details-content-section summary-card-status-red"
                            data-aos={workOrderObj[summarySctivePill]?.aging[2]?.length > 0 ? "fade-left" : null} data-aos-duration="700">
                            {
                                workOrderObj[summarySctivePill]?.aging[2]?.length > 0 ?
                                    workOrderObj[summarySctivePill]?.aging[2]?.map((data, index) => {
                                        return (
                                            <WorkOrderSummaryCard data={data} status={summaryActivePillStatus}
                                                key={`${summarySctivePill + Constent.SUPERVISOR_SIDEBAR_SUBTYPE2 + data?.itemId || index}`} />
                                        )
                                    })
                                    :
                                    <NoDataFoundItemGroup />
                            }
                        </div>
                    }
                    {
                        summaryActivePillStatus === Constent.SUPERVISOR_SIDEBAR_SUBTYPE3 &&
                        <div className="wrapper-work-order-summary-details-content-section summary-card-status-green"
                            data-aos={workOrderObj[summarySctivePill]?.done[2]?.length > 0 ? "fade-left" : null} data-aos-duration="700">
                            {
                                workOrderObj[summarySctivePill]?.done[2]?.length > 0 ?
                                    workOrderObj[summarySctivePill]?.done[2]?.map((data, index) => {
                                        return (
                                            <WorkOrderSummaryCard data={data} status={summaryActivePillStatus}
                                                key={`${summarySctivePill + Constent.SUPERVISOR_SIDEBAR_SUBTYPE3 + data?.itemId || index}`} />
                                        )
                                    })
                                    :
                                    <NoDataFoundItemGroup />
                            }
                        </div>
                    }
                    {
                        summaryActivePillStatus === Constent.SUPERVISOR_SIDEBAR_SUBTYPE4 &&
                        <div className="wrapper-work-order-summary-details-content-section summary-card-status-blue"
                            data-aos={workOrderObj[summarySctivePill]?.returned[2]?.length > 0 ? "fade-left" : null} data-aos-duration="700">
                            {
                                workOrderObj[summarySctivePill]?.returned[2]?.length > 0 ?
                                    workOrderObj[summarySctivePill]?.returned[2]?.map((data, index) => {
                                        return (
                                            <WorkOrderSummaryCard data={data} status={summaryActivePillStatus}
                                                key={`${summarySctivePill + Constent.SUPERVISOR_SIDEBAR_SUBTYPE4 + data?.itemId || index}`} />
                                        )
                                    })
                                    :
                                    <NoDataFoundItemGroup />
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default WorkOrderSummaryDetailsBodyContent;