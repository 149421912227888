import React from "react";
import { Constent } from "../../../../../Assets/Constants/Constent";
import {
  CardItemGroup,
  CardWrapperItemGroup,
} from "../../../../Common/CardContent";
import {
  CustomDateFormator,
  TrackOrderStatusCal,
} from "../../../../Common/GenericFunctions";

const TrackOrderDetailsAccordion = ({
  userDataObj,
  trackOrderDetailsData,
  selectedTrackOrderData,
  downloadBtnState,
}) => {
  const orderDetails = trackOrderDetailsData?.orderDetails;
  const customerDetails = trackOrderDetailsData?.customerDetails;
  const contactInfo = trackOrderDetailsData?.customerDetails?.contactInfo;
  const productDetails = trackOrderDetailsData?.productDetails;
  const { status } = TrackOrderStatusCal(selectedTrackOrderData) || {
    status: "",
    addClass: "",
  };

  return (
    <div
      className="accordion accordion-borderless mb-1 custom-accordion-section"
      id="workOrderDetails"
    >
      {/* Card - 1 */}
      <div className="card">
        <div className="card-header" id="workOrderHeadingOne">
          <button
            className="btn btn-link btn-block text-dark text-left custom-accordion-btn-link"
            type="button"
            data-toggle="collapse"
            data-target="#workOrderCollapseOne"
            aria-expanded="false"
            aria-controls="workOrderCollapseOne"
          >
            {Constent.TRACK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE3}
          </button>
        </div>
        <div
          id="workOrderCollapseOne"
          className={`collapse show ${downloadBtnState ? "show" : ""}`}
          aria-labelledby="workOrderHeadingOne"
          data-parent="#workOrderCollapseOne"
        >
          <div className="card-body">
            <div className="custom-card-body-section">
              <div className="card-item-title-section">
                {Constent.TRACK_ORDER_DETAILS_CARD_ITEM_SECTION_TITLE1}
              </div>
              <CardWrapperItemGroup
                titleCol1={Constent.TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE1}
                titleCol2={Constent.TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE9}
                titleCol3={Constent.TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE5}
                valueCol1={orderDetails?.orderID || "-"}
                valueCol2={orderDetails?.orderType || "-"}
                valueCol3={
                  orderDetails?.customerRequestedDate?.split("T")[0] || "-"
                }
              />
              <CardWrapperItemGroup
                titleCol1={Constent.TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE4}
                titleCol2={Constent.TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE3}
                titleCol3={Constent.TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE6}
                valueCol1={selectedTrackOrderData?.orderContext || "-"}
                valueCol2={orderDetails?.orderChannel || "-"}
                valueCol3={selectedTrackOrderData?.dealerCode || "-"}
              />
              <CardWrapperItemGroup
                titleCol1={Constent.TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE7}
                titleCol2={Constent.TRACK_ORDER_DETAILS_CARD_SUB_ITEM1_TITLE8}
                valueCol1={orderDetails?.reasonCode || "-"}
                valueCol2={status || "-"}
              />
            </div>
            <div className="custom-card-body-section">
              <div className="card-item-title-section">
                {Constent.TRACK_ORDER_DETAILS_CARD_ITEM_SECTION_TITLE2}
              </div>
              <CardWrapperItemGroup
                titleCol1={Constent.TRACK_ORDER_DETAILS_CARD_SUB_ITEM2_TITLE1}
                titleCol2={Constent.TRACK_ORDER_DETAILS_CARD_SUB_ITEM2_TITLE2}
                titleCol3={Constent.TRACK_ORDER_DETAILS_CARD_SUB_ITEM2_TITLE3}
                valueCol1={
                  (customerDetails?.customerFirstName || "-") +
                  " " +
                  (customerDetails?.customerLastName || "-")
                }
                valueCol2={customerDetails?.customerId || "-"}
                valueCol3={
                  customerDetails?.customerDob
                    ? CustomDateFormator({
                        date: new Date(
                          Number(customerDetails?.customerDob)
                        ).toLocaleDateString(),
                      }) || "-"
                    : "-"
                }
              />
              <CardWrapperItemGroup
                titleCol1={Constent.TRACK_ORDER_DETAILS_CARD_SUB_ITEM2_TITLE4}
                titleCol2={Constent.TRACK_ORDER_DETAILS_CARD_SUB_ITEM2_TITLE5}
                titleCol3={Constent.TRACK_ORDER_DETAILS_CARD_SUB_ITEM2_TITLE6}
                valueCol1={customerDetails?.customerIDType || "-"}
                valueCol2={contactInfo?.customerPrimaryEmail || "-"}
                valueCol3={contactInfo?.customertelephoneNumber || "-"}
              />
            </div>
            {/* <div className="custom-card-body-section">
                            <div className="card-item-title-section">
                                {Constent.TRACK_ORDER_DETAILS_CARD_ITEM_SECTION_TITLE3}
                            </div>
                            <CardWrapperItemGroup titleCol1={Constent.TRACK_ORDER_DETAILS_CARD_SUB_ITEM3_TITLE1}
                                titleCol2={Constent.TRACK_ORDER_DETAILS_CARD_SUB_ITEM3_TITLE2}
                                titleCol3={Constent.TRACK_ORDER_DETAILS_CARD_SUB_ITEM3_TITLE3}
                                valueCol1={"-"}
                                valueCol2={"-"}
                                valueCol3={"-"} />
                        </div> */}
            {/* <div className="custom-card-body-section">
                            <div className="card-item-title-section">
                                {Constent.TRACK_ORDER_DETAILS_CARD_ITEM_SECTION_TITLE4}
                            </div>
                            <div className="card-empty-data-section">
                                No data available
                            </div>
                        </div>
                        <div className="custom-card-body-section">
                            <div className="card-item-title-section">
                                {Constent.TRACK_ORDER_DETAILS_CARD_ITEM_SECTION_TITLE5}
                            </div>
                            <div className="card-single-data-section">
                                No data available
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
      {/* Card - 2 */}
      <div className="card">
        <div className="card-header" id="workOrderHeadingTwo">
          <button
            className="btn btn-link btn-block text-dark text-left custom-accordion-btn-link collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#workOrderCollapseTwo"
            aria-expanded="false"
            aria-controls="workOrderCollapseTwo"
          >
            {Constent.TRACK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE2}
          </button>
        </div>
        <div
          id="workOrderCollapseTwo"
          className={`collapse ${downloadBtnState ? "show" : ""}`}
          aria-labelledby="workOrderHeadingTwo"
          data-parent="#workOrderCollapseTwo"
        >
          <div className="card-body">
            {/* <div className="custom-card-body-section">
                            <div className="card-item-title-section">
                                <CardWrapperItemGroup titleCol1={"IMEI:"}
                                valueCol1={"-"} />
                            </div>
                        </div> */}
            <div className="custom-card-body-section">
              <div className="card-item-title-section">{"Products:"}</div>
              <div className="wrapper-card-body-section">
                <div className="card-body-col-section">
                  <div className="card-item-group d-block">
                    <div className="card-item-title">{"Items:"}</div>
                    <div className="card-item-value">
                      {productDetails?.packageName}
                    </div>
                  </div>
                </div>
                <div className="card-body-col-section">
                  <div className="card-item-group d-block">
                    <div className="card-item-title">{"Fibre Service:"}</div>
                    <div className="card-item-value">
                      {productDetails?.fibreServiceItems?.map((item, index) => {
                        return <div key={item + index}>{item}</div>;
                      })}
                    </div>
                  </div>
                </div>
                <div className="card-body-col-section">
                  <div className="card-item-group d-block">
                    <div className="card-item-title">{"VOIP Service:"}</div>
                    <div className="card-item-value d-block">
                      {productDetails?.voipServiceItems?.map((item, index) => {
                        return <div key={item + index}>{item}</div>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Card - 3 */}
      {/* <div className="card">
                <div className="card-header" id="workOrderHeadingThree">
                    <button className="btn btn-link btn-block text-dark text-left custom-accordion-btn-link collapsed"
                        type="button" data-toggle="collapse" data-target="#workOrderCollapseThree"
                        aria-expanded="false" aria-controls="workOrderCollapseThree">
                        {Constent.TRACK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE3}
                    </button>
                </div>
                <div id="workOrderCollapseThree" className="collapse" aria-labelledby="workOrderHeadingThree" data-parent="#workOrderCollapseThree">
                    <div className="card-body">
                        <div className="custom-card-body-section">
                            <div className="card-item-title-section">
                                {"Activities:"}
                            </div>
                            <CardWrapperItemGroup titleCol1={"Type:"}
                                titleCol2={"Description:"}
                                titleCol3={"Created:"}
                                valueCol1={"-"}
                                valueCol2={"-"} 
                                valueCol3={"-"} />
                            <CardWrapperItemGroup titleCol1={"Status"}
                                titleCol2={"Remarks:"}
                                valueCol1={"-"}
                                valueCol2={"-"} />
                        </div>
                    </div>
                </div>
            </div> */}
      {/* Card - 4 */}
      {/* <div className="card">
                <div className="card-header" id="workOrderHeadingThree">
                    <button className="btn btn-link btn-block text-dark text-left custom-accordion-btn-link collapsed"
                        type="button" data-toggle="collapse" data-target="#workOrderCollapseThree"
                        aria-expanded="false" aria-controls="workOrderCollapseThree">
                        {Constent.TRACK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE3}
                    </button>
                </div>
                <div id="workOrderCollapseThree" className="collapse" aria-labelledby="workOrderHeadingThree" data-parent="#workOrderCollapseThree">
                    <div className="card-body">
                        <div className="custom-card-body-section">
                            <div className="card-item-title-section">
                                {"Activities:"}
                            </div>
                            <CardWrapperItemGroup titleCol1={"Type:"}
                                titleCol2={"Description:"}
                                titleCol3={"Created:"}
                                valueCol1={"-"}
                                valueCol2={"-"} 
                                valueCol3={"-"} />
                            <CardWrapperItemGroup titleCol1={"Status"}
                                titleCol2={"Remarks:"}
                                valueCol1={"-"}
                                valueCol2={"-"} />
                        </div>
                    </div>
                </div>
            </div> */}
    </div>
  );
};

export default TrackOrderDetailsAccordion;
