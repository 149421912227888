import React from 'react'
import { Constent } from '../../../../Assets/Constants/Constent';
import { BodyBreadcrumbs2 } from '../../../Common/BodyBreadcrumbs';
import { BackBtn } from '../../../Common/Button';

const InventoryStockDetailsBodyHeader = ({ bannarTitle, activeItem }) => {
    return (
        <div className='inventory-view-details-header-start-section'>
            <div className="wrapper-view-details-header-section">
                <BodyBreadcrumbs2 secondItem={Constent.HEADER_MENU_ITEM4}
                    activeItem={activeItem} secondTab="/inventory" />
                <div className="inventory-view-details-header-content-group-section"
                    data-aos="fade-right" data-aos-easing="ease-in-out">
                    <div className="inventory-view-header-item">
                        <BackBtn navLink={"/inventory"} />
                    </div>
                    <div className="inventory-view-header-item">
                        {bannarTitle}
                    </div>
                    <div className="inventory-view-header-item">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default InventoryStockDetailsBodyHeader;