export const viewStockListRequestValidation = (request) => {
  const {
    sapMaterialCode,
    itemDesc,
    deviceTypeId,
    inventoryItemTypeId,
    itemLocationId,
    manufacturerId,
    userId,
  } = request;

  /*
    user Id, inventoryItemTypeId, itemLocationId, deviceTypeId != null,
    sapMaterialCode - only number  
    */
  return !(
    sapMaterialCode === null &&
    itemDesc === null &&
    deviceTypeId === null
  );
};

export const ReturnStockCartPlaceOrderBtnValidation = (
  selectedReturnStockList,
  returndSerialNumberObj
) => {
  /*
    If at list one cart is seleted and at list one serial number is valid then return true
    else if all seleted list have atlist one valid serial number then return true
    */
  let flag = true;
  let count = 0;

  selectedReturnStockList?.map((selectedItemNumberObj) => {
    if (
      returndSerialNumberObj[selectedItemNumberObj?.sAPMaterialCode]?.length ===
      0
    ) {
      flag = false;
    } else {
      returndSerialNumberObj[selectedItemNumberObj?.sAPMaterialCode]?.map(
        (serialNumberItem) => {
          if (
            serialNumberItem?.valid === "invalid" ||
            serialNumberItem?.valid === "" ||
            serialNumberItem?.valid?.length === 0 ||
            serialNumberItem?.valid?.serialNumber === 0
          ) {
            flag = false;
          } else {
            count += 1;
          }
        }
      );
    }
  });
  return { flag: flag, count: count };
};

export const AcknowledgeApiCallValidation = ({
  acknowledgeSelectedSerialNumbersList,
  acknowledgeTotalAvailableItems,
}) => {
  let toatlCount = 0;
  Object.keys(acknowledgeSelectedSerialNumbersList)?.map((mcode) => {
    if (acknowledgeSelectedSerialNumbersList[mcode]?.length > 0) {
      toatlCount += acknowledgeSelectedSerialNumbersList[mcode]?.length;
    }
  });

  return toatlCount === acknowledgeTotalAvailableItems;
};

export const ApproveStockApiCallValidation = ({ itemDetails }) => {
  /*
        Check all meterial code if request quantity == approve quantity
    */

  let flag = true;
  itemDetails?.map((data) => {
    if (
      parseInt(data?.approvedQuantity || 0) !==
      parseInt(data?.requestedQuantity)
    ) {
      flag = false;
    }
  });

  return flag;
};
