import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Constent } from '../../../Assets/Constants/Constent';

import { HeaderNavChild3 } from '../../../Components/Common/HeaderNav';
import Footer from '../../../Components/Footer/Footer';
import { Header } from '../../../Components/Header/Header';
import WorkOrderStatusUpdateConfirmationBodyContent from '../../../Components/MainBody/BodyContent/Installer/ConfirmationPagesContent/WorkOrderStatusUpdateConfirmationBodyContent';

import '../../../Assets/CSS/InstallerUpdateWorkOrderPage.css';

const UpdateWorkOrderStatusConfirmationPage = ({ globalState, setGlobalState }) => {
    const userDataObj = useSelector((state) => state.userDataInfo);
    
    return (
        <div className='page-start-section'>
            <Header userDataObj={userDataObj} globalState={globalState}
                setGlobalState={setGlobalState} installerTabFlag={true} />
            <div className="body-start-section">
                <div className="body-full-content-section">
                    <HeaderNavChild3 secondItem={Constent.HEADER_MENU_ITEM1}
                        secondTab={"/workorder"}
                        activeItem={Constent.BODY_HEADER_UPDATE_ACTIVITY} />
                    <WorkOrderStatusUpdateConfirmationBodyContent userDataObj={userDataObj}/>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default UpdateWorkOrderStatusConfirmationPage;