import React, { useState } from 'react'
import { Header } from '../../../Components/Header/Header';
import SupervisorSidebar from '../../../Components/MainBody/Sidebar/SupervisorSidebar';
import { useSelector } from 'react-redux';
import Footer from '../../../Components/Footer/Footer';
import { Constent } from '../../../Assets/Constants/Constent';
import { HeaderNavChild2 } from '../../../Components/Common/HeaderNav';
import AssignActivityConfirmationBodyContent from '../../../Components/MainBody/BodyContent/Supervisor/ConfirmationPagesContent/AssignActivityConfirmationBodyContent';

const AssignActivityConfirmation = ({ globalState, setGlobalState }) => {
    const userDataObj = useSelector((state) => state.userDataInfo);
    const workOrderObj = useSelector((state) => state.workOrderInfo);

    const { selectedWorkOrderItem } = workOrderObj;

    const [pageState, setPageState] = useState({
        activityId: "",
        userName: "",
        displayMsg: "You have successfully assigned the task."
    });
    return (
        <div className='page-start-section'>
            <Header userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} tab1={true} />
            <div className={globalState?.sidebarState ? "body-start-section sidebar-active-section" : "body-start-section"}>
                {/* Sidebar */}
                <SupervisorSidebar userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} />
                <div className="body-full-content-section">
                    <HeaderNavChild2 activeItem={Constent.HEADER_MENU_ITEM1}/>
                    <AssignActivityConfirmationBodyContent pageState={pageState} userDataObj={userDataObj}
                    selectedWorkOrderItem={selectedWorkOrderItem} />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default AssignActivityConfirmation;