import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiConstant } from "../Assets/Constants/ApiConstants";
import Footer from "../Components/Footer/Footer";
import { Header } from "../Components/Header/Header";
import SupervisorSidebar from "../Components/MainBody/Sidebar/SupervisorSidebar";
import ViewWorkorderReportBodyHearder from "../Components/MainBody/BodyHeader/ViewWorkorderReportBodyHearder";
import "../Assets/CSS/ViewWorkOrderReportPage.css";
import { ViewWorkOrderReportAdvSearchModal } from "../Components/Modals/ViewWorkOrderReportAdvSearchModal";
import ViewWorkorderReportBodyContent from "../Components/MainBody/BodyContent/ViewWorkorderReportBodyContent";
import { DirectLovCalls, ReportExtraction, headers } from "../Api/ApiCalls";
import { Constent } from "../Assets/Constants/Constent";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { saveLovs } from "../ReduxFeature/StateFeatures/LovDataState";

const ViewWorkOrderReportPage = ({ globalState, setGlobalState }) => {
  const userDataObj = useSelector((state) => state.userDataInfo);
  const workOrderDataObj = useSelector((state) => state.workOrderInfo);
  const lovDataObj = useSelector((state) => state.lovDataInfo);
  const dispatch = useDispatch();

  const [pageState, setPageState] = useState({
    workOrderListData: [],
    accessProvider: "",
    // totalItems: workOrderDataObj?.workOrderList?.length,
    // workOrderList: workOrderDataObj?.workOrderList,
    // bodyview: "Day",
    // toggleIcon: "List",

    // workOrderId: "",
    workOrderFromDate: "",
    workOrderToDate: "",
    // Advance Search Modal
    advanceSearchLovs: ApiConstant.WORK_ORDER_ADVANCE_SEARCH_LOV1,
    advanceSearchSubLovs: [],

    selectedAdvanceSearchType: "",
    selectedAdvanceSearchSubTypeList: [],

    dataLoader: false,
    // Error Part
    getDataError: false,
    getDataErrorMsg: "",

    formDateError: "",
    toDateError: "",
    selectedOutletId: "",
    supervisor: "",
    supervisorListWithUserGroup: [],
    accessProviderValue: null,
  });

  const [supervisorListWithUserGroup, setSupervisorListWithUserGroup] =
    useState({});

  function requestValidationCheck() {
    if (
      pageState?.workOrderFromDate?.length === 0 ||
      pageState?.workOrderToDate?.length === 0 ||
      moment(pageState?.workOrderFromDate) <=
        moment(moment(pageState?.workOrderToDate))
    ) {
      setPageState({
        ...pageState,
        formDateError: "",
        toDateError: "",
      });
      return true;
    } else {
      setPageState({
        ...pageState,
        formDateError: "Invalid Input",
        toDateError: "Invalid Input",
      });
      return false;
    }
  }
  function reportExtractionAPICall() {
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);

    const searchType = Constent.WORK_ORDER_INPUT_ITEM_LABEL1;

    const request = {
      workOrderId:
        searchType === Constent.WORK_ORDER_INPUT_ITEM_LABEL1 &&
        pageState?.workOrderId?.length > 0
          ? "FieldActivity-" + pageState?.workOrderId
          : null,
      orderNo:
        searchType === Constent.TRACK_ORDER_INPUT_ITEM_LABEL1 &&
        pageState?.workOrderId?.length > 0
          ? pageState?.workOrderId
          : null,
      customerId:
        searchType === Constent.WORK_ORDER_INPUT_ITEM_LABEL2 &&
        pageState?.workOrderId?.length > 0
          ? pageState?.workOrderId
          : null,
      fromDate: pageState?.workOrderFromDate,
      toDate: pageState?.workOrderToDate,
      accessProvider:
        userDataObj?.userData?.activeUserData?.userRole === Constent.ADMIN_ROLE
          ? pageState?.accessProviderValue
          : null,
      appointmentType:
        pageState?.selectedAdvanceSearchType?.length > 0
          ? pageState?.selectedAdvanceSearchType
          : null,
      userId: userDataObj?.userData?.activeUserData?.userData?.userId,
      companyName: userDataObj?.userData?.activeUserData?.userData?.outletName,
      userRole: userDataObj?.userData?.activeUserData?.userRole,
    };

    if (requestValidationCheck()) {
      setPageState({
        ...pageState,
        dataLoader: true,
        workOrderListData: [],
        formDateError: "",
        toDateError: "",
      });

      ReportExtraction(
        "/fibre/reportExtraction/reportExtractionDetails",
        request,
        header
      )
        .then((response) => {
          setPageState({
            ...pageState,
            getDataError: false,
            dataLoader: false,
            workOrderListData: response,
          });
        })
        .catch((error) => {
          let errorMessage;
          if (error.response.status == 500 || error.response.status == 503) {
            errorMessage = ApiConstant.WORK_ORDER_LIST_ERROR_MESSAGE;
            toast.error(errorMessage, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 10000,
              theme: "colored",
            });
          } else if (error.response.status == 404) {
            errorMessage = ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE;
            toast.warning(errorMessage, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 10000,
              theme: "colored",
            });
          } else {
            errorMessage = error.response.statusText;
          }

          setPageState({
            ...pageState,
            getDataError: true,
            dataLoader: false,
            totalItems: 0,
            workOrderList: [],
            getDataErrorMsg: errorMessage,
          });
        });
    } else {
      setPageState({
        ...pageState,
        dataLoader: false,
        formDateError: "Invalid Input",
        toDateError: "Invalid Input",
      });
    }
  }

  // List of supervisor Group by Outlet/ Access Partner

  useEffect(() => {
    if (
      userDataObj?.userData?.activeUserData?.userRole === Constent.ADMIN_ROLE
    ) {
      if (
        lovDataObj?.accessProviderLOV === null ||
        lovDataObj?.accessProviderLOV === undefined ||
        lovDataObj?.accessProviderLOV?.length === 0
      ) {
        const header = headers(
          userDataObj?.userData?.activeUserData?.authToken
        );
        DirectLovCalls(
          "/fibre/getlovs/getLovValues?lovKey=LOV_ACCESS_PROVIDER",
          header
        ).then((response) => {
          dispatch(
            saveLovs({
              key: ApiConstant.LOV_STORE_ACCESS_PROVIDERS_KEY,
              value: response,
            })
          );
        });
      }
    }
  }, []);
  useEffect(() => {
    requestValidationCheck();
  }, [pageState?.workOrderFromDate, pageState?.workOrderToDate]);

  return (
    <div className="page-start-section">
      <Header
        userDataObj={userDataObj}
        globalState={globalState}
        setGlobalState={setGlobalState}
        tab1={true}
      />
      <div
        className={
          globalState?.sidebarState
            ? "body-start-section sidebar-active-section"
            : "body-start-section"
        }
      >
        {/* Sidebar */}
        <SupervisorSidebar
          userDataObj={userDataObj}
          globalState={globalState}
          setGlobalState={setGlobalState}
        />
        <div className="body-full-content-section">
          <ViewWorkorderReportBodyHearder
            globalState={globalState}
            pageState={pageState}
            setPageState={setPageState}
            userDataObj={userDataObj}
            reportExtractionAPICall={reportExtractionAPICall}
            requestValidationCheck={requestValidationCheck}
          />
          <ViewWorkorderReportBodyContent
            pageState={pageState}
            setPageState={setPageState}
          />
        </div>
        <Footer />
      </div>
      <ViewWorkOrderReportAdvSearchModal
        pageState={pageState}
        setPageState={setPageState}
        reportExtractionAPICall={reportExtractionAPICall}
        workOrderDataObj={workOrderDataObj}
        lovDataObj={lovDataObj}
        userDataObj={userDataObj}
        supervisorListWithUserGroup={supervisorListWithUserGroup}
      />
      <ToastContainer />
    </div>
  );
};

export default ViewWorkOrderReportPage;
