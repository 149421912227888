import moment from 'moment';
import React, { useRef } from 'react'
import WorkOrderCard from '../../../Cards/Supervisor/WorkOrderCard';

const BigCalendarListView = ({ calendar, globalState, pageState, setPageState }) => {
    const today = moment();
    const dataObjList = pageState?.calendarViewData;


    // const activeRef = useRef(null);

    // const ScrollToTop = element =>{
    //     window.scrollTo({
    //         top: element.current.offsetBottom + 500,
    //         behavior: "smooth",
    //     });

    // }

    return (
        <div className='custom-big-calendar-list-view-section'>
            {
                calendar?.map(week => {
                    return (
                        week?.map((day, dayIndex) => {
                            const index = dataObjList?.findIndex(obj => obj?.date?.split(" ")[0] === day?.format("DD-MM-YYYY"));

                            return (
                                <div className="custom-big-calendar-day-section" key={`${day?.format("YYYY-MM-DD") + dayIndex}`}>
                                    <div className="custom-big-calendar-day-item-group">
                                        {
                                            day?.format("YYYY-MM-DD ddd") === today?.format("YYYY-MM-DD ddd") ?
                                                <span id="page-1">{day?.format("DD-MM-YYYY ")}<span>{`(Today)`}</span></span>
                                                :
                                                <span>{day?.format("DD-MM-YYYY ")}<span>{day?.format("(dddd)")}</span></span>
                                        }
                                    </div>
                                    <div className="custom-big-calendar-day-item-group">
                                        {
                                            index !== -1 ?
                                                pageState?.calendarViewData[index]?.workOrderList?.map(workOrderData => {
                                                    return (
                                                        <WorkOrderCard key={`workOrder${workOrderData?.itemId}`}
                                                            workOrderData={workOrderData}
                                                            globalState={globalState} pageState={pageState}
                                                            setPageState={setPageState} />
                                                    )
                                                })
                                                :
                                                <div className="custom-big-calendar-day-empty-item">
                                                    There is no activity available in this date.
                                                </div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    )
                })
            }
        </div>
    )
}

export default BigCalendarListView;