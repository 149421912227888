import React from 'react';
import { NoDataFoundItemGroup } from '../../../Common/CardContent';
import { CardLoader } from '../../../Loaders/Loaders';
import InstallerWorkOrderCard from '../../Cards/Supervisor/InstallerWorkOrderCard';

const InstallerSearchActivityBodyContent = ({ filterList, globalState, pageState, setPageState, setGlobalState }) => {

    return (
        <div className='body-content-section custom-scroll-bar-section'>
            <div className="wrapper-workorder-body-content-section installer-wrapper-workorder-body-content-section">
                {
                    pageState?.dataLoader ?
                        [0, 1].map(val => {
                            return (
                                <CardLoader key={`getInstallerCard${val}`} />
                            )
                        })
                        :
                        pageState?.getDataError ?
                            <NoDataFoundItemGroup message={pageState?.getDataErrorMsg} />
                            :
                            filterList?.map((workOrderData, index) => {
                                return <InstallerWorkOrderCard globalState={globalState} setGlobalState={setGlobalState}
                                    key={`workOrderList${workOrderData?.itemId + workOrderData?.resolutionDate + index}`}
                                    pageState={pageState} setPageState={setPageState}
                                    workOrderData={workOrderData} />
                            })
                }
            </div>
        </div>
    )
}

export default InstallerSearchActivityBodyContent;