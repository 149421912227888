import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { headers, SignatureDocument, UpdateWorkOrderDetails } from '../../../../Api/ApiCalls';
import { ApiConstant } from '../../../../Assets/Constants/ApiConstants';
import { updateWorkOrderDetailsData } from '../../../../ReduxFeature/StateFeatures/WorkOrderDataState';
import InstallerUpdateWorkOrderBodyContentForm from './UpdateWorkOrderHelper/InstallerUpdateWorkOrderBodyContentForm';
import InstallerUpdateWorkOrderBodyContentHeader from './UpdateWorkOrderHelper/InstallerUpdateWorkOrderBodyContentHeader';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CardDetailsLoader } from '../../../Loaders/Loaders';

const InstallerUpdateWorkOrderBodyContent = ({ userDataObj, globalState, pageState, setPageState, workOrderDataObj }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { workOrderDetails, workOrderStatus, returnReasonValue, returnRemarksFinalValue, saveAndContinueBtnEnabled,workOrderInfo } = workOrderDataObj;

    const saveAndContinueBtnEnabledApiCall = ( installerUrl,customerUrl,speedState,wifiState,remark,signData) => {

        if (saveAndContinueBtnEnabled) {
            setPageState({ ...pageState, apiCallAction: true });
            //  Header
            const header = headers(
                userDataObj?.userData?.activeUserData?.authToken
            );
            const activityType = workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OSI_TYPE ? ApiConstant.WOKR_ORDER_OSI_SORT_TYPE :
                workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OST_TYPE ? ApiConstant.WOKR_ORDER_OST_SORT_TYPE :
                    workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_BTU_TYPE ? ApiConstant.WOKR_ORDER_BTU_SORT_TYPE : null

            const installationCompleteRequest = {
                activityId: workOrderDetails?.itemID,
                activitySubType: workOrderDetails?.itemSubTypeId,
                activityType: activityType,
                meshDevice: workOrderDetails?.ownOrderDetails?.deviceDetails?.meshDeviceList?.length > 0,
                installerContact: userDataObj?.userData?.activeUserData?.userData?.contactNumber,
                installerId: userDataObj?.userData?.activeUserData?.userData?.userId,
                installerName: userDataObj?.userData?.activeUserData?.userData?.fullName,
                status: workOrderStatus,
                storeId: userDataObj?.userData?.activeUserData?.userData?.outletId,
                userId: userDataObj?.userData?.activeUserData?.userData?.userId,
                statusUpdateDateTime: moment().format("YYYY-MM-DD HH:mm"),
                reservationId: workOrderDetails?.installationDetail?.reservationId,
                remarks: returnRemarksFinalValue?.length > 0 ? returnRemarksFinalValue : null,
                returnedReason: returnReasonValue?.length > 0 ? returnReasonValue : null,
                customerId: workOrderDetails?.customerDetails?.customerId,
                caseNumber: workOrderDetails?.caseDetail?.caseNO,
                customerAccountId: workOrderDetails?.customerDetails?.customerAccountId,
                subscriberId: workOrderDetails?.subscriberId
            }
            UpdateWorkOrderDetails("/fibre/update/updateactivitydetails", installationCompleteRequest, header).then(response => {
                setPageState({ ...pageState, apiCallAction: false });
                /*-- Update all Location Items --*/
                if (response?.overallUpdateStatus === "SUCCESS") {
                    if (workOrderStatus === ApiConstant.WOKR_ORDER_RETURNED) {
                        navigate("/workorder/statusupdate/confirm");
                    }
                    else {
                        dispatch(updateWorkOrderDetailsData({
                            type: ApiConstant.INSTALLER_WORK_ORDER_ACTIVE_TAB,
                            value: 2
                        }));

                        dispatch(updateWorkOrderDetailsData({
                            type: ApiConstant.INSTALLER_COMPLETE_TIME,
                            value: installationCompleteRequest?.statusUpdateDateTime
                        }));
                    }
                } else {
                    toast.error(ApiConstant.INSTALLER_INST_COMPLETE_ERROR_MESSAGE,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 10000,
                            theme: 'colored'
                        }
                    );
                }
            }).catch(error => {
                setPageState({ ...pageState, apiCallAction: false });
                if (error.response.status === 500 || error.response.status === 503) {
                    toast.error(ApiConstant.INSTALLER_INST_COMPLETE_ERROR_MESSAGE,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 10000,
                            theme: 'colored'
                        }
                    );
                }
                else if (error.response.status === 404) {
                    toast.warning(ApiConstant.INSTALLER_INST_COMPLETE_ERROR_MESSAGE,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 10000,
                            theme: 'colored'
                        }
                    );
                }
            });
            
            if (workOrderDataObj?.workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OSI_TYPE ||
                workOrderDataObj?.workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OST_TYPE) {
                let signaturePayload = {
                    activityId: workOrderDetails?.itemID,
                    userId: userDataObj?.userData?.activeUserData?.userData?.userId,
                    outletId: userDataObj?.userData?.activeUserData?.userData?.outletId,
                    remarks: remark ? remark : null,
                    cableLength: workOrderInfo?.additionalCableLength,
                    LANspeed: speedState ? speedState : null,
                    WIFIspeed: wifiState ? wifiState : null,
                    customerName: signData?.customerName ? signData?.customerName : null,
                    installerName: signData?.installerName ? signData?.installerName : null,
                    customerSignature: customerUrl ? customerUrl : null,
                    InstallerSignature: installerUrl ? installerUrl : null,
                    returned: workOrderDataObj?.workOrderStatus == "RETURNED" ? true : false
                }
                let url = "/fibre/eSAF/Upload";
                SignatureDocument(url, signaturePayload, header)
                .then((res) => {
                    // setSignDocument(res);
                    if (res?.statusCode === "1111") {
                        toast.success(res?.description, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 5000,
                            theme: "colored",
                        })
                    } else {
                        toast.error(res?.description, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 5000,
                            theme: "colored",
                        })
                    }
          
                })
                .catch((err) => {
                //   setSignDocument(err);
                    let errormsg = err.response?.data?.description
                    if (errormsg) {
                        toast.error(errormsg, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 5000,
                            theme: "colored",
                        });
                    } else {
                        toast.error("request failed", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 5000,
                            theme: "colored",
                        });
                    }
          
                })
            }
        }
    }

    return (
        <div className='body-content-section pt-0'>
            <div className="update-work-order-details-content-section">
                <div className="update-work-order-details-content-item-section"></div>
                {
                    pageState?.dataLoader ?
                        <CardDetailsLoader />
                        :
                        <div className="update-work-order-details-content-item-section">
                            <InstallerUpdateWorkOrderBodyContentHeader pageState={pageState}
                                setPageState={setPageState} />
                            <InstallerUpdateWorkOrderBodyContentForm pageState={pageState}
                                setPageState={setPageState}
                                saveAndContinueBtnEnabledApiCall={saveAndContinueBtnEnabledApiCall} />
                        </div>
                }
                <div className="update-work-order-details-content-item-section"></div>
            </div>
        </div>
    )
}

export default InstallerUpdateWorkOrderBodyContent;
