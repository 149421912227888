import React from 'react'
import { Constent } from '../../../../Assets/Constants/Constent';
import { EmptyValueHandler } from '../../../Common/GenericFunctions';

const SwitchUserCard = ({ data, switchUserApiCall }) => {


    return (
        <div className='switch-user-card-section'>
            <div className="wrapper-switch-user-card-section">
                <div className="switch-user-card-item-section">
                    <div className="switch-user-card-item-group">
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.SWITCH_USER_CARD_ITEM_TITLE1}
                            </div>
                            <div className="card-item-value">
                                {EmptyValueHandler({ item: data?.userId })}
                            </div>
                        </div>
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.SWITCH_USER_CARD_ITEM_TITLE2}
                            </div>
                            <div className="card-item-value">
                                {EmptyValueHandler({ item: data?.userName })}                                
                            </div>
                        </div>
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.SWITCH_USER_CARD_ITEM_TITLE3}
                            </div>
                            <div className="card-item-value">
                                {EmptyValueHandler({ item: data?.userRole })}                        
                            </div>
                        </div>                        
                    </div>
                </div>
                <div className="switch-user-card-item-section">
                    <div className="switch-user-btn-section"
                        data-toggle="modal" data-target="#switchUserModal" onClick={() => {
                            switchUserApiCall(data);
                        }}>
                        {Constent.SWITCH_BTN}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SwitchUserCard;