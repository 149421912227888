import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../../Components/Footer/Footer";
import { Header } from "../../Components/Header/Header";
import SupervisorSidebar from "../../Components/MainBody/Sidebar/SupervisorSidebar";

import { ToastContainer, toast } from "react-toastify";
import "../../Assets/CSS/WorkOrderHeader.css";
import "../../Assets/CSS/WorkOrderAdvanceSearchModal.css";

import SearchActivityBodyHeader from "../../Components/MainBody/BodyHeader/Supervisor/SearchActivityBodyHeader";
import WorkOrderAdvanceSearchModal from "../../Components/Modals/WorkOrderAdvanceSearchModal";
import SearchActivityBodyContent from "../../Components/MainBody/BodyContent/Supervisor/SearchActivityBodyContent";
import { headers, WorkOrderPage } from "../../Api/ApiCalls";
import {
  resetWorkOrderListData,
  setSearchWorkOrderListData,
  workOrderSearchPageState,
} from "../../ReduxFeature/StateFeatures/WorkOrderDataState";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import moment from "moment";
import { Constent } from "../../Assets/Constants/Constent";
import { WorkOrderAdvanceSearchRequestType } from "../../Components/Common/GenericFunctions";

const SearchAcivityPage = ({ globalState, setGlobalState }) => {
  const userDataObj = useSelector((state) => state.userDataInfo);
  const workOrderDataObj = useSelector((state) => state.workOrderInfo);
  const {
    workOrderId,
    workOrderFromDate,
    workOrderToDate,
    formCalendarNav,
    searchType,
  } = workOrderDataObj;
  const dispatch = useDispatch();

  const [pageState, setPageState] = useState({
    totalItems: workOrderDataObj?.workOrderList?.length,
    workOrderList: workOrderDataObj?.workOrderList,
    bodyview: "Day",
    toggleIcon: "List",

    workOrderId: "",
    workOrderFromDate: "",
    workOrderToDate: "",
    // Advance Search Modal
    advanceSearchLovs: ApiConstant.WORK_ORDER_ADVANCE_SEARCH_LOV1,
    advanceSearchSubLovs: [],

    selectedAdvanceSearchType: "",
    selectedAdvanceSearchSubTypeList: [],

    dataLoader: false,
    // Error Part
    getDataError: false,
    getDataErrorMsg: "",
  });

  function searchWorkOrderListApiCall() {
    dispatch(resetWorkOrderListData());

    setPageState({
      ...pageState,
      dataLoader: true,
      totalItems: 0,
      workOrderList: [],
    });
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);

    const workOrderListRequest = {
      fromDate:
        (pageState?.workOrderFromDate || moment().format("YYYY-MM-DD")) +
        " 00:00",
      toDate:
        (pageState?.workOrderToDate || moment().format("YYYY-MM-DD")) +
        " 23:59",
      requestType: WorkOrderAdvanceSearchRequestType({ value: searchType }),
      activityType:
        pageState?.selectedAdvanceSearchType?.length > 0
          ? pageState?.selectedAdvanceSearchType
          : null,
      activitySubType:
        pageState?.selectedAdvanceSearchSubTypeList?.length > 0
          ? pageState?.selectedAdvanceSearchSubTypeList
          : null,
      itemSubType: null,
      stsType: null,
      activityId:
        searchType === Constent.WORK_ORDER_INPUT_ITEM_LABEL1 &&
        pageState?.workOrderId?.length > 0
          ? "FieldActivity-" + pageState?.workOrderId
          : null,
      orderNumber:
        searchType === Constent.TRACK_ORDER_INPUT_ITEM_LABEL1 &&
        pageState?.workOrderId?.length > 0
          ? pageState?.workOrderId
          : null,
      customerId:
        searchType === Constent.WORK_ORDER_INPUT_ITEM_LABEL2 &&
        pageState?.workOrderId?.length > 0
          ? pageState?.workOrderId
          : null,
      userId: userDataObj?.userData?.activeUserData?.userData?.userId,
      // Infinite scroll is pending
      limit: 500,
      offSet: 0,
    };

    WorkOrderPage(
      "/fibre/activity/supervisorlist",
      workOrderListRequest,
      header
    )
      .then((response) => {
        dispatch(setSearchWorkOrderListData(response?.workQueueVo));
        setPageState({
          ...pageState,
          dataLoader: false,
          getDataError: false,
          workOrderList: response?.workQueueVo,
          totalItems: response?.workQueueVo?.length,
          getDataErrorMsg: "",
        });
      })
      .catch((error) => {
        dispatch(resetWorkOrderListData());
        let errorMessage;
        if (error.response.status == 500 || error.response.status == 503) {
          errorMessage = ApiConstant.WORK_ORDER_LIST_ERROR_MESSAGE;
          toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        } else if (error.response.status == 404) {
          errorMessage = ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE;
          toast.warning(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        } else {
          errorMessage = error.response.statusText;
        }

        setPageState({
          ...pageState,
          getDataError: true,
          dataLoader: false,
          totalItems: 0,
          workOrderList: [],
          getDataErrorMsg: errorMessage,
        });
      });
  }

  useEffect(() => {
    if (!formCalendarNav) {
      searchWorkOrderListApiCall();
    } else {
      dispatch(
        workOrderSearchPageState({
          type: ApiConstant.CALENDAR_TO_WORK_ORDER_NAV,
          value: false,
        })
      );
    }
  }, []);

  // Filter List Section
  const filterList = pageState?.workOrderList?.filter((item) => {
    if (
      pageState?.workOrderId === "" ||
      item?.itemId
        ?.toLowerCase()
        ?.includes(pageState?.workOrderId?.toLowerCase()) ||
      item?.orderId
        ?.toLowerCase()
        ?.includes(pageState?.workOrderId?.toLowerCase()) ||
      item?.customerId
        ?.toLowerCase()
        ?.includes(pageState?.workOrderId?.toLowerCase())
    ) {
      return item;
    }
  });

  return (
    <div className="page-start-section">
      <Header
        userDataObj={userDataObj}
        globalState={globalState}
        setGlobalState={setGlobalState}
        tab1={true}
      />
      <div
        className={
          globalState?.sidebarState
            ? "body-start-section sidebar-active-section"
            : "body-start-section"
        }
      >
        {/* Sidebar */}
        <SupervisorSidebar
          userDataObj={userDataObj}
          globalState={globalState}
          setGlobalState={setGlobalState}
        />
        <div className="body-full-content-section">
          <SearchActivityBodyHeader
            userDataObj={userDataObj}
            globalState={globalState}
            setGlobalState={setGlobalState}
            searchWorkOrderListApiCall={searchWorkOrderListApiCall}
            pageState={pageState}
            setPageState={setPageState}
            workOrderDataObj={workOrderDataObj}
            filterList={filterList}
          />
          <SearchActivityBodyContent
            userDataObj={userDataObj}
            globalState={globalState}
            setGlobalState={setGlobalState}
            pageState={pageState}
            setPageState={setPageState}
            workOrderDataObj={workOrderDataObj}
            filterList={filterList}
          />
        </div>
        <Footer />
      </div>
      <WorkOrderAdvanceSearchModal
        pageState={pageState}
        setPageState={setPageState}
        searchWorkOrderListApiCall={searchWorkOrderListApiCall}
        workOrderDataObj={workOrderDataObj}
      />
      <ToastContainer />
    </div>
  );
};

export default SearchAcivityPage;
