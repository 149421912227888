import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../../Components/Footer/Footer';
import { Header } from '../../Components/Header/Header'
import DashbordBodyHeader from '../../Components/MainBody/BodyHeader/Supervisor/DashbordBodyHeader';
import SupervisorSidebar from '../../Components/MainBody/Sidebar/SupervisorSidebar';
import '../../Assets/CSS/SupervisorDashbord.css';
import '../../Assets/CSS/WorkOrderBody.css';
import '../../Assets/CSS/CustomBigCalendar.css';

import DashbordBodyContent from '../../Components/MainBody/BodyContent/Supervisor/DashbordBodyContent';
import moment from 'moment';
import { headers, SupervisorCalendar } from '../../Api/ApiCalls';
import { useNavigate } from 'react-router-dom';
import { resetWorkOrderListData, setSearchWorkOrderListData, workOrderSearchPageState } from '../../ReduxFeature/StateFeatures/WorkOrderDataState';
import { toast, ToastContainer } from 'react-toastify';
import { ApiConstant } from '../../Assets/Constants/ApiConstants';
import { setLoginUserName, setLoginUserToken } from '../../ReduxFeature/StateFeatures/B2CDataState';

export const DashbordPage = ({ globalState, setGlobalState }) => {
    const userDataObj = useSelector((state) => state.userDataInfo);
    const workOrderObj = useSelector((state) => state.workOrderInfo);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [pageState, setPageState] = useState({
        totalItems: 0,
        bodyview: "Month",
        toggleIcon: "Calendar",
        calendarDate: moment(),
        calendarView: true,
        calendarViewData: []
    });
    const dayViewItemIndex = pageState?.calendarViewData?.findIndex(obj => obj.date?.split(" ")[0] === pageState?.calendarDate?.format("DD-MM-YYYY"));

    useEffect(() => {
        dispatch(resetWorkOrderListData());
        dispatch(setLoginUserName(""));
        dispatch(setLoginUserToken(""));
        //  Header
        const header = headers(
            userDataObj?.userData?.activeUserData?.authToken
        );

        const calendarRequest = {
            monthYear: (pageState?.calendarDate?.format("YYYY-MM-DD") || null),
            userId: (userDataObj?.userData?.activeUserData?.userData?.userId || null)
        };

        SupervisorCalendar("/fibre/activity/calendar/supervisor", calendarRequest, header)
            .then(response => {
                setPageState({
                    ...pageState, totalItems: (response?.totalWorkOrder || 0),
                    calendarViewData: (response?.calendarResponse || [])
                });
            }).catch(error => {
                let errorMessage;
                if (error.response.status === 500 || error.response.status === 503) {
                    errorMessage = ApiConstant.WORK_ORDER_LIST_ERROR_MESSAGE;
                    toast.error(errorMessage,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 10000,
                            theme: 'colored'
                        }
                    );
                }
                else if (error.response.status === 404) {
                    errorMessage = ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE;
                    toast.warning(errorMessage,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 10000,
                            theme: 'colored'
                        }
                    );
                }
                else {
                    errorMessage = error.response.statusText;
                    toast.error(errorMessage,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 10000,
                            theme: 'colored'
                        }
                    );
                }
            });

    }, [pageState?.calendarDate?.format("MM")]);



    function MoveSearchWorkOrderNav(workOrderList, day) {
        dispatch(workOrderSearchPageState({
            type: ApiConstant.WORK_ORDER_FROM_DATE,
            value: day.format("YYYY-MM-DD")
        }));
        dispatch(workOrderSearchPageState({
            type: ApiConstant.WORK_ORDER_TO_DATE,
            value: day.format("YYYY-MM-DD")
        }));
        dispatch(workOrderSearchPageState({
            type: ApiConstant.CALENDAR_TO_WORK_ORDER_NAV,
            value: true
        }));
        
        dispatch(setSearchWorkOrderListData(workOrderList)) && navigate("/workOrder");
    }


    return (
        <div className='page-start-section'>
            <Header userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} tab0={true} />

            <div className={globalState?.sidebarState ? "body-start-section sidebar-active-section" : "body-start-section"}>
                {/* Sidebar */}
                <SupervisorSidebar userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} />
                {/* Body full content section */}
                <div className="body-full-content-section">
                    <DashbordBodyHeader userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState}
                        pageState={pageState} setPageState={setPageState} index={dayViewItemIndex} />

                    <DashbordBodyContent userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState}
                        pageState={pageState} setPageState={setPageState}
                        index={dayViewItemIndex} MoveSearchWorkOrderNav={MoveSearchWorkOrderNav} />
                </div>
                <Footer />
            </div>
            <ToastContainer />
        </div>
    )
}
