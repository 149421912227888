import React from 'react';
import { DownloadDocument, headers } from '../../Api/ApiCalls';
import { Constent } from '../../Assets/Constants/Constent';
import { CloseModalIcon, DocumentIcon, DownloadIcon, EyeIcon } from '../../Assets/Images/CelcomSvgImages';
import { toast } from 'react-toastify';
import { ApiConstant } from '../../Assets/Constants/ApiConstants';

const WorkOrderDocumentsListModal = ({ documentList, userDataObj }) => {

    function downloadDocumentFile({ data, filename, type }) {
        
        const linkSource = `data:application/${type};base64,${data}`;

        const downloadLink = document.createElement('a');
        const fileName = filename;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    return (
        <div className="modal fade returnd-work-order-modal view-document-list-modal" id="workOrderDocumentListModal" data-backdrop="static"
            data-keyboard="false" tabIndex="-1" aria-labelledby="workOrderDocumentListModalLabel"
            aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header pb-0">
                        <div className="wrapper-modal-header pt-5">
                            <div className="modal-header-title-section">
                                <h5 className="modal-title" id="workOrderDocumentListModalLabel">
                                    {Constent.VIEW_DOCUMENTS_MODAL_TITLE}
                                </h5>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <CloseModalIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="wrapper-modal-body">
                            {
                                documentList?.map((documentDetails, index) => {
                                    return (
                                        <div className="document-modal-card-item-group" key={`documentdetails${documentDetails?.docReferenceId + index}`}>
                                            <div className="document-modal-card-item-section" onClick={() => {
                                                //  Header
                                                const header = headers(
                                                    userDataObj?.userData?.activeUserData?.authToken
                                                );
                                                const request = {
                                                    documentId: documentDetails?.docReferenceId
                                                }

                                                DownloadDocument("/fibre/file/download", request, header).then(response => {
                                                    const fileType = documentDetails?.attachmentName?.split(".")[1];

                                                    downloadDocumentFile({
                                                        data: response.data,
                                                        filename: documentDetails?.attachmentName,
                                                        type: fileType
                                                    })
                                                }).catch(() => {
                                                    toast.warning(ApiConstant.DOWNLOAD_DOCUMENT_WARNING_MESSAGE + " " + documentDetails?.attachmentName,
                                                        {
                                                            position: toast.POSITION.BOTTOM_RIGHT,
                                                            autoClose: 10000,
                                                            theme: 'colored'
                                                        }
                                                    );
                                                })
                                            }}>
                                                <div className="document-modal-card-item">
                                                    <DocumentIcon fill={"#009BDF"} />
                                                </div>
                                                <div className="document-modal-card-item">{documentDetails?.attachmentName || "-"}</div>
                                                <div className="document-modal-card-item">
                                                    <DownloadIcon />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="okay-btn-section" data-dismiss="modal">
                            <span>{Constent.OKAY_BTN}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkOrderDocumentsListModal;