import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Constent } from '../../../../Assets/Constants/Constent';
import { AppoinmentSubTypeConverter, AppoinmentTypeConverter, AssignedToSameAsUserId } from '../../../Common/GenericFunctions';
import { RightArrowIcon } from '../../../../Assets/Images/CelcomSvgImages';
import { selectedWorkOrderItem } from '../../../../ReduxFeature/StateFeatures/WorkOrderDataState';
import { SupervisorWorkOrderStatusCal } from '../../../Common/GenericFunctions';


const WorkOrderCard = ({ globalState, pageState, setGlobalState, workOrderData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userDataObj = useSelector((state) => state.userDataInfo?.userData);

    const { status, addClass } = (SupervisorWorkOrderStatusCal(workOrderData) || {
        status: "",
        addClass: ""
    });
    
    return (
        <div className={pageState?.toggleIcon === "List" ?
            'workorder-card-section'
            :
            "workorder-card-section wrapper-workorder-card-view"} >
            <div className={`wrapper-workorder-card-section ${pageState?.toggleIcon === "Card" ? "card-view" : ""} ${addClass}`}>
                <div className="workorder-card-item-section" onClick={() => {
                    dispatch(selectedWorkOrderItem(workOrderData));
                    navigate("/workorderdetails");
                }}>
                    <div className="wrapper-workorder-card-item-group">
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.WORK_ORDER_CARD_ITEM_TITLE1}
                            </div>
                            <div className="card-item-value">
                                {workOrderData?.itemId?.replace("FieldActivity-", "") || "-"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="workorder-card-item-section" role="button" onClick={() => {
                    // setGlobalState({ ...pageState, selectedWorkOrderId: workOrderData?.itemId });
                    dispatch(selectedWorkOrderItem(workOrderData));
                    navigate("/workorderdetails");
                }}>
                    <div className={globalState?.sidebarState ?
                        "workorder-card-item-group custom-grid-1"
                        :
                        "workorder-card-item-group"}
                    >
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.WORK_ORDER_CARD_ITEM_TITLE2}
                            </div>
                            <div className="card-item-value">
                                {AppoinmentTypeConverter({ type: workOrderData?.workOrderType || "-" })}
                            </div>
                        </div>
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.WORK_ORDER_CARD_ITEM_TITLE3}
                            </div>
                            <div className="card-item-value">
                                {
                                    AppoinmentSubTypeConverter({ subType: workOrderData?.workOrderSubType || "-" , type: workOrderData?.workOrderType || "-"})
                                }
                            </div>
                        </div>
                    </div>

                    <div className={globalState?.sidebarState ?
                        "workorder-card-item-group custom-grid-1"
                        :
                        "workorder-card-item-group"}
                    >
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.WORK_ORDER_CARD_ITEM_TITLE4}
                            </div>
                            <div className="card-item-value">
                                {workOrderData?.resolutionDate || "-"}
                            </div>
                        </div>
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.WORK_ORDER_CARD_ITEM_TITLE5}
                            </div>
                            <div className="card-item-value">
                                {workOrderData?.district || "-"}
                            </div>
                        </div>
                    </div>

                    <div className={globalState?.sidebarState ?
                        "workorder-card-item-group custom-grid-1"
                        :
                        "workorder-card-item-group"}
                    >
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.WORK_ORDER_CARD_ITEM_TITLE6}
                            </div>
                            <div className="card-item-value">
                                {AssignedToSameAsUserId(workOrderData, userDataObj) || workOrderData?.assignedTo === null ||
                                    workOrderData?.assignedTo === undefined || workOrderData?.assignedTo === "" ?
                                    "-" : workOrderData?.assignedTo}
                            </div>
                        </div>
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.WORK_ORDER_CARD_ITEM_TITLE7}
                            </div>
                            <div className="card-item-value">
                                {status || "-"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="workorder-card-item-section" role="button" onClick={() => {
                    setGlobalState({ ...pageState, selectedWorkOrderId: workOrderData?.itemId });
                    dispatch(selectedWorkOrderItem(workOrderData));
                    navigate("/workorderdetails");
                }}>
                    <RightArrowIcon stroke={"#009BDF"} />
                </div>
            </div>
        </div>
    )
}

export default WorkOrderCard;