import React, { useState } from "react";
import { NoDataFoundItemGroup } from "../../../../Common/CardContent";
import { ApiConstant } from "../../../../../Assets/Constants/ApiConstants";
import { Constent } from "../../../../../Assets/Constants/Constent";
import { PagenationIndexPositionCal } from "../../../../Common/GenericFunctions";
import Pagination from "../../../../Common/Pagination";
import { CardLoader } from "../../../../Loaders/Loaders";
import ManageStockCard from "../../../Cards/Supervisor/ManageStockCard";

const InventoryRequestStockContent = ({
  globalState,
  pageState,
  setPageState,
  requestStockList,
  invDataObj,
}) => {
  const { viewStockInputValue } = invDataObj;
  const [currentPage, setCurrentPage] = useState(1);
  const postPerPage = Math.min(requestStockList?.length, 10);
  const filterList = requestStockList?.filter((item) => {
    if (
      viewStockInputValue === "" ||
      item?.sAPMaterialCode
        ?.toLowerCase()
        ?.includes(viewStockInputValue?.toLowerCase()) ||
      item?.itemName
        ?.toLowerCase()
        ?.includes(viewStockInputValue?.toLowerCase())
    ) {
      return item;
    }
  });
  const { indexOfLastPost, indexOfFirstPost, displayPosts } =
    PagenationIndexPositionCal(currentPage, postPerPage, filterList);

  return (
    <>
      {pageState?.dataLoader ? (
        <>
          {[0, 1].map((val) => {
            return <CardLoader key={`requestStcokList${val}`} />;
          })}
        </>
      ) : pageState?.getRequestStockDataError ? (
        <NoDataFoundItemGroup
          message={pageState?.getRequestStockDataErrorMsg}
        />
      ) : (
        <>
          {filterList?.length > 0 ? (
            <div className="inventory-filter-section">
              <div className="filter-item-info-section">
                <strong>{`${indexOfFirstPost + 1} - ${
                  indexOfLastPost > filterList?.length
                    ? filterList?.length
                    : indexOfLastPost
                } of ${filterList?.length}`}</strong>{" "}
                Items
              </div>
              <div
                className="filter-item-info-section"
                hidden={filterList?.length < 11}
              >
                <Pagination
                  lastPageNumber={Math.ceil(filterList?.length / postPerPage)}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
              <div className="filter-item-info-section">
                {Constent.INVENTORY_TRANSFER_STORE_ID_LABLE}{" "}
                <strong>{ApiConstant.INVENTORY_REQUESTING_STORE_ID}</strong>
              </div>
            </div>
          ) : (
            <></>
          )}
          {displayPosts?.map((data, index) => {
            return (
              <ManageStockCard
                key={`requestmanageInv${index}`}
                globalState={globalState}
                pageState={pageState}
                setPageState={setPageState}
                data={data}
              />
            );
          })}
        </>
      )}
    </>
  );
};

export default InventoryRequestStockContent;
