import React, { useState } from 'react'
import { NoDataFoundItemGroup } from '../../../Common/CardContent';
import { PagenationIndexPositionCal } from '../../../Common/GenericFunctions';
import Pagination from '../../../Common/Pagination';
import { CardLoader } from '../../../Loaders/Loaders';
import TrackOrderCard from '../../Cards/Supervisor/TrackOrderCard';

const TrackOrderBodyContent = ({ userDataObj, globalState, pageState, setPageState, trackOrderListData, trackOrderDataObj }) => {
    const { orderStatusValue, orderNumberValue, customerIdValue } = trackOrderDataObj;

    const [currentPage, setCurrentPage] = useState(1);
    const postPerPage = Math.min(trackOrderListData?.length, 10);

    const { indexOfLastPost, indexOfFirstPost, displayPosts } = PagenationIndexPositionCal(currentPage, postPerPage, trackOrderListData);

    const filterList = trackOrderListData?.filter(item => {
        if ((orderNumberValue === "")
            || (item?.orderId?.includes(orderNumberValue))) {
            return item;
        }
    });

    const displayFilterList = displayPosts?.filter(filterItem => {
        if ((orderNumberValue === "")
            || (filterItem?.orderId?.includes(orderNumberValue))) {
            return filterItem;
        }
    });
    
    return (
        <div className='body-content-section'>
            {/* Paggination */}
            {/* Body Content - Card */}
            <div className="wrapper-body-content-section">
                {
                    pageState?.dataLoader ?
                        [0, 1].map(val => {
                            return (
                                <CardLoader key={`trackOrderList${val}`} />
                            )
                        })
                        :
                        pageState?.getTrackOrderDataError ?
                            <NoDataFoundItemGroup message={pageState?.getDataErrorMsg} />
                            :
                            <>
                                {
                                    filterList?.length > 0 ?
                                        <div className="trackorder-filter-section mb-3">
                                            <div className="filter-item-info-section">
                                                <strong>{`${indexOfFirstPost + 1} - ${indexOfLastPost > filterList?.length ? filterList?.length : indexOfLastPost} of ${filterList?.length}`}</strong> Items
                                            </div>
                                            <div className="filter-item-info-section" hidden={filterList?.length < 11}>
                                                <Pagination lastPageNumber={Math.ceil(filterList?.length / postPerPage)}
                                                    currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                            </div>
                                            <div className="filter-item-info-section">

                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    displayFilterList?.map((data, index) => {
                                        return (
                                            <TrackOrderCard key={`trackOrder${index}`} globalState={globalState}
                                                pageState={pageState} setPageState={setPageState} data={data} />
                                        );
                                    })
                                }
                            </>
                }
            </div>
        </div>
    )
}

export default TrackOrderBodyContent;