import React, { useState } from "react";
import { Constent } from "../../../Assets/Constants/Constent";
import { TableRowUpDownArrow } from "../../Common/Table";
import {
  EmptyValueHandler,
  PagenationIndexPositionCal,
  ReportExtractionStatusCal,
} from "../../Common/GenericFunctions";
import { NoDataFoundItemGroup } from "../../Common/CardContent";
import { CardDetailsLoader } from "../../Loaders/Loaders";
import Pagination from "../../Common/Pagination";

const ViewWorkorderReportBodyContent = ({ pageState }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState(10);
  const postPerPage = Math.min(pageState?.workOrderListData?.length, pageRange);
  const { indexOfLastPost, indexOfFirstPost, displayPosts } =
    PagenationIndexPositionCal(
      currentPage,
      postPerPage,
      pageState?.workOrderListData
    );

  return (
    <div className="body-content-section">
      {pageState?.dataLoader ? (
        <CardDetailsLoader key={`getInstallerCard`} />
      ) : pageState?.workOrderListData?.length > 0 ? (
        <>
          <div className="wraper-body-report-table-section">
            <table className="">
              <thead>
                <tr>
                  {Constent.VIEW_WORKORDER_REPORTEXTRACTION_TABLE_COL_NAME_LIST?.map(
                    (colName, index) => {
                      return (
                        <th key={colName + index}>
                          <span>{colName}</span>
                        </th>
                      );
                    }
                  )}
                </tr>
              </thead>

              <tbody>
                {displayPosts?.map((item, index) => {
                  const { status } = ReportExtractionStatusCal(item);
                  const cal = index + (currentPage - 1) * pageRange;

                  return (
                    <tr className={"className"} key={item + " - " + index}>
                      <td data-title={Constent.REPORT_EXT_SN_LABLE}>
                        {cal + 1}
                      </td>
                      <td data-title={Constent.REPORT_EXT_ACCESS_LABLE}>
                        {EmptyValueHandler({ item: item?.accessProvider })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_COM_NAM_LABLE}>
                        {EmptyValueHandler({ item: item?.appointmentDate })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_APPDATE_LABLE}>
                        {EmptyValueHandler({ item: item?.orderNo })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_ORD_NO_LABLE}>
                        {EmptyValueHandler({
                          item: item?.workOrderId?.replaceAll(
                            "FieldActivity-",
                            ""
                          ),
                        })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_WO_ID_LABLE}>
                        {EmptyValueHandler({
                          item: item?.appointmentType?.replaceAll("_", " "),
                        })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_STATUS_LABLE}>
                        {EmptyValueHandler({ item: item?.appointmentSubType })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_APP_TYP_LABLE}>
                        {EmptyValueHandler({ item: status })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_APP_SUB_TYPE_LABLE}>
                        {EmptyValueHandler({ item: item?.customerName })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_CUS_NAM_LABLE}>
                        {EmptyValueHandler({ item: item?.customerId })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_CUS_ID_LABLE}>
                        {EmptyValueHandler({ item: item?.customerContactNo })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_CUS_CNO_LABLE}>
                        {EmptyValueHandler({ item: item?.customerAddress })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_ADDRESS_LABLE}>
                        {EmptyValueHandler({ item: item?.plan })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_PLAN_LABLE}>
                        {EmptyValueHandler({ item: item?.hsiUsername })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_HSI_USER_NAME_LABLE}>
                        {EmptyValueHandler({ item: item?.installerNotes })}
                      </td>
                      <td
                        data-title={Constent.REPORT_EXT_NOTeS_FOR_INSTL_LABLE}
                      >
                        {EmptyValueHandler({ item: item?.installerContactNo })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_INSTL_NAME_LABLE}>
                        {EmptyValueHandler({ item: item?.rgwSlNo })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_INSTL_CON_NO_LABLE}>
                        {EmptyValueHandler({ item: item?.butSlNo })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_INSTL_CLS_TIME_LABLE}>
                        {EmptyValueHandler({
                          item: item?.desiredInstallation,
                        })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_RGW_IMEI_LABLE}>
                        {EmptyValueHandler({ item: item?.additionalCabling })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_BTU_IMEI_LABLE}>
                        {EmptyValueHandler({ item: item?.returnRemarks })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_DES_INTL_LABLE}>
                        {EmptyValueHandler({
                          item: item?.installationCloseTimestamp,
                        })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_ADDRESS_TYPE_LABLE}>
                        {EmptyValueHandler({
                          item: item?.customerAddressType,
                        })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_ACT_CLOSE_LABLE}>
                        {EmptyValueHandler({
                          item: item?.installerClosingDateAndTime,
                        })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_MESH_IMEI_LABLE}>
                        {EmptyValueHandler({
                          item: item?.meshSerialNumber,
                        })}
                      </td>
                      <td
                        data-title={Constent.REPORT_EXT_RETUN_REMARK_ADD_LABLE}
                      >
                        {EmptyValueHandler({
                          item: item?.returnRemarksAdditionalInfo,
                        })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_OST_ELEM_LABLE}>
                        {EmptyValueHandler({
                          item: item?.ostElement,
                        })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_OST_RESOLUTION_LABLE}>
                        {EmptyValueHandler({
                          item: item?.ostResolution,
                        })}
                      </td>
                      <td data-title={Constent.REPORT_EXT_OST_PROB_LABLE}>
                        {EmptyValueHandler({
                          item: item?.ostProblemCause,
                        })}
                      </td>

                      {/* <td data-title={Constent.REPORT_EXT_WO_CLOSE_LABLE}>
                      {EmptyValueHandler({ item: item?.desiredInstallation })}
                    </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="w-100 mb-3 mt-3 d-flex justify-content-center wraper-pagination-item-section">
            <div className="w-100 d-flex justify-content-end align-items-center">
              <span>Items per page:</span>
              <div className="pagination-rang-item-group-section ml-2">
                <div className="form-group mb-0 ">
                  <select
                    className="custom-select"
                    value={pageRange}
                    onChange={(e) => {
                      const { value } = e.target;
                      setPageRange(value);
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                  </select>
                </div>
              </div>
            </div>

            <Pagination
              lastPageNumber={Math.ceil(
                pageState?.workOrderListData?.length / postPerPage
              )}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </>
      ) : (
        <NoDataFoundItemGroup message={"No data found"} />
      )}
    </div>
  );
};

export default ViewWorkorderReportBodyContent;
