import React from 'react';
import { ApiConstant } from '../../../../../Assets/Constants/ApiConstants';
import { Constent } from '../../../../../Assets/Constants/Constent';
import { DownloadIcon, FilterIcon } from '../../../../../Assets/Images/CelcomSvgImages';

const ViewStockDetailsCardContent = ({ activeTab, setActiveTab, selectedCardItem, selectedCardItemDetails, downloadExcelFun, pageState, selectedCardItemDetailsList }) => {
    
    return (
        <div className="wrapper-view-stock-row-section"
            data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-delay="100">
            <div className="view-stock-col-item-section"></div>
            {/* M-Code & Name */}
            <div className="view-stock-col-item-section">
                <div className="card-item-group">
                    <div className="card-item-title">
                        {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE1}
                    </div>
                    <div className="card-item-value">
                        {selectedCardItem?.sAPMaterialCode || "-"}
                    </div>
                </div>
                <div className="card-item-group">
                    <div className="card-item-title">
                        {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE2}
                    </div>
                    <div className="card-item-value">
                        {selectedCardItem?.itemName || "-"}
                    </div>
                </div>
            </div>
            {/* Other Details */}
            <div className="view-stock-col-item-section">
                <div className={activeTab === ApiConstant.INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE1 ?
                    "card-item-group card-item-group-active"
                    :
                    "card-item-group"} onClick={() => {
                        setActiveTab(ApiConstant.INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE1);
                    }}>
                    <div className="card-item-inner-group">
                        <div className="card-item-title">
                            {Constent.INVENTORY_CARD_VIEW_STOCK_ITEM_TITLE1}
                        </div>
                        <div className="card-item-value">
                            {selectedCardItemDetailsList?.filter(item => {
                                if (item!==undefined || item!==null || item!=="") {
                                    return item;
                                }
                            }).length || "-"}
                        </div>
                    </div>
                </div>
                <div className={activeTab === ApiConstant.INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE2 ?
                    "card-item-group card-item-group-active"
                    : "card-item-group"} onClick={() => {
                        setActiveTab(ApiConstant.INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE2);
                    }}>
                    <div className="card-item-inner-group">
                        <div className="card-item-title">
                            {Constent.INVENTORY_CARD_VIEW_STOCK_ITEM_TITLE2}
                        </div>
                        <div className="card-item-value">
                            {selectedCardItemDetails?.inStock?.count || "-"}
                        </div>
                    </div>
                </div>
                <div className={activeTab === ApiConstant.INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE3 ?
                    "card-item-group card-item-group-active"
                    : "card-item-group"} onClick={() => {
                        setActiveTab(ApiConstant.INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE3);
                    }}>
                    <div className="card-item-inner-group">
                        <div className="card-item-title">
                            {Constent.INVENTORY_CARD_VIEW_STOCK_ITEM_TITLE3}
                        </div>
                        <div className="card-item-value">
                            {selectedCardItemDetails?.unAvaliable?.count || "-"}
                        </div>
                    </div>
                </div>
                <div className={activeTab === ApiConstant.INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE4 ?
                    "card-item-group card-item-group-active"
                    : "card-item-group"} onClick={() => {
                        setActiveTab(ApiConstant.INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE4);
                    }}>
                    <div className="card-item-inner-group">
                        <div className="card-item-title">
                            {Constent.INVENTORY_CARD_VIEW_STOCK_ITEM_TITLE4}
                        </div>
                        <div className="card-item-value">
                            {selectedCardItemDetails?.sold?.count || "-"}
                        </div>
                    </div>
                </div>
                <div className={activeTab === ApiConstant.INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE5 ?
                    "card-item-group card-item-group-active"
                    : "card-item-group"} onClick={() => {
                        setActiveTab(ApiConstant.INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE5);
                    }}>
                    <div className="card-item-inner-group">
                        <div className="card-item-title">
                            {Constent.INVENTORY_CARD_VIEW_STOCK_ITEM_TITLE5}
                        </div>
                        <div className="card-item-value">
                            {selectedCardItemDetails?.others?.count || "-"}
                        </div>
                    </div>

                </div>
            </div>
            {/* Btn */}
            <div className="view-stock-col-item-section" >
                <div className="download-btn-section" onClick={!pageState?.dataLoader ? downloadExcelFun : null}>
                    <span>{Constent.DOWNLOAD_BTN}</span>
                    <DownloadIcon />
                </div>
                <div className="filter-btn-section" data-toggle="modal" data-target="#viewStockDetailsFilterModal">
                    <FilterIcon />
                    <span>{Constent.INVENTORY_FILTER_SUB_ITEM_TITLE1}</span>
                </div>
            </div>

            <div className="view-stock-col-item-section"></div>
        </div>
    )
}

export default ViewStockDetailsCardContent;