import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ApiConstant } from '../../../../Assets/Constants/ApiConstants';
import { Constent } from '../../../../Assets/Constants/Constent';
import { RightArrowIcon } from '../../../../Assets/Images/CelcomSvgImages';
import { setTrackOrderData } from '../../../../ReduxFeature/StateFeatures/TrackOrderDataState';
import { TrackOrderStatusCal } from '../../../Common/GenericFunctions';

const TrackOrderCard = ({ globalState, pageState, setPageState, data }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { status, addClass } = (TrackOrderStatusCal(data) || {
        status: "",
        addClass: ""
    });

    return (
        <div className="trackorder-card-section" onClick={() => {
            dispatch(setTrackOrderData({
                type: ApiConstant.TRACK_ORDER_SELECTED_DATA,
                data: data
            })) && navigate("/trackorderdetails")
        }}>
            <div className={`wrapper-trackorder-card-section ${addClass}`}>
                <div className="trackorder-card-item-section">
                    <div className="wrapper-trackorder-card-item-group">
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.TRACK_ORDER_CARD_ITEM_TITLE1}
                            </div>
                            <div className="card-item-value">
                                {data?.orderId || "-"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="trackorder-card-item-section">
                    <div className={globalState?.sidebarState ?
                        "trackorder-card-item-group custom-grid-1"
                        :
                        "trackorder-card-item-group"}
                    >
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.TRACK_ORDER_CARD_ITEM_TITLE2}
                            </div>
                            <div className="card-item-value">
                                {data?.orderContext || "-"}
                            </div>
                        </div>
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.TRACK_ORDER_CARD_ITEM_TITLE3}
                            </div>
                            <div className="card-item-value">
                                {data?.orderType || "-"}
                            </div>

                        </div>
                    </div>

                    <div className={globalState?.sidebarState ?
                        "trackorder-card-item-group custom-grid-1"
                        :
                        "trackorder-card-item-group"}
                    >
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.TRACK_ORDER_CARD_ITEM_TITLE4}
                            </div>
                            <div className="card-item-value">
                                {data?.orderReceivedDate?.split("T")[0] || "-"}
                            </div>
                        </div>
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.TRACK_ORDER_CARD_ITEM_TITLE5}
                            </div>
                            <div className="card-item-value">
                                {data?.orderReceivedDate?.split("T")[0] || "-"}
                            </div>
                        </div>
                    </div>

                    <div className={globalState?.sidebarState ?
                        "trackorder-card-item-group custom-grid-1"
                        :
                        "trackorder-card-item-group"}
                    >
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.TRACK_ORDER_CARD_ITEM_TITLE6}
                            </div>
                            <div className="card-item-value">
                                {data?.customerId || "-"}
                            </div>
                        </div>
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.TRACK_ORDER_CARD_ITEM_TITLE7}
                            </div>
                            <div className="card-item-value">
                                {status || "-"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="trackorder-card-item-section" role="button">
                    <RightArrowIcon stroke={"#009BDF"} />
                </div>
            </div>
        </div>
    )
}

export default TrackOrderCard;