import React from 'react';
import { useSelector } from 'react-redux';
import { Constent } from '../../../../../Assets/Constants/Constent';

const UpdateWorkOrderLiveLocationContent = ({ pageState, setPageState }) => {
    const workOrderDataObj = useSelector((state) => state.workOrderInfo);

    const { workOrderDetails, locationBtnEnabled, activeTab, installationCompleteTime } = workOrderDataObj;

    const locationCaptured = (workOrderDetails?.installationDetail?.installerArrivalDate?.length > 0 || workOrderDetails?.installationDetail?.location?.length > 0);

    return (
        <div className={`update-work-order-content-item-group ${activeTab === 2 ? "update-work-order-step-2" : ""}`}>
            {
                activeTab === 1 ?
                    <div className="update-work-order-content-item">
                        <span>{Constent.UPDATE_WORK_ORDER_LOCATION_BTN_LAVEL1}</span>
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input"
                                data-toggle={locationCaptured || locationBtnEnabled ? "" : "modal"}
                                data-target={locationCaptured || locationBtnEnabled ? ""
                                    : "#updateWorkOrderEnabledLocationModal"} value=""
                                id="locationBtn" checked={locationCaptured || locationBtnEnabled || false}
                                onChange={(e) => { }} />
                            <label className="custom-control-label" htmlFor="locationBtn" role={locationCaptured || locationBtnEnabled ? "" : "button"}></label>
                        </div>
                    </div>
                    :
                    <div className="update-work-order-content-item">
                        <div className="update-work-order-content-inner-item-section">
                            {`${Constent.UPDATE_WORK_ORDER_LOCATION_BTN_PART_LAVEL1} `}
                            <span>{Constent.UPDATE_WORK_ORDER_LOCATION_BTN_PART_LAVEL2}</span>
                            {` ${Constent.UPDATE_WORK_ORDER_LOCATION_BTN_PART_LAVEL3}`}
                        </div>
                        <div className="update-work-order-content-inner-item-section">
                            {`${Constent.UPDATE_WORK_ORDER_LOCATION_BTN_LAVEL3} ${installationCompleteTime?.split(" ")[0]}`}
                        </div>
                    </div>
            }
        </div>
    )
}

export default UpdateWorkOrderLiveLocationContent;