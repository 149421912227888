import React from "react";
import { useDispatch } from "react-redux";
import { ApiConstant } from "../../../../Assets/Constants/ApiConstants";
import { Constent } from "../../../../Assets/Constants/Constent";
import { setReturnStockLovData } from "../../../../ReduxFeature/StateFeatures/CartDataState";
import { setStoreTransferLovData } from "../../../../ReduxFeature/StateFeatures/CartDataState";
import RequestCartCard from "../../Cards/Supervisor/RequestCartCard";
import ReturnCartCard from "../../Cards/Supervisor/ReturnCartCard";
import TransferCartCard from "../../Cards/Supervisor/TransferCartCard";

const CartBodyContent = ({
  userDataObj,
  globalState,
  pageState,
  setPageState,
  cartDataObj,
}) => {
  const dispatch = useDispatch();
  const {
    requestStockList,
    returnStockList,
    transferStockList,
    selectedRequestStockList,
    selectedReturnStockList,
    selectedTransferStockList,
    returnReasonValue,
    returnRemarkValue,
    storeTransferValue,
  } = cartDataObj;

  return (
    <div
      className={`body-content-section custom-scroll-bar-section ${
        pageState?.activeTab === Constent.UNDERLINE_NAV_CART_ITEM2 && "pt-0"
      }`}
    >
      {pageState?.activeTab === Constent.UNDERLINE_NAV_CART_ITEM2 && (
        <div
          className="cart-return-drop-down-section mb-3"
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="100"
          hidden={returnStockList == 0}
        >
          <div className="cart-return-drop-down-item-section"></div>
          <div className="cart-return-drop-down-item-section">
            <div className="card-item-group">
              <div className="form-group m-0 custom-drop-down-form-group">
                <select
                  className="custom-select"
                  value={returnReasonValue}
                  onChange={(e) => {
                    dispatch(
                      setReturnStockLovData({
                        type: ApiConstant.CART_REDUX_RETURN_REASON_VALUE,
                        value: e.target.value,
                      })
                    );
                    dispatch(
                      setReturnStockLovData({
                        type: ApiConstant.CART_REDUX_RETURN_REMARK_VALUE,
                        value: "",
                      })
                    );
                  }}
                  id={`${Constent.RETURN_REASON}`}
                >
                  <option defaultValue="">-- Select --</option>
                  <option value="">-- Select --</option>
                  {ApiConstant?.CART_RETURN_REASON_LOVS?.map(
                    (reaturnRasonData, index) => {
                      return (
                        <option
                          value={reaturnRasonData?.value}
                          key={`reaturnRasonData${
                            reaturnRasonData?.value + index
                          }`}
                        >
                          {reaturnRasonData?.display}
                        </option>
                      );
                    }
                  )}
                </select>
                <label htmlFor={`${Constent.RETURN_REASON}`}>
                  {Constent.RETURN_REASON} <span>*</span>
                </label>
              </div>
            </div>
            <div className="card-item-group">
              {returnReasonValue === "Others" ? (
                <div className="form-group mb-0 custom-input-form-group ">
                  <input
                    type="text"
                    className="form-control"
                    value={returnRemarkValue}
                    id={`${Constent.REMARKS}`}
                    placeholder=" "
                    onChange={(e) => {
                      dispatch(
                        setReturnStockLovData({
                          type: ApiConstant.CART_REDUX_RETURN_REMARK_VALUE,
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                  <label htmlFor={`${Constent.REMARKS}`}>
                    {Constent.REMARKS}
                    <span className="text-danger ml-1">*</span>
                  </label>
                  <span className="invalid-msg-text">
                    {Constent.INVALID_INPUT}
                  </span>
                </div>
              ) : (
                <div className="form-group m-0 custom-drop-down-form-group">
                  <select
                    className="custom-select"
                    value={returnRemarkValue}
                    onChange={(e) =>
                      dispatch(
                        setReturnStockLovData({
                          type: ApiConstant.CART_REDUX_RETURN_REMARK_VALUE,
                          value: e.target.value,
                        })
                      )
                    }
                    id={`${Constent.REMARKS}`}
                  >
                    <option defaultValue="">-- Select --</option>
                    <option value="">-- Select --</option>
                    {ApiConstant?.CART_RETURN_REMARKS_LOVS?.map(
                      (remarksData, index) => {
                        return (
                          <option
                            value={remarksData?.value}
                            key={`remarksData${remarksData?.value + index}`}
                          >
                            {remarksData?.display}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <label htmlFor={`${Constent.REMARKS}`}>
                    {Constent.REMARKS}
                    <span>*</span>
                  </label>
                </div>
              )}
            </div>
          </div>
          <div className="cart-return-drop-down-item-section"></div>
        </div>
      )}
      {pageState?.activeTab === Constent.UNDERLINE_NAV_CART_ITEM3 && (
        <div
          className="cart-return-drop-down-section mb-3"
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="100"
          hidden={transferStockList == 0}
        >
          <div className="cart-return-drop-down-item-section"></div>
          <div className="cart-return-drop-down-item-section">
            <div className="card-item-group">
              <div className="form-group m-0 custom-drop-down-form-group">
                <select
                  className="custom-select"
                  value={storeTransferValue}
                  onChange={(e) => {
                    dispatch(
                      setStoreTransferLovData({
                        type: ApiConstant.CART_REDUX_TRANSFER_REMARK_VALUE,
                        value: e.target.value,
                      })
                    );
                  }}
                  id={`${Constent.TRANSFER_TO_STORE}`}
                >
                  <option defaultValue="">-- Select --</option>
                  <option value="">-- Select --</option>
                  {ApiConstant?.CART_STORE_TO_TRANSFER_LOVS?.map(
                    (returnStoreTransfer, index) => {
                      return (
                        <option
                          value={returnStoreTransfer?.value}
                          key={`returnStoreTransfer${
                            returnStoreTransfer?.value + index
                          }`}
                        >
                          {returnStoreTransfer?.display}
                        </option>
                      );
                    }
                  )}
                </select>
                <label htmlFor={`${Constent.TRANSFER_TO_STORE}`}>
                  {Constent.TRANSFER_TO_STORE} <span>*</span>
                </label>
              </div>
            </div>
          </div>
          <div className="cart-return-drop-down-item-section"></div>
        </div>
      )}
      <div
        className="cart-select-all-item-section"
        onClick={() => {
          pageState?.activeTab === Constent.UNDERLINE_NAV_CART_ITEM1
            ? setPageState({
                ...pageState,
                requestStockSelectAll: !pageState.requestStockSelectAll,
              })
            : pageState?.activeTab === Constent.UNDERLINE_NAV_CART_ITEM2
            ? setPageState({
                ...pageState,
                returnStockSelectAll: !pageState.returnStockSelectAll,
              })
            : setPageState({
                ...pageState,
                transferStockSelectAll: !pageState.transferStockSelectAll,
              });
        }}
      >
        {pageState?.activeTab === Constent.UNDERLINE_NAV_CART_ITEM1
          ? requestStockList.length > 0
            ? pageState?.requestStockSelectAll
              ? Constent.UNSELECT_ALL_BTN
              : Constent.SELECT_ALL_BTN
            : null
          : pageState?.activeTab === Constent.UNDERLINE_NAV_CART_ITEM2
          ? returnStockList.length > 0
            ? pageState?.returnStockSelectAll
              ? Constent.UNSELECT_ALL_BTN
              : Constent.SELECT_ALL_BTN
            : null
          : transferStockList.length > 0
          ? pageState?.transferStockSelectAll
            ? Constent.UNSELECT_ALL_BTN
            : Constent.SELECT_ALL_BTN
          : null}
      </div>
      <div
        className="wrapper-body-content-section mt-3"
        data-aos="fade-left"
        data-aos-duration="1000"
        data-aos-delay="100"
      >
        {pageState?.activeTab === Constent.UNDERLINE_NAV_CART_ITEM1 ? (
          <>
            {requestStockList?.map((data, index) => {
              return (
                <RequestCartCard
                  globalState={globalState}
                  pageState={pageState}
                  selectedRequestStockList={selectedRequestStockList}
                  requestStockList={requestStockList}
                  setPageState={setPageState}
                  data={data}
                  index={index}
                  key={`request${(data?.sAPMaterialCode || "-") + index}`}
                />
              );
            })}
          </>
        ) : pageState?.activeTab === Constent.UNDERLINE_NAV_CART_ITEM2 ? (
          <>
            {returnStockList?.map((data, index) => {
              return (
                <ReturnCartCard
                  globalState={globalState}
                  pageState={pageState}
                  returnStockList={returnStockList}
                  selectedReturnStockList={selectedReturnStockList}
                  setPageState={setPageState}
                  data={data}
                  index={index}
                  key={`return${(data?.sAPMaterialCode || "-") + index}`}
                />
              );
            })}
          </>
        ) : (
          <>
            {transferStockList?.map((data, index) => {
              return (
                <TransferCartCard
                  globalState={globalState}
                  pageState={pageState}
                  transferStockList={transferStockList}
                  selectedTransferStockList={selectedTransferStockList}
                  setPageState={setPageState}
                  data={data}
                  index={index}
                  key={`return${(data?.sAPMaterialCode || "-") + index}`}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default CartBodyContent;
