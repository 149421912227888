import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiConstant } from '../../../../../Assets/Constants/ApiConstants';
import { Constent } from '../../../../../Assets/Constants/Constent';
import { TickMarkIcon } from '../../../../../Assets/Images/CelcomSvgImages';
import { updateWorkOrderDetailsData } from '../../../../../ReduxFeature/StateFeatures/WorkOrderDataState';
import { EmptyValueHandler, NumberFormater } from '../../../../Common/GenericFunctions';

const BTUAccordionBodyContent = ({ deviceDetails, workOrderDataObj }) => {
    const dispatch = useDispatch();
    const allDevicesList = deviceDetails?.rgwDeviceList?.concat(deviceDetails?.btuDeviceList, deviceDetails?.meshDeviceList);

    const { btuDeviceCollected, btuId, workOrderDetails } = workOrderDataObj;

    const [validation, setValidation] = useState({ btuIDClass: "" })

    return (
        <div className="card">
            <div className="card-header" id="workOrderHeadingTwo">
                <button className="btn btn-link btn-block text-dark text-left custom-accordion-btn-link collapsed"
                    type="button" data-toggle="collapse" data-target="#workOrderCollapseThree"
                    aria-expanded="false" aria-controls="workOrderCollapseThree">
                    {Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_TITLE1}
                </button>
            </div>
            <div id="workOrderCollapseThree" className="collapse btu-workorder-card-collapse"
                aria-labelledby="workOrderHeadingTwo"
                data-parent="#workOrderCollapseThree">
                <div className="card-body">
                    {
                        !(workOrderDetails?.itemSubTypeId?.toLowerCase()?.includes("Relocation"?.toLowerCase())) ?
                            <div className="custom-card-body-item-group-section">
                                <div className="form-group m-0 custom-drop-down-form-group">
                                    <select className="custom-select" value={btuDeviceCollected} onChange={(e) => {
                                        dispatch(updateWorkOrderDetailsData({
                                            type: ApiConstant.INSTALLER_BTU_DEVICE_COLLECTED_INPUTVALUE,
                                            value: e.target.value
                                        }));
                                    }}>
                                        <option defaultValue="" value="" >--Select--</option>
                                        {
                                            ApiConstant.WORK_ORDER_UPDATE_BTU_TYPE_LOVS?.map(btuDeviceLov => {
                                                return (
                                                    <option key={btuDeviceLov?.value} value={btuDeviceLov?.value} >
                                                        {btuDeviceLov?.display}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label htmlFor={Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_DEVICE_COLLECTED_LABEL}>
                                        {Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_DEVICE_COLLECTED_LABEL}<span>*</span>
                                    </label>
                                </div>
                            </div> : <></>
                    }


                    <div className="custom-card-body-item-group-section">
                        <div className={`form-group mb-0 custom-input-form-group custom-normal-input-form-group ${validation?.btuIDClass}`}>
                            { /* custom-valid-form-group --- custom-invalid-form-group*/}
                            <div className="input-group">
                                <input type="text" className="form-control" value={btuId}
                                    onChange={e => {
                                        setValidation({ ...validation, btuIDClass: "" })
                                        dispatch(updateWorkOrderDetailsData({
                                            type: ApiConstant.INSTALLER_BTU_ID_INPUTVALUE,
                                            value: e.target.value
                                        }));
                                    }}
                                    onBlur={e => {
                                        setValidation({
                                            ...validation,
                                            btuIDClass: btuId?.length >= Constent.BTU_SERIAL_NUMBER_MIN ? btuId?.length <= Constent.BTU_SERIAL_NUMBER_MAX ? "custom-valid-form-group" : "custom-invalid-form-group"
                                                : ""
                                        })
                                    }}
                                    id={Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL2} placeholder=" " />
                                <label htmlFor={Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL2}>
                                    {Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL2}
                                </label>
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <TickMarkIcon stroke={"#009BDF"} />
                                    </span>
                                </div>
                            </div>
                            <span className='invalid-msg-text'>{Constent.INVALID_INPUT}</span>
                        </div>
                    </div>

                    {/* <div className="custom-card-body-item-group-section">
                        {
                            allDevicesList?.map((deviceData, index) => {
                                return (
                                    <div className="btu-device-item-group-section" key={`btuDeviceList${index}`}>
                                        <div className="btu-device-item-section"><span>
                                            {
                                                NumberFormater({ numberValue: index + 1 })
                                            }
                                        </span></div>
                                        <div className="btu-device-item-section">
                                            <div className="card-item-group">
                                                <div className="card-item-title">
                                                    {Constent.WORK_ORDER_OST_DEVICE_ITEM_TITLE1}
                                                </div>
                                                <div className="card-item-value">
                                                    {EmptyValueHandler({ item: deviceData?.name })}
                                                </div>
                                            </div>
                                            <div className="card-item-group">
                                                <div className="card-item-title">
                                                    {"Product Id"}
                                                </div>
                                                <div className="card-item-value">
                                                    {EmptyValueHandler({ item: deviceData?.productID })}
                                                </div>
                                            </div>
                                            <div className="card-item-group">
                                                <div className="card-item-title">
                                                    {"Device Type"}
                                                </div>
                                                <div className="card-item-value">
                                                    {EmptyValueHandler({ item: deviceData?.deviceType })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default BTUAccordionBodyContent;