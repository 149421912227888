import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ApiConstant } from "../../../../../Assets/Constants/ApiConstants";
import { Constent } from "../../../../../Assets/Constants/Constent";
import SignatureModal from "./SignatureModal";
import CustomerSignModal from "./CustomerSignModal";
import { TickMarkIcon } from "../../../../../Assets/Images/CelcomSvgImages";

const InstallerSignature = ({
    sign,
    setSign,
    installerUrl,
    setInstallerUrl,
    customerSign,
    setCustomerSign,
    setCustomertUrl,
    customerUrl,
    setSignData,
    signData,
}) => {
    const [checkbox, setCheckBox] = useState();
    const workOrderDataObj = useSelector((state) => state.workOrderInfo)
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState('');
  
    // Handle input change
    // const handleChange = (event) => {
    //   const { value } = event.target;
  
    //   // Regular expression to allow only alphanumeric characters
    //   const alphanumericRegex = /^[a-zA-Z0-9]*$/;
  
    //   if (alphanumericRegex.test(value) || value === '') {
    //     setInputValue(value);
    //     setError('');
    //   } else {
    //     setError('Input must be alphanumeric');
    //   }
    // };

    function link(){
        window.open('https://cdn.prod.website-files.com/637c596725cb8b7f82fe360b/66441fbebd3f2567c6fda490_CelcomDigi_Terms_and_Conditions_CelcomDigi_Fibre_14052024.pdf', '_blank')
      }
      function faqLink(){
        window.open('https://help.celcomdigi.com/support/solutions/articles/70000657275-celcomdigi-fibre', '_blank')
      }
    return (
        <>
            <div>
                {workOrderDataObj?.workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OSI_TYPE ||
                    workOrderDataObj?.workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OST_TYPE
                    ? (
                        <div className="checkmark">
                            <div className="custom-signature-section">
                                <div>
                                    <div className="signature-box"
                                        // data-toggle="modal"
                                        // data-target="#instSignDocumentModal"
                                    >
                                        {installerUrl &&
                                            <img src={installerUrl} height={70} width={80} />
                                        }
                                        <span>Installer Signature<span>*</span></span>
                                    </div>
                                    <div className="form-group mb-0 mt-4 custom-input-form-group custom-normal-input-form-group">
                                        <div className="input-custom-input-form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={signData?.installerName}
                                                disabled = {true}
                                                onChange={(e) => {
                                                    setSignData((prevValues) => ({
                                                        ...prevValues,
                                                        installerName: e.target.value,
                                                      }));
                                                }}
                                                id={
                                                    Constent.INSTALLER_NAME
                                                }
                                                placeholder=" "
                                            />
                                            <label
                                                htmlFor={
                                                    Constent.INSTALLER_NAME
                                                }
                                            >
                                                {
                                                    Constent.INSTALLER_NAME
                                                }
                                                <span>*</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="signature-box"
                                        // data-toggle="modal"
                                        // data-target="#custSignDocumentModal"
                                    >
                                        {customerUrl &&
                                            <img src={customerUrl} height={70} width={80} />
                                        }
                                        <span>Customer Signature<span>*</span></span>
                                    </div>
                                    <div className="form-group mb-0 mt-4 custom-input-form-group custom-normal-input-form-group">
                                        <div className="input-custom-input-form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={signData?.customerName}
                                                disabled = {true}
                                                name="name" 
                                                // pattern= '[A-Za-z0-9]{0,15}'
                                                onkeyup="this.value=this.value.replace(/[^A-z]/g,'');"
                                                onChange={(e) => {
                                                    setSignData((prevValues) => ({
                                                        ...prevValues,
                                                        customerName: e.target.value,
                                                      }));
                                                }}
                                                id={
                                                    Constent.CUSTOMER_NAME
                                                }
                                                placeholder=" "
                                            />
                                            <label
                                                htmlFor={
                                                    Constent.CUSTOMER_NAME
                                                }
                                            >
                                                {
                                                    Constent.CUSTOMER_NAME
                                                }
                                                <span>*</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <SignatureModal
                                setSign={setSign}
                                sign={sign}
                                setInstallerUrl={setInstallerUrl}
                                installerUrl={installerUrl}
                            />
                            <CustomerSignModal
                                setCustomerSign={setCustomerSign}
                                customerSign={customerSign}
                                setCustomerUrl={setCustomertUrl}
                                customerUrl={customerUrl}
                            />
                            {workOrderDataObj?.workOrderStatus != "RETURNED" &&
                                <>
                                    <label class="main">I hereby confirm that the equipment, installation or restoration
                                        service provided is acceptable and complete to my satisfaction.<span className="important">*</span>
                                        <input className="custom-control-input"
                                            type="checkbox" 
                                            disabled = {true}
                                            onChange={(e) => {
                                                setSignData((prevValues) => ({
                                                    ...prevValues,
                                                    confirm: e.target.value,
                                                  }));
                                            }}
                                            />
                                        <span class="checkbox-container"></span>
                                    </label>
                                    <label class="main">By signing this you acknowledge and accept CelcomDigi Fibre's General
                                        Terms & Conditions and FAQ, all work shall be deemed completed and billing instruction
                                        shall proceed to you accordingly.<span className="important">*</span>
                                        <input type="checkbox"
                                        disabled = {true}
                                         onChange={(e) => {
                                            setSignData((prevValues) => ({
                                                ...prevValues,
                                                acknowledge: e.target.value,
                                              }));
                                        }}
                                        />
                                        <span class="checkbox-container"></span>
                                    </label>
                                </>
                            }
                            <div className="linkCondition">
                                    <span onClick={link} className= "termslink" >
                                    Terms&Conditions</span>
                                    <span onClick={faqLink} className="termslink">FAQ</span>
                                    </div>
                        </div>
                    ) :
                    ""}
            </div>
        </>
    )
}
export default InstallerSignature
