import React from 'react'
import { ApiConstant } from '../../../../../Assets/Constants/ApiConstants';
import { Constent } from '../../../../../Assets/Constants/Constent'

const WorkOrderDetailsTimeLine = ({ workOrderDetailsObj }) => {
    const orderDetails = workOrderDetailsObj?.ownOrderDetails;
    const installationDetail = workOrderDetailsObj?.installationDetail;
    return (
        <ul>
            <li>
                <div className={`timeline-dot ${workOrderDetailsObj?.createdDate?.split("T")[0]?.trim()?.length > 0 ? "status-green" : ""}`}></div>
                {Constent.WORK_ORDER_DETAILS_CONTENT_TIMELINE_ITEM1}
                <br />
                <strong>
                    {workOrderDetailsObj?.createdDate?.substring(0, 16)?.replace("T", " ") || "-"}
                </strong>
            </li>
            <li>
                <div className={`timeline-dot ${workOrderDetailsObj?.resolutionDate?.split("T")[0]?.trim()?.length > 0 ? "status-green" : ""}`}></div>
                {Constent.WORK_ORDER_DETAILS_CONTENT_TIMELINE_ITEM2}
                <br />
                <strong>
                    {/* {workOrderDetailsObj?.resolutionDate?.split("T")[0]?.trim() || "-"} */}
                    {workOrderDetailsObj?.resolutionDate?.substring(0, 16)?.replace("T", " ") || "-"}
                </strong>
            </li>
            <li>
                <div className={`timeline-dot ${installationDetail?.installerArrivalDate?.split(" ")[0]?.trim()?.length > 0 ? "status-green" : ""}`}></div>
                {Constent.WORK_ORDER_DETAILS_CONTENT_TIMELINE_ITEM3}
                <br />
                <strong>
                    {installationDetail?.installerArrivalDate?.substring(0, 16)?.replace("T", " ") || "-"}
                </strong>
            </li>
            {
                workOrderDetailsObj?.itemStatus === ApiConstant?.WORK_ORDER_STATUS_RETURNED ||
                    workOrderDetailsObj?.itemStatus === ApiConstant?.WORK_ORDER_STATUS_CANCLED ?
                    <li>
                        <div className={`timeline-dot ${((workOrderDetailsObj?.itemStatus === ApiConstant?.WORK_ORDER_STATUS_RETURNED ||
                            workOrderDetailsObj?.itemStatus === ApiConstant?.WORK_ORDER_STATUS_CANCLED) &&
                            installationDetail?.returnedDate?.split(" ")[0]?.trim()) ? "status-green" : ""}`}></div>
                        {workOrderDetailsObj?.itemStatus === ApiConstant?.WORK_ORDER_STATUS_CANCLED ? ApiConstant?.WORK_ORDER_STATUS_CANCLED:Constent.WORK_ORDER_DETAILS_CONTENT_TIMELINE_ITEM6}
                        <br />
                        <strong>
                            {installationDetail?.returnedDate?.substring(0, 16)?.replace("T", " ") || "-" || "-"}
                        </strong>
                    </li>
                    :
                    <>
                        <li>
                            <div className={`timeline-dot ${installationDetail?.installationCompleteDate?.split(" ")[0]?.trim()?.length > 0 ? "status-green" : ""}`}></div>
                            {Constent.WORK_ORDER_DETAILS_CONTENT_TIMELINE_ITEM4}
                            <br />
                            <strong>
                                {installationDetail?.installationCompleteDate?.substring(0, 16)?.replace("T", " ") || "-"}
                            </strong>
                        </li>
                        <li>
                            <div className={`timeline-dot ${installationDetail?.workorderCompleteDate?.split(" ")[0]?.trim()?.length > 0 ? "status-green" : ""}`}></div>
                            {Constent.WORK_ORDER_DETAILS_CONTENT_TIMELINE_ITEM5}
                            <br />
                            <strong>
                                {installationDetail?.workorderCompleteDate?.substring(0, 16)?.replace("T", " ") || "-"}
                            </strong>
                        </li>
                    </>
            }

        </ul>
    )
}

export default WorkOrderDetailsTimeLine