import React, { useEffect, useState } from 'react';
import { headers, UpdateInsatller } from '../../Api/ApiCalls';
import { ApiConstant } from '../../Assets/Constants/ApiConstants';
import { Constent } from '../../Assets/Constants/Constent';
import { CloseModalIcon } from '../../Assets/Images/CelcomSvgImages';
import { CardItemGroup } from '../Common/ModalHelper';

import { toast } from 'react-toastify';
import { setInstallerData } from '../../ReduxFeature/StateFeatures/InstallerDataState';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const EditInstallerModal = ({ installerDetails, pageState, setPageState, userDataObj }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [editInstaller, setEditInstaller] = useState({
        editContact: "",
        editEmail: "",
        enableEditBtn: false
    });
    const [errorHandeller, setErrorHandeller] = useState({
        editContact: "",
        editEmail: "",
        editContactValidation: true,
        editEmailValidation: true
    });
    const UpdateInstallerInformationApiCall = () => {
        if (editInstaller?.enableEditBtn) {
            setPageState({ ...pageState, apiCallAction: true, apiCallActionMsg: "Updating..." });
            const header = headers(
                userDataObj?.userData?.activeUserData?.authToken
            );

            const installerUpdateRequest = {
                "userNbr": installerDetails?.userNbr,
                "accessLevel": installerDetails?.accessLevel,
                "partnerId": installerDetails?.partnerId,
                "outletId": installerDetails?.outletId,
                "outletName": installerDetails?.outletName,
                "outletType": installerDetails?.outletType,
                "userId": installerDetails?.userId,
                "companyName": installerDetails?.companyName || installerDetails?.outletName,
                "firstName": installerDetails?.firstName,
                "lastName": installerDetails?.lastName,
                "id": installerDetails?.id,
                "brnid": installerDetails?.brnid,
                "email": editInstaller?.editEmail,
                "mobileNo": editInstaller?.editContact,
                "supervisorId": userDataObj?.userData?.activeUserData?.userData?.userId,
                "district": installerDetails?.district,
                "idType": installerDetails?.idTypeDto,
                "preferredLang": installerDetails?.preferredLang
            }

            UpdateInsatller("/fibre/update/information", installerUpdateRequest, header).then(response => {
                setPageState({ ...pageState, apiCallAction: false });
                if (response?.result?.toLowerCase()?.includes("Successfully"?.toLowerCase())) {
                    dispatch(setInstallerData({
                        type: ApiConstant.CREATE_INSTALLER_RESPONSE,
                        value: response?.result
                    }));
                    dispatch(setInstallerData({
                        type: ApiConstant.MANAGE_INSTALLER_INPUT,
                        value: installerDetails?.userId
                    }));
                    setEditInstaller({
                        editContact: "",
                        editEmail: "",
                        enableEditBtn: false
                    });

                    navigate("/manageinstaller/confirm");
                } else {
                    toast.warn(response?.result || "No Updates Found",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 10000,
                            theme: 'colored'
                        }
                    );
                }
            }).catch(error => {
                setPageState({ ...pageState, apiCallAction: false });
                toast.error(ApiConstant.GET_INSTALLER_ERROR_MESSAGE_WRONG || error.response.statusText,
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 10000,
                        theme: 'colored'
                    }
                );
            });
        }

    }
    useEffect(() => {

        let editBtn = false;
        if (editInstaller?.editContact !== "" && editInstaller?.editEmail !== ""
            && errorHandeller?.editContactValidation && errorHandeller?.editEmailValidation) {
            editBtn = true;
        }
        setEditInstaller({ ...editInstaller, enableEditBtn: editBtn });

    }, [errorHandeller]);
    return (
        <div className="modal fade installer-data-manage-modal" id="editInstallerModal" data-backdrop="static"
            data-keyboard="false" tabIndex="-1" aria-labelledby="editInstallerModalLabel"
            aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header pb-0">
                        <div className="wrapper-modal-header pt-5">
                            <div className="modal-header-title-section">
                                <h5 className="modal-title" id="editInstallerModalLabel">
                                    {Constent.EDIT_INSTALLER_MODAL_TITLE}
                                </h5>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <CloseModalIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="wrapper-modal-body">
                            <div className="mobal-body-section-group">
                                <CardItemGroup title={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE3}
                                    value={installerDetails?.userId || "-"} />
                                <CardItemGroup title={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE1}
                                    value={installerDetails?.firstName || "-"} />
                                <CardItemGroup title={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE2}
                                    value={installerDetails?.email || "-"} />
                                <CardItemGroup title={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE5}
                                    value={installerDetails?.mobileNo || "-"} />
                                <CardItemGroup title={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE6}
                                    value={installerDetails?.idType || "-"} />
                                <CardItemGroup title={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE7}
                                    value={installerDetails?.id || "-"} />
                                <CardItemGroup title={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE4}
                                    value={installerDetails?.district || "-"} />

                            </div>
                            <div className="mobal-body-section-group">
                                <div className="card-item-group">
                                    <div className={`form-group mb-0 custom-input-form-group ${editInstaller?.editEmail === "" ? "" :
                                        errorHandeller?.editEmail?.length > 0 ? "custom-invalid-form-group" :
                                            errorHandeller?.editEmailValidation ? "custom-valid-form-group" : ""}`}>
                                        <input type="text" className="form-control"
                                            id={`edit${Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE10}`}
                                            name="editEmail" placeholder=" " value={editInstaller.editEmail}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                setEditInstaller({ ...editInstaller, editEmail: value });

                                            }} onBlur={e => {
                                                const { value } = e.target;
                                                const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

                                                if (regex.test(value)) {
                                                    setErrorHandeller({ ...errorHandeller, editEmail: "", editEmailValidation: true });
                                                }
                                                else if (value === "") {
                                                    setErrorHandeller({ ...errorHandeller, editEmail: "", editEmailValidation: false })
                                                }
                                                else {
                                                    setErrorHandeller({
                                                        ...errorHandeller, editEmail: "Please enter valid email id",
                                                        editEmailValidation: false
                                                    })
                                                }
                                            }} />
                                        <label htmlFor={`edit${Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE10}`}>
                                            {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE10}
                                        </label>
                                        <span className='invalid-msg-text'>{errorHandeller.editEmail || Constent.INVALID_INPUT}</span>
                                    </div>
                                </div>
                                <div className="card-item-group">
                                    <div className={`form-group mb-0 custom-input-form-group ${editInstaller?.editContact === "" ? "" :
                                        errorHandeller?.editContact?.length > 0 ? "custom-invalid-form-group" :
                                            errorHandeller?.editContactValidation ? "custom-valid-form-group" : ""}`}>
                                        <input type="number" className="form-control" value={editInstaller.editContact}
                                            id={`edit${Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE5}`} placeholder=" "
                                            name="editContact"
                                            onChange={e => {
                                                setEditInstaller({ ...editInstaller, editContact: e.target.value })
                                            }} onBlur={e => {
                                                const { value } = e.target;
                                                if (value?.length >= 10 && value?.length <= 20) {
                                                    setErrorHandeller({ ...errorHandeller, editContact: "", editContactValidation: true });
                                                }
                                                else if (value === "") {
                                                    setErrorHandeller({ ...errorHandeller, editContact: "", editContactValidation: false })
                                                }
                                                else {
                                                    setErrorHandeller({
                                                        ...errorHandeller, editContact: "Please enter valid contact number",
                                                        editContactValidation: false
                                                    })
                                                }
                                            }} />
                                        <label htmlFor={`edit${Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE5}`}>
                                            {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE5}
                                        </label>
                                        <span className='invalid-msg-text'>{Constent.INVALID_INPUT}</span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="mobal-body-section-group">
                                <div className="card-item-group">
                                    <div className="form-group m-0 custom-drop-down-form-group">
                                        <select className="custom-select"
                                            // onChange={(e) => console.log(e)}
                                            id={`edit${Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE4}`}>
                                            <option defaultValue="">-- Select --</option>
                                            <option value="Month">Month</option>
                                            <option value="Day">Day</option>
                                        </select>
                                        <label htmlFor={`edit${Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE4}`}>
                                            {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE4}
                                        </label>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="refresh-btn-section" onClick={() => {
                            setErrorHandeller({
                                editContact: "",
                                editEmail: "",
                                editContactValidation: true,
                                editEmailValidation: true
                            });

                            setEditInstaller({
                                editContact: "",
                                editEmail: "",
                                enableEditBtn: false
                            });
                        }}>
                            <span>{Constent.REFRESH_BTN}</span>
                        </div>
                        <div className={`search-btn-section ${editInstaller?.enableEditBtn ? "" : "inactive-btn"}`}
                            data-dismiss={editInstaller?.enableEditBtn ? "modal" : null} onClick={UpdateInstallerInformationApiCall}>
                            {Constent.EDIT_BTN}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditInstallerModal