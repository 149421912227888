import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Footer from "../../Components/Footer/Footer";

import { Header } from "../../Components/Header/Header";
import CartBodyHeader from "../../Components/MainBody/BodyHeader/Supervisor/CartBodyHeader";
import SupervisorSidebar from "../../Components/MainBody/Sidebar/SupervisorSidebar";

import "../../Assets/CSS/CartPage.css";
import "../../Assets/CSS/RequestStockPlaceOrderModal.css";
import "../../Assets/CSS/ReturnStockPlaceOrderModal.css";
import CartBodyContent from "../../Components/MainBody/BodyContent/Supervisor/CartBodyContent";
import { Constent } from "../../Assets/Constants/Constent";
import RequestStockPlaceOrderModal from "../../Components/Modals/RequestStockPlaceOrderModal";
import CartAddSerialNumberModal from "../../Components/Modals/CartAddSerialNumberModal";
import { ReturnStockCartPlaceOrderBtnValidation } from "../../Components/Common/Validation";
import ReturnStockPlaceOrderModal from "../../Components/Modals/ReturnStockPlaceOrderModal";
import { ToastContainer } from "react-toastify";
import { ApiActionLoader } from "../../Components/Loaders/ApiActionLoader";
import TransferStockPlaceOrderModal from "../../Components/Modals/TransferStockPlaceOrderModal";

const CartPage = ({ globalState, setGlobalState }) => {
  const userDataObj = useSelector((state) => state.userDataInfo);
  const cartDataObj = useSelector((state) => state.cartDataInfo);

  const {
    requestStockList,
    returnStockList,
    transferStockList,
    selectedRequestStockList,
    selectedReturnStockList,
    selectedTransferStockList,
    returndSerialNumberObj,
    returnReasonValue,
    storeTransferValue,
    returnRemarkValue,
  } = cartDataObj;

  const [pageState, setPageState] = useState({
    requestStockSelectAll: false,
    returnStockSelectAll: false,
    activeTab:
      returnStockList?.length > 0 &&
      requestStockList?.length === 0 &&
      transferStockList?.length === 0
        ? Constent.UNDERLINE_NAV_CART_ITEM2
        : returnStockList?.length === 0 &&
          requestStockList?.length > 0 &&
          transferStockList?.length === 0
        ? Constent.UNDERLINE_NAV_CART_ITEM1
        : Constent.UNDERLINE_NAV_CART_ITEM3,
    clickedSerialNumberItem: null,

    returnStockPlaceOrderBtnActive: false,
    totalRetunedItems: 0,

    apiCallAction: false,
    apiCallActionMsg: "Progressing...",
  });

  useEffect(() => {
    const { flag, count } = ReturnStockCartPlaceOrderBtnValidation(
      selectedReturnStockList,
      returndSerialNumberObj
    );
    setPageState({
      ...pageState,
      returnStockPlaceOrderBtnActive: flag,
      totalRetunedItems: count,
    });
  }, [selectedReturnStockList, returndSerialNumberObj]);

  return (
    <div className="page-start-section">
      {pageState?.apiCallAction && (
        <ApiActionLoader msg={pageState?.apiCallActionMsg} />
      )}
      <Header
        userDataObj={userDataObj}
        globalState={globalState}
        setGlobalState={setGlobalState}
        tab5={true}
      />
      <div
        className={
          globalState?.sidebarState
            ? "body-start-section sidebar-active-section"
            : "body-start-section"
        }
      >
        {/* Sidebar */}
        <SupervisorSidebar
          userDataObj={userDataObj}
          globalState={globalState}
          setGlobalState={setGlobalState}
        />
        <div className="body-full-content-section">
          <CartBodyHeader
            userDataObj={userDataObj}
            globalState={globalState}
            setGlobalState={setGlobalState}
            pageState={pageState}
            setPageState={setPageState}
            requestStockList={requestStockList}
            returnStockList={returnStockList}
            transferStockList={transferStockList}
          />
          <CartBodyContent
            userDataObj={userDataObj}
            globalState={globalState}
            setGlobalState={setGlobalState}
            pageState={pageState}
            setPageState={setPageState}
            cartDataObj={cartDataObj}
          />

          {selectedRequestStockList?.length > 0 &&
            pageState?.activeTab === Constent.UNDERLINE_NAV_CART_ITEM1 && (
              <div
                className={
                  globalState?.sidebarState
                    ? "cart-place-order-section sidebar-active-section"
                    : "cart-place-order-section"
                }
              >
                <div className="wrapper-cart-place-order-section">
                  <div className="cart-place-order-item-group">
                    <span>{`${selectedRequestStockList?.length?.toLocaleString(
                      "en-US",
                      {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      }
                    )} `}</span>
                    {`${Constent.CART_REQUEST_STOCK_PLACE_ORDER_MSG}`}
                  </div>
                  <div
                    className="cart-place-order-item-group"
                    data-toggle="modal"
                    data-target="#requestStockPlaceOrderModal"
                  >
                    <div className="place-order-btn-section">
                      <span>{Constent.CART_PLACE_ORDER_BTN}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {selectedReturnStockList?.length > 0 &&
            pageState?.activeTab === Constent.UNDERLINE_NAV_CART_ITEM2 && (
              <div
                className={
                  globalState?.sidebarState
                    ? "cart-place-order-section cart-return-place-order-section sidebar-active-section"
                    : "cart-place-order-section cart-return-place-order-section"
                }
              >
                <div className="wrapper-cart-place-order-section">
                  <div
                    className={
                      pageState?.returnStockPlaceOrderBtnActive
                        ? "cart-place-order-item-group"
                        : "cart-place-order-item-group cart-msg-validation"
                    }
                  >
                    {pageState?.returnStockPlaceOrderBtnActive ? (
                      <>
                        <span>{`${pageState?.totalRetunedItems?.toLocaleString(
                          "en-US",
                          {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          }
                        )} `}</span>
                        {`${Constent.CART_RETURN_STOCK_PLACE_ORDER_MSG}`}
                      </>
                    ) : (
                      <span>{`${Constent.CART_RETURN_STOCK_VALIDATION}`}</span>
                    )}
                  </div>
                  <div
                    className={
                      pageState?.returnStockPlaceOrderBtnActive
                        ? "cart-place-order-item-group"
                        : "cart-place-order-item-group inactive-btn"
                    }
                    data-toggle={
                      pageState?.returnStockPlaceOrderBtnActive ? "modal" : ""
                    }
                    data-target={
                      pageState?.returnStockPlaceOrderBtnActive
                        ? "#returnStockPlaceOrderModal"
                        : ""
                    }
                  >
                    <div className="place-order-btn-section">
                      <span>{Constent.CART_PLACE_ORDER_BTN}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {selectedTransferStockList?.length > 0 &&
            pageState?.activeTab === Constent.UNDERLINE_NAV_CART_ITEM3 && (
              <div
                className={
                  globalState?.sidebarState
                    ? "cart-place-order-section sidebar-active-section"
                    : "cart-place-order-section"
                }
              >
                <div className="wrapper-cart-place-order-section">
                  <div className="cart-place-order-item-group">
                    <span>{`${selectedTransferStockList?.length?.toLocaleString(
                      "en-US",
                      {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      }
                    )} `}</span>
                    {`${Constent.CART_TRANSFER_STOCK_PLACE_ORDER_MSG}`}
                  </div>
                  <div
                    className="cart-place-order-item-group"
                    data-toggle="modal"
                    data-target="#transferStockPlaceOrderModal"
                  >
                    <div className="place-order-btn-section">
                      <span>{Constent.CART_PLACE_ORDER_BTN}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
        <Footer />
      </div>
      {selectedRequestStockList?.length > 0 &&
        pageState?.activeTab === Constent.UNDERLINE_NAV_CART_ITEM1 && (
          <RequestStockPlaceOrderModal
            selectedRequestStockList={selectedRequestStockList}
            pageState={pageState}
            setPageState={setPageState}
            userDataObj={userDataObj}
          />
        )}
      {selectedReturnStockList?.length > 0 &&
        pageState?.activeTab === Constent.UNDERLINE_NAV_CART_ITEM2 && (
          <ReturnStockPlaceOrderModal
            selectedReturnStockList={selectedReturnStockList}
            returndSerialNumberObj={returndSerialNumberObj}
            pageState={pageState}
            setPageState={setPageState}
            userDataObj={userDataObj}
            returnReasonValue={returnReasonValue}
            returnRemarkValue={returnRemarkValue}
          />
        )}
      {selectedTransferStockList?.length > 0 &&
        pageState?.activeTab === Constent.UNDERLINE_NAV_CART_ITEM3 && (
          <TransferStockPlaceOrderModal
            selectedTransferStockList={selectedTransferStockList}
            pageState={pageState}
            setPageState={setPageState}
            userDataObj={userDataObj}
            storeTransferValue={storeTransferValue}
          />
        )}

      {pageState?.clickedSerialNumberItem?.sAPMaterialCode?.length > 0 && (
        <CartAddSerialNumberModal
          data={pageState?.clickedSerialNumberItem}
          seletedSerialList={
            returndSerialNumberObj[
              pageState?.clickedSerialNumberItem?.sAPMaterialCode
            ]
          }
          returndSerialNumberObj={returndSerialNumberObj}
          userDataObj={userDataObj}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default CartPage;
