import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveUserData } from "../ReduxFeature/StateFeatures/UserDataState";
import { loginHeader } from "../Api/ApiCalls";
import axios from "axios";
import { ApiConstant } from "../Assets/Constants/ApiConstants";
import Footer from "../Components/Footer/Footer";
import {
  setLoginUserName,
  setLoginUserToken,
} from "../ReduxFeature/StateFeatures/B2CDataState";
// import {
//   installerResponse,
//   supervisorResponse,
//   superAdminResponse,
// } from "../Assets/Constants/temp";

const LoginLoaderPage = ({ setUserRole, globalState, setGlobalState }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const b2cDataObj = useSelector((state) => state.b2cDataInfo);

  function LoginApiCall() {
    const header = loginHeader({ azToken: b2cDataObj?.azToken });

    axios
      .post(
        ApiConstant.INSTALLER_PORAL_DOMAIN_NAME + "fibre/login",
        {
          username: b2cDataObj?.loginUserName,
        },
        { headers: header }
      )
      .then((response) => {
        const { data } = response;

        const responseData = {
          activeUserData: {
            userRole: data?.role?.split("_")[1],
            authToken: data?.authToken,
            userData: data?.userData,
          },
          loginUserData: {
            userRole: data?.role?.split("_")[1],
            authToken: data?.authToken,
            userData: data?.userData,
          },
        };

        dispatch(saveUserData(responseData));
        setUserRole(responseData?.activeUserData?.userRole);
        setGlobalState({ ...globalState, authLoaderPageState: false });

        navigate("/dashboard");
      })
      .catch(() => {
        dispatch(setLoginUserName(""));
        dispatch(setLoginUserToken(""));
        setUserRole("");
        setGlobalState({ ...globalState, authLoaderPageState: false });
        navigate("/");
      });
  }

  // function directLogin() {
  //   const data = supervisorResponse;
  //   const responseData = {
  //     activeUserData: {
  //       userRole: data?.role?.split("_")[1],
  //       authToken: data?.authToken,
  //       userData: data?.userData,
  //     },
  //     loginUserData: {
  //       userRole: data?.role?.split("_")[1],
  //       authToken: data?.authToken,
  //       userData: data?.userData,
  //     },
  //   };
  //   dispatch(saveUserData(responseData));
  //   setUserRole(responseData?.activeUserData?.userRole);
  //   setGlobalState({ ...globalState, authLoaderPageState: false });
  //   navigate("/dashboard");
  // }
  useEffect(() => {
    LoginApiCall();
    // directLogin();
  }, [globalState]);

  return (
    <div className="login-page-starter-section">
      <div className="col-12 p-0 wrapper-login-page-section">
        <div className="login-page-loader-section">
          <div className="login-loader-item-group-section">
            <div className="login-loader-item-section"></div>
            <div className="login-loader-item-section">Loading...</div>
          </div>
          <div className="login-loader-item-group-section">
            Please wait while we are fetching your Information
          </div>
        </div>
        <Footer loginPage={true} />
      </div>
      <div className="login-page-loader-note-section">
        {/* Note: <span> Do Not Refresh the page</span> */}
      </div>
    </div>
  );
};

export default LoginLoaderPage;
