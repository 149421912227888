import React from "react";
import { Constent } from "../../Assets/Constants/Constent";
import {
  CloseModalIcon,
  ConfirmationIcon,
} from "../../Assets/Images/CelcomSvgImages";
import { AcknowledgeStockTable, RequestPlaceOrderTable } from "../Common/Table";

const AcknowledgeStockConfirmationModal = ({
  acknowledgeStockApiCall,
  selectedTrackStockItemDetails,
  acknowledgeTotalAvailableItems,
  acknowledgeSelectedSerialNumbersList,
}) => {
  return (
    <div
      className="modal fade request-stock-place-order-modal acknowledge-stock-confirmation-modal"
      id="requestStockPlaceOrderModal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby="requestStockPlaceOrderModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pb-0">
            <div className="wrapper-modal-header pt-5">
              <div className="modal-header-title-section">
                <ConfirmationIcon />
                <h5
                  className="modal-title"
                  id="requestStockPlaceOrderModalLabel"
                >
                  {Constent.CONFIRMATION_MODAL_TITLE}
                </h5>
                <h5 className="modal-title">
                  {Constent.ACKNOWLEDGE_STOCK_MODAL_TITLE}
                </h5>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <CloseModalIcon />
                </span>
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="wrapper-modal-body">
              <AcknowledgeStockTable
                title1={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE1}
                title2={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE2}
                title3={
                  Constent.INVENTORY_CARD_ACKNOWLEDGEED_ITEM_DETAILS_TITLE1
                }
                title4={
                  Constent.INVENTORY_CARD_ACKNOWLEDGEED_ITEM_DETAILS_TITLE2
                }
                title5={
                  Constent.INVENTORY_CARD_ACKNOWLEDGEED_ITEM_DETAILS_TITLE3
                }
                title6={
                  Constent.INVENTORY_CARD_ACKNOWLEDGEED_ITEM_DETAILS_TITLE4
                }
                selectedTrackStockItemDetails={selectedTrackStockItemDetails}
                acknowledgeTotalAvailableItems={acknowledgeTotalAvailableItems}
                acknowledgeSelectedSerialNumbersList={
                  acknowledgeSelectedSerialNumbersList
                }
              />
            </div>
          </div>
          <div className="modal-footer">
            <div className="refresh-btn-section" data-dismiss="modal">
              <span>{Constent.CANCEL_BTN}</span>
            </div>
            <div
              className="search-btn-section"
              data-dismiss="modal"
              onClick={acknowledgeStockApiCall}
            >
              {Constent.CONFIRMATION_BTN}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcknowledgeStockConfirmationModal;
