import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Constent } from '../../Assets/Constants/Constent';
import { CloseModalIcon, FileSearchIcon, HomeIcon, LogoutIcon, ManageInstallerIcon, ManageInventoryIcon, ProfileIcon, RightArrowIcon, TrackOrderIcon } from '../../Assets/Images/CelcomSvgImages';
import { EmptyValueHandler } from '../Common/GenericFunctions';
import PersonalDetailsModal from '../Modals/PersonalDetailsModal';

const HeaderSideNavMenu = ({ globalState, setGlobalState, userDataObj, tab0, tab1, tab2, tab3, tab4, activeUserData }) => {
    const navigate = useNavigate();

    return (
        <>
            <div className={globalState?.sideMenuOpenState ?
                "side-menu-section side-menu-section-open"
                :
                "side-menu-section"}>
                <div className="side-menu-item-group-section">
                    <div className="side-menu-item-group">
                        <div className="wrapper-side-menu-group">
                            <div className="side-menu-inner-item-group-section">
                                <ProfileIcon />
                            </div>
                            <div className="side-menu-inner-item-group-section">
                                <div className="side-menu-inner-item">{EmptyValueHandler({ item: activeUserData?.userData?.fullName })}</div>
                                <div className="side-menu-inner-item">{EmptyValueHandler({ item: activeUserData?.userData?.userId })}</div>
                                <div className="side-menu-inner-item" data-toggle="modal" data-target="#personalDetailsModal"
                                    onClick={() => {
                                        setGlobalState({ ...globalState, sideMenuOpenState: !globalState?.sideMenuOpenState })
                                    }}>
                                    <span>{Constent.PROFILE_INFO_PERSONAL_DETAILS}</span>
                                    <RightArrowIcon stroke={"#009BDF"} />
                                </div>
                            </div>
                            <div className="side-menu-inner-item-group-section" onClick={() => {
                                setGlobalState({ ...globalState, sideMenuOpenState: !globalState?.sideMenuOpenState });
                            }}>
                                <CloseModalIcon />
                            </div>
                        </div>
                    </div>
                    <div className={tab0 ? "side-menu-item-group side-menu-item-group-active"
                        : "side-menu-item-group"} onClick={() => {
                            setGlobalState({ ...globalState, sideMenuOpenState: !globalState?.sideMenuOpenState });
                            navigate("/dashboard");
                        }}>
                        <div className="wrapper-side-menu-item-group">
                            <HomeIcon />
                            <span>{Constent.BODY_HEADER_DEFUALT_PATH}</span>
                        </div>
                        <RightArrowIcon stroke={"#999999"}/>
                    </div>
                    <div className={tab1 ? "side-menu-item-group side-menu-item-group-active" :
                        "side-menu-item-group "} onClick={() => {
                            setGlobalState({ ...globalState, sideMenuOpenState: !globalState?.sideMenuOpenState });
                            navigate("/workorder");
                        }}>
                        <div className="wrapper-side-menu-item-group">
                            <FileSearchIcon />
                            <span>{Constent.HEADER_MENU_ITEM1}</span>                            
                        </div>
                        <RightArrowIcon stroke={"#999999"}/>
                    </div>
                    {
                        (userDataObj?.userData?.activeUserData?.userRole === Constent.SUPER_ROLE)  &&
                        <div className={tab2 ? "side-menu-item-group  side-menu-item-group-active" :
                            "side-menu-item-group"} onClick={() => {
                                setGlobalState({ ...globalState, sideMenuOpenState: !globalState?.sideMenuOpenState });
                                navigate("/trackorder");
                            }}>
                            <div className="wrapper-side-menu-item-group">
                                <TrackOrderIcon />
                                <span>{Constent.HEADER_MENU_ITEM2}</span>
                            </div>
                            <RightArrowIcon stroke={"#999999"}/>
                        </div>
                    }

                    {
                        userDataObj?.userData?.activeUserData?.userRole === Constent.SUPER_ROLE &&
                        <div className={tab3 ? "side-menu-item-group side-menu-item-group-active" :
                            "side-menu-item-group"} onClick={() => {
                                setGlobalState({ ...globalState, sideMenuOpenState: !globalState?.sideMenuOpenState });
                                navigate("/manageinstaller");
                            }}>
                            <div className="wrapper-side-menu-item-group">
                                <ManageInstallerIcon />
                                <span>{Constent.HEADER_MENU_ITEM3}</span>
                            </div>
                            <RightArrowIcon stroke={"#999999"}/>
                        </div>
                    }
                    {
                        userDataObj?.userData?.activeUserData?.userRole !== Constent.INSTALLER_ROLE &&
                        <div className={tab4 ? "side-menu-item-group side-menu-item-group-active" :
                            "side-menu-item-group"} onClick={() => {
                                setGlobalState({ ...globalState, sideMenuOpenState: !globalState?.sideMenuOpenState });
                                navigate("/inventory");
                            }}>
                            <div className="wrapper-side-menu-item-group">
                                <ManageInventoryIcon />
                                <span>{Constent.HEADER_MENU_ITEM4}</span>
                            </div>
                            <RightArrowIcon stroke={"#999999"}/>
                        </div>

                    }
                    <div className="side-menu-item-group" data-toggle="modal" data-target="#logoutModal"
                        onClick={() => {
                            setGlobalState({ ...globalState, sideMenuOpenState: !globalState?.sideMenuOpenState });
                        }}>
                        <div className="wrapper-side-menu-item-group">
                            <LogoutIcon />
                            <span>{Constent.HEADER_MENU_ITEM56}</span>
                        </div>
                    </div>
                </div>
                {/* Overlay Section*/}
                <div className="side-menu-item-group-section" onClick={() => {
                    setGlobalState({ ...globalState, sideMenuOpenState: !globalState?.sideMenuOpenState });
                }}></div>
            </div>
            <PersonalDetailsModal activeUserData={activeUserData} />
        </>

    )
}

export default HeaderSideNavMenu;