import React from "react";
import { Constent } from "../../../../../Assets/Constants/Constent";
import {
  AddIcon,
  SearchIcon,
} from "../../../../../Assets/Images/CelcomSvgImages";
import { DownloadBtn } from "../../../../Common/Button";

import { useSelector } from "react-redux";
import {
  DonloadExcelFile,
  EmptyValueHandler,
} from "../../../../Common/GenericFunctions";
const AcknowledgeStockDetailsCardContent = ({
  pageState,
  setPageState,
  globalState,
  serialInput,
  setSerialInput,
}) => {
  const invDataObj = useSelector((state) => state.inventoryDataInfo);

  const {
    selectedTrackStockItemDetails,
    acknowledgeTotalAvailableItems,
    acknowledgeSelectedSerialNumbersList,
  } = invDataObj;

  let receivedCount = 0;
  Object.keys(acknowledgeSelectedSerialNumbersList)?.map((mcode) => {
    receivedCount += acknowledgeSelectedSerialNumbersList[mcode]?.length;
  });

  function downloadExcelFun() {
    //Sl, Material Code Item Name, Item Serial Number, Expiry Date, Status
    const downloadData = [];

    selectedTrackStockItemDetails?.itemDetails?.map((itemDetails, index) => {
      itemDetails?.serialNumbers?.map((serialNumberItem, itemIndex) => {
        downloadData.push({
          SL: index + 1 + itemIndex,
          "Material Code": EmptyValueHandler({
            item: itemDetails?.sapMaterialCode,
          }),
          "Item Name": EmptyValueHandler({
            item: itemDetails?.deviceModelDesc,
          }),
          "Item Serial Numbers": EmptyValueHandler({ item: serialNumberItem }),
        });
      });
    });
    const fileName =
      "Acknowledge-Stock_" + selectedTrackStockItemDetails?.transferId;

    /* Download Excel Function */
    DonloadExcelFile({
      fileName: fileName,
      downloadData: downloadData,
    });
  }

  return (
    <div
      className={`wrapper-acknowledge-stock-row-section ${
        globalState?.sidebarState ? "active-sidebar" : ""
      }`}
    >
      <div className="acknowledge-stock-col-item-section"></div>
      <div className="acknowledge-stock-col-item-section">
        {/* Search Sl & Unmatch Btn */}
        <div className="acknowledge-stock-col-item">
          <div className="acknowledge-stock-col-inner-item-section">
            <div className="form-group mb-0 custom-input-form-group">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  id="serialNumber"
                  placeholder=" "
                  value={serialInput}
                  onChange={(e) => setSerialInput(e.target.value)}
                />
                <label htmlFor="serialNumber">
                  {Constent.SERIAL_NUMBER_INPUT_ITEM_LABEL1}
                </label>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <SearchIcon stroke={"#CCCCCC"} />
                  </span>
                </div>
              </div>
              <span className="invalid-msg-text">{Constent.INVALID_INPUT}</span>
            </div>
          </div>
          <div className="acknowledge-stock-col-inner-item-section">
            <div className="unmatch-btn-section d-none">
              <AddIcon />
              <span>Unmatch</span>
            </div>
          </div>
        </div>
        {/* Each Section and Download */}
        <div className="acknowledge-stock-col-item">
          <div className="acknowledge-stock-col-inner-item-section">
            <div
              className={`acknowledge-stock-col-inner-item ${
                pageState?.activeTab ===
                Constent.INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE1
                  ? "active-tab"
                  : ""
              }`}
              onClick={() => {
                setPageState({
                  ...pageState,
                  activeTab:
                    Constent.INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE1,
                });
              }}
            >
              <div className="card-item-inner-group">
                <div className="card-item-title">
                  {Constent.INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE1}
                </div>
                <div className="card-item-value status-black">
                  {acknowledgeTotalAvailableItems || "-"}
                </div>
              </div>
            </div>
            <div
              className={`acknowledge-stock-col-inner-item ${
                pageState?.activeTab ===
                Constent.INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE2
                  ? "active-tab"
                  : ""
              }`}
              onClick={() => {
                setPageState({
                  ...pageState,
                  activeTab:
                    Constent.INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE2,
                });
              }}
            >
              <div className="card-item-inner-group">
                <div className="card-item-title">
                  {Constent.INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE2}
                </div>
                <div className="card-item-value status-green">
                  {receivedCount || "0"}
                </div>
              </div>
            </div>
            <div
              className={`acknowledge-stock-col-inner-item ${
                pageState?.activeTab ===
                Constent.INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE3
                  ? "active-tab"
                  : ""
              }`}
              onClick={() => {
                setPageState({
                  ...pageState,
                  activeTab:
                    Constent.INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE3,
                });
              }}
            >
              <div className="card-item-inner-group">
                <div className="card-item-title">
                  {Constent.INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE3}
                </div>
                <div className="card-item-value status-yellow">
                  {acknowledgeTotalAvailableItems - receivedCount || "0"}
                </div>
              </div>
            </div>
            <div
              className={`acknowledge-stock-col-inner-item ${
                pageState?.activeTab ===
                Constent.INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE4
                  ? "active-tab"
                  : ""
              }`}
              onClick={() => {
                setPageState({
                  ...pageState,
                  activeTab:
                    Constent.INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE4,
                });
              }}
            >
              <div className="card-item-inner-group">
                <div className="card-item-title">
                  {Constent.INVENTORY_CARD_ACKNOWLEDGE_STOCK_ITEM_TITLE4}
                </div>
                <div className="card-item-value status-red">{"-"}</div>
              </div>
            </div>
          </div>
          <div
            className="acknowledge-stock-col-inner-item-section"
            onClick={downloadExcelFun}
          >
            <DownloadBtn />
          </div>
        </div>
      </div>
      <div className="acknowledge-stock-col-item-section"></div>
    </div>
  );
};

export default AcknowledgeStockDetailsCardContent;
