import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApiConstant } from "../../../../../Assets/Constants/ApiConstants";
import { Constent } from "../../../../../Assets/Constants/Constent";
import { FileDocumentIcon } from "../../../../../Assets/Images/CelcomSvgImages";
import { WorkOrderDocumentContent } from "../WorkOrderDeatilsHelper/WorkOrderDocumentContent";
import { toast } from "react-toastify";
import { setUploadedDocumentsList } from "../../../../../ReduxFeature/StateFeatures/WorkOrderDataState";
import {
  headers,
  InstallerDownloadDocuments,
  InstallerUploadedDocuments,
} from "../../../../../Api/ApiCalls";
import {
  CardItemGroup,
  CardWrapperItemGroup,
} from "../../../../Common/CardContent";
import {
  AppoinmentTypeConverter,
  AppoinmentSubTypeConverter,
  EmptyValueHandler,
} from "../../../../Common/GenericFunctions";

const WorkOrderDetailsAccordion = ({
  userDataObj,
  workOrderDetailsObj,
  selectedWorkOrder,
  documentList,
  downloadBtnState,
}) => {
  const workOrderObj = useSelector((state) => state.workOrderInfo);
  const installerNotesObj = workOrderObj?.installerNotes?.orderNotesList;
  const addressDetails = workOrderDetailsObj?.addressDetails;
  const orderDetails = workOrderDetailsObj?.ownOrderDetails;
  const customerDetails = workOrderDetailsObj?.customerDetails;
  const relatedWorkOrderDetails = workOrderDetailsObj?.relatedWorkOrderDetails;
  const caseDetails = workOrderDetailsObj?.caseDetail;
  const uploadedDocumentList = workOrderObj?.uploadedDocumentsList;
  const dispatch = useDispatch();
  const [isDocumentsfetched, setisDocumentsfetched] = useState(false);
  const { rgwDeviceList, btuDeviceList, meshDeviceList } =
    workOrderDetailsObj?.ownOrderDetails?.deviceDetails === undefined ||
    workOrderDetailsObj?.ownOrderDetails?.deviceDetails === null
      ? { rgwDeviceList: [], btuDeviceList: [], meshDeviceList: [] }
      : workOrderDetailsObj?.ownOrderDetails?.deviceDetails;

  const totalDeviceList = [].concat(rgwDeviceList, meshDeviceList);
  const appoinmentType = AppoinmentTypeConverter({
    type: workOrderDetailsObj?.itemTypeID || "-",
  });

  const appoinmentSubType = AppoinmentSubTypeConverter({
    subType: workOrderDetailsObj?.itemSubTypeId || "-",
    type: workOrderDetailsObj?.itemTypeID || "-",
  });

  const rgwDevicesListWithSerialNumber =
    appoinmentType === "On-Site Installation" &&
    appoinmentSubType === "New Installation"
      ? rgwDeviceList?.filter(
          (rgwDevice) =>
            rgwDevice.deviceSerialNo !== null && rgwDevice.deviceSerialNo !== ""
        )
      : [];

  const meshDevicesListWithSerialNumber =
    appoinmentType === "On-Site Installation" &&
    appoinmentSubType === "New Installation"
      ? meshDeviceList?.filter(
          (meshDevice) =>
            meshDevice.deviceSerialNo !== null &&
            meshDevice.deviceSerialNo !== ""
        )
      : [];


  const installerNotesFiltered = installerNotesObj?.filter((filterItem) => {
    // filter out all null Notes
    if (filterItem !== "") {
      return filterItem;
    }
  });

  // useEffect(() => {
  //   const uploadedDocumentList = workOrderObj?.uploadedDocumentsList;
  //   if (uploadedDocumentList?.length <= 0) {
  //     toast.info(ApiConstant.NO_DOCUMENTS_UPLOADED, {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //       autoClose: 10000,
  //       theme: "colored",
  //     });
  //   }
  // }, []);
  function uploadedDocList() {
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);
    const uploadedDocumentsRequest = {
      orderId: selectedWorkOrder?.itemId,
    };

    InstallerUploadedDocuments(
      "/fibre/file/sftpFileList/",
      uploadedDocumentsRequest,
      header
    )
      .then((response) => {
        console.log(response);
        dispatch(
          setUploadedDocumentsList({
            type: ApiConstant.WORK_ORDER_UPLOADED_DOCUMENT_LIST,
            value: response,
          })
        );
      })
      .catch(() => {
        // TODO document why this arrow function is empty
        dispatch(
          setUploadedDocumentsList({
            type: ApiConstant.WORK_ORDER_UPLOADED_DOCUMENT_LIST,
            value: [],
          })
        );
      });
  }
  return (
    <div
      className="accordion accordion-borderless mb-1 custom-accordion-section"
      id="workOrderDetails"
    >
      {/* Card - 1 */}
      <div className="card">
        <div className="card-header" id="workOrderHeadingOne">
          <button
            className="btn btn-link btn-block text-dark text-left custom-accordion-btn-link"
            type="button"
            data-toggle="collapse"
            data-target="#workOrderCollapseOne"
            aria-expanded="false"
            aria-controls="workOrderCollapseOne"
          >
            {Constent.WORK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE1}
          </button>
        </div>
        <div
          id="workOrderCollapseOne"
          className={`collapse show ${downloadBtnState ? "show" : ""}`}
          aria-labelledby="workOrderHeadingOne"
          data-parent="#workOrderCollapseOne"
        >
          <div className="card-body">
            <CardWrapperItemGroup
              titleCol1={Constent.WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE1}
              titleCol2={Constent.WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE16}
              titleCol3={Constent.WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE6}
              valueCol1={AppoinmentTypeConverter({
                type: workOrderDetailsObj?.itemTypeID || "-",
              })}
              valueCol2={AppoinmentSubTypeConverter({
                subType: workOrderDetailsObj?.itemSubTypeId || "-",
                type: workOrderDetailsObj?.itemTypeID || "-",
              })}
              valueCol3={EmptyValueHandler({
                item: addressDetails?.unitNumber,
              })}
            />
            <CardWrapperItemGroup
              // titleCol1={Constent.WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE2}
              titleCol1={Constent.WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE7}
              titleCol2={Constent.WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE12}
              titleCol3={Constent.WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE4}
              // valueCol1={workOrderDetailsObj?.subscriberId || "-"}
              valueCol1={addressDetails?.stretName || "-"}
              valueCol2={addressDetails?.addressCity || "-"}
              valueCol3={addressDetails?.buildingName || "-"}
            />

            <CardWrapperItemGroup
              titleCol1={Constent.WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE3}
              titleCol2={Constent.WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE8}
              titleCol3={Constent.WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE13}
              valueCol1={customerDetails?.contactInfo?.addressType || "-"}
              valueCol2={addressDetails?.pinCode || "-"}
              valueCol3={addressDetails?.stateOrProvince || "-"}
            />

            <div className="wrapper-card-body-section">
              <div className="card-body-col-section">
                <CardItemGroup
                  title={Constent.WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE17}
                  // titleCol2={Constent.WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE9}
                  // titleCol3={workOrderDetailsObj?.installationDetail?.returnRemarks?.length > 0 ? Constent.WORK_ORDER_DETAILS_CARD_SUB_ITEM_TITLE18: ""}
                  value={workOrderDetailsObj?.accessPartner || "-"}
                  // valueCol2={workOrderDetailsObj?.resolutionDate?.split("T")[0]}
                  // valueCol3={workOrderDetailsObj?.installationDetail?.returnRemarks?.length > 0 ? workOrderDetailsObj?.installationDetail?.returnRemarks: ""}
                />
              </div>
              <div className="card-body-col-section">
                {
                  <div className="card-item-group">
                    <div className="card-item-title">
                      {Constent.ORDER_DETAILS_CARD_SUB_ITEM_TITLE8}
                    </div>
                    <div className="card-item-value">
                      <div className="device-item-group-section">
                        <div className="device-item-group">
                          RGW -{" "}
                          {rgwDeviceList?.length > 0
                             ? rgwDeviceList?.length -
                             rgwDevicesListWithSerialNumber?.length
                            : "0"}
                        </div>
                        <div className="device-item-group">
                          MESH -{" "}
                          {meshDeviceList?.length > 0
                            ? meshDeviceList?.length -
                            meshDevicesListWithSerialNumber?.length
                            : "0"}
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="card-body-col-section"></div>
            </div>

            <div className="wrapper-card-body-section">
              <div className="card-body-col-section">
                {
                  <div className="card-item-group">
                    <div className="card-item-title">
                      {Constent.ORDER_DETAILS_CARD_SUB_ITEM_TITLE12}
                    </div>
                    <div className="card-item-value">
                      <div className="device-item-group-section">
                        <div>
                          {installerNotesObj != null ? (
                            installerNotesFiltered?.map((data, index) => {
                              if (index + 1 == installerNotesFiltered.length) {
                                // only display the latest Notes
                                return (
                                  <div
                                    className="device-item-group"
                                    key={data + index}
                                  >
                                    {data}
                                  </div>
                                );
                              }
                            })
                          ) : (
                            <div className="device-item-group">-</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="card-body-col-section"></div>
              <div className="card-body-col-section"></div>
            </div>
          </div>
        </div>
      </div>
      {/* Card - 2 */}
      <div className="card">
        <div className="card-header" id="workOrderHeadingTwo">
          <button
            className={
              "btn btn-link btn-block text-dark text-left custom-accordion-btn-link collapsed"
            }
            type="button"
            data-toggle="collapse"
            data-target="#workOrderCollapseTwo"
            aria-expanded="false"
            aria-controls="workOrderCollapseTwo"
          >
            {Constent.WORK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE2}
          </button>
        </div>
        <div
          id="workOrderCollapseTwo"
          className={`collapse ${downloadBtnState ? "show" : ""}`}
          aria-labelledby="workOrderHeadingTwo"
          data-parent="#workOrderCollapseTwo"
        >
          <div className="card-body">
            <CardWrapperItemGroup
              titleCol1={Constent.CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE1}
              titleCol2={Constent.CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE2}
              titleCol3={Constent.CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE5}
              // titleCol3={Constent.CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE3}
              valueCol1={customerDetails?.customerId || "-"}
              valueCol2={customerDetails?.customerStatus || "-"}
              valueCol3={customerDetails?.customerSegment || "-"}
              // valueCol3={customerDetails?.customerType || "-"}
            />
            <CardWrapperItemGroup
              // titleCol2={Constent.CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE6}
              titleCol1={Constent.CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE7}
              titleCol2={Constent.CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE8}
              titleCol3={Constent.CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE11}
              // titleCol3={Constent.CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE12}
              // valueCol2={customerDetails?.customerCategory || "-"}
              valueCol1={customerDetails?.customerFirstName || "-"}
              valueCol2={customerDetails?.customerLastName || "-"}
              valueCol3={
                customerDetails?.contactInfo?.customertelephoneNumber || "-"
              }
              // valueCol3={customerDetails?.contactInfo?.city || "-"}
            />
            <CardWrapperItemGroup
            // titleCol2={Constent.CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE9}
            // titleCol3={Constent.CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE10}

            // valueCol2={customerDetails?.customerGender || "-"}
            // valueCol3={customerDetails?.contactInfo?.customerPrimaryEmail || "-"}
            />
            <CardWrapperItemGroup
            // titleCol1={Constent.CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE16}
            // titleCol2={Constent.CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE17}
            // titleCol3={Constent.CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE18}
            // valueCol1={customerDetails?.contactInfo?.town || "-"}
            // valueCol2={customerDetails?.contactInfo?.buildingType || "-"}
            // valueCol3={customerDetails?.contactInfo?.pinCode || "-"}
            />
            <CardWrapperItemGroup
              titleCol1={Constent.CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE13}
              titleCol2={Constent.CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE14}
              titleCol3={Constent.CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE19}
              valueCol1={customerDetails?.customerIDType || "-"}
              valueCol2={customerDetails?.customerIdentification || "-"}
              valueCol3={
                customerDetails?.contactInfo
                  ?.customerAlternatetelephoneNumber || "-"
              }
            />
            <div className="wrapper-card-body-section">
              <div className="card-body-col-section">
                <CardItemGroup />
              </div>
              <div className="card-body-col-section">
                {(userDataObj?.userData?.activeUserData?.userRole ===
                  Constent.ADMIN_ROLE ||
                  userDataObj?.userData?.activeUserData?.userRole ===
                    Constent.SUPER_ROLE) &&
                  documentList?.length > 0 && (
                    <div className="card-item-group document-list-item-group">
                      <div className="card-item-title">
                        {Constent.CUSTOMER_DETAILS_CARD_SUB_ITEM_TITLE15}
                      </div>
                      <div
                        className="card-item-value d-flex"
                        data-toggle="modal"
                        data-target="#workOrderDocumentListModal"
                      >
                        <span>{`${documentList?.length} - Items`}</span>
                        <div className="card-item-value-dot-section ml-2">
                          <FileDocumentIcon />
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              <div className="card-body-col-section"></div>
            </div>
          </div>
        </div>
      </div>
      {/* Card - 3 */}
      {workOrderDetailsObj?.itemTypeID === ApiConstant.WOKR_ORDER_OST_TYPE ? (
        <div className="card">
          <div className="card-header" id="workOrderHeadingThree">
            <button
              className="btn btn-link btn-block text-dark text-left custom-accordion-btn-link collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#workOrderCollapseThree"
              aria-expanded="false"
              aria-controls="workOrderCollapseThree"
            >
              {Constent.WORK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE4}
            </button>
          </div>
          <div
            id="workOrderCollapseThree"
            className={`collapse ${downloadBtnState ? "show" : ""}`}
            aria-labelledby="workOrderHeadingThree"
            data-parent="#workOrderCollapseThree"
          >
            <div className="card-body">
              <CardWrapperItemGroup
                titleCol1={Constent.CASE_DETAILS_CARD_SUB_ITEM_TITLE1}
                titleCol2={Constent.CASE_DETAILS_CARD_SUB_ITEM_TITLE8}
                // titleCol2={Constent.CASE_DETAILS_CARD_SUB_ITEM_TITLE2}
                titleCol3={Constent.CASE_DETAILS_CARD_SUB_ITEM_TITLE3}
                valueCol1={caseDetails?.caseNO || "-"}
                valueCol2={caseDetails?.serviceNumber || "-"}
                // valueCol2={caseDetails?.outletID || "-"}
                valueCol3={caseDetails?.category || "-"}
              />
              <CardWrapperItemGroup
                titleCol1={Constent.CASE_DETAILS_CARD_SUB_ITEM_TITLE10}
                titleCol2={Constent.CASE_DETAILS_CARD_SUB_ITEM_TITLE9}
                titleCol3={Constent.CASE_DETAILS_CARD_SUB_ITEM_TITLE6}
                // || Constent.CASE_DETAILS_CARD_SUB_ITEM_TITLE4
                // titleCol2={Constent.CASE_DETAILS_CARD_SUB_ITEM_TITLE5}
                valueCol1={caseDetails?.caseAging || "-"}
                valueCol2={caseDetails?.created || "-"}
                valueCol3={caseDetails?.status || "-"}
              />
              {/* valueCol1={caseDetails?.type || "-"} */}
              {/* valueCol2={caseDetails?.owner || "-"} */}

              <CardWrapperItemGroup
                // titleCol1={Constent.CASE_DETAILS_CARD_SUB_ITEM_TITLE7}
                titleCol1={Constent.ORDER_DETAILS_CARD_SUB_ITEM_TITLE10}
                titleCol2={Constent.ORDER_DETAILS_CARD_SUB_ITEM_TITLE11}
                // valueCol1={caseDetails?.source || "-"}
                valueCol1={workOrderDetailsObj?.hsiUsername || "-"}
                valueCol2={workOrderDetailsObj?.hsiPassword || "-"}
              />
              <CardWrapperItemGroup
                // titleCol2={Constent.CASE_DETAILS_CARD_SUB_ITEM_TITLE11}
                titleCol1={Constent.CASE_DETAILS_CARD_SUB_ITEM_TITLE12}
                // valueCol2={caseDetails?.creatorFullName || "-"}
                valueCol1={caseDetails?.customerIDType || "-"}
              />

              <div className="wrapper-card-body-section">
                {relatedWorkOrderDetails?.count > 0 && (
                  <div className="card-body-col-section">
                    {(userDataObj?.userData?.activeUserData?.userRole ===
                      Constent.ADMIN_ROLE ||
                      userDataObj?.userData?.activeUserData?.userRole ===
                        Constent.SUPER_ROLE) && (
                      <div className="card-item-group">
                        <div className="card-item-title">
                          {Constent.ORDER_DETAILS_CARD_SUB_ITEM_TITLE9}
                        </div>
                        <div
                          className="card-item-value"
                          data-toggle="modal"
                          data-target="#workOrderReturnModal"
                        >
                          <span>{`${
                            relatedWorkOrderDetails?.count || 0
                          } - Times`}</span>
                          <div className="card-item-value-dot-section">
                            <div className="card-item-value-dot"></div>
                            <div className="card-item-value-dot"></div>
                            <div className="card-item-value-dot"></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card">
          <div className="card-header" id="workOrderHeadingThree">
            <button
              className="btn btn-link btn-block text-dark text-left custom-accordion-btn-link collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#workOrderCollapseThree"
              aria-expanded="false"
              aria-controls="workOrderCollapseThree"
            >
              {Constent.WORK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE3}
            </button>
          </div>
          <div
            id="workOrderCollapseThree"
            className={`collapse ${downloadBtnState ? "show" : ""}`}
            aria-labelledby="workOrderHeadingThree"
            data-parent="#workOrderCollapseThree"
          >
            <div className="card-body">
              <CardWrapperItemGroup
                titleCol1={Constent.ORDER_DETAILS_CARD_SUB_ITEM_TITLE1}
                titleCol2={Constent.ORDER_DETAILS_CARD_SUB_ITEM_TITLE5}
                // titleCol2={Constent.ORDER_DETAILS_CARD_SUB_ITEM_TITLE2}
                titleCol3={Constent.ORDER_DETAILS_CARD_SUB_ITEM_TITLE3}
                valueCol1={orderDetails?.orderID || "-"}
                valueCol2={orderDetails?.planName || "-"}
                // valueCol2={orderDetails?.orderType || "-"}
                valueCol3={orderDetails?.orderStatus?.replace("_", " ") || "-"}
              />
              <CardWrapperItemGroup
                // titleCol1={Constent.ORDER_DETAILS_CARD_SUB_ITEM_TITLE4}
                titleCol1={Constent.ORDER_DETAILS_CARD_SUB_ITEM_TITLE10}
                titleCol2={Constent.ORDER_DETAILS_CARD_SUB_ITEM_TITLE11}
                titleCol3={Constent.ORDER_DETAILS_CARD_SUB_ITEM_TITLE7}
                // titleCol3={Constent.ORDER_DETAILS_CARD_SUB_ITEM_TITLE12}
                // valueCol1={orderDetails?.customerRequestedDate?.split("T")[0] || "-"}
                valueCol1={workOrderDetailsObj?.hsiUsername || "-"}
                valueCol2={workOrderDetailsObj?.hsiPassword || "-"}
                valueCol3={orderDetails?.customerAccountID || "-"}
                // valueCol3={installerNotesObj?.toString().replaceAll(",", ", ") || "-"}
              />
              <CardWrapperItemGroup
                titleCol1={Constent.ORDER_DETAILS_CARD_SUB_ITEM_TITLE13}
                titleCol2={Constent.ORDER_DETAILS_CARD_SUB_ITEM_TITLE14}
                valueCol1={orderDetails?.accessPartnerOrderId || "-"}
                valueCol2={orderDetails?.accessPartnerBuID || "-"}
              />
              <div className="wrapper-card-body-section">
                {relatedWorkOrderDetails?.count > 0 ? (
                  <div className="card-body-col-section show-more-details-modal">
                    {
                      // ((userDataObj?.userData?.activeUserData?.userRole === Constent.ADMIN_ROLE)
                      //     || (userDataObj?.userData?.activeUserData?.userRole === Constent.SUPER_ROLE)) && (

                      // )
                      <div className="card-item-group">
                        <div className="card-item-title">
                          {Constent.ORDER_DETAILS_CARD_SUB_ITEM_TITLE9}
                        </div>
                        <div
                          className="card-item-value"
                          data-toggle="modal"
                          data-target="#workOrderReturnModal"
                        >
                          <span>{`${relatedWorkOrderDetails?.count} - Times`}</span>
                          <div className="card-item-value-dot-section">
                            <div className="card-item-value-dot"></div>
                            <div className="card-item-value-dot"></div>
                            <div className="card-item-value-dot"></div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                ) : (
                  <div className="card-body-col-section"></div>
                )}
                <div className="card-body-col-section"></div>
                <div className="card-body-col-section"></div>
              </div>

              {/* <div className="wrapper-card-body-section">
                                    <div className="card-body-col-section">
                                        <CardItemGroup 
                                            title={Constent.ORDER_DETAILS_CARD_SUB_ITEM_TITLE7}
                                            value={orderDetails?.customerAccountID || "-"} />
                                    </div>

                                    <div className="card-body-col-section">
                                    </div>
                                </div> */}
            </div>
          </div>
        </div>
      )}
      {/* Card - 4 CR-4012 Changes - To display device details in workorder page */}
      <div className="card">
        <div className="card-header" id="workOrderHeadingSix">
          <button
            className="btn btn-link btn-block text-dark text-left custom-accordion-btn-link collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#workOrderCollapseSix"
            aria-expanded="false"
            aria-controls="workOrderCollapseSix"
          >
            {Constent.WORK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE6}
          </button>
        </div>
        <div
          id="workOrderCollapseSix"
          className={`collapse ${downloadBtnState ? "show" : ""}`}
          aria-labelledby="workOrderHeadingSix"
          data-parent="#workOrderCollapseSix"
        >
          <div className="card-body">
            {/* RGW */}
            {rgwDeviceList?.map((deviceData, index) => {
              return (
                <div
                  className="work-order-device-detail"
                  key={`workorderdevicedetail${index}`}
                >
                  {`${
                    deviceData?.nameToDisplay &&
                    deviceData?.nameToDisplay != null
                      ? deviceData?.nameToDisplay?.replaceAll("_", " ")
                      : ""
                  }  ${
                    deviceData?.deviceBrand && deviceData?.deviceBrand != null
                      ? "  :  " + deviceData?.deviceBrand?.replaceAll("_", " ")
                      : ""
                  }  ${
                    deviceData?.deviceModel && deviceData?.deviceModel != null
                      ? "  :  " + deviceData?.deviceModel?.replaceAll("_", " ")
                      : ""
                  }
                  ${
                    deviceData?.deviceSerialNo &&
                    deviceData?.deviceSerialNo != null
                      ? "  :  " +
                        deviceData?.deviceSerialNo?.replaceAll("_", " ")
                      : ""
                  }`}
                </div>
              );
            })}
            {/* BTU */}
            {btuDeviceList?.map((deviceData, index) => {
              return (
                <div
                  className="work-order-device-detail"
                  key={`workorderdevicedetail${index}`}
                >
                  {`${
                    deviceData?.name && deviceData?.name != null
                      ? deviceData?.name?.replaceAll("_", " ")
                      : ""
                  }  ${
                    deviceData?.deviceBrand && deviceData?.deviceBrand != null
                      ? "  :  " + deviceData?.deviceBrand?.replaceAll("_", " ")
                      : ""
                  }  ${
                    deviceData?.deviceModel && deviceData?.deviceModel != null
                      ? "  :  " + deviceData?.deviceModel?.replaceAll("_", " ")
                      : ""
                  }
                  ${
                    deviceData?.deviceSerialNo &&
                    deviceData?.deviceSerialNo != null
                      ? "  :  " +
                        deviceData?.deviceSerialNo?.replaceAll("_", " ")
                      : ""
                  }`}
                </div>
              );
            })}
            {/* Mesh */}
            {meshDeviceList?.map((deviceData, index) => {
              return (
                <div
                  className="work-order-device-detail"
                  key={`workorderdevicedetail${index}`}
                >
                  {`${
                    deviceData?.nameToDisplay &&
                    deviceData?.nameToDisplay != null
                      ? deviceData?.nameToDisplay?.replaceAll("_", " ")
                      : ""
                  }  ${
                    deviceData?.deviceBrand && deviceData?.deviceBrand != null
                      ? "  :  " + deviceData?.deviceBrand?.replaceAll("_", " ")
                      : ""
                  }  ${
                    deviceData?.deviceModel && deviceData?.deviceModel != null
                      ? "  :  " + deviceData?.deviceModel?.replaceAll("_", " ")
                      : ""
                  }
                  ${
                    deviceData?.deviceSerialNo &&
                    deviceData?.deviceSerialNo != null
                      ? "  :  " +
                        deviceData?.deviceSerialNo?.replaceAll("_", " ")
                      : ""
                  }`}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* Card - 5 */}
      <div className="card">
        <div className="card-header" id="workOrderHeadingFour">
          <button
            className={
              "btn btn-link btn-block text-dark text-left custom-accordion-btn-link collapsed"
            }
            type="button"
            data-toggle="collapse"
            data-target="#workOrderCollapseFour"
            aria-expanded="false"
            aria-controls="workOrderCollapseFour"
            onClick={() => {
              if (!isDocumentsfetched) {
                uploadedDocList();
                setisDocumentsfetched(true);
              }
            }}
          >
            {Constent.WORK_ORDER_DETAILS_CONTENT_ACCORDION_TITLE5}
          </button>
        </div>
        <div
          id="workOrderCollapseFour"
          className={`collapse ${downloadBtnState ? "show" : ""}`}
          aria-labelledby="workOrderCollapseFour"
          data-parent="#workOrderCollapseFour"
        >
          {uploadedDocumentList?.length > 0 ? (
            uploadedDocumentList?.map((documentItem, index) => {
              return (
                <WorkOrderDocumentContent
                  documentItem={documentItem}
                  index={index}
                  userDataObj={userDataObj}
                  selectedWorkOrder={selectedWorkOrder}
                />
              );
            })
          ) : (
            <div className="work-order-device-detail">
              There are no Files available.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkOrderDetailsAccordion;
