import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { Constent } from "../../Assets/Constants/Constent";

const initialState = {
  workOrderId: "",
  searchType: Constent.WORK_ORDER_INPUT_ITEM_LABEL1,
  formCalendarNav: false,
  workOrderFromDate: "",
  workOrderToDate: "",
  workOrderAppointmentType: "",
  calendarViewData: [],
  workOrderList: [],
  selectedWorkOrderItem: null,
  workOrderDetails: null,

  installerNotes: [],
  documentList: [],
  uploadedDocumentsList: null,

  updateActivityBtn: true,
  locationBtnEnabled: false,
  updateWorkOrderStatus: "",
  locationCoordinates: "",
  locationAddress: "",
  locationCaptureTime: "",

  workOrderStatus: "",
  returnReasonValue: "",
  returnRemarksValue: "",
  returnRemarksFinalValue: "",

  ostElementValue: "",
  ostResolutionValue: "",
  ostProblemCauseValue: "",
  ostOrderId: "",

  ostResolutionLovs: [],
  ostProblemCauseLovs: [],

  installationCompleteTime: "",

  btuId: "",
  btuPortNumber: "",
  btuDeviceCollected: "",

  uploadedDocuments: null,

  activeTab: 1,

  isModelChangeForAnySwapDevices: false,

  deviceSLValidation: null,
  devicesWithExistingSerialNo: [],

  infrastructureLovValue: "",
  desiredinstallationLovValue: "",
  additionalCableLength: "",

  saveAndContinueBtnEnabled: false,
  submitBtnEnabled: false,

  ostChekedDeviceType: "",

  workOrderClosingTime: "",
  summaryDate: moment(),
  summaryData: {
    installation: {
      progress: [0, 0.0, []],
      done: [0, 0.0, []],
      returned: [0, 0.0, []],
      aging: [0, 0.0, []],
    },
    troubleshooting: {
      progress: [0, 0.0, []],
      done: [0, 0.0, []],
      returned: [0, 0.0, []],
      aging: [0, 0.0, []],
    },
    btu_collection: {
      progress: [0, 0.0, []],
      done: [0, 0.0, []],
      returned: [0, 0.0, []],
      aging: [0, 0.0, []],
    },
  },
  summarySctivePill: Constent.SUPERVISOR_SIDEBAR_OSI_VALUE,
  summaryActivePillStatus: Constent.SUPERVISOR_SIDEBAR_SUBTYPE1,

  calendarDate: moment(),
};

export const WorkOrderDataState = createSlice({
  name: "workOrderDataState",
  initialState,
  reducers: {
    // Creating the Actions
    /* ------ Dashbord Calendar ------- */
    workOrderSearchPageState: (state, action) => {
      const { type, value } = action.payload;
      switch (type) {
        case ApiConstant.CALENDAR_TO_WORK_ORDER_NAV:
          return { ...state, formCalendarNav: value };
        case ApiConstant.WORK_ORDER_ID:
          return { ...state, workOrderId: value };
        case ApiConstant.WORK_ORDER_FROM_DATE:
          return { ...state, workOrderFromDate: value };
        case ApiConstant.WORK_ORDER_TO_DATE:
          return { ...state, workOrderToDate: value };
        case ApiConstant.WORK_ORDER_RESET_FIELD:
          return {
            ...state,
            workOrderId: "",
            workOrderFromDate: "",
            workOrderToDate: "",
          };
        case ApiConstant.INSTALLER_WORK_ORDER_CALENDAR_DATE:
          return { ...state, calendarDate: value };
        case Constent.WORK_ORDER_REQUEST_TYPE1:
          return { ...state, searchType: value };
        default:
          return { ...state };
      }
    },
    /* ------ Work Order List ------- */
    setSearchWorkOrderListData: (state, action) => {
      return { ...state, workOrderList: action.payload };
    },
    /* ------ Installer Notes List ------- */
    setInstallerNotesData: (state, action) => {
      return { ...state, installerNotes: action.payload };
    },
    /* ------ Uploaded Documents List ------- */
    setUploadedDocumentsList: (state, action) => {
      const { type, value } = action.payload;
      switch (type) {
        case ApiConstant.WORK_ORDER_UPLOADED_DOCUMENT_LIST: {
          return { ...state, uploadedDocumentsList: value };
        }
        default:
          return { ...state };
      }
    },
    /* ------ Work Order Details ------- */
    selectedWorkOrderItem: (state, action) => {
      return { ...state, selectedWorkOrderItem: action.payload };
    },
    setSearchWorkOrderDetailsData: (state, action) => {
      return { ...state, workOrderDetails: action.payload };
    },
    setDocumentManagementData: (state, action) => {
      const { type, value } = action.payload;
      switch (type) {
        case ApiConstant.WORK_ORDER_DOCUMENT_LIST: {
          return { ...state, documentList: value };
        }
        default:
          return { ...state };
      }
    },
    /* ------ Update Work Order Details ------- */
    updateWorkOrderDetailsData: (state, action) => {
      const { type, value } = action.payload;
      switch (type) {
        case "isUpload": {
          /* Onchange Function */
          return { ...state, isUpload: value };
        }
        case ApiConstant.INSTALLER_WORK_ORDER_UPDATE_ACTIVE:
          return { ...state, updateActivityBtn: value };
        case ApiConstant.INSTALLER_SL_VALIDATION:
          return { ...state, deviceSLValidation: value };
        case ApiConstant.INSTALLER_SL_EXISTING_SERIAL_NUMBER:
          return { ...state, devicesWithExistingSerialNo: value };
        case ApiConstant.INSTALLER_CLOSE_WORK_ORDER_TYPE:
          return { ...state, workOrderClosingTime: value };
        case ApiConstant.INSTALLER_SL_VALIDATION_SERIAL_NUMBER: {
          /* Onchange Function */
          const { productId, itemValue } = value;
          state.deviceSLValidation[productId] = {
            ...state.deviceSLValidation[productId],
            serialnumber: itemValue,
            valid: "",
          };
          break;
        }
        case ApiConstant.INSTALLER_BTU_ID_INPUTVALUE: {
          /* Onchange Function */
          return { ...state, btuId: value };
        }
        case ApiConstant.INSTALLER_BTU_PORT_INPUTVALUE: {
          /* Onchange Function */
          return { ...state, btuPortNumber: value };
        }
        case ApiConstant.INSTALLER_BTU_DEVICE_COLLECTED_INPUTVALUE: {
          /* Onchange Function */
          return { ...state, btuDeviceCollected: value };
        }
        case ApiConstant.INSTALLER_SL_VALIDATION_VALID: {
          /* On Blur Function */
          const { productId, itemValue } = value;
          state.deviceSLValidation[productId] = {
            ...state.deviceSLValidation[productId],
            valid: itemValue,
          };
          break;
        }

        case ApiConstant.INSTALLER_SL_VALIDATION_DEVICE_SWAP_MODEL_DETAILS: {
          /* On Blur Function */
          const {
            productId,
            isSwapDeviceWithModelChange,
            newDeviceBrand,
            newDeviceModel,
            newMaterialCode,
          } = value;
          state.deviceSLValidation[productId] = {
            ...state.deviceSLValidation[productId],
            isSwapDeviceWithModelChange: isSwapDeviceWithModelChange,
            newDeviceBrand: newDeviceBrand,
            newDeviceModel: newDeviceModel,
            newMaterialCode: newMaterialCode,
          };
          break;
        }

        case ApiConstant.INSTALLER_WORK_ORDER_MODEL_CHANGE_IN_SWAP_DEVICES: {
          /* On Blur Function */
          return { ...state, isModelChangeForAnySwapDevices: value };
        }

        case ApiConstant.INSTALLER_SL_INNER_DROP_DOWN_ELEMENT_VALUE: {
          /* On Blur Function */
          const { productId, itemValue } = value;
          state.deviceSLValidation[productId] = {
            ...state.deviceSLValidation[productId],
            elementValue: itemValue,
            resolutionValue: "",
            problemCauseValue: "",
          };
          break;
        }
        case ApiConstant.INSTALLER_SL_INNER_DROP_DOWN_REASON_VALUE: {
          /* On Blur Function */
          const { productId, itemValue } = value;
          state.deviceSLValidation[productId] = {
            ...state.deviceSLValidation[productId],
            resolutionValue: itemValue,
            problemCauseValue: "",
          };
          break;
        }
        case ApiConstant.INSTALLER_SL_INNER_DROP_DOWN_PROBLEM_CAUSE_VALUE: {
          /* On Blur Function */
          const { productId, itemValue } = value;
          state.deviceSLValidation[productId] = {
            ...state.deviceSLValidation[productId],
            problemCauseValue: itemValue,
          };
          break;
        }
        case ApiConstant.INSTALLER_WORK_ORDER_STATUS:
          return {
            ...state,
            workOrderStatus: value,
            returnReasonValue: "",
            returnRemarksValue: "",
            returnRemarksFinalValue: "",
          };
        case ApiConstant.LOV_STORE_UPDATE_WORK_ORDER_RETURN_REASON_KEY:
          return {
            ...state,
            returnReasonValue: value,
            returnRemarksValue: "",
            returnRemarksFinalValue: "",
          };
        case ApiConstant.LOV_STORE_UPDATE_WORK_ORDER_RETURN_REMARKS_KEY:
          return {
            ...state,
            returnRemarksValue: value,
            returnRemarksFinalValue:
              value === Constent.UPDATE_WORK_ORDER_OTHER_REMARS_LABEL
                ? ""
                : value,
          };
        case ApiConstant.LOV_STORE_UPDATE_WORK_ORDER_RETURN_REMARKS_INPUT_KEY:
          return {
            ...state,
            returnRemarksFinalValue: value,
          };
        case ApiConstant.LOV_STORE_UPDATE_WORK_ORDER_INFRASTRUCTURE_KEY:
          return { ...state, infrastructureLovValue: value };
        case ApiConstant.LOV_STORE_UPDATE_WORK_ORDER_DISIRED_INSTALLATION_KEY:
          return { ...state, desiredinstallationLovValue: value };
        case ApiConstant.UPDATE_WORK_ORDER_SAVE_AND_CONTINUE_BTN_VALUE:
          return { ...state, saveAndContinueBtnEnabled: value };
        case ApiConstant.UPDATE_WORK_ORDER_SUBMIT_BTN_VALUE:
          return { ...state, submitBtnEnabled: value };
        case ApiConstant.INSTALLER_ADDITIONAL_CABLE_LENGTH_INPUTVALUE:
          return { ...state, additionalCableLength: value };
        case ApiConstant.INSTALLER_WORK_ORDER_ACTIVE_TAB:
          return { ...state, activeTab: value };
        case ApiConstant.INSTALLER_ENABLED_LOCATION_BTN:
          const { lattitude, longitude, streetName, time } = value;
          return {
            ...state,
            locationBtnEnabled: true,
            locationCoordinates: lattitude + " " + longitude,
            locationAddress: streetName,
            locationCaptureTime: time,
          };
        case ApiConstant.INSTALLER_COMPLETE_TIME:
          return { ...state, installationCompleteTime: value };
        case ApiConstant.UPDATE_WORK_ORDER_STATUS_VALUE:
          return { ...state, updateWorkOrderStatus: value };
        case ApiConstant.UPDATE_WORK_ORDER_UPLOAD_DOCUMENT:
          return { ...state };
        case ApiConstant.INSTALLER_WORK_ORDER_OST_ELEMENT_LOV_VALUE: {
          const { lovValue, data } = value;
          return {
            ...state,
            ostElementValue: lovValue,
            ostResolutionLovs: data,
            ostProblemCauseLovs: [],
            ostResolutionValue: "",
            ostProblemCauseValue: "",
          };
        }
        case ApiConstant.INSTALLER_WORK_ORDER_OST_CHECKED_DEVICE_TYPE: {
          return { ...state, ostChekedDeviceType: value };
        }
        case ApiConstant.INSTALLER_WORK_ORDER_OST_RESOLUTION_LOV_VALUE: {
          const { lovValue, data } = value;
          return {
            ...state,
            ostResolutionValue: lovValue,
            ostProblemCauseLovs: data,
            ostProblemCauseValue: "",
          };
        }
        case ApiConstant.INSTALLER_WORK_ORDER_OST_PROBLEM_CASE_LOV_VALUE:
          return {
            ...state,
            ostProblemCauseValue: value,
          };
        case ApiConstant.INSTALLER_WORK_ORDER_OST_ORDER_ID:
          return {
            ...state,
            ostOrderId: value,
          };

        default:
          return { ...state };
      }
    },

    /* ------ Summary Data ------- */
    saveSummaryData: (state, action) => {
      const { type, value } = action.payload;
      switch (type) {
        case ApiConstant.SAVE_SUMMARY_DATA:
          return { ...state, summaryData: value };
        case ApiConstant.SAVE_SUMMARY_DATE:
          return { ...state, summaryDate: value };
        case ApiConstant.SUMMARY_PILL_ITEM:
          return { ...state, summarySctivePill: value };
        case ApiConstant.SUMMARY_PILL_STATUS_ITEM:
          return { ...state, summaryActivePillStatus: value };
        default:
          break;
      }
    },

    /* ---- Reset Data ---- */
    resetWorkOrderListData: (state) => {
      return { ...state, workOrderList: [] };
    },
    resetWorkOrderDetailsData: (state) => {
      return {
        ...state,
        workOrderDetails: null,
        updateActivityBtn: true,
        locationBtnEnabled: false,
        updateWorkOrderStatus: "",
        locationCoordinates: "",
        locationAddress: "",
        locationCaptureTime: "",

        workOrderStatus: "",
        returnReasonValue: "",
        returnRemarksValue: "",
        returnRemarksFinalValue: "",

        installationCompleteTime: "",

        btuId: "",
        btuPortNumber: "",

        uploadedDocuments: null,
        uploadedDocumentsList: null,
        activeTab: 1,

        deviceSLValidation: null,

        infrastructureLovValue: "",
        desiredinstallationLovValue: "",
        additionalCableLength: "",
      };
    },
    resetWorkOrderData: () => initialState,
  },
});

export const {
  saveSummaryData,
  setSearchWorkOrderListData,
  setInstallerNotesData,
  setUploadedDocumentsList,
  selectedWorkOrderItem,
  setDocumentManagementData,
  setSearchWorkOrderDetailsData,
  workOrderSearchPageState,
  updateWorkOrderDetailsData,
  resetWorkOrderListData,
  resetWorkOrderDetailsData,
  resetWorkOrderData,
} = WorkOrderDataState.actions;

export default WorkOrderDataState.reducer;
