import React from "react";
import { useNavigate } from "react-router-dom";
import { Constent } from "../../Assets/Constants/Constent";
import {
  CloseModalIcon,
  ConfirmationIcon,
} from "../../Assets/Images/CelcomSvgImages";
import { ApproveStockTable } from "../Common/Table";

const ApproveStockModal = ({
  selectedTrackStockItemDetails,
  approveStockApiCall,
  pageState,
  setPageState,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="modal fade request-stock-place-order-modal approve-qnt-modal"
      id="requestStockPlaceOrderModal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby="requestStockPlaceOrderModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pb-0">
            <div className="wrapper-modal-header pt-5">
              <div className="modal-header-title-section">
                <ConfirmationIcon />
                <h5
                  className="modal-title"
                  id="requestStockPlaceOrderModalLabel"
                >
                  {Constent.CONFIRMATION_MODAL_TITLE}
                </h5>
                <h5 className="modal-title">
                  {pageState?.isRejected
                    ? Constent.REJECT_STOCK_MODAL_TITLE
                    : Constent.APPROVE_STOCK_MODAL_TITLE}
                </h5>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <CloseModalIcon />
                </span>
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="wrapper-modal-body">
              <ApproveStockTable
                title1={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_DETAILS_TITLE5}
                title2={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE2}
                title3={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE1}
                title4={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE9}
                title5={
                  pageState?.isRejected
                    ? Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE11
                    : Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE10
                }
                data={selectedTrackStockItemDetails?.itemDetails}
              />
            </div>
          </div>
          <div className="modal-footer">
            <div
              className="refresh-btn-section"
              data-dismiss="modal"
              onClick={() => {
                setPageState({ ...pageState, isRejected: false });
              }}
            >
              <span>{Constent.CANCEL_BTN}</span>
            </div>
            <div
              className="search-btn-section"
              data-dismiss="modal"
              onClick={approveStockApiCall}
            >
              {Constent.CONFIRMATION_BTN}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveStockModal;
