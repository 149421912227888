import React, { useState } from 'react';
import { Header } from '../../../Components/Header/Header';
import SupervisorSidebar from '../../../Components/MainBody/Sidebar/SupervisorSidebar';
import { useSelector } from 'react-redux';
import { HeaderNavChild2 } from '../../../Components/Common/HeaderNav';
import { Constent } from '../../../Assets/Constants/Constent';
import ManageInstallerConfirmationBodyContent from '../../../Components/MainBody/BodyContent/Supervisor/ConfirmationPagesContent/ManageInstallerConfirmationBodyContent';
import Footer from '../../../Components/Footer/Footer';

const ManageInstallerConfirmationPage = ({ globalState, setGlobalState, installerDataObj }) => {

    const userDataObj = useSelector((state) => state.userDataInfo);
    const installerDataInfo = useSelector((state) => state.installerDataInfo);

    const [pageState, setPageState] = useState({
        displayMsg: installerDataObj?.addInstallerResponse.status?.toLowerCase()?.includes("User Created With"?.toLowerCase()) ?
            "You have successfully Created an Installer."
            : installerDataInfo?.addInstallerResponse?.toLowerCase()?.includes("Updated Successfully"?.toLowerCase()) ?
                installerDataInfo?.addInstallerResponse : "You have successfully Deleted an Installer."
    });

    return (
        <div className='page-start-section'>
            <Header userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} tab3={true} />
            <div className={globalState?.sidebarState ? "body-start-section sidebar-active-section" : "body-start-section"}>
                {/* Sidebar */}
                <SupervisorSidebar userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} />
                <div className="body-full-content-section">
                    <HeaderNavChild2 activeItem={Constent.HEADER_MENU_ITEM3} />
                    <ManageInstallerConfirmationBodyContent pageState={pageState} globalState={globalState}
                        installerDataObj={installerDataObj} userDataObj={userDataObj} />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default ManageInstallerConfirmationPage;