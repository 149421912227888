import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Constent } from '../../../../../Assets/Constants/Constent';
import OSTAccordionBodyContentDeviceItem from './OSTAccordionBodyContentDeviceItem';
import { TickMarkIcon } from "../../../../../Assets/Images/CelcomSvgImages";
import SignatureModal from "./SignatureModal";
import { Link } from 'react-router-dom';

const OSTAccordionBodyContent = ({ ownOrderDetails, workOrderDataObj, SerialNumberValidation,
  speedState,
  setSpeedState,
  wifiState,
  setWifiState,
  remark,
  setRemark,
  deviceBrand,
  setDeviceBrand,
  userDataObj }) => {
    const lovDataObj = useSelector((state) => state.lovDataInfo);

    
    const { rgwDeviceList, btuDeviceList, meshDeviceList } = ownOrderDetails?.deviceDetails;

    const { deviceSLValidation, ostChekedDeviceType } = workOrderDataObj;
   
    function speedLanVal(e) {
      setSpeedState(e?.target.value)
    }
  
    function speedWifiVal(e) {
      setWifiState(e?.target.value)
    }
  
    
    function remarkVal(e) {
      setRemark(e?.target.value)
    }

    return (
        <>
        <div className="card">
            <div className="card-header" id="workOrderHeadingOne">
                <button className="btn btn-link btn-block text-dark text-left custom-accordion-btn-link" type="button"
                    data-toggle="collapse" data-target="#workOrderCollapseOne" aria-expanded="false"
                    aria-controls="workOrderCollapseOne">
                    {Constent.UPDATE_WORK_ORDER_OST_CONTENT_ACCORDION_TITLE1}
                </button>
            </div>
            <div id="workOrderCollapseOne" className="collapse show"
                aria-labelledby="workOrderHeadingOne" data-parent="#workOrderCollapseOne">
                <div className="card-body ost-card-body">
                    {
                        // RGW
                        rgwDeviceList?.map((rgwDeviceItem, rgwIndex) => {
                            const productDetails = deviceSLValidation[rgwDeviceItem?.productID];
                            
                            return (
                                <OSTAccordionBodyContentDeviceItem key={`rgwDeviceList${rgwIndex}`} productDetails={productDetails}
                                    userDataObj={userDataObj} SerialNumberValidation={SerialNumberValidation}
                                    ostChekedDeviceType={ostChekedDeviceType} catagory={"RGW"} btuDevice={false}
                                    deviceData={rgwDeviceItem} lovDataObj={lovDataObj} deviceSLValidation={deviceSLValidation} />
                            )
                        })
                    }
                    {
                        // Mesh
                        meshDeviceList?.map((meshDeviceItem, meshIndex) => {
                            const productDetails = deviceSLValidation[meshDeviceItem?.productID];
                            return (
                                <OSTAccordionBodyContentDeviceItem key={`meshDeviceList${meshIndex}`} productDetails={productDetails}
                                    userDataObj={userDataObj} SerialNumberValidation={SerialNumberValidation}
                                    ostChekedDeviceType={ostChekedDeviceType} catagory={"MESH"} btuDevice={false}
                                    deviceData={meshDeviceItem} lovDataObj={lovDataObj} deviceSLValidation={deviceSLValidation} />
                            )
                        })
                    }
                    {
                        // BTU
                        btuDeviceList?.map((rgwDeviceItem, rgwIndex) => {
                            const productDetails = deviceSLValidation[rgwDeviceItem?.productID];
                            
                            return (
                                <OSTAccordionBodyContentDeviceItem key={`btuDeviceList${rgwIndex}`} productDetails={productDetails}
                                    userDataObj={userDataObj} SerialNumberValidation={SerialNumberValidation}
                                    ostChekedDeviceType={ostChekedDeviceType} catagory={"BTU"} btuDevice={true}
                                    deviceData={rgwDeviceItem} lovDataObj={lovDataObj} deviceSLValidation={deviceSLValidation}deviceBrand={deviceBrand}
                                    setDeviceBrand={setDeviceBrand} />
                            )
                        })
                    }
                    {/* {
                        [
                            {
                                productID: 'OL11372168PI149090741',
                                name: 'RGW-WHT',
                                productStatus: 'In-Active',
                                productCategory: 'FDH_FD2',
                                productType: 'Device',
                                productSubType: 'Device',
                                productSpecificationRefID: '20006498',
                                productFamilyInstanceID: 'SI13454778',
                                associatedOfferInstanceID: 'OL11372168PI14909065',
                                productOfferingRefID: '20006498',
                                deviceType: 'RGW',
                                deviceSerialNo: 'NA',
                                devicePreviousSerialNo: null,
                                meshDevice: false,
                                meshDeviceType: null
                            }
                        ]?.map((meshDeviceItem, meshIndex) => {
                            const productDetails = deviceSLValidation[meshDeviceItem?.productID];
                            return (
                                <OSTAccordionBodyContentDeviceItem key={`meshDeviceList${meshIndex}`} productDetails={productDetails}
                                    userDataObj={userDataObj} SerialNumberValidation={SerialNumberValidation}
                                    ostChekedDeviceType={ostChekedDeviceType}
                                    deviceData={meshDeviceItem} lovDataObj={lovDataObj} deviceSLValidation={deviceSLValidation} />
                            )
                        })
                    } */}
                </div>
            </div>
        </div>
        <div className="card">
        <div className="card-header" id="workOrderHeadingTwo">
          <button
            className="btn btn-link btn-block text-dark text-left custom-accordion-btn-link collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#workOrderCollapseThree"
            aria-expanded="false"
            aria-controls="workOrderCollapseThree"
          >
            {Constent.UPDATE_WORK_ORDER_OST_CONTENT_ACCORDION_TITLE2}
          </button>
        </div>
        <div
          id="workOrderCollapseThree"
          className="collapse"
          aria-labelledby="workOrderHeadingTwo"
          data-parent="#workOrderCollapseThree"
        >
          <div className="card-body">
            <div className="custom-card-body-item-group-section">
              <div className="form-group mb-0 custom-input-form-group custom-normal-input-form-group">
                {/* custom-valid-form-group --- custom-invalid-form-group*/}
                <div className="input-custom-input-form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={speedState}
                    disabled = {true}
                    onChange={(e) => {
                     speedLanVal(e)
                    }}
                    id={
                      Constent.UPDATE_WORK_ORDER_DOWNLOAD_SPEED_LAN_INPUT_ITEM_LABEL
                    }
                    placeholder=" "
                  />
                  <label
                    htmlFor={
                      Constent.UPDATE_WORK_ORDER_DOWNLOAD_SPEED_LAN_INPUT_ITEM_LABEL
                    }
                  >
                    {
                      Constent.UPDATE_WORK_ORDER_DOWNLOAD_SPEED_LAN_INPUT_ITEM_LABEL
                    }
                    <span>*</span>
                  </label>
                <span className="invalid-msg-text">
                  {Constent.INVALID_INPUT}
                </span>
                </div>
              </div>
            </div>
            <div className="custom-card-body-item-group-section">
              <div className="form-group mb-0 custom-input-form-group custom-normal-input-form-group">
                {/* custom-valid-form-group --- custom-invalid-form-group*/}
                <div className="input-custom-input-form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={wifiState}
                    disabled = {true}
                    onChange={(e) => {
                      speedWifiVal(e)
                    }}
                    id={
                      Constent.UPDATE_WORK_ORDER_DOWNLOAD_SPEED_WIFI_INPUT_ITEM_LABEL
                    }
                    placeholder=" "
                  />
                  <label
                    htmlFor={
                      Constent.UPDATE_WORK_ORDER_DOWNLOAD_SPEED_WIFI_INPUT_ITEM_LABEL
                    }
                  >
                    {
                      Constent.UPDATE_WORK_ORDER_DOWNLOAD_SPEED_WIFI_INPUT_ITEM_LABEL
                    }
                    <span>*</span>
                  </label>
                <span className="invalid-msg-text">
                  {Constent.INVALID_INPUT}
                </span>
                </div>
              </div>
            </div>
            <div className="custom-card-body-item-group-section">
              <div className="form-group mb-0 custom-input-form-group custom-normal-input-form-group">
                {/* custom-valid-form-group --- custom-invalid-form-group*/}
                <div className="input-custom-input-form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={remark}
                    disabled = {true}
                    onChange={(e) => {
                      remarkVal(e)
                    }}
                    id={
                      Constent.REMARKS
                    }
                    placeholder=" "
                  />
                  <label
                    htmlFor={
                      Constent.REMARKS
                    }
                  >
                    {
                      Constent.REMARKS
                    }
                    <span>*</span>
                  </label>
                <span className="invalid-msg-text">
                  {Constent.INVALID_INPUT}
                </span>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
      </>
    )
}

export default OSTAccordionBodyContent;
