import React, { useState, useEffect } from "react";
import { Header } from "../../Components/Header/Header";
import SupervisorSidebar from "../../Components/MainBody/Sidebar/SupervisorSidebar";

import { useSelector, useDispatch } from "react-redux";

import "../../Assets/CSS/TrackStockRequestDetailsPage.css";
import AdminTrackStockRequestDetailsBodyContent from "../../Components/MainBody/BodyContent/Admin/AdminTrackStockRequestDetailsBodyContent";
import Footer from "../../Components/Footer/Footer";
import TrackStockRequestDetailsBodyHeader from "../../Components/MainBody/BodyHeader/Supervisor/TrackStockRequestDetailsBodyHeader";
import { headers, TrackStockDetails, UpdateStock1 } from "../../Api/ApiCalls";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { ToastContainer, toast } from "react-toastify";
import { setTrackStockFieldValue } from "../../ReduxFeature/StateFeatures/InventoryDataState";
import ApproveStockModal from "../../Components/Modals/ApproveStockModal";
import { useNavigate } from "react-router-dom";
import { ApproveStockApiCallValidation } from "../../Components/Common/Validation";
import { ApiActionLoader } from "../../Components/Loaders/ApiActionLoader";

const AdminTrackStockRequestDetailsPage = ({ globalState, setGlobalState }) => {
  const userDataObj = useSelector((state) => state.userDataInfo);
  const inventoryDataObj = useSelector((state) => state.inventoryDataInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    selectedTrackStockItem,
    selectedTrackStockItemDetails,
    trackStockDetailsApproverComment,
  } = inventoryDataObj;
  const [pageState, setPageState] = useState({
    dataLoader: false,
    approverComments: "",
    isRejected: false,

    apiCallAction: false,
    apiCallActionMsg: "Approving...",
  });
  function ErrorMessageSetUp({ error, message1, message2 }) {
    let errorMessage;
    if (error.response.status === 500 || error.response.status === 503) {
      errorMessage = message1;
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    } else if (error.response.status === 404) {
      errorMessage = message2;
      toast.warning(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    } else {
      errorMessage = error.response.statusText;
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    }
    return errorMessage;
  }

  function trackStockDetailsApiCall() {
    setPageState({ ...pageState, dataLoader: true });
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);

    const trackStockDetailsRequest = {
      transferId: selectedTrackStockItem?.transferId,
    };

    TrackStockDetails(
      "/fibre/inventory/getTransferDetails",
      trackStockDetailsRequest,
      header
    )
      .then((response) => {
        dispatch(
          setTrackStockFieldValue({
            type: ApiConstant.INVENTORY_TRACK_STOCK_SELECTED_DATA_ITEM_DETAILS,
            value: response,
          })
        );

        setPageState({
          ...pageState,
          dataLoader: false,
          selectedTrackStockItemDetails: response,
          getDataError: false,
          getDataErrorMsg: "",
        });
      })
      .catch((error) => {
        const errorMessage = ErrorMessageSetUp({
          error: error,
          message1: ApiConstant.VIEW_STOCK_LIST_ERROR_MESSAGE,
          message2: ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE,
        });
        setPageState({
          ...pageState,
          dataLoader: false,
          selectedTrackStockItemDetails: null,
          getDataError: true,
          getDataErrorMsg: errorMessage,
        });
      });
  }

  function approveStockApiCall() {
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);
    /* --------- Validation need to implement --------*/
    if (
      ApproveStockApiCallValidation({
        itemDetails: selectedTrackStockItemDetails?.itemDetails,
      })
    ) {
      const stockItem = [];
      setPageState({ ...pageState, apiCallAction: true });
      selectedTrackStockItemDetails?.itemDetails?.map((itemData) => {
        stockItem.push({
          requestedQuantity: itemData?.requestedQuantity,
          approvedQuantity: itemData?.approvedQuantity,
          transferQuantity: itemData?.approvedQuantity,
          receivedQuantity: itemData?.approvedQuantity,
          sapMaterialCode: itemData?.sapMaterialCode,
        });
      });

      const approveStockRequest = {
        action: pageState?.isRejected ? "REJECT" : "APPROVE",
        approvalUser: userDataObj?.userData?.activeUserData?.userData?.userId,
        approverComments: trackStockDetailsApproverComment,
        receiverStore: ApiConstant.INVENTORY_REQUESTING_STORE_ID,
        shipUser: selectedTrackStockItemDetails?.createUser,
        stockItem: stockItem,
        storeId: ApiConstant.INVENTORY_REQUESTING_STORE_ID,
        transferId: selectedTrackStockItemDetails?.transferId,
      };

      UpdateStock1("/fibre/inventory/updateStock", approveStockRequest, header)
        .then((response) => {
          setPageState({ ...pageState, apiCallAction: false });
          if (
            response?.errorCode === "Success" &&
            response?.errorMessage === null &&
            (response?.transferStatus
              ?.toLowerCase()
              ?.includes("IN PROGRESS".toLowerCase()) ||
              response?.transferStatus
                ?.toLowerCase()
                ?.includes("REJECTED".toLowerCase()))
          ) {
            dispatch(
              setTrackStockFieldValue({
                type: ApiConstant.ADMIN_TRACK_STOCK_CONFIRMATION_MSG,
                value: pageState?.isRejected
                  ? ApiConstant.REJECT_SUCESSFULL_MSG
                  : ApiConstant.APPROVED_SUCESSFULL_MSG,
              })
            ) && navigate("/inventory/confirm");
          } else {
            toast.warning(ApiConstant.SUBMIT_APPROVE_ERROR_MESSAGE, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 10000,
              theme: "colored",
            });
          }
        })
        .catch((error) => {
          setPageState({ ...pageState, apiCallAction: false });
          ErrorMessageSetUp({
            error: error,
            message1: ApiConstant.SUBMIT_APPROVE_ERROR_MESSAGE,
            message2: ApiConstant.SUBMIT_APPROVE_ERROR_MESSAGE,
          });
        });
    } else {
      toast.warning(ApiConstant.APPROVE_QUANTITY_NOT_MATCH_WARNING_MESSAGE, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    }
  }
  useEffect(() => {
    trackStockDetailsApiCall();
  }, []);

  return (
    <div className="page-start-section">
      {pageState?.apiCallAction && (
        <ApiActionLoader msg={pageState?.apiCallActionMsg} />
      )}
      <Header
        userDataObj={userDataObj}
        globalState={globalState}
        setGlobalState={setGlobalState}
        tab4={true}
      />
      <div
        className={
          globalState?.sidebarState
            ? "body-start-section sidebar-active-section"
            : "body-start-section"
        }
      >
        {/* Sidebar */}
        <SupervisorSidebar
          userDataObj={userDataObj}
          globalState={globalState}
          setGlobalState={setGlobalState}
        />
        <div className="body-full-content-section">
          <TrackStockRequestDetailsBodyHeader />
          <AdminTrackStockRequestDetailsBodyContent
            pageState={pageState}
            globalState={globalState}
            setPageState={setPageState}
            trackStockDetailsApproverComment={trackStockDetailsApproverComment}
            selectedTrackStockItem={selectedTrackStockItem}
            selectedTrackStockItemDetails={selectedTrackStockItemDetails}
          />
        </div>
        <Footer />
      </div>
      <ToastContainer />

      <ApproveStockModal
        selectedTrackStockItemDetails={selectedTrackStockItemDetails}
        setPageState={setPageState}
        trackStockDetailsApproverComment={trackStockDetailsApproverComment}
        pageState={pageState}
        selectedTrackStockItem={selectedTrackStockItem}
        approveStockApiCall={approveStockApiCall}
      />
    </div>
  );
};

export default AdminTrackStockRequestDetailsPage;
