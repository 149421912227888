import React from "react";
import { Constent } from "../../../../../Assets/Constants/Constent";
import { TrackStockDetailsReturnTable } from "../../../../Common/Table";

const TrackStockReturnItemContent = ({ selectedTrackStockItemDetails }) => {
  return (
    <div className="track-stock-request-details-inner-item-return-table-section">
      <TrackStockDetailsReturnTable
        title1={"SL"}
        title2={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE2}
        title3={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE1}
        title4={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE9}
        title5={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE4}
        title6={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE3}
        data={selectedTrackStockItemDetails}
      />
    </div>
  );
};

export default TrackStockReturnItemContent;
