import moment from 'moment';
import React from 'react'
import DatePicker from 'react-date-picker';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ApiConstant } from '../../../../../Assets/Constants/ApiConstants';
import { Constent } from '../../../../../Assets/Constants/Constent';
import { CalendarIcon, LeftArrowIcon, RightArrowIcon } from '../../../../../Assets/Images/CelcomSvgImages';
import { selectedWorkOrderItem, workOrderSearchPageState } from '../../../../../ReduxFeature/StateFeatures/WorkOrderDataState';
import { CardItemGroup } from '../../../../Common/CardContent';
import { InstallerWorkOrderStatusCal, NumberFormater } from '../../../../Common/GenericFunctions';

const InstallerDashbordBodyCalendar = ({ pageState, workOrderDataObj, installerCalendatData }) => {
    const dispatch = useDispatch();

    const timeLine = Constent.INSTALLER_TIMELINE;
    const { calendarDate } = workOrderDataObj;

    const navigate = useNavigate();

    return (
        <div className='installer-body-calendar-start-section'>
            <div className="wrapper-installer-body-calendar-start-section">
                <div className="installer-body-calendar-group-section">
                    <div className="wrapper-installer-body-calendar-group-section">
                        <div className="installer-body-calendar-item-group-section">
                            <div className="inetaller-body-calendar-item-group" onClick={() => {
                                dispatch(workOrderSearchPageState({
                                    type: ApiConstant.INSTALLER_WORK_ORDER_CALENDAR_DATE,
                                    value: moment()
                                }))
                            }}>
                                {Constent.TODAY_BTN}
                            </div>
                            <div className="inetaller-body-calendar-item-group">
                                <div className="wrapper-inetaller-body-calendar-item-group">
                                    <div className="installer-body-calendar-item-btn" onClick={() => {
                                        dispatch(workOrderSearchPageState({
                                            type: ApiConstant.INSTALLER_WORK_ORDER_CALENDAR_DATE,
                                            value: moment(calendarDate).add(-1, 'days')
                                        }));
                                    }}>
                                        <LeftArrowIcon stroke={"#333333"} />
                                    </div>
                                    <div className="installer-body-calendar-item-btn">
                                        <div className="installer-body-calendar-item-btn-section">
                                            <span>{moment(calendarDate).format("YYYY-MM-DD")}</span>
                                            <CalendarIcon fill={"#333333"} />
                                        </div>
                                        <div className="installer-body-calendar-item-btn-section">
                                            <div className="date-picker-item-section">
                                                <DatePicker selected={moment()} onChange={date => {
                                                    dispatch(workOrderSearchPageState({
                                                        type: ApiConstant.INSTALLER_WORK_ORDER_CALENDAR_DATE,
                                                        value: moment(date)
                                                    }))
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="installer-body-calendar-item-btn" onClick={() => {
                                        dispatch(workOrderSearchPageState({
                                            type: ApiConstant.INSTALLER_WORK_ORDER_CALENDAR_DATE,
                                            value: moment(calendarDate).add(1, 'days')
                                        }));
                                    }}>
                                        <RightArrowIcon stroke={"#333333"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="installer-body-calendar-item-group-section">
                            <div className="form-group mb-0 ">
                                <select className="custom-select">
                                    <option value="Day">Day</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="installer-body-calendar-group-section">
                    <div className="wrapper-installer-body-calendar-group-section">
                        {
                            timeLine?.map((hourSet, houreIndex) => {
                                return (
                                    <div className="installer-body-calendar-hour-group-section"
                                        key={`hourIten${houreIndex}`}>
                                        {
                                            hourSet?.map((minItem, minIndex) => {
                                                let flag = true;
                                                let apiResponseObj;

                                                let statusColor;

                                                let isBTUHight = false;

                                                installerCalendatData?.workQueueVo?.map(apiData => {
                                                    let apiTime = (apiData?.resolutionDate?.split(" ")[1] || null);
                                                    const tempTime = parseInt(minItem?.split(":")[1]) + 9;
                                                    if ((minItem === apiTime) || (NumberFormater({ numberValue: parseInt(minItem?.split(":")[0]) }) === NumberFormater({ numberValue: parseInt(apiTime?.split(":")[0]) }) && (parseInt(apiTime?.split(":")[1]) <= tempTime && parseInt(apiTime?.split(":")[1]) >= minItem?.split(":")[1]))) {
                                                        flag = false;
                                                        apiResponseObj = apiData;
                                                        const { itemStatus } = InstallerWorkOrderStatusCal({ status: apiData?.itemStatus });
                                                        statusColor = itemStatus;

                                                        isBTUHight = apiData?.itemTypeId === "BTU_Collection";
                                                    }
                                                });
                                                
                                                return (
                                                    (minIndex === 0 || !flag) &&
                                                    <div className="installer-body-calendar-min-group-section" key={`minIten${minItem + minIndex}`}>
                                                        {
                                                            (minIndex === 0 || !flag) && <div className={
                                                                // isBTUHight ?
                                                                // "installer-body-calendar-item-section installer-btu-item-height" :
                                                                "installer-body-calendar-item-section"}>
                                                                {
                                                                    minIndex === 0 &&
                                                                    <div className="installer-body-calendar-item-time-section">
                                                                        {minItem}
                                                                    </div>
                                                                }
                                                                {
                                                                    !flag && <div className={(statusColor === Constent.SUPERVISOR_SIDEBAR_SUBTYPE1
                                                                        && "installer-body-calendar-item-value-section installer-status-yellow")
                                                                        || ((statusColor === Constent.SUPERVISOR_SIDEBAR_SUBTYPE5 || statusColor === Constent.SUPERVISOR_SIDEBAR_SUBTYPE5_TEXT)
                                                                            && "installer-body-calendar-item-value-section installer-status-red")
                                                                        || (statusColor === Constent.SUPERVISOR_SIDEBAR_SUBTYPE3
                                                                            && "installer-body-calendar-item-value-section installer-status-green")}
                                                                        style={{ top: `calc(${minIndex * 16}% + 8px)` }}>

                                                                        <div className="wrapper-installer-body-calendar-item-value-section" onClick={() => {
                                                                            dispatch(selectedWorkOrderItem(apiResponseObj)) && navigate("/workorderdetails")
                                                                        }}>
                                                                            <div className="installer-body-calendar-item-value-group-section">
                                                                                <div className="wrapper-installer-body-calendar-item-value-group-section">
                                                                                    <CardItemGroup
                                                                                        title={Constent.WORK_ORDER_CARD_ITEM_TITLE1}
                                                                                        value={apiResponseObj?.itemId?.replaceAll("FieldActivity-", "") || "-"} />
                                                                                    <CardItemGroup
                                                                                        title={Constent.WORK_ORDER_CARD_ITEM_TITLE11}
                                                                                        value={apiResponseObj?.workOrderType || "-"} />
                                                                                    <CardItemGroup
                                                                                        title={Constent.WORK_ORDER_CARD_ITEM_TITLE4}
                                                                                        value={apiResponseObj?.resolutionDate || "-"} />
                                                                                    <CardItemGroup
                                                                                        title={Constent.WORK_ORDER_CARD_ITEM_TITLE10}
                                                                                        value={statusColor || "-"} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InstallerDashbordBodyCalendar;