import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { headers } from "../../../../../Api/ApiCalls";
import { ApiConstant } from "../../../../../Assets/Constants/ApiConstants";
import { Constent } from "../../../../../Assets/Constants/Constent";
import { TickMarkIcon } from "../../../../../Assets/Images/CelcomSvgImages";
import { updateWorkOrderDetailsData } from "../../../../../ReduxFeature/StateFeatures/WorkOrderDataState";
import SignatureModal from "./SignatureModal";
import CustomerSignModal from "./CustomerSignModal";
import { AppoinmentSubTypeConverter, AppoinmentTypeConverter } from "../../../../Common/GenericFunctions";

const OSIAccordionBodyContent = ({
  workOrderDataObj,
  ownOrderDetails,
  userDataObj,
  SerialNumberValidation,
  speedState,
  setSpeedState,
  wifiState,
  setWifiState,
  brandDetails,
  setBrandDetails,
  remark,
  setRemark,
}) => {
  const lovDataObj = useSelector((state) => state.lovDataInfo);
  const dispatch = useDispatch();
 
  const {
    deviceSLValidation,
    btuId,
    btuPortNumber,
    infrastructureLovValue,
    desiredinstallationLovValue,
    additionalCableLength,
  } = workOrderDataObj;
  const { infrastructure, desiredinstallation } = lovDataObj;
  const { rgwDeviceList, btuDeviceList, meshDeviceList } =
    ownOrderDetails?.deviceDetails;
  const [inputFieldValidation, setInputFieldValidation] = useState({
    btuPortNumberClass:
      btuPortNumber.length === 0
        ? ""
        : 0 < btuPortNumber.length && btuPortNumber.length <= 4
        ? "custom-valid-form-group"
        : "custom-invalid-form-group",
  });

  function speedLanVal(e) {
    setSpeedState(e?.target.value)
  }

  function speedWifiVal(e) {
    setWifiState(e?.target.value)
  }

  
  function remarkVal(e) {
    setRemark(e?.target.value)
  }

  function brandVal(e) {
    setBrandDetails(e?.target.value)
  }

 
  return (
    <>
      {/* Card -1 */}
      <div className="card">
        <div className="card-header" id="workOrderHeadingOne">
          <button
            className="btn btn-link btn-block text-dark text-left custom-accordion-btn-link"
            type="button"
            data-toggle="collapse"
            data-target="#workOrderCollapseOne"
            aria-expanded="false"
            aria-controls="workOrderCollapseOne"
          >
            {Constent.UPDATE_WORK_ORDER_OSI_CONTENT_ACCORDION_TITLE1}
          </button>
        </div>
        <div
          id="workOrderCollapseOne"
          className="collapse show"
          aria-labelledby="workOrderHeadingOne"
          data-parent="#workOrderCollapseOne"
        >
          <div className="card-body">
            {
              // RGW
              rgwDeviceList?.map((deviceData, index) => {
                const productDetails =
                  deviceSLValidation[deviceData?.productID];

                const inputBtnDisabled = !(
                  deviceData?.deviceSerialNo === null ||
                  deviceData?.deviceSerialNo === undefined
                );

                return (
                  <div
                    className="custom-card-body-item-group-section"
                    key={`rgwKeyIndex${index}`}
                  >
                    <div className="custom-form-group-title-section mb-2">
                      <span>{`${
                        deviceData?.nameToDisplay
                          ? deviceData?.nameToDisplay?.replaceAll("_", " ")
                          : deviceData?.nameToDisplay
                      }`}</span>
                    </div>
                    <div
                      className={`form-group mb-0 custom-input-form-group custom-normal-input-form-group ${
                        productDetails?.valid === ApiConstant.VALID_INP
                          ? "custom-valid-form-group"
                          : productDetails?.valid === ApiConstant.INVALID_INP
                          ? "custom-invalid-form-group"
                          : ""
                      }`}
                    >
                      {/* custom-valid-form-group --- custom-invalid-form-group*/}
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          value={productDetails?.serialnumber || ""}
                          disabled={inputBtnDisabled}
                          onChange={(e) => {
                            dispatch(
                              updateWorkOrderDetailsData({
                                type: ApiConstant.INSTALLER_SL_VALIDATION_SERIAL_NUMBER,
                                value: {
                                  productId: deviceData?.productID,
                                  itemValue: e.target.value,
                                },
                              })
                            );
                          }}
                          onBlur={(e) => {
                            // console.log(deviceData?.productID)
                            SerialNumberValidation({
                              event: e,
                              object: {
                                productId: deviceData?.productID,
                                outletId:
                                  userDataObj?.userData?.activeUserData
                                    ?.userData?.outletId,
                                userId:
                                  userDataObj?.userData?.activeUserData
                                    ?.userData?.userId,
                                materialCode:
                                  deviceData?.productSpecificationRefID,
                              },
                              header: headers(
                                userDataObj?.userData?.activeUserData?.authToken
                              ),
                            });
                          }}
                          id={Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL1}
                          placeholder=" "
                        />
                        <label
                          htmlFor={Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL1}
                        >
                          {Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL1}
                          <span>*</span>
                        </label>
                        <div className="input-group-append">
                          <span
                            className={`input-group-text ${
                              inputBtnDisabled
                                ? "custom-input-group-disabled"
                                : ""
                            }`}
                          >
                            <TickMarkIcon stroke={"#009BDF"} />
                          </span>
                        </div>
                      </div>
                      <span className="invalid-msg-text">
                        {Constent.INVALID_SERIAL_NUMBER}
                      </span>
                    </div>
                  </div>
                );
              })
            }
            {
              // Mesh
              meshDeviceList?.map((deviceData, index) => {
                const productDetails =
                  deviceSLValidation[deviceData?.productID];

                const inputBtnDisabled = !(
                  deviceData?.deviceSerialNo === null ||
                  deviceData?.deviceSerialNo === undefined
                );

                return (
                  <div
                    className="custom-card-body-item-group-section"
                    key={`meshKeyIndex${index}`}
                  >
                    {/*CDCR-83 changes - To append Device Brand and Device Model to nameToDisplay*/}
                    <div className="custom-form-group-title-section mb-2">
                      <span>{`${
                        deviceData?.nameToDisplay
                          ? deviceData?.nameToDisplay?.replaceAll("_", " ")
                          : deviceData?.nameToDisplay
                      } - ${
                        deviceData?.deviceBrand
                          ? deviceData?.deviceBrand?.replaceAll("_", " ")
                          : deviceData?.deviceBrand
                      } - ${
                        deviceData?.deviceModel
                          ? deviceData?.deviceModel?.replaceAll("_", " ")
                          : deviceData?.deviceModel
                      }`}</span>
                      {/* {` ${Constent.UPDATE_WORK_ORDER_MODAL_NAME_TITLE1}`} */}
                    </div>
                    <div
                      className={`form-group mb-0 custom-input-form-group custom-normal-input-form-group ${
                        productDetails?.valid === ApiConstant.VALID_INP
                          ? "custom-valid-form-group"
                          : productDetails?.valid === ApiConstant.INVALID_INP
                          ? "custom-invalid-form-group"
                          : ""
                      }`}
                    >
                      {/* custom-valid-form-group --- custom-invalid-form-group*/}
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          value={productDetails?.serialnumber || ""}
                          disabled={inputBtnDisabled}
                          onChange={(e) => {
                            dispatch(
                              updateWorkOrderDetailsData({
                                type: ApiConstant.INSTALLER_SL_VALIDATION_SERIAL_NUMBER,
                                value: {
                                  productId: deviceData?.productID,
                                  itemValue: e.target.value,
                                },
                              })
                            );
                          }}
                          onBlur={(e) => {
                            SerialNumberValidation({
                              event: e,
                              object: {
                                productId: deviceData?.productID,
                                outletId:
                                  userDataObj?.userData?.activeUserData
                                    ?.userData?.outletId,
                                userId:
                                  userDataObj?.userData?.activeUserData
                                    ?.userData?.userId,
                                materialCode:
                                  deviceData?.productSpecificationRefID,
                              },
                              header: headers(
                                userDataObj?.userData?.activeUserData?.authToken
                              ),
                            });
                          }}
                          id={Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL1}
                          placeholder=" "
                        />
                        <label
                          htmlFor={Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL1}
                        >
                          {Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL1}
                          <span>*</span>
                        </label>
                        <div className="input-group-append">
                          <span
                            className={`input-group-text ${
                              inputBtnDisabled
                                ? "custom-input-group-disabled"
                                : ""
                            }`}
                          >
                            <TickMarkIcon stroke={"#009BDF"} />
                          </span>
                        </div>
                      </div>
                      <span className="invalid-msg-text">
                        {Constent.INVALID_SERIAL_NUMBER}
                      </span>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
      {/* Card - 2 */}
      <div className="card">
        <div className="card-header" id="workOrderHeadingTwo">
          <button
            className="btn btn-link btn-block text-dark text-left custom-accordion-btn-link collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#workOrderCollapseTwo"
            aria-expanded="false"
            aria-controls="workOrderCollapseTwo"
          >
            {Constent.UPDATE_WORK_ORDER_OSI_CONTENT_ACCORDION_TITLE2}
          </button>
        </div>
        <div
          id="workOrderCollapseTwo"
          className="collapse"
          aria-labelledby="workOrderHeadingTwo"
          data-parent="#workOrderCollapseTwo"
        >
          <div className="card-body">
            {/* BTU ID */}
            {/* <div className="custom-card-body-item-group-section">
                            <div className="custom-form-group-title-section mb-2">
                                <span>{Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_TITLE1}</span>
                            </div>
                            <div className={`form-group mb-0 custom-input-form-group custom-normal-input-form-group ${btuId?.length > 0 ? "custom-valid-form-group" : ""}`}>
                                <div className="input-group">
                                    <input type="text" className="form-control" value={btuId} onChange={e => {
                                        dispatch(updateWorkOrderDetailsData({
                                            type: ApiConstant.INSTALLER_BTU_ID_INPUTVALUE,
                                            value: e.target.value
                                        }))
                                    }}
                                        id={Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL1} placeholder=" " />
                                    <label htmlFor={Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL1}>
                                        {Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL1}
                                    </label>
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <TickMarkIcon stroke={"#009BDF"} />
                                        </span>
                                    </div>
                                </div>
                                <span className='invalid-msg-text'>
                                    {Constent.INVALID_MSG + " " + Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL1}
                                </span>
                            </div>
                        </div> */}
            {/* BTU Serial Number */}
            {btuDeviceList?.map((deviceData, index) => {
              const productDetails = deviceSLValidation[deviceData?.productID];
              return (
                <div
                  className="custom-card-body-item-group-section"
                  key={`btuKeyIndex${index}`}
                >
                  <div className="custom-form-group-title-section mb-2">
                    <span>
                      {
                        Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_TITLE2
                      }
                    </span>
                  </div>
                  <div
                    className={`form-group mb-0 custom-input-form-group custom-normal-input-form-group ${
                      productDetails?.valid === ApiConstant.VALID_INP
                        ? "custom-valid-form-group"
                        : productDetails?.valid === ApiConstant.INVALID_INP
                        ? "custom-invalid-form-group"
                        : ""
                    }`}
                  >
                    {/* custom-valid-form-group --- custom-invalid-form-group*/}
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        value={productDetails?.serialnumber || ""}
                        onChange={(e) => {
                          dispatch(
                            updateWorkOrderDetailsData({
                              type: ApiConstant.INSTALLER_SL_VALIDATION_SERIAL_NUMBER,
                              value: {
                                productId: deviceData?.productID,
                                itemValue: e.target.value,
                              },
                            })
                          );
                        }}
                        onBlur={(e) => {
                          const { value } = e.target;
                          // console.log(value, value?.length)
                          if (value?.length === 0 || value === "") {
                            dispatch(
                              updateWorkOrderDetailsData({
                                type: ApiConstant.INSTALLER_SL_VALIDATION_VALID,
                                value: {
                                  productId: deviceData?.productID,
                                  itemValue: "",
                                },
                              })
                            );
                          } else if (
                            value?.length >= Constent.BTU_SERIAL_NUMBER_MIN
                          ) {
                            dispatch(
                              updateWorkOrderDetailsData({
                                type: ApiConstant.INSTALLER_SL_VALIDATION_VALID,
                                value: {
                                  productId: deviceData?.productID,
                                  itemValue: ApiConstant.VALID_INP,
                                },
                              })
                            );
                          } else {
                            dispatch(
                              updateWorkOrderDetailsData({
                                type: ApiConstant.INSTALLER_SL_VALIDATION_VALID,
                                value: {
                                  productId: deviceData?.productID,
                                  itemValue: ApiConstant.INVALID_INP,
                                },
                              })
                            );
                          }
                        }}
                        id={
                          Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL2
                        }
                        placeholder=" "
                      />
                      <label
                        htmlFor={
                          Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL2
                        }
                      >
                        {
                          Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL2
                        }
                        {/* <span>*</span> */}
                      </label>
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <TickMarkIcon stroke={"#009BDF"} />
                        </span>
                      </div>
                    </div>
                    <span className="invalid-msg-text">
                      {Constent.INVALID_MSG +
                        " " +
                        Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL2}
                    </span>
                  </div>
                </div>
              );
            })}

            {/* BTU Port Number */}
            <div className="custom-card-body-item-group-section">
              <div className="custom-form-group-title-section mb-2">
                <span>
                  {
                    Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_TITLE3
                  }
                </span>
              </div>
              <div
                className={`form-group mb-0 custom-input-form-group custom-normal-input-form-group ${inputFieldValidation?.btuPortNumberClass}`}
              >
                {/* custom-valid-form-group --- custom-invalid-form-group */}
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    value={btuPortNumber}
                    onChange={(e) => {
                      dispatch(
                        updateWorkOrderDetailsData({
                          type: ApiConstant.INSTALLER_BTU_PORT_INPUTVALUE,
                          value: e.target.value,
                        })
                      );
                      setInputFieldValidation({
                        ...inputFieldValidation,
                        btuPortNumberClass: "",
                      });
                    }}
                    onBlur={(e) => {
                      setInputFieldValidation({
                        ...inputFieldValidation,
                        btuPortNumberClass:
                          e.target.value.length === 0
                            ? ""
                            : 0 < e.target.value.length &&
                              e.target.value.length <= 4
                            ? "custom-valid-form-group"
                            : "custom-invalid-form-group",
                      });
                    }}
                    id={
                      Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL3
                    }
                    placeholder=" "
                  />
                  <label
                    htmlFor={
                      Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL3
                    }
                  >
                    {
                      Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL3
                    }
                    {/* <span>*</span> */}
                  </label>
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <TickMarkIcon stroke={"#009BDF"} />
                    </span>
                  </div>
                </div>
                <span className="invalid-msg-text">
                  {Constent.INVALID_MSG +
                    " " +
                    Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL3}
                </span>
              </div>
            </div>
            {/* {!(workOrderDataObj?.workOrderDetails?.itemSubTypeId.includes(ApiConstant.WOKR_ORDER_RELOCATION_SUBTYPE_MAP)) && */}
            <div className="custom-card-body-item-group-section">
            <div className="custom-form-group-title-section mb-2">
                <span>
                  {
                    Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_TITLE4
                  }
                </span>
              </div>
              <div className="form-group mb-0 custom-input-form-group custom-normal-input-form-group">
                {/* custom-valid-form-group --- custom-invalid-form-group*/}
                <div className="input-custom-input-form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={brandDetails}
                    disabled = {true}
                    onChange={(e) => {
                      brandVal(e)
                    }}
                    id={
                      Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL4
                    }
                    placeholder=" "
                  />
                  <label
                    htmlFor={
                      Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL4
                    }
                  >
                    {
                      Constent.UPDATE_WORK_ORDER_BTU_CONTENT_ACCORDION_SUB_ITEM_LABEL4
                    }
                    <span>*</span>
                  </label>
                <span className="invalid-msg-text">
                  {Constent.INVALID_INPUT}
                </span>
                </div>
              </div>
            </div> 
            {/* } */}
          </div>
        </div>
      </div>
      {/* Card - 3 */}
      <div className="card">
        <div className="card-header" id="workOrderHeadingTwo">
          <button
            className="btn btn-link btn-block text-dark text-left custom-accordion-btn-link collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#workOrderCollapseThree"
            aria-expanded="false"
            aria-controls="workOrderCollapseThree"
          >
            {Constent.UPDATE_WORK_ORDER_OSI_CONTENT_ACCORDION_TITLE3}
          </button>
        </div>
        <div
          id="workOrderCollapseThree"
          className="collapse"
          aria-labelledby="workOrderHeadingTwo"
          data-parent="#workOrderCollapseThree"
        >
          <div className="card-body">
            <div className="custom-card-body-item-group-section">
              <div className="form-group m-0 custom-drop-down-form-group">
                <select
                  className="custom-select"
                  value={infrastructureLovValue}
                  onChange={(e) => {
                    dispatch(
                      updateWorkOrderDetailsData({
                        type: ApiConstant.LOV_STORE_UPDATE_WORK_ORDER_INFRASTRUCTURE_KEY,
                        value: e.target.value,
                      })
                    );
                  }}
                >
                  <option defaultValue="" value="">
                    --Select--
                  </option>
                  {infrastructure?.map((data, index) => {
                    return (
                      <option
                        key={`${
                          Constent.UPDATE_WORK_ORDER_INFRASTRUCTURE_INPUT_ITEM_LABEL +
                          index
                        }`}
                        value={`${data?.value}`}
                      >
                        {data?.display}
                      </option>
                    );
                  })}
                </select>
                <label
                  htmlFor={
                    Constent.UPDATE_WORK_ORDER_INFRASTRUCTURE_INPUT_ITEM_LABEL
                  }
                >
                  {Constent.UPDATE_WORK_ORDER_INFRASTRUCTURE_INPUT_ITEM_LABEL}
                  <span>*</span>
                </label>
              </div>
            </div>
            <div className="custom-card-body-item-group-section">
              <div className="form-group m-0 custom-drop-down-form-group">
                <select
                  className="custom-select"
                  value={desiredinstallationLovValue}
                  onChange={(e) => {
                    dispatch(
                      updateWorkOrderDetailsData({
                        type: ApiConstant.LOV_STORE_UPDATE_WORK_ORDER_DISIRED_INSTALLATION_KEY,
                        value: e.target.value,
                      })
                    );
                  }}
                >
                  <option defaultValue="" value="">
                    --Select--
                  </option>
                  {desiredinstallation?.map((data, index) => {
                    return (
                      <option
                        key={`${
                          Constent.UPDATE_WORK_ORDER_DESIRED_INSTALLATION_INPUT_ITEM_LABEL +
                          index
                        }`}
                        value={`${data?.value}`}
                      >
                        {data?.display}
                      </option>
                    );
                  })}
                </select>
                <label
                  htmlFor={
                    Constent.UPDATE_WORK_ORDER_DESIRED_INSTALLATION_INPUT_ITEM_LABEL
                  }
                >
                  {
                    Constent.UPDATE_WORK_ORDER_DESIRED_INSTALLATION_INPUT_ITEM_LABEL
                  }
                  <span>*</span>
                </label>
              </div>
            </div>
            <div className="custom-card-body-item-group-section">
              <div className="form-group mb-0 custom-input-form-group custom-normal-input-form-group">
                {/* custom-valid-form-group --- custom-invalid-form-group*/}
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    value={additionalCableLength}
                    onChange={(e) => {
                      dispatch(
                        updateWorkOrderDetailsData({
                          type: ApiConstant.INSTALLER_ADDITIONAL_CABLE_LENGTH_INPUTVALUE,
                          value: e.target.value,
                        })
                      );
                    }}
                    id={
                      Constent.UPDATE_WORK_ORDER_ADDITIONAL_CABLE_LENGTH_INPUT_ITEM_LABEL
                    }
                    placeholder=" "
                  />
                  <label
                    htmlFor={
                      Constent.UPDATE_WORK_ORDER_ADDITIONAL_CABLE_LENGTH_INPUT_ITEM_LABEL
                    }
                  >
                    {
                      Constent.UPDATE_WORK_ORDER_ADDITIONAL_CABLE_LENGTH_INPUT_ITEM_LABEL
                    }
                     {/* <span>*</span> */}
                  </label>
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <TickMarkIcon stroke={"#009BDF"} />
                    </span>
                  </div>
                </div>
                <span className="invalid-msg-text">
                  {Constent.INVALID_INPUT}
                </span>
              </div>
            </div>
            <div className="custom-card-body-item-group-section">
              <div className="form-group mb-0 custom-input-form-group custom-normal-input-form-group">
                {/* custom-valid-form-group --- custom-invalid-form-group*/}
                <div className="input-custom-input-form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={speedState}
                    disabled = {true}
                    onChange={(e) => {
                      speedLanVal(e)
                    }}
                    id={
                      Constent.UPDATE_WORK_ORDER_DOWNLOAD_SPEED_LAN_INPUT_ITEM_LABEL
                    }
                    placeholder=" "
                  />
                  <label
                    htmlFor={
                      Constent.UPDATE_WORK_ORDER_DOWNLOAD_SPEED_LAN_INPUT_ITEM_LABEL
                    }
                  >
                    {
                      Constent.UPDATE_WORK_ORDER_DOWNLOAD_SPEED_LAN_INPUT_ITEM_LABEL
                    }
                    <span>*</span>
                  </label>
                <span className="invalid-msg-text">
                  {Constent.INVALID_INPUT}
                </span>
                </div>
              </div>
            </div>
            <div className="custom-card-body-item-group-section">
              <div className="form-group mb-0 custom-input-form-group custom-normal-input-form-group">
                {/* custom-valid-form-group --- custom-invalid-form-group*/}
                <div className="input-custom-input-form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={wifiState}
                    disabled = {true}
                    onChange={(e) => {
                      speedWifiVal(e)
                    }}
                    id={
                      Constent.UPDATE_WORK_ORDER_DOWNLOAD_SPEED_WIFI_INPUT_ITEM_LABEL
                    }
                    placeholder=" "
                  />
                  <label
                    htmlFor={
                      Constent.UPDATE_WORK_ORDER_DOWNLOAD_SPEED_WIFI_INPUT_ITEM_LABEL
                    }
                  >
                    {
                      Constent.UPDATE_WORK_ORDER_DOWNLOAD_SPEED_WIFI_INPUT_ITEM_LABEL
                    }
                    <span>*</span>
                  </label>
                <span className="invalid-msg-text">
                  {Constent.INVALID_INPUT}
                </span>
                </div>
              </div>
            </div> 
            <div className="custom-card-body-item-group-section">
              <div className="form-group mb-0 custom-input-form-group custom-normal-input-form-group">
                {/* custom-valid-form-group --- custom-invalid-form-group*/}
                <div className="input-custom-input-form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={remark}
                    disabled = {true}
                    onChange={(e) => {
                      remarkVal(e)
                    }}
                    id={
                      Constent.REMARKS
                    }
                    placeholder=" "
                  />
                  <label
                    htmlFor={
                      Constent.REMARKS
                    }
                  >
                    {
                      Constent.REMARKS
                    }
                    <span>*</span>
                  </label>
                <span className="invalid-msg-text">
                  {Constent.INVALID_INPUT}
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default OSIAccordionBodyContent;
