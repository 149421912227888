import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import DatePicker from "react-date-picker";
import { Constent } from "../../../../Assets/Constants/Constent";
import {
  CalendarIcon,
  DownloadIcon,
  SearchIcon,
} from "../../../../Assets/Images/CelcomSvgImages";
import { BodyBreadcrumbs } from "../../../Common/BodyBreadcrumbs";
import { workOrderSearchPageState } from "../../../../ReduxFeature/StateFeatures/WorkOrderDataState";
import { ApiConstant } from "../../../../Assets/Constants/ApiConstants";
import {
  DonloadExcelFile,
  EmptyValueHandler,
  SupervisorWorkOrderStatusCal,
} from "../../../Common/GenericFunctions";
import { useNavigate } from "react-router-dom";

const SearchActivityBodyHeader = ({
  globalState,
  filterList,
  pageState,
  setPageState,
  userDataObj,
  workOrderDataObj,
  searchWorkOrderListApiCall,
  reportExtractionAPICall,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { workOrderId, workOrderFromDate, workOrderToDate, searchType } =
    workOrderDataObj;

  const downloadExcelFun = () => {
    let fileName = "";
    // const fileName =
    //   "ReportList_" +
    //   (pageState?.workOrderFromDate || moment().format("YYYY-MM-DD")) +
    //   "-to-" +
    //   (pageState?.workOrderToDate || moment().format("YYYY-MM-DD"));
    // Request Stock
    //Sl, Material Code, Item Name, Status, Type, Category, Brand, Request Qty, Approve Qty, Received Qty, Transferred Qty
    const downloadData = [];
    if (
      pageState?.activePill === Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM1
    ) {
      fileName =
        "WorkList_" +
        (pageState?.workOrderFromDate || moment().format("YYYY-MM-DD")) +
        "-to-" +
        (pageState?.workOrderToDate || moment().format("YYYY-MM-DD"));
      filterList?.map((data, index) => {
        const { status } = SupervisorWorkOrderStatusCal(data) || { status: "" };

        downloadData.push({
          SL: index + 1,
          "Item Id": EmptyValueHandler({ item: data?.itemId }),
          "Order Id/Case Id": EmptyValueHandler({ item: data?.orderId }),
          "Customer Id": EmptyValueHandler({ item: data?.customerId }),
          "Order Created Date": EmptyValueHandler({ item: data?.createdDate }),
          "Appointment Date": EmptyValueHandler({ item: data?.resolutionDate }),
          "Appointment Type": EmptyValueHandler({ item: data?.workOrderType }),
          "Appointment Sub Type": EmptyValueHandler({
            item: data?.workOrderSubType,
          }),
          "Assign To": EmptyValueHandler({ item: data?.assignedTo }),
          Status: EmptyValueHandler({ item: status }),
          District: EmptyValueHandler({ item: data?.district }),
          Address: EmptyValueHandler({
            item: data?.city + ", " + data?.district + ", " + data?.street,
          }),
        });
      });
    } else {
      pageState?.workOrderListData?.map((data, index) => {
        const { status } = SupervisorWorkOrderStatusCal(data) || { status: "" };

        if (
          userDataObj?.userData?.activeUserData?.userRole ===
          Constent.ADMIN_ROLE
        ) {
          fileName =
            "CFMP_SuperAdmin_Report" +
            (pageState?.workOrderReportFromDate ||
              moment().format("YYYY-MM-DD")) +
            "-to-" +
            (pageState?.workOrderReportToDate ||
              moment().subtract(1, "d").format("YYYY-MM-DD"));
        } else {
          fileName =
            "CFMP_Supervisor_Report" +
            (pageState?.workOrderReportFromDate ||
              moment().format("YYYY-MM-DD")) +
            "-to-" +
            (pageState?.workOrderReportToDate ||
              moment().subtract(1, "d").format("YYYY-MM-DD"));
        }

        const downloadContent = {
          SNo: EmptyValueHandler({ item: index + 1 }),
          access_provider: EmptyValueHandler({ item: data?.accessProvider }),
          // outlet_id: EmptyValueHandler({ item: data?.accessProvider }),
          appointment_date: EmptyValueHandler({ item: data?.appointmentDate }),
          order_no: EmptyValueHandler({ item: data?.orderNo }),

          work_order_id: EmptyValueHandler({ item: data?.workOrderId }),
          status: EmptyValueHandler({ item: data?.workOrderStatus }),
          appointment_type: EmptyValueHandler({ item: data?.appointmentType }),
          appointment_sub_type: EmptyValueHandler({
            item: data?.appointmentSubType,
          }),

          customer_name: EmptyValueHandler({ item: data?.customerName }),
          customer_id: EmptyValueHandler({ item: data?.customerId }),
          customer_contact_number: EmptyValueHandler({
            item: data?.customerContactNo,
          }),
          customer_address_type: EmptyValueHandler({
            item: data?.customerAddressType,
          }),
          customer_address: EmptyValueHandler({ item: data?.customerAddress }),
          plan: EmptyValueHandler({ item: data?.plan }),
          hsi_username: EmptyValueHandler({ item: data?.hsiUsername }),
          notes_for_installer: EmptyValueHandler({
            item: data?.installerNotes,
          }),
          installer_name: EmptyValueHandler({ item: data?.installerName }),
          installer_contact_number: EmptyValueHandler({
            item: data?.installerContactNo,
          }),
          installer_closing_date_and_time: EmptyValueHandler({
            item: data?.installationCloseTimestamp,
          }),
          activity_closing_date_and_time: EmptyValueHandler({
            item: data?.installerClosingDateAndTime,
          }),
          rgw_serial_number: EmptyValueHandler({ item: data?.rgwSlNo }),
          mesh_serial_number: EmptyValueHandler({
            item: data?.meshSerialNumber,
          }),
          btu_serial_number: EmptyValueHandler({ item: data?.butSlNo }),
          desired_installation: EmptyValueHandler({
            item: data?.desiredInstallation,
          }),
          additional_cabling: EmptyValueHandler({
            item: data?.additionalCabling,
          }),
          return_remarks: EmptyValueHandler({ item: data?.returnRemarks }),
          return_remarks_additional_info: EmptyValueHandler({
            item: data?.returnRemarksAdditionalInfo,
          }),
          ost_element: EmptyValueHandler({ item: data?.ostElement }),
          ost_resolution: EmptyValueHandler({ item: data?.ostResolution }),
          ost_problem_cause: EmptyValueHandler({ item: data?.ostProblemCause }),
        };
        if (
          userDataObj?.userData?.activeUserData?.userRole ===
          Constent.ADMIN_ROLE
        ) {
          downloadContent["company_name"] = EmptyValueHandler({
            item: data?.companyName,
          });
        }
        downloadData.push(downloadContent);
      });
    }

    /* Download Excel Function */
    DonloadExcelFile({
      fileName: fileName,
      downloadData: downloadData,
    });
  };

  return (
    <div className="workorder-header-start-section">
      <div className="wrapper-workorder-header-section">
        <BodyBreadcrumbs activeItem={Constent.HEADER_MENU_ITEM1} />
        <div
          className="inventory-header-pill-section mb-3"
          data-aos="fade-right"
          data-aos-easing="ease-in-out"
          data-aos-delay="100"
        >
          {userDataObj?.userData?.activeUserData?.userRole ===
          Constent.INSTALLER_ROLE ? (
            <div className="nav-responsive">
              <div className="nav nav-pills nav-child-2 position-relative text-center defualt-custom-pill-transition">
                <a
                  className={
                    pageState?.activePill ===
                    Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM1
                      ? "nav-link col active"
                      : "nav-link col"
                  }
                  // onClick={() => {
                  //   setPageState({
                  //     ...pageState,
                  //     activePill:
                  //       Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM1,
                  //   });
                  // }}
                  // data-toggle="tab"
                  // href={"#" + Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM1}
                >
                  {Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM1}
                </a>
              </div>
            </div>
          ) : (
            <div className="nav-responsive">
              <div className="nav nav-pills nav-child-2 position-relative text-center defualt-custom-pill-transition">
                <a
                  className={
                    pageState?.activePill ===
                    Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM1
                      ? "nav-link col active"
                      : "nav-link col"
                  }
                  onClick={() => {
                    setPageState({
                      ...pageState,
                      activePill:
                        Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM1,
                    });
                  }}
                  data-toggle="tab"
                  href={"#" + Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM1}
                >
                  {Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM1}
                </a>
                <a
                  className={
                    pageState?.activePill ===
                    Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM2
                      ? "nav-link col active"
                      : "nav-link col"
                  }
                  onClick={() => {
                    setPageState({
                      ...pageState,
                      activePill:
                        Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM2,
                    });
                  }}
                  data-toggle="tab"
                  href={"#" + Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM2}
                >
                  {Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM2}
                </a>
                <hr className="tab-active defualt-custom-pill-transition" />
              </div>
            </div>
          )}
        </div>
        {pageState?.activePill ===
        Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM2 ? (
          <div className="workorder-header-search-group-section">
            <div
              className={`${
                globalState?.sidebarState ? "col-xxl-12" : "col-xxl-10"
              } col-xl-12 wrapper-workorder-header-search-group`}
            >
              <div className="workorder-header-search-group-row-section">
                {Constent.BODY_HEADER_VIEW_WORKORDER_REPORT_SEARCHCARD}
                <SearchIcon stroke={"#CCCCCC"} />
              </div>

              <div className="workorder-header-search-group-row-section view-workorder-report-header-search-group-row-section">
                <div className="view-workorder-report-search-group-section">
                  <div className="view-workorder-report-serch-item-section">
                    <div className="workorder-search-inner-group-section">
                      <div
                        className={`form-group mb-0 custom-input-form-group ${
                          pageState?.formDateError?.length > 0 &&
                          "custom-invalid-form-group"
                        }`}
                      >
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="formDate"
                            placeholder=" "
                            value={pageState?.workOrderReportFromDate}
                            onChange={(e) => {
                              setPageState({
                                ...pageState,
                                workOrderReportFromDate: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="formDate">
                            {Constent.DATE_RANGE_INPUT_ITEM_LABEL1}
                          </label>
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <CalendarIcon fill={"#333333"} />
                            </span>
                            <div className="input-group-append-date-picker-section">
                              <DatePicker
                                selected={moment()}
                                onChange={(date) => {
                                  setPageState({
                                    ...pageState,
                                    workOrderReportFromDate:
                                      moment(date)?.format("YYYY-MM-DD"),
                                  });
                                }}
                                maxDate={new Date()}
                              />
                            </div>
                          </div>
                        </div>
                        <span className="invalid-msg-text">
                          {pageState?.formDateError}
                        </span>
                      </div>
                    </div>
                    <div className="workorder-search-inner-group-section">
                      <div
                        className={`form-group mb-0 custom-input-form-group ${
                          pageState?.toDateError?.length > 0 &&
                          "custom-invalid-form-group"
                        }`}
                      >
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="toDate"
                            placeholder=" "
                            pattern="[0-9]{4,6}"
                            value={pageState?.workOrderReportToDate}
                            onChange={(e) => {
                              setPageState({
                                ...pageState,
                                workOrderReportToDate: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="toDate">
                            {Constent.DATE_RANGE_INPUT_ITEM_LABEL2}
                          </label>
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <CalendarIcon fill={"#333333"} />
                            </span>
                            <div className="input-group-append-date-picker-section">
                              <DatePicker
                                selected={moment()}
                                onChange={(date) => {
                                  setPageState({
                                    ...pageState,
                                    workOrderReportToDate:
                                      moment(date)?.format("YYYY-MM-DD"),
                                  });
                                }}
                                maxDate={new Date()}
                              />
                            </div>
                          </div>
                        </div>
                        <span className="invalid-msg-text">
                          {pageState?.toDateError}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="workorder-search-group-section"
                  onClick={reportExtractionAPICall}
                >
                  <SearchIcon />
                  {Constent.SEARCH_BTN}
                </div>
              </div>
              <div className="workorder-header-search-group-row-section">
                <div className="workorder-header-advance-search-section">
                  <span
                    data-toggle="modal"
                    data-target="#viewWorkOrderReportAdvanceSearch"
                  >
                    {Constent.BODY_HEADER_DASHBORD_BANNAR_SUB_TEXT1}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="workorder-header-search-group-section">
            <div
              className={`${
                globalState?.sidebarState ? "col-xxl-12" : "col-xxl-10"
              } col-xl-12 wrapper-workorder-header-search-group`}
            >
              <div className="workorder-header-search-group-row-section">
                {Constent.HEADER_MENU_ITEM1}
                <SearchIcon stroke={"#CCCCCC"} />
              </div>
              <div className="workorder-header-search-group-row-section">
                <div className="workorder-search-group-section">
                  <div className="form-group mb-0 custom-input-form-group ">
                    <input
                      type={
                        searchType === Constent.WORK_ORDER_INPUT_ITEM_LABEL1
                          ? "number"
                          : "text"
                      }
                      className="form-control"
                      name={Constent.WORK_ORDER_INPUT_ITEM_LABEL1}
                      value={pageState?.workOrderId}
                      id="workorderId"
                      placeholder=" "
                      onChange={(e) => {
                        setPageState({
                          ...pageState,
                          workOrderId: e.target.value,
                        });
                        dispatch(
                          workOrderSearchPageState({
                            type: ApiConstant.WORK_ORDER_ID,
                            value: e.target.value,
                          })
                        );
                      }}
                    />
                    <label htmlFor="workorderId">{searchType}</label>
                    <span className="invalid-msg-text">
                      {Constent.INVALID_INPUT}
                    </span>
                  </div>
                </div>
                <div className="workorder-search-group-section">
                  {Constent.FREE_CONTENT_TEXT2}
                </div>
                <div className="workorder-search-group-section">
                  <div className="workorder-search-inner-group-section">
                    <div className="form-group mb-0 custom-input-form-group">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="formDate"
                          placeholder=" "
                          value={pageState?.workOrderFromDate}
                          onChange={(e) => {
                            setPageState({
                              ...pageState,
                              workOrderFromDate: e.target.value,
                            });
                            dispatch(
                              workOrderSearchPageState({
                                type: ApiConstant.WORK_ORDER_FROM_DATE,
                                value: e.target.value,
                              })
                            );
                          }}
                        />
                        <label htmlFor="formDate">
                          {Constent.DATE_RANGE_INPUT_ITEM_LABEL1}
                        </label>
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <CalendarIcon fill={"#333333"} />
                          </span>
                          <div className="input-group-append-date-picker-section">
                            <DatePicker
                              selected={moment()}
                              onChange={(date) => {
                                setPageState({
                                  ...pageState,
                                  workOrderFromDate:
                                    moment(date)?.format("YYYY-MM-DD"),
                                });
                                dispatch(
                                  workOrderSearchPageState({
                                    type: ApiConstant.WORK_ORDER_FROM_DATE,
                                    value: moment(date)?.format("YYYY-MM-DD"),
                                  })
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <span className="invalid-msg-text">
                        {Constent.INVALID_INPUT}
                      </span>
                    </div>
                  </div>
                  <div className="workorder-search-inner-group-section">
                    <div className="form-group mb-0 custom-input-form-group">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="toDate"
                          placeholder=" "
                          pattern="[0-9]{4,6}"
                          value={pageState?.workOrderToDate}
                          onChange={(e) => {
                            setPageState({
                              ...pageState,
                              workOrderToDate: e.target.value,
                            });
                            dispatch(
                              workOrderSearchPageState({
                                type: ApiConstant.WORK_ORDER_TO_DATE,
                                value: e.target.value,
                              })
                            );
                          }}
                        />
                        <label htmlFor="toDate">
                          {Constent.DATE_RANGE_INPUT_ITEM_LABEL2}
                        </label>
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <CalendarIcon fill={"#333333"} />
                          </span>
                          <div className="input-group-append-date-picker-section">
                            <DatePicker
                              selected={moment()}
                              onChange={(date) => {
                                setPageState({
                                  ...pageState,
                                  workOrderToDate:
                                    moment(date)?.format("YYYY-MM-DD"),
                                });
                                dispatch(
                                  workOrderSearchPageState({
                                    type: ApiConstant.WORK_ORDER_TO_DATE,
                                    value: moment(date)?.format("YYYY-MM-DD"),
                                  })
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <span className="invalid-msg-text">
                        {Constent.INVALID_INPUT}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="workorder-search-group-section"
                  onClick={searchWorkOrderListApiCall}
                >
                  <SearchIcon />
                  {Constent.SEARCH_BTN}
                </div>
              </div>
              <div className="workorder-header-search-group-row-section">
                <div className="workorder-header-advance-search-section">
                  <span
                    data-toggle="modal"
                    data-target="#workOrderAdvanceSearch"
                  >
                    {Constent.BODY_HEADER_DASHBORD_BANNAR_SUB_TEXT1}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {
        // filterList?.length > 0 &&
        <div className="dashbord-header-filter-section">
          <div className="dashbord-header-filter-itme-info d-flex">
            <div className="order-header-filter-item-info">
              {
                // filterList?.length > 0 &&
                <strong>{`${
                  pageState?.activePill ===
                  Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM1
                    ? filterList?.length
                    : pageState?.workOrderListData?.length
                } - ${Constent.HEADER_MENU_ITEM1}`}</strong>
              }
            </div>
            <div className="order-header-download-item-section">
              {((pageState?.activePill ===
                Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM1 &&
                filterList?.length > 0) ||
                (pageState?.activePill ===
                  Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM2 &&
                  pageState?.workOrderListData?.length > 0)) && (
                <div
                  className="item-list-download-btn-section"
                  onClick={() => {
                    // if (
                    //   userDataObj?.userData?.activeUserData?.userRole ===
                    //   Constent.INSTALLER_ROLE
                    // ) {
                    //   downloadExcelFun();
                    // } else {
                    //   navigate("/viewworkorderreport");
                    // }
                    downloadExcelFun();
                  }}
                >
                  <>
                    <span>{Constent.DOWNLOAD_BTN}</span>
                    <DownloadIcon />
                  </>
                  {/* {userDataObj?.userData?.activeUserData?.userRole ===
                  Constent.INSTALLER_ROLE ? (
                    <>
                      <span>{Constent.DOWNLOAD_BTN}</span>
                      <DownloadIcon />
                    </>
                  )
                  : (
                    <span>{Constent.BODY_HEADER_VIEW_WORKORDER_REPORT}</span>
                  )} */}
                </div>
              )}
            </div>
          </div>
          {/* <div className="work-order-search-filter-item-section d-flex">
                        <div className="form-group mb-0 custom-transparent-form-group custom-white-color-form-group">
                            <select className="custom-select bg-white" onChange={(e) => {
                                setPageState({
                                    ...pageState, bodyview: e.target.value,
                                    toggleIcon: e.target.value === "Month" ?
                                        "Calendar" : pageState?.toggleIcon === "List" ? "List" : "Card"
                                })
                            }}>
                                <option defaultValue="">Month</option>
                                <option value="Day">Day</option>
                                <option value="Month">Month</option>
                            </select>
                            <label htmlFor="exampleSelect1">By</label>
                        </div>
                        <div className="form-group mb-0 custom-transparent-form-group custom-white-color-form-group">
                            <select className="custom-select bg-white" onChange={(e) => {
                                setPageState({
                                    ...pageState, bodyview: e.target.value,
                                    toggleIcon: e.target.value === "Month" ?
                                        "Calendar" : pageState?.toggleIcon === "List" ? "List" : "Card"
                                })
                            }}>
                                <option defaultValue="">Month</option>
                                <option value="Day">Day</option>
                                <option value="Month">Month</option>
                            </select>
                            <label htmlFor="exampleSelect1">By</label>
                        </div>
                    </div> */}
        </div>
      }
    </div>
  );
};

export default SearchActivityBodyHeader;
