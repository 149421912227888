import { createSlice } from "@reduxjs/toolkit";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";

const initialState = {
  manageStockList: [],
  requestStockList: [],
  trackRequestStockList: [],
  trackReturnStockList: [],
  trackTransferStockList: [],
  transferSerialNumberObj: {},

  brandLov: [],

  viewStockCategoryValue: "",
  viewStockBrandValue: "",
  viewStockInputValue: "",

  requestorOutletName: "",
  destinationOutletName: "",
  destinationOutletContact: "",
  requestorOutletContactNo: "",
  destinationOutletAdd: "",
  requestorOutletAdd: "",

  trackStockRequestId: "",
  trackStockFormData: "",
  trackStockToData: "",
  trackStockRequestStatus: "",

  selectedViewStockItem: null,
  setSelectedViewStockItemData: {},

  selectedTrackStockItem: null,
  selectedTrackStockItemDetails: null,

  trackStockDetailsApproverComment: "",
  adminTrackStockConfirmMsg: "",

  acknowledgeTotalAvailableItems: null,
  acknowledgeReceivedItems: null,
  acknowledgeSelectedSerialNumbersList: null,
  supervisorTrackStockConfirmMsg: "",
};

export const InventoryDataState = createSlice({
  name: "inventoryDataState",
  initialState,
  reducers: {
    // Creating the Actions
    setStockListData: (state, action) => {
      const { list, type } = action.payload;
      switch (type) {
        case ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE1:
          return { ...state, manageStockList: list };
        case ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE2:
          list?.map((data) => (data["reqQuantity"] = 0));
          return { ...state, requestStockList: list };
        case ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE3:
          return { ...state, brandLov: list };

        case ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE4:
          return { ...state, trackRequestStockList: list };
        case ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE5:
          return { ...state, trackReturnStockList: list };
        case ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE6:
          return { ...state, trackTransferStockList: list };

        default:
          return { ...state };
      }
    },
    setViewStockFieldValue: (state, action) => {
      const { type, value } = action.payload;
      switch (type) {
        case "viewStockCategoryValue":
          return { ...state, viewStockCategoryValue: value };
        case "viewStockBrandValue":
          return { ...state, viewStockBrandValue: value };
        case "viewStockInputValue":
          return {
            ...state,
            viewStockInputValue: value,
            viewStockCategoryValue: "",
            viewStockBrandValue: "",
          };
        default:
          return { ...state };
      }
    },

    setViewTransferStockFieldValue: (state, action) => {
      const { type, value } = action.payload;
      switch (type) {
        case "requestorOutletName":
          return { ...state, requestorOutletName: value };
        case "destinationOutletName":
          return { ...state, destinationOutletName: value };
        case "destinationOutletContact":
          return { ...state, destinationOutletContact: value };
        case "requestorOutletContactNo":
          return { ...state, requestorOutletContactNo: value };
        case "destinationOutletAdd":
          return { ...state, destinationOutletAdd: value };
        case "requestorOutletAdd":
          return { ...state, requestorOutletAdd: value };
        default:
          return { ...state };
      }
    },

    setTrackStockFieldValue: (state, action) => {
      const { type, value } = action.payload;
      switch (type) {
        case ApiConstant.INVENTORY_TRACK_STOCK_REQUEST_ID_VALUE:
          return { ...state, trackStockRequestId: value };
        case ApiConstant.INVENTORY_TRACK_STOCK_FORM_DATE_VALUE:
          return { ...state, trackStockFormData: value };
        case ApiConstant.INVENTORY_TRACK_STOCK_TO_DATE_VALUE:
          return { ...state, trackStockToData: value };
        case ApiConstant.INVENTORY_TRACK_STOCK_REQUEST_STATUS_VALUE:
          return { ...state, trackStockRequestStatus: value };

        case ApiConstant.INVENTORY_TRACK_STOCK_SELECTED_DATA_ITEM:
          return { ...state, selectedTrackStockItem: value };

        case ApiConstant.INVENTORY_TRACK_STOCK_SELECTED_DATA_ITEM_DETAILS:
          return { ...state, selectedTrackStockItemDetails: value };

        case ApiConstant.INVENTORY_TRACK_STOCK_SELECTED_DATA_ITEM_DETAILS_APPROVE_QNT_UPDATE:
          const { sapMaterialCode, approvedQuantity, requestedQuantity } =
            value;

          const index =
            state.selectedTrackStockItemDetails?.itemDetails?.findIndex(
              (obj) => obj?.sapMaterialCode === sapMaterialCode
            );
          if (index !== -1) {
            const apvQunt =
              parseInt(requestedQuantity) > parseInt(approvedQuantity)
                ? parseInt(approvedQuantity)
                : parseInt(requestedQuantity);
            state.selectedTrackStockItemDetails.itemDetails[index] = {
              ...state.selectedTrackStockItemDetails.itemDetails[index],
              approvedQuantity: apvQunt,
            };
          }
          break;
        case ApiConstant.ACKNOWLEDGE_EMPTY_CHECKBOX_SELECTION:
          return {
            ...state,
            acknowledgeSelectedSerialNumbersList: {
              ...state.acknowledgeSelectedSerialNumbersList,
              [value]: [],
            },
          };

        case ApiConstant.ACKNOWLEDGE_CHECKBOX_SELECTION: {
          const { materialCode, serialNumber } = value;

          state.acknowledgeSelectedSerialNumbersList[materialCode] = [
            ...state.acknowledgeSelectedSerialNumbersList[materialCode],
            serialNumber,
          ];
          break;
        }
        case ApiConstant.ACKNOWLEDGE_CHECKBOX_UNSELECTION: {
          const { materialCode, serialNumber } = value;
          state.acknowledgeSelectedSerialNumbersList[materialCode] =
            state.acknowledgeSelectedSerialNumbersList[materialCode].filter(
              (item) => item !== serialNumber
            );
          break;
        }

        case ApiConstant.ACKNOWLEDGE_TOTAL_ITEMS:
          return { ...state, acknowledgeTotalAvailableItems: value };
        case ApiConstant.ACKNOWLEDGE_RECEIVED_ITEMS:
          return { ...state, acknowledgeReceivedItems: value };
        case ApiConstant.INVENTORY_TRACK_STOCK_RESET_SELECTED_DATA_ITEM:
          return {
            ...state,
            selectedTrackStockItem: null,
            selectedTrackStockItemDetails: null,
            adminTrackStockConfirmMsg: null,
            acknowledgeTotalAvailableItems: null,
            acknowledgeReceivedItems: null,
            acknowledgeSelectedSerialNumbersList: null,
          };
        case ApiConstant.TRACK_STOCK_APPROVER_COMMENT:
          return { ...state, trackStockDetailsApproverComment: value };
        case ApiConstant.ADMIN_TRACK_STOCK_CONFIRMATION_MSG:
          return { ...state, adminTrackStockConfirmMsg: value };
        case ApiConstant.SUPERVISOR_TRACK_STOCK_CONFIRMATION_MSG:
          return { ...state, supervisorTrackStockConfirmMsg: value };
        default:
          return { ...state };
      }
    },
    setViewRequestStockQuntData: (state, action) => {
      const { materialCode, qunt, totalAvailableQty } = action.payload;

      const index = state?.requestStockList?.findIndex(
        (obj) => obj?.sAPMaterialCode === materialCode
      );

      if (index !== -1) {
        state.requestStockList[index] = {
          ...state.requestStockList[index],
          reqQuantity:
            totalAvailableQty > parseInt(qunt)
              ? parseInt(qunt)
              : totalAvailableQty,
        };
      }
    },
    selectedViewStockItem: (state, action) => {
      return { ...state, selectedViewStockItem: action.payload };
    },
    setSelectedViewStockItemData: (state, action) => {
      return { ...state, setSelectedViewStockItemData: action.payload };
    },
    setTrackRequestStockListData: (state, action) => {
      return { ...state, trackRequestStockList: action.payload };
    },
    setTrackReturnStockListData: (state, action) => {
      return { ...state, trackReturnStockList: action.payload };
    },
    setTrackTransferStockListData: (state, action) => {
      return { ...state, trackTransferStockList: action.payload };
    },

    setTransferStockSerialNumberData: (state, action) => {
      var { type, value } = action.payload;
      return {
        ...state,
        transferSerialNumberObj: value,
      };
    },
    transferStockSerialNumberData: (state, action) => {
      const { materialCode, data } = action.payload;
      // switch (type) {
      // case ApiConstant.CART_REDUX_RETURN_SERIAL_NUMBER_ADD_VALUE:

      return {
        ...state,
        transferSerialNumberObj: {
          ...state.transferSerialNumberObj,
          [materialCode]: [
            ...new Map(
              data
                ?.filter((item) => {
                  if (item?.valid === "valid") {
                    return item;
                  }
                })
                .map((item) => [item["serialNumber"], item])
            ).values(),
          ],
        },
      };
    },

    removeSelectedTransferStockData: (state, action) => {
      let recivedData = action.payload;

      const index = state.selectedTransferStockList?.findIndex(
        (obj) => obj?.sapMaterialCode === recivedData?.sapMaterialCode
      );

      // Data is present
      index !== -1 && state.selectedTransferStockList?.splice(index, 1);

      return state;
    },

    removeTransferStockData: (state, action) => {
      let recivedData = action.payload;

      const index = state.trackRequestStockList?.findIndex(
        (obj) => obj?.sapMaterialCode === recivedData?.sapMaterialCode
      );

      // Data is present
      state.trackRequestStockList?.splice(index, 1);
      delete state?.transferSerialNumberObj[recivedData?.sapMaterialCode];

      return state;
    },
    /* ---- Reset Data ---- */
    resetInvData: () => initialState,
  },
});

export const {
  setStockListData,
  setViewRequestStockQuntData,
  removeTransferStockData,
  setTransferStockSerialNumberData,
  removeSelectedTransferStockData,
  setTrackStockFieldValue,
  selectedViewStockItem,
  setSelectedViewStockItemData,
  transferStockSerialNumberData,
  setViewStockFieldValue,
  setTrackRequestStockListData,
  setViewTransferStockFieldValue,
  setTrackReturnStockListData,
  resetInvData,
} = InventoryDataState.actions;

export default InventoryDataState.reducer;
