import React,{ useState } from 'react';
import { Constent } from '../../../../../Assets/Constants/Constent';
import { AfterCopyIcon, ConfirmTickIcon, CopyIcon } from '../../../../../Assets/Images/CelcomSvgImages';
import { ConfirmationBackBtn } from '../../../../Common/Button';

const ManageInstallerConfirmationBodyContent = ({ pageState, installerDataObj, userDataObj }) => {
    const [copyState, setCopyState] = useState(false);

    return (
        <div className='body-content-section pt-0'>
            <div className="assign-workorder-confirmation-section manage-installer-confirmation-section">
                <div className="workorder-confirmation-section"></div>
                <div className="workorder-confirmation-section">
                    <div className="workorder-confirmation-content-section">
                        <ConfirmTickIcon />
                        <div className="wrapper-workorder-confirmation-content-section">
                            <div className="confirmation-content-item mt-3">
                                {`${Constent.BODY_HEADER_DASHBORD_BANNAR_SUB_TEXT} ${userDataObj?.userData?.activeUserData?.userData?.firstName}`}
                            </div>
                            <div className="confirmation-content-item mt-1">
                                {pageState?.displayMsg}
                            </div>
                            <div className="confirmation-content-item mt-1">
                                ID: <span>{installerDataObj?.addInstallerResponse?.status?.split(":")[1] || installerDataObj?.installerNameOrContact}</span>
                                &nbsp;&nbsp;
                                <span role="button" onClick={() => (navigator.clipboard.writeText(installerDataObj?.addInstallerResponse?.status?.split(":")[1]), setCopyState(true))}> 
                                    {copyState? <AfterCopyIcon/>:<CopyIcon />}
                                    <span className="confirmation-content-item mt-1 mb-4"></span> 
                                </span>
                            </div>
                        </div>
                        <ConfirmationBackBtn btnItemName={Constent.HEADER_MENU_ITEM3} btnRoute={"/manageinstaller"} />
                    </div>
                </div>
                <div className="workorder-confirmation-section"></div>
            </div>
        </div>
    )
}

export default ManageInstallerConfirmationBodyContent;