import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ApiConstant } from "../../../../Assets/Constants/ApiConstants";
import { Constent } from "../../../../Assets/Constants/Constent";
import { AddIcon, SubIcon } from "../../../../Assets/Images/CelcomSvgImages";
import { setTrackStockFieldValue } from "../../../../ReduxFeature/StateFeatures/InventoryDataState";
import { CardItemGroup } from "../../../Common/CardContent";

const AdminTrackStockRequestDetailsCard = ({ data, index }) => {
  const dispatch = useDispatch();
  const [currentQty, setCurrentQty] = useState(data?.requestedQuantity);

  useEffect(() => {
    dispatch(
      setTrackStockFieldValue({
        type: ApiConstant.INVENTORY_TRACK_STOCK_SELECTED_DATA_ITEM_DETAILS_APPROVE_QNT_UPDATE,
        value: {
          sapMaterialCode: data?.sapMaterialCode,
          approvedQuantity: data?.requestedQuantity,
          requestedQuantity: data?.requestedQuantity,
        },
      })
    );
  }, []);

  return (
    <div className="track-stock-request-details-card-section">
      <div className="wrapper-track-stock-request-details-card-section">
        <div className="normal-card-item-section">
          <div className="card-item-group">
            {index.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </div>
        </div>
        <div className="normal-card-item-section">
          <CardItemGroup
            title={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE2}
            value={data?.deviceModelDesc || "-"}
          />
          <CardItemGroup
            title={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE1}
            value={data?.sapMaterialCode || "-"}
          />
          <CardItemGroup
            title={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE9}
            value={data?.requestedQuantity || "-"}
          />
        </div>
        <div className="normal-card-item-section">
          <div className="normal-card-item-add-qnt-section">
            <div className="normal-card-item-qnt-title">
              {Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE10}
            </div>
            <div className="card-item-qnt-section">
              <div
                className="card-item-qnt-btn"
                onClick={() => {
                  setCurrentQty(currentQty != 0 ? currentQty - 1 : currentQty);
                  dispatch(
                    setTrackStockFieldValue({
                      type: ApiConstant.INVENTORY_TRACK_STOCK_SELECTED_DATA_ITEM_DETAILS_APPROVE_QNT_UPDATE,
                      value: {
                        sapMaterialCode: data?.sapMaterialCode,
                        approvedQuantity:
                          currentQty != 0 ? currentQty - 1 : currentQty,
                        requestedQuantity: data?.requestedQuantity,
                      },
                    })
                  );
                }}
              >
                <SubIcon />
              </div>
              <input
                className="card-item-qnt-input"
                value={currentQty || 0}
                onChange={(e) => {
                  setCurrentQty(e.target.value);
                  dispatch(
                    setTrackStockFieldValue({
                      type: ApiConstant.INVENTORY_TRACK_STOCK_SELECTED_DATA_ITEM_DETAILS_APPROVE_QNT_UPDATE,
                      value: {
                        sapMaterialCode: data?.sapMaterialCode,
                        approvedQuantity: e.target.value,
                        requestedQuantity: data?.requestedQuantity,
                      },
                    })
                  );
                }}
                type="number"
              />
              <div
                className="card-item-qnt-btn"
                onClick={() => {
                  setCurrentQty(
                    currentQty != data?.requestedQuantity
                      ? currentQty + 1
                      : currentQty
                  );
                  dispatch(
                    setTrackStockFieldValue({
                      type: ApiConstant.INVENTORY_TRACK_STOCK_SELECTED_DATA_ITEM_DETAILS_APPROVE_QNT_UPDATE,
                      value: {
                        sapMaterialCode: data?.sapMaterialCode,
                        approvedQuantity:
                          currentQty != data?.requestedQuantity
                            ? currentQty + 1
                            : currentQty,
                        requestedQuantity: data?.requestedQuantity,
                      },
                    })
                  );
                }}
              >
                <AddIcon stroke={"#009bdf"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTrackStockRequestDetailsCard;
