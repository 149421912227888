import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { ApiConstant } from '../../../../../Assets/Constants/ApiConstants';
import { Constent } from '../../../../../Assets/Constants/Constent';
import { OtherImages } from '../../../../../Assets/Images/CelcomOtherImages';
import { AfterCopyIcon, ClockIcon, ConfirmTickIcon, CopyIcon, DownloadIcon } from '../../../../../Assets/Images/CelcomSvgImages';
import { ConfirmationBackBtn, DownloadBtn } from '../../../../Common/Button';

const WorkOrderStatusUpdateConfirmationBodyContent = ({ userDataObj }) => {

    const workOrderDataObj = useSelector((state) => state.workOrderInfo);
    const [downloadBtnState, setDownloadBtnState] = useState(false);
    const [copyState, setCopyState] = useState(false);
    const componentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            setDownloadBtnState(false);
        }
    });

    const downloadPdfFun = () => {
        setDownloadBtnState(true);
    }
    useEffect(() => {
        if (downloadBtnState) {
            handlePrint();
        }
    }, [downloadBtnState]);

    return (
        <div className='body-content-section pt-0' ref={componentRef}>
            <div className="assign-workorder-confirmation-section update-workorder-confirmation-section">
                <div className="workorder-confirmation-section"></div>
                <div className="workorder-confirmation-section">
                    {
                        downloadBtnState && <img src={OtherImages.CelcomLogo} style={{ right: 24, top: 20 }} className="position-absolute" />
                    }
                    {
                        !downloadBtnState && <div className="download-btn-section" onClick={downloadPdfFun}>
                            <span>{Constent.DOWNLOAD_BTN}</span>
                            <DownloadIcon />
                        </div>
                    }
                    <div className={`workorder-confirmation-content-section ${downloadBtnState ? "pt-6" : ""}`}>
                        <ConfirmTickIcon />
                        <div className="wrapper-workorder-confirmation-content-section">
                            <div className="confirmation-content-item mt-2">
                                {`${Constent.BODY_HEADER_DASHBORD_BANNAR_SUB_TEXT} ${userDataObj?.userData?.activeUserData?.userData?.firstName || "-"}`}
                            </div>
                            <div className="confirmation-content-item">
                                {workOrderDataObj?.workOrderStatus === ApiConstant.WOKR_ORDER_RETURNED ?
                                    Constent.UPDATE_WORK_ORDER_CONFIRMATION_CONTENT_ITEM3
                                    :
                                    Constent.UPDATE_WORK_ORDER_CONFIRMATION_CONTENT_ITEM1}
                            </div>
                            <div className="confirmation-content-item confirmation-content-item-ost">
                                {`${Constent.WORK_ORDER_DETAILS_CONTENT_TITLE1} `}
                                <span>{workOrderDataObj?.workOrderDetails?.itemID?.replaceAll("FieldActivity-", "") || "-"}</span>
                                &nbsp;&nbsp;
                                <span role="button" onClick={() => (navigator.clipboard.writeText(workOrderDataObj?.workOrderDetails?.itemID?.replaceAll("FieldActivity-", "") || "-"), setCopyState(true))}>
                                    {copyState ? <AfterCopyIcon /> : <CopyIcon />}
                                    <span className="confirmation-content-item mt-1 mb-4"></span>
                                </span>
                            </div>

                            {
                                (workOrderDataObj?.ostOrderId !== "" && workOrderDataObj?.ostOrderId !== null && workOrderDataObj?.ostOrderId?.length > 0) ?
                                    <div className="confirmation-content-item confirmation-content-item-ost">
                                        {`${Constent.TRACK_ORDER_DETAILS_CONTENT_TITLE1} `}
                                        <span>{workOrderDataObj?.ostOrderId || "-"}</span>
                                        &nbsp;&nbsp;
                                        <span role="button" onClick={() => (navigator.clipboard.writeText(workOrderDataObj?.ostOrderId?.replaceAll("FieldActivity-", "") || "-"), setCopyState(true))}>
                                            {copyState ? <AfterCopyIcon /> : <CopyIcon />}
                                            <span className="confirmation-content-item mt-1 mb-4"></span>
                                        </span>
                                    </div>
                                    :
                                    <></>
                            }
                        </div>
                        <div className="wrapper-workorder-confirmation-content-section wrapper-update-workorder-confirmation-content-section mt-3">
                            <div className="confirmation-content-item">
                                <ClockIcon />
                            </div>
                            <div className="confirmation-content-item mb-1">
                                {Constent.UPDATE_WORK_ORDER_CONFIRMATION_CONTENT_ITEM2}
                            </div>
                            <div className="confirmation-content-item mb-3">
                                <div className="confirmation-content-inner-item-group">
                                    {workOrderDataObj?.workOrderClosingTime?.split(" ")[0] || moment().format("YYYY-MM-DD")}
                                </div>
                                <div className="confirmation-content-inner-item-group">
                                    {workOrderDataObj?.workOrderClosingTime?.split(" ")[1] || moment().format("HH:mm")}
                                </div>
                            </div>
                        </div>
                        {
                            !downloadBtnState && <ConfirmationBackBtn btnItemName={Constent.HEADER_MENU_ITEM1} btnRoute={"/workorder"} />
                        }
                    </div>
                </div>
                <div className="workorder-confirmation-section"></div>
            </div>
        </div>
    )
}

export default WorkOrderStatusUpdateConfirmationBodyContent;