import moment from 'moment';
import React, { useState } from 'react'
import DatePicker from 'react-date-picker';
import { useDispatch, useSelector } from 'react-redux';
import { ApiConstant } from '../../../../Assets/Constants/ApiConstants';
import { Constent } from '../../../../Assets/Constants/Constent';
import { DropDownIcon } from '../../../../Assets/Images/CelcomSvgImages';
import { saveSummaryData } from '../../../../ReduxFeature/StateFeatures/WorkOrderDataState';
import { BodyBreadcrumbs } from '../../../Common/BodyBreadcrumbs';
import { HeaderNavChild2 } from '../../../Common/HeaderNav';

const WorkOrderSummaryBodyHeader = ({ pageState, setPageState }) => {
    const workOrderObj = useSelector((state) => state.workOrderInfo);
    const [monthPicker, setMonthPicker] = useState(false);
    const dispatch = useDispatch();

    const { summaryDate, summarySctivePill } = workOrderObj;

    return (
        <div className='work-order-summary-details-header-start-section'>
            <div className="wrapper-work-order-summary-details-header-section">
                <BodyBreadcrumbs activeItem={Constent.BODY_HEADER_ON_SITE_SUMMARY} />
                <div className="work-order-summary-details-header-nav-section">
                    <div className="wrapper-work-order-summary-details-header-nav-section"></div>
                    <div className="wrapper-work-order-summary-details-header-nav-section">
                        <div className="work-order-summary-item-group-section">
                            <div className="work-order-summary-item-group">
                                {Constent.BODY_HEADER_ON_SITE_SUMMARY}
                            </div>
                            <div className="work-order-summary-item-group" onClick={() => {
                                setMonthPicker(!monthPicker);
                            }}>
                                <div className="work-order-summary-item-section">
                                    <div className="work-order-summary-item">
                                        <div className="work-order-summary-item-title">
                                            {Constent.CUSTOM_MONTH_PICKER_LABLE}
                                        </div>
                                        <div className="work-order-summary-item-value">
                                            {moment(summaryDate)?.format("MMMM, YYYY")}
                                        </div>
                                    </div>
                                    <div className={`custom-month-picker-item ${monthPicker ? "active" : ""}`}>
                                        <DropDownIcon stroke={"#333333"} />
                                    </div>
                                </div>
                                <div className="month-picker-item-section">
                                    <DatePicker selected={moment()} maxDetail="year"
                                        onCalendarClose={() => {
                                            setMonthPicker(false)
                                        }}
                                        onChange={date => {
                                            dispatch(saveSummaryData({
                                                type: ApiConstant.SAVE_SUMMARY_DATE,
                                                value: date
                                            }))
                                        }} monthAriaLabel="Month" minDetail="decade" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="wrapper-work-order-summary-details-header-nav-section"></div>
                </div>
            </div>
            <div className="wrapper-work-order-summary-details-header-section">
                <div className="empty-block"></div>
                <div className="nav-responsive">
                    <div className="nav nav-pills nav-child-3 position-relative text-center">
                        <a className={summarySctivePill === Constent.SUPERVISOR_SIDEBAR_OSI_VALUE ?
                            "nav-link col active" : "nav-link col"} data-toggle="tab"
                            href={Constent.BODY_HEADER_SUMMARY_PILL_ITEM1} onClick={() => {
                                dispatch(saveSummaryData({
                                    type: ApiConstant.SUMMARY_PILL_ITEM,
                                    value: Constent.SUPERVISOR_SIDEBAR_OSI_VALUE
                                }));
                                setPageState({ ...pageState, activePill: Constent.SUPERVISOR_SIDEBAR_OSI_VALUE })
                            }}>
                            {Constent.BODY_HEADER_SUMMARY_PILL_ITEM1}
                        </a>
                        <a className={summarySctivePill === Constent.SUPERVISOR_SIDEBAR_OST_VALUE ?
                            "nav-link col active" : "nav-link col"} data-toggle="tab"
                            href={Constent.BODY_HEADER_SUMMARY_PILL_ITEM2} onClick={() => {
                                dispatch(saveSummaryData({
                                    type: ApiConstant.SUMMARY_PILL_ITEM,
                                    value: Constent.SUPERVISOR_SIDEBAR_OST_VALUE
                                }));
                                setPageState({ ...pageState, activePill: Constent.SUPERVISOR_SIDEBAR_OST_VALUE })
                            }}>
                            {Constent.BODY_HEADER_SUMMARY_PILL_ITEM2}
                        </a>
                        <a className={summarySctivePill === Constent.SUPERVISOR_SIDEBAR_BTU_VALUE
                            ? "nav-link col active" : "nav-link col"} data-toggle="tab"
                            href={Constent.BODY_HEADER_SUMMARY_PILL_ITEM3} onClick={() => {
                                dispatch(saveSummaryData({
                                    type: ApiConstant.SUMMARY_PILL_ITEM,
                                    value: Constent.SUPERVISOR_SIDEBAR_BTU_VALUE
                                }));
                                setPageState({ ...pageState, activePill: Constent.SUPERVISOR_SIDEBAR_BTU_VALUE })
                            }}>
                            {Constent.BODY_HEADER_SUMMARY_PILL_ITEM3}
                        </a>
                        <hr className="tab-active" />
                    </div>
                </div>
                <div className="empty-block"></div>
            </div>
        </div>
    )
}

export default WorkOrderSummaryBodyHeader;