import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CreateInstaller, headers } from '../../Api/ApiCalls';
import { ApiConstant } from '../../Assets/Constants/ApiConstants';
import { Constent } from '../../Assets/Constants/Constent';
import { CloseModalIcon } from '../../Assets/Images/CelcomSvgImages';
import { setInstallerData } from '../../ReduxFeature/StateFeatures/InstallerDataState';

import { toast } from 'react-toastify';

const AddInstallerModal = ({ userDataObj, lovDataObj, globalState, setPageState, setGlobalState, pageState }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [filteredListDistrict, setFilteredDistrictList] = useState([])
    const [apiStatus, setApiStatus] = useState(false)

    const [addInstallerState, setAddInstallerState] = useState({
        // Create insatller Data
        addInstallerFirstName: "",
        addInstallerLastName: "",
        addInstallerIdType: "",
        addInstallerUserName: "",
        addInstallerIdNo: "",
        addInstallerContact: "",
        addInstallerEmail: "",
        addInstallerState: "",
        addInstallerDistrict: "",
    })
    const [addInstallerStateError, setAddInstallerStateError] = useState({
        // Create insatller Data
        addInstallerFirstName: "0",
        addInstallerLastName: "0",
        addInstallerIdType: "0",
        addInstallerUserName: "0",
        addInstallerIdNo: "0",
        addInstallerContact: "0",
        addInstallerEmail: "0",
        addInstallerState: "0",
        addInstallerDistrict: "0",
    })

    function addInstallerDataApiCall() {

        if (apiStatus) {
            setPageState({ ...pageState, apiCallAction: true, apiCallActionMsg: "Creating..." })
            const header = headers(
                userDataObj?.userData?.activeUserData?.authToken
            );

            const createInstallerRequest = {
                companyName: userDataObj?.userData?.activeUserData?.userData?.outletName,
                district: addInstallerState?.addInstallerDistrict,
                state: addInstallerState?.addInstallerState,
                email: addInstallerState?.addInstallerEmail,
                firstName: addInstallerState?.addInstallerFirstName, id: addInstallerState?.addInstallerIdNo,
                idType: addInstallerState?.addInstallerIdType, lastName: addInstallerState?.addInstallerLastName,
                mobileNo: addInstallerState?.addInstallerContact, outletId: userDataObj?.userData?.activeUserData?.userData?.outletId,
                outletName: userDataObj?.userData?.activeUserData?.userData?.outletName,
                outletType: userDataObj?.userData?.activeUserData?.userData?.outletType,
                partnerId: userDataObj?.userData?.activeUserData?.userData?.partnersapId,
                supervisorId: userDataObj?.userData?.activeUserData?.userData?.userId,
                userId: addInstallerState?.addInstallerUserName
            }

            CreateInstaller("/fibre/installer/create", createInstallerRequest, header).then(response => {
                setPageState({ ...pageState, apiCallAction: false })
                if (response?.status?.toLowerCase()?.includes("User Created With"?.toLowerCase())) {
                    dispatch(setInstallerData({
                        type: ApiConstant.CREATE_INSTALLER_RESPONSE,
                        value: response
                    }));
                    dispatch(setInstallerData({
                        type: ApiConstant.MANAGE_INSTALLER_INPUT,
                        value: addInstallerState?.addInstallerUserName
                    }));
                    navigate("/manageinstaller/confirm");
                }

                else if (response?.status === "FAIL") {
                    toast.error(ApiConstant.GET_INSTALLER_ERROR_MESSAGE_WRONG,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 1000,
                            theme: 'colored'
                        }
                    );

                }
            }).catch(error => {
                setPageState({ ...pageState, apiCallAction: false })
                setGlobalState({
                    ...globalState, createInstallerUserName: "",
                    isInstallerCreated: false
                });

                toast.error(ApiConstant.GET_INSTALLER_ERROR_MESSAGE_WRONG || error?.message,
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 10000,
                        theme: 'colored'
                    }
                );
            })
        }

    }

    const isValidInputInstaller = (e) => {
        if (e.target.name === Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE3) {
            if (e.target.value == null || e.target.value.length <= 0) {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerUserName: "Field is Required" });
            }
            // else if(!pattern.test(e.target.value)){
            //     setAddInstallerStateError({ ...addInstallerStateError, addInstallerUserName: "Cannot have special Charaters" });

            // }
            else if (!isNaN(e.target.value)) {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerUserName: "Cannot be number" });

            }
            else if (e.target.value.length < 8) {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerUserName: "Minimum Length Should be 8" });
            }
            else if (e.target.value.length >= 10) {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerUserName: "Maximum Length Should be 9" });
            }
            else if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,`~.<>\/?]+/.test(e.target.value) || (e.target.value).includes(" ")) {

                setAddInstallerStateError({ ...addInstallerStateError, addInstallerUserName: "No special characters allowed" });
            }
            else {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerUserName: "" });
            }
        }
        if (e.target.name === Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE6) {

            if (e.target.value == null || e.target.value.length <= 0) {

                setAddInstallerStateError({ ...addInstallerStateError, addInstallerIdType: "Field is Required" });
            }
            else {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerIdType: "" });
            }
        }
        if (e.target.name === Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE7) {
            if (e.target.value == null || e.target.value.length <= 0) {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerIdNo: "Field is Required" });
            }
            else {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerIdNo: "" });
            }
        }
        if (e.target.name === Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE10) {
            if (e.target.value == null || e.target.value.length <= 0) {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerEmail: "Field is Required" });
            }
            if (! /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerEmail: "Enter a valid email" });
            }
            else {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerEmail: "" });
            }
        }
        if (e.target.name === Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE11) {
            if (e.target.value == null || e.target.value.length <= 0) {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerFirstName: "Field is Mandatory" });
            }
            else if (!isNaN(e.target.value)) {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerUserName: "Cannot be number" });
            }
            else {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerFirstName: "" });
            }

        }
        if (e.target.name === Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE12) {
            if (e.target.value == null || e.target.value.length <= 0) {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerLastName: "Field is Mandatory" });
            }
            else if (!isNaN(e.target.value)) {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerUserName: "Cannot be number" });
            }
            else {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerLastName: "" });
            }

        }
        if (e.target.name === Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE13) {
            if (e.target.value == null || e.target.value.length <= 0) {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerState: "Field is Mandatory" });
            }
            else {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerState: "" });
            }

        }
        if (e.target.name === Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE4) {
            if (e.target.value == null || e.target.value.length <= 0) {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerDistrict: "Field is Mandatory" });
            }
            else {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerDistrict: "" });
            }

        }
        if (e.target.name === Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE5) {

            if (e.target.value == null || e.target.value.length <= 0) {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerContact: "Field is Mandatory" });
            }
            else if (e.target.value.length < 10) {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerContact: "Please check contact Number" });
            }
            else if (e.target.value.length >= 10 && e.target.value.length <= 20) {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerContact: "" });
            }
            else {
                setAddInstallerStateError({ ...addInstallerStateError, addInstallerContact: "Validate your number" });
            }
        }



    }
    useEffect(() => {
        let enable = true;
        Object.keys(addInstallerStateError)?.map(item => {
            if (addInstallerStateError[item]?.length > 0) {
                enable = false;
            }
        });
        setApiStatus(enable)
    }, [addInstallerStateError])


    return (
        <div className="modal fade installer-data-manage-modal" id="addInstallerModal" data-backdrop="static"
            data-keyboard="false" tabIndex="-1" aria-labelledby="addInstallerModalLabel"
            aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header pb-0">
                        <div className="wrapper-modal-header pt-5">
                            <div className="modal-header-title-section">
                                <h5 className="modal-title" id="addInstallerModalLabel">
                                    {Constent.ADD_INSTALLER_MODAL_TITLE}
                                </h5>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <CloseModalIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="wrapper-modal-body">
                            <div className="mobal-body-section-group">
                                <div className="card-item-group">
                                    <div className={`form-group mb-0 custom-input-form-group custom ${addInstallerStateError.addInstallerUserName === "0" ? "" : addInstallerStateError.addInstallerUserName.length > 1 ? "custom-invalid-form-group" : ""}`}   >
                                        <input type="text" className="form-control" value={addInstallerState?.addInstallerUserName}
                                            id={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE3}
                                            name={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE3}
                                            placeholder=" " onChange={(e) => {
                                                setAddInstallerState({ ...addInstallerState, addInstallerUserName: e.target.value });
                                            }} onBlur={(e) => isValidInputInstaller(e)} />
                                        <label htmlFor={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE3}>
                                            {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE3}
                                            <span className='addinstallerspan'> * </span>
                                        </label>
                                        <span className='invalid-msg-text'>{addInstallerStateError.addInstallerUserName || Constent.INVALID_INPUT}</span>
                                    </div>
                                </div>

                                <div className="card-item-group">
                                    <div className={`form-group mb-0 custom-drop-down-form-group custom ${addInstallerStateError.addInstallerIdType === "0" ? "" : addInstallerStateError.addInstallerIdType.length > 1 ? "" : ""}`} >
                                        <select className="custom-select " value={addInstallerState?.addInstallerIdType}
                                            onChange={(e) => {
                                                setAddInstallerState({ ...addInstallerState, addInstallerIdType: e.target.value });
                                            }}
                                            onBlur={(e) => isValidInputInstaller(e)}
                                            id={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE6}
                                            name={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE6}
                                        >
                                            <option defaultValue="">-- Select --</option>
                                            {
                                                lovDataObj?.idtype?.map((idTypedata, index) => {
                                                    return (
                                                        <option key={`idType${idTypedata?.lovDisplay + index}`}
                                                            value={idTypedata?.lovValue}>
                                                            {idTypedata?.lovDisplay}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <label htmlFor={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE6}>
                                            {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE6}
                                            <span>*</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="mobal-body-section-group">
                                <div className="card-item-group">
                                    <div className={`form-group mb-0 custom-input-form-group custom ${addInstallerStateError.addInstallerIdNo === "0" ? "" : addInstallerStateError.addInstallerIdNo.length > 1 ? "custom-invalid-form-group" : ""}`}>
                                        <input type="text" className="form-control" value={addInstallerState?.addInstallerIdNo}
                                            id={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE7}
                                            name={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE7}
                                            placeholder=" " onChange={(e) => {
                                                setAddInstallerState({ ...addInstallerState, addInstallerIdNo: e.target.value });
                                            }} onBlur={(e) => isValidInputInstaller(e)} />
                                        <label htmlFor={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE7}>
                                            {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE7}
                                            <span className='addinstallerspan'> * </span>
                                        </label>
                                        <span className='invalid-msg-text'>{Constent.INVALID_INPUT}</span>
                                    </div>
                                </div>

                                <div className="card-item-group">
                                    <div className={`form-group mb-0 custom-input-form-group custom ${addInstallerStateError.addInstallerEmail === "0" ? "" : addInstallerStateError.addInstallerEmail.length > 1 ? "custom-invalid-form-group" : ""}`}>
                                        <input type="text" className="form-control" value={addInstallerState?.addInstallerEmail}
                                            onChange={(e) => {
                                                setAddInstallerState({ ...addInstallerState, addInstallerEmail: e.target.value });
                                            }} onBlur={(e) => isValidInputInstaller(e)}
                                            id={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE10}
                                            name={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE10}
                                            placeholder=" " />
                                        <label htmlFor={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE10}>
                                            {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE10}
                                            <span className='addinstallerspan'> * </span>
                                        </label>
                                        <span className='invalid-msg-text'>{addInstallerStateError.addInstallerEmail || Constent.INVALID_INPUT}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="mobal-body-section-group">
                                <div className="card-item-group">
                                    <div className={`form-group mb-0 custom-input-form-group custom ${addInstallerStateError.addInstallerFirstName === "0" ? "" : addInstallerStateError.addInstallerFirstName.length > 1 ? "custom-invalid-form-group" : ""}`}>
                                        <input type="text" className="form-control" value={addInstallerState?.addInstallerFirstName}
                                            id={`${Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE11}`}
                                            name={`${Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE11}`}
                                            placeholder=" " onChange={(e) => {
                                                setAddInstallerState({ ...addInstallerState, addInstallerFirstName: e.target.value });
                                            }} onBlur={(e) => isValidInputInstaller(e)} />
                                        <label htmlFor={`${Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE11}`}>
                                            {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE11}
                                            <span className='addinstallerspan'> * </span>

                                        </label>
                                        <span className='invalid-msg-text'>{Constent.INVALID_INPUT}</span>
                                    </div>
                                </div>
                                <div className="card-item-group">
                                    <div className={`form-group mb-0 custom-input-form-group custom ${addInstallerStateError.addInstallerLastName === "0" ? "" : addInstallerStateError.addInstallerLastName.length > 1 ? "custom-invalid-form-group" : ""}`}>
                                        <input type="text" className="form-control" value={addInstallerState?.addInstallerLastName}
                                            onChange={(e) => {
                                                setAddInstallerState({ ...addInstallerState, addInstallerLastName: e.target.value });
                                            }}
                                            onBlur={(e) => isValidInputInstaller(e)}
                                            id={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE12} placeholder=" "
                                            name={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE12}
                                        />
                                        <label htmlFor={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE12}>
                                            {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE12}
                                            <span className='addinstallerspan'> * </span>

                                        </label>
                                        <span className='invalid-msg-text'>{Constent.INVALID_INPUT}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="mobal-body-section-group">

                                <div className="card-item-group">
                                    <div className={`form-group mb-0 custom-drop-down-form-group custom ${addInstallerStateError.addInstallerState === "0" ? "" : addInstallerStateError.addInstallerState.length > 1 ? "custom-invalid-form-group" : ""}`}>
                                        <select className="custom-select" value={addInstallerState?.addInstallerState}
                                            onChange={(e) => {
                                                const stateIndex = lovDataObj?.state?.findIndex(obj => obj.lovValue === e.target.value);
                                                if (stateIndex !== -1) {
                                                    setFilteredDistrictList(lovDataObj?.state[stateIndex]?.district)
                                                }
                                                setAddInstallerState({ ...addInstallerState, addInstallerState: e.target.value });
                                            }}
                                            onBlur={(e) => isValidInputInstaller(e)}

                                            id={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE13}
                                            name={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE13}
                                        >
                                            <option defaultValue="">-- Select --</option>
                                            {
                                                lovDataObj?.state?.map((districtData, index) => {
                                                    return (
                                                        <option key={`state${districtData?.lovDisplay + index}`}
                                                            value={districtData?.lovValue}>
                                                            {districtData?.lovDisplay}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <label htmlFor={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE13}>
                                            {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE13}
                                            <span>*</span>
                                        </label>
                                    </div>
                                </div>

                                <div className="card-item-group">
                                    <div className={`form-group mb-0 custom-drop-down-form-group custom ${addInstallerStateError.addInstallerDistrict === "0" ? "" : addInstallerStateError.addInstallerDistrict.length > 1 ? "custom-invalid-form-group" : ""}`}>
                                        <select className="custom-select" value={addInstallerState?.addInstallerDistrict}
                                            onChange={(e) => {
                                                setAddInstallerState({ ...addInstallerState, addInstallerDistrict: e.target.value });
                                            }}
                                            onBlur={(e) => isValidInputInstaller(e)}
                                            disabled={filteredListDistrict?.length > 0 ? false : true}
                                            id={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE4}
                                            name={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE4}
                                        >
                                            <option defaultValue="">-- Select --</option>
                                            {
                                                filteredListDistrict?.map((districtData, index) => {
                                                    return (
                                                        <option key={`district${districtData?.lovDisplay + index}`}
                                                            value={districtData?.lovValue}>
                                                            {districtData?.lovDisplay}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <label htmlFor={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE4}>
                                            {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE4}
                                            <span>*</span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div className="mobal-body-section-group">
                                <div className="card-item-group">
                                    <div className={`form-group mb-0 custom-input-form-group custom ${addInstallerStateError.addInstallerContact === "0" ? "" : addInstallerStateError.addInstallerContact.length > 1 ? "custom-invalid-form-group" : ""}`} >
                                        <input type="number" className="form-control" value={addInstallerState?.addInstallerContact}
                                            onChange={(e) => {
                                                setAddInstallerState({ ...addInstallerState, addInstallerContact: e.target.value });
                                            }}
                                            onBlur={(e) => isValidInputInstaller(e)}
                                            id={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE5}
                                            name={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE5}
                                            placeholder=" " />
                                        <label htmlFor={Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE5}>
                                            {Constent.MANAGE_INSTALLER_CARD_ITEM_TITLE5}
                                            <span className='addinstallerspan'> * </span>

                                        </label>
                                        <span className='invalid-msg-text'>{addInstallerStateError.addInstallerContact || Constent.INVALID_INPUT}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="refresh-btn-section" onClick={() => {
                            setAddInstallerState({
                                ...addInstallerState, addInstallerFirstName: "",
                                addInstallerLastName: "",
                                addInstallerIdType: "",
                                addInstallerUserName: "",
                                addInstallerIdNo: "",
                                addInstallerContact: "",
                                addInstallerEmail: "",
                                addInstallerDistrict: "",
                                addInstallerState: "",
                            });
                            setAddInstallerStateError({
                                ...addInstallerStateError, addInstallerFirstName: "0",
                                addInstallerLastName: "0",
                                addInstallerIdType: "0",
                                addInstallerUserName: "0",
                                addInstallerIdNo: "0",
                                addInstallerContact: "0",
                                addInstallerEmail: "0",
                                addInstallerDistrict: "0",
                                addInstallerState: "0",
                            })
                        }}>
                            <span>{Constent.REFRESH_BTN}</span>
                        </div>
                        <div className={`search-btn-section ${apiStatus ? '' : 'inactive-btn'}`}
                            data-dismiss={apiStatus ? "modal" : null}
                            onClick={addInstallerDataApiCall} >
                            {Constent.ADD_BTN}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddInstallerModal;