import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Constent } from "../../../../../Assets/Constants/Constent";
import { updateWorkOrderDetailsData } from "../../../../../ReduxFeature/StateFeatures/WorkOrderDataState";
import { ApiConstant } from "../../../../../Assets/Constants/ApiConstants";
import OSTActivityDropDownContent from "./OSTActivityDropDownContent";

const UpdateWorkOrderDropDownContent = ({
  pageState,
  setPageState,
  workOrderDataObj,
  remark,
  setRemark,
}) => {
  const lovDataObj = useSelector((state) => state.lovDataInfo);
  const dispatch = useDispatch();
  const {
    workOrderStatus,
    returnReasonValue,
    returnRemarksValue,
    returnRemarksFinalValue,
    workOrderDetails,
    activeTab,
  } = workOrderDataObj;
  const { reaturnreason, reaturnremarks } = lovDataObj;

  const workOrderLov =
    activeTab === 1
      ? [
          {
            display: "Completed",
            value: "INST_COMPLETE",
          },
          {
            display: "Returned",
            value: "RETURNED",
          },
        ]
      : [
          {
            display: "Work Order Completed",
            value: "WO_COMPLETE",
          },
        ];

        function remarkVal(e) {
          setRemark(e?.target.value)
        }

  return (
    <div className="update-work-order-content-item-group">
      <div className="update-work-order-content-item">
        <div className="form-group m-0 custom-drop-down-form-group">
          <select
            className="custom-select"
            value={workOrderStatus}
            onChange={(e) => {
              dispatch(
                updateWorkOrderDetailsData({
                  type: ApiConstant.INSTALLER_WORK_ORDER_STATUS,
                  value: e.target.value,
                })
              );
            }}
          >
            <option defaultValue="select" value="">
              --Select--
            </option>
            {workOrderLov?.map((data, index) => {
              return (
                <option key={`workOrder${index}`} value={`${data?.value}`}>
                  {data?.display}
                </option>
              );
            })}
          </select>
          <label htmlFor={Constent.UPDATE_WORK_ORDER_STATUS_INPUT_ITEM_LABEL}>
            {Constent.UPDATE_WORK_ORDER_STATUS_INPUT_ITEM_LABEL}
            <span>*</span>
          </label>
        </div>
        {
          // Based On activity Type
          workOrderStatus === "RETURNED" && (
            <div className="update-work-order-content-inner-item-section">
              <div className="form-group m-0 custom-drop-down-form-group">
                <select
                  className="custom-select"
                  value={returnReasonValue}
                  onChange={(e) => {
                    dispatch(
                      updateWorkOrderDetailsData({
                        type: ApiConstant.LOV_STORE_UPDATE_WORK_ORDER_RETURN_REASON_KEY,
                        value: e.target.value,
                      })
                    );
                  }}
                >
                  <option defaultValue="select" value="select">
                    --Select--
                  </option>
                  {reaturnreason?.map((data, index) => {
                    return (
                      <option
                        key={`${
                          Constent.UPDATE_WORK_ORDER_RETURN_REASON_INPUT_ITEM_LABEL +
                          index
                        }`}
                        value={`${data}`}
                      >
                        {data}
                      </option>
                    );
                  })}
                </select>
                <label
                  htmlFor={
                    Constent.UPDATE_WORK_ORDER_RETURN_REASON_INPUT_ITEM_LABEL
                  }
                >
                  {Constent.UPDATE_WORK_ORDER_RETURN_REASON_INPUT_ITEM_LABEL}
                  <span>*</span>
                </label>
              </div>
              {returnReasonValue?.length > 0 && (
                <div className="form-group m-0 custom-drop-down-form-group">
                  <select
                    className="custom-select"
                    value={returnRemarksValue}
                    onChange={(e) => {
                      dispatch(
                        updateWorkOrderDetailsData({
                          type: ApiConstant.LOV_STORE_UPDATE_WORK_ORDER_RETURN_REMARKS_KEY,
                          value: e.target.value,
                        })
                      );
                    }}
                  >
                    <option value="">--Select--</option>
                    {[...reaturnremarks[returnReasonValue]]
                      ?.sort((a, b) =>
                        parseInt(a.seqno) > parseInt(b.seqno) ? 1 : -1
                      )
                      ?.map((data, index) => {
                        return (
                          <option
                            key={`${
                              Constent.UPDATE_WORK_ORDER_RETURN_REMARKS_INPUT_ITEM_LABEL +
                              index
                            }`}
                            value={`${data?.value}`}
                          >
                            {data?.display}
                          </option>
                        );
                      })}
                  </select>
                  <label
                    htmlFor={
                      Constent.UPDATE_WORK_ORDER_RETURN_REMARKS_INPUT_ITEM_LABEL
                    }
                  >
                    {Constent.UPDATE_WORK_ORDER_RETURN_REMARKS_INPUT_ITEM_LABEL}
                    <span>*</span>
                  </label>
                </div>
              )}

              {returnRemarksValue ===
                Constent.UPDATE_WORK_ORDER_OTHER_REMARS_LABEL && (
                <div className="form-group m-0">
                  <textarea
                    className="form-control"
                    id="returnRemarksTextArea"
                    placeholder=" "
                    spellCheck="false"
                    value={returnRemarksFinalValue}
                    onChange={(e) => {
                      dispatch(
                        updateWorkOrderDetailsData({
                          type: ApiConstant.LOV_STORE_UPDATE_WORK_ORDER_RETURN_REMARKS_INPUT_KEY,
                          value: e.target.value,
                        })
                      );
                    }}
                  ></textarea>
                  <label htmlFor="returnRemarksTextArea">
                    {
                      Constent.UPDATE_WORK_ORDER_RETURN_REMARKS_COMMENT_INPUT_ITEM_LABEL
                    }
                    <span>*</span>
                  </label>
                </div>
              )}
              <div className="custom-card-body-item-group-section">
              <div className="form-group mb-0 custom-input-form-group custom-normal-input-form-group">
                {/* custom-valid-form-group --- custom-invalid-form-group*/}
                <div className="custom-input-form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={remark}
                    disabled = {true}
                    onChange={(e) => {
                      remarkVal(e)
                    }}
                    id={
                      Constent.REMARKS
                    }
                    placeholder=" "
                  />
                  <label
                    htmlFor={
                      Constent.REMARKS
                    }
                  >
                    {
                      Constent.REMARKS
                    }
                    <span>*</span>
                  </label>
                <span className="invalid-msg-text">
                  {Constent.INVALID_INPUT}
                </span>
                </div>
              </div>
            </div>
            </div>
          )
        }
        {workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OST_TYPE &&
          activeTab === 2 && (
            <OSTActivityDropDownContent workOrderDataObj={workOrderDataObj} />
          )}
      </div>
    </div>
  );
};

export default UpdateWorkOrderDropDownContent;
