import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import React from "react";
import DatePicker from "react-date-picker";
import { Constent } from "../../../../../Assets/Constants/Constent";
import {
  CalendarIcon,
  SearchIcon,
} from "../../../../../Assets/Images/CelcomSvgImages";
import { setTrackStockFieldValue } from "../../../../../ReduxFeature/StateFeatures/InventoryDataState";
import { ApiConstant } from "../../../../../Assets/Constants/ApiConstants";

const InventoryBodyHeaderTrackStockGroup = ({
  userDataObj,
  globalState,
  lovDataObj,
  setPageState,
  inventoryApiCalls,
}) => {
  const dispatch = useDispatch();
  const invDataObj = useSelector((state) => state.inventoryDataInfo);

  const {
    trackStockRequestId,
    trackStockFormData,
    trackStockToData,
    trackStockRequestStatus,
  } = invDataObj;
  const { invrequeststatus } = lovDataObj;

  return (
    <div className="inventory-header-search-group-row-section">
      <div className="inventory-search-group-section">
        <div className="form-group mb-0 custom-input-form-group ">
          <input
            type="text"
            className="form-control"
            value={trackStockRequestId}
            onChange={(e) => {
              dispatch(
                setTrackStockFieldValue({
                  type: ApiConstant.INVENTORY_TRACK_STOCK_REQUEST_ID_VALUE,
                  value: e.target.value,
                })
              );
            }}
            id="trackStockRequestId"
            placeholder=" "
          />
          <label htmlFor="trackStockRequestId">
            {Constent.REQUEST_ID_INPUT_ITEM_LABEL}
          </label>
          <span className="invalid-msg-text">{Constent.INVALID_INPUT}</span>
        </div>
      </div>
      <div className="inventory-search-group-section text-center">
        {Constent.FREE_CONTENT_TEXT2}
      </div>
      <div className="inventory-search-group-section">
        <div className="workorder-search-inner-group-section">
          <div className="form-group mb-0 custom-input-form-group">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="formDate"
                onChange={(e) => {
                  dispatch(
                    setTrackStockFieldValue({
                      type: ApiConstant.INVENTORY_TRACK_STOCK_FORM_DATE_VALUE,
                      value: e.target.value,
                    })
                  );
                }}
                value={trackStockFormData}
                placeholder=" "
              />
              <label htmlFor="formDate">
                {Constent.DATE_RANGE_INPUT_ITEM_LABEL1}
              </label>
              <div className="input-group-append">
                <span className="input-group-text">
                  <CalendarIcon fill={"#333333"} />
                </span>
                <div className="input-group-append-date-picker-section">
                  <DatePicker
                    selected={moment()}
                    onChange={(date) => {
                      dispatch(
                        setTrackStockFieldValue({
                          type: ApiConstant.INVENTORY_TRACK_STOCK_FORM_DATE_VALUE,
                          value: moment(date)?.format("YYYY-MM-DD"),
                        })
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <span className="invalid-msg-text">{Constent.INVALID_INPUT}</span>
          </div>
        </div>
        <div className="workorder-search-inner-group-section">
          <div className="form-group mb-0 custom-input-form-group">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                value={trackStockToData}
                id="toDate"
                placeholder=" "
                onChange={(e) => {
                  dispatch(
                    setTrackStockFieldValue({
                      type: ApiConstant.INVENTORY_TRACK_STOCK_TO_DATE_VALUE,
                      value: e.target.value,
                    })
                  );
                }}
              />
              <label htmlFor="toDate">
                {Constent.DATE_RANGE_INPUT_ITEM_LABEL2}
              </label>
              <div className="input-group-append">
                <span className="input-group-text">
                  <CalendarIcon fill={"#333333"} />
                </span>
                <div className="input-group-append-date-picker-section">
                  <DatePicker
                    selected={moment()}
                    onChange={(date) => {
                      dispatch(
                        setTrackStockFieldValue({
                          type: ApiConstant.INVENTORY_TRACK_STOCK_TO_DATE_VALUE,
                          value: moment(date)?.format("YYYY-MM-DD"),
                        })
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <span className="invalid-msg-text">{Constent.INVALID_INPUT}</span>
          </div>
        </div>
      </div>

      <div className="inventory-search-group-section">
        <div className="form-group m-0 custom-drop-down-form-group">
          <select
            className="custom-select"
            value={trackStockRequestStatus}
            onChange={(e) => {
              dispatch(
                setTrackStockFieldValue({
                  type: ApiConstant.INVENTORY_TRACK_STOCK_REQUEST_STATUS_VALUE,
                  value: e.target.value,
                })
              );
            }}
          >
            <option defaultValue="">-- Select --</option>
            <option value="">-- Select --</option>
            {invrequeststatus?.map((lovData, index) => {
              return (
                <option key={lovData?.value + index} value={lovData?.value}>
                  {lovData?.display}
                </option>
              );
            })}
          </select>
          <label htmlFor="requestStatus">
            {Constent.REQUEST_STATUS_INPUT_ITEM_LABEL}
          </label>
        </div>
      </div>
      <div
        className="inventory-search-group-section"
        onClick={inventoryApiCalls}
      >
        <SearchIcon />
        {Constent.SEARCH_BTN}
      </div>
    </div>
  );
};

export default InventoryBodyHeaderTrackStockGroup;
