import moment from 'moment';
import React from 'react'
import DatePicker from 'react-date-picker';
import { Constent } from '../../Assets/Constants/Constent';
import { CalendarIcon, CloseModalIcon, FilterIcon, SearchIcon } from '../../Assets/Images/CelcomSvgImages';

const InventoryViewStockFilterModal = ({ pageState, setPageState}) => {
    return (
        <div className="modal fade view-stock-details-filter-modal" id="viewStockDetailsFilterModal"
            data-backdrop="static"
            data-keyboard="false" tabIndex="-1" aria-labelledby="viewStockDetailsFilterModalLabel"
            aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header pb-0">
                        <div className="wrapper-modal-header pt-5">
                            <div className="modal-header-title-section">
                                <h5 className="modal-title" id="viewStockDetailsFilterModalLabel">
                                    {Constent.INVENTORY_FILTER_SUB_ITEM_TITLE1}
                                </h5>
                                <span><FilterIcon fill={"#CCCCCC"} /></span>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <CloseModalIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="wrapper-modal-body">
                            <div className="view-stock-filter-input-group-section mt-3">
                                <div className="form-group mb-0 custom-input-form-group">
                                    <div className="input-group">
                                        <input type="text" className="form-control" id={Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL1} placeholder=" "
                                        onChange={(e) => { setPageState({ ...pageState, filterSerialNumber: e.target.value })}} value={pageState?.filterSerialNumber}/>
                                        <label htmlFor={Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL1}>{Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL1}</label>
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <SearchIcon stroke={"#009BDF"} />
                                            </span>
                                        </div>
                                    </div>
                                    <span className='invalid-msg-text'>{Constent.INVALID_INPUT}</span>
                                </div>
                                {/* <div className="form-group mb-0 custom-input-form-group">
                                    <div className="input-group">
                                        <input type="text" className="form-control" id={Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL2} placeholder=" " />
                                        <label htmlFor={Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL2}>{Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL2}</label>
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <SearchIcon stroke={"#009BDF"} />
                                            </span>
                                        </div>
                                    </div>
                                    <span className='invalid-msg-text'>{Constent.INVALID_INPUT}</span>
                                </div> */}
                                <div className="form-group mb-0 custom-input-form-group">
                                    <div className="input-group">
                                        <input type="text" className="form-control" id={Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL3} placeholder=" "
                                        onChange={e => { setPageState({ ...pageState, expiryDate: e.target.value })}} value={pageState?.expiryDate}/>
                                        <label htmlFor={Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL3}>{Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL3}</label>
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <CalendarIcon fill={'#333333'} />
                                            </span>
                                            {/* <div className="input-group-append-date-picker-section">
                                                <DatePicker selected={moment()}
                                                onChange={date => { setPageState({ ...pageState, expiryDate: moment(date)?.format("MM-DD-YYYY") })}} 
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                                    <span className='invalid-msg-text'>{Constent.INVALID_INPUT}</span>
                                </div>
                                {/* <div className="form-group m-0 custom-drop-down-form-group">
                                    <select className="custom-select" onChange={(e) => console.log(e)} id={Constent.PRODUCT_CATAGORY_INPUT_ITEM_LABEL}>
                                        <option defaultValue="">-- Select --</option>
                                        <option value="Month">Month</option>
                                        <option value="Day">Day</option>
                                    </select>
                                    <label htmlFor={Constent.PRODUCT_CATAGORY_INPUT_ITEM_LABEL}>{Constent.PRODUCT_CATAGORY_INPUT_ITEM_LABEL}</label>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="refresh-btn-section" data-dismiss="modal"
                        onClick={(e) => { setPageState({ ...pageState, filterSerialNumber: "", expiryDate: "" })}}
                        >
                            <span>{Constent.CLEAR_BTN}</span>
                        </div>
                        <div className="search-btn-section" data-dismiss="modal">
                            {Constent.APPLY_BTN}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InventoryViewStockFilterModal;