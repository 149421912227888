import React from "react";
import { Constent } from "../../../../Assets/Constants/Constent";
import { BodyBreadcrumbs } from "../../../Common/BodyBreadcrumbs";
import InventoryBodyHeaderManageStockGroup from "./InventorySubItems/InventoryBodyHeaderManageStockGroup";
import InventoryBodyHeaderTrackStockGroup from "./InventorySubItems/InventoryBodyHeaderTrackStockGroup";

const InventoryBodyHeader = ({
  userDataObj,
  globalState,
  pageState,
  setPageState,
  inventoryApiCalls,
  lovDataObj,
  invDataObj,
}) => {
  return (
    <div className="inventory-header-start-section">
      <div className="wrapper-inventory-header-section">
        <BodyBreadcrumbs activeItem={Constent.HEADER_MENU_ITEM4} />
        <div
          className="inventory-header-pill-section"
          data-aos="fade-right"
          data-aos-easing="ease-in-out"
          data-aos-delay="100"
        >
          <div className="nav-responsive">
            <div className="nav nav-pills nav-child-2 position-relative text-center defualt-custom-pill-transition">
              <a
                className={
                  pageState?.activePill ===
                  Constent.BODY_HEADER_INVENTORY_PILL_ITEM1
                    ? "nav-link col active"
                    : "nav-link col"
                }
                onClick={() => {
                  setPageState({
                    ...pageState,
                    activePill: Constent.BODY_HEADER_INVENTORY_PILL_ITEM1,
                    activeRadioBtn:
                      Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1,
                  });
                }}
                data-toggle="tab"
                href={"#" + Constent.BODY_HEADER_INVENTORY_PILL_ITEM1}
              >
                {Constent.BODY_HEADER_INVENTORY_PILL_ITEM1}
              </a>
              <a
                className={
                  pageState?.activePill ===
                  Constent.BODY_HEADER_INVENTORY_PILL_ITEM2
                    ? "nav-link col active"
                    : "nav-link col"
                }
                onClick={() => {
                  setPageState({
                    ...pageState,
                    activePill: Constent.BODY_HEADER_INVENTORY_PILL_ITEM2,
                    activeRadioBtn: Constent.REQUEST_STOCK_RADIO_BTN_LABLE,
                  });
                }}
                data-toggle="tab"
                href={"#" + Constent.BODY_HEADER_INVENTORY_PILL_ITEM2}
              >
                {Constent.BODY_HEADER_INVENTORY_PILL_ITEM2}
              </a>
              <hr className="tab-active defualt-custom-pill-transition" />
            </div>
          </div>
        </div>
        <div className="inventory-header-search-group-section">
          <div
            className={
              pageState?.activePill ===
              Constent.BODY_HEADER_INVENTORY_PILL_ITEM1
                ? globalState?.sidebarState
                  ? "col-xxl-12 col-xl-12 wrapper-inventory-header-search-group"
                  : "col-xxl-10 col-xl-12 wrapper-inventory-header-search-group"
                : "col-12 wrapper-inventory-header-search-group track-stock-pill-active"
            }
          >
            <div
              className="inventory-header-search-group-row-section"
              data-aos="fade-right"
              data-aos-easing="ease-in-out"
              data-aos-delay="100"
            >
              <div
                className="inventory-header-search-inner-group"
                data-aos="fade"
              >
                {pageState?.activePill ===
                Constent.BODY_HEADER_INVENTORY_PILL_ITEM1
                  ? Constent.BODY_HEADER_INVENTORY_SEARCH_TITLE1
                  : Constent.BODY_HEADER_INVENTORY_SEARCH_TITLE2}
              </div>
              {pageState?.activePill ===
              Constent.BODY_HEADER_INVENTORY_PILL_ITEM1 ? (
                <>
                  <div
                    className="inventory-header-search-inner-group"
                    data-aos="fade"
                  >
                    <div className="custom-control custom-radio">
                      <input
                        className="custom-control-input"
                        onChange={(e) => {
                          setPageState({
                            ...pageState,
                            activeRadioBtn: e.target.checked
                              ? Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1
                              : Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM2,
                          });
                        }}
                        checked={
                          pageState?.activeRadioBtn ===
                          Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1
                        }
                        type="radio"
                        name={
                          Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1
                        }
                        id={
                          Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1
                        }
                        value={
                          Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={
                          Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1
                        }
                      >
                        {Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1}
                      </label>
                    </div>
                  </div>
                  <div
                    className="inventory-header-search-inner-group"
                    data-aos="fade"
                  >
                    <div className="custom-control custom-radio">
                      <input
                        className="custom-control-input"
                        onChange={(e) => {
                          setPageState({
                            ...pageState,
                            activeRadioBtn: e.target.checked
                              ? Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM2
                              : Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1,
                          });
                        }}
                        checked={
                          pageState?.activeRadioBtn ===
                          Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM2
                        }
                        type="radio"
                        name={
                          Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM2
                        }
                        id={
                          Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM2
                        }
                        value="request"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={
                          Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM2
                        }
                      >
                        {Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM2}
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="inventory-header-search-inner-group"
                    data-aos="fade"
                  >
                    <div className="custom-control custom-radio">
                      <input
                        className="custom-control-input"
                        onChange={(e) => {
                          setPageState({
                            ...pageState,
                            activeRadioBtn: e.target.checked
                              ? Constent.REQUEST_STOCK_RADIO_BTN_LABLE
                              : Constent.RETURN_STOCK_RADIO_BTN_LABLE,
                          });
                        }}
                        checked={
                          pageState?.activeRadioBtn ===
                          Constent.REQUEST_STOCK_RADIO_BTN_LABLE
                        }
                        type="radio"
                        name={Constent.REQUEST_STOCK_RADIO_BTN_LABLE}
                        id={Constent.REQUEST_STOCK_RADIO_BTN_LABLE}
                        value={Constent.REQUEST_STOCK_RADIO_BTN_LABLE}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={Constent.REQUEST_STOCK_RADIO_BTN_LABLE}
                      >
                        {Constent.REQUEST_STOCK_RADIO_BTN_LABLE}
                      </label>
                    </div>
                  </div>
                  <div
                    className="inventory-header-search-inner-group"
                    data-aos="fade"
                  >
                    <div className="custom-control custom-radio">
                      <input
                        className="custom-control-input"
                        onChange={(e) => {
                          setPageState({
                            ...pageState,
                            activeRadioBtn: e.target.checked
                              ? Constent.RETURN_STOCK_RADIO_BTN_LABLE
                              : Constent.REQUEST_STOCK_RADIO_BTN_LABLE,
                          });
                        }}
                        checked={
                          pageState?.activeRadioBtn ===
                          Constent.RETURN_STOCK_RADIO_BTN_LABLE
                        }
                        type="radio"
                        name={Constent.RETURN_STOCK_RADIO_BTN_LABLE}
                        id={Constent.RETURN_STOCK_RADIO_BTN_LABLE}
                        value={Constent.RETURN_STOCK_RADIO_BTN_LABLE}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={Constent.RETURN_STOCK_RADIO_BTN_LABLE}
                      >
                        {Constent.RETURN_STOCK_RADIO_BTN_LABLE}
                      </label>
                    </div>
                  </div>
                  <div
                    className="inventory-header-search-inner-group"
                    data-aos="fade"
                  >
                    <div className="custom-control custom-radio">
                      <input
                        className="custom-control-input"
                        onChange={(e) => {
                          setPageState({
                            ...pageState,
                            activeRadioBtn: e.target.checked
                              ? Constent.TRANSFER_STOCK_RADIO_BTN_LABLE
                              : Constent.RETURN_STOCK_RADIO_BTN_LABLE,
                          });
                        }}
                        checked={
                          pageState?.activeRadioBtn ===
                          Constent.TRANSFER_STOCK_RADIO_BTN_LABLE
                        }
                        type="radio"
                        name={Constent.TRANSFER_STOCK_RADIO_BTN_LABLE}
                        id={Constent.TRANSFER_STOCK_RADIO_BTN_LABLE}
                        value={Constent.TRANSFER_STOCK_RADIO_BTN_LABLE}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={Constent.TRANSFER_STOCK_RADIO_BTN_LABLE}
                      >
                        {Constent.TRANSFER_STOCK_RADIO_BTN_LABLE}
                      </label>
                    </div>
                  </div>
                </>
              )}
            </div>
            {pageState?.activePill ===
            Constent.BODY_HEADER_INVENTORY_PILL_ITEM1 ? (
              <InventoryBodyHeaderManageStockGroup
                userDataObj={userDataObj}
                globalState={globalState}
                pageState={pageState}
                setPageState={setPageState}
                invDataObj={invDataObj}
                inventoryApiCalls={inventoryApiCalls}
                invCategoryBrandLov={lovDataObj?.invcategorybrand}
              />
            ) : (
              <InventoryBodyHeaderTrackStockGroup
                userDataObj={userDataObj}
                globalState={globalState}
                pageState={pageState}
                setPageState={setPageState}
                invDataObj={invDataObj}
                inventoryApiCalls={inventoryApiCalls}
                lovDataObj={lovDataObj}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryBodyHeader;
