import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Constent } from '../../Assets/Constants/Constent';
import { CloseModalIcon, LogoutIcon } from '../../Assets/Images/CelcomSvgImages';

const LogoutModal = ({ showCartPart }) => {
    const navigate = useNavigate();

    return (
        <div className="modal fade logout-modal"
            id="logoutModal" data-backdrop="static"
            data-keyboard="false" tabIndex="-1" aria-labelledby="logoutModalLabel"
            aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header pb-0">
                        <div className="wrapper-modal-header pt-5">
                            <div className="modal-header-title-section">
                                <LogoutIcon fill={"#FF9700"} />
                                <div className="modal-header-title-item">
                                    {Constent.LOGOUT_MODAL_TITLE1}
                                </div>
                                <div className="modal-body pt-0">
                                    <div className="wrapper-modal-body">
                                        {Constent.LOGOUT_MODAL_TITLE2}
                                    </div>
                                </div>
                                
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <CloseModalIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-header-title-item text-center">
                        { showCartPart && Constent.LOGOUT_MODAL_MESSAGE }
                    </div>
                    <div className="modal-footer">
                        {
                            showCartPart && <div className="refresh-btn-section" data-dismiss="modal" onClick={() => {
                                navigate("/cart")
                            }}>
                                <span>{Constent.GO_TO_CART_BTN}</span>
                            </div>
                        }

                        <div className="okay-btn-section" data-dismiss="modal" onClick={() => {
                            navigate("/")
                        }}>
                            <span>{Constent.LOGOUT_BTN}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogoutModal;