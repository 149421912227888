import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { headers, ValidSerialNumberCheck } from "../../Api/ApiCalls";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { Constent } from "../../Assets/Constants/Constent";
import {
  AddItemIcon,
  CloseModalIcon,
  DeleteIcon,
  TickMarkIcon,
} from "../../Assets/Images/CelcomSvgImages";
import { returnStockSerialNumberData } from "../../ReduxFeature/StateFeatures/CartDataState";
import { toast } from "react-toastify";
const CartAddSerialNumberModal = ({ data, seletedSerialList, userDataObj }) => {
  const dispatch = useDispatch();
  const [serialNumberList, setSerialNumberList] = useState(
    structuredClone(seletedSerialList)
  );

  const [inactiveSaveBtn, setInavtiveSaveBtn] = useState(true);

  function serialNumberCheckApiCall(serialNumber, index) {
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);

    if (serialNumber !== "" && serialNumber?.length > 0) {
      const serialNumberCheckApiRequest = {
        serialNumber: serialNumber,
        outletId: userDataObj?.userData?.activeUserData?.userData?.outletId,
        userId: userDataObj?.userData?.activeUserData?.userData?.userId,
        returnStock: true,
      };
      ValidSerialNumberCheck(
        "/fibre/inventory/validSerialUin",
        serialNumberCheckApiRequest,
        header
      )
        .then((response) => {
          if (
            response?.status === "Success" &&
            parseInt(response?.stockAvaialble) > 0 &&
            data?.sAPMaterialCode === response?.materialCode
          ) {
            const values = [...serialNumberList];

            values[index]["valid"] = "valid";
            // serialNumberList[index] = { ...serialNumberList[index], serialNumber: e.target.value, valid: "" }

            setSerialNumberList(values);
          } else {
            const values = [...serialNumberList];

            values[index]["valid"] = "invalid";
            // serialNumberList[index] = { ...serialNumberList[index], serialNumber: e.target.value, valid: "" }

            setSerialNumberList(values);
          }
        })
        .catch((error) => {
          let errorMessage;
          if (
            error.response.status === 500 ||
            error.response.status === 503 ||
            error.response.status === 404
          ) {
            errorMessage = ApiConstant.VALID_SERIAL_NUMBER_ERROR_MESSAGE;
            toast.error(errorMessage, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 10000,
              theme: "colored",
            });
          } else {
            errorMessage = error.response.statusText;
            toast.error(errorMessage, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 10000,
              theme: "colored",
            });
          }
          const values = [...serialNumberList];

          values[index]["valid"] = "invalid";
          // serialNumberList[index] = { ...serialNumberList[index], serialNumber: e.target.value, valid: "" }

          setSerialNumberList(values);
        });
    }
  }
  useEffect(() => {
    setSerialNumberList(structuredClone(seletedSerialList));
  }, [seletedSerialList]);

  useEffect(() => {
    let validateCount = 0;
    serialNumberList?.map((item) => {
      if (item?.valid === ApiConstant.VALID_INP) {
        validateCount += 1;
      }
    });

    if (
      validateCount !== 0 &&
      serialNumberList?.length > 0 &&
      validateCount === serialNumberList?.length
    ) {
      setInavtiveSaveBtn(false);
    } else {
      setInavtiveSaveBtn(true);
    }
  }, [serialNumberList]);

  return (
    <div
      className="modal fade add-serial-number-modal"
      id="addSerialNumberModal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby="addSerialNumberModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pb-0">
            <div className="wrapper-modal-header pt-5">
              <div className="modal-header-title-section">
                <div className="modal-header-group-section">
                  <h5 className="modal-title" id="addSerialNumberModalLabel">
                    {Constent.ADD_SERIAL_NUMBER_MODAL_TITLE}
                  </h5>
                  <span>{`(${serialNumberList?.length})`}</span>
                </div>
                <div className="modal-header-group-section">
                  <div className="modal-header-group-item">
                    {`${Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE1} `}
                    <span>{data?.sAPMaterialCode}</span>
                  </div>
                  <div className="modal-header-group-item">
                    <div
                      className="add-row-btn-section"
                      onClick={() => {
                        setSerialNumberList([
                          ...serialNumberList,
                          {
                            serialNumber: "",
                            valid: "",
                          },
                        ]);
                      }}
                    >
                      {Constent.ADD_ROW_BTN}
                      <AddItemIcon />
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <CloseModalIcon />
                </span>
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="wrapper-modal-body">
              {serialNumberList?.map((item, index) => {
                return (
                  <div className="mobal-body-section-group" key={index}>
                    <div className="modal-body-group-item">{index + 1}.</div>
                    <div className="modal-body-group-item">
                      <div
                        className={`form-group mb-0 custom-input-form-group custom-normal-input-form-group ${
                          item?.valid === "valid"
                            ? "custom-valid-form-group"
                            : item?.valid === "invalid"
                            ? "custom-invalid-form-group"
                            : ""
                        }`}
                      >
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="serialNumber"
                            placeholder=" "
                            value={item["serialNumber"]}
                            onBlur={(e) =>
                              serialNumberCheckApiCall(e.target.value, index)
                            }
                            onChange={(e) => {
                              const values = [...serialNumberList];

                              values[index]["serialNumber"] = e.target.value;
                              values[index]["valid"] = "";
                              // serialNumberList[index] = { ...serialNumberList[index], serialNumber: e.target.value, valid: "" }

                              setSerialNumberList(values);
                            }}
                          />
                          <label htmlFor="serialNumber">
                            {Constent.SERIAL_NUMBER_INPUT_ITEM_LABEL1}
                          </label>
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <TickMarkIcon stroke={"#009BDF"} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={"modal-body-group-item"}
                      onClick={() => {
                        const values = [...serialNumberList];
                        values.splice(index, 1);
                        setSerialNumberList(values);
                      }}
                    >
                      <DeleteIcon />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="modal-footer">
            <div
              className="refresh-btn-section"
              onClick={() => {
                dispatch(
                  returnStockSerialNumberData({
                    materialCode: data?.sAPMaterialCode,
                    data: [
                      {
                        serialNumber: "",
                        valid: "",
                      },
                    ],
                  })
                );

                setSerialNumberList([
                  {
                    serialNumber: "",
                    valid: "",
                  },
                ]);
              }}
            >
              <span>{Constent.REFRESH_BTN}</span>
            </div>
            <div
              className={`search-btn-section ${
                inactiveSaveBtn ? "inactive-btn" : ""
              }`}
              data-dismiss={inactiveSaveBtn ? null : "modal"}
              onClick={() => {
                if (!inactiveSaveBtn) {
                  dispatch(
                    returnStockSerialNumberData({
                      materialCode: data?.sAPMaterialCode,
                      data: serialNumberList,
                    })
                  );
                }
              }}
            >
              {Constent.SAVE_BTN}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartAddSerialNumberModal;
