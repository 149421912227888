import {
  DownArrowIcon,
  UpArrowIcon,
} from "../../Assets/Images/CelcomSvgImages";

export const Table = () => {
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">First</th>
          <th scope="col">Last</th>
          <th scope="col">Handle</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">1</th>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
        <tr>
          <th scope="row">2</th>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
        </tr>
        <tr>
          <th scope="row">3</th>
          <td>Larry</td>
          <td>the Bird</td>
          <td>@twitter</td>
        </tr>
      </tbody>
    </table>
  );
};

export const TableRowUpDownArrow = ({ fill, stroke }) => {
  return (
    <div className="filter-row-icon">
      <UpArrowIcon fill={fill} stroke={stroke} />
      <DownArrowIcon fill={fill} stroke={stroke} />
    </div>
  );
};

export const RequestPlaceOrderTable = ({
  title1,
  title2,
  title3,
  title4,
  title5,
  data,
}) => {
  return (
    <table className="custom-table">
      <thead>
        <tr>
          <th>
            <span>{title1}</span>
          </th>
          <th>
            <span>{title2}</span>
          </th>
          <th>
            <span>{title3}</span>
          </th>
          <th>
            <span>{title4}</span>
          </th>
          <th>
            <span>{title5}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((itemData) => {
          return (
            <tr key={`tableData${itemData?.sAPMaterialCode}`}>
              <th data-title={title1}>
                <span>{itemData?.sAPMaterialCode || "-"}</span>
              </th>
              <td data-title={title2}>
                <span>{itemData?.itemName || "-"}</span>
              </td>
              <td data-title={title3}>
                <span>{itemData?.brand || "-"}</span>
              </td>
              <td data-title={title4}>
                <span>{itemData?.category || "-"}</span>
              </td>
              <td data-title={title5}>
                <span>{itemData?.reqQuantity || "0"}</span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const ReturnPlaceOrderTable = ({
  title1,
  title2,
  title3,
  title4,
  title5,
  data,
  returnedSerialNumberList,
}) => {
  return (
    <table className="custom-table">
      <thead>
        <tr>
          <th>
            <span>{title1}</span>
          </th>
          <th>
            <span>{title2}</span>
          </th>
          <th>
            <span>{title3}</span>
          </th>
          <th>
            <span>{title4}</span>
          </th>
          <th>
            <span>{title5}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((itemData) => {
          const filterValidSerialNumberList = returnedSerialNumberList[
            itemData?.sAPMaterialCode
          ]?.filter((item) => {
            if (item?.valid === "valid" && item?.serialNumber?.length > 0) {
              return item;
            }
          });
          return (
            <tr key={`tableData${itemData?.sAPMaterialCode}`}>
              <th data-title={title1}>
                <span>{itemData?.sAPMaterialCode || "-"}</span>
              </th>
              <td data-title={title2}>
                <span>{itemData?.itemName || "-"}</span>
              </td>
              <td data-title={title3}>
                <span>{itemData?.brand || "-"}</span>
              </td>
              <td data-title={title4}>
                <span>{itemData?.category || "-"}</span>
              </td>
              <td data-title={title5}>
                <div className="return-stock-serial-number-item-section">
                  {filterValidSerialNumberList?.map((returnItem, index) => {
                    return (
                      <div
                        className="return-stock-serial-number-item"
                        key={`returnedSerialNumbers${
                          returnItem?.serialNumber + index
                        }`}
                      >
                        <span>{`- ${returnItem?.serialNumber || "-"}`}</span>
                      </div>
                    );
                  })}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const TransferPlaceOrderTable = ({
  title1,
  title2,
  title3,
  title4,
  title5,
  data,
  returnedSerialNumberList,
}) => {
  return (
    <table className="custom-table">
      <thead>
        <tr>
          <th>
            <span>{title1}</span>
          </th>
          <th>
            <span>{title2}</span>
          </th>
          <th>
            <span>{title4}</span>
          </th>
          <th>
            <span>{title5}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.itemDetails?.map((itemData) => {
          const filterValidSerialNumberList = returnedSerialNumberList[
            itemData?.sapMaterialCode
          ]?.filter((item) => {
            if (item?.valid === "valid" && item?.serialNumber?.length > 0) {
              return item;
            }
          });
          return (
            <tr key={`tableData${itemData?.sapMaterialCode}`}>
              <th data-title={title1}>
                <span>{itemData?.sapMaterialCode || "-"}</span>
              </th>
              <td data-title={title2}>
                <span>{itemData?.deviceModelDesc || "-"}</span>
              </td>
              <td data-title={title4}>
                <span>{itemData?.category || "-"}</span>
              </td>
              <td data-title={title5}>
                <div className="return-stock-serial-number-item-section">
                  {filterValidSerialNumberList?.map((returnItem, index) => {
                    return (
                      <div
                        className="return-stock-serial-number-item"
                        key={`returnedSerialNumbers${
                          returnItem?.serialNumber + index
                        }`}
                      >
                        <span>{`${returnItem?.serialNumber || "-"}`}</span>
                      </div>
                    );
                  })}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const TrackStockDetailsRequestTable = ({
  title1,
  title2,
  title3,
  title4,
  title5,
  title6,
  title7,
  data,
}) => {
  return (
    <table className="custom-table">
      <thead>
        <tr>
          <th>
            <span>{title1}</span>
          </th>
          <th>
            <span>{title2}</span>
          </th>
          <th>
            <span>{title3}</span>
          </th>
          <th>
            <span>{title4}</span>
          </th>
          <th>
            <span>{title5}</span>
          </th>
          <th>
            <span>{title6}</span>
          </th>
          <th>
            <span>{title7}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => {
          return (
            <tr
              key={`trackstockDetailstableData${item?.sapMaterialCode + index}`}
            >
              <th data-title={title1}>
                <span>{`${index + 1}.` || "-"}</span>
              </th>
              <td data-title={title2}>
                <span>{item?.deviceModelDesc || "-"}</span>
              </td>
              <td data-title={title3}>
                <span>{item?.sapMaterialCode || "-"}</span>
              </td>
              <td data-title={title4}>
                <span>{item?.requestedQuantity || "-"}</span>
              </td>
              <td data-title={title5}>
                <span>{item?.approvedQuantity || "-"}</span>
              </td>
              <td data-title={title6}>
                <span>{item?.receivedQuantity || "-"}</span>
              </td>
              <td data-title={title7}>
                <span>{item?.transferQuantity || "-"}</span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const TrackStockDetailsReturnTable = ({
  title1,
  title2,
  title3,
  title4,
  title5,
  title6,
  data,
}) => {
  return (
    <table className="custom-table">
      <thead>
        <tr>
          <th>
            <span>{title1}</span>
          </th>
          <th>
            <span>{title2}</span>
          </th>
          <th>
            <span>{title3}</span>
          </th>
          <th>
            <span>{title4}</span>
          </th>
          <th>
            <span>{title5}</span>
          </th>
          <th>
            <span>{title6}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => {
          return item?.serialNumbers?.map(
            (dataSerialNumber, serialNumberIndex) => {
              return (
                <tr
                  key={`trackstockDetailstableData${
                    item?.sapMaterialCode +
                    dataSerialNumber +
                    index +
                    serialNumberIndex
                  }`}
                >
                  <th data-title={title1}>
                    <span>{`${index + serialNumberIndex + 1}.` || "-"}</span>
                  </th>
                  <td data-title={title2}>
                    <span>{item?.deviceModelDesc || "-"}</span>
                  </td>
                  <td data-title={title3}>
                    <span>{item?.sapMaterialCode || "-"}</span>
                  </td>
                  <td data-title={title4}>
                    <span>{dataSerialNumber || "-"}</span>
                  </td>
                  <td data-title={title5}>
                    <span>{item?.category || "-"}</span>
                  </td>
                  <td data-title={title6}>
                    <span>{item?.manufacturer || "-"}</span>
                  </td>
                </tr>
              );
            }
          );
        })}
      </tbody>
    </table>
  );
};

export const ApproveStockTable = ({
  title1,
  title2,
  title3,
  title4,
  title5,
  data,
}) => {
  return (
    <table className="custom-table">
      <thead>
        <tr>
          <th>
            <span>{title1}</span>
          </th>
          <th>
            <span>{title2}</span>
          </th>
          <th>
            <span>{title3}</span>
          </th>
          <th>
            <span>{title4}</span>
          </th>
          <th>
            <span>{title5}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((itemData, index) => {
          return (
            <tr key={`tableData${itemData?.sapMaterialCode + index}`}>
              <th data-title={title1}>
                <span>{index + 1 || "-"}</span>
              </th>
              <td data-title={title2}>
                <span>{itemData?.deviceModelDesc || "-"}</span>
              </td>
              <td data-title={title3}>
                <span>{itemData?.sapMaterialCode || "-"}</span>
              </td>
              <td data-title={title4}>
                <span>{itemData?.requestedQuantity || "0"}</span>
              </td>
              <td data-title={title5}>
                <span>{itemData?.approvedQuantity || "0"}</span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const AcknowledgeStockTable = ({
  title1,
  title2,
  title3,
  title4,
  title5,
  title6,
  selectedTrackStockItemDetails,
  acknowledgeSelectedSerialNumbersList,
}) => {
  return (
    <table className="custom-table">
      <thead>
        <tr>
          <th>
            <span>{title1}</span>
          </th>
          <th>
            <span>{title2}</span>
          </th>
          <th>
            <span>{title3}</span>
          </th>
          <th>
            <span>{title4}</span>
          </th>
          <th>
            <span>{title5}</span>
          </th>
          <th>
            <span>{title6}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(acknowledgeSelectedSerialNumbersList)?.map((mcode) => {
          if (acknowledgeSelectedSerialNumbersList[mcode]?.length > 0) {
            const itemDetailsIndex =
              selectedTrackStockItemDetails?.itemDetails?.findIndex(
                (obj) => obj.sapMaterialCode === mcode
              );
            const selecetedItemDetails =
              selectedTrackStockItemDetails?.itemDetails[itemDetailsIndex];
            return (
              <tr key={`tableData`}>
                <th data-title={title1}>
                  <span>{mcode || "-"}</span>
                </th>
                <td data-title={title2}>
                  <span>{selecetedItemDetails?.deviceModelDesc || "-"}</span>
                </td>
                <td data-title={title3}>
                  <span>{selecetedItemDetails?.requestedQuantity || "-"}</span>
                </td>
                <td data-title={title4}>
                  <span>{selecetedItemDetails?.approvedQuantity || "-"}</span>
                </td>
                <td data-title={title5}>
                  <span>
                    {acknowledgeSelectedSerialNumbersList[mcode]?.length || "0"}
                  </span>
                </td>
                <td data-title={title6}>
                  <span>{selecetedItemDetails?.transferQuantity || "0"}</span>
                </td>
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );
};
