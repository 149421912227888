import React from 'react';
import { Constent } from '../../../../Assets/Constants/Constent';

const InstallerDashbordBodyHeader = ({ userDataObj }) => {
    const userChanged = userDataObj?.userData?.activeUserData?.userRole !== userDataObj?.userData?.loginUserData?.userRole;

    return (
        <div className="dashbord-header-start-section position-relative">
            <div className='old-bcdp-link'>Visit old <a href="https://centouch.celcom.com.my/">BCDP</a> portal.</div>
            <div className="dashbord-header-banner-content-section">
                <h3>{Constent.BODY_HEADER_DASHBORD_BANNAR_SUB_TEXT}&nbsp; {userDataObj?.userData?.activeUserData?.userData?.firstName}!</h3>
                <h5>{Constent.BODY_HEADER_DASHBORD_BANNAR_TEXT}</h5>
            </div>
        </div>
    )
}

export default InstallerDashbordBodyHeader;
