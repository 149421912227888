import React from "react";
import { useNavigate } from "react-router-dom";
import { ApiConstant } from "../../../../Assets/Constants/ApiConstants";
import { Constent } from "../../../../Assets/Constants/Constent";
import { InformationIcon } from "../../../../Assets/Images/CelcomSvgImages";
import { BackBtn, DownloadBtn } from "../../../Common/Button";
import { CardItemGroup } from "../../../Common/CardContent";
import {
  DonloadExcelFile,
  EmptyValueHandler,
  TrackStockItemStatusCal,
} from "../../../Common/GenericFunctions";
import { CardDetailsLoader, CardLoader } from "../../../Loaders/Loaders";
import TrackStockRequestItemContent from "./InventoryHelper/TrackStockRequestItemContent";
import TrackStockReturnItemContent from "./InventoryHelper/TrackStockReturnItemContent";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import AdminTrackStockRequestDetailsCard from "../../Cards/Admin/AdminTrackStockRequestDetailsCard";
import SupervisorTrackStockTransferCard from "../../Cards/Supervisor/SupervisorTrackStockTransferCard";
import { setViewTransferStockFieldValue } from "../../../../ReduxFeature/StateFeatures/InventoryDataState";
import { useDispatch } from "react-redux";
import ToggleButton from "../../../Common/ToggleButton";
import { useState } from "react";

const TrackStockRequestDetailsBodyContent = ({
  selectedTrackStockItem,
  userDataObj,
  globalState,
  pageState,
  setPageState,
  selectedTrackStockItemDetails,
  requestorOutletName,
  destinationOutletContact,
  destinationOutletName,
  requestorOutletContactNo,
  destinationOutletAdd,
  requestorOutletAdd,
  toggleStatus,
  setToggleStatus,
}) => {
  const isReturnStock = selectedTrackStockItem?.requestType
    ?.toLowerCase()
    ?.includes(ApiConstant.INVENTORY_TRACK_STOCK_RETURN_TYPE.toLowerCase());
  const isTransferStock = selectedTrackStockItem?.requestType
    ?.toLowerCase()
    ?.includes(ApiConstant.INVENTORY_TRANSFER_STOCK_REQUEST_TYPE.toLowerCase());
  const originStoreId = isReturnStock
    ? selectedTrackStockItem?.transferFromStoreId
    : selectedTrackStockItem?.transferToStoreId;
  const destinationStoreId = isReturnStock
    ? selectedTrackStockItem?.transferToStoreId
    : selectedTrackStockItem?.transferFromStoreId;
  const { className, displayAckBtn } = TrackStockItemStatusCal(
    selectedTrackStockItem?.transferStatus
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const downloadExcelFun = () => {
    const downloadItemDetails = selectedTrackStockItemDetails?.itemDetails;
    const fileName =
      selectedTrackStockItem?.requestType?.replaceAll(" ", "-") +
      "_" +
      selectedTrackStockItem?.transferId;

    if (
      ApiConstant.INVENTORY_TRACK_STOCK_REQUEST_TYPE ===
      selectedTrackStockItem?.requestType
    ) {
      // Request Stock
      //Sl, Material Code, Item Name, Status, Type, Category, Brand, Request Qty, Approve Qty, Received Qty, Transferred Qty
      const downloadData = [];
      downloadItemDetails?.map((data, index) => {
        downloadData.push({
          SL: index + 1,
          "Material Code": EmptyValueHandler({ item: data?.sapMaterialCode }),
          "Item Name": EmptyValueHandler({ item: data?.deviceModelDesc }),
          Status: EmptyValueHandler({
            item: selectedTrackStockItem?.transferStatus,
          }),
          Type: EmptyValueHandler({
            item: selectedTrackStockItem?.requestType,
          }),
          Category: EmptyValueHandler({ item: data?.category }),
          Brand: EmptyValueHandler({ item: data?.manufacturer }),
          "Request Quantity": EmptyValueHandler({
            item: data?.requestedQuantity,
          }),
          "Approve Quantity": EmptyValueHandler({
            item: data?.approvedQuantity,
          }),
          "Received Quantity": EmptyValueHandler({
            item: data?.receivedQuantity,
          }),
          "Transferred Quantity": EmptyValueHandler({
            item: data?.transferQuantity,
          }),
        });
      });

      /* Download Excel Function */
      DonloadExcelFile({
        fileName: fileName,
        downloadData: downloadData,
      });
    } else {
      // Return Stock
      // Sl, Material Code, Item Name, Status, Type, Serial Number(s), Category, Brand
      const downloadData = [];
      downloadItemDetails?.map((parrentData, index) => {
        parrentData?.serialNumbers?.map((serialNumber, innerIndex) => {
          downloadData.push({
            SL: index + innerIndex + 1,
            "Material Code": EmptyValueHandler({
              item: parrentData?.sapMaterialCode,
            }),
            "Item Name": EmptyValueHandler({
              item: parrentData?.deviceModelDesc,
            }),
            Status: EmptyValueHandler({
              item: selectedTrackStockItem?.transferStatus,
            }),
            Type: EmptyValueHandler({
              item: selectedTrackStockItem?.requestType,
            }),
            "Serial Number(s)": EmptyValueHandler({ item: serialNumber }),
            Category: EmptyValueHandler({ item: parrentData?.category }),
            Brand: EmptyValueHandler({ item: parrentData?.manufacturer }),
          });
        });
      });

      /* Download Excel Function */
      DonloadExcelFile({
        fileName: fileName,
        downloadData: downloadData,
      });
    }
  };

  function BackButtonClicked() {
    dispatch(
      setViewTransferStockFieldValue({
        type: "requestorOutletName",
        value: "",
      })
    );
    dispatch(
      setViewTransferStockFieldValue({
        type: "destinationOutletName",
        value: "",
      })
    );
    dispatch(
      setViewTransferStockFieldValue({
        type: "requestorOutletContactNo",
        value: "",
      })
    );
    dispatch(
      setViewTransferStockFieldValue({
        type: "destinationOutletContact",
        value: "",
      })
    );
    dispatch(
      setViewTransferStockFieldValue({
        type: "requestorOutletAdd",
        value: "",
      })
    );
    dispatch(
      setViewTransferStockFieldValue({
        type: "destinationOutletAdd",
        value: "",
      })
    );
  }

  function onCancelClicked() {
    dispatch(
      setViewTransferStockFieldValue({
        type: "requestorOutletName",
        value: "",
      })
    );
    dispatch(
      setViewTransferStockFieldValue({
        type: "destinationOutletName",
        value: "",
      })
    );
    dispatch(
      setViewTransferStockFieldValue({
        type: "requestorOutletContactNo",
        value: "",
      })
    );
    dispatch(
      setViewTransferStockFieldValue({
        type: "destinationOutletContact",
        value: "",
      })
    );
    dispatch(
      setViewTransferStockFieldValue({
        type: "requestorOutletAdd",
        value: "",
      })
    );
    dispatch(
      setViewTransferStockFieldValue({
        type: "destinationOutletAdd",
        value: "",
      })
    );
  }

  return (
    <div className="body-content-section pt-0">
      <div
        className={`track-stock-request-details-content-section ${
          globalState?.sidebarState ? "sidebar-open" : ""
        }`}
      >
        <div className="track-stock-request-details-content-item-section"></div>
        {pageState?.dataLoader ? (
          <CardDetailsLoader />
        ) : pageState?.getDataError ? (
          <div className="error-message-section">
            {pageState?.getDataErrorMsg}...
          </div>
        ) : (
          <div className="track-stock-request-details-content-item-section">
            {/* Title & Btn */}
            <div className="track-stock-request-details-inner-item-section">
              <div className="track-stock-request-details-item-section">
                <span onClick={() => BackButtonClicked()}>
                  <BackBtn navLink={"/inventory"} />
                </span>
                <div className="track-stock-request-details-item">
                  {`${Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE1}: `}
                  <span>{selectedTrackStockItem?.transferId || "-"}</span>
                </div>
              </div>
              <div className="track-stock-request-details-item-section"></div>
              <div
                className="track-stock-request-details-item-section"
                onClick={downloadExcelFun}
              >
                <DownloadBtn />
              </div>
            </div>
            <div className="track-stock-request-details-inner-item-section">
              <div className="track-stock-request-details-item-section">
                <CardItemGroup
                  title={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE1}
                  value={selectedTrackStockItem?.transferId || "-"}
                />
                <CardItemGroup
                  title={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE2}
                  value={selectedTrackStockItem?.requestType || "-"}
                />
                <CardItemGroup
                  title={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE3}
                  value={selectedTrackStockItem?.createDate || "-"}
                />
                <CardItemGroup
                  title={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE4}
                  value={originStoreId || "-"}
                />
                <CardItemGroup
                  title={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE5}
                  value={destinationStoreId || "-"}
                />
                <CardItemGroup
                  title={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE6}
                  value={selectedTrackStockItem?.transferFromStoreName || "-"}
                />
                <CardItemGroup
                  title={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE8}
                  value={selectedTrackStockItem?.approvalUser || "-"}
                />

                <div className="card-item-group">
                  <div className="card-item-title">
                    {Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE7}
                  </div>
                  <div className={`card-item-value ${className}`}>
                    {selectedTrackStockItem?.transferStatus || "-"}
                  </div>
                </div>
              </div>

              {selectedTrackStockItem.requestType === "Transfer Stock" &&
              selectedTrackStockItem.transferStatus === "Inbound-Picking" ? (
                <>
                  <div className="track-stock-request-details-card-item-section">
                    {selectedTrackStockItemDetails?.itemDetails.map(
                      (data, index) => {
                        return (
                          <SupervisorTrackStockTransferCard
                            data={data}
                            index={index + 1}
                            key={`approveCard${index + data?.sapMaterialCode}`}
                            setPageState={setPageState}
                            pageState={pageState}
                          />
                        );
                      }
                    )}
                  </div>
                  <ToggleButton
                    label2="Request for SAMART Pick-up and Delivery"
                    name="toggleStatus"
                    checked={toggleStatus ? true : false}
                    onChange={() => setToggleStatus(!toggleStatus)}
                  />
                  <div>
                    {toggleStatus && (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="inventory-search-group-section">
                              <div className="form-group mb-0 custom-input-form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="requestorOutletName"
                                  value={requestorOutletName}
                                  placeholder=" "
                                  onChange={(e) => {
                                    dispatch(
                                      setViewTransferStockFieldValue({
                                        type: "requestorOutletName",
                                        value: e.target.value,
                                      })
                                    );
                                  }}
                                />
                                <label htmlFor="requestorOutletName">
                                  Requestor Outlet Name
                                </label>
                                <span className="invalid-msg-text">
                                  {Constent.INVALID_INPUT}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="inventory-search-group-section">
                              <div className="form-group mb-0 custom-input-form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="destinationOutletName"
                                  value={destinationOutletName}
                                  placeholder=" "
                                  onChange={(e) => {
                                    dispatch(
                                      setViewTransferStockFieldValue({
                                        type: "destinationOutletName",
                                        value: e.target.value,
                                      })
                                    );
                                  }}
                                />
                                <label htmlFor="destinationOutletName">
                                  Destination Outlet Name
                                </label>
                                <span className="invalid-msg-text">
                                  {Constent.INVALID_INPUT}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-md-6">
                            <div className="inventory-search-group-section">
                              <div className="form-group mb-0 custom-input-form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="requestorOutletContactNo"
                                  value={requestorOutletContactNo}
                                  placeholder=" "
                                  onChange={(e) => {
                                    dispatch(
                                      setViewTransferStockFieldValue({
                                        type: "requestorOutletContactNo",
                                        value: e.target.value,
                                      })
                                    );
                                  }}
                                />
                                <label htmlFor="requestorOutletContactNo">
                                  Requestor Outlet Contact Number
                                </label>
                                <span className="invalid-msg-text">
                                  {Constent.INVALID_INPUT}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="inventory-search-group-section">
                              <div className="form-group mb-0 custom-input-form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="destinationOutletContact"
                                  value={destinationOutletContact}
                                  placeholder=" "
                                  onChange={(e) => {
                                    dispatch(
                                      setViewTransferStockFieldValue({
                                        type: "destinationOutletContact",
                                        value: e.target.value,
                                      })
                                    );
                                  }}
                                />
                                <label htmlFor="destinationOutletContact">
                                  Destination Outlet Contact Number
                                </label>
                                <span className="invalid-msg-text">
                                  {Constent.INVALID_INPUT}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-md-6">
                            <div className="inventory-search-group-section">
                              <div className="form-group mb-0 custom-input-form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="requestorOutletAdd"
                                  value={requestorOutletAdd}
                                  placeholder=" "
                                  onChange={(e) => {
                                    dispatch(
                                      setViewTransferStockFieldValue({
                                        type: "requestorOutletAdd",
                                        value: e.target.value,
                                      })
                                    );
                                  }}
                                />
                                <label htmlFor="requestorOutletAdd">
                                  Requestor Outlet Collection Address
                                </label>
                                <span className="invalid-msg-text">
                                  {Constent.INVALID_INPUT}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="inventory-search-group-section">
                              <div className="form-group mb-0 custom-input-form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="destinationOutletAdd"
                                  value={destinationOutletAdd}
                                  placeholder=" "
                                  onChange={(e) => {
                                    dispatch(
                                      setViewTransferStockFieldValue({
                                        type: "destinationOutletAdd",
                                        value: e.target.value,
                                      })
                                    );
                                  }}
                                />
                                <label htmlFor="destinationOutletAdd">
                                  Destination Outlet Collection Address
                                </label>
                                <span className="invalid-msg-text">
                                  {Constent.INVALID_INPUT}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <br />
                    <div
                      className="row"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="col-md-2">
                        <div
                          className="refresh-btn-section"
                          onClick={() => onCancelClicked()}
                        >
                          {Constent.CANCEL_BTN}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div
                          className="cart-place-order-item-group"
                          data-toggle="modal"
                          data-target="#TransferStockOrderPlaceSerialNo"
                        >
                          <div className="place-order-btn-section">
                            <span>{Constent.SUBMIT_BTN}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {selectedTrackStockItemDetails?.itemDetails?.length > 0 && (
                    <div className="track-stock-request-details-inner-item-section">
                      {!isReturnStock ? (
                        <TrackStockRequestItemContent
                          selectedTrackStockItemDetails={
                            selectedTrackStockItemDetails?.itemDetails
                          }
                        />
                      ) : (
                        <TrackStockReturnItemContent
                          selectedTrackStockItemDetails={
                            selectedTrackStockItemDetails?.itemDetails
                          }
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>

            {!isReturnStock &&
            selectedTrackStockItem?.transferStatus ===
              ApiConstant.INVENTORY_TRACK_STOCK_COMPLETED_STATUS[0] ? (
              <div className="track-stock-request-details-inner-item-section mt-3">
                <div className="track-stock-request-details-item-section">
                  {selectedTrackStockItem.transferToStoreId ===
                    userDataObj?.userData?.activeUserData?.userData
                      ?.outletId && (
                    <div
                      className="acknowledge-btn-section"
                      onClick={() => {
                        navigate("/inventory/trackstock/acknowledge");
                      }}
                    >
                      {Constent.ACKNOWLEDGE_BTN}
                      <InformationIcon fill={"#FFFFFF"} stroke={"#FFFFFF"} />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="track-stock-request-details-inner-item-section"></div>
            )}
          </div>
        )}
        <div className="track-stock-request-details-content-item-section"></div>
      </div>
    </div>
  );
};

export default TrackStockRequestDetailsBodyContent;
