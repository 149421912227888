import React from "react";
import { Constent } from "../../../../Assets/Constants/Constent";
import { BodyBreadcrumbs } from "../../../Common/BodyBreadcrumbs";
import AdminInventoryBodyHeaderTrackStockGroup from "./InventorySubItems/AdminInventoryBodyHeaderTrackStockGroup";

const AdminInventoryBodyHeader = ({
  userDataObj,
  globalState,
  pageState,
  setPageState,
  inventoryApiCalls,
}) => {
  const adminUser =
    userDataObj?.userData?.loginUserData?.userRole === Constent.ADMIN_ROLE &&
    userDataObj?.userData?.activeUserData?.userRole === Constent.ADMIN_ROLE;
  return (
    <div
      className={`inventory-header-start-section ${
        adminUser && "admin-inventory-header-start-section"
      }`}
    >
      <div className="wrapper-inventory-header-section">
        <BodyBreadcrumbs activeItem={Constent.HEADER_MENU_ITEM4} />
        <div
          className="inventory-header-search-group-section"
          data-aos="fade-right"
          data-aos-easing="ease-in-out"
        >
          <div className="col-12 wrapper-inventory-header-search-group">
            <div
              className="inventory-header-search-group-row-section"
              data-aos="fade-right"
              data-aos-easing="ease-in-out"
              data-aos-delay="100"
            >
              <div
                className="inventory-header-search-inner-group"
                data-aos="fade"
              >
                {Constent.BODY_HEADER_INVENTORY_SEARCH_TITLE2}
              </div>
              <>
                <div
                  className="inventory-header-search-inner-group"
                  data-aos="fade"
                >
                  <div className="custom-control custom-radio">
                    <input
                      className="custom-control-input"
                      onChange={(e) => {
                        setPageState({
                          ...pageState,
                          trackStockActiveRadioBtn: e.target.checked
                            ? Constent.REQUEST_STOCK_RADIO_BTN_LABLE
                            : Constent.TRANSFER_STOCK_RADIO_BTN_LABLE,
                        });
                      }}
                      checked={
                        pageState?.trackStockActiveRadioBtn ===
                        Constent.REQUEST_STOCK_RADIO_BTN_LABLE
                      }
                      type="radio"
                      name={Constent.REQUEST_STOCK_RADIO_BTN_LABLE}
                      id={Constent.REQUEST_STOCK_RADIO_BTN_LABLE}
                      value={Constent.REQUEST_STOCK_RADIO_BTN_LABLE}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={Constent.REQUEST_STOCK_RADIO_BTN_LABLE}
                    >
                      {Constent.REQUEST_STOCK_RADIO_BTN_LABLE}
                    </label>
                  </div>
                </div>
                <div
                  className="inventory-header-search-inner-group"
                  data-aos="fade"
                >
                  <div className="custom-control custom-radio">
                    <input
                      className="custom-control-input"
                      onChange={(e) => {
                        setPageState({
                          ...pageState,
                          trackStockActiveRadioBtn: e.target.checked
                            ? Constent.TRANSFER_STOCK_RADIO_BTN_LABLE
                            : Constent.REQUEST_STOCK_RADIO_BTN_LABLE,
                        });
                      }}
                      checked={
                        pageState?.trackStockActiveRadioBtn ===
                        Constent.TRANSFER_STOCK_RADIO_BTN_LABLE
                      }
                      type="radio"
                      name={Constent.TRANSFER_STOCK_RADIO_BTN_LABLE}
                      id={Constent.TRANSFER_STOCK_RADIO_BTN_LABLE}
                      value={Constent.TRANSFER_STOCK_RADIO_BTN_LABLE}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={Constent.TRANSFER_STOCK_RADIO_BTN_LABLE}
                    >
                      {Constent.TRANSFER_STOCK_RADIO_BTN_LABLE}
                    </label>
                  </div>
                </div>
                {false && (
                  <div
                    className="inventory-header-search-inner-group"
                    data-aos="fade"
                  >
                    <div className="custom-control custom-radio">
                      <input
                        className="custom-control-input"
                        onChange={(e) => {
                          setPageState({
                            ...pageState,
                            trackStockActiveRadioBtn: e.target.checked
                              ? Constent.RETURN_STOCK_RADIO_BTN_LABLE
                              : Constent.REQUEST_STOCK_RADIO_BTN_LABLE,
                          });
                        }}
                        checked={
                          pageState?.trackStockActiveRadioBtn ===
                          Constent.RETURN_STOCK_RADIO_BTN_LABLE
                        }
                        type="radio"
                        name={Constent.RETURN_STOCK_RADIO_BTN_LABLE}
                        id={Constent.RETURN_STOCK_RADIO_BTN_LABLE}
                        value="view"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={Constent.RETURN_STOCK_RADIO_BTN_LABLE}
                      >
                        {Constent.RETURN_STOCK_RADIO_BTN_LABLE}
                      </label>
                    </div>
                  </div>
                )}
              </>
            </div>
            <AdminInventoryBodyHeaderTrackStockGroup
              inventoryApiCalls={inventoryApiCalls}
              pageState={pageState}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminInventoryBodyHeader;
