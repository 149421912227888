import React from 'react';
import { useDispatch } from 'react-redux';
import { ApiConstant } from '../../../../Assets/Constants/ApiConstants';
import { Constent } from '../../../../Assets/Constants/Constent';
import { AddUserIcon, SearchIcon, ToggleBtnIcon1, ToggleBtnIcon2 } from '../../../../Assets/Images/CelcomSvgImages';
import { setInstallerData } from '../../../../ReduxFeature/StateFeatures/InstallerDataState';
import { BodyBreadcrumbs } from '../../../Common/BodyBreadcrumbs';

const ManageInstallerBodyHeader = ({ installerDataObj, globalState, pageState, setPageState, GetInstallerApiCall, filterList }) => {
    const filterCount = filterList?.length;
    const { installerNameOrContact } = installerDataObj;
    const dispatch = useDispatch();

    return (
        <div className="manage-installer-header-start-section">
            <div className="wrapper-manage-installer-header-section">
                <BodyBreadcrumbs activeItem={Constent.HEADER_MENU_ITEM3} />
                <div className={`manage-installer-header-search-group-section ${globalState?.sidebarState ? "sidebar-active" : ""}`}>
                    <div className="manage-installer-header-search-group-item-section"></div>
                    <div className="manage-installer-header-search-group-item-section">
                        <div className="manage-installer-search-group-item" >
                            <div className="manage-installer-search-group-item-row-section">
                                {Constent.BODY_HEADER_MANAGE_INSTALLER_TITLE1}
                            </div>
                            <div className="manage-installer-search-group-item-row-section">
                                <div className="search-group-item-section">
                                    <div className="form-group mb-0 custom-input-form-group ">
                                        <input type="text" className="form-control" value={installerNameOrContact || ""}
                                            id="installerNameOrContact" placeholder=" " onChange={(e) => {
                                                dispatch(setInstallerData({
                                                    type: ApiConstant.MANAGE_INSTALLER_INPUT,
                                                    value: e.target.value
                                                }));
                                            }} />
                                        <label htmlFor="installerNameOrContact">{Constent.MANAGE_INSTALLER_INPUT_ITEM_LABEL1}</label>
                                        <span className='invalid-msg-text'>{Constent.INVALID_INPUT}</span>
                                    </div>
                                </div>
                                <div className="search-group-item-section">
                                    <div className="manage-installer-search-group-btn normal-custom-transition" onClick={GetInstallerApiCall}>
                                        <SearchIcon />
                                        {Constent.SEARCH_BTN}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="manage-installer-search-group-item normal-custom-transition"
                            data-toggle="modal" data-target="#addInstallerModal">
                            <span>
                                <div><AddUserIcon /></div>
                                {Constent.ADD_INSTALLER_BTN}
                            </span>
                        </div>
                    </div>
                    <div className="manage-installer-header-search-group-item-section"></div>
                </div>
            </div>
            {
                filterCount > 0
                &&
                <div className="dashbord-header-filter-section">
                    <div className="dashbord-header-filter-itme-info">
                        <strong>{`${Constent.MANAGE_INSTALLER_FILTER_ITEM_TITLE1} - ${filterCount}`}</strong>
                    </div>
                    <div className="dashbord-header-filter-item-toggle-btn" onClick={() => {
                        setPageState({ ...pageState, cardView: pageState?.cardView === "List" ? "Card" : "List" })
                    }}>
                        {
                            pageState?.cardView === "List" ? <ToggleBtnIcon2 /> : <ToggleBtnIcon1 />
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default ManageInstallerBodyHeader;