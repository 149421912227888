import React from "react";
import { Constent } from "../../../../../Assets/Constants/Constent";
import { TrackStockDetailsRequestTable } from "../../../../Common/Table";

const TrackStockRequestItemContent = ({ selectedTrackStockItemDetails }) => {
  return (
    <div className="track-stock-request-details-inner-item-request-table-section">
      <TrackStockDetailsRequestTable
        title1={"SL"}
        title2={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE2}
        title3={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE1}
        title4={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_DETAILS_TITLE1}
        title5={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_DETAILS_TITLE2}
        title6={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_DETAILS_TITLE3}
        title7={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_DETAILS_TITLE4}
        data={selectedTrackStockItemDetails}
      />
    </div>
  );
};

export default TrackStockRequestItemContent;
