import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Constent } from '../../../../Assets/Constants/Constent';
import { OtherImages } from '../../../../Assets/Images/CelcomOtherImages';
import { AssignIcon, ColoredTimeLineIcon, DownloadIcon, LeftArrowIcon } from '../../../../Assets/Images/CelcomSvgImages';
import { CardDetailsLoader } from '../../../Loaders/Loaders';
import { AppoinmentSubTypeConverter, AppoinmentTypeConverter, AssignedToSameAsUserId } from '../../../Common/GenericFunctions';

import WorkOrderDetailsAccordion from './WorkOrderDeatilsHelper/WorkOrderDetailsAccordion';
import WorkOrderDetailsTimeLine from './WorkOrderDeatilsHelper/WorkOrderDetailsTimeLine';

const WorkOrderDetailsBodyContent = ({ userDataObj, selectedWorkOrder, pageState, documentList, globalState, workOrderDetailsObj }) => {
  const [downloadBtnState, setDownloadBtnState] = useState(false);
  const componentRef = useRef(null);
  const navigate = useNavigate();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setDownloadBtnState(false);
    }
  });

  const downloadPdfFun = () => {
    setDownloadBtnState(true);
  }
  useEffect(() => {
    if (downloadBtnState) {
      handlePrint();
    }
  }, [downloadBtnState]);

  return (
    <div className='body-content-section pt-0' ref={componentRef}>
      {
        downloadBtnState && <div className={`d-flex justify-content-end pt-5`}>
          {
            downloadBtnState && <img src={OtherImages.CelcomLogo} style={{ right: 24 }} className="" />
          }
        </div>
      }
      <div className={`work-order-details-content-section mb-2 ${(globalState?.sidebarState || downloadBtnState) ? "active-sidebar" : ""}`}>
        <div className="section"></div>
        <div className="section">
          <div className="back-btn-section" onClick={()=> navigate("/workorder")}>
            <LeftArrowIcon stroke={"#009BDF"}/>
            <span>{Constent.BACK_BTN}</span>
          </div>
        </div>
        <div className="section"></div>
      </div>
      <div className={`work-order-details-content-section ${(globalState?.sidebarState || downloadBtnState) ? "active-sidebar" : ""}`}>

        <div className={`work-order-details-content-item-section`}></div>
        {
          pageState?.dataLoader ?
            <CardDetailsLoader />
            :
            pageState?.getDataError ?
              <div className="error-message-section">
                {pageState?.getDataErrorMsg}...
              </div>
              :
              <div className="work-order-details-content-item-section">
                {/* Title & Btn */}
                <div className="work-order-details-inner-item-section">
                  <div className="work-order-details-item-section">
                    <div className="work-order-details-item">
                      {`${Constent.WORK_ORDER_DETAILS_CONTENT_TITLE1} `}<span>
                       
                        {workOrderDetailsObj?.itemID?.replace("FieldActivity-", "")?.trim()}
                      </span>
                    </div>
                  </div>
                  <div className="work-order-details-item-section"></div>
                  <div className="work-order-details-item-section">
                    { /* if item status = Returned or Closed, Download button goes to right*/
                      !downloadBtnState && (workOrderDetailsObj?.itemStatus == "Returned" || workOrderDetailsObj?.itemStatus == "Cancel"
                       || workOrderDetailsObj?.itemStatus == "Closed") &&
                      <div className=""></div>
                    }
                    {
                      !downloadBtnState && <div className="download-btn-section" onClick={downloadPdfFun}>
                        <span>{Constent.DOWNLOAD_BTN}</span>
                        <DownloadIcon />
                      </div>
                    }
                    { /* if item status = Returned or Closed, Assign button will disappear*/
                      !downloadBtnState && workOrderDetailsObj?.itemStatus != "Returned" && workOrderDetailsObj?.itemStatus != "Closed" 
                      &&  workOrderDetailsObj?.itemStatus != "Cancel" &&
                      <div className="assign-btn-section" data-toggle="modal"
                        data-target="#assignWorkOrder">
                        <span>{Constent.ASSIGN_BTN}</span>
                        <AssignIcon />
                      </div>
                    }

                  </div>
                </div>
                {/* Timeline */}
                <div className="work-order-details-inner-item-section">
                  <div className="work-order-details-item-section"></div>
                  <div className="work-order-details-item-section">
                    <WorkOrderDetailsTimeLine workOrderDetailsObj={workOrderDetailsObj} />
                  </div>
                  <div className="work-order-details-item-section"></div>
                </div>
                <div className="work-order-details-inner-item-section-mobile"
                  data-toggle="modal" data-target="#workOrderTimelineModal">

                  <span><ColoredTimeLineIcon />{Constent.VIEW_TIMELINE_MODAL_TITLE}</span>
                </div>
                {/* Accordion */}
                <div className="work-order-details-inner-item-section">
                  <WorkOrderDetailsAccordion userDataObj={userDataObj} selectedWorkOrder={selectedWorkOrder}
                    workOrderDetailsObj={workOrderDetailsObj} pageState = {pageState} documentList={documentList} downloadBtnState={downloadBtnState} />
                </div>
              </div>

        }
        <div className="work-order-details-content-item-section"></div>
      </div>
    </div>
  )
}

export default WorkOrderDetailsBodyContent;