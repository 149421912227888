import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Constent } from '../../../../Assets/Constants/Constent';
import { RightArrowIcon } from '../../../../Assets/Images/CelcomSvgImages';
import { selectedWorkOrderItem } from '../../../../ReduxFeature/StateFeatures/WorkOrderDataState';
import { CardItemGroup } from '../../../Common/CardContent';
import { InstallerWorkOrderStatusCal } from '../../../Common/GenericFunctions';

const DashbordSidebarCard = ({ data }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { itemStatus } = InstallerWorkOrderStatusCal({ status: data?.itemStatus });
    return (
        <div className='installer-sidebar-card-start-section' onClick={() => {
            dispatch(selectedWorkOrderItem(data)) && navigate("/workorderdetails")
        }}>
            <div className="wrapper-installer-sidebar-card-start-section">
                <div className="installer-sidebar-card-item-section">
                    <CardItemGroup title={Constent.WORK_ORDER_CARD_ITEM_TITLE1} value={data?.itemId?.replaceAll("FieldActivity-", "") || "-"} />
                    <CardItemGroup title={Constent.WORK_ORDER_CARD_ITEM_TITLE2} value={data?.itemTypeId?.replaceAll("_", " ") || "-"} />
                    <CardItemGroup title={Constent.WORK_ORDER_CARD_ITEM_TITLE4} value={data?.resolutionDate?.split()[0] || "-"} />
                    <CardItemGroup title={Constent.WORK_ORDER_CARD_ITEM_TITLE10} value={itemStatus || "-"} />
                </div>
                <div className="installer-sidebar-card-item-section">
                    <RightArrowIcon stroke={"#999999"} />
                </div>
            </div>
        </div>
    )
}

export default DashbordSidebarCard;