import React from "react";
import { Constent } from "../../../../Assets/Constants/Constent";
import { CartIcon } from "../../../../Assets/Images/CelcomSvgImages";
import { BodyBreadcrumbs } from "../../../Common/BodyBreadcrumbs";
import { UnderLineNavbar2 } from "../../../Common/UnderLineNavbar";

const CartBodyHeader = ({
  userDataObj,
  globalState,
  pageState,
  setPageState,
  requestStockList,
  returnStockList,
  transferStockList,
}) => {
  return (
    <div className="cart-header-start-section">
      <div className="wrapper-cart-header-section">
        <BodyBreadcrumbs activeItem={Constent.BODY_HEADER_CART_PATH} />
        <div
          className="cart-header-nav-section"
          data-aos="fade-right"
          data-aos-easing="ease-in-out"
        >
          <div
            className="cart-header-nav-item-section"
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-delay="100"
          >
            <CartIcon />
            {Constent.BODY_HEADER_CART_PATH}
          </div>
          <div
            className="cart-header-nav-item-section"
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-delay="200"
          >
            <UnderLineNavbar2
              nav1={Constent.UNDERLINE_NAV_CART_ITEM1}
              nav2={Constent.UNDERLINE_NAV_CART_ITEM2}
              nav3={Constent.UNDERLINE_NAV_CART_ITEM3}
              notifi={true}
              count1={requestStockList?.length}
              count2={returnStockList?.length}
              count3={transferStockList?.length}
              pageState={pageState}
              setPageState={setPageState}
            />
          </div>
          <div className="cart-header-nav-item-section"></div>
        </div>
      </div>
    </div>
  );
};

export default CartBodyHeader;
