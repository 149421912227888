import React from 'react';
import { NoDataFoundItemGroup } from '../../../Common/CardContent';
import { CardLoader } from '../../../Loaders/Loaders';
import SwitchUserCard from '../../Cards/Admin/SwitchUserCard';

const SwitchUserBodyContent = ({ pageState, setPageState, switchUserApiCall }) => {

    const filterList = pageState?.supervisorList?.filter(item => {
        if (pageState?.filterUserName === ""
            || item?.userId?.toLowerCase()?.includes(pageState?.filterUserName?.toLowerCase())) {
            return item;
        }
    })
    return (
        <div className='body-content-section'>
            {/* Body Content - Card */}
            <div className="wrapper-body-content-section switch-user-body-content-section">
                {
                    pageState?.dataLoader ?
                        [0, 1].map(val => {
                            return (
                                <CardLoader key={`switchUserList${val}`} />
                            )
                        })
                        :
                        <>
                            {
                                filterList?.length > 0 ?
                                    filterList?.map((data, index) => {
                                        return (
                                            <SwitchUserCard data={data}
                                                switchUserApiCall={switchUserApiCall}
                                                key={`switchuser${data?.userId} ${index}`}
                                                setPageState={setPageState}
                                            />
                                        )
                                    })
                                    :
                                    <NoDataFoundItemGroup message={"No Supervisor found"} />                                    
                            }
                        </>
                }
            </div>
        </div>
    )
}

export default SwitchUserBodyContent;