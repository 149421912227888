import React, { useState } from "react";
import { Constent } from "../../../../../Assets/Constants/Constent";
import { PagenationIndexPositionCal } from "../../../../Common/GenericFunctions";
import Pagination from "../../../../Common/Pagination";
import { CardLoader } from "../../../../Loaders/Loaders";
import AdminTrackStockCard from "../../../Cards/Admin/AdminTrackStockCard";

const AdminInventoryTrackReturnStockContent = ({
  invDataObj,
  pageState,
  setPageState,
}) => {
  const { trackReturnStockList, trackStockRequestId, trackStockRequestStatus } =
    invDataObj;

  const [currentPage, setCurrentPage] = useState(1);
  const postPerPage = Math.min(trackReturnStockList?.length, 10);
  const { indexOfLastPost, indexOfFirstPost, displayPosts } =
    PagenationIndexPositionCal(currentPage, postPerPage, trackReturnStockList);

  const filterList = trackReturnStockList?.filter((item) => {
    if (
      (trackStockRequestId === "" && trackStockRequestStatus === "") ||
      (item?.transferId
        ?.toLowerCase()
        ?.includes(trackStockRequestId?.toLowerCase()) &&
        item?.transferStatus
          ?.toLowerCase()
          ?.includes(trackStockRequestStatus?.toLowerCase()))
    ) {
      return item;
    }
  });

  // const displayFilterList = displayPosts?.filter(filterItem => {
  //     if ((trackStockRequestId === "" && trackStockRequestStatus === "")
  //         || filterItem?.transferId?.toLowerCase()?.includes(trackStockRequestId?.toLowerCase()) &&
  //         filterItem?.transferStatus?.toLowerCase()?.includes(trackStockRequestStatus?.toLowerCase())) {
  //         return filterItem;
  //     }
  // });
  return (
    <>
      {pageState?.dataLoader ? (
        [0, 1].map((val) => {
          return <CardLoader key={`trackOrderList${val}`} />;
        })
      ) : pageState?.getTrackStockRequestDataError ? (
        <div className="error-message-section">
          {pageState?.getTrackStockRequestDataErrorMsg}...
        </div>
      ) : (
        <>
          {filterList?.length > 0 && (
            <div className="inventory-filter-section">
              <div className="filter-item-info-section">
                <strong>{`${indexOfFirstPost + 1} - 
                                        ${
                                          indexOfLastPost > filterList?.length
                                            ? filterList?.length
                                            : indexOfLastPost
                                        } of ${
                  filterList.length
                }`}</strong>{" "}
                Items
              </div>
              <div
                className="filter-item-info-section"
                hidden={filterList?.length < 11}
              >
                <Pagination
                  lastPageNumber={Math.ceil(filterList?.length / postPerPage)}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
              <div className="filter-item-info-section"></div>
            </div>
          )}
          {filterList?.map((data, index) => {
            return (
              <AdminTrackStockCard
                key={`${
                  Constent.TRANSFER_STOCK_RADIO_BTN_LABLE +
                  data?.transferId +
                  index
                }`}
                data={data}
                pageState={pageState}
              />
            );
          })}
        </>
      )}
    </>
  );
};

export default AdminInventoryTrackReturnStockContent;

// import React, { useState } from "react";
// import { Constent } from "../../../../../Assets/Constants/Constent";
// import { PagenationIndexPositionCal } from "../../../../Common/GenericFunctions";
// import Pagination from "../../../../Common/Pagination";
// import AdminTrackStockCard from "../../../Cards/Admin/AdminTrackStockCard";

// const AdminInventoryTrackReturnStockContent = ({
//   globalState,
//   pageState,
//   setPageState,
// }) => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const postPerPage = Math.min(pageState?.trackReturnStockList?.length, 10);
//   const { indexOfLastPost, indexOfFirstPost, displayPosts } =
//     PagenationIndexPositionCal(
//       currentPage,
//       postPerPage,
//       pageState?.trackReturnStockList
//     );

//   return (
//     <>
//       {pageState?.dataLoader ? (
//         <>Loading the data need to implement</>
//       ) : (
//         <>
//           {pageState?.trackReturnStockListFilterView && (
//             <div className="inventory-filter-section">
//               <div className="filter-item-info-section">
//                 <strong>{`${indexOfFirstPost + 1} - ${
//                   indexOfLastPost > pageState?.trackReturnStockList?.length
//                     ? pageState?.trackReturnStockList?.length
//                     : indexOfLastPost
//                 } of ${pageState?.trackReturnStockList?.length}`}</strong>{" "}
//                 Items
//               </div>
//               <div
//                 className="filter-item-info-section"
//                 hidden={pageState?.trackReturnStockListFilterView?.length < 11}
//               >
//                 <Pagination
//                   lastPageNumber={Math.ceil(
//                     pageState?.trackReturnStockList?.length / postPerPage
//                   )}
//                   currentPage={currentPage}
//                   setCurrentPage={setCurrentPage}
//                 />
//               </div>
//               <div className="filter-item-info-section"></div>
//             </div>
//           )}
//           {displayPosts?.map((data, index) => {
//             return (
//               <AdminTrackStockCard
//                 key={`${Constent.RETURN_STOCK_RADIO_BTN_LABLE + data?.id}`}
//                 data={data}
//               />
//             );
//           })}
//         </>
//       )}
//     </>
//   );
// };

// export default AdminInventoryTrackReturnStockContent;
