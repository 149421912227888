import React from 'react'
import { useState } from 'react';
import { LeftArrowIcon, RightArrowIcon } from '../../Assets/Images/CelcomSvgImages';

const Pagination = ({ lastPageNumber, currentPage, setCurrentPage }) => {
    const pageNumbers = [];
    const [pageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);


    for (let i = 1; i <= lastPageNumber; i++) {
        pageNumbers.push(i);
    }
    return (
        <div className="pagination-body-section">
            <div className="pagination-btn-section" onClick={()=>{
                
                if(currentPage > 1){
                    setCurrentPage(prev =>{
                        setCurrentPage(prev - 1)
                    });
                    if((currentPage -1)% pageNumberLimit === 0){
                        setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
                        setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
                    }
                }
            }}>
                <LeftArrowIcon stroke={currentPage === 1 ? "#CCCCCC": "#009BDF"}/>
            </div>
            <div className='pagination-section'>
                {
                    pageNumbers?.map(number => {
                        return (
                            number < maxPageNumberLimit + 1 && number > minPageNumberLimit &&
                                <div key={`pagination${number}`} onClick={(e) => {
                                    setCurrentPage(number)
                                }}
                                    className={currentPage === number ? "pagination-item-section pagination-item-active" :
                                        "pagination-item-section"} id={number}>
                                    {number}
                                </div>
                                
                        )
                    })
                }
            </div>
            <div className="pagination-btn-section" onClick={()=>{
                if(currentPage < lastPageNumber){
                    setCurrentPage(prev =>{
                        setCurrentPage(prev + 1)
                    });
                    
                    if(currentPage + 1 > maxPageNumberLimit){
                        setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
                        setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
                    }
                }
            }}>
                <RightArrowIcon stroke={currentPage === lastPageNumber ? "#CCCCCC": "#009BDF"} />
            </div>
        </div>
    )
}

export default Pagination