import React from "react";
import { ApiConstant } from "../../../../Assets/Constants/ApiConstants";
import { Constent } from "../../../../Assets/Constants/Constent";

import InventoryRequestStockContent from "./InventoryHelper/InventoryRequestStockContent";
import InventoryTrackStockContent from "./InventoryHelper/InventoryTrackStockContent";
import InventoryViewStockContent from "./InventoryHelper/InventoryViewStockContent";

const InventoryBodyContent = ({
  userDataObj,
  globalState,
  pageState,
  setPageState,
  manageStockList,
  requestStockList,
  trackRequestStockList,
  trackReturnStockList,
  trackTransferStockList,
  invDataObj,
}) => {
  /* 
  Contions are Requried -
      - Paggination Nulmber List
      - If activePill !== "Manage" --> Call the api and populate the date using pagination
      - If activePill === "Manage" and activeRadioBtn === View 
          - Call the view stock generic api where store id getting from user info
          - Handel different combination request
      - If activePill === "Manage" and activeRadioBtn === Request
          - Call the view stock generic api where store id is primary (currently hard coded)
          - Handel different combination request
      
  */
  return (
    <div className="body-content-section">
      {/* Paggination */}
      {/* Body Content - Card */}
      <div className="wrapper-body-content-section">
        {pageState?.activePill === Constent.BODY_HEADER_INVENTORY_PILL_ITEM1 ? (
          pageState?.activeRadioBtn ===
          Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1 ? (
            <InventoryViewStockContent
              globalState={globalState}
              pageState={pageState}
              userDataObj={userDataObj}
              setPageState={setPageState}
              manageStockList={manageStockList}
              invDataObj={invDataObj}
            />
          ) : (
            <InventoryRequestStockContent
              globalState={globalState}
              pageState={pageState}
              setPageState={setPageState}
              requestStockList={requestStockList}
              invDataObj={invDataObj}
            />
          )
        ) : pageState?.activeRadioBtn ===
          Constent.REQUEST_STOCK_RADIO_BTN_LABLE ? (
          <InventoryTrackStockContent
            globalState={globalState}
            pageState={pageState}
            setPageState={setPageState}
            pageLoader={pageState?.trackStockRequestDataLoader}
            trackStockListFilterView={trackRequestStockList?.length > 0}
            trackStockList={trackRequestStockList}
            getTrackStockDataError={pageState?.getTrackStockRequestDataError}
            getTrackStockDataErrorMsg={
              pageState?.getTrackStockRequestDataErrorMsg
            }
            requestType={ApiConstant.INVENTORY_TRACK_STOCK_REQUEST_TYPE}
            trackRequestDetailsPage={
              pageState?.activeRadioBtn ===
              Constent.REQUEST_STOCK_RADIO_BTN_LABLE
            }
          />
        ) : pageState?.activeRadioBtn ===
          Constent.RETURN_STOCK_RADIO_BTN_LABLE ? (
          <InventoryTrackStockContent
            globalState={globalState}
            pageState={pageState}
            setPageState={setPageState}
            getTrackStockDataError={pageState?.getTrackStockReturnDataError}
            getTrackStockDataErrorMsg={
              pageState?.getTrackStockReturnkDataErrorMsg
            }
            requestType={ApiConstant.INVENTORY_TRACK_STOCK_RETURN_TYPE}
            pageLoader={pageState?.trackStockReturnDataLoader}
            trackStockListFilterView={trackReturnStockList?.length > 0}
            trackStockList={trackReturnStockList}
          />
        ) : (
          <InventoryTrackStockContent
            globalState={globalState}
            pageState={pageState}
            setPageState={setPageState}
            pageLoader={pageState?.trackStockTransferDataLoader}
            trackStockListFilterView={trackTransferStockList?.length > 0}
            trackStockList={trackTransferStockList}
            getTrackStockDataError={pageState?.getTrackStockTransferDataError}
            getTrackStockDataErrorMsg={
              pageState?.getTrackStockTransferDataErrorMsg
            }
            requestType={ApiConstant.INVENTORY_TRANSFER_STOCK_REQUEST_TYPE}
            trackRequestDetailsPage={
              pageState?.activeRadioBtn ===
              Constent.TRANSFER_STOCK_RADIO_BTN_LABLE
            }
          />
        )}
      </div>
    </div>
  );
};

export default InventoryBodyContent;
