import { createSlice } from "@reduxjs/toolkit";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";

const initialState = {
    installerNameOrContact: "",
    addInstallerResponse: null
}

export const installerDataState = createSlice({
    name: 'installerDataState',
    initialState,
    reducers: {
        setInstallerData: (state, action) => {
            const { type, value } = action.payload;
            switch (type) {
                case ApiConstant.MANAGE_INSTALLER_INPUT:
                    return { ...state, installerNameOrContact: value };
                case ApiConstant.CREATE_INSTALLER_RESPONSE:
                    return { ...state, addInstallerResponse: value };
                default:
                    break;
            }
        },
        /* ---- Reset Data ---- */
        resetInstallerData: () => initialState
    }
})

export const { setInstallerData, resetInstallerData } = installerDataState.actions;

export default installerDataState.reducer;