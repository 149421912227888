import moment from "moment";
import { React, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-date-picker";
import { Constent } from "../../Assets/Constants/Constent";
import {
  CalendarIcon,
  CloseModalIcon,
  SearchIcon,
} from "../../Assets/Images/CelcomSvgImages";
import { workOrderSearchPageState } from "../../ReduxFeature/StateFeatures/WorkOrderDataState";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";

const WorkOrderAdvanceSearchModal = ({
  pageState,
  setPageState,
  searchWorkOrderListApiCall,
  workOrderDataObj,
}) => {
  const dispatch = useDispatch();
  const { workOrderId, workOrderFromDate, workOrderToDate, searchType } =
    workOrderDataObj;
  const userDataObj = useSelector(
    (state) => state.userDataInfo?.userData?.loginUserData
  );
  const [userRole, setUserRole] = useState(userDataObj?.userRole || null);

  return (
    <div
      className="modal fade advance-search-work-order-modal"
      id="workOrderAdvanceSearch"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby="workOrderAdvanceSearchLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pb-0">
            <div className="wrapper-modal-header pt-5">
              <div className="modal-header-title-section">
                <h5 className="modal-title" id="workOrderAdvanceSearchLabel">
                  {Constent.WORK_ORDER_ADVANCED_SEARCH_MODAL_TITLE}
                </h5>
                <SearchIcon stroke={"#00000080"} />
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <CloseModalIcon />
                </span>
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="wrapper-modal-body">
              <div className="mobal-body-section-group">
                <div className="card-item-group">
                  <div className="form-group m-0 custom-drop-down-form-group">
                    <select
                      className="custom-select"
                      onChange={(e) => {
                        const { value } = e.target;
                        dispatch(
                          workOrderSearchPageState({
                            type: Constent.WORK_ORDER_REQUEST_TYPE1,
                            value: value,
                          })
                        );
                      }}
                    >
                      <option key={`advanceRequestType1`} value="">
                        {searchType}
                      </option>
                      <option
                        key={Constent.WORK_ORDER_INPUT_ITEM_LABEL1}
                        value={Constent.WORK_ORDER_INPUT_ITEM_LABEL1}
                      >
                        {Constent.WORK_ORDER_INPUT_ITEM_LABEL1}
                      </option>
                      <option
                        key={Constent.TRACK_ORDER_INPUT_ITEM_LABEL1}
                        value={Constent.TRACK_ORDER_INPUT_ITEM_LABEL1}
                      >
                        {Constent.TRACK_ORDER_INPUT_ITEM_LABEL1}
                      </option>

                      <option
                        key={Constent.WORK_ORDER_INPUT_ITEM_LABEL2}
                        value={Constent.WORK_ORDER_INPUT_ITEM_LABEL2}
                      >
                        {Constent.WORK_ORDER_INPUT_ITEM_LABEL2}
                      </option>
                    </select>
                    <label htmlFor={Constent.WORK_ORDER_REQUEST_TYPE1}>
                      {Constent.WORK_ORDER_REQUEST_TYPE1}
                    </label>
                  </div>
                </div>
                <div className="card-item-group">
                  <div className="form-group mb-0 custom-input-form-group ">
                    <input
                      type={
                        searchType === Constent.WORK_ORDER_INPUT_ITEM_LABEL1
                          ? "number"
                          : "text"
                      }
                      className="form-control"
                      value={pageState?.workOrderId}
                      id="workorderId"
                      placeholder=" "
                      onChange={(e) => {
                        setPageState({
                          ...pageState,
                          workOrderId: e.target.value,
                        });
                        dispatch(
                          workOrderSearchPageState({
                            type: ApiConstant.WORK_ORDER_ID,
                            value: e.target.value,
                          })
                        );
                      }}
                    />
                    <label htmlFor="workorderId">{searchType}</label>
                    <span className="invalid-msg-text">
                      {Constent.INVALID_INPUT}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mobal-body-section-group">
                {Constent.FREE_CONTENT_TEXT2}
              </div>
              <div className="mobal-body-section-group">
                <div className="card-item-group">
                  <div className="form-group mb-0 custom-input-form-group">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="formDate"
                        placeholder=" "
                        value={pageState?.workOrderFromDate}
                        onChange={(e) => {
                          setPageState({
                            ...pageState,
                            workOrderFromDate: e.target.value,
                          });
                          dispatch(
                            workOrderSearchPageState({
                              type: ApiConstant.WORK_ORDER_FROM_DATE,
                              value: e.target.value,
                            })
                          );
                        }}
                      />
                      <label htmlFor="formDate">
                        {Constent.DATE_RANGE_INPUT_ITEM_LABEL1}
                      </label>
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <CalendarIcon fill={"#333333"} />
                        </span>
                        <div className="input-group-append-date-picker-section">
                          <DatePicker
                            selected={moment()}
                            onChange={(date) => {
                              setPageState({
                                ...pageState,
                                workOrderFromDate:
                                  moment(date)?.format("YYYY-MM-DD"),
                              });
                              dispatch(
                                workOrderSearchPageState({
                                  type: ApiConstant.WORK_ORDER_FROM_DATE,
                                  value: moment(date)?.format("YYYY-MM-DD"),
                                })
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <span className="invalid-msg-text">
                      {Constent.INVALID_INPUT}
                    </span>
                  </div>
                </div>
                <div className="card-item-group">
                  <div className="form-group mb-0 custom-input-form-group">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id={Constent.DATE_RANGE_INPUT_ITEM_LABEL2}
                        placeholder=" "
                        value={pageState?.workOrderToDate}
                        onChange={(e) => {
                          setPageState({
                            ...pageState,
                            workOrderToDate: e.target.value,
                          });
                          dispatch(
                            workOrderSearchPageState({
                              type: ApiConstant.WORK_ORDER_TO_DATE,
                              value: e.target.value,
                            })
                          );
                        }}
                      />
                      <label htmlFor={Constent.DATE_RANGE_INPUT_ITEM_LABEL2}>
                        {Constent.DATE_RANGE_INPUT_ITEM_LABEL2}
                      </label>
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <CalendarIcon fill={"#333333"} />
                        </span>
                        <div className="input-group-append-date-picker-section">
                          <DatePicker
                            selected={moment()}
                            onChange={(date) => {
                              setPageState({
                                ...pageState,
                                workOrderToDate:
                                  moment(date)?.format("YYYY-MM-DD"),
                              });
                              dispatch(
                                workOrderSearchPageState({
                                  type: ApiConstant.WORK_ORDER_TO_DATE,
                                  value: moment(date)?.format("YYYY-MM-DD"),
                                })
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <span className="invalid-msg-text">
                      {Constent.INVALID_INPUT}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mobal-body-section-group">
                <div className="card-item-group">
                  <div className="form-group m-0 custom-drop-down-form-group">
                    <select
                      className="custom-select"
                      onChange={(e) => {
                        const { value } = e.target;

                        if (value === "select") {
                          setPageState({
                            ...pageState,
                            advanceSearchSubLovs: [],
                            selectedAdvanceSearchType: value,
                          });
                        } else {
                          setPageState({
                            ...pageState,
                            selectedAdvanceSearchType: value,
                            advanceSearchSubLovs:
                              pageState?.advanceSearchLovs[
                                pageState?.advanceSearchLovs?.findIndex(
                                  (obj) => obj?.value === value
                                )
                              ]?.sub_Item,
                            selectedAdvanceSearchSubTypeList: [],
                          });
                        }
                      }}
                      value={pageState?.selectedAdvanceSearchType}
                    >
                      <option key={`advanceSearchLovsEmpty1`} value="">
                        -- Select --
                      </option>
                      <option key={`advanceSearchLovsEmpty2`} value="">
                        -- Select --
                      </option>
                      {pageState?.advanceSearchLovs?.map((data, index) => {
                        return (
                          <option
                            key={`advanceSearchLovs${index}`}
                            value={`${data?.value}`}
                          >
                            {data?.display}
                          </option>
                        );
                      })}
                    </select>
                    <label htmlFor={Constent.WORK_ORDER_CARD_ITEM_TITLE2}>
                      {Constent.WORK_ORDER_CARD_ITEM_TITLE2}
                    </label>
                  </div>
                </div>
              </div>
              <div className="mobal-body-section-group">
                <div className="card-item-group">
                  {pageState?.advanceSearchSubLovs?.map((data, index) => {
                    return (
                      <div
                        className="custom-control custom-checkbox"
                        key={`advanceSearchSubLovs${index}`}
                      >
                        <input
                          className="custom-control-input"
                          type="checkbox"
                          value=""
                          checked={pageState?.selectedAdvanceSearchSubTypeList?.includes(
                            data.value
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const isFindIndex =
                                pageState?.selectedAdvanceSearchSubTypeList?.findIndex(
                                  (obj) => obj === data.value
                                );

                              if (isFindIndex === -1) {
                                setPageState({
                                  ...pageState,
                                  selectedAdvanceSearchSubTypeList: [
                                    ...pageState?.selectedAdvanceSearchSubTypeList,
                                    data?.value,
                                  ],
                                });
                              }
                            } else {
                              setPageState({
                                ...pageState,
                                selectedAdvanceSearchSubTypeList:
                                  pageState?.selectedAdvanceSearchSubTypeList?.filter(
                                    (val) => val !== data?.value
                                  ),
                              });
                            }
                          }}
                          id={`${data?.value}`}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`${data?.value}`}
                        >
                          {data?.display}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* ----------CR-5079 Changes Start---------*/}
          {userRole === Constent.ADMIN_ROLE ? (
            <div className="mobal-body-section-group">
              <div className="card-item-group">
                <div className="form-group m-0 custom-drop-down-form-group">
                  <select
                    className="custom-select"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value === "All_Appointments") {
                        setPageState({
                          ...pageState,
                          selectedAppointmenttype: value,
                          selectedSuperAdminMonitoring: true,
                        });
                      } else {
                        setPageState({
                          ...pageState,
                          selectedAppointmenttype: value,
                          selectedSuperAdminMonitoring: false,
                        });
                      }
                    }}
                    value={pageState?.selectedAppointmenttype}
                  >
                    <option
                      key={`advanceSearchAppointmentTypeLovsEmpty1`}
                      value=""
                    >
                      -- Select --
                    </option>
                    <option
                      key={`advanceSearchAppointmentTypeLovsEmpty2`}
                      value=""
                    >
                      -- Select --
                    </option>
                    {pageState?.advanceSearchAppointmentTypeLovs?.map(
                      (data, index) => {
                        return (
                          <option
                            key={`advanceAppointmentTypeSearchLovs${index}`}
                            value={`${data?.value}`}
                          >
                            {data?.display}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <label htmlFor={Constent.WORK_ORDER_CARD_ITEM_TITLE13}>
                    {Constent.WORK_ORDER_CARD_ITEM_TITLE13}
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {/* ----------CR-5079 Changes End---------*/}
          <div className="modal-footer">
            <div
              className="refresh-btn-section"
              aria-label="Close"
              onClick={() => {
                dispatch(
                  workOrderSearchPageState({
                    type: ApiConstant.WORK_ORDER_RESET_FIELD,
                    value: null,
                  })
                );
                setPageState({
                  ...pageState,
                  workOrderId: "",
                  workOrderFromDate: "",
                  workOrderToDate: "",
                  advanceSearchLovs: ApiConstant.WORK_ORDER_ADVANCE_SEARCH_LOV1,
                  advanceSearchSubLovs: [],
                  selectedAdvanceSearchType: "",
                  selectedAdvanceSearchSubTypeList: [],
                });
              }}
            >
              <span>{Constent.REFRESH_BTN}</span>
            </div>
            <div
              className="search-btn-section"
              data-dismiss="modal"
              aria-label="Close"
              onClick={searchWorkOrderListApiCall}
            >
              <SearchIcon />
              {Constent.SEARCH_BTN}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkOrderAdvanceSearchModal;
