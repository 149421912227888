import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ApiConstant } from '../../../../Assets/Constants/ApiConstants';
import { Constent } from '../../../../Assets/Constants/Constent';
import { RightArrowIcon } from '../../../../Assets/Images/CelcomSvgImages';
import { selectedWorkOrderItem } from '../../../../ReduxFeature/StateFeatures/WorkOrderDataState';
import { AppoinmentSubTypeConverter, AppoinmentTypeConverter } from '../../../Common/GenericFunctions';

const WorkOrderSummaryCard = ({ data, status }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    return (
        <div className='workorder-card-section'>
            <div className="wrapper-workorder-card-section">
                <div className="workorder-card-item-section" onClick={() => {
                    dispatch(selectedWorkOrderItem(data))
                    navigate("/workorderdetails");
                }}>
                    <div className="wrapper-workorder-card-item-group">
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.WORK_ORDER_CARD_ITEM_TITLE1}
                            </div>
                            <div className="card-item-value">
                                {data?.itemId?.replaceAll("FieldActivity-", "") || "-"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="workorder-card-item-section" onClick={() => {
                    dispatch(selectedWorkOrderItem(data))
                    navigate("/workorderdetails");
                }}>
                    <div className="workorder-card-item-group">
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.WORK_ORDER_CARD_ITEM_TITLE2}
                            </div>
                            <div className="card-item-value">
                                {AppoinmentTypeConverter({ type: data?.itemTypeId?.replaceAll("_", " ") || "-" })}
                            </div>
                        </div>
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.WORK_ORDER_CARD_ITEM_TITLE3}
                            </div>
                            <div className="card-item-value">
                                {AppoinmentSubTypeConverter({
                                    subType:
                                        data?.itemTypeId?.includes(ApiConstant.WOKR_ORDER_OST_TYPE) ?
                                            data?.itemSubTypeId?.replaceAll(ApiConstant.WOKR_ORDER_OST_TYPE_MAP, "")
                                            :
                                            data?.itemSubTypeId?.replaceAll(data?.itemTypeId, "")?.replaceAll("_", " ") || "-" ,
                                    type: data?.itemTypeId?.replaceAll("_", " ") || "-" 
                                })}
                            </div>

                        </div>
                    </div>
                    <div className="workorder-card-item-group">
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.WORK_ORDER_CARD_ITEM_TITLE4}
                            </div>
                            <div className="card-item-value">
                                {data?.resolutionDate || "-"}
                            </div>
                        </div>
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.WORK_ORDER_CARD_ITEM_TITLE5}
                            </div>
                            <div className="card-item-value">
                                {data?.itemDetails?.itemSummary?.substring(
                                    data?.itemDetails?.itemSummary?.indexOf("DistStrt") + 9,
                                    data?.itemDetails?.itemSummary?.indexOf("DistEnd")
                                ) || "-"}
                            </div>
                        </div>
                    </div>
                    <div className="workorder-card-item-group">
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.WORK_ORDER_CARD_ITEM_TITLE6}
                            </div>
                            <div className="card-item-value">
                                {data?.assignedTo?.split(" ")[0] || "-"}
                            </div>
                        </div>
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.WORK_ORDER_CARD_ITEM_TITLE7}
                            </div>
                            <div className="card-item-value">
                                {status || "-"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="workorder-card-item-section" role="button" onClick={() => {
                    dispatch(selectedWorkOrderItem(data))
                    navigate("/workorderdetails");
                }}>
                    <RightArrowIcon stroke={"#009BDF"} />
                </div>
            </div>
        </div>
    )
}

export default WorkOrderSummaryCard;