import React from "react";
import { useNavigate } from "react-router-dom";
import { headers, RequestStock } from "../../Api/ApiCalls";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { Constent } from "../../Assets/Constants/Constent";
import { toast } from "react-toastify";
import {
  CloseModalIcon,
  ConfirmationIcon,
} from "../../Assets/Images/CelcomSvgImages";
import { RequestPlaceOrderTable } from "../Common/Table";
import {
  removeRequestStockData,
  removeSelectedRequestStockData,
  setConfirmationPageData,
} from "../../ReduxFeature/StateFeatures/CartDataState";
import { useDispatch } from "react-redux";

const RequestStockPlaceOrderModal = ({
  selectedRequestStockList,
  userDataObj,
  pageState,
  setPageState,
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  function requestStockApiCall() {
    setPageState({ ...pageState, apiCallAction: true });
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);

    const listOfItemDetailsRequest = [];
    selectedRequestStockList?.map((selectedItem) => {
      listOfItemDetailsRequest.push({
        sapMaterialCode: selectedItem?.sAPMaterialCode,
        transferQuantity: selectedItem?.reqQuantity,
      });
    });

    const request = {
      comments: "null",
      createdBy: userDataObj?.userData?.activeUserData?.userData?.userId,
      listOfItemDetailsRequest: listOfItemDetailsRequest,
      requestType: "RequestFromHSBB",
      towId: null,
      transferFromStoreId: ApiConstant.INVENTORY_REQUESTING_STORE_ID,
      transferToStoreId:
        userDataObj?.userData?.activeUserData?.userData?.outletId,
    };
    RequestStock("/fibre/inventory/createStockTransferRequest", request, header)
      .then((response) => {
        setPageState({ ...pageState, apiCallAction: false });
        if (response?.transferId?.length > 0) {
          dispatch(
            setConfirmationPageData({
              comment: Constent.CART_REQUEST_STOCK_SUCESSFULL_MSG,
              listOfIds: [response?.transferId],
            })
          );
          selectedRequestStockList?.map((itemData) => {
            dispatch(removeSelectedRequestStockData(itemData));
            dispatch(removeRequestStockData(itemData));
          }) && navigate("/cart/confirm");
        }
      })
      .catch((error) => {
        setPageState({ ...pageState, apiCallAction: false });
        let errorMessage;
        if (error.response.status === 500 || error.response.status === 503) {
          errorMessage = ApiConstant.SUBMIT_REQUEST_STOCK_ERROR_MESSAGE;
          toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        } else {
          errorMessage = error.response.statusText;
          toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        }
      });
  }
  return (
    <div
      className="modal fade request-stock-place-order-modal"
      id="requestStockPlaceOrderModal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby="requestStockPlaceOrderModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pb-0">
            <div className="wrapper-modal-header pt-5">
              <div className="modal-header-title-section">
                <ConfirmationIcon />
                <h5
                  className="modal-title"
                  id="requestStockPlaceOrderModalLabel"
                >
                  {Constent.CONFIRMATION_MODAL_TITLE}
                </h5>
                <h5 className="modal-title">
                  {Constent.REQUEST_STOCK_PLACE_ORDER_MODAL_TITLE}
                </h5>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <CloseModalIcon />
                </span>
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="wrapper-modal-body">
              <RequestPlaceOrderTable
                title1={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE1}
                title2={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE2}
                title3={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE3}
                title4={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE4}
                title5={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE8}
                data={selectedRequestStockList}
              />
            </div>
          </div>
          <div className="modal-footer">
            <div className="refresh-btn-section" data-dismiss="modal">
              <span>{Constent.EDIT_QUANTITY_BTN}</span>
            </div>
            <div
              className="search-btn-section"
              data-dismiss="modal"
              onClick={requestStockApiCall}
            >
              {Constent.CONFIRMATION_BTN}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestStockPlaceOrderModal;
