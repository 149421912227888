import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Constent } from "../../../../Assets/Constants/Constent";
import {
  AddIcon,
  BackArrowIcon,
  SubIcon,
} from "../../../../Assets/Images/CelcomSvgImages";
import {
  saveRequestStockData,
  saveReturnStockData,
  saveTransferStockData,
} from "../../../../ReduxFeature/StateFeatures/CartDataState";
import {
  selectedViewStockItem,
  setViewRequestStockQuntData,
} from "../../../../ReduxFeature/StateFeatures/InventoryDataState";

const ManageStockCard = ({ globalState, pageState, setPageState, data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartDataObj = useSelector((state) => state.cartDataInfo);
  const [itemMoved, setItemMoved] = useState(false);

  useEffect(() => {
    cartDataObj?.requestStockList?.map((item) => {
      if (
        item.sAPMaterialCode == data?.sAPMaterialCode &&
        pageState?.activeRadioBtn !==
          Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1
      )
        setItemMoved(true);
    });
    cartDataObj?.returnStockList?.map((item) => {
      if (
        item.sAPMaterialCode == data?.sAPMaterialCode &&
        pageState?.activeRadioBtn ===
          Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1
      )
        setItemMoved(true);
    });
    cartDataObj?.transferStockList?.map((item) => {
      if (
        item.sAPMaterialCode == data?.sAPMaterialCode &&
        pageState?.activeRadioBtn ===
          Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1
      )
        setItemMoved(true);
    });
  }, [cartDataObj]);

  return (
    <div className="manage-stock-card-section">
      <div className="item-moved-to-cart-card" hidden={!itemMoved}>
        &nbsp;Item moved to Cart&nbsp;
      </div>
      <div
        className={
          pageState?.activePill === Constent.BODY_HEADER_INVENTORY_PILL_ITEM1 &&
          pageState?.activeRadioBtn ===
            Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1
            ? "wrapper-manage-stock-card-section"
            : "wrapper-manage-stock-card-section request-stock-card-active"
        }
      >
        <div
          className="normal-card-item-section"
          role="button"
          onClick={() => {
            pageState?.activePill ===
              Constent.BODY_HEADER_INVENTORY_PILL_ITEM1 &&
              pageState?.activeRadioBtn === "View" &&
              data?.totalAvailableQty > 0 &&
              dispatch(selectedViewStockItem(data)) &&
              navigate("/inventory/view");
          }}
        >
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE1}
            </div>
            <div className="card-item-value">{data?.sAPMaterialCode}</div>
          </div>
        </div>
        <div
          className="normal-card-item-section"
          role="button"
          onClick={() => {
            pageState?.activePill ===
              Constent.BODY_HEADER_INVENTORY_PILL_ITEM1 &&
              pageState?.activeRadioBtn ===
                Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1 &&
              data?.totalAvailableQty > 0 &&
              dispatch(selectedViewStockItem(data)) &&
              navigate("/inventory/view");
          }}
        >
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE2}
            </div>
            <div className="card-item-value">{data?.itemName || "-"}</div>
          </div>
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE3}
            </div>
            <div className="card-item-value">{data?.brand || "-"}</div>
          </div>
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE4}
            </div>
            <div className="card-item-value">{data?.category || "-"}</div>
          </div>
          {pageState?.activePill ===
            Constent.BODY_HEADER_INVENTORY_PILL_ITEM1 &&
          pageState?.activeRadioBtn ===
            Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1 ? (
            <div className="card-item-group">
              <div className="card-item-title">
                {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE5}
              </div>
              <div className="card-item-value">{data?.totalQntity || "0"}</div>
            </div>
          ) : (
            <></>
          )}
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE6}
            </div>
            <div className="card-item-value">
              {data?.totalAvailableQty || "0"}
            </div>
          </div>
          {pageState?.activePill ===
            Constent.BODY_HEADER_INVENTORY_PILL_ITEM1 &&
          pageState?.activeRadioBtn ===
            Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1 ? (
            <div className="card-item-group">
              <div className="card-item-title">
                {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE7}
              </div>
              <div className="card-item-value">{"-"}</div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="normal-card-item-section">
          {pageState?.activePill ===
            Constent.BODY_HEADER_INVENTORY_PILL_ITEM1 &&
          pageState?.activeRadioBtn ===
            Constent.BODY_HEADER_INVENTORY_MANAGE_STOCK_SUB_ITEM1 ? (
            <>
              <div className="d-flex flex-row" style={{ width: 270 }}>
                <div
                  className={`normal-card-item-btn-section ${
                    data?.totalAvailableQty > 0
                      ? "active-card-item-btn-section"
                      : ""
                  }`}
                  onClick={() => {
                    data?.totalAvailableQty > 0 &&
                      dispatch(saveReturnStockData(data));
                  }}
                >
                  <BackArrowIcon />
                  <span>{Constent.INVENTORY_CARD_ITEM_BTN1_TEXT}</span>
                </div>
                &nbsp; &nbsp; &nbsp;
                <div
                  className={`normal-card-item-btn-section ${
                    data?.totalAvailableQty > 0
                      ? "active-card-item-btn-section"
                      : ""
                  }`}
                  onClick={() => {
                    data?.totalAvailableQty > 0 &&
                      dispatch(saveTransferStockData(data));
                  }}
                >
                  <BackArrowIcon />
                  <span>{Constent.INVENTORY_CARD_ITEM_BTN3_TEXT}</span>
                </div>
              </div>
            </>
          ) : (
            <div className="normal-card-item-inner-section">
              <div className="normal-card-item-add-qnt-section">
                <div className="normal-card-item-qnt-title">
                  {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE8}
                </div>
                <div className="card-item-qnt-section">
                  <div
                    className="card-item-qnt-btn"
                    onClick={() => {
                      data?.totalAvailableQty > 0 &&
                        dispatch(
                          setViewRequestStockQuntData({
                            materialCode: data?.sAPMaterialCode,
                            qunt:
                              data?.reqQuantity <= 0
                                ? 0
                                : data?.reqQuantity - 1,
                            totalAvailableQty: data?.totalAvailableQty,
                          })
                        );
                    }}
                  >
                    <SubIcon />
                  </div>
                  <input
                    className="card-item-qnt-input"
                    value={data?.reqQuantity || 0}
                    onChange={(e) => {
                      data?.totalAvailableQty > 0 &&
                        dispatch(
                          setViewRequestStockQuntData({
                            materialCode: data?.sAPMaterialCode,
                            qunt: e.target.value ? parseInt(e.target.value) : 0,
                            totalAvailableQty: data?.totalAvailableQty,
                          })
                        );
                    }}
                    type="number"
                  />
                  <div
                    className="card-item-qnt-btn"
                    onClick={() => {
                      data?.totalAvailableQty > 0 &&
                        dispatch(
                          setViewRequestStockQuntData({
                            materialCode: data?.sAPMaterialCode,
                            qunt: data?.reqQuantity + 1,
                            totalAvailableQty: data?.totalAvailableQty,
                          })
                        );
                    }}
                  >
                    <AddIcon stroke={"#009bdf"} />
                  </div>
                </div>
              </div>
              <div
                className={`normal-card-item-btn-section ${
                  data?.totalAvailableQty > 0 &&
                  data?.reqQuantity <= data?.totalAvailableQty
                    ? "active-card-item-btn-section"
                    : ""
                }`}
                onClick={() => {
                  data?.totalAvailableQty > 0 &&
                    data?.reqQuantity > 0 &&
                    dispatch(saveRequestStockData(data));
                }}
              >
                <AddIcon />
                {Constent.INVENTORY_CARD_ITEM_BTN2_TEXT}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageStockCard;
