import React from 'react';
import classes from './togglebutton.module.css';
function ToggleButton  ({
  text1,
  text2,
  label1,
  label2,
  primary_color1,
  color,
  checked,
  ...props
}) {
  return (
    <>
  <div className="row">
  <label className={checked?` ${classes.label}` :` ${classes.labelactive}`}>{label1}</label>
  <label className={` ${classes.switch}`} >
  <input  className={classes['switch-input']} type="checkbox" checked={checked} {...props}  />
  <span  className={classes['switch-label']} style={{backgroundColor:color}} dataPrimaryColor={primary_color1} data-on={text1} data-off={text2} ></span> 
  <span  className={classes['switch-handle']}  ></span> 
 </label>
 <label className={checked?` ${classes.labelactive}`:`${classes.label}`}>{label2}</label>
 </div>
  </>
);
};

export default ToggleButton;