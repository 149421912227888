import React from 'react';
import '../Assets/CSS/NotFoundPage.css';
import { Constent } from '../Assets/Constants/Constent';
import { PageNotFoundIcon } from '../Assets/Images/CelcomSvgImages';

const NotFoundPage = () => {
    return (
        <div className='notfound-page-section'>
            <div className="wrapper-notfound-page-section">
                <div className="notfound-image-section">
                    <PageNotFoundIcon />
                </div>
                <div className="notfound-page-content-section">
                    <div className="notfound-page-content-item-section">
                        <span>Oops!</span> {Constent.NOT_FOUND_PAGE_MESSAGE1}
                    </div>
                    <div className="notfound-page-content-item-section">
                        {Constent.NOT_FOUND_PAGE_MESSAGE2}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFoundPage;