import React from 'react';
import { ApiConstant } from '../../../Assets/Constants/ApiConstants';
import { Constent } from '../../../Assets/Constants/Constent';
import { DeleteIcon, DocumentIcon, EyeIcon, TickMarkIcon } from '../../../Assets/Images/CelcomSvgImages';

const FileUploadModalViewSection = ({ pageState, setPageState }) => {
    return (
        <div className="wrapper-modal-body-section">
            <div className="upload-document-modal-body-group-section">
                {Constent.UPLOAD_DOCUMENT_MODAL_BODY_TITLE2}
            </div>
            <div className="upload-document-modal-body-group-section">
                <div className="upload-document-modal-body-item-group-section">
                    <div className="upload-document-modal-body-item-group">
                        <div className="upload-document-modal-body-item">
                            {Constent.UPLOAD_DOCUMENT_MODAL_BODY_VIEW_TITLE1}
                        </div>
                        <div className="upload-document-modal-body-item text-center">
                            {Constent.UPLOAD_DOCUMENT_MODAL_BODY_VIEW_TITLE2}
                        </div>
                    </div>
                </div>
                {
                    pageState?.uploadedFileList?.length > 0 ?
                        <div className="upload-document-modal-body-item-group-section">
                            {
                                pageState?.uploadedFileList?.map((documentItem, index) => {
                                    if (documentItem?.uploadStatus === ApiConstant.UPLOAD_DOCUMENT_ITEM_PROGRESS_STATUS) {
                                        return (
                                            <div className="upload-document-modal-body-item-group uploading-in-progress"
                                                key={`inProgressDocument${documentItem?.name + index}`}>
                                                <div className="upload-document-modal-body-item">
                                                    <div className="upload-document-modal-body-inner-item-section"><DocumentIcon /></div>
                                                    <div className="upload-document-modal-body-inner-item-section">
                                                        {documentItem?.name || "-"}
                                                    </div>
                                                </div>
                                                <div className="upload-document-modal-body-item">
                                                    <div className="upload-document-modal-body-inner-item-section">
                                                        <span>{`${Constent.UPLOAD_DOCUMENT_MODAL_BODY_FILE_UPLOAD_LABEL}  `}</span>
                                                        {`${documentItem?.progress}%`}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else if (documentItem?.uploadStatus === ApiConstant.UPLOAD_DOCUMENT_ITEM_COMPLETE_STATUS) {
                                        return (
                                            <div className="upload-document-modal-body-item-group uploaded"
                                                key={`uploadedDocument${documentItem?.name + index}`}>
                                                <div className="upload-document-modal-body-item">
                                                    <div className="upload-document-modal-body-inner-item-section"><DocumentIcon /></div>
                                                    <div className="upload-document-modal-body-inner-item-section">
                                                        {documentItem?.name || "-"}
                                                    </div>
                                                    <div className="upload-document-modal-body-inner-item-section"><TickMarkIcon /></div>
                                                </div>
                                                <div className="upload-document-modal-body-item">
                                                    <div className="upload-document-modal-body-inner-item-section"><EyeIcon /></div>
                                                </div>
                                            </div>
                                        );
                                    }
                                    else {
                                        return (
                                            <div className="upload-document-modal-body-item-group"
                                                key={`selectedDocument${documentItem?.name + index}`}>
                                                <div className="upload-document-modal-body-item">
                                                    <div className="upload-document-modal-body-inner-item-section"><DocumentIcon /></div>
                                                    <div className="upload-document-modal-body-inner-item-section">
                                                        {documentItem?.name || "-"}
                                                    </div>
                                                </div>
                                                <div className="upload-document-modal-body-item">
                                                    <div className="upload-document-modal-body-inner-item-section" onClick={() => {
                                                        let objectURL;
                                                        const file = documentItem?.file;
                                                        objectURL = URL.createObjectURL(file);

                                                        window.open(objectURL, '_blank');
                                                        window.open(objectURL);
                                                    }}>
                                                        <EyeIcon />
                                                    </div>
                                                    <div className="upload-document-modal-body-inner-item-section" onClick={() => {
                                                        setPageState({
                                                            ...pageState,
                                                            uploadedFileList: pageState?.uploadedFileList?.filter(obj => obj.id != index)
                                                        })
                                                    }}><DeleteIcon /></div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })
                            }
                        </div>
                        :
                        <div className="text-center h-100 w-100 d-flex align-items-center justify-content-center">
                            There are no files available.
                        </div>
                }
            </div>
        </div>
    )
}

export default FileUploadModalViewSection;