import moment from 'moment';
import React from 'react';
import DatePicker from 'react-date-picker';
import { useDispatch } from 'react-redux';
import { ApiConstant } from '../../../../../Assets/Constants/ApiConstants';
import { Constent } from '../../../../../Assets/Constants/Constent';
import { DropDownIcon } from '../../../../../Assets/Images/CelcomSvgImages';
import { saveSummaryData } from '../../../../../ReduxFeature/StateFeatures/WorkOrderDataState';
import { NoDataFoundItemGroup } from '../../../../Common/CardContent';
import { NumberFormater } from '../../../../Common/GenericFunctions';
import { CardLoader } from '../../../../Loaders/Loaders';
import DashbordSidebarCard from '../../../Cards/Installer/DashbordSidebarCard';

const InstallerDashbordBodySidebar = ({ pageState, setPageState, summaryDate }) => {
    const dispatch = useDispatch();

    const statusColor = pageState?.activeSidebarNav === Constent.SUPERVISOR_SIDEBAR_SUBTYPE1 ? "installer-status-yellow" :
        pageState?.activeSidebarNav === Constent.SUPERVISOR_SIDEBAR_SUBTYPE5 ? "installer-status-red" :
            pageState?.activeSidebarNav === Constent.SUPERVISOR_SIDEBAR_SUBTYPE3 ? "installer-status-green" : "";

    return (
        <div className='installer-dashbord-sidebar-section'>
            <div className="wrapper-installer-dashbord-sidebar-section">
                <div className="installer-dashbord-sidebar-item-group-section">
                    <div className="wrapper-installer-dashbord-sidebar-item-group-section">
                        <div className="installer-dashbord-sidebar-item-section">
                            Work Order Summary
                        </div>
                        <div className="installer-dashbord-sidebar-item-section">
                            <div className="installer-sidebar-summary-item-group">
                                <div className="wrapper-installer-sidebar-summary-item">
                                    <div className="installer-sidebar-summary-item">
                                        <div className="installer-sidebar-summary-item-title">
                                            {Constent.CUSTOM_MONTH_PICKER_LABLE}
                                        </div>
                                        <div className="installer-sidebar-summary-item-value">
                                            {moment(summaryDate).format("MMMM, YYYY")}
                                        </div>
                                    </div>
                                    <DropDownIcon stroke={"#333333"} />
                                </div>
                                <div className="wrapper-installer-sidebar-summary-item">
                                    <div className="month-picker-item-section">
                                        <DatePicker selected={moment()} maxDetail="year" onChange={date => {
                                            dispatch(saveSummaryData({
                                                type: ApiConstant.SAVE_SUMMARY_DATE,
                                                value: date
                                            }))
                                        }}
                                            monthAriaLabel="Month" minDetail="decade" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="installer-dashbord-sidebar-item-group-section">
                    {/* Nav Section */}
                    <div className="installer-dashbord-sidebar-item-section">
                        <div className="installer-summary-details-nav-section">
                            <div className={`intaller-summary-details-nav-item-section 
                                summary-status-yellow 
                                ${pageState?.activeSidebarNav === Constent.SUPERVISOR_SIDEBAR_SUBTYPE1
                                && "intaller-summary-details-nav-item-active"}`} onClick={() => {
                                    setPageState({ ...pageState, activeSidebarNav: Constent.SUPERVISOR_SIDEBAR_SUBTYPE1 })
                                }}>
                                <div className="summary-details-nav-item-title">
                                    {Constent.SUPERVISOR_SIDEBAR_SUBTYPE1}
                                </div>
                                <div className="summary-details-nav-item-value">
                                    <NumberFormater
                                        numberValue={(pageState?.installerSidedarData !== null
                                            && pageState?.installerSidedarData[Constent.SUPERVISOR_SIDEBAR_SUBTYPE1]?.length)
                                            || "00"} />
                                </div>
                            </div>
                            <div className={`intaller-summary-details-nav-item-section 
                                summary-status-red 
                                ${pageState?.activeSidebarNav === Constent.SUPERVISOR_SIDEBAR_SUBTYPE5
                                && "intaller-summary-details-nav-item-active"}`} onClick={() => {
                                    setPageState({ ...pageState, activeSidebarNav: Constent.SUPERVISOR_SIDEBAR_SUBTYPE5 })
                                }}>
                                <div className="summary-details-nav-item-title">
                                    {Constent.SUPERVISOR_SIDEBAR_SUBTYPE5_TEXT}
                                </div>
                                <div className="summary-details-nav-item-value">
                                    <NumberFormater
                                        numberValue={(pageState?.installerSidedarData !== null
                                            && pageState?.installerSidedarData[Constent.SUPERVISOR_SIDEBAR_SUBTYPE5]?.length)
                                            || "00"} />
                                </div>
                            </div>
                            {/* <div className={`intaller-summary-details-nav-item-section 
                                summary-status-green 
                                ${pageState?.activeSidebarNav === Constent.SUPERVISOR_SIDEBAR_SUBTYPE3
                                && "intaller-summary-details-nav-item-active"}`} onClick={() => {
                                    setPageState({ ...pageState, activeSidebarNav: Constent.SUPERVISOR_SIDEBAR_SUBTYPE3 })
                                }}>
                                <div className="summary-details-nav-item-title">
                                    {Constent.SUPERVISOR_SIDEBAR_SUBTYPE3}
                                </div>
                                <div className="summary-details-nav-item-value">
                                    <NumberFormater
                                        numberValue={(pageState?.installerSidedarData !== null
                                            && pageState?.installerSidedarData[Constent.SUPERVISOR_SIDEBAR_SUBTYPE3]?.length)
                                            || "00"} />
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {/* Card Section */}
                    <div className="installer-dashbord-sidebar-item-section">
                        <div className={`wrapper-installer-sidebar-card-section ${statusColor}`}>
                            {
                                pageState?.dataLoader ?
                                    <>
                                        {
                                            [0, 1].map(data => {
                                                return (
                                                    <CardLoader key={`sidebarLoader${data}`} />
                                                )
                                            })
                                        }
                                    </>
                                    :
                                    pageState?.summaryDataError ?
                                        <NoDataFoundItemGroup message={pageState?.summaryDataErrorMessage} />
                                        :
                                        pageState?.installerSidedarData !== null
                                        && pageState?.installerSidedarData[pageState?.activeSidebarNav]?.map(data => {
                                            return (
                                                <DashbordSidebarCard data={data} key={`summary${pageState?.activeSidebarNav + data?.itemId}`} />
                                            );
                                        })
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default InstallerDashbordBodySidebar;