import React, { useEffect } from 'react';
import { Constent } from '../../Assets/Constants/Constent';
import { CloseModalIcon, LogoutIcon } from '../../Assets/Images/CelcomSvgImages';

const SessionExpireModal = ({ remaining, modalActive, setModalActive }) => {
    const dismissModal = () => {
        setModalActive(false);
        document.body.classList.remove("modal-open");
    }

useEffect(() => {
    if (remaining >= Constent.SESSION_EXPIRE_TIMEOUT_MESSAGE) {      // 13 mins popup an alert
        dismissModal()
    }
}, [remaining]);

    return (
        <div className={`modal fade logout-modal ${modalActive ? "show" : ""}`}
            id="sessionExpireModal" data-backdrop="static"
            data-keyboard="false" tabIndex="-1" aria-labelledby="sessionExpireModalLabel"
            aria-modal="true" role="dialog" style={{ display: modalActive ? "block" : "none" }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header pb-0">
                        <div className="wrapper-modal-header pt-5">
                            <div className="modal-header-title-section">
                                <LogoutIcon fill={"#FF9700"} />
                                <div className="modal-header-title-item">
                                    {Constent.LOGOUT_MODAL_TITLE1}
                                </div>
                                <div className="modal-header-title-item">
                                    {Constent.LOGOUT_MODAL_TITLE2}
                                </div>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={dismissModal}>
                                <span aria-hidden="true">
                                    <CloseModalIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body pt-0">
                        <div className="wrapper-modal-body">
                            {
                                // showCartPart && "Your session will expire in 2 mins. Click continue to proceed"
                                <span>Your session will expire in {(remaining / 1000) > 60 ?
                                    <>{Math.floor((remaining / 1000) / 60) + " mins"}</> :
                                    <>{Math.floor(remaining / 1000) + " sec"}</>}.</span>
                            }
                        </div>
                    </div>
                    {/* <div className="modal-footer">
                        <div className="okay-btn-section" data-dismiss="modal" onClick={dismissModal}>
                            <span>{Constent.CONTINUE_BTN}</span>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default SessionExpireModal;