import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  GenericLovCalls,
  headers,
  PostTest,
  TrackStockList,
} from "../../Api/ApiCalls";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { Constent } from "../../Assets/Constants/Constent";
import Footer from "../../Components/Footer/Footer";
import { Header } from "../../Components/Header/Header";
import AdminInventoryBodyContent from "../../Components/MainBody/BodyContent/Admin/AdminInventoryBodyContent";
import AdminInventoryBodyHeader from "../../Components/MainBody/BodyHeader/Admin/AdminInventoryBodyHeader";
import SupervisorSidebar from "../../Components/MainBody/Sidebar/SupervisorSidebar";
import {
  setStockListData,
  setTrackStockFieldValue,
} from "../../ReduxFeature/StateFeatures/InventoryDataState";
import { saveLovs } from "../../ReduxFeature/StateFeatures/LovDataState";
import { ToastContainer, toast } from "react-toastify";

const AdminInventoryPage = ({ globalState, setGlobalState }) => {
  const userDataObj = useSelector((state) => state.userDataInfo);
  const lovDataObj = useSelector((state) => state.lovDataInfo);

  const invDataObj = useSelector((state) => state.inventoryDataInfo);
  const dispatch = useDispatch();

  const {
    trackRequestStockList,
    trackReturnStockList,
    selectedTrackStockItem,
    selectedTrackStockItemDetails,
    trackStockRequestId,
    trackStockFormData,
    trackStockToData,
    trackStockRequestStatus,
  } = invDataObj;

  const [pageState, setPageState] = useState({
    totalItems: 0,
    trackRequestStockList: [],
    trackReturnStockList: [],
    trackStockActiveRadioBtn: Constent.REQUEST_STOCK_RADIO_BTN_LABLE,

    dataLoader: false,

    trackRequestStockListFilterView: false,
    trackReturnStockListFilterView: false,

    currentPage: 1,
    postPerPage: 10,
    getTrackStockRequestDataError: false,
    getTrackStockRequestDataErrorMsg: "",
  });
  function ErrorMessageSetUp({ error, message1, message2 }) {
    let errorMessage;
    if (error.response.status === 500 || error.response.status === 503) {
      errorMessage = message1;
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    } else if (error.response.status === 404) {
      errorMessage = message2;
      toast.warning(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    } else {
      errorMessage = error.response.statusText;
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    }
    return errorMessage;
  }
  function inventoryApiCalls() {
    setPageState({ ...pageState, dataLoader: true });
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);
    // Track Stock Part
    if (
      pageState?.trackStockActiveRadioBtn ===
      Constent.REQUEST_STOCK_RADIO_BTN_LABLE
    ) {
      const trackRequestStockRequest = {
        approverName: null,
        dateFrom: trackStockFormData?.length > 0 ? trackStockFormData : null,
        dateTo: trackStockToData?.length > 0 ? trackStockToData : null,
        requestType: "RequestFromHSBB",
        requestingStoreId: null,
        storeId: ApiConstant.INVENTORY_REQUESTING_STORE_ID,
        transferId:
          trackStockRequestId?.length > 0 ? trackStockRequestId : null,
        transferStatus:
          trackStockRequestStatus?.length > 0 ? trackStockRequestStatus : null,
        userId: null,
      };
      TrackStockList(
        "/fibre/inventory/getTransferListByDate",
        trackRequestStockRequest,
        header
      )
        .then((response) => {
          if (!response?.status?.includes("No data found")) {
            /*-------- Set The List in Redux ---------*/
            dispatch(
              setStockListData({
                type: ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE4,
                list: response?.transferListByDate,
              })
            );
            /*-------- Set the other Stafs in State ---------*/
            setPageState({
              ...pageState,
              dataLoader: false,
              getTrackStockRequestDataError: false,
              getTrackStockRequestDataErrorMsg: "",
            });
          } else {
            toast.warning(response?.status, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 10000,
              theme: "colored",
            });
            setPageState({
              ...pageState,
              dataLoader: false,
              getTrackStockRequestDataError: true,
              getTrackStockRequestDataErrorMsg: response?.status,
            });
          }
        })
        .catch((error) => {
          /*-------- Set The List in Redux ---------*/
          dispatch(
            setStockListData({
              type: ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE4,
              list: [],
            })
          );
          const errorMessage = ErrorMessageSetUp({
            error: error,
            message1: ApiConstant.VIEW_STOCK_LIST_ERROR_MESSAGE,
            message2: ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE,
          });
          setPageState({
            ...pageState,
            dataLoader: false,
            getTrackStockRequestDataError: true,
            getTrackStockRequestDataErrorMsg: errorMessage,
          });
        });
    } else {
      const trackTransferStockRequest = {
        approverName: null,
        dateFrom: trackStockFormData?.length > 0 ? trackStockFormData : null,
        dateTo: trackStockToData?.length > 0 ? trackStockToData : null,
        requestType: "TransferForHSBB",
        requestingStoreId: null,
        storeId: null,
        transferId:
          trackStockRequestId?.length > 0 ? trackStockRequestId : null,
        transferStatus:
          trackStockRequestStatus?.length > 0 ? trackStockRequestStatus : null,
        userId: null,
      };
      TrackStockList(
        "/fibre/inventory/getTransferListByDate",
        trackTransferStockRequest,
        header
      )
        .then((response) => {
          if (!response?.status?.includes("No data found")) {
            /*-------- Set The List in Redux ---------*/
            dispatch(
              setStockListData({
                type: ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE5,
                list: response?.transferListByDate,
              })
            );
            /*-------- Set the other Stafs in State ---------*/
            setPageState({
              ...pageState,
              dataLoader: false,
              getTrackStockRequestDataError: false,
              getTrackStockRequestDataErrorMsg: "",
            });
          } else {
            toast.warning(response?.status, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 10000,
              theme: "colored",
            });
            setPageState({
              ...pageState,
              dataLoader: false,
              getTrackStockRequestDataError: true,
              getTrackStockRequestDataErrorMsg: response?.status,
            });
          }
        })
        .catch((error) => {
          /*-------- Set The List in Redux ---------*/
          dispatch(
            setStockListData({
              type: ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE4,
              list: [],
            })
          );
          const errorMessage = ErrorMessageSetUp({
            error: error,
            message1: ApiConstant.VIEW_STOCK_LIST_ERROR_MESSAGE,
            message2: ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE,
          });
          setPageState({
            ...pageState,
            dataLoader: false,
            getTrackStockRequestDataError: true,
            getTrackStockRequestDataErrorMsg: errorMessage,
          });
        });
    }
  }

  useEffect(() => {
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);
    if (
      lovDataObj?.invrequeststatus === null ||
      lovDataObj?.invrequeststatus === undefined ||
      lovDataObj?.invrequeststatus?.length === 0
    ) {
      GenericLovCalls(
        "/fibre/getLovs",
        { searchBy: ApiConstant.LOV_INVENTORY_REQUEST_STATUS_KEY },
        header
      ).then((response) => {
        dispatch(
          saveLovs({
            key: ApiConstant.LOV_STORE_REQUEST_STATUS_KEY,
            value: response?.wrapperLovItemDataList,
          })
        );
      });
    }
    if (
      selectedTrackStockItem !== null &&
      selectedTrackStockItemDetails !== null
    ) {
      dispatch(
        setTrackStockFieldValue({
          type: ApiConstant.INVENTORY_TRACK_STOCK_RESET_SELECTED_DATA_ITEM,
          value: null,
        })
      );
    }
  }, []);

  //console.log(pageState.trackStockActiveRadioBtn);
  return (
    <div className="page-start-section">
      <Header
        userDataObj={userDataObj}
        globalState={globalState}
        setGlobalState={setGlobalState}
        tab4={true}
      />
      <div
        className={
          globalState?.sidebarState
            ? "body-start-section sidebar-active-section"
            : "body-start-section"
        }
      >
        {/* Sidebar */}
        <SupervisorSidebar
          userDataObj={userDataObj}
          globalState={globalState}
          setGlobalState={setGlobalState}
        />
        <div className="body-full-content-section">
          <AdminInventoryBodyHeader
            userDataObj={userDataObj}
            globalState={globalState}
            setGlobalState={setGlobalState}
            inventoryApiCalls={inventoryApiCalls}
            pageState={pageState}
            setPageState={setPageState}
          />

          <AdminInventoryBodyContent
            userDataObj={userDataObj}
            globalState={globalState}
            setGlobalState={setGlobalState}
            inventoryApiCalls={inventoryApiCalls}
            invDataObj={invDataObj}
            pageState={pageState}
            setPageState={setPageState}
          />
        </div>
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdminInventoryPage;
