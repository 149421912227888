import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { Constent } from "../../Assets/Constants/Constent";

import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import moment from "moment";

export const PagenationIndexPositionCal = (
  currentPage,
  postsParPage,
  posts
) => {
  const indexOfLastPost = currentPage * postsParPage;
  const indexOfFirstPost = indexOfLastPost - postsParPage;
  const displayPosts = posts?.slice(indexOfFirstPost, indexOfLastPost);

  return {
    indexOfLastPost: indexOfLastPost,
    indexOfFirstPost: indexOfFirstPost,
    displayPosts: displayPosts,
  };
};

export const AssignedToSameAsUserId = (workOrderData, userDataObj) => {
  let same = false;

  if (
    (userDataObj?.loginUserData?.userRole == "ADMIN" ||
      userDataObj?.loginUserData?.userRole == "SUPER") &&
    workOrderData?.assignedTo == userDataObj?.activeUserData?.userData?.userId
  ) {
    same = true;
  }

  return same;
};

export const FullCalendarDateList = (date) => {
  const startDate = date.clone().startOf("month").startOf("week");
  const endDate = date.clone().endOf("month").endOf("week");
  const day = startDate.clone().subtract(1, "day");
  const calendar = [];

  while (day.isBefore(endDate, "day")) {
    calendar.push(
      Array(7)
        .fill(0)
        .map(() => day.add(1, "day").clone())
    );
  }

  return calendar;
};

export const NumberFormater = ({ numberValue }) => {
  return (
    numberValue.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }) || "00"
  );
};

export const WorkOrderAdvanceSearchRequestType = ({ value = "" }) => {
  switch (value) {
    case Constent.WORK_ORDER_INPUT_ITEM_LABEL1:
      return "WORKORDERID";
    case Constent.TRACK_ORDER_INPUT_ITEM_LABEL1:
      return "ORDERNUMBER";
    case Constent.WORK_ORDER_INPUT_ITEM_LABEL2:
      return "CUSTOMERID";
  }
};
export const SupervisorWorkOrderStatusCal = (workOrderItem) => {
  /* ---- 
    If Close status status is Closed/ Returned  
        Status = closed/ returnd
        Class = status-green
    If Inprogress status is Assigned, SelfAssigned, Installer_Arrived, Installed and task Id != READY
        Status = In-Progress
        Class = status-yellow
    If Not Started status is Open and task Id ==  READY
        Status = Not Started
        Class = status-red
    */
  // Completed
  if (
    workOrderItem?.itemStatus === ApiConstant.WORK_ORDER_STATUS_CLOSED ||
    workOrderItem?.itemStatus === ApiConstant.WORK_ORDER_STATUS_RETURNED
  ) {
    return {
      status: workOrderItem?.itemStatus,
      addClass: "status-green",
    };
  }
  // Inprogress
  else if (
    (workOrderItem?.itemStatus === ApiConstant.WORK_ORDER_STATUS_ASSIGNED ||
      workOrderItem?.itemStatus ===
        ApiConstant.WORK_ORDER_STATUS_SELF_ASSIGNED ||
      workOrderItem?.itemStatus ===
        ApiConstant.WORK_ORDER_STATUS_INSTALLER_ARRIVED ||
      workOrderItem?.itemStatus ===
        ApiConstant.WORK_ORDER_STATUS_INSTALLATION_COMPLETED) &&
    workOrderItem?.taskId !== ApiConstant.WORK_ORDER_TASK_ID_READY
  ) {
    // console.log(moment(workOrderItem?.resolutionDate?.split(" ")[0],"DD-MM-YYYY HH:mm") >= moment(), moment(workOrderItem?.resolutionDate?.split(" ")[0],"DD-MM-YYYY").format("YYYY-MM-DD HH:mm"), moment(moment().format("YYYY-MM-DD")).isSame(moment(workOrderItem?.resolutionDate?.split(" ")[0],"DD-MM-YYYY").format("YYYY-MM-DD")))

    const currntTimeList = moment()?.format("HH:mm")?.split(":");
    const workOrderTimeList = workOrderItem?.resolutionDate
      ?.split(" ")[1]
      ?.split(":") || ["00", "00"];

    if (
      (moment(moment().format("YYYY-MM-DD")).isSame(
        moment(
          workOrderItem?.resolutionDate?.split(" ")[0],
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD")
      ) &&
        parseInt(currntTimeList[0]) >= parseInt(workOrderTimeList[0]) &&
        parseInt(currntTimeList[1]) > parseInt(workOrderTimeList[1])) ||
      moment(moment().format("YYYY-MM-DD")).isAfter(
        moment(
          workOrderItem?.resolutionDate?.split(" ")[0],
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD")
      )
    ) {
      // Aging
      return {
        status: Constent.SUPERVISOR_SIDEBAR_SUBTYPE2,
        addClass: "status-red",
      };
    } else {
      return {
        status: ApiConstant.WORK_ORDER_DISPLAY_STATUS_PROGRESS,
        addClass: "status-yellow",
      };
    }
  } else if (
    workOrderItem?.itemStatus === ApiConstant.WORK_ORDER_STATUS_OPEN &&
    workOrderItem?.taskId === ApiConstant.WORK_ORDER_TASK_ID_READY
  ) {
    return {
      status: ApiConstant.WORK_ORDER_DISPLAY_STATUS_NOT_STARTED,
      addClass: "status-red",
    };
  } else {
    return {
      status: workOrderItem?.itemStatus,
      addClass: "status-red",
    };
  }
};

export const ReportExtractionStatusCal = (itemObj) => {
  /* ---- */
  // Completed
  if (
    itemObj?.workOrderStatus === "IN_PROGRESS" ||
    itemObj?.workOrderStatus === "New"
  ) {
    return {
      status: "In Progress",
    };
  } else if (
    itemObj?.workOrderStatus === "Complete" ||
    itemObj?.workOrderStatus === "Closed"
  ) {
    return {
      status: "Closed",
    };
  } else {
    return {
      status: itemObj?.workOrderStatus
    };
  }
};

export const TrackOrderStatusCal = (trackOrderItem) => {
  /* ---- */
  // Completed
  if (trackOrderItem?.orderStatus === ApiConstant.TRACK_ORDER_COMPLETE_STATUS) {
    return {
      status: ApiConstant.TRACK_ORDER_COMPLETE_STATUS_DISPLAY,
      addClass: "status-green",
    };
  }
  // Inprogress
  else if (
    trackOrderItem?.orderStatus === ApiConstant.TRACK_ORDER_IN_PROGRESS_STATUS
  ) {
    return {
      status: ApiConstant.TRACK_ORDER_IN_PROGRESS_STATUS_DISPLAY,
      addClass: "status-yellow",
    };
  } else {
    return {
      status: ApiConstant.WORK_ORDER_DISPLAY_STATUS_NOT_STARTED,
      addClass: "status-red",
    };
  }
};

export const CustomDateFormator = ({ date }) => {
  return moment(new Date(date)).format("yyyy-MM-DD");
};

export const validNumberInput = (input) => {
  return /^\d+$/.test(input);
};

export const extractNumberFromString = (string) => {
  return string.replace(/^\D+/g, "");
};

export const ViewStockItemDetailsStatusCal = (item) => {
  /*
    Return status and className
    if is Status = IN STOCK then status="In Stock" and className="status-green"
    if is Status = UNAVAILABLE then status="Unavailable" and className="status-yellow"
    if is Status = SOLD then status="Sold" and className="status-red"
    else status black and "-"
    */
  switch (item?.status) {
    case ApiConstant.INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE2:
      return { status: "In Stock", className: "status-green" };
    case ApiConstant.INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE3:
      return { status: "Unavailable", className: "status-yellow" };
    case ApiConstant.INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE4:
      return { status: "Sold", className: "status-red" };
    default:
      return { status: "-", className: "status-black" };
  }
};

export const TrackStockItemStatusCal = (itemStatus) => {
  /*
    If Status are In-Transit, Receiving, Dispatched === status green
    else if are Receiving, Inbound-Picking, Awaiting Response, In-Progress, Receiving === status-yellow
    else if Rejected Request, Cancelled Request, Inbound-Picking, Inbound-Cancelled === status-red
    */
  if (ApiConstant.INVENTORY_TRACK_STOCK_COMPLETED_STATUS.includes(itemStatus)) {
    return { className: "status-green", displayAckBtn: true };
  } else if (
    ApiConstant.INVENTORY_TRACK_STOCK_PROGRESS_STATUS.includes(itemStatus)
  ) {
    return { className: "status-yellow", displayAckBtn: false };
  } else if (
    ApiConstant.INVENTORY_TRACK_STOCK_NOT_STARTED_STATUS.includes(itemStatus)
  ) {
    return { className: "status-red", displayAckBtn: false };
  } else {
    return { className: "", displayAckBtn: false };
  }
};

export const LovKeyDataValueNullOrEmpty = ({ listValue }) => {
  return (
    listValue === undefined || listValue === null || listValue?.length === 0
  );
};

export const AppoinmentTypeConverter = ({ type }) => {
  if (
    type?.includes(ApiConstant.WOKR_ORDER_OSI_TYPE) ||
    type?.includes(ApiConstant.WOKR_ORDER_OSI_TYPE.replaceAll("_", " "))
  ) {
    return ApiConstant.WOKR_ORDER_OSI_TYPE_DISPLAY;
  } else if (
    type?.includes(ApiConstant.WOKR_ORDER_OST_TYPE_MAP) ||
    type?.includes(ApiConstant.WOKR_ORDER_OST_TYPE_MAP.replaceAll("_", " "))
  ) {
    return ApiConstant.WOKR_ORDER_OST_TYPE_DISPLAY;
  } else {
    return type?.replaceAll("_", " ");
  }
};
export const AppoinmentSubTypeConverter = ({ subType, type = "" }) => {
  if (
    (type?.includes("Installation") || type?.includes("Site_Installation")) &&
    subType?.includes("Return") &&
    !(
      subType?.includes("Return_Relocation") ||
      subType?.includes("Return Relocation")
    )
  ) {
    return "New Installation";
  } else if (
    (type?.includes("Installation") || type?.includes("Site_Installation")) &&
    (subType?.includes("Return_Relocation") ||
      subType?.includes("Return Relocation"))
  ) {
    return ApiConstant.WORK_ORDER_OSI_RELOCATION_TYPE;
  } else if (
    subType?.includes(ApiConstant.WOKR_ORDER_OSI_SUBTYPE_MAP1) ||
    subType?.includes(
      ApiConstant.WOKR_ORDER_OSI_SUBTYPE_MAP1.replaceAll("_", " ")
    )
  ) {
    return ApiConstant.WOKR_ORDER_OSI_SUBTYPE_DISPLAY1;
  } else if (
    subType?.includes(ApiConstant.WOKR_ORDER_OST_SUBTYPE_MAP1) ||
    subType?.includes(
      ApiConstant.WOKR_ORDER_OST_SUBTYPE_MAP1.replaceAll("_", " ")
    )
  ) {
    return ApiConstant.WOKR_ORDER_OST_SUBTYPE_DISPLAY1;
  } else if (
    subType?.includes(ApiConstant.WOKR_ORDER_RELOCATION_SUBTYPE_MAP) ||
    subType?.includes(
      ApiConstant.WOKR_ORDER_RELOCATION_SUBTYPE_MAP.replaceAll("_", " ")
    )
  ) {
    return ApiConstant.WOKR_ORDER_RELOCATION_SUBTYPE_MAP;
  } else if (
    subType?.includes(ApiConstant.WOKR_ORDER_BTU_SUBTYPE_MAP1) ||
    subType?.includes(
      ApiConstant.WOKR_ORDER_BTU_SUBTYPE_MAP1.replaceAll("_", " ")
    )
  ) {
    return ApiConstant.WOKR_ORDER_BTU_SUBTYPE_DISPLAY1;
  } else if (
    subType?.includes(ApiConstant.WOKR_ORDER_BTU_SUBTYPE_MAP2) ||
    subType?.includes(
      ApiConstant.WOKR_ORDER_BTU_SUBTYPE_MAP2.replaceAll("_", " ")
    )
  ) {
    return ApiConstant.WOKR_ORDER_BTU_SUBTYPE_DISPLAY2;
  } else {
    return subType;
  }
};
/*-------- Installer -------------*/
export const InstallerWorkOrderStatusCal = ({ status }) => {
  if (ApiConstant.INSTALLER_WORK_ORDER_IN_PROGRESS_STATUS?.includes(status)) {
    return {
      itemStatus: Constent.SUPERVISOR_SIDEBAR_SUBTYPE1,
      addClass: "status-yellow",
    };
  } else if (ApiConstant.WORK_ORDER_STATUS_CLOSED === status) {
    return {
      itemStatus: Constent.SUPERVISOR_SIDEBAR_SUBTYPE3,
      addClass: "status-green",
    };
  } else if (ApiConstant.WORK_ORDER_STATUS_CANCLED === status) {
    return {
      itemStatus: status,
      addClass: "status-red",
    };
  } else {
    return {
      itemStatus: Constent.SUPERVISOR_SIDEBAR_SUBTYPE5_TEXT,
      addClass: "status-red",
    };
  }
};

export const EmptyValueHandler = ({ item }) => {
  return item || "-";
};

export const DonloadExcelFile = ({
  fileName = "Data",
  sheetName = ["Data"],
  downloadData = [],
}) => {
  const docType = "xlsx";

  const ws = XLSX.utils.json_to_sheet(downloadData);
  const wb = { Sheets: { Data: ws }, SheetNames: sheetName };
  const excelBuffer = XLSX.write(wb, { bookType: docType, type: "array" });
  const downloadDataXlsx = new Blob([excelBuffer], { type: docType });
  FileSaver.saveAs(downloadDataXlsx, fileName + ".xlsx");
};

export const FileConversion = ({ size }) => {
  return size * 0.00000095367432;
};

export const AllowedFileTypes = ({ extention }) => {
  let flag = false;

  ["doc", "docx", "xlsx", "pdf", "jpg", "jpeg", "png", "mp4", "MOV", "MKV"].map(
    (item) => {
      if (item.toLowerCase() === extention.toLowerCase()) {
        flag = true;
      }
    }
  );
  return flag;
};

export const SentenceCaseConverter = ({ string = "" }) => {
  return string
    ?.trim()
    ?.split(" ")
    .map((word) => word[0]?.toUpperCase() + word?.slice(1)?.toLowerCase())
    .join(" ");
};
