import { BodyBreadcrumbsWhitePage, BodyBreadcrumbsWhitePageChild2, BodyBreadcrumbsWhitePageChild3 } from "./BodyBreadcrumbs";

export const HeaderNavChild2 = ({ activeItem, backTab }) => {
    return (
        <div className="body-header-start-section">
            <div className="wrapper-body-header-section pb-4">
                <BodyBreadcrumbsWhitePage activeItem={activeItem} backTab={backTab} />
            </div>
        </div>
    );
}

export const HeaderNavChild3 = ({ activeItem, secondItem, secondTab }) => {
    return (
        <div className="body-header-start-section">
            <div className="wrapper-body-header-section pb-2">
                <BodyBreadcrumbsWhitePageChild2 activeItem={activeItem} secondItem={secondItem}
                    secondTab={secondTab} />
            </div>
        </div>
    );
}

export const HeaderNavChild4 = ({ activeItem, secondItem, secondTab, thirdItem, thirdTab }) => {
    return (
        <div className="body-header-start-section">
            <div className="wrapper-body-header-section pb-2">
                <BodyBreadcrumbsWhitePageChild3 activeItem={activeItem} secondItem={secondItem}
                    secondTab={secondTab} thirdItem={thirdItem} thirdTab={thirdTab} />
            </div>
        </div>
    );
}