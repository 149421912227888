import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userData: null,
    activeTab: '',
    switchUserBtn: false,
}


export const UserDataState = createSlice({
    name: 'userDataState',
    initialState,
    reducers: {
        // Creating the Actions
        /* ---- Login Data ---- */
        saveUserData: (state, action) => {
            state.userData = action.payload;
        },
        activeTab: (state, action) => {
            state.activeTab = action.payload;
        },
        switchUserBtn: (state, action) => {
            state.switchUserBtn = !action.payload;
        },
        changeActiveTab: (state, action) => {
            return { ...state, userData: { ...state?.userData, activeUserData: action.payload } }
        },
        /* ---- Reset Data ---- */
        resetData: () => initialState
    }
});

export const { saveUserData, switchUserBtn, activeTab, changeActiveTab, resetData } = UserDataState.actions;

export default UserDataState.reducer;
