import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Constent } from '../../../../Assets/Constants/Constent';
import { RightArrowIcon } from '../../../../Assets/Images/CelcomSvgImages';
import { selectedWorkOrderItem } from '../../../../ReduxFeature/StateFeatures/WorkOrderDataState';
import { AppoinmentSubTypeConverter, AppoinmentTypeConverter, InstallerWorkOrderStatusCal } from '../../../Common/GenericFunctions';
import { CardItemGroup } from '../../../Common/ModalHelper';

const InstallerWorkOrderCard = ({ workOrderData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { itemStatus, addClass } = InstallerWorkOrderStatusCal({ status: workOrderData?.itemStatus });

    return (
        <div className='workorder-card-section'>
            <div className={`wrapper-workorder-card-section ${addClass}`}>
                <div className="workorder-card-item-section">
                    <div className="wrapper-workorder-card-item-group">
                        <div className="card-item-group">
                            <div className="card-item-title">
                                {Constent.WORK_ORDER_CARD_ITEM_TITLE1}
                            </div>
                            <div className="card-item-value">
                                {workOrderData?.itemId?.replaceAll("FieldActivity-", "") || "-"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="workorder-card-item-section" role="button" onClick={() => {
                    dispatch(selectedWorkOrderItem(workOrderData)) && navigate("/workorderdetails")
                }}>
                    <CardItemGroup title={Constent.WORK_ORDER_CARD_ITEM_TITLE2} value={AppoinmentTypeConverter({ type: workOrderData?.workOrderType || "-" })} />
                    <CardItemGroup title={Constent.WORK_ORDER_CARD_ITEM_TITLE3} value={AppoinmentSubTypeConverter({ subType: workOrderData?.workOrderSubType || "-" , type: workOrderData?.workOrderType})} />
                    <CardItemGroup title={Constent.WORK_ORDER_CARD_ITEM_TITLE4} value={workOrderData?.resolutionDate?.split("T")[0] || "-"} />
                    <CardItemGroup title={Constent.WORK_ORDER_CARD_ITEM_TITLE7} value={itemStatus || "-"} />
                </div>
                <div className="workorder-card-item-section" role="button" onClick={() => {
                    dispatch(selectedWorkOrderItem(workOrderData)) && navigate("/workorderdetails")
                }}>
                    <RightArrowIcon stroke={"#009BDF"} />
                </div>
            </div>
        </div>
    )
}

export default InstallerWorkOrderCard;