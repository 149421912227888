import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ApiConstant } from "../../../../Assets/Constants/ApiConstants";
import { Constent } from "../../../../Assets/Constants/Constent";
import {
  AddIcon,
  DeleteIcon,
  SubIcon,
} from "../../../../Assets/Images/CelcomSvgImages";
import {
  removeTransferStockData,
  removeAllSelectedTransferStockData,
  removeSelectedTransferStockData,
  saveAllSelectedTransferStockData,
  saveSelectedTransferStockData,
  setCartTransferStockQuntData,
} from "../../../../ReduxFeature/StateFeatures/CartDataState";
import { setViewRequestStockQuntData } from "../../../../ReduxFeature/StateFeatures/InventoryDataState";

const TransferCartCard = ({
  data,
  index,
  pageState,
  setPageState,
  transferStockList,
  selectedTransferStockList,
}) => {
  const [checkBoxState, setCheckBoxState] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (pageState?.requestStockSelectAll) {
      dispatch(saveAllSelectedTransferStockData());
      if (!checkBoxState) setCheckBoxState(true);
    } else if (!pageState?.requestStockSelectAll) {
      dispatch(removeAllSelectedTransferStockData());
      if (checkBoxState) setCheckBoxState(false);
    }
  }, [pageState?.requestStockSelectAll]);

  useEffect(() => {
    if (checkBoxState) {
      const itemIndex = selectedTransferStockList?.findIndex(
        (obj) => obj?.sAPMaterialCode === data?.sAPMaterialCode
      );
      if (itemIndex !== -1) {
        dispatch(saveSelectedTransferStockData(data));
        if (selectedTransferStockList?.length == transferStockList?.length) {
          setPageState({ ...pageState, requestStockSelectAll: true });
        }
      }
    } else if (!checkBoxState) {
      dispatch(removeSelectedTransferStockData(data));
      if (selectedTransferStockList?.length == 0) {
        setPageState({ ...pageState, requestStockSelectAll: false });
      }
    }
  }, [checkBoxState]);

  return (
    <div className="request-stock-card-section">
      <div className="wrapper-request-stock-card-section">
        <div className="normal-card-item-section">
          <div className="card-item-checkbox">
            <div className="custom-control custom-checkbox">
              <input
                className="custom-control-input"
                type="checkbox"
                value=""
                checked={checkBoxState}
                id={`request${index}`}
                onChange={(e) => {
                  setCheckBoxState(e.target.checked);

                  if (e.target.checked) {
                    const itemIndex = selectedTransferStockList?.findIndex(
                      (obj) => obj?.sAPMaterialCode === data?.sAPMaterialCode
                    );
                    if (itemIndex === -1) {
                      dispatch(saveSelectedTransferStockData(data));
                    }
                  } else {
                    dispatch(removeSelectedTransferStockData(data));
                  }
                }}
              />
              <label
                className="custom-control-label"
                htmlFor={`request${index}`}
              ></label>
            </div>
          </div>
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE1}
            </div>
            <div className="card-item-value">
              {data?.sAPMaterialCode || "-"}
            </div>
          </div>
        </div>
        <div className="normal-card-item-section">
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE2}
            </div>
            <div className="card-item-value">{data?.itemName || "-"}</div>
          </div>
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE3}
            </div>
            <div className="card-item-value">{data?.brand || "-"}</div>
          </div>
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE4}
            </div>
            <div className="card-item-value">{data?.category || "-"}</div>
          </div>
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE6}
            </div>
            <div className="card-item-value">
              {data?.totalAvailableQty || "-"}
            </div>
          </div>
          <div className="normal-card-item-inner-section">
            <div className="normal-card-item-add-qnt-section">
              <div className="normal-card-item-qnt-title">
                {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE8}
              </div>
              <div className="card-item-qnt-section">
                <div
                  className="card-item-qnt-btn"
                  onClick={() => {
                    data?.totalAvailableQty > 0 &&
                      dispatch(
                        setViewRequestStockQuntData({
                          materialCode: data?.sAPMaterialCode,
                          qunt:
                            data?.reqQuantity <= 0 ? 0 : data?.reqQuantity - 1,
                          totalAvailableQty: data?.totalAvailableQty,
                        })
                      ) &&
                      dispatch(
                        setCartTransferStockQuntData({
                          type: ApiConstant.ADD_STOCK_QUNT_UPDATE,
                          value: {
                            materialCode: data?.sAPMaterialCode,
                            qunt:
                              data?.reqQuantity <= 0
                                ? 0
                                : data?.reqQuantity - 1,
                            totalAvailableQty: data?.totalAvailableQty,
                          },
                        })
                      ) &&
                      checkBoxState &&
                      dispatch(
                        setCartTransferStockQuntData({
                          type: ApiConstant.SELECTED_STOCK_QUNT_UPDATE,
                          value: {
                            materialCode: data?.sAPMaterialCode,
                            qunt:
                              data?.reqQuantity <= 0
                                ? 0
                                : data?.reqQuantity - 1,
                            totalAvailableQty: data?.totalAvailableQty,
                          },
                        })
                      );
                  }}
                >
                  <SubIcon />
                </div>
                <input
                  className="card-item-qnt-input"
                  type="number"
                  value={data?.reqQuantity || 0}
                  onChange={(e) => {
                    data?.totalAvailableQty > 0 &&
                      dispatch(
                        setViewRequestStockQuntData({
                          materialCode: data?.sAPMaterialCode,
                          qunt: e.target.value ? parseInt(e.target.value) : 0,
                          totalAvailableQty: data?.totalAvailableQty,
                        })
                      ) &&
                      dispatch(
                        setCartTransferStockQuntData({
                          type: ApiConstant.ADD_STOCK_QUNT_UPDATE,
                          value: {
                            materialCode: data?.sAPMaterialCode,
                            qunt: e.target.value ? parseInt(e.target.value) : 0,
                            totalAvailableQty: data?.totalAvailableQty,
                          },
                        })
                      ) &&
                      checkBoxState &&
                      dispatch(
                        setCartTransferStockQuntData({
                          type: ApiConstant.SELECTED_STOCK_QUNT_UPDATE,
                          value: {
                            materialCode: data?.sAPMaterialCode,
                            qunt: e.target.value ? parseInt(e.target.value) : 0,
                            totalAvailableQty: data?.totalAvailableQty,
                          },
                        })
                      );
                  }}
                />
                <div
                  className="card-item-qnt-btn"
                  onClick={() => {
                    data?.totalAvailableQty > 0 &&
                      dispatch(
                        setViewRequestStockQuntData({
                          materialCode: data?.sAPMaterialCode,
                          qunt: data?.reqQuantity + 1,
                          totalAvailableQty: data?.totalAvailableQty,
                        })
                      ) &&
                      dispatch(
                        setCartTransferStockQuntData({
                          type: ApiConstant.ADD_STOCK_QUNT_UPDATE,
                          value: {
                            materialCode: data?.sAPMaterialCode,
                            qunt: data?.reqQuantity + 1,
                            totalAvailableQty: data?.totalAvailableQty,
                          },
                        })
                      ) &&
                      checkBoxState &&
                      dispatch(
                        setCartTransferStockQuntData({
                          type: ApiConstant.SELECTED_STOCK_QUNT_UPDATE,
                          value: {
                            materialCode: data?.sAPMaterialCode,
                            qunt: data?.reqQuantity + 1,
                            totalAvailableQty: data?.totalAvailableQty,
                          },
                        })
                      );
                  }}
                >
                  <AddIcon stroke={"#009bdf"} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="normal-card-item-section"
          onClick={() => {
            setCheckBoxState(false);
            dispatch(removeSelectedTransferStockData(data));
            dispatch(removeTransferStockData(data));
          }}
        >
          <DeleteIcon />
        </div>
      </div>
    </div>
  );
};

export default TransferCartCard;
