import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  SignatureDocument,
  ValidSerialNumberCheck,
  ValidateDeviceSwap,
  headers,
} from "../../../../../Api/ApiCalls";
import { ApiConstant } from "../../../../../Assets/Constants/ApiConstants";
import { Constent } from "../../../../../Assets/Constants/Constent";
import {
  FileUploadIcon,
  RightArrowIcon,
} from "../../../../../Assets/Images/CelcomSvgImages";
import { updateWorkOrderDetailsData } from "../../../../../ReduxFeature/StateFeatures/WorkOrderDataState";
import BTUAccordionBodyContent from "./BTUAccordionBodyContent";
import OSIAccordionBodyContent from "./OSIAccordionBodyContent";
import OSTAccordionBodyContent from "./OSTAccordionBodyContent";
import UpdateLiveLocationContent from "./UpdateLiveLocationContent";
import UpdateWorkOrderDropDownContent from "./UpdateWorkOrderDropDownContent";
import UpdateWorkOrderLiveLocationContent from "./UpdateWorkOrderLiveLocationContent";
import { toast } from "react-toastify";
import SignatureModal from "./SignatureModal";
import CustomerSignModal from "./CustomerSignModal";
import InstallerSignature from "./InstallerSignature";
import InstallerUpdateWorkOrderPage from "../../../../../Pages/InstallerViewPages/InstallerUpdateWorkOrderPage";

const InstallerUpdateWorkOrderBodyContentForm = ({
  pageState,
  setPageState,
  saveAndContinueBtnEnabledApiCall,
}) => {
  const userDataObj = useSelector((state) => state.userDataInfo);
  const workOrderDataObj = useSelector((state) => state.workOrderInfo);
  const {
    workOrderDetails,
    workOrderInfo,
    deviceSLValidation,
    devicesWithExistingSerialNo,
    ostResolutionValue
  } = workOrderDataObj;
  const [sign, setSign] = useState();
  const [installerUrl, setInstallerUrl] = useState();
  const [customerSign, setCustomerSign] = useState();
  const [customerUrl, setCustomertUrl] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [speedState, setSpeedState] = useState();
  const [wifiState, setWifiState] = useState();
  const [remark,setRemark] = useState();
  const [deviceBrand,setDeviceBrand] = useState();
  const [brandDetails, setBrandDetails] = useState();
  const [signDocument, setSignDocument] = useState();
  const [signData, setSignData] = useState({
    installerName: "",
    customerName: "",
    confirm: "",
    acknowledge: ""
  })
  const { ownOrderDetails } =
    workOrderDataObj?.workOrderDetails !== null &&
    workOrderDataObj?.workOrderDetails;
    const header = headers(
      userDataObj?.userData?.activeUserData?.authToken
);
  const dispatch = useDispatch();
  function enableSave() {
    let OSI = (workOrderDataObj?.workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OSI_TYPE)
    let OST = (workOrderDataObj?.workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OST_TYPE)
    if (OSI || OST) {
      if ((OSI ? (workOrderDataObj?.additionalCableLength && workOrderDataObj?.additionalCableLength != "" ) : true)
        && wifiState && wifiState != "" && speedState && speedState != ""
        && installerUrl && customerUrl && signData?.installerName && signData?.installerName != ""
        && signData?.customerName && signData?.customerName != "" &&
        (workOrderDataObj?.workOrderStatus ===
          ApiConstant.WOKR_ORDER_RETURNED ? true : (signData?.confirm && signData?.acknowledge))) {
        return true
      } else {
        return false
      }
    }
    else {
      return true
    }
  }
  useEffect(() => {
    enableSave()
  },[installerUrl,customerUrl,signData,wifiState,speedState,workOrderInfo])

  useEffect(() =>{
    dispatch(
      updateWorkOrderDetailsData({
        type: "isUpload",
        value:
        false,
      })
    );
  },[])
  const SerialNumberValidation = ({ event, object, header }) => {
    const { value } = event.target;
    const request = {
      serialNumber: value,
      outletId: object?.outletId,
      userId: object?.userId,
    };
    let deblicateSerialNumber = false;

    const deviceSLValidation = workOrderDataObj?.deviceSLValidation;

    Object.keys(deviceSLValidation).map((productId) => {
      if (
        object?.productId !== productId &&
        deviceSLValidation[productId]?.serialnumber === value
      ) {
        deblicateSerialNumber = true;
      }
    });
    if (!deblicateSerialNumber) {
      ValidSerialNumberCheck("/fibre/inventory/validSerialUin", request, header)
        .then((response) => {
          if (
            response?.status === "Success" &&
            response?.serialNumber === value &&
            response?.stockAvaialble > 0
          ) {
            //  && response?.materialCode === object?.materialCode
            // if (workOrderDataObj?.workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OSI_TYPE) {
            dispatch(
              updateWorkOrderDetailsData({
                type: ApiConstant.INSTALLER_SL_VALIDATION_VALID,
                value: {
                  productId: object?.productId,
                  itemValue: ApiConstant.VALID_INP,
                },
              })
            );
            if (object?.isValid == "OST") {
              ValidateDeviceSwap(
                "/fibre/inventory/validateDeviceSwap",
                object?.validateDeviceSwapRequest,
                header
              )
                .then((innerresponse) => {
                  dispatch(
                    updateWorkOrderDetailsData({
                      type: ApiConstant.INSTALLER_SL_VALIDATION_DEVICE_SWAP_MODEL_DETAILS,
                      value: {
                        productId: object?.deviceData?.productID,
                        isSwapDeviceWithModelChange:
                          innerresponse?.isSwapDeviceWithModelChange,
                        newDeviceBrand: innerresponse?.newDeviceBrand,
                        newDeviceModel: innerresponse?.newDeviceModel,
                        newMaterialCode: innerresponse?.newMaterialCode,
                      },
                    })
                  );
                  if (innerresponse?.isSwapDeviceWithModelChange === true) {
                    toast.warning(
                      ApiConstant.INSTALLER_INST_SWAP_DEVICE_MODEL_CHANGE_WARNING_MESSAGE,
                      {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 10000,
                        theme: "colored",
                      }
                    );
                  }
                })
                .catch((err) => {
                  // TODO document why this arrow function is empty
                });
            }
          } else {
            // if (workOrderDataObj?.workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OSI_TYPE) {
            dispatch(
              updateWorkOrderDetailsData({
                type: ApiConstant.INSTALLER_SL_VALIDATION_VALID,
                value: {
                  productId: object?.productId,
                  itemValue: ApiConstant.INVALID_INP,
                },
              })
            );
            dispatch(
              updateWorkOrderDetailsData({
                type: ApiConstant.INSTALLER_SL_VALIDATION_DEVICE_SWAP_MODEL_DETAILS,
                value: {
                  productId: object?.deviceData?.productID,
                  isSwapDeviceWithModelChange: false,
                  newDeviceBrand: "",
                  newDeviceModel: "",
                  newMaterialCode: "",
                },
              })
            );
            // }
          }
        })
        .catch((error) => {
          dispatch(
            updateWorkOrderDetailsData({
              type: ApiConstant.INSTALLER_SL_VALIDATION_VALID,
              value: {
                productId: object?.productId,
                itemValue: ApiConstant.INVALID_INP,
              },
            })
          );
          dispatch(
            updateWorkOrderDetailsData({
              type: ApiConstant.INSTALLER_SL_VALIDATION_DEVICE_SWAP_MODEL_DETAILS,
              value: {
                productId: object?.deviceData?.productID,
                isSwapDeviceWithModelChange: false,
                newDeviceBrand: "",
                newDeviceModel: "",
                newMaterialCode: "",
              },
            })
          );
        });
    } else {
      dispatch(
        updateWorkOrderDetailsData({
          type: ApiConstant.INSTALLER_SL_VALIDATION_VALID,
          value: {
            productId: object?.productId,
            itemValue: ApiConstant.INVALID_INP,
          },
        })
      );
      dispatch(
        updateWorkOrderDetailsData({
          type: ApiConstant.INSTALLER_SL_VALIDATION_DEVICE_SWAP_MODEL_DETAILS,
          value: {
            productId: object?.deviceData?.productID,
            isSwapDeviceWithModelChange: false,
            newDeviceBrand: "",
            newDeviceModel: "",
            newMaterialCode: "",
          },
        })
      );
    }
  };
  useEffect(() =>{
    if(workOrderDataObj?.isUpload){
      OSTSignature()
    }
  },[workOrderDataObj?.isUpload])
  
    function OSTSignature() {
      // setPageState({ ...pageState, apiCallAction: true });
      const { rgwDeviceList, btuDeviceList, meshDeviceList } =
        ownOrderDetails?.deviceDetails;
      const comboList = rgwDeviceList?.concat(btuDeviceList, meshDeviceList);
      const deviceProductItem = [];
      Object.keys(deviceSLValidation)?.map((productId) => {
        const deviceIndex = comboList?.findIndex(
          (obj) => obj?.productID === productId
        );
        const deviceInfo = comboList[deviceIndex];
        if (workOrderDataObj?.workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OSI_TYPE) {
        if (deviceSLValidation[productId]?.valid === ApiConstant.VALID_INP) {
          // if (!devicesWithExistingSerialNo.includes(productId)) {
            deviceProductItem.push({ 
              deviceSerialNo: deviceSLValidation[productId]?.serialnumber,
              deviceType: deviceInfo?.deviceType,
              devicePreviousSerialNo: null,
            });
          // }
        }}
        if(workOrderDataObj?.workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OST_TYPE) {
          if (deviceSLValidation[productId]?.valid === ApiConstant.VALID_INP) {
            // if (!devicesWithExistingSerialNo.includes(productId)) {
              deviceProductItem.push({
                deviceSerialNo: deviceSLValidation[productId]?.serialnumber,
                deviceType: deviceInfo?.deviceType,
                devicePreviousSerialNo: deviceInfo?.deviceSerialNo,
                deviceProblemCause: deviceSLValidation[productId]?.problemCauseValue,
                deviceResolution: deviceSLValidation[productId]?.resolutionValue,
              });
            // }
          }}
      });
      //  console.log("enter into function",workOrderDataObj?.workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OSI_TYPE)
    if(workOrderDataObj?.isUpload){
      if (workOrderDataObj?.workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OSI_TYPE ||
        workOrderDataObj?.workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OST_TYPE) {
          // console.log("if block")
          dispatch(
            updateWorkOrderDetailsData({
              type: "isUpload",
              value:
              false,
            })
          );
        let signaturePayload = {
          activityId: workOrderDetails?.itemID,
          userId: userDataObj?.userData?.activeUserData?.userData?.userId,
          outletId: userDataObj?.userData?.activeUserData?.userData?.outletId,
          installationBTUBrand: brandDetails ? brandDetails : null,
          troubleShootingBTUBrand: deviceBrand ? deviceBrand : null,
          remarks: remark ? remark : null,
          cableLength: workOrderDataObj?.additionalCableLength ? workOrderDataObj?.additionalCableLength : null,
          LANspeed: speedState ? speedState : null,
         
          ostResolution:
          Object?.keys(deviceSLValidation)?.length > 0
            ? deviceProductItem[0].deviceResolution
            : ostResolutionValue,
          WIFIspeed: wifiState ? wifiState : null,
          customerName: signData?.customerName ?  signData?.customerName : null,
          installerName: signData?.installerName ? signData?.installerName : null,
          customerSignature: customerUrl ? customerUrl : null,
          InstallerSignature: installerUrl ? installerUrl : null,
          returned: workOrderDataObj?.workOrderStatus == "RETURNED" ? true : false,
          deviceProductItem: deviceProductItem
        }
        let url = "/fibre/eSAF/Upload";
        SignatureDocument(url, signaturePayload, header)
        .then((res) => {
          setSignDocument(res);
          if (res?.statusCode === "1111") {
              toast.success(res?.description, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 5000,
                  theme: "colored",
              })
          } else {
              toast.error(res?.description, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 5000,
                  theme: "colored",
              })
          }

      })
      .catch((err) => {
        setSignDocument(err);
          let errormsg = err.response?.data?.description
          if (errormsg) {
              toast.error(errormsg, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 5000,
                  theme: "colored",
              });
          } else {
              toast.error("request failed", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 5000,
                  theme: "colored",
              });
          }

      })
      }
    }
    }
 
  return (
    <div className="update-work-order-content-item-group-section">
      {/* Full Content Section */}
      <div className="wrapper-update-work-order-content-item-group-section">
        {/* Title Part */}
        <div className="update-work-order-content-item-group">
          {workOrderDataObj?.activeTab === 1
            ? Constent.UPDATE_WORK_ORDER_CONTENT_TITLE1
            : Constent.UPDATE_WORK_ORDER_CONTENT_TITLE2}
        </div>
        {/* Location Btn Or Message */}
        <UpdateWorkOrderLiveLocationContent
          pageState={pageState}
          setPageState={setPageState}
        />
        {/* Display Location and time */}
        <UpdateLiveLocationContent />
        {/* Drop Down Section */}
        <UpdateWorkOrderDropDownContent
          pageState={pageState}
          setPageState={setPageState}
          workOrderDataObj={workOrderDataObj}
          remark={remark}
          setRemark={setRemark}
        />
        {/* --- Accordions on step -2 and 3 diffrent types--- */}
        {workOrderDataObj?.activeTab === 2 && (
          <div
            className="accordion accordion-borderless mb-1 custom-accordion-section"
            id="updateWorkOrder"
          >
            {workOrderDataObj?.workOrderDetails?.itemTypeID ===
              ApiConstant.WOKR_ORDER_OSI_TYPE ? (
              <OSIAccordionBodyContent
                ownOrderDetails={ownOrderDetails}
                SerialNumberValidation={SerialNumberValidation}
                workOrderDataObj={workOrderDataObj}
                userDataObj={userDataObj}
                speedState={speedState}
                setSpeedState={setSpeedState}
                wifiState={wifiState}
                setWifiState={setWifiState}
                brandDetails={brandDetails}
                setBrandDetails={setBrandDetails}
                remark={remark}
                setRemark={setRemark}
              />
            ) : workOrderDataObj?.workOrderDetails?.itemTypeID ===
              ApiConstant.WOKR_ORDER_OST_TYPE ? (
              <OSTAccordionBodyContent
                ownOrderDetails={ownOrderDetails}
                SerialNumberValidation={SerialNumberValidation}
                userDataObj={userDataObj}
                workOrderDataObj={workOrderDataObj}
                deviceBrand={deviceBrand}
                setDeviceBrand={setDeviceBrand}
                speedState={speedState}
                setSpeedState={setSpeedState}
                wifiState={wifiState}
                setWifiState={setWifiState}
                remark={remark}
                setRemark={setRemark}
              />
            ) : workOrderDataObj?.workOrderDetails?.itemTypeID ===
              ApiConstant.WOKR_ORDER_BTU_TYPE ? (
              <BTUAccordionBodyContent
                deviceDetails={ownOrderDetails?.deviceDetails || []}
                workOrderDataObj={workOrderDataObj}
              />
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
      {/****installer signature ***/}
      <InstallerSignature
        sign={sign}
        setSign={setSign}
        installerUrl={installerUrl}
        setInstallerUrl={setInstallerUrl}
        customerSign={customerSign}
        setCustomerSign={setCustomerSign}
        setCustomertUrl={setCustomertUrl}
        customerUrl={customerUrl}
        setSignData={setSignData}
        signData={signData}
      />

      {/* Btn Section */}
      <div className="wrapper-update-work-order-content-item-group-section">
        <div className="update-work-order-content-btn-group-section">
          <div className="update-work-order-content-btn-group"></div>
          <div className="update-work-order-content-btn-group">
            <div
              className="file-upload-btn-section"
              data-toggle="modal"
              data-target="#uploadDocumentModal"
            >
              <span>{Constent.UPLOAD_BTN}</span>
              <FileUploadIcon />
            </div>
            {workOrderDataObj?.activeTab === 1 ? (
              // Save & Continue Btn
              <div
                className={`submit-btn-section 
                  ${(workOrderDataObj?.saveAndContinueBtnEnabled )  // && enableSave() use when signature required
                    ? "active-submit-btn-section"
                    : ""
                  }`}
                onClick={() => {saveAndContinueBtnEnabledApiCall(
                  installerUrl,
                  customerUrl,
                  speedState,
                  wifiState,
                  remark,
                  signData
                )}}
              >
                <span>
                  {workOrderDataObj?.workOrderStatus ===
                    ApiConstant.WOKR_ORDER_RETURNED
                    ? Constent.SUBMIT_BTN
                    : Constent.SAVE_AND_CONTINUE_BTN}
                </span>
                <RightArrowIcon />
              </div>
            ) : (
              // Submit Btn
              <div  onClick={() => {OSTSignature(
                installerUrl,
                customerUrl,
                speedState,
                wifiState,
                deviceBrand,
                brandDetails,
                remark,
                signData
              )}}>
              <div
                className={`submit-btn-section 
                  ${(workOrderDataObj?.submitBtnEnabled)   // && enableSave() use when signature required
                    ? "active-submit-btn-section"
                    : ""
                  }`
                }
                
                data-toggle={workOrderDataObj?.submitBtnEnabled && "modal"}
                data-target={
                  workOrderDataObj?.submitBtnEnabled &&
                  "#requestStockPlaceOrderModal"
                }
              >
                <span>{Constent.SUBMIT_BTN}</span>
                <RightArrowIcon />
              </div>
                </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallerUpdateWorkOrderBodyContentForm;
