import moment from "moment";
import React from "react";
import DatePicker from "react-date-picker";
import { useDispatch } from "react-redux";
import { ApiConstant } from "../../../../Assets/Constants/ApiConstants";
import { Constent } from "../../../../Assets/Constants/Constent";
import {
  CalendarIcon,
  SearchIcon,
} from "../../../../Assets/Images/CelcomSvgImages";
import { setTrackOrderFieldValues } from "../../../../ReduxFeature/StateFeatures/TrackOrderDataState";
import { BodyBreadcrumbs } from "../../../Common/BodyBreadcrumbs";

const TrackOrderBodyHeader = ({
  userDataObj,
  globalState,
  pageState,
  setPageState,
  trackOrderApiCalls,
  lovDataObj,
  trackOrderDataObj,
  inputBtnDisabled,
}) => {
  const dispatch = useDispatch();
  const { ordercontext, orderstatus, ordersearchtype } = lovDataObj;
  const {
    searchType,
    fromDateValue,
    toDateValue,
    orderStatusValue,
    orderContextValue,
    orderNumberValue,
    customerIdValue,
    srvcAttrValValue,
  } = trackOrderDataObj;
  const searchTypeIndex =
    ordersearchtype?.findIndex((obj) => obj?.value === searchType) || 0;

  const textFieldValue =
    ordersearchtype[searchTypeIndex]?.value ===
    ApiConstant.TRACK_ORDER_ORDER_ID_VALUE
      ? orderNumberValue
      : ordersearchtype[searchTypeIndex]?.value ===
        ApiConstant.TRACK_ORDER_CUSTOMER_ID_VALUE
      ? customerIdValue
      : ordersearchtype[searchTypeIndex]?.value ===
          ApiConstant.TRACK_ORDER_SERVICE_VAL_VALUE && srvcAttrValValue;

  return (
    <div className="trackorder-header-start-section">
      <div className="wrapper-trackorder-header-section">
        <BodyBreadcrumbs activeItem={Constent.HEADER_MENU_ITEM2} />
        <div className="trackorder-header-search-group-section">
          <div
            className={`${
              globalState?.sidebarState ? "col-xxl-12" : "col-xxl-10"
            } col-xl-12 wrapper-trackorder-header-search-group`}
          >
            <div className="trackorder-header-search-group-row-section">
              {Constent.HEADER_MENU_ITEM2}
              <SearchIcon stroke={"#CCCCCC"} />
            </div>
            <div className="trackorder-header-search-group-row-section">
              <div className="trackorder-search-group-section">
                <div className="form-group m-0 custom-drop-down-form-group">
                  <select
                    className="custom-select"
                    value={orderContextValue}
                    onChange={(e) => {
                      dispatch(
                        setTrackOrderFieldValues({
                          type: ApiConstant.TRACK_ORDER_CONTEXT_VALUE,
                          value: e.target.value,
                        })
                      );
                    }}
                    id="orderContext"
                  >
                    <option defaultValue="">-- Select --</option>
                    {ordercontext?.map((data, index) => {
                      return (
                        <option
                          key={`orderContext${index}`}
                          value={`${data?.value}`}
                        >
                          {data?.display}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="orderContext">
                    {Constent.TRACK_ORDER_CARD_ITEM_TITLE2}
                    <span className="text-danger ml-1">*</span>
                  </label>
                </div>
              </div>
              {searchType === "ORDERSTATUS" ? (
                <div className="trackorder-search-group-section">
                  <div className="form-group m-0 custom-drop-down-form-group">
                    <select
                      className="custom-select"
                      id="orderStatus"
                      value={orderStatusValue}
                      disabled={inputBtnDisabled}
                      onChange={(e) => {
                        dispatch(
                          setTrackOrderFieldValues({
                            type: ApiConstant.TRACK_ORDER_STATUS_VALUE,
                            value: e.target.value,
                          })
                        );
                      }}
                    >
                      <option defaultValue="" value="">
                        -- Select --
                      </option>
                      {orderstatus?.map((data, index) => {
                        return (
                          <option
                            key={`advanceSearchLovs${index}`}
                            value={`${data?.value}`}
                          >
                            {data?.display}
                          </option>
                        );
                      })}
                    </select>
                    <label htmlFor="orderStatus">
                      {Constent.TRACK_ORDER_CARD_ITEM_TITLE7}
                    </label>
                  </div>
                </div>
              ) : (
                <div className="trackorder-search-group-section">
                  <div className="form-group mb-0 custom-input-form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="trackOrderInput"
                      value={textFieldValue}
                      placeholder=" "
                      disabled={inputBtnDisabled}
                      onChange={(e) => {
                        dispatch(
                          setTrackOrderFieldValues({
                            type: ordersearchtype[searchTypeIndex]?.value,
                            value: e.target.value,
                          })
                        );
                      }}
                    />
                    <label htmlFor="trackOrderInput">
                      {ordersearchtype[searchTypeIndex]?.display}
                    </label>
                    <span className="invalid-msg-text">
                      {Constent.INVALID_INPUT}
                    </span>
                  </div>
                </div>
              )}
              <div className="trackorder-search-group-section">
                {Constent.FREE_CONTENT_TEXT2}
              </div>
              <div className="trackorder-search-group-section">
                <div className="trackorder-search-inner-group-section">
                  <div className="form-group mb-0 custom-input-form-group">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        value={fromDateValue}
                        disabled={inputBtnDisabled}
                        onChange={(e) => {
                          dispatch(
                            setTrackOrderFieldValues({
                              type: ApiConstant.TRACK_ORDER_FORM_DATE_VALUE,
                              value: e.target.value,
                            })
                          );
                        }}
                        id="formDate"
                        placeholder=" "
                      />
                      <label htmlFor="formDate">
                        {Constent.DATE_RANGE_INPUT_ITEM_LABEL1}
                      </label>
                      <div className="input-group-append">
                        <span
                          className={`input-group-text ${
                            inputBtnDisabled
                              ? "custom-input-group-disabled"
                              : ""
                          }`}
                        >
                          <CalendarIcon fill={"#333333"} />
                        </span>
                        <div className="input-group-append-date-picker-section">
                          <DatePicker
                            selected={moment()}
                            disableCalendar={inputBtnDisabled}
                            onChange={(date) => {
                              dispatch(
                                setTrackOrderFieldValues({
                                  type: ApiConstant.TRACK_ORDER_FORM_DATE_VALUE,
                                  value: moment(date)?.format("YYYY-MM-DD"),
                                })
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <span className="invalid-msg-text">
                      {Constent.INVALID_INPUT}
                    </span>
                  </div>
                </div>
                <div className="trackorder-search-inner-group-section">
                  <div className="form-group mb-0 custom-input-form-group">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="toDate"
                        value={toDateValue}
                        disabled={inputBtnDisabled}
                        onChange={(e) => {
                          dispatch(
                            setTrackOrderFieldValues({
                              type: ApiConstant.TRACK_ORDER_TO_DATE_VALUE,
                              value: e.target.value,
                            })
                          );
                        }}
                        placeholder=" "
                      />
                      <label htmlFor="toDate">
                        {Constent.DATE_RANGE_INPUT_ITEM_LABEL2}
                      </label>
                      <div className="input-group-append">
                        <span
                          className={`input-group-text ${
                            inputBtnDisabled
                              ? "custom-input-group-disabled"
                              : ""
                          }`}
                        >
                          <CalendarIcon fill={"#333333"} />
                        </span>
                        <div className="input-group-append-date-picker-section">
                          <DatePicker
                            selected={moment()}
                            disableCalendar={inputBtnDisabled}
                            onChange={(date) => {
                              dispatch(
                                setTrackOrderFieldValues({
                                  type: ApiConstant.TRACK_ORDER_TO_DATE_VALUE,
                                  value: moment(date)?.format("YYYY-MM-DD"),
                                })
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <span className="invalid-msg-text">
                      {Constent.INVALID_INPUT}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`trackorder-search-group-section ${
                  inputBtnDisabled ? "custom-disable-btn" : ""
                }`}
                onClick={() => (inputBtnDisabled ? null : trackOrderApiCalls())}
              >
                <SearchIcon />
                {Constent.SEARCH_BTN}
              </div>
            </div>
            <div className="trackorder-header-search-group-row-section">
              <div className="trackorder-header-advance-search-section">
                <span
                  data-toggle="modal"
                  data-target="#trackOrderAdvanceSearch"
                >
                  {Constent.WORK_ORDER_ADVANCED_SEARCH_MODAL_TITLE}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackOrderBodyHeader;
