import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Constent } from "../../../../Assets/Constants/Constent";
import { OtherImages } from "../../../../Assets/Images/CelcomOtherImages";
import { DownloadIcon } from "../../../../Assets/Images/CelcomSvgImages";
import { BackBtn, DownloadBtn } from "../../../Common/Button";
import { NoDataFoundItemGroup } from "../../../Common/CardContent";
import { CardDetailsLoader } from "../../../Loaders/Loaders";
import TrackOrderDetailsAccordion from "./TrackOrderDetailsHelper/TrackOrderDetailsAccordion";

const TrackOrderDetailsBodyContent = ({
  userDataObj,
  globalState,
  pageState,
  setPageState,
  selectedTrackOrderData,
}) => {
  const [downloadBtnState, setDownloadBtnState] = useState(false);

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setDownloadBtnState(false);
    },
  });

  const downloadPdfFun = () => {
    setDownloadBtnState(true);
  };
  useEffect(() => {
    if (downloadBtnState) {
      handlePrint();
    }
  }, [downloadBtnState]);

  return (
    <div className="body-content-section pt-0" ref={componentRef}>
      {downloadBtnState && (
        <div className={`d-flex justify-content-end pt-5`}>
          {downloadBtnState && (
            <img
              src={OtherImages.CelcomLogo}
              style={{ right: 24 }}
              className=""
            />
          )}
        </div>
      )}
      <div
        className={`track-order-details-content-section ${
          globalState?.sidebarState ? "active-sidebar" : ""
        }`}
      >
        <div className="track-order-details-content-item-section"></div>
        {pageState?.dataLoader ? (
          <CardDetailsLoader />
        ) : pageState?.getTrackOrderDetailsDataError ? (
          <NoDataFoundItemGroup
            message={pageState?.getTrackOrderDetailsDataErrorMsg}
          />
        ) : (
          <div className="track-order-details-content-item-section">
            {/* Title & Btn */}
            <div className="track-order-details-inner-item-section">
              <div className="track-order-details-item-section">
                {!downloadBtnState && <BackBtn navLink={"/trackorder"} />}
                <div className="work-order-details-item mt-2">
                  {`${Constent.TRACK_ORDER_DETAILS_CONTENT_TITLE1}  `}
                  <span>{selectedTrackOrderData?.orderId || "-"}</span>
                </div>
              </div>
              <div className="track-order-details-item-section"></div>
              <div className="track-order-details-item-section">
                <div className="track-order-details-item-btn"></div>
                {!downloadBtnState && (
                  <div
                    className="download-btn-section"
                    onClick={downloadPdfFun}
                  >
                    <span>{Constent.DOWNLOAD_BTN}</span>
                    <DownloadIcon />
                  </div>
                )}
              </div>
            </div>
            {/* Accordion */}
            <div className="track-order-details-inner-item-section">
              <TrackOrderDetailsAccordion
                userDataObj={userDataObj}
                trackOrderDetailsData={pageState?.trackOrderDetailsData}
                selectedTrackOrderData={selectedTrackOrderData}
                downloadBtnState={downloadBtnState}
              />
            </div>
          </div>
        )}
        <div className="track-order-details-content-item-section"></div>
      </div>
    </div>
  );
};

export default TrackOrderDetailsBodyContent;
