import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Footer from '../../Components/Footer/Footer';
import { Header } from '../../Components/Header/Header';
import WorkOrderSummaryBodyHeader from '../../Components/MainBody/BodyHeader/Supervisor/WorkOrderSummaryBodyHeader';
import "../../Assets/CSS/WorkOrderSummaryDetailsPage.css";
import WorkOrderSummaryDetailsBodyContent from '../../Components/MainBody/BodyContent/Supervisor/WorkOrderSummaryDetailsBodyContent';
import { Constent } from '../../Assets/Constants/Constent';

const WorkOrderSummaryDetailsPage = ({ globalState, setGlobalState }) => {
    const userDataObj = useSelector((state) => state.userDataInfo);
    const workOrderObj = useSelector((state) => state.workOrderInfo?.summaryData);

    const [pageState, setPageState] = useState({
        dataLoader: false,
        activePill: Constent.SUPERVISOR_SIDEBAR_OSI_VALUE,
        activePillStatus: Constent.SUPERVISOR_SIDEBAR_SUBTYPE1
    });

    return (
        <div className="page-start-section">
            <Header userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState}  />
            <div className="body-start-section">
                <div className="body-full-content-section">
                    <WorkOrderSummaryBodyHeader pageState={pageState}
                        setPageState={setPageState} workOrderObj={workOrderObj} />
                    <WorkOrderSummaryDetailsBodyContent pageState={pageState}
                        setPageState={setPageState} workOrderObj={workOrderObj} />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default WorkOrderSummaryDetailsPage;