import React from 'react';
import { Constent } from '../../Assets/Constants/Constent';
import { CloseModalIcon } from '../../Assets/Images/CelcomSvgImages';
import { EmptyValueHandler } from '../Common/GenericFunctions';
import { ReturnWorkOrderModalBodyContent } from '../Common/ModalHelper';

const WorkOrderReturnedModal = ({ returnWorkOrderList }) => {
    return (
        <div className="modal fade returnd-work-order-modal" id="workOrderReturnModal" data-backdrop="static"
            data-keyboard="false" tabIndex="-1" aria-labelledby="workOrderReturnModalLabel"
            aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header pb-0">
                        <div className="wrapper-modal-header pt-5">
                            <div className="modal-header-title-section">
                                <h5 className="modal-title" id="workOrderReturnModalLabel">
                                    {Constent.RETURNED_WORK_ORDER_MODAL_TITLE}
                                </h5>
                                <span>{returnWorkOrderList?.length + ` - Times`}</span>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <CloseModalIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="wrapper-modal-body">
                            {
                                returnWorkOrderList?.map((returnOrderData, index) => {
                                    
                                    return (
                                        <ReturnWorkOrderModalBodyContent key={`ReturnIndex ${index + returnOrderData?.activityId}`}
                                            value1={EmptyValueHandler({ item: returnOrderData?.activityId?.replace("FieldActivity-", "") })}
                                            value2={EmptyValueHandler({ item: returnOrderData?.activityType?.replaceAll("_", " ") })} 
                                            value3={EmptyValueHandler({item: returnOrderData?.status?.replace(returnOrderData?.activityType, "")?.replaceAll("_", " ") })} 
                                            value4={"Returned"}
                                            value5={EmptyValueHandler({item: returnOrderData?.returnRemark })} 
                                            value6={""}
                                            value7={""} value8={""} />
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="okay-btn-section" data-dismiss="modal">
                            <span>{Constent.OKAY_BTN}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkOrderReturnedModal;