import React from 'react';
import { NoDataFoundItemGroup } from '../../../Common/CardContent';
import { CardLoader } from '../../../Loaders/Loaders';
import ManageInstallerCard from '../../Cards/Supervisor/ManageInstallerCard';

const ManageInstallerBodyContent = ({ userDataObj, globalState, pageState, setPageState, filterList }) => {

    return (
        <div className='body-content-section custom-scroll-bar-section'>
            <div className={pageState?.cardView === "List" ?
                "wrapper-manage-installer-body-content-section"
                :

                "wrapper-manage-installer-body-content-section custom-card"} >
                {
                    pageState?.getDataLoader ?
                        [0, 1].map(val => {
                            return (
                                <CardLoader key={`getInstallerCard${val}`} />
                            )
                        })
                        :
                        pageState?.getDataError ?
                            <NoDataFoundItemGroup message={pageState?.getDataErrorMsg} />
                            :
                            filterList?.length > 0 ?
                                filterList?.map((data, installerIndex) => {
                                    return (
                                        <ManageInstallerCard key={`manageInstallerCard${data?.userId + installerIndex}`}
                                            data={data} setPageState={setPageState} globalState={globalState} pageState={pageState} />
                                    )
                                })
                                :
                                <NoDataFoundItemGroup message={"No Installer Available"} />
                }
            </div>
        </div>
    )
}

export default ManageInstallerBodyContent;