import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../../Components/Footer/Footer';
import { Header } from '../../Components/Header/Header';
import SupervisorSidebar from '../../Components/MainBody/Sidebar/SupervisorSidebar';
import '../../Assets/CSS/InventoryViewStockPage.css';
import InventoryStockDetailsBodyHeader from '../../Components/MainBody/BodyHeader/Supervisor/InventoryStockDetailsBodyHeader';
import InventoryViewStockBodyContent from '../../Components/MainBody/BodyContent/Supervisor/InventoryViewStockBodyContent';
import InventoryViewStockFilterModal from '../../Components/Modals/InventoryViewStockFilterModal';
import { headers, ViewStockDetails } from '../../Api/ApiCalls';
import { setSelectedViewStockItemData } from '../../ReduxFeature/StateFeatures/InventoryDataState';
import { ToastContainer, toast } from 'react-toastify';
import { ApiConstant } from '../../Assets/Constants/ApiConstants';
import { extractNumberFromString } from '../../Components/Common/GenericFunctions';
import { Constent } from '../../Assets/Constants/Constent';


const InventoryViewStockPage = ({ globalState, setGlobalState }) => {
    const userDataObj = useSelector((state) => state.userDataInfo);
    const invDataObj = useSelector((state) => state.inventoryDataInfo);

    const dispatch = useDispatch();

    const [pageState, setPageState] = useState({
        dataLoader: false,

        filterSerialNumber: "",
        expiryDate: "",

        selectedCardItemDetails: null,
        selectedCardItemDetailsList: null,
        // Error Part
        getViewStockDataError: false,
        getViewStockDataErrorMsg: "",
    });

    function retrieveItemDetailApiCall() {
        setPageState({ ...pageState, dataLoader: true });
        //  Header
        const header = headers(
            userDataObj?.userData?.activeUserData?.authToken
        );
        const retrieveItemDetailsRequest = {
            recordRowStart: 0, recordRowEnd: Constent.ROW_END_RETRIEVE_DETAILS,
            outletId: extractNumberFromString(userDataObj?.userData?.activeUserData?.userData?.outletId),
            materialCode: invDataObj?.selectedViewStockItem?.sAPMaterialCode
        };

        ViewStockDetails("/fibre/inventory/retrieveItemDetails", retrieveItemDetailsRequest, header)
            .then(response => {
                if (response?.materialCode === invDataObj?.selectedViewStockItem?.sAPMaterialCode) {
                    // dispatch(setSelectedViewStockItemData(response));
                    let list = [].concat(response?.inStock?.inventoryItems, response?.unAvaliable?.inventoryItems,
                        response?.sold?.inventoryItems, response?.faulty?.inventoryItems, response?.others?.inventoryItems)
                    setPageState({
                        ...pageState, selectedCardItemDetailsList: list, selectedCardItemDetails: response,
                        dataLoader: false, getViewStockDataError: false,
                        getViewStockDataErrorMsg: "",
                    });
                }
                else {
                    toast.warning("Invalid Respnse",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 10000,
                            theme: 'colored'
                        }
                    );
                    setPageState({
                        ...pageState, dataLoader: false,
                        getViewStockDataError: true, getViewStockDataErrorMsg: "Invalid Respnse"
                    });
                }
            }).catch(error => {
                let errorMessage;
                if (error.response.status === 500 || error.response.status === 503) {
                    errorMessage = ApiConstant.VIEW_STOCK_LIST_ERROR_MESSAGE;
                    toast.error(errorMessage,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 10000,
                            theme: 'colored'
                        }
                    );
                }
                else if (error.response.status === 404) {
                    errorMessage = ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE;
                    toast.warning(errorMessage,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 10000,
                            theme: 'colored'
                        }
                    );
                }
                else {
                    errorMessage = error.response.statusText;
                    toast.error(errorMessage,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 10000,
                            theme: 'colored'
                        }
                    );
                }
                setPageState({
                    ...pageState, dataLoader: false,
                    getViewStockDataError: true, getViewStockDataErrorMsg: errorMessage
                });
            });
    }
    useEffect(() => {
        retrieveItemDetailApiCall();
    }, []);

    return (
        <div className='page-start-section'>
            <Header userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} tab4={true} />
            <div className={globalState?.sidebarState ? "body-start-section sidebar-active-section" : "body-start-section"}>
                {/* Sidebar */}
                <SupervisorSidebar userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} />
                <div className="body-full-content-section">
                    <InventoryStockDetailsBodyHeader bannarTitle={Constent.BODY_HEADER_INVENTORY_VIEW_STOCK_BANNAR_TEXT}
                        activeItem={Constent.BODY_HEADER_INVENTORY_SUB_PATH1} />
                    <InventoryViewStockBodyContent pageState={pageState}
                        setPageState={setPageState} selectedCardItemDetails={pageState?.selectedCardItemDetails}
                        selectedCardItem={invDataObj?.selectedViewStockItem}
                        selectedCardItemDetailsList={pageState?.selectedCardItemDetailsList} />
                </div>
                <Footer />
            </div>
            <InventoryViewStockFilterModal  pageState={pageState} setPageState={setPageState}/>
            <ToastContainer />
        </div>
    )
}

export default InventoryViewStockPage;