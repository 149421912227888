import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import DatePicker from 'react-date-picker';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ApiConstant } from '../../../Assets/Constants/ApiConstants';
import { Constent } from '../../../Assets/Constants/Constent'
import '../../../Assets/CSS/SupervisorSummary.css'
import { DropDownIcon, LeftArrowIcon, RightArrowIcon } from '../../../Assets/Images/CelcomSvgImages'
import { saveSummaryData } from '../../../ReduxFeature/StateFeatures/WorkOrderDataState';

export default function SupervisorSidebar({ globalState, setGlobalState }) {
  const workOrderObj = useSelector((state) => state.workOrderInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [monthPicker, setMonthPicker] = useState(false);

  const { summaryData, summaryDate } = workOrderObj;
  

  return (
    <div className={globalState?.sidebarState ? "sidebar-start-section" : "sidebar-start-section sidebar-inactive-section"}>
      <div className="sidebar-toggle-btn-section" onClick={() => setGlobalState({ ...globalState, sidebarState: !globalState?.sidebarState })}>
        {
          globalState?.sidebarState ? <RightArrowIcon /> : <LeftArrowIcon />
        }

      </div>
      <div className="sidebar-content-section">
        <div className="sidebar-content-group-section">
          <span>{`${Constent.SUPERVISOR_SIDEBAR_TITLE1} `}</span>
          <br />
          <span>{Constent.SUPERVISOR_SIDEBAR_TITLE2}</span>
        </div>
        <div className="sidebar-content-group-section">
          <div className="custom-month-picker-section">
            <div className="wrapper-custom-month-picker-section custom-sidebar-month-picker-section" onClick={() => {
              setMonthPicker(!monthPicker);
            }}>
              <div className="custom-month-picker-item-section">
                <div className="custom-month-picker-item">
                  {Constent.CUSTOM_MONTH_PICKER_LABLE}
                </div>
                <div className="custom-month-picker-item">
                  {`${moment(summaryDate).format("MMMM")}, ${moment(summaryDate).format("YYYY")}`}
                </div>
              </div>
              <div className="custom-month-picker-item-section">
                <div className={`custom-month-picker-item ${monthPicker ? "active" : ""}`}>
                  <DropDownIcon stroke={"#333333"} />
                </div>
              </div>
              <div className="month-picker-item-section">
                <DatePicker selected={moment()} maxDetail="year" 
                onCalendarClose={()=>{
                  setMonthPicker(false)
                }}
                onChange={date => {
                  dispatch(saveSummaryData({
                    type: ApiConstant.SAVE_SUMMARY_DATE,
                    value: date
                  }))
                }}
                  monthAriaLabel="Month" minDetail="decade" />
              </div>
            </div>
          </div>
        </div>
        <div className="sidebar-content-group-section">
          <div className="sidebar-content-group-item-section">
            <div className="sidebar-content-group-item">{Constent.SUPERVISOR_SIDEBAR_OSI_TITLE}</div>
            <div className="sidebar-content-group-item">
              <div className="sidebar-content-group-sub-item-section">
                <div className="sidebar-content-group-sub-item">
                  {Constent.SUPERVISOR_SIDEBAR_SUBTYPE1}
                </div>
                <div className="sidebar-content-group-sub-item sidebar-color-yellow" onClick={() => {
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_OSI_VALUE
                  }));
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_STATUS_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_SUBTYPE1
                  }));
                  navigate("/workordersummary");
                }}>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.installation?.progress[0] || "0"}</div>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.installation?.progress[1] || "0.0"}%</div>
                </div>
              </div>
              <div className="sidebar-content-group-sub-item-section">
                <div className="sidebar-content-group-sub-item">
                  {Constent.SUPERVISOR_SIDEBAR_SUBTYPE2}
                </div>
                <div className="sidebar-content-group-sub-item sidebar-color-red" onClick={() => {
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_OSI_VALUE
                  }));
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_STATUS_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_SUBTYPE2
                  }));
                  navigate("/workordersummary");
                }}>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.installation?.aging[0] || "0"}</div>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.installation?.aging[1] || "0.0"}%</div>
                </div>
              </div>
              <div className="sidebar-content-group-sub-item-section">
                <div className="sidebar-content-group-sub-item">
                  {Constent.SUPERVISOR_SIDEBAR_SUBTYPE3}
                </div>
                <div className="sidebar-content-group-sub-item sidebar-color-green" onClick={() => {
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_OSI_VALUE
                  }));
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_STATUS_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_SUBTYPE3
                  }));
                  navigate("/workordersummary");
                }}>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.installation?.done[0] || "0"}</div>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.installation?.done[1] || "0.0"}%</div>
                </div>
              </div>
              <div className="sidebar-content-group-sub-item-section">
                <div className="sidebar-content-group-sub-item">
                  {Constent.SUPERVISOR_SIDEBAR_SUBTYPE4}
                </div>
                <div className="sidebar-content-group-sub-item sidebar-color-blue" onClick={() => {
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_OSI_VALUE
                  }));
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_STATUS_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_SUBTYPE4
                  }));
                  navigate("/workordersummary");
                }}>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.installation?.returned[0] || "0"}</div>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.installation?.returned[1] || "0.0"}%</div>
                </div>
              </div>
            </div>
          </div>
          <div className="sidebar-content-group-item-section">
            <div className="sidebar-content-group-item">{Constent.SUPERVISOR_SIDEBAR_OST_TITLE}</div>
            <div className="sidebar-content-group-item">
              <div className="sidebar-content-group-sub-item-section">
                <div className="sidebar-content-group-sub-item">
                  {Constent.SUPERVISOR_SIDEBAR_SUBTYPE1}
                </div>
                <div className="sidebar-content-group-sub-item sidebar-color-yellow" onClick={() => {
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_OST_VALUE
                  }));
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_STATUS_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_SUBTYPE1
                  }));
                  navigate("/workordersummary");
                }}>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.troubleshooting?.progress[0] || "0"}</div>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.troubleshooting?.progress[1] || "0.0"}%</div>
                </div>
              </div>
              <div className="sidebar-content-group-sub-item-section">
                <div className="sidebar-content-group-sub-item">
                  {Constent.SUPERVISOR_SIDEBAR_SUBTYPE2}
                </div>
                <div className="sidebar-content-group-sub-item sidebar-color-red" onClick={() => {
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_OST_VALUE
                  }));
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_STATUS_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_SUBTYPE2
                  }));
                  navigate("/workordersummary");
                }}>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.troubleshooting?.aging[0] || "0"}</div>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.troubleshooting?.aging[1] || "0.0"}%</div>
                </div>
              </div>
              <div className="sidebar-content-group-sub-item-section">
                <div className="sidebar-content-group-sub-item">
                  {Constent.SUPERVISOR_SIDEBAR_SUBTYPE3}
                </div>
                <div className="sidebar-content-group-sub-item sidebar-color-green" onClick={() => {
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_OST_VALUE
                  }));
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_STATUS_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_SUBTYPE3
                  }));
                  navigate("/workordersummary");
                }}>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.troubleshooting?.done[0] || "0"}</div>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.troubleshooting?.done[1] || "0.0"}%</div>
                </div>
              </div>
              <div className="sidebar-content-group-sub-item-section">
                <div className="sidebar-content-group-sub-item">
                  {Constent.SUPERVISOR_SIDEBAR_SUBTYPE4}
                </div>
                <div className="sidebar-content-group-sub-item sidebar-color-blue" onClick={() => {
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_OST_VALUE
                  }));
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_STATUS_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_SUBTYPE4
                  }));
                  navigate("/workordersummary");
                }}>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.troubleshooting?.returned[0] || "0"}</div>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.troubleshooting?.returned[1] || "0.0"}%</div>
                </div>
              </div>
            </div>
          </div>
          <div className="sidebar-content-group-item-section">
            <div className="sidebar-content-group-item">{Constent.SUPERVISOR_SIDEBAR_BTU_TITLE}</div>
            <div className="sidebar-content-group-item">
              <div className="sidebar-content-group-sub-item-section">
                <div className="sidebar-content-group-sub-item">
                  {Constent.SUPERVISOR_SIDEBAR_SUBTYPE1}
                </div>
                <div className="sidebar-content-group-sub-item sidebar-color-yellow" onClick={() => {
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_BTU_VALUE
                  }));
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_STATUS_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_SUBTYPE1
                  }));
                  navigate("/workordersummary");
                }}>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.btu_collection?.progress[0] || "0"}</div>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.btu_collection?.progress[1] || "0.0"}%</div>
                </div>
              </div>
              <div className="sidebar-content-group-sub-item-section">
                <div className="sidebar-content-group-sub-item">
                  {Constent.SUPERVISOR_SIDEBAR_SUBTYPE2}
                </div>
                <div className="sidebar-content-group-sub-item sidebar-color-red" onClick={() => {
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_BTU_VALUE
                  }));
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_STATUS_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_SUBTYPE2
                  }));
                  navigate("/workordersummary");
                }}>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.btu_collection?.aging[0] || "0"}</div>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.btu_collection?.aging[1] || "0.0"}%</div>
                </div>
              </div>
              <div className="sidebar-content-group-sub-item-section">
                <div className="sidebar-content-group-sub-item">
                  {Constent.SUPERVISOR_SIDEBAR_SUBTYPE3}
                </div>
                <div className="sidebar-content-group-sub-item sidebar-color-green" onClick={() => {
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_BTU_VALUE
                  }));
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_STATUS_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_SUBTYPE3
                  }));
                  navigate("/workordersummary");
                }}>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.btu_collection?.done[0] || "0"}</div>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.btu_collection?.done[1] || "0.0"}%</div>
                </div>
              </div>
              <div className="sidebar-content-group-sub-item-section">
                <div className="sidebar-content-group-sub-item">
                  {Constent.SUPERVISOR_SIDEBAR_SUBTYPE4}
                </div>
                <div className="sidebar-content-group-sub-item sidebar-color-blue" onClick={() => {
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_BTU_VALUE
                  }));
                  dispatch(saveSummaryData({
                    type: ApiConstant.SUMMARY_PILL_STATUS_ITEM,
                    value: Constent.SUPERVISOR_SIDEBAR_SUBTYPE4
                  }));
                  navigate("/workordersummary");
                }}>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.btu_collection?.returned[0] || "0"}</div>
                  <div className="sidebar-content-group-sub-item-value">{summaryData?.btu_collection?.returned[1] || "0.0"}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
