import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Header } from '../../Components/Header/Header';
import TrackOrderBodyHeader from '../../Components/MainBody/BodyHeader/Supervisor/TrackOrderBodyHeader';
import SupervisorSidebar from '../../Components/MainBody/Sidebar/SupervisorSidebar';

import '../../Assets/CSS/TrackOrderPage.css';
import Footer from '../../Components/Footer/Footer';
import TrackOrderBodyContent from '../../Components/MainBody/BodyContent/Supervisor/TrackOrderBodyContent';
import TrackOrderAdvanceSearchModal from '../../Components/Modals/TrackOrderAdvanceSearchModal';
import { ApiConstant } from '../../Assets/Constants/ApiConstants';
import { LovKeyDataValueNullOrEmpty, validNumberInput } from '../../Components/Common/GenericFunctions';
import { saveLovs } from '../../ReduxFeature/StateFeatures/LovDataState';
import { ToastContainer, toast } from 'react-toastify';
import { headers, TrackOrderList } from '../../Api/ApiCalls';
import { setTrackOrderData } from '../../ReduxFeature/StateFeatures/TrackOrderDataState';

const TrackOrderPage = ({ globalState, setGlobalState }) => {
    const userDataObj = useSelector((state) => state.userDataInfo);
    const lovDataObj = useSelector((state) => state.lovDataInfo);
    const trackOrderDataObj = useSelector((state) => state.trackOrderDataInfo);


    const dispatch = useDispatch();
    const { searchType, fromDateValue, toDateValue, orderStatusValue, srvcAttrKeyValue,
        orderContextValue, orderNumberValue, customerIdValue, srvcAttrValValue, trackOrderListData } = trackOrderDataObj;

    const inputBtnDisabled = !(orderContextValue?.length > 0 && orderContextValue !== "")

    const [pageState, setPageState] = useState({
        dataLoader: false,
        // Error Part
        getTrackOrderDataError: false,
        getTrackOrderDataErrorMsg: "",
    });
    function ErrorMessageSetUp({ error, message1, message2 }) {
        let errorMessage;
        if (error.response.status === 500 || error.response.status === 503) {
            errorMessage = message1;
            toast.error(errorMessage,
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 10000,
                    theme: 'colored'
                }
            );
        }
        else if (error.response.status === 404) {
            errorMessage = message2;
            toast.warning(errorMessage,
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 10000,
                    theme: 'colored'
                }
            );
        }
        else {
            errorMessage = error.response.statusText;
            toast.error(errorMessage,
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 10000,
                    theme: 'colored'
                }
            );
        }
        return errorMessage;
    }

    function trackOrderApiCalls() {
        setPageState({ ...pageState, dataLoader: true });
        //  Header
        const header = headers(
            userDataObj?.userData?.activeUserData?.authToken
        );


        /*------validation need to implement-------*/
        const request = {
            searchType: searchType,
            dealerCode: userDataObj?.userData?.activeUserData?.userData?.outletId,
            startDate: fromDateValue?.length > 0 ? fromDateValue : null,
            endDate: toDateValue?.length > 0 ? toDateValue : null,
            orderContext: orderContextValue?.length > 0 ? orderContextValue : null,
            orderStatus: orderStatusValue?.length > 0 ? orderStatusValue : null,
            orderNumber: orderNumberValue?.length > 0 ? orderNumberValue : null,
            customerId: customerIdValue?.length > 0 ? customerIdValue : null,
            srvcAttrKey: srvcAttrValValue?.length > 0 ? validNumberInput(srvcAttrValValue) ? "MSISDN" : "USER_NAME"
                : null, // "MSISDN" (numaric) else "USER_NAME"
            srvcAttrVal: srvcAttrValValue?.length > 0 ? srvcAttrValValue : null
        }

        TrackOrderList("/fibre/order/list", request, header).then(response => {
            if (response?.status === "SUCCESS" && response?.orderItems?.length > 0) {
                dispatch(setTrackOrderData({
                    type: ApiConstant.TRACK_ORDER_LIST_DATA,
                    data: response?.orderItems
                }));
                setPageState({
                    ...pageState, dataLoader: false,
                    getTrackOrderDataError: false, getTrackOrderDataErrorMsg: ""
                });
            }
            else {
                dispatch(setTrackOrderData({
                    type: ApiConstant.TRACK_ORDER_RESET_LIST_DATA,
                    data: null
                }));
                setPageState({
                    ...pageState, dataLoader: false,
                    getTrackOrderDataError: true, getTrackOrderDataErrorMsg: "No Data Found"
                });
            }

        }).catch(error => {
            dispatch(setTrackOrderData({
                type: ApiConstant.TRACK_ORDER_RESET_LIST_DATA,
                data: null
            }));
            const errorMessage = ErrorMessageSetUp({
                error: error, message1: ApiConstant.TRACK_ORDER_LIST_ERROR_MESSAGE,
                message2: ApiConstant.INVENTORY_VALID_INPUT_WARING_MESSAGE
            });
            setPageState({
                ...pageState, dataLoader: false,
                getTrackOrderDataError: true, getTrackOrderDataErrorMsg: errorMessage
            });
        })
    }

    useEffect(() => {
        if (LovKeyDataValueNullOrEmpty({ listValue: lovDataObj?.ordercontext })) {
            dispatch(saveLovs({
                key: ApiConstant.LOV_STORE_TRACK_ORDER_CONTEXT_KEY,
                value: ApiConstant.TRACK_ORDER_CONTEXT_LOVS
            }));
        }
        if (LovKeyDataValueNullOrEmpty({ listValue: lovDataObj?.orderstatus })) {
            dispatch(saveLovs({
                key: ApiConstant.LOV_STORE_TRACK_ORDER_STATUS_KEY,
                value: ApiConstant.TRACK_ORDER_STATUS_LOVS
            }));
        }
        if (LovKeyDataValueNullOrEmpty({ listValue: lovDataObj?.ordersearchtype })) {
            dispatch(saveLovs({
                key: ApiConstant.LOV_STORE_TRACK_ORDER_SEARCH_TYPE_KEY,
                value: ApiConstant.TRACK_ORDER_SEARCH_TYPE_LOVS
            }));
        }
    }, []);

    return (
        <div className='page-start-section'>
            <Header userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} tab2={true} />
            <div className={globalState?.sidebarState ? "body-start-section sidebar-active-section" : "body-start-section"}>
                {/* Sidebar */}
                <SupervisorSidebar userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} />
                <div className="body-full-content-section">
                    <TrackOrderBodyHeader userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState}
                        pageState={pageState} setPageState={setPageState} trackOrderDataObj={trackOrderDataObj}
                        trackOrderApiCalls={trackOrderApiCalls} lovDataObj={lovDataObj} inputBtnDisabled={inputBtnDisabled}/>

                    <TrackOrderBodyContent userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState}
                        pageState={pageState} setPageState={setPageState}
                        trackOrderListData={trackOrderListData} trackOrderDataObj={trackOrderDataObj} />
                </div>
                <Footer />
            </div>
            <TrackOrderAdvanceSearchModal pageState={pageState} setPageState={setPageState} inputBtnDisabled={inputBtnDisabled}
                trackOrderDataObj={trackOrderDataObj} lovDataObj={lovDataObj} trackOrderApiCalls={trackOrderApiCalls} />
            <ToastContainer />
        </div>
    )
}

export default TrackOrderPage;