import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { headers, WorkOrderPage } from '../../Api/ApiCalls';
import { ApiConstant } from '../../Assets/Constants/ApiConstants';
import Footer from '../../Components/Footer/Footer';
import { Header } from '../../Components/Header/Header';
import InstallerSearchActivityBodyContent from '../../Components/MainBody/BodyContent/Installer/InstallerSearchActivityBodyContent';
import SearchActivityBodyHeader from '../../Components/MainBody/BodyHeader/Supervisor/SearchActivityBodyHeader';
import WorkOrderAdvanceSearchModal from '../../Components/Modals/WorkOrderAdvanceSearchModal';
import { resetWorkOrderListData, setSearchWorkOrderListData } from '../../ReduxFeature/StateFeatures/WorkOrderDataState';

import { ToastContainer, toast } from 'react-toastify';
import { WorkOrderAdvanceSearchRequestType } from '../../Components/Common/GenericFunctions';
import { Constent } from '../../Assets/Constants/Constent';

const InstallerSearchAcivityPage = ({ globalState, setGlobalState }) => {
    const userDataObj = useSelector((state) => state.userDataInfo);
    const workOrderDataObj = useSelector((state) => state.workOrderInfo);
    const dispatch = useDispatch();

    const { workOrderId, workOrderFromDate, workOrderToDate, workOrderList, searchType } = workOrderDataObj;

    const [pageState, setPageState] = useState({
        dataLoader: false,
        totalItems: 0,
        workOrderList: [],

        apicalled: false,

        workOrderId: "",
        workOrderFromDate: "",
        workOrderToDate: "",
        // Advance Search Modal
        advanceSearchLovs: ApiConstant.WORK_ORDER_ADVANCE_SEARCH_LOV1,
        advanceSearchSubLovs: [],

        selectedAdvanceSearchType: "",
        selectedAdvanceSearchSubTypeList: [],

        // Error Part
        getDataError: false,
        getDataErrorMsg: "",
    });

    function searchWorkOrderListApiCall() {
        //  Header
        const header = headers(
            userDataObj?.userData?.activeUserData?.authToken
        );

        dispatch(resetWorkOrderListData());
        setPageState({ ...pageState, dataLoader: true });

        const workOrderListRequest = {
            fromDate: (pageState?.workOrderFromDate
                || moment().format("YYYY-MM-DD")) + " 00:00",
            toDate: (pageState?.workOrderToDate
                || moment().format("YYYY-MM-DD")) + " 23:59",
            requestType: WorkOrderAdvanceSearchRequestType({ value: searchType }),
            activityType: pageState?.selectedAdvanceSearchType?.length > 0 ?
                pageState?.selectedAdvanceSearchType : null,
            activitySubType: pageState?.selectedAdvanceSearchSubTypeList?.length > 0 ?
                pageState?.selectedAdvanceSearchSubTypeList : null,
            itemSubType: null,
            stsType: null,
            activityId: (searchType === Constent.WORK_ORDER_INPUT_ITEM_LABEL1 && pageState?.workOrderId?.length > 0) ?
            "FieldActivity-" + pageState?.workOrderId : null,
            userId: userDataObj?.userData?.activeUserData?.userData?.userId,
            orderNumber: (searchType === Constent.TRACK_ORDER_INPUT_ITEM_LABEL1 && pageState?.workOrderId?.length > 0) ? pageState?.workOrderId : null,
            customerId: (searchType === Constent.WORK_ORDER_INPUT_ITEM_LABEL2 && pageState?.workOrderId?.length > 0) ? pageState?.workOrderId : null,            
            // Infinite scroll is pending
            limit: 500,
            offSet: 0
        };
        WorkOrderPage("/fibre/activity/installerlist", workOrderListRequest, header).then(response => {
            dispatch(setSearchWorkOrderListData(response?.workQueueVo));
            setPageState({
                ...pageState, dataLoader: false,
                getDataError: false,
                getDataErrorMsg: "",
            });
        }).catch(error => {
            dispatch(resetWorkOrderListData());
            let errorMessage;
            if (error.response.status == 500 || error.response.status == 503) {
                errorMessage = ApiConstant.WORK_ORDER_LIST_ERROR_MESSAGE;
                toast.error(errorMessage,
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 10000,
                        theme: 'colored'
                    }
                );
            }
            else if (error.response.status == 404) {
                errorMessage = ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE;
                toast.warning(errorMessage,
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 10000,
                        theme: 'colored'
                    }
                );
            }
            else {
                errorMessage = error.response.statusText;
            }

            setPageState({
                ...pageState, getDataError: true,
                dataLoader: false,
                getDataErrorMsg: errorMessage
            });
        })
    }

    useEffect(() => {
        searchWorkOrderListApiCall();
        setGlobalState({ ...globalState, sidebarState: false })
    }, []);

    // Filter List Section
    const filterList = workOrderList?.filter(item => {

        if (pageState?.workOrderId === ""
            || item?.itemId?.toLowerCase()?.includes(pageState?.workOrderId?.toLowerCase())
            || item?.orderId?.toLowerCase()?.includes(pageState?.workOrderId?.toLowerCase())
            || item?.customerId?.toLowerCase()?.includes(pageState?.workOrderId?.toLowerCase())) {
            return item;
        }
    });

    return (
        <div className='page-start-section'>
            <Header userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} installerTabFlag={false} />
            <div className="body-start-section">
                <div className="body-full-content-section">
                    <SearchActivityBodyHeader userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState}
                        pageState={pageState} setPageState={setPageState} workOrderDataObj={workOrderDataObj}
                        searchWorkOrderListApiCall={searchWorkOrderListApiCall} filterList={filterList} />
                    <InstallerSearchActivityBodyContent userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState}
                        pageState={pageState} setPageState={setPageState} filterList={filterList} />
                </div>
                <Footer />
            </div>
            <WorkOrderAdvanceSearchModal pageState={pageState} setPageState={setPageState} workOrderDataObj={workOrderDataObj} searchWorkOrderListApiCall={searchWorkOrderListApiCall}/>
            <ToastContainer />
        </div>
    )
}

export default InstallerSearchAcivityPage;