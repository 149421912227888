import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApiConstant } from "../../../../Assets/Constants/ApiConstants";
import { Constent } from "../../../../Assets/Constants/Constent";
import { RightArrowIcon } from "../../../../Assets/Images/CelcomSvgImages";
import { setTrackStockFieldValue } from "../../../../ReduxFeature/StateFeatures/InventoryDataState";
import { TrackStockItemStatusCal } from "../../../Common/GenericFunctions";

const AdminTrackStockCard = ({
  globalState,
  pageState,
  setPageState,
  data,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { className } = TrackStockItemStatusCal(data?.transferStatus);


  return (
    <div className="track-stock-card-section">
      <div className="wrapper-track-stock-card-section">
        <div className="normal-card-item-section">
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE1}
            </div>
            <div className="card-item-value">{data?.transferId || "-"}</div>
          </div>
        </div>
        <div
          className={`normal-card-item-section inventory-status-green`}
          role="button"
          onClick={() => {
            dispatch(
              setTrackStockFieldValue({
                type: ApiConstant.INVENTORY_TRACK_STOCK_SELECTED_DATA_ITEM,
                value: data,
              })
            ) && navigate("/inventory/trackrequestdetails");
          }}
        >
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE2}
            </div>
            {pageState.trackStockActiveRadioBtn ===
            Constent.REQUEST_STOCK_RADIO_BTN_LABLE ? (
              <div className="card-item-value">
                {ApiConstant.INVENTORY_TRACK_STOCK_REQUEST_TYPE}
              </div>
            ) : (
              <div className="card-item-value">
                {ApiConstant.INVENTORY_TRANSFER_STOCK_REQUEST_TYPE}
              </div>
            )}
          </div>
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE3}
            </div>
            <div className="card-item-value">{data?.createDate || "-"}</div>
          </div>
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE4}
            </div>
            <div className="card-item-value">
              {data?.transferFromStoreId || "-"}
            </div>
          </div>
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE5}
            </div>
            <div className="card-item-value">
              {data?.transferToStoreId || "-"}
            </div>
          </div>
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE6}
            </div>
            <div className="card-item-value">
              {data?.transferToStoreName || "-"}
            </div>
          </div>
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE7}
            </div>
            <div className={`card-item-value ${className}`}>
              {data?.transferStatus || "-"}
            </div>
          </div>
          <div className="normal-card-item-btn-section">
            <RightArrowIcon stroke={"#009BDF"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTrackStockCard;
