import React from 'react';
import { useSelector } from 'react-redux';
import { Constent } from '../../../Assets/Constants/Constent';
import { HeaderNavChild2 } from '../../../Components/Common/HeaderNav';
import Footer from '../../../Components/Footer/Footer';
import { Header } from '../../../Components/Header/Header';
import PlaceOrderConfirmationBodyContent from '../../../Components/MainBody/BodyContent/Supervisor/ConfirmationPagesContent/PlaceOrderConfirmationBodyContent';
import SupervisorSidebar from '../../../Components/MainBody/Sidebar/SupervisorSidebar';

const PlaceOrderConfirmationPage = ({ globalState, setGlobalState }) => {
    const userDataObj = useSelector((state) => state.userDataInfo);

    return (
        <div className='page-start-section'>
            <Header userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} tab5={true} />
            <div className={globalState?.sidebarState ? "body-start-section sidebar-active-section" : "body-start-section"}>
                {/* Sidebar */}
                <SupervisorSidebar userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState} />
                <div className="body-full-content-section">
                    <HeaderNavChild2 activeItem={Constent.BODY_HEADER_CART_PATH} />
                    <PlaceOrderConfirmationBodyContent globalState={globalState} setGlobalState={setGlobalState} />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default PlaceOrderConfirmationPage;