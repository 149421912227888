import React from "react";
import { useSelector } from "react-redux";
import { ApiConstant } from "../../../../../Assets/Constants/ApiConstants";
import { toast } from "react-toastify";
import * as FileSaver from "file-saver";
import {
  headers,
  InstallerDownloadDocuments,
} from "../../../../../Api/ApiCalls";

export const WorkOrderDocumentContent = ({
  documentItem,
  index,
  userDataObj,
  selectedWorkOrder,
}) => {
  return (
    <div
      className="document-section"
      key={index}
      onClick={() => {
        const header = headers(
          userDataObj?.userData?.activeUserData?.authToken
        );
        const filename = documentItem?.fileNameStoredInServer;
        const downloadDocumentsRequest = {
          orderId: selectedWorkOrder?.itemId,
          filename: filename,
        };

        InstallerDownloadDocuments(
          "/fibre/file/sftpdownload/",
          downloadDocumentsRequest,
          header
        )
          .then((response) => {
            const type = response?.type;
            const url = window.URL.createObjectURL(
              new File([response], documentItem?.actualFileName, { type })
            );
            const downloadLink = document.createElement("a");
            const downloadfileName = documentItem?.actualFileName;
            downloadLink.href = url;
            downloadLink.download = downloadfileName;
            downloadLink.click();
          })
          .catch(() => {
            toast.warning(
              ApiConstant.DOWNLOAD_DOCUMENT_WARNING_MESSAGE + " " + filename,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 10000,
                theme: "colored",
              }
            );
          });
      }}
    >
      {documentItem?.actualFileName}
    </div>
  );
};

// export default WorkOrderDocumentContent;
