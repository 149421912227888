import React from "react";

export const UnderLineNavbar2 = ({
  nav1,
  nav2,
  nav3,
  notifi,
  count1,
  count2,
  count3,
  pageState,
  setPageState,
}) => {
  return (
    <div className="nav-responsive nav-responsive-3">
      <div className="nav nav-underlines nav-child-3 position-relative text-center">
        <a
          className={`nav-link col ${
            pageState?.activeTab === nav1 ? "active" : ""
          }`}
          data-toggle="tab"
          href={"#" + nav1}
          onClick={() => setPageState({ ...pageState, activeTab: nav1 })}
        >
          {nav1}
          {notifi && count1 > 0 && (
            <div className="nav-link-notification-section">{count1}</div>
          )}
        </a>
        <a
          className={`nav-link col ${
            pageState?.activeTab === nav2 ? "active" : ""
          }`}
          data-toggle="tab"
          href={"#" + nav2}
          onClick={() => setPageState({ ...pageState, activeTab: nav2 })}
        >
          {nav2}
          {notifi && count2 > 0 && (
            <div className="nav-link-notification-section">{count2}</div>
          )}
        </a>
        <a
          className={`nav-link col ${
            pageState?.activeTab === nav3 ? "active" : ""
          }`}
          data-toggle="tab"
          href={"#" + nav3}
          onClick={() => setPageState({ ...pageState, activeTab: nav3 })}
        >
          {nav3}
          {notifi && count3 > 0 && (
            <div className="nav-link-notification-section">{count3}</div>
          )}
        </a>
        <hr className="link-active" />
      </div>
    </div>
  );
};
