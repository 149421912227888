import React, { useState } from "react";
import { Constent } from "../../Assets/Constants/Constent";
import { FileUploader } from "react-drag-drop-files";
import { DeleteIcon, FileUploadIcon } from "../../Assets/Images/CelcomSvgImages";
import { SuperAdminUploadedDocuments, headers } from "../../Api/ApiCalls";
import { useSelector } from "react-redux";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { toast, ToastContainer } from "react-toastify";
import * as XLSX from 'xlsx';

const UploadScreen = () => {
    const fileTypes = ["XLSX", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    const [fileData, setFileData] = useState();
    const [fileUploaded, setFileUploaded] = useState(false);
    const hiddenFileInput = React.useRef(null);
    const [errorMessage, setErrorMessage] = useState({ flag: false, msg: "" });
    const userDataObj = useSelector((state) => state.userDataInfo);
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);
    const userId = userDataObj?.userData?.activeUserData?.userData?.userId;
    const [upload, setUpload] = useState();

    const handleDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let file = e?.dataTransfer?.files[0];
        const fileName = file?.name;
        const filetype = file?.type
        //setfileType(filetype)
        // Get the file name and size
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            const isValid = validateExcelData(jsonData);
            if (fileTypes.includes(filetype)) {
                if (isValid) {
                    if (file.size == 0) {
                        setErrorMessage({ flag: true, msg: "File size should not 0kb" })
                    } else if (file.size <= 51200) {
                        setFileData(file);
                        setErrorMessage({ flag: false, msg: "" })
                        setFileUploaded(true)
                    } else {
                        setErrorMessage({ flag: true, msg: "File size should not exceed 50kb" })

                    }
                }
                else {
                    setErrorMessage({ flag: true, msg: "Please use only allowed special characters." })
                    // setShowMsgSize(false)
                }
            } else {
                setErrorMessage({ flag: true, msg: "Please upload .xlsx file" })
            }
        };
        reader.onerror = (event) => {
            setErrorMessage({ flag: true, msg: "Please use only allowed special characters." })
        };
        reader.readAsArrayBuffer(file);
    }

    const validateExcelData = (data) => {
        const allowedChars = /^[a-zA-Z0-9\/=_#@|\-\\:;\s]*$/;
        for (let row of data) {
            for (let cell of row) {
                // console.log("cell", cell)
                if (cell != "" && cell != undefined && cell.toString().trim() != "" && !allowedChars.test(cell.toString())) {
                    //   console.error(`Invalid cell value found: ${cell}`);
                    return false;
                }
            }
        }
        return true;
    }

    async function attachUploadedDocument(e) {
        let file = e?.target?.files[0];
        const fileName = file?.name;
        const filetype = file?.type
        //setfileType(filetype)
        // Get the file name and size
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            const isValid = validateExcelData(jsonData);
            if (fileTypes.includes(filetype)) {
                if (isValid) {
                    if (file.size == 0) {
                        setErrorMessage({ flag: true, msg: "File size should not 0kb" })
                    } else if (file.size <= 51200) {
                        setFileData(file);
                        if (e?.target.files?.length >= 1) {
                            setErrorMessage({ flag: false, msg: "" })
                            setFileUploaded(true)
                        }

                    } else {
                        setErrorMessage({ flag: true, msg: "File size should not exceed 50kb" })

                    }
                }
                else {
                    setErrorMessage({ flag: true, msg: "Please use only allowed special characters." })
                    // setShowMsgSize(false)
                }
            } else {
                setErrorMessage({ flag: true, msg: "Please upload .xlsx file" })
            }
        };
        reader.onerror = (event) => {
            setErrorMessage({ flag: true, msg: "Please use only allowed special characters." })
        };
        reader.readAsArrayBuffer(file);
    }

    function uploadBtnClick() {
        header["Content-Type"] = "multipart/form-data";
        const file = new FormData();
        file.append("file", fileData);
        let url = "fibre/upload/WorkNotes";
        let request = {
            request_id: timestamp,
            user_id: userId,
            file: file.get("file")
        };

        // const header = headers(userDataObj?.userData?.activeUserData?.authToken);
        SuperAdminUploadedDocuments(url, request, header)
            .then((res) => {
                setUpload(res);
                setFileData()
                setFileUploaded(false)
                if (res?.statusCode === "0000") {
                    toast.success(res?.statusDescription, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: false,
                        theme: "colored",
                    })
                } else {
                    toast.error(res?.statusDescription, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 5000,
                        theme: "colored",
                    })
                }

            })
            .catch((err) => {
                setUpload(err);
                let errormsg = err.response?.data?.statusDescription
                if (errormsg) {
                    toast.error(errormsg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 5000,
                        theme: "colored",
                    });
                } else {
                    toast.error("request failed", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 5000,
                        theme: "colored",
                    });
                }

            })
    }
    const onClicked = event => {
        event.preventDefault();
        hiddenFileInput.current.click();
    }
    const date = new Date();
    const timestamp = (date.getFullYear().toString() + date.getMonth().toString() +
        date.getDate().toString() + date.getHours().toString() +
        date.getMinutes().toString() + date.getSeconds().toString() + "_" + userId?.toString())

    function onDelete() {
        setFileUploaded(false)
        setFileData()
    }

    return (
        <>
            <div className="workorder-header-search-group-section">
                <div className={`${"col-xxl-12"} col-xl-12 wrapper-workorder-header-search-group`}>
                    <div className="workorder-header-search-group-row-section">
                        {Constent.HEADER_MENU_ITEM6}
                    </div>
                    <div className="upload-report-search-group-section">
                        <div className="view-report-search-group-section">
                            <div className="view-report-serch-item-section">
                                <fieldset>
                                    <div className="view-workorder-report-serch-upload-section"
                                        draggable
                                        onDragOver={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}
                                        onDrop={(e) => { handleDrop(e) }}
                                    >
                                        {!fileUploaded &&
                                            <input
                                                type="file"
                                                onChange={(e) => { attachUploadedDocument(e) }}
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                disabled={fileUploaded}
                                                multiple={false}
                                                ref={hiddenFileInput}
                                                style={{ display: "none" }}
                                            />}
                                        {fileUploaded ? <label className="view-workorder-report-serch-upload-button">{fileData?.name}<span onClick={() => onDelete()}><DeleteIcon /></span></label> :
                                            <label className="view-workorder-report-serch-upload-button" onClick={onClicked}>File to upload  <FileUploadIcon /></label>
                                        }
                                        <p>Only .xlsx file, with special characters / = _ # @ | -\;: are allowed.</p>
                                    </div>
                                </fieldset>
                                <div
                                    className="workorder-search-group-section"
                                // onClick={reportExtractionAPICall}
                                >
                                    <div
                                        className="search-btn-section"
                                        onClick={() => { uploadBtnClick() }}
                                    >
                                        {/* <SearchIcon /> */}

                                        {Constent.UPLOAD_BTN1}
                                    </div>
                                </div>

                            </div>
                            {errorMessage?.flag && <span className="errormessage">{errorMessage?.msg}</span>}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default UploadScreen;
