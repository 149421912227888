import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Constent } from '../../Assets/Constants/Constent'
import { CloseModalIcon, ConfirmationIcon, MoveLeftArrowIcon, MoveRightArrowIcon } from '../../Assets/Images/CelcomSvgImages';
import { resetCartData } from '../../ReduxFeature/StateFeatures/CartDataState';
import { resetInvData } from '../../ReduxFeature/StateFeatures/InventoryDataState';
import { resetLovData } from '../../ReduxFeature/StateFeatures/LovDataState';
import { resetTrackOrderData } from '../../ReduxFeature/StateFeatures/TrackOrderDataState';
import { resetData, changeActiveTab } from '../../ReduxFeature/StateFeatures/UserDataState';
import { resetWorkOrderData } from '../../ReduxFeature/StateFeatures/WorkOrderDataState';
import { useDispatch, useSelector } from 'react-redux';
import { headers, SwitchSupervisorUser, SwitchUser } from '../../Api/ApiCalls';

const SwitchUserModal = ({ switchBack, pageState, setPageState }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userDataObj = useSelector((state) => state.userDataInfo);

    const reSetState = () => {
        dispatch(resetCartData());
        dispatch(resetWorkOrderData());
        dispatch(resetLovData());
        dispatch(resetInvData());
        dispatch(resetTrackOrderData());
    };


    function adminSwitchUserDetailsApiCall(header) {
        /* ----- Switch User Api Call ------ */
        SwitchUser("/fibre/getDealerDetails",
            pageState?.switchUserData?.userId, header
        ).then(response => {
            setPageState({ ...pageState, apiCallAction: false });
            reSetState();
            dispatch(changeActiveTab({
                userRole: response?.role?.split("_")[1],
                authToken: response?.authToken,
                userData: response?.userData
            }))
                && navigate("/dashboard")
        }).catch(error => {
            // console.log(error);
            setPageState({ ...pageState, apiCallAction: false });
        });
    }

    function supervisorSwitchUserDetailsApiCall(header) {
        const request = {
            "username": userDataObj?.userData?.loginUserData?.userData?.userId,
            "outletId": userDataObj?.userData?.loginUserData?.userData?.outletId,
            "requestedUser": pageState?.switchUserData?.userId
        }

        SwitchSupervisorUser("/fibre/switch/supervisorDetails", request, header).then(response => {
            setPageState({ ...pageState, apiCallAction: false });
            reSetState();
            response.userData['listOfSupervisors'] = userDataObj?.userData?.loginUserData?.userData?.listOfSupervisors;
            
            dispatch(changeActiveTab({
                userRole: response?.role?.split("_")[1],
                authToken: response?.authToken,
                userData: response?.userData
            }))
                && navigate("/dashboard")
        }).catch(err => {
            setPageState({ ...pageState, apiCallAction: false });
        })
    }

    function switchUserApiCallConfirmation() {

        if (switchBack) {
            reSetState();
            dispatch(changeActiveTab(userDataObj?.userData?.loginUserData))
                && navigate("/switchuser")
        }
        else {
            setPageState({ ...pageState, apiCallAction: true });
            //  Header
            const header = headers(
                userDataObj?.userData?.activeUserData?.authToken
            );
            if (Constent.ADMIN_ROLE === userDataObj?.userData?.loginUserData?.userRole) {
                adminSwitchUserDetailsApiCall(header);
            }
            else if (Constent.SUPER_ROLE === userDataObj?.userData?.loginUserData?.userRole) {
                supervisorSwitchUserDetailsApiCall(header);
            }
        }
    }

    return (
        <div className="modal fade switch-user-confirmarion-modal" id="switchUserModal" data-backdrop="static"
            data-keyboard="false" tabIndex="-1" aria-labelledby="switchUserModalLabel"
            aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header pb-0">
                        <div className="wrapper-modal-header pt-5">
                            <div className="modal-header-title-section">
                                <ConfirmationIcon />
                                <h5 className="modal-title" id="switchUserModalLabel">
                                    {Constent.CONFIRMATION_MODAL_TITLE}
                                </h5>
                                <h5 className="modal-title">
                                    {Constent.SWITCH_TO_ANOTHER_ACCOUNT_TITLE1}<span>{` ${Constent.SWITCH_TO_TITLE} `}</span>{Constent.SWITCH_TO_ANOTHER_ACCOUNT_TITLE2}
                                </h5>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <CloseModalIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="wrapper-modal-body">
                            <div className={`switch-user-modal-body-section 
                            ${switchBack ? "switch-user-bg-green" : "switch-user-bg-red"}`}>
                                <div className="switch-user-modal-body-item-section"></div>
                                <div className="switch-user-modal-body-item-section">
                                    <div className="switch-user-modal-body-item-group">
                                        <div className="switch-user-modal-body-item">
                                            {
                                                switchBack ?
                                                    Constent.SWITCH_USER_MODAL_TO_TITLE :
                                                    Constent.SWITCH_USER_MODAL_FROM_TITLE

                                            }
                                        </div>
                                        <div className="switch-user-modal-body-item">
                                            {
                                                userDataObj?.userData?.loginUserData?.userData?.firstName
                                            }
                                        </div>
                                        <div className="switch-user-modal-body-item">
                                            {
                                                userDataObj?.userData?.loginUserData?.userData?.userId
                                            }
                                        </div>
                                        <div className="switch-user-modal-body-item">
                                            {
                                                userDataObj?.userData?.loginUserData?.userRole === Constent.SUPER_ROLE
                                                    ?
                                                    Constent.SWITCH_SUPER_USER_ROLE_MODAL: Constent.SWITCH_ADMIN_USER_ROLE_MODAL
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="switch-user-modal-body-item-section">
                                    {
                                        switchBack ? <MoveLeftArrowIcon /> : <MoveRightArrowIcon />
                                    }
                                </div>
                                <div className="switch-user-modal-body-item-section">
                                    <div className="switch-user-modal-body-item-group">
                                        <div className="switch-user-modal-body-item">
                                            {
                                                switchBack ?
                                                    Constent.SWITCH_USER_MODAL_FROM_TITLE :
                                                    Constent.SWITCH_USER_MODAL_TO_TITLE

                                            }
                                        </div>
                                        <div className="switch-user-modal-body-item">
                                            {
                                                switchBack ?
                                                    userDataObj?.userData?.activeUserData?.userData?.firstName :
                                                    pageState?.switchUserData?.userName
                                            }
                                        </div>
                                        <div className="switch-user-modal-body-item">
                                            {
                                                switchBack ?
                                                    userDataObj?.userData?.activeUserData?.userData?.userId :
                                                    pageState?.switchUserData?.userId
                                            }
                                        </div>
                                        <div className="switch-user-modal-body-item">
                                            {
                                                Constent.SWITCH_SUPER_USER_ROLE_MODAL
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="refresh-btn-section" data-dismiss="modal">
                            <span>{Constent.CANCEL_BTN}</span>
                        </div>
                        <div className="search-btn-section" data-dismiss="modal"
                            onClick={switchUserApiCallConfirmation}>
                            {Constent.CONFIRMATION_BTN}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SwitchUserModal;