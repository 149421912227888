import React from 'react';
import { Constent } from '../../Assets/Constants/Constent';
import { CloseModalIcon } from '../../Assets/Images/CelcomSvgImages';
import { InseallerDetailsModalBodyContent } from '../Common/ModalHelper';

const InstallerDetailsInfoModal = ({ installerDetails }) => {
    return (
        <div className="modal fade installer-details-modal hidden-lg"
            id="manageInstallerModal" data-backdrop="static"
            data-keyboard="false" tabIndex="-1" aria-labelledby="manageInstallerModalLabel"
            aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header pb-0">
                        <div className="wrapper-modal-header pt-5">
                            <div className="modal-header-title-section">
                                <h5 className="modal-title" id="manageInstallerModalLabel">
                                    {Constent.INSTALLER_DETAILS_MODAL_TITLE}
                                </h5>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <CloseModalIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="wrapper-modal-body">
                            <InseallerDetailsModalBodyContent installerNameVal={installerDetails?.userId || "-"}
                                userNameVal={(installerDetails?.firstName + " " + installerDetails?.lastName) || "-"}
                                emailVal={installerDetails?.email || "-"}
                                contactNumVal={installerDetails?.mobileNo || "-"}
                                idTypeVal={installerDetails?.idType || "-"} idNumberVal={installerDetails?.id || "-"}
                                districtVal={installerDetails?.district || "-"} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="okay-btn-section" data-dismiss="modal">
                            <span>{Constent.OKAY_BTN}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InstallerDetailsInfoModal;