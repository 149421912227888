import { NoDataFoundIcon } from "../../Assets/Images/CelcomSvgImages";

export const CardItemGroup = ({ title, value }) => {
    return (
        <div className="card-item-group">
            <div className="card-item-title">
                {title}
            </div>
            <div className="card-item-value">
                {value}
            </div>
        </div>
    );
}

export const CardWrapperItemGroup = ({ titleCol1, titleCol2, titleCol3, valueCol1, valueCol2, valueCol3 }) => {
    return (
        <div className="wrapper-card-body-section">
            <div className="card-body-col-section">
                <CardItemGroup title={titleCol1} value={valueCol1} />
            </div>
            <div className="card-body-col-section">
                <CardItemGroup title={titleCol2} value={valueCol2} />
            </div>
            <div className="card-body-col-section">
                <CardItemGroup title={titleCol3} value={valueCol3} />
            </div>
        </div>
    );
}

export const NoDataFoundItemGroup = ({ message }) => {
    return (
        <div className="no-data-found-item-group">
            <div className="no-data-found-item-image">
                <NoDataFoundIcon />
            </div>
            <div className="no-data-found-item-text">
                {message?.length > 0 ? message : "Sorry! No data available."}</div>
        </div>
    )
}