import React from 'react'
import { Link } from 'react-router-dom'
import '../../Assets/CSS/Footer.css'
import { OtherImages } from '../../Assets/Images/CelcomOtherImages'


export default function Footer({ loginPage }) {
  return (
    <div className={`footer-start-section ${loginPage ? "footer-start-section-login" : ""}`}>
      <div className="footer-col-section">
        <a href='https://www.celcom.com.my/legal/privacy-matters' className="footer-legal-links">
          Privacy Policy
        </a>

        <a href='https://www.celcom.com.my/legal/terms-and-conditions' className="footer-legal-links">
          Terms & Conditions
        </a>
      </div>
      <div className="footer-col-section ">
        Copyright &copy; 1991 -2015 Celcom Axiata Berhad [Company No. 167469-A]. All Rights Reserved
      </div>
      <div className="footer-col-section">
        <Link to="/dashboard" >
          <img src={OtherImages.CelcomLogo} alt="" />
        </Link>
      </div>
    </div>
  )
}
