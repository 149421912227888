import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../Components/Footer/Footer";
import { Header } from "../Components/Header/Header";
import SupervisorSidebar from "../Components/MainBody/Sidebar/SupervisorSidebar";

import { ToastContainer, toast } from "react-toastify";
import "../Assets/CSS/WorkOrderHeader.css";
import "../Assets/CSS/WorkOrderAdvanceSearchModal.css";

import SearchActivityBodyHeader from "../Components/MainBody/BodyHeader/Supervisor/SearchActivityBodyHeader";
import WorkOrderAdvanceSearchModal from "../Components/Modals/WorkOrderAdvanceSearchModal";
import SearchActivityBodyContent from "../Components/MainBody/BodyContent/Supervisor/SearchActivityBodyContent";
import {
  DirectLovCalls,
  headers,
  ReportExtraction,
  WorkOrderPage,
} from "../Api/ApiCalls";
import {
  resetWorkOrderListData,
  setSearchWorkOrderListData,
  workOrderSearchPageState,
} from "../ReduxFeature/StateFeatures/WorkOrderDataState";
import { ApiConstant } from "../Assets/Constants/ApiConstants";
import moment from "moment";
import { Constent } from "../Assets/Constants/Constent";
import { WorkOrderAdvanceSearchRequestType } from "../Components/Common/GenericFunctions";
import ViewWorkorderReportBodyHearder from "../Components/MainBody/BodyHeader/ViewWorkorderReportBodyHearder";
import ViewWorkorderReportBodyContent from "../Components/MainBody/BodyContent/ViewWorkorderReportBodyContent";
import { ViewWorkOrderReportAdvSearchModal } from "../Components/Modals/ViewWorkOrderReportAdvSearchModal";
import { saveLovs } from "../ReduxFeature/StateFeatures/LovDataState";

const WorkOrderViewReportPage = ({ globalState, setGlobalState }) => {
  const userDataObj = useSelector((state) => state.userDataInfo);
  const lovDataObj = useSelector((state) => state.lovDataInfo);

  const workOrderDataObj = useSelector((state) => state.workOrderInfo);
  const {
    workOrderId,
    workOrderFromDate,
    workOrderToDate,
    formCalendarNav,
    searchType,
  } = workOrderDataObj;
  const dispatch = useDispatch();

  const [pageState, setPageState] = useState({
    totalItems: workOrderDataObj?.workOrderList?.length,
    workOrderList: workOrderDataObj?.workOrderList,
    bodyview: "Day",
    toggleIcon: "List",
    workOrderId: "",
    workOrderFromDate: "",
    workOrderToDate: "",
    workOrderReportFromDate: "",
    workOrderReportToDate: "",
    // Advance Search Modal
    activePill: Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM1,
    advanceSearchLovs: ApiConstant.WORK_ORDER_ADVANCE_SEARCH_LOV1,
    advanceSearchSubLovs: [],
    selectedAdvanceSearchType: "",
    selectedAdvanceSearchTypeReport: "",
    selectedAdvanceSearchSubTypeList: [],
    selectedAdvanceSearchAppointmentType: "",
    advanceSearchAppointmentTypeLovs:
      ApiConstant.WORK_ORDER_ADVANCE_SEARCH_APPOINTMENT_TYPE_LOV,
    selectedAppointmenttype: "",
    selectedSuperAdminMonitoring: false,
    dataLoader: false,
    workOrderListData: [],
    // Error Part
    getDataError: false,
    getDataErrorMsg: "",
  });

  const [supervisorListWithUserGroup, setSupervisorListWithUserGroup] =
    useState({});

  function searchWorkOrderListApiCall() {
    dispatch(resetWorkOrderListData());

    setPageState({
      ...pageState,
      dataLoader: true,
      totalItems: 0,
      workOrderList: [],
    });
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);

    const workOrderListRequest = {
      fromDate:
        (pageState?.workOrderFromDate || moment().format("YYYY-MM-DD")) +
        " 00:00",
      toDate:
        (pageState?.workOrderToDate || moment().format("YYYY-MM-DD")) +
        " 23:59",
      requestType: WorkOrderAdvanceSearchRequestType({ value: searchType }),
      activityType:
        pageState?.selectedAdvanceSearchType?.length > 0
          ? pageState?.selectedAdvanceSearchType
          : null,
      activitySubType:
        pageState?.selectedAdvanceSearchSubTypeList?.length > 0
          ? pageState?.selectedAdvanceSearchSubTypeList
          : null,
      itemSubType: null,
      stsType: null,
      activityId:
        searchType === Constent.WORK_ORDER_INPUT_ITEM_LABEL1 &&
        pageState?.workOrderId?.length > 0
          ? "FieldActivity-" + pageState?.workOrderId
          : null,
      orderNumber:
        searchType === Constent.TRACK_ORDER_INPUT_ITEM_LABEL1 &&
        pageState?.workOrderId?.length > 0
          ? pageState?.workOrderId
          : null,
      customerId:
        searchType === Constent.WORK_ORDER_INPUT_ITEM_LABEL2 &&
        pageState?.workOrderId?.length > 0
          ? pageState?.workOrderId
          : null,
      userId:
        pageState?.workOrderId?.length > 0 ||
        pageState?.selectedSuperAdminMonitoring === true
          ? "SuperAdminMonitoring"
          : userDataObj?.userData?.activeUserData?.userData?.userId,
      // Infinite scroll is pending
      limit: 500,
      offSet: 0,
    };

    WorkOrderPage(
      "/fibre/activity/supervisorlist",
      workOrderListRequest,
      header
    )
      .then((response) => {
        dispatch(setSearchWorkOrderListData(response?.workQueueVo));
        setPageState({
          ...pageState,
          dataLoader: false,
          getDataError: false,
          workOrderList: response?.workQueueVo,
          totalItems: response?.workQueueVo?.length,
          getDataErrorMsg: "",
        });
      })
      .catch((error) => {
        dispatch(resetWorkOrderListData());
        let errorMessage;
        if (error.response.status == 500 || error.response.status == 503) {
          errorMessage = ApiConstant.WORK_ORDER_LIST_ERROR_MESSAGE;
          toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        } else if (error.response.status == 404) {
          errorMessage = ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE;
          toast.warning(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        } else {
          errorMessage = error.response.statusText;
        }

        setPageState({
          ...pageState,
          getDataError: true,
          dataLoader: false,
          totalItems: 0,
          workOrderList: [],
          getDataErrorMsg: errorMessage,
        });
      });
  }

  function requestValidationCheck() {
    if (
      pageState?.workOrderReportFromDate?.length === 0 ||
      pageState?.workOrderReportToDate?.length === 0 ||
      moment(pageState?.workOrderReportFromDate) <=
        moment(moment(pageState?.workOrderReportToDate))
    ) {
      setPageState({
        ...pageState,
        formDateError: "",
        toDateError: "",
      });
      return true;
    } else {
      setPageState({
        ...pageState,
        formDateError: "Invalid Input",
        toDateError: "Invalid Input",
      });
      return false;
    }
  }

  function reportExtractionAPICall() {
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);

    const searchType = Constent.WORK_ORDER_INPUT_ITEM_LABEL1;

    const request = {
      workOrderId:
        searchType === Constent.WORK_ORDER_INPUT_ITEM_LABEL1 &&
        pageState?.workOrderId?.length > 0
          ? "FieldActivity-" + pageState?.workOrderId
          : null,
      orderNo:
        searchType === Constent.TRACK_ORDER_INPUT_ITEM_LABEL1 &&
        pageState?.workOrderId?.length > 0
          ? pageState?.workOrderId
          : null,
      customerId:
        searchType === Constent.WORK_ORDER_INPUT_ITEM_LABEL2 &&
        pageState?.workOrderId?.length > 0
          ? pageState?.workOrderId
          : null,
      fromDate:
        (pageState?.workOrderReportFromDate ||
          moment().subtract(1, "d").format("YYYY-MM-DD")) + " 00:00:00",

      toDate:
        (pageState?.workOrderReportToDate ||
          moment().subtract(1, "d").format("YYYY-MM-DD")) + " 23:59:59",
      accessProviderDetail:
        userDataObj?.userData?.activeUserData?.userRole === Constent.ADMIN_ROLE
          ? pageState?.accessProviderValue?.length > 0
            ? pageState?.accessProviderValue
            : null
          : null,
      appointmentType:
        pageState?.selectedAdvanceSearchTypeReport?.length > 0
          ? pageState?.selectedAdvanceSearchTypeReport
          : null,
      userId: userDataObj?.userData?.activeUserData?.userData?.userId,
      companyName: userDataObj?.userData?.activeUserData?.userData?.outletName,
      userRole: userDataObj?.userData?.activeUserData?.userRole,
    };

    if (requestValidationCheck()) {
      setPageState({
        ...pageState,
        dataLoader: true,
        workOrderListData: [],
        formDateError: "",
        toDateError: "",
      });

      ReportExtraction(
        "/fibre/reportExtraction/reportExtractionDetails",
        request,
        header
      )
        .then((response) => {
          if (response?.length == 0) {
            toast.warning("No Records Found", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 10000,
              theme: "colored",
            });
          }
          setPageState({
            ...pageState,
            getDataError: false,
            dataLoader: false,
            workOrderListData: response,
          });
        })
        .catch((error) => {
          let errorMessage;
          if (error.response.status == 500 || error.response.status == 503) {
            errorMessage = ApiConstant.WORK_ORDER_LIST_ERROR_MESSAGE;
            toast.error(errorMessage, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 10000,
              theme: "colored",
            });
          } else if (error.response.status == 404) {
            errorMessage = ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE;
            toast.warning(errorMessage, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 10000,
              theme: "colored",
            });
          } else {
            errorMessage = error.response.statusText;
          }

          setPageState({
            ...pageState,
            getDataError: true,
            dataLoader: false,
            totalItems: 0,
            workOrderListData: [],
            getDataErrorMsg: errorMessage,
          });
        });
    } else {
      setPageState({
        ...pageState,
        dataLoader: false,
        formDateError: "Invalid Input",
        toDateError: "Invalid Input",
      });
    }
  }

  useEffect(() => {
    if (!formCalendarNav) {
      searchWorkOrderListApiCall();
    } else {
      dispatch(
        workOrderSearchPageState({
          type: ApiConstant.CALENDAR_TO_WORK_ORDER_NAV,
          value: false,
        })
      );
    }
    if (
      userDataObj?.userData?.activeUserData?.userRole === Constent.ADMIN_ROLE
    ) {
      if (
        lovDataObj?.accessProviderLOV === null ||
        lovDataObj?.accessProviderLOV === undefined ||
        lovDataObj?.accessProviderLOV?.length === 0
      ) {
        const header = headers(
          userDataObj?.userData?.activeUserData?.authToken
        );
        DirectLovCalls(
          "/fibre/getlovs/getLovValues?lovKey=LOV_ACCESS_PROVIDER",
          header
        ).then((response) => {
          dispatch(
            saveLovs({
              key: ApiConstant.LOV_STORE_ACCESS_PROVIDERS_KEY,
              value: response,
            })
          );
        });
      }
    }
  }, []);

  useEffect(() => {
    requestValidationCheck();
  }, [pageState?.workOrderReportFromDate, pageState?.workOrderReportToDate]);

  // Filter List Section
  const filterList = pageState?.workOrderList?.filter((item) => {
    if (
      pageState?.workOrderId === "" ||
      item?.itemId
        ?.toLowerCase()
        ?.includes(pageState?.workOrderId?.toLowerCase()) ||
      item?.orderId
        ?.toLowerCase()
        ?.includes(pageState?.workOrderId?.toLowerCase()) ||
      item?.customerId
        ?.toLowerCase()
        ?.includes(pageState?.workOrderId?.toLowerCase())
    ) {
      return item;
    }
  });

  return (
    <div className="page-start-section">
      <Header
        userDataObj={userDataObj}
        globalState={globalState}
        setGlobalState={setGlobalState}
        tab1={true}
      />
      <div
        className={
          globalState?.sidebarState
            ? "body-start-section sidebar-active-section"
            : "body-start-section"
        }
      >
        {/* Sidebar */}
        <SupervisorSidebar
          userDataObj={userDataObj}
          globalState={globalState}
          setGlobalState={setGlobalState}
        />
        <div className="body-full-content-section">
          <SearchActivityBodyHeader
            userDataObj={userDataObj}
            globalState={globalState}
            setGlobalState={setGlobalState}
            searchWorkOrderListApiCall={searchWorkOrderListApiCall}
            pageState={pageState}
            setPageState={setPageState}
            workOrderDataObj={workOrderDataObj}
            filterList={filterList}
            reportExtractionAPICall={reportExtractionAPICall}
          />
          {pageState?.activePill ===
          Constent.BODY_HEADER_WORKORDERREPORT_PILL_ITEM1 ? (
            <SearchActivityBodyContent
              userDataObj={userDataObj}
              globalState={globalState}
              setGlobalState={setGlobalState}
              pageState={pageState}
              setPageState={setPageState}
              workOrderDataObj={workOrderDataObj}
              filterList={filterList}
            />
          ) : (
            <ViewWorkorderReportBodyContent
              pageState={pageState}
              setPageState={setPageState}
            />
          )}
        </div>
        <Footer />
      </div>
      <WorkOrderAdvanceSearchModal
        pageState={pageState}
        setPageState={setPageState}
        searchWorkOrderListApiCall={searchWorkOrderListApiCall}
        workOrderDataObj={workOrderDataObj}
      />
      <ViewWorkOrderReportAdvSearchModal
        pageState={pageState}
        setPageState={setPageState}
        reportExtractionAPICall={reportExtractionAPICall}
        workOrderDataObj={workOrderDataObj}
        lovDataObj={lovDataObj}
        userDataObj={userDataObj}
        supervisorListWithUserGroup={supervisorListWithUserGroup}
      />
      <ToastContainer />
    </div>
  );
};

export default WorkOrderViewReportPage;
