import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Constent } from "../../Assets/Constants/Constent";
import { HeaderNavChild2 } from "../../Components/Common/HeaderNav";
import Footer from "../../Components/Footer/Footer";
import { Header } from "../../Components/Header/Header";
import InstallerWorkOrderDetailsBodyContent from "../../Components/MainBody/BodyContent/Installer/InstallerWorkOrderDetailsBodyContent";
import WorkOrderDetailsTimelineModal from "../../Components/Modals/WorkOrderDetailsTimelineModal";
import { ToastContainer, toast } from "react-toastify";
import {
  resetWorkOrderDetailsData,
  setSearchWorkOrderDetailsData,
  updateWorkOrderDetailsData,
  setInstallerNotesData,
  setUploadedDocumentsList,
} from "../../ReduxFeature/StateFeatures/WorkOrderDataState";
import {
  DirectLovCalls,
  headers,
  InstallerNotes,
  WorkOrderDetails,
  InstallerUploadedDocuments,
} from "../../Api/ApiCalls";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import WorkOrderDetailsDeviceListModal from "../../Components/Modals/WorkOrderDetailsDeviceListModal";
import WorkOrderReturnedModal from "../../Components/Modals/WorkOrderReturnedModal";

const InstallerWorkOrderDetailsPage = ({ globalState, setGlobalState }) => {
  const userDataObj = useSelector((state) => state.userDataInfo);

  const workOrderObj = useSelector((state) => state.workOrderInfo);
  const { selectedWorkOrderItem, updateActivityBtn, workOrderDetails } =
    workOrderObj;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pageState, setPageState] = useState({
    dataLoader: false,
    // Error Part
    getDataError: false,
    getDataErrorMsg: "",
  });

  function ErrorMessageSetUp({ error, message1, message2 }) {
    let errorMessage;
    if (error?.response?.status === 500 || error?.response?.status === 503) {
      errorMessage = message1;
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    } else if (error.response.status === 404) {
      errorMessage = message2;
      toast.warning(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    } else {
      errorMessage = error.response.statusText;
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    }
    return errorMessage;
  }

  function workOrderDetailsApiCall() {
    dispatch(resetWorkOrderDetailsData());

    setPageState({ ...pageState, dataLoader: true });
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);
    const workOrderDetailsRequest = {
      activityId: selectedWorkOrderItem?.itemId,
      userId: userDataObj?.userData?.activeUserData?.userData?.userId,
      outletId: userDataObj?.userData?.activeUserData?.userData?.outletId,
    };

    WorkOrderDetails("/fibre/activity/details", workOrderDetailsRequest, header)
      .then((response) => {
        dispatch(setSearchWorkOrderDetailsData(response));

        getInstallerNotes(header, response?.orderId);
        const { installationDetail, ownOrderDetails } = response;

        if (
          ownOrderDetails !== null &&
          ownOrderDetails?.deviceDetails !== null
        ) {
          if (response?.itemTypeID === ApiConstant.WOKR_ORDER_OSI_TYPE) {
            const { rgwDeviceList, btuDeviceList, meshDeviceList } =
              ownOrderDetails?.deviceDetails;

            const deviceSlValidation = {};
            const devicesWithExistingSerialNo = [];
            // Concat - Device List - to create the Device Sl validation
            rgwDeviceList?.concat(meshDeviceList)?.map((deviceItem) => {
              deviceSlValidation[deviceItem?.productID] = {
                serialnumber: "",
                valid: "",
              };
            });
//In case of On-Site Installation, Serial number to get autopopulated(if present in response) in the Update Work Order page.
            if (
              response?.itemSubTypeId
                ?.toLowerCase()
                ?.includes("Installation".toLowerCase())
            ) {
              rgwDeviceList?.concat(meshDeviceList)?.map((deviceItem) => {
                if (
                  deviceItem?.deviceSerialNo != null ||
                  deviceItem?.deviceSerialNo?.length > 0
                ) {
                  deviceSlValidation[deviceItem?.productID] = {
                    serialnumber: deviceItem?.deviceSerialNo || "",
                    valid: ApiConstant.VALID_INP,
                  };
                  devicesWithExistingSerialNo.push(deviceItem?.productID);
                }
              });
            }

            dispatch(
              updateWorkOrderDetailsData({
                type: ApiConstant.INSTALLER_SL_VALIDATION,
                value: deviceSlValidation,
              })
            );
            dispatch(
              updateWorkOrderDetailsData({
                type: ApiConstant.INSTALLER_SL_EXISTING_SERIAL_NUMBER,
                value: devicesWithExistingSerialNo,
              })
            );
          } else if (response?.itemTypeID === ApiConstant.WOKR_ORDER_OST_TYPE) {
            dispatch(
              updateWorkOrderDetailsData({
                type: ApiConstant.INSTALLER_SL_VALIDATION,
                value: {},
              })
            );
          }

          /*------ 
                    Active Tab = ApiConstant.INSTALLER_WORK_ORDER_ACTIVE_TAB, 
                    Device Sl validation Requried,  
                    Set Location Details
                 ------*/
          if (
            installationDetail?.installerArrivalDate?.length > 0 &&
            installationDetail?.location?.length > 0 &&
            installationDetail?.installationCompleteDate?.length > 0
          ) {
            // Step - 2
            dispatch(
              updateWorkOrderDetailsData({
                type: ApiConstant.INSTALLER_WORK_ORDER_ACTIVE_TAB,
                value: 2,
              })
            );
            dispatch(
              updateWorkOrderDetailsData({
                type: ApiConstant.INSTALLER_ENABLED_LOCATION_BTN,
                value: {
                  lattitude: installationDetail?.latitude || "-",
                  longitude: installationDetail?.longitude || "-",
                  streetName: installationDetail?.streetName || "-",
                  time: installationDetail?.installerArrivalDate || "-",
                },
              })
            );
            dispatch(
              updateWorkOrderDetailsData({
                type: ApiConstant.INSTALLER_COMPLETE_TIME,
                value: installationDetail?.installationCompleteDate || "-",
              })
            );
          } else if (
            installationDetail?.installerArrivalDate?.length > 0 &&
            installationDetail?.location?.length > 0
          ) {
            // Step - 1
            dispatch(
              updateWorkOrderDetailsData({
                type: ApiConstant.INSTALLER_WORK_ORDER_ACTIVE_TAB,
                value: 1,
              })
            );
            dispatch(
              updateWorkOrderDetailsData({
                type: ApiConstant.INSTALLER_ENABLED_LOCATION_BTN,
                value: {
                  lattitude: installationDetail?.latitude || "-",
                  longitude: installationDetail?.longitude || "-",
                  streetName: installationDetail?.streetName || "-",
                  time: installationDetail?.installerArrivalDate || "-",
                },
              })
            );
          } else {
            // Step - 1
            dispatch(
              updateWorkOrderDetailsData({
                type: ApiConstant.INSTALLER_WORK_ORDER_ACTIVE_TAB,
                value: 1,
              })
            );
          }
          dispatch(
            updateWorkOrderDetailsData({
              type: ApiConstant.INSTALLER_WORK_ORDER_UPDATE_ACTIVE,
              value: true,
            })
          );
        } else {
          dispatch(
            updateWorkOrderDetailsData({
              type: ApiConstant.INSTALLER_WORK_ORDER_UPDATE_ACTIVE,
              value: false,
            })
          );
          toast.warning(
            ApiConstant.INSTALLER_ACTIVITY_DETAILS_NO_DEVICE_DETAILS_WARNING_MESSAGE,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 20000,
              theme: "colored",
            }
          );
        }

        setPageState({
          ...pageState,
          dataLoader: false,
          getDataError: false,
          getDataErrorMsg: "",
        });
      })
      .catch((error) => {
        dispatch(resetWorkOrderDetailsData());

        const errorMessage = ErrorMessageSetUp({
          error: error,
          message1: ApiConstant.WORK_ORDER_LIST_ERROR_MESSAGE,
          message2: ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE,
        });

        setPageState({
          ...pageState,
          dataLoader: false,
          getDataError: true,
          getDataErrorMsg: errorMessage,
        });
      });
  }

  function getInstallerNotes(header, orderId) {
    const installerNotesRequest = {
      orderId: orderId,
    };
    InstallerNotes("/fibre/activity/getnotes", installerNotesRequest, header)
      .then((response) => {
        dispatch(setInstallerNotesData(response));
        // setPageState({
        //     ...pageState,
        //     installerNotes: response?.orderNotesList

        // });
      })
      .catch((error) => {
        dispatch(setInstallerNotesData([]));
      });
  }

  function getUploadedDocumentsList(header, orderId) {
    const uploadedDocumentsRequest = {
      orderId: selectedWorkOrderItem?.itemId,
    };

    InstallerUploadedDocuments(
      "/fibre/file/sftpFileList/",
      uploadedDocumentsRequest,
      header
    )
      .then((response) => {
        dispatch(
          setUploadedDocumentsList({
            type: ApiConstant.WORK_ORDER_UPLOADED_DOCUMENT_LIST,
            value: response,
          })
        );
      })
      .catch(() => {
        // TODO document why this arrow function is empty
        dispatch(
          setUploadedDocumentsList({
            type: ApiConstant.WORK_ORDER_UPLOADED_DOCUMENT_LIST,
            value: [],
          })
        );
      });
  }

  useEffect(() => {
    dispatch(
      updateWorkOrderDetailsData({
        type: ApiConstant.INSTALLER_WORK_ORDER_OST_ORDER_ID,
        value: "",
      })
    );
    dispatch(
      updateWorkOrderDetailsData({
        type: ApiConstant.INSTALLER_WORK_ORDER_STATUS,
        value: "",
      })
    );
    dispatch(
      updateWorkOrderDetailsData({
        type: ApiConstant.INSTALLER_BTU_DEVICE_COLLECTED_INPUTVALUE,
        value: "",
      })
    );
    if (selectedWorkOrderItem?.itemId === null) {
      navigate("/workorder");
    } else {
      workOrderDetailsApiCall();
    }
  }, []);

  return (
    <div className="page-start-section">
      <Header
        userDataObj={userDataObj}
        globalState={globalState}
        setGlobalState={setGlobalState}
        installerTabFlag={true}
      />
      <div className="body-start-section">
        <div className="body-full-content-section">
          <HeaderNavChild2
            activeItem={Constent.BODY_HEADER_WORKORDER_DETAILS_PATH}
            backTab={"/workorder"}
          />
          <InstallerWorkOrderDetailsBodyContent
            userDataObj={userDataObj}
            globalState={globalState}
            setGlobalState={setGlobalState}
            updateActivityBtn={updateActivityBtn}
            pageState={pageState}
            setPageState={setPageState}
            selectedWorkOrder={workOrderObj?.selectedWorkOrderItem}
            workOrderDetailsObj={workOrderObj?.workOrderDetails}
          />
        </div>
        <Footer />
      </div>
      <WorkOrderDetailsDeviceListModal
        ownOrderDetails={workOrderDetails?.ownOrderDetails}
      />
      <WorkOrderDetailsTimelineModal
        workOrderDetailsObj={workOrderObj?.workOrderDetails}
      />
      <WorkOrderReturnedModal
        returnWorkOrderList={
          workOrderDetails?.relatedWorkOrderDetails?.relatedWorkOrder || []
        }
      />
      <ToastContainer />
    </div>
  );
};

export default InstallerWorkOrderDetailsPage;
