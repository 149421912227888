import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { headers } from "../../../../../Api/ApiCalls";
import { ApiConstant } from "../../../../../Assets/Constants/ApiConstants";
import { Constent } from "../../../../../Assets/Constants/Constent";
import { TickMarkIcon } from "../../../../../Assets/Images/CelcomSvgImages";
import { updateWorkOrderDetailsData } from "../../../../../ReduxFeature/StateFeatures/WorkOrderDataState";
import { EmptyValueHandler } from "../../../../Common/GenericFunctions";
import { toast } from "react-toastify";

const OSTAccordionBodyContentDeviceItem = ({
  deviceData,
  lovDataObj,
  deviceSLValidation,
  SerialNumberValidation,
  userDataObj,
  ostChekedDeviceType,
  productDetails,
  catagory,
  btuDevice,
  deviceBrand,
  setDeviceBrand,
}) => {
  const { deviceelements } = lovDataObj;
  const dispatch = useDispatch();
  const [checkbox, setCheckBox] = useState(
    Object?.keys(deviceSLValidation)?.length > 0
      ? Object?.keys(deviceSLValidation)?.findIndex(
          (obj) => obj === deviceData?.productID
        ) !== -1
      : false
  );

  function deviceVal(e) {
    setDeviceBrand(e?.target.value)
  }
  const [deviceItemState, setDeviceItemStatus] = useState({
    resolutionLovs: [],
    problemCauseLovs: [],
  });
  useEffect(() => {
    const resolutionIndex = deviceelements?.findIndex(
      (obj) => obj?.lovValue === productDetails?.elementValue
    );

    if (resolutionIndex !== undefined && resolutionIndex !== -1) {
      const problemCauseIndex = deviceelements[
        resolutionIndex
      ]?.resolution?.findIndex(
        (obj) => obj?.lovValue === productDetails?.resolutionValue
      );
      const resolutionLovList =
        deviceelements[resolutionIndex]?.resolution?.length > 0
          ? [...deviceelements[resolutionIndex]?.resolution]
          : [];

      resolutionLovList?.sort((a, b) =>
        parseInt(a.seqNo) > parseInt(b.seqNo) ? 1 : -1
      );
      if (problemCauseIndex !== undefined && problemCauseIndex !== -1) {
        const problemCauseLovList =
          deviceelements[resolutionIndex]?.resolution[problemCauseIndex]
            ?.problemcause?.length > 0
            ? [
                ...deviceelements[resolutionIndex]?.resolution[
                  problemCauseIndex
                ]?.problemcause,
              ]
            : [];

        problemCauseLovList?.sort((a, b) =>
          parseInt(a.seqNo) > parseInt(b.seqNo) ? 1 : -1
        );
        setDeviceItemStatus({
          ...deviceItemState,
          resolutionLovs: resolutionLovList,
          problemCauseLovs: problemCauseLovList,
        });
      } else {
        setDeviceItemStatus({
          ...deviceItemState,
          resolutionLovs: resolutionLovList,
        });
      }
    }
    let flag = false;
    Object.keys(deviceSLValidation).map((productId) => {
      if (deviceSLValidation[productId]?.isSwapDeviceWithModelChange === true) {
        flag = true;
      }
    });
    if (flag) {
      dispatch(
        updateWorkOrderDetailsData({
          type: ApiConstant.INSTALLER_WORK_ORDER_MODEL_CHANGE_IN_SWAP_DEVICES,
          value: true,
        })
      );
    } else {
      dispatch(
        updateWorkOrderDetailsData({
          type: ApiConstant.INSTALLER_WORK_ORDER_MODEL_CHANGE_IN_SWAP_DEVICES,
          value: false,
        })
      );
    }
  }, [deviceSLValidation]);

  return (
    <div className="custom-card-body-item-group-section">
      <div className="ost-device-item-group-section">
        <div className="ost-device-item-section">
          <div className="empty-section"></div>
          <div className="custom-control custom-checkbox">
            <input
              className="custom-control-input"
              type="checkbox"
              checked={checkbox}
              id={`${deviceData?.productID}`}
              onChange={(e) => {
                // if (ostChekedDeviceType.length === 0 || ostChekedDeviceType === ""
                //     || ostChekedDeviceType === deviceData?.deviceType) {

                const { checked } = e.target;
                let validationSelect = true;

                if (Object?.keys(deviceSLValidation)?.length > 0) {
                  Object.keys(deviceSLValidation)?.map((data) => {
                    if (
                      deviceSLValidation[data]?.deviceStatus !==
                      deviceData?.productStatus
                    ) {
                      validationSelect = false;
                    }
                  });
                }

                // dispatch(updateWorkOrderDetailsData({
                //     type: ApiConstant.INSTALLER_WORK_ORDER_OST_CHECKED_DEVICE_TYPE,
                //     value: checked ?
                //         (ostChekedDeviceType.length > 0 ? ostChekedDeviceType : deviceData?.deviceType)
                //         :
                //         Object?.keys(deviceSLValidation)?.length === 1 ? "" : ostChekedDeviceType
                // }))

                if (validationSelect) {
                  if (checked) {
                    dispatch(
                      updateWorkOrderDetailsData({
                        type: ApiConstant.INSTALLER_SL_VALIDATION,
                        value: {
                          ...deviceSLValidation,
                          [deviceData?.productID]: {
                            serialnumber: "",
                            valid: "",
                            elementValue: "",
                            resolutionValue: "",
                            problemCauseValue: "",
                            deviceStatus: deviceData?.productStatus,
                            isSwapDeviceWithModelChange: false,
                            newDeviceBrand: "",
                            newDeviceModel: "",
                            newMaterialCode: "",
                          },
                        },
                      })
                    );
                  } else {
                    const temp = { ...deviceSLValidation };

                    delete temp[deviceData?.productID];
                    dispatch(
                      updateWorkOrderDetailsData({
                        type: ApiConstant.INSTALLER_SL_VALIDATION,
                        value: temp,
                      })
                    );
                  }
                  setCheckBox(e.target.checked);
                } else {
                  toast.warning(
                    ApiConstant.INSTALLER_INST_COMPLETE_ERROR_MESSAGE,
                    {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      autoClose: 10000,
                      theme: "colored",
                    }
                  );
                }
                // } else {
                //     toast.warning(ApiConstant.OST_DEVICE_SELECTION_WARNING_MESSAGE,
                //         {
                //             position: toast.POSITION.BOTTOM_RIGHT,
                //             autoClose: 10000,
                //             theme: 'colored'
                //         }
                //     );
                // }
              }}
            />
            <label
              className="custom-control-label"
              htmlFor={`${deviceData?.productID}`}
            >
              {Constent.OST_DEVICE_CHECKBOX_LABLE}
            </label>
          </div>
        </div>
        <div className="ost-device-item-section">
          <div className="ost-device-item-info">
            <div className="card-item-group">
              <div className="card-item-title">
                {Constent.WORK_ORDER_OST_DEVICE_ITEM_TITLE3}
              </div>
              <div className="card-item-value">
                {EmptyValueHandler({ item: catagory }) === "BTU"
                  ? EmptyValueHandler({ item: catagory })
                  : EmptyValueHandler({ item: deviceData?.nameToDisplay })}
              </div>
            </div>
            <div className="card-item-group">
              <div className="card-item-title">
                {Constent.WORK_ORDER_OST_DEVICE_ITEM_TITLE1}
              </div>
              <div className="card-item-value">
                {EmptyValueHandler({ item: deviceData?.deviceBrand })}
                {" - "}
                {EmptyValueHandler({ item: deviceData?.deviceModel })}
              </div>
            </div>
            <div className="card-item-group">
              <div className="card-item-title">
                {Constent.WORK_ORDER_OST_DEVICE_ITEM_TITLE2}
              </div>
              <div className="card-item-value">
                {EmptyValueHandler({ item: deviceData?.productStatus })}
              </div>
            </div>
            <div className="card-item-group">
              <div className="card-item-title">
                {Constent.WORK_ORDER_OST_DEVICE_ITEM_TITLE4}
              </div>
              <div className="card-item-value">
                {EmptyValueHandler({ item: deviceData?.deviceSerialNo })}
              </div>
            </div>
            {EmptyValueHandler({ item: catagory }) === "BTU" ?
            <div className="card-item-group">
              <div className="card-item-title">
                {Constent.WORK_ORDER_OST_DEVICE_ITEM_TITLE5}
              </div>
            </div>
            : null }
          </div>
          <div className="ost-device-item">
            <div
              className={`form-group mb-0 custom-input-form-group custom-normal-input-form-group ${
                productDetails?.valid === ApiConstant.VALID_INP
                  ? "custom-valid-form-group"
                  : productDetails?.valid === ApiConstant.INVALID_INP
                  ? "custom-invalid-form-group"
                  : ""
              }`}
            >
              {/* custom-valid-form-group --- custom-invalid-form-group*/}
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={productDetails?.serialnumber || ""}
                  disabled={!checkbox}
                  onChange={(e) => {
                    dispatch(
                      updateWorkOrderDetailsData({
                        type: ApiConstant.INSTALLER_SL_VALIDATION_SERIAL_NUMBER,
                        value: {
                          productId: deviceData?.productID,
                          itemValue: e.target.value,
                        },
                      })
                    );
                  }}
                  onBlur={(e) => {
                    if (btuDevice) {
                      const { value } = e.target;
                      if (value?.length === 0 || value === "") {
                        dispatch(
                          updateWorkOrderDetailsData({
                            type: ApiConstant.INSTALLER_SL_VALIDATION_VALID,
                            value: {
                              productId: deviceData?.productID,
                              itemValue: "",
                            },
                          })
                        );
                      } else if (
                        value?.length >= Constent.BTU_SERIAL_NUMBER_MIN
                      ) {
                        dispatch(
                          updateWorkOrderDetailsData({
                            type: ApiConstant.INSTALLER_SL_VALIDATION_VALID,
                            value: {
                              productId: deviceData?.productID,
                              itemValue: ApiConstant.VALID_INP,
                            },
                          })
                        );
                      } else {
                        dispatch(
                          updateWorkOrderDetailsData({
                            type: ApiConstant.INSTALLER_SL_VALIDATION_VALID,
                            value: {
                              productId: deviceData?.productID,
                              itemValue: ApiConstant.INVALID_INP,
                            },
                          })
                        );
                      }
                    } else {
                      SerialNumberValidation({
                        event: e,
                        object: {
                          productId: deviceData?.productID,
                          outletId:
                            userDataObj?.userData?.activeUserData?.userData
                              ?.outletId,
                          userId:
                            userDataObj?.userData?.activeUserData?.userData
                              ?.userId,
                          materialCode: deviceData?.productSpecificationRefID,
                          isValid: "OST",
                          catagory: catagory,
                          validateDeviceSwapRequest: {
                            prevuin: deviceData?.deviceSerialNo,
                            uin: productDetails?.serialnumber,
                          },
                          deviceData: deviceData,
                        },
                        header: headers(
                          userDataObj?.userData?.activeUserData?.authToken
                        ),
                      });
                    }
                  }}
                  id={Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL1}
                  placeholder=" "
                />
                <label htmlFor={Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL1}>
                  {Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL1}
                  <span>{checkbox && "*"}</span>
                </label>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <TickMarkIcon stroke={"#009BDF"} />
                  </span>
                </div>
              </div>
              <span className="invalid-msg-text"></span>
            </div>
            <div className="form-group m-0 custom-drop-down-form-group custom-white-drop-down-form-group">
              <select
                className="custom-select "
                disabled={!checkbox}
                value={productDetails?.elementValue || ""}
                onChange={(e) => {
                  const index = deviceelements?.findIndex(
                    (obj) => obj?.lovDisplay === e.target.value
                  );
                  if (index !== -1) {
                    dispatch(
                      updateWorkOrderDetailsData({
                        type: ApiConstant.INSTALLER_SL_INNER_DROP_DOWN_ELEMENT_VALUE,
                        value: {
                          productId: deviceData?.productID,
                          itemValue: e.target.value,
                        },
                      })
                    );

                    const resolutionLovs =
                      deviceelements[index]?.resolution?.length > 0
                        ? [...deviceelements[index]?.resolution]
                        : [];

                    resolutionLovs?.sort((a, b) =>
                      parseInt(a.seqNo) > parseInt(b.seqNo) ? 1 : -1
                    );

                    setDeviceItemStatus({
                      ...deviceItemState,
                      resolutionLovs: resolutionLovs,
                      problemCauseLovs: [],
                    });
                  }
                  if (e.target.value === "") {
                    dispatch(
                      updateWorkOrderDetailsData({
                        type: ApiConstant.INSTALLER_SL_INNER_DROP_DOWN_ELEMENT_VALUE,
                        value: {
                          productId: deviceData?.productID,
                          itemValue: e.target.value,
                        },
                      })
                    );
                    setDeviceItemStatus({
                      ...deviceItemState,
                      resolutionLovs: [],
                      problemCauseLovs: [],
                    });
                  }
                }}
              >
                <option defaultValue="">--Select--</option>
                <option value="">--Select--</option>
                {deviceelements?.map((elementItem, elementIndex) => {
                  return (
                    <option
                      value={elementItem?.lovDisplay}
                      key={`innerElement${
                        elementItem?.lovDisplay + elementIndex
                      }`}
                    >
                      {elementItem?.lovDisplay}
                    </option>
                  );
                })}
              </select>
              <label
                htmlFor={Constent.UPDATE_WORK_ORDER_ELEMENT_INPUT_ITEM_LABEL}
              >
                {Constent.UPDATE_WORK_ORDER_ELEMENT_INPUT_ITEM_LABEL}
                <span>{checkbox && "*"}</span>
              </label>
            </div>
            <div className="form-group m-0 custom-drop-down-form-group custom-white-drop-down-form-group">
              <select
                className="custom-select "
                disabled={!checkbox}
                value={productDetails?.resolutionValue || ""}
                onChange={(e) => {
                  const index = deviceItemState?.resolutionLovs?.findIndex(
                    (obj) => obj?.lovDisplay === e.target.value
                  );
                  if (index !== -1) {
                    dispatch(
                      updateWorkOrderDetailsData({
                        type: ApiConstant.INSTALLER_SL_INNER_DROP_DOWN_REASON_VALUE,
                        value: {
                          productId: deviceData?.productID,
                          itemValue: e.target.value,
                        },
                      })
                    );
                    const problemCauseLovList =
                      deviceItemState?.resolutionLovs[index]?.problemcause
                        ?.length > 0
                        ? [
                            ...deviceItemState?.resolutionLovs[index]
                              ?.problemcause,
                          ]
                        : [];

                    problemCauseLovList?.sort((a, b) =>
                      parseInt(a.seqNo) > parseInt(b.seqNo) ? 1 : -1
                    );
                    setDeviceItemStatus({
                      ...deviceItemState,
                      problemCauseLovs: problemCauseLovList,
                    });
                  }
                }}
              >
                <option value="">--Select--</option>
                {deviceItemState?.resolutionLovs?.map(
                  (resolutionItem, resolutionIndex) => {
                    return (
                      <option
                        value={resolutionItem?.lovDisplay}
                        key={`innerResolution${
                          resolutionItem?.lovDisplay + resolutionIndex
                        }`}
                      >
                        {resolutionItem?.lovDisplay}
                      </option>
                    );
                  }
                )}
              </select>
              <label
                htmlFor={Constent.UPDATE_WORK_ORDER_RESOLUTION_INPUT_ITEM_LABEL}
              >
                {Constent.UPDATE_WORK_ORDER_RESOLUTION_INPUT_ITEM_LABEL}
                <span>{checkbox && "*"}</span>
              </label>
            </div>
            <div className="form-group m-0 custom-drop-down-form-group custom-white-drop-down-form-group">
              <select
                className="custom-select "
                disabled={!checkbox}
                value={productDetails?.problemCauseValue || ""}
                onChange={(e) => {
                  dispatch(
                    updateWorkOrderDetailsData({
                      type: ApiConstant.INSTALLER_SL_INNER_DROP_DOWN_PROBLEM_CAUSE_VALUE,
                      value: {
                        productId: deviceData?.productID,
                        itemValue: e.target.value,
                      },
                    })
                  );
                }}
              >
                <option value="">--Select--</option>
                {deviceItemState?.problemCauseLovs?.map(
                  (problemCauseItem, problemCauseIndex) => {
                    return (
                      <option
                        value={problemCauseItem?.lovDisplay}
                        key={`innerProblemCause${
                          problemCauseItem?.lovDisplay + problemCauseIndex
                        }`}
                      >
                        {problemCauseItem?.lovDisplay}
                      </option>
                    );
                  }
                )}
              </select>
              <label
                htmlFor={
                  Constent.UPDATE_WORK_ORDER_PROBLEM_CAUSE_INPUT_ITEM_LABEL
                }
              >
                {Constent.UPDATE_WORK_ORDER_PROBLEM_CAUSE_INPUT_ITEM_LABEL}
                <span>{checkbox && "*"}</span>
              </label>
            </div>
             {EmptyValueHandler({ item: catagory }) === "BTU" ?
            <div className="form-group mb-0 custom-input-form-group custom-normal-input-form-group">
            <div className="input-custom-input-form-group">
                  <input
                    type="text"
                    // disabled={!checkbox}
                    className="form-control"
                     value={deviceBrand}
                     disabled = {true}
                     onChange={(e) => {
                      deviceVal(e)
                    }}
                    id={
                      Constent.WORK_ORDER_OST_DEVICE_ITEM_TITLE5
                    }
                    placeholder=" "
                  />
                  <label
                    htmlFor={
                      Constent.WORK_ORDER_OST_DEVICE_ITEM_TITLE5
                    }
                  >
                    {
                      Constent.WORK_ORDER_OST_DEVICE_ITEM_TITLE5
                    }
                    {/* <span>{checkbox && "*"}</span> */}
                  </label>
                  
                <span className="invalid-msg-text">
                  {Constent.INVALID_INPUT}
                </span>
                </div>
                </div>
                : null }
          </div>
        </div>
      </div>
    </div>
  );
};

export default OSTAccordionBodyContentDeviceItem;
