import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApiConstant } from "../../../../../Assets/Constants/ApiConstants";
import { Constent } from "../../../../../Assets/Constants/Constent";
import { updateWorkOrderDetailsData } from "../../../../../ReduxFeature/StateFeatures/WorkOrderDataState";

const OSTActivityDropDownContent = ({ workOrderDataObj }) => {
  const lovDataObj = useSelector((state) => state.lovDataInfo);
  const dispatch = useDispatch();
  const {
    ostElementValue,
    ostResolutionValue,
    ostProblemCauseValue,
    ostResolutionLovs,
    ostProblemCauseLovs,
    deviceSLValidation,
  } = workOrderDataObj;
  const { elements } = lovDataObj;
  const [tickedSwap, setTickedSwap] = useState(
    Object?.keys(deviceSLValidation)?.length > 0
  );

  useEffect(() => {
    if (Object?.keys(deviceSLValidation)?.length > 0) setTickedSwap(true);
    else setTickedSwap(false);
  }, [deviceSLValidation]);

  return (
    <div className="update-work-order-content-inner-item-section">
      <div className="form-group m-0 custom-drop-down-form-group">
        <select
          className="custom-select"
          disabled={tickedSwap}
          value={tickedSwap ? "" : ostElementValue}
          onChange={(e) => {
            let resolutionList = [];
            const index = elements?.findIndex(
              (obj) => obj?.lovValue === e.target.value
            );
            if (index !== -1) {
              resolutionList = elements[index]?.resolution;
            }
            dispatch(
              updateWorkOrderDetailsData({
                type: ApiConstant.INSTALLER_WORK_ORDER_OST_ELEMENT_LOV_VALUE,
                value: { lovValue: e.target.value, data: resolutionList },
              })
            );
          }}
        >
          <option defaultValue="">--Select--</option>
          <option value={tickedSwap}>--Select--</option>
          {elements?.map((data, index) => {
            return (
              <option
                key={`${
                  Constent.UPDATE_WORK_ORDER_ELEMENT_INPUT_ITEM_LABEL + index
                }`}
                value={`${data?.lovValue}`}
              >
                {data?.lovDisplay}
              </option>
            );
          })}
        </select>
        <label htmlFor={Constent.UPDATE_WORK_ORDER_ELEMENT_INPUT_ITEM_LABEL}>
          {Constent.UPDATE_WORK_ORDER_ELEMENT_INPUT_ITEM_LABEL}
          <span>{!tickedSwap && "*"}</span>
        </label>
      </div>
      {ostElementValue?.length > 0 && !tickedSwap && (
        <div className="form-group m-0 custom-drop-down-form-group">
          <select
            className="custom-select"
            value={ostResolutionValue}
            onChange={(e) => {
              let problemCaseList = [];
              const index = ostResolutionLovs?.findIndex(
                (obj) => obj?.lovValue === e.target.value
              );
              if (index !== -1) {
                problemCaseList = ostResolutionLovs[index]?.problemcause;
              }
              dispatch(
                updateWorkOrderDetailsData({
                  type: ApiConstant.INSTALLER_WORK_ORDER_OST_RESOLUTION_LOV_VALUE,
                  value: {
                    lovValue: e.target.value,
                    data: problemCaseList,
                  },
                })
              );
            }}
          >
            <option value="">--Select--</option>
            {ostResolutionLovs?.map((data, index) => {
              return (
                <option
                  key={`${
                    Constent.UPDATE_WORK_ORDER_RESOLUTION_INPUT_ITEM_LABEL +
                    index
                  }`}
                  value={`${data?.lovValue}`}
                >
                  {data?.lovDisplay}
                </option>
              );
            })}
          </select>
          <label
            htmlFor={Constent.UPDATE_WORK_ORDER_RESOLUTION_INPUT_ITEM_LABEL}
          >
            {Constent.UPDATE_WORK_ORDER_RESOLUTION_INPUT_ITEM_LABEL}
            <span>{!tickedSwap && "*"}</span>
          </label>
        </div>
      )}
      {ostResolutionValue?.length > 0 && !tickedSwap && (
        <div className="form-group m-0 custom-drop-down-form-group">
          <select
            className="custom-select"
            value={ostProblemCauseValue}
            onChange={(e) => {
              dispatch(
                updateWorkOrderDetailsData({
                  type: ApiConstant.INSTALLER_WORK_ORDER_OST_PROBLEM_CASE_LOV_VALUE,
                  value: e.target.value,
                })
              );
            }}
          >
            <option value="">--Select--</option>
            {ostProblemCauseLovs?.map((data, index) => {
              return (
                <option
                  key={`${
                    Constent.UPDATE_WORK_ORDER_PROBLEM_CAUSE_INPUT_ITEM_LABEL +
                    index
                  }`}
                  value={`${data?.lovValue}`}
                >
                  {data?.lovDisplay}
                </option>
              );
            })}
          </select>
          <label
            htmlFor={Constent.UPDATE_WORK_ORDER_PROBLEM_CAUSE_INPUT_ITEM_LABEL}
          >
            {Constent.UPDATE_WORK_ORDER_PROBLEM_CAUSE_INPUT_ITEM_LABEL}
            <span>{!tickedSwap && "*"}</span>
          </label>
        </div>
      )}
    </div>
  );
};

export default OSTActivityDropDownContent;
