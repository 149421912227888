import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { headers, TrackStockDetails } from "../../Api/ApiCalls";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { Header } from "../../Components/Header/Header";
import TrackStockRequestDetailsBodyContent from "../../Components/MainBody/BodyContent/Supervisor/TrackStockRequestDetailsBodyContent";
import TrackStockRequestDetailsBodyHeader from "../../Components/MainBody/BodyHeader/Supervisor/TrackStockRequestDetailsBodyHeader";
import SupervisorSidebar from "../../Components/MainBody/Sidebar/SupervisorSidebar";
import {
  setTrackStockFieldValue,
  setTransferStockSerialNumberData,
} from "../../ReduxFeature/StateFeatures/InventoryDataState";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../../Components/Footer/Footer";
import TransferAddSerialNumberModal from "../../Components/Modals/TransferAddSerialNumberModal";
import TransferStockOrderPlaceSerialNo from "../../Components/Modals/TransferStockOrderPlaceSerialNo";

const TrackStockRequestDetailsPage = ({
  globalState,
  setGlobalState,
  invDataObj,
}) => {
  const userDataObj = useSelector((state) => state.userDataInfo);
  const inventoryDataObj = useSelector((state) => state.inventoryDataInfo);

  const dispatch = useDispatch();
  const {
    selectedTrackStockItem,
    selectedTrackStockItemDetails,
    selectedTransferStockList,
    transferSerialNumberObj,
    storeTransferValue,
    requestorOutletName,
    destinationOutletContact,
    destinationOutletName,
    requestorOutletContactNo,
    destinationOutletAdd,
    requestorOutletAdd,
  } = inventoryDataObj;

  const [pageState, setPageState] = useState({
    dataLoader: false,
    selectedTrackStockItemDetails: null,
    clickedSerialNumberItem: {},
    // Error Part
    getDataError: false,
    getDataErrorMsg: "",
    seletedSerialList: [],
  });

  const [toggleStatus, setToggleStatus] = useState(false);

  function ErrorMessageSetUp({ error, message1, message2 }) {
    let errorMessage;
    if (error.response.status === 500 || error.response.status === 503) {
      errorMessage = message1;
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    } else if (error.response.status === 404) {
      errorMessage = message2;
      toast.warning(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    } else {
      errorMessage = error.response.statusText;
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    }
    return errorMessage;
  }
  function TracKStockDetailsApiCall() {
    setPageState({ ...pageState, dataLoader: true });
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);

    const trackStockDetailsRequest = {
      transferId: selectedTrackStockItem?.transferId,
    };
    TrackStockDetails(
      "/fibre/inventory/getTransferDetails",
      trackStockDetailsRequest,
      header
    )
      .then((response) => {
        let totalItems = 0;
        let tData = {};
        response?.itemDetails?.map((item) => {
          if (selectedTrackStockItem?.requestType === "Transfer Stock") {
            tData[item?.sapMaterialCode] = [];
          }
          dispatch(
            setTrackStockFieldValue({
              type: ApiConstant.ACKNOWLEDGE_EMPTY_CHECKBOX_SELECTION,
              value: item?.sapMaterialCode,
            })
          );
          totalItems += item?.serialNumbers?.length;
        });
        dispatch(setTransferStockSerialNumberData({ value: tData }));
        dispatch(
          setTrackStockFieldValue({
            type: ApiConstant.ACKNOWLEDGE_TOTAL_ITEMS,
            value: totalItems,
          })
        );
        dispatch(
          setTrackStockFieldValue({
            type: ApiConstant.INVENTORY_TRACK_STOCK_SELECTED_DATA_ITEM_DETAILS,
            value: response,
          })
        );

        setPageState({
          ...pageState,
          dataLoader: false,
          selectedTrackStockItemDetails: response,
          getDataError: false,
          getDataErrorMsg: "",
        });
      })
      .catch((error) => {
        const errorMessage = ErrorMessageSetUp({
          error: error,
          message1: ApiConstant.VIEW_STOCK_LIST_ERROR_MESSAGE,
          message2: ApiConstant.WORK_ORDER_LIST_WARNING_MESSAGE,
        });
        setPageState({
          ...pageState,
          dataLoader: false,
          selectedTrackStockItemDetails: null,
          getDataError: true,
          getDataErrorMsg: errorMessage,
        });
      });
  }

  useEffect(() => {
    TracKStockDetailsApiCall();
  }, []);

  useEffect(() => {}, [pageState?.clickedSerialNumberItem]);

  return (
    <div className="page-start-section">
      <Header
        userDataObj={userDataObj}
        globalState={globalState}
        setGlobalState={setGlobalState}
        tab4={true}
      />
      <div
        className={
          globalState?.sidebarState
            ? "body-start-section sidebar-active-section"
            : "body-start-section"
        }
      >
        {/* Sidebar */}
        <SupervisorSidebar
          userDataObj={userDataObj}
          globalState={globalState}
          setGlobalState={setGlobalState}
        />
        <div className="body-full-content-section">
          <TrackStockRequestDetailsBodyHeader />
          <TrackStockRequestDetailsBodyContent
            pageState={pageState}
            globalState={globalState}
            setPageState={setPageState}
            selectedTrackStockItem={selectedTrackStockItem}
            selectedTrackStockItemDetails={
              pageState?.selectedTrackStockItemDetails
            }
            requestorOutletName={requestorOutletName}
            destinationOutletName={destinationOutletName}
            destinationOutletContact={destinationOutletContact}
            userDataObj={userDataObj}
            requestorOutletContactNo={requestorOutletContactNo}
            destinationOutletAdd={destinationOutletAdd}
            requestorOutletAdd={requestorOutletAdd}
            invDataObj={invDataObj}
            toggleStatus={toggleStatus}
            setToggleStatus={setToggleStatus}
          />
        </div>
        <Footer />
        <TransferAddSerialNumberModal
          seletedSerialList={
            transferSerialNumberObj[
              pageState?.clickedSerialNumberItem?.sapMaterialCode
            ]
          }
          data={pageState?.clickedSerialNumberItem}
          userDataObj={userDataObj}
          invDataObj={invDataObj}
        />
        <TransferStockOrderPlaceSerialNo
          selectedTransferStockList={selectedTrackStockItemDetails}
          transferSerialNumberObj={transferSerialNumberObj}
          pageState={pageState}
          setPageState={setPageState}
          userDataObj={userDataObj}
          invDataObj={invDataObj}
          requestorOutletName={requestorOutletName}
          destinationOutletName={destinationOutletName}
          destinationOutletContact={destinationOutletContact}
          requestorOutletContactNo={requestorOutletContactNo}
          destinationOutletAdd={destinationOutletAdd}
          requestorOutletAdd={requestorOutletAdd}
          toggleStatus={toggleStatus}
          setToggleStatus={setToggleStatus}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default TrackStockRequestDetailsPage;
