import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router-dom';
import { Constent } from '../../../../Assets/Constants/Constent';
import { DownloadIcon, EdditUserIcon } from '../../../../Assets/Images/CelcomSvgImages';
import { SupervisorWorkOrderStatusCal } from '../../../Common/GenericFunctions';
import { DownloadBtn } from '../../../Common/Button';
import { CardDetailsLoader } from '../../../Loaders/Loaders';
import WorkOrderDetailsAccordion from '../Supervisor/WorkOrderDeatilsHelper/WorkOrderDetailsAccordion';
import WorkOrderDetailsTimeLine from '../Supervisor/WorkOrderDeatilsHelper/WorkOrderDetailsTimeLine';
import { OtherImages } from '../../../../Assets/Images/CelcomOtherImages';

const InstallerWorkOrderDetailsBodyContent = ({ userDataObj, selectedWorkOrder, pageState, updateActivityBtn, workOrderDetailsObj, globalState }) => {

    const navigate = useNavigate();
    const [downloadBtnState, setDownloadBtnState] = useState(false);
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            setDownloadBtnState(false);
        }
    });

    const downloadPdfFun = () => {
        setDownloadBtnState(true);
    }

    const { status } = (SupervisorWorkOrderStatusCal(selectedWorkOrder) || {
        status: "",
        addClass: ""
    });

    useEffect(() => {
        if (downloadBtnState) {
            handlePrint();
        }
    }, [downloadBtnState]);

    return (
        <div className='body-content-section pt-0' ref={componentRef}>
            {
                downloadBtnState && <div className={`d-flex justify-content-end pt-5`}>
                    {
                        downloadBtnState && <img src={OtherImages.CelcomLogo} style={{ right: 24 }} className="" />
                    }
                </div>
            }
            <div className={`work-order-details-content-section installer-work-order-details-content-section ${downloadBtnState ? "active-sidebar" : ""}`}>
                <div className="work-order-details-content-item-section"></div>
                {
                    pageState?.dataLoader ?
                        <CardDetailsLoader />
                        :
                        pageState?.getDataError ?
                            <div className="error-message-section">
                                {pageState?.getDataErrorMsg}...
                            </div>
                            :
                            <div className="work-order-details-content-item-section">
                                {/* Title & Btn */}
                                <div className="work-order-details-inner-item-section">
                                    <div className="work-order-details-item-section">
                                        <div className="work-order-details-item">
                                            {`${Constent.WORK_ORDER_DETAILS_CONTENT_TITLE1} `}<span>
                                                {workOrderDetailsObj?.itemID?.replace("FieldActivity-", "")?.trim()}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="work-order-details-item-section"></div>
                                    <div className="work-order-details-item-section">
                                        { /* if item status = Done, Download button goes to right*/
                                            !downloadBtnState && status == "Closed" &&
                                            <div className=""></div>
                                        }
                                        {
                                            !downloadBtnState && <div className="download-btn-section" onClick={downloadPdfFun}>
                                                <span>{Constent.DOWNLOAD_BTN}</span>
                                                <DownloadIcon />
                                            </div>
                                        }
                                        {
                                            !downloadBtnState && status!="Closed" && <div className={`assign-btn-section ${!updateActivityBtn ? "inactive-btn" : ""}`} onClick={() => {
                                                updateActivityBtn && navigate("/workorder/statusupdate");
                                            }}>
                                                <span>{Constent.UPDATE_BTN}</span>
                                                <EdditUserIcon />
                                            </div>
                                        }

                                    </div>
                                </div>
                                {/* Timeline */}
                                <div className="work-order-details-inner-item-section">
                                    <div className="work-order-details-item-section"></div>
                                    <div className="work-order-details-item-section">
                                        <WorkOrderDetailsTimeLine workOrderDetailsObj={workOrderDetailsObj} />
                                    </div>
                                    <div className="work-order-details-item-section"></div>
                                </div>
                                <div className="work-order-details-inner-item-section-mobile"
                                    data-toggle="modal" data-target="#workOrderTimelineModal">
                                    <span>{Constent.VIEW_TIMELINE_MODAL_TITLE}</span>
                                </div>
                                {/* Accordion */}
                                <div className="work-order-details-inner-item-section">
                                    <WorkOrderDetailsAccordion userDataObj={userDataObj} selectedWorkOrder={selectedWorkOrder}
                                        workOrderDetailsObj={workOrderDetailsObj} downloadBtnState={downloadBtnState} />
                                </div>
                            </div>

                }
                <div className="work-order-details-content-item-section"></div>
            </div>
        </div>
    )
}

export default InstallerWorkOrderDetailsBodyContent;