import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NoDataFoundItemGroup } from "../../../../Common/CardContent";
import { PagenationIndexPositionCal } from "../../../../Common/GenericFunctions";
import Pagination from "../../../../Common/Pagination";
import { CardLoader } from "../../../../Loaders/Loaders";
import TrackStockCard from "../../../Cards/Supervisor/TrackStockCard";

const InventoryTrackStockContent = ({
  globalState,
  pageState,
  setPageState,
  getTrackStockDataError,
  getTrackStockDataErrorMsg,
  trackStockList,
  trackRequestDetailsPage,
  requestType,
  pageLoader,
}) => {
  const invDataObj = useSelector((state) => state.inventoryDataInfo);

  const { trackStockRequestId, trackStockRequestStatus } = invDataObj;
  const [currentPage, setCurrentPage] = useState(1);
  const postPerPage = Math.min(trackStockList?.length, 10);
  const filterList = trackStockList?.filter((item) => {
    if (
      (trackStockRequestId === "" && trackStockRequestStatus === "") ||
      (item?.transferId
        ?.toLowerCase()
        ?.includes(trackStockRequestId?.toLowerCase()) &&
        item?.transferStatus
          ?.toLowerCase()
          ?.includes(trackStockRequestStatus?.toLowerCase()))
    ) {
      return item;
    }
  });
  const { indexOfLastPost, indexOfFirstPost, displayPosts } =
    PagenationIndexPositionCal(currentPage, postPerPage, filterList);

  return (
    <>
      {pageLoader ? (
        <>
          {[0, 1].map((val) => {
            return <CardLoader key={`trackStockRequestList${val}`} />;
          })}
        </>
      ) : getTrackStockDataError ? (
        <NoDataFoundItemGroup message={getTrackStockDataErrorMsg} />
      ) : (
        <>
          {displayPosts?.length > 0 ? (
            <div className="inventory-filter-section">
              <div className="filter-item-info-section">
                <strong>{`${indexOfFirstPost + 1} - ${
                  indexOfLastPost > filterList?.length
                    ? filterList?.length
                    : indexOfLastPost
                } of ${filterList?.length}`}</strong>{" "}
                Items
              </div>
              <div className="filter-item-info-section">
                <Pagination
                  lastPageNumber={Math.ceil(filterList?.length / postPerPage)}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
              <div className="filter-item-info-section"></div>
            </div>
          ) : (
            <></>
          )}
          {displayPosts?.map((data, index) => {
            return (
              <TrackStockCard
                key={`trackInv${index}`}
                globalState={globalState}
                requestType={requestType}
                pageState={pageState}
                trackRequestDetailsPage={trackRequestDetailsPage}
                setPageState={setPageState}
                data={data}
              />
            );
          })}
        </>
      )}
    </>
  );
};

export default InventoryTrackStockContent;
