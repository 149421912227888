import React from 'react';
import { Constent } from '../../Assets/Constants/Constent';
import { CloseModalIcon } from '../../Assets/Images/CelcomSvgImages';
import { EmptyValueHandler, NumberFormater } from '../Common/GenericFunctions';
import { ReturnWorkOrderModalBodyContent } from '../Common/ModalHelper';

const WorkOrderDetailsDeviceListModal = ({ returnWorkOrderList, ownOrderDetails }) => {
    const { rgwDeviceList, btuDeviceList, meshDeviceList } = (ownOrderDetails?.deviceDetails === undefined ||
        ownOrderDetails?.deviceDetails === null) ? { rgwDeviceList: [], btuDeviceList: [], meshDeviceList: [] }
        : ownOrderDetails?.deviceDetails;

    const totalDeviceList = [].concat(rgwDeviceList, meshDeviceList);

    return (
        <div className="modal fade returnd-work-order-modal" id="workOrderDeviceListModal" data-backdrop="static"
            data-keyboard="false" tabIndex="-1" aria-labelledby="workOrderDeviceListModalLabel"
            aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header pb-0">
                        <div className="wrapper-modal-header pt-5">
                            <div className="modal-header-title-section">
                                <h5 className="modal-title" id="workOrderDeviceListModalLabel">
                                    {Constent.WORK_ORDER_DETAILS_DEVICE_LIST_MODAL_TITLE}
                                </h5>
                                <span>{totalDeviceList?.length + ` - Items`}</span>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <CloseModalIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="wrapper-modal-body">
                            {
                                totalDeviceList?.map((deviceItemData, index) => {
                                    return (
                                        <div className="returnd-work-order-modal-card-item-group" key={`deviceItem${index + (deviceItemData?.productID || "-")}`}>
                                            <div className="returnd-work-order-modal-card-item-inner-group">
                                                <div className="card-item-group d-flex align-items-center">
                                                    <div className="card-item-value">
                                                        {NumberFormater({ numberValue: index + 1 })}
                                                    </div>
                                                </div>
                                                {/* <div className="card-item-group">
                                                    <div className="card-item-title">
                                                        {Constent.WORK_ORDER_DETAILS_MODAL_DEVICE_NAME}
                                                    </div>
                                                    <div className="card-item-value">
                                                        {EmptyValueHandler({ item: deviceItemData?.name })}
                                                    </div>
                                                </div> */}
                                                <div className="card-item-group">
                                                    <div className="card-item-title">
                                                        {Constent.WORK_ORDER_DETAILS_MODAL_DEVICE_TYPE}
                                                    </div>
                                                    <div className="card-item-value">
                                                        {EmptyValueHandler({ item: deviceItemData?.deviceType })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="okay-btn-section" data-dismiss="modal">
                            <span>{Constent.OKAY_BTN}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkOrderDetailsDeviceListModal;