import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Constent } from '../../Assets/Constants/Constent'
import { LeftArrowIcon } from '../../Assets/Images/CelcomSvgImages'

export const BodyBreadcrumbs = ({ activeItem }) => {
    const navigate = useNavigate();
    return (
        <>
            <ol className="breadcrumb">
                <li className="breadcrumb-item custome-path-inactive-tab">
                    <div onClick={() =>
                        navigate("/dashboard")}
                        className='custome-back-mobile-text' >{Constent.BODY_HEADER_DEFUALT_PATH}</div>
                    <div onClick={() =>
                        navigate("/dashboard")} className='custome-back-mobile-btn'>
                        <LeftArrowIcon stroke={'#009BDF'} />
                    </div>
                </li>
                <li className="breadcrumb-item active custome-path-active-tab">
                    {activeItem}
                </li>
            </ol>
        </>
    )
}

export const BodyBreadcrumbs2 = ({ secondItem, activeItem, backTab, secondTab }) => {
    const navigate = useNavigate();
    return (
        <>
            <ol className="breadcrumb custom-breadcrumb-child-2" >

                <li className="breadcrumb-item custome-path-inactive-tab">
                    <div onClick={() =>
                        navigate(backTab || "/dashboard")}
                        className='custome-back-mobile-text' >{Constent.BODY_HEADER_DEFUALT_PATH}</div>
                </li>
                <li className="breadcrumb-item custome-path-inactive-tab">
                    <div onClick={() =>
                        navigate(secondTab || "/dashboard")}
                        className='custome-back-mobile-text'>{secondItem}</div>

                    <div onClick={() =>
                        navigate(secondTab || "/dashboard")} className='custome-back-mobile-btn'>
                        <LeftArrowIcon stroke={'#009BDF'} />
                    </div>

                </li>
                <li className="breadcrumb-item active custome-path-active-tab">
                    {activeItem}
                </li>
            </ol>
        </>
    )
}


export const BodyBreadcrumbsWhitePage = ({ activeItem, backTab }) => {
    const navigate = useNavigate();
    return (
        <>
            <ol className="breadcrumb mb-0 custom-breadcrumb-white">
                <li className="breadcrumb-item custome-path-inactive-tab">
                    <div onClick={() =>
                        navigate(backTab || "/dashboard")}
                        className='custome-back-mobile-text' >{Constent.BODY_HEADER_DEFUALT_PATH}</div>
                    <div onClick={() =>
                        navigate(backTab || "/dashboard")} className='custome-back-mobile-btn'>
                        <LeftArrowIcon stroke={'#009BDF'} />
                    </div>
                </li>
                <li className="breadcrumb-item active custome-path-active-tab">
                    {activeItem}
                </li>
            </ol>
        </>
    )
}

export const BodyBreadcrumbsWhitePageChild2 = ({ activeItem, secondItem, secondTab }) => {
    const navigate = useNavigate();
    return (
        <>
            <ol className="breadcrumb custom-breadcrumb-child-2 custom-breadcrumb-white" >

                <li className="breadcrumb-item custome-path-inactive-tab">
                    <div onClick={() =>
                        navigate("/dashboard")}
                        className='custome-back-mobile-text' >{Constent.BODY_HEADER_DEFUALT_PATH}</div>
                </li>
                <li className="breadcrumb-item custome-path-inactive-tab">
                    <div onClick={() =>
                        navigate(secondTab || "/dashboard")}
                        className='custome-back-mobile-text'>{secondItem}</div>

                    <div onClick={() =>
                        navigate(secondTab || "/dashboard")} className='custome-back-mobile-btn'>
                        <LeftArrowIcon stroke={'#009BDF'} />
                    </div>

                </li>
                <li className="breadcrumb-item active custome-path-active-tab">
                    {activeItem}
                </li>
            </ol>
        </>
    )
}


export const BodyBreadcrumbsWhitePageChild3 = ({ secondItem, activeItem, secondTab, thirdItem, thirdTab }) => {
    const navigate = useNavigate();
    return (
        <>
            <ol className="breadcrumb mb-0 custom-breadcrumb-white">
                <li className="breadcrumb-item custome-path-inactive-tab">
                    <div onClick={() =>
                        navigate("/dashboard")}
                        className='custome-back-mobile-text' >{Constent.BODY_HEADER_DEFUALT_PATH}</div>
                </li>
                <li className="breadcrumb-item custome-path-inactive-tab">
                    <div onClick={() =>
                        navigate(secondTab || "/dashboard")}
                        className='custome-back-mobile-text ml-3' >{secondItem}</div>
                </li>
                <li className="breadcrumb-item custome-path-inactive-tab">
                    <div onClick={() =>
                        navigate(thirdTab || "/dashboard")}
                        className='custome-back-mobile-text ml-3'>{thirdItem}</div>

                    <div onClick={() =>
                        navigate(thirdTab || "/dashboard")} className='custome-back-mobile-btn'>
                        <LeftArrowIcon stroke={'#009BDF'} />
                    </div>

                </li>
                <li className="breadcrumb-item active custome-path-active-tab">
                    {activeItem}
                </li>
            </ol>
        </>
    )
}