import React from "react";
import { Constent } from "../../Assets/Constants/Constent";
import { NoDataFoundItemGroup } from "../../Components/Common/CardContent";
import * as XLSX from 'xlsx';
import { DownloadIcon } from "../../Assets/Images/CelcomSvgImages";

const ViewCards = ({ viewCardsData }) => {
    const list = viewCardsData?.orderwithNotesList
    const fileName = "excel"

    const downloadExcel = (list) => {

        const processedRecords = list.map(item => ({
            'Order ID': item.orderId,
            'Created Date': item.created_dt,
            // 'Note Type': item.noteType,
            'Notes': item.orderNotesList.join(', '), // Convert array to a comma-separated string
            'Request ID': item.request_id
        }));
        // Create a new workbook and a sheet
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(processedRecords);

        // Append the sheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Generate a download link and trigger the download
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    };
    return (
        <>
            <div className="dashbord-header-filter-section">
                <div className="dashbord-header-filter-itme-info d-flex">
                    <div className="order-header-filter-item-info">
                        {
                            // filterList?.length > 0 &&
                            <strong>{`${list?.length > 0 ? list?.length : 0} - ${Constent.HEADER_MENU_NOTES}`}</strong>
                        }
                    </div>
                    <div className="order-header-download-item-section">
                        <div onClick={() => downloadExcel(list)}
                            className="item-list-download-btn-section" >
                            <span>{Constent.DOWNLOAD_BTN}</span>
                            <DownloadIcon />
                        </div>
                    </div>
                </div>

            </div>
            {viewCardsData && list && list?.length > 0 ?
                list?.map((item) => (
                    <div className="view-card-section">
                        <div className="viewSearch-card-section">
                            <div className="wrapper-view-card-section">
                                <div className="normal-card-item-section">
                                    <div className="card-item-group">
                                        <div className="card-item-title">
                                            {Constent.WORK_ORDER_DETAILS_CONTENT_TITLE2}
                                        </div>
                                        <div className="card-item-value">
                                            {item?.orderId || "-"}
                                        </div>
                                    </div>
                                </div>
                                <div className={`normal-card-item-section `}>
                                    <div className="card-item-group">
                                        <div className="card-item-title">
                                            {Constent.WORK_ORDER_CARD_ITEM_TITLE4}
                                        </div>
                                        <div className="card-item-value">
                                            {item?.created_dt || "-"}
                                        </div>
                                    </div>
                                    <div className="card-item-group " >
                                        <div className="card-item-title">
                                            {Constent.PARTNER_VIEW_NOTES_TEXT1}
                                        </div>
                                        <div className="card-item-value">
                                            {item?.orderNotesList || "-"}
                                        </div>
                                    </div>
                                    <div className="card-item-group">
                                        <div className="card-item-title">
                                            {Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE1}
                                        </div>
                                        <div className="card-item-value">
                                            {item?.request_id || "-"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                )
                :
                <NoDataFoundItemGroup />
            }
        </>
    )
}
export default ViewCards;
