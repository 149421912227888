import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ordersearchtype: [
        {
            "display": "Order Number",
            "value": "ORDERID"

        },
        {
            "display": "Customer ID",
            "value": "CUSTOMERID"
        },
        {
            "display": "Service ID",
            "value": "SERVICEID"
        },
        {
            "display": "Order Status",
            "value": "ORDERSTATUS"
        }
    ]
}


export const LovDataState = createSlice({
    name: 'LovDataState',
    initialState,
    reducers: {
        // Creating the Actions
        saveLovs: (state, action) => {
            const { key, value } = action.payload

            return { ...state, [key]: value }
        },
        /* ---- Reset Data ---- */
        resetLovData: () => initialState
    }
});

export const { saveLovs, resetLovData } = LovDataState.actions;

export default LovDataState.reducer;
