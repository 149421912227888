import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Constent } from "../../../../Assets/Constants/Constent";
import {
  AdditionIcon,
  DeleteIcon,
} from "../../../../Assets/Images/CelcomSvgImages";
import {
  removeReturnStockData,
  removeSelectedReturnStockData,
  saveAllSelectedReturnStockData,
  saveSelectedReturnStockData,
} from "../../../../ReduxFeature/StateFeatures/CartDataState";

const ReturnCartCard = ({
  data,
  index,
  pageState,
  selectedReturnStockList,
  setPageState,
}) => {
  const [checkBoxState, setCheckBoxState] = useState(false);
  const cardCheckBoxSelected = pageState?.returnStockSelectAll || checkBoxState;
  const dispatch = useDispatch();

  useEffect(() => {
    if (pageState?.returnStockSelectAll) {
      dispatch(saveAllSelectedReturnStockData());
    } else {
      if (checkBoxState) {
        const itemIndex = selectedReturnStockList?.findIndex(
          (obj) => obj?.id === data?.id
        );
        if (itemIndex !== -1) {
          dispatch(saveSelectedReturnStockData(data));
        }
      } else {
        dispatch(removeSelectedReturnStockData(data));
      }
    }
  }, [checkBoxState, pageState?.returnStockSelectAll]);
  return (
    <div className="return-stock-card-section">
      <div className="wrapper-return-stock-card-section">
        <div className="normal-card-item-section">
          <div className="card-item-checkbox">
            <div className="custom-control custom-checkbox">
              <input
                className="custom-control-input"
                type="checkbox"
                value=""
                checked={cardCheckBoxSelected}
                id={`return${index}`}
                onChange={(e) => {
                  setCheckBoxState(e.target.checked);
                  if (e.target.checked) {
                    const itemIndex = selectedReturnStockList?.findIndex(
                      (obj) => obj?.id === data?.id
                    );
                    if (itemIndex === -1) {
                      dispatch(saveSelectedReturnStockData(data));
                    }
                  }
                }}
              />
              <label
                className="custom-control-label"
                htmlFor={`return${index}`}
              ></label>
            </div>
          </div>
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE1}
            </div>
            <div className="card-item-value">
              {data?.sAPMaterialCode || "-"}
            </div>
          </div>
        </div>
        <div
          className="normal-card-item-section"
          data-toggle="collapse"
          data-target={`#stock-details-${index}`}
          aria-expanded="false"
          aria-controls={`stock-details-${index}`}
        >
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE2}
            </div>
            <div className="card-item-value">{data?.itemName || "-"}</div>
          </div>
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE3}
            </div>
            <div className="card-item-value">{data?.brand || "-"}</div>
          </div>
          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE4}
            </div>
            <div className="card-item-value">{data?.category || "-"}</div>
          </div>

          <div className="card-item-group">
            <div className="card-item-title">
              {Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE6}
            </div>
            <div className="card-item-value">
              {data?.totalAvailableQty || "-"}
            </div>
          </div>
        </div>
        <div className="normal-card-item-section">
          <div
            className="card-item-group"
            onClick={() => {
              setPageState({ ...pageState, clickedSerialNumberItem: data });
            }}
            data-toggle="modal"
            data-target="#addSerialNumberModal"
          >
            <div className="card-item-inner">
              {`${Constent.SERIAL_NUMBER_BTN} `}
              <span>
                <span>*</span>
                <AdditionIcon />
              </span>
            </div>
          </div>
          <div
            className="card-item-group"
            onClick={() => {
              setCheckBoxState(false);
              dispatch(removeSelectedReturnStockData(data));
              dispatch(removeReturnStockData(data));
            }}
          >
            <DeleteIcon />
          </div>
          {/* <div className={accordion ? "card-item-group custom-rotate-90" : "card-item-group"} onClick={() => {
                        setAccordion(!accordion);
                    }} data-toggle="collapse" data-target={`#stock-details-${index}`} aria-expanded="false" aria-controls={`stock-details-${index}`}>
                        <DropDownIcon />
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default ReturnCartCard;
