import React from 'react';

export const CardLoader = () => {
    return (
        <div className="card-loader-section">
            <div className="card-loader-item-section">
                <div className="card-loader-item"></div>
            </div>
        </div>
    )
}

export const CardDetailsLoader = () => {
    return (
        <div className="card-details-loader-section">
            <div className="card-loader-item-section">
                <div className="card-loader-item"></div>
            </div>
        </div>
    )
}