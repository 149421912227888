import React from "react";
import { useSelector } from "react-redux";
import { Constent } from "../../../Assets/Constants/Constent";
import { HeaderNavChild3 } from "../../../Components/Common/HeaderNav";
import Footer from "../../../Components/Footer/Footer";
import { Header } from "../../../Components/Header/Header";
import ApproveStockConfirmationBodyContent from "../../../Components/MainBody/BodyContent/Admin/ConfirmationPagesContent/ApproveStockConfirmationBodyContent";
import SupervisorSidebar from "../../../Components/MainBody/Sidebar/SupervisorSidebar";

const ApproveStockConfirmationPage = ({ globalState, setGlobalState }) => {
  const userDataObj = useSelector((state) => state.userDataInfo);
  const inventoryDataObj = useSelector((state) => state.inventoryDataInfo);
  const { selectedTrackStockItem, adminTrackStockConfirmMsg } =
    inventoryDataObj;

  return (
    <div className="page-start-section">
      <Header
        userDataObj={userDataObj}
        globalState={globalState}
        setGlobalState={setGlobalState}
        tab4={true}
      />

      <div
        className={
          globalState?.sidebarState
            ? "body-start-section sidebar-active-section"
            : "body-start-section"
        }
      >
        {/* Sidebar */}
        <SupervisorSidebar
          userDataObj={userDataObj}
          globalState={globalState}
          setGlobalState={setGlobalState}
        />
        <div className="body-full-content-section">
          <HeaderNavChild3
            activeItem={Constent.BODY_HEADER_TRACK_REQUEST_DETAILS}
            secondItem={Constent.HEADER_MENU_ITEM4}
            secondTab={"/inventory"}
          />
          <ApproveStockConfirmationBodyContent
            globalState={globalState}
            setGlobalState={setGlobalState}
            message={adminTrackStockConfirmMsg}
            userName={
              userDataObj?.userData?.activeUserData?.userData?.firstName
            }
            requestId={selectedTrackStockItem?.transferId || "-"}
          />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ApproveStockConfirmationPage;
