import React, { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { Constent } from '../../Assets/Constants/Constent';
import SessionExpireModal from '../Modals/SessionExpireModal';

function IdleTimerContainer({ userData, showCartPart }) {
  const navigate = useNavigate();
  const timeout = Constent.SESSION_EXPIRE // 15 mins
  const [remaining, setRemaining] = useState(timeout);
  const [elapsed, setElapsed] = useState(0);
  const [lastActive, setLastActive] = useState(+new Date());
  const [isIdle, setIsIdle] = useState(false);

  const [modalActive, setModalActive] = useState(false);

  const handleOnActive = () => {    // Do some active action
    setIsIdle(false);
  }

  const handleOnIdle = () => {      // Do some idle action like log out your user
    setIsIdle(true);

    if (userData) {
      navigate("/");
    }
  }

  const {
    // reset,
    // pause,
    // resume,
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  });

  useEffect(() => {
    setInterval(() => {
      setRemaining(getRemainingTime())
      setLastActive(getLastActiveTime())
      setElapsed(getElapsedTime())
    }, 1000);
  }, []);

  function createMarkup() {
    return { __html: '<div class="modal-backdrop fade show"></div>' };
  }
  
  useEffect(() => {
    if (remaining <= Constent.SESSION_EXPIRE_TIMEOUT_MESSAGE && userData) {      // 13 mins popup an alert
      setModalActive(true);
      document.body.classList.add("modal-open");
    }
  }, [remaining]);

  return (
    <>
      <div className="profile-details-item" >
        <div className="logout-btn-section" id="#sessionExpireModalSector" data-toggle="modal"
          data-target="#sessionExpireModal"
          dangerouslySetInnerHTML={modalActive ? createMarkup() : null}>
          {/* Session Expired */}
        </div>
      </div>
      <SessionExpireModal showCartPart={showCartPart} remaining={remaining}
        modalActive={modalActive} setModalActive={setModalActive} />
    </>
  );
}

export default IdleTimerContainer;