import React from 'react';
import { Constent } from '../../Assets/Constants/Constent';
import { CloseModalIcon, ColoredTimeLineIcon } from '../../Assets/Images/CelcomSvgImages';
import WorkOrderDetailsTimeLine from '../MainBody/BodyContent/Supervisor/WorkOrderDeatilsHelper/WorkOrderDetailsTimeLine';

const WorkOrderDetailsTimelineModal = ({ workOrderDetailsObj }) => {
    return (
        <div className="modal fade work-order-details-time-line-modal" id="workOrderTimelineModal"
            data-backdrop="static"
            data-keyboard="false" tabIndex="-1" aria-labelledby="workOrderTimelineModalLabel"
            aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header pb-0">
                        <div className="wrapper-modal-header pt-5">
                            <div className="modal-header-title-section">
                                <span><ColoredTimeLineIcon /></span>
                                <h5 className="modal-title" id="workOrderTimelineModalLabel">
                                    {Constent.VIEW_TIMELINE_MODAL_TITLE}
                                </h5>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <CloseModalIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="wrapper-modal-body">
                            <WorkOrderDetailsTimeLine workOrderDetailsObj={workOrderDetailsObj} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="okay-btn-section" data-dismiss="modal">
                            {Constent.OKAY_BTN}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkOrderDetailsTimelineModal;