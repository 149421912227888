import moment from "moment";
import React from "react";
import DatePicker from "react-date-picker";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { Constent } from "../../Assets/Constants/Constent";
import {
  CalendarIcon,
  CloseModalIcon,
  SearchIcon,
} from "../../Assets/Images/CelcomSvgImages";
import { useDispatch } from "react-redux";
import { workOrderSearchPageState } from "../../ReduxFeature/StateFeatures/WorkOrderDataState";

export const ViewWorkOrderReportAdvSearchModal = ({
  pageState,
  setPageState,
  reportExtractionAPICall,
  workOrderDataObj,
  userDataObj,
  lovDataObj,
  supervisorListWithUserGroup,
}) => {
  const dispatch = useDispatch();
  const listOfOutletId = Object.keys(supervisorListWithUserGroup);
  const { workOrderId, workOrderFromDate, workOrderToDate, searchType } =
    workOrderDataObj;

  return (
    <div
      className="modal fade advance-search-work-order-modal"
      id="viewWorkOrderReportAdvanceSearch"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby="viewWorkOrderReportAdvanceSearchLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pb-0">
            <div className="wrapper-modal-header pt-5">
              <div className="modal-header-title-section">
                <h5 className="modal-title" id="workOrderAdvanceSearchLabel">
                  {Constent.WORK_ORDER_ADVANCED_SEARCH_MODAL_TITLE}
                </h5>
                <SearchIcon stroke={"#00000080"} />
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <CloseModalIcon />
                </span>
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="wrapper-modal-body">
              {userDataObj?.userData?.activeUserData?.userRole ===
              Constent.ADMIN_ROLE ? (
                <>
                  <div className="mobal-body-section-group">
                    <div className="card-item-group">
                      <div className="form-group m-0 custom-drop-down-form-group">
                        <select
                          className="custom-select"
                          onChange={(e) => {
                            const { value } = e.target;
                            setPageState({
                              ...pageState,
                              accessProviderValue: value,
                            });
                          }}
                          value={pageState.accessProviderValue}
                        >
                          <option defaultValue="" value="">
                            -- Select --
                          </option>
                          <option defaultValue="" value="">
                            -- Select --
                          </option>
                          {/* <option key={`advanceRequestType1`} value="">
                            {searchType}
                          </option> */}
                          {lovDataObj?.accessProviderLOV?.map((item, index) => {
                            return (
                              <option
                                key={`accessProviderLOV${index}`}
                                value={item?.Value}
                              >
                                {item?.Value}
                              </option>
                            );
                          })}
                        </select>
                        <label htmlFor={Constent.ACCESS_PROVIDER}>
                          {Constent.ACCESS_PROVIDER}
                        </label>
                      </div>
                    </div>
                    <div className="card-item-group">
                      <div className="form-group mb-0 custom-input-form-group ">
                        <span className="invalid-msg-text">
                          {Constent.INVALID_INPUT}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="mobal-body-section-group">
                <div className="card-item-group">
                  <div
                    className={`form-group mb-0 custom-input-form-group ${
                      pageState?.formDateError?.length > 0 &&
                      "custom-invalid-form-group"
                    }`}
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="formDate"
                        placeholder=" "
                        value={pageState?.workOrderReportFromDate}
                        onChange={(e) => {
                          setPageState({
                            ...pageState,
                            workOrderReportFromDate: e.target.value,
                          });
                        }}
                      />
                      <label htmlFor="formDate">
                        {Constent.DATE_RANGE_INPUT_ITEM_LABEL1}
                      </label>
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <CalendarIcon fill={"#333333"} />
                        </span>
                        <div className="input-group-append-date-picker-section">
                          <DatePicker
                            selected={moment()}
                            onChange={(date) => {
                              setPageState({
                                ...pageState,
                                workOrderReportFromDate:
                                  moment(date)?.format("YYYY-MM-DD"),
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <span className="invalid-msg-text">
                      {pageState?.formDateError}
                    </span>
                  </div>
                </div>
                <div className="card-item-group">
                  <div
                    className={`form-group mb-0 custom-input-form-group ${
                      pageState?.toDateError?.length > 0 &&
                      "custom-invalid-form-group"
                    }`}
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id={Constent.DATE_RANGE_INPUT_ITEM_LABEL2}
                        placeholder=" "
                        value={pageState?.workOrderReportToDate}
                        onChange={(e) => {
                          setPageState({
                            ...pageState,
                            workOrderReportToDate: e.target.value,
                          });
                        }}
                      />
                      <label htmlFor={Constent.DATE_RANGE_INPUT_ITEM_LABEL2}>
                        {Constent.DATE_RANGE_INPUT_ITEM_LABEL2}
                      </label>
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <CalendarIcon fill={"#333333"} />
                        </span>
                        <div className="input-group-append-date-picker-section">
                          <DatePicker
                            selected={moment()}
                            onChange={(date) => {
                              setPageState({
                                ...pageState,
                                workOrderReportToDate:
                                  moment(date)?.format("YYYY-MM-DD"),
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <span className="invalid-msg-text">
                      {pageState?.toDateError}
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="mobal-body-section-group">
                {Constent.FREE_CONTENT_TEXT2}
              </div> */}
              <div className="mobal-body-section-group">
                <div className="card-item-group">
                  <div className="form-group m-0 custom-drop-down-form-group">
                    <select
                      className="custom-select"
                      onChange={(e) => {
                        const { value } = e.target;

                        if (value === "select") {
                          setPageState({
                            ...pageState,
                            advanceSearchSubLovs: [],
                            selectedAdvanceSearchTypeReport: value,
                          });
                        } else {
                          setPageState({
                            ...pageState,
                            selectedAdvanceSearchTypeReport: value,
                            advanceSearchSubLovs:
                              pageState?.advanceSearchLovs[
                                pageState?.advanceSearchLovs?.findIndex(
                                  (obj) => obj?.value === value
                                )
                              ]?.sub_Item,
                            selectedAdvanceSearchSubTypeList: [],
                          });
                        }
                      }}
                      value={pageState?.selectedAdvanceSearchTypeReport}
                    >
                      <option key={`advanceSearchLovsEmpty1`} value="">
                        -- Select --
                      </option>
                      <option key={`advanceSearchLovsEmpty2`} value="">
                        -- Select --
                      </option>
                      {pageState?.advanceSearchLovs?.map((data, index) => {
                        return (
                          <option
                            key={`advanceSearchLovs${index}`}
                            value={`${data?.value}`}
                          >
                            {data?.display}
                          </option>
                        );
                      })}
                    </select>
                    <label htmlFor={Constent.WORK_ORDER_CARD_ITEM_TITLE2}>
                      {Constent.WORK_ORDER_CARD_ITEM_TITLE2}
                    </label>
                  </div>
                </div>
              </div>
              <div className="mobal-body-section-group">
                {/* <div className="card-item-group">
                  {pageState?.advanceSearchSubLovs?.map((data, index) => {
                    return (
                      <div
                        className="custom-control custom-checkbox"
                        key={`advanceSearchSubLovs${index}`}
                      >
                        <input
                          className="custom-control-input"
                          type="checkbox"
                          value=""
                          checked={pageState?.selectedAdvanceSearchSubTypeList?.includes(
                            data.value
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const isFindIndex =
                                pageState?.selectedAdvanceSearchSubTypeList?.findIndex(
                                  (obj) => obj === data.value
                                );

                              if (isFindIndex === -1) {
                                setPageState({
                                  ...pageState,
                                  selectedAdvanceSearchSubTypeList: [
                                    ...pageState?.selectedAdvanceSearchSubTypeList,
                                    data?.value,
                                  ],
                                });
                              }
                            } else {
                              setPageState({
                                ...pageState,
                                selectedAdvanceSearchSubTypeList:
                                  pageState?.selectedAdvanceSearchSubTypeList?.filter(
                                    (val) => val !== data?.value
                                  ),
                              });
                            }
                          }}
                          id={`${data?.value}`}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`${data?.value}`}
                        >
                          {data?.display}
                        </label>
                      </div>
                    );
                  })}
                </div> */}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div
              className="refresh-btn-section"
              aria-label="Close"
              onClick={() => {
                setPageState({
                  ...pageState,
                  workOrderId: "",
                  workOrderReportFromDate: "",
                  workOrderReportToDate: "",
                  advanceSearchLovs: ApiConstant.WORK_ORDER_ADVANCE_SEARCH_LOV1,
                  advanceSearchSubLovs: [],
                  accessProviderValue: "",
                  selectedAdvanceSearchTypeReport: "",
                  selectedAdvanceSearchSubTypeList: [],
                });
              }}
            >
              <span>{Constent.REFRESH_BTN}</span>
            </div>
            <div
              className="search-btn-section"
              data-dismiss="modal"
              aria-label="Close"
              onClick={reportExtractionAPICall}
            >
              <SearchIcon />
              {Constent.SEARCH_BTN}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
