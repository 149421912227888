import axios from "axios";
import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  DirectLovCalls,
  GenericLovCall,
  headers,
  UpdateWorkOrderDetails,
} from "../../Api/ApiCalls";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { Constent } from "../../Assets/Constants/Constent";
import {
  CloseModalIcon,
  LocationIcon,
} from "../../Assets/Images/CelcomSvgImages";
import { updateWorkOrderDetailsData } from "../../ReduxFeature/StateFeatures/WorkOrderDataState";
import { toast } from "react-toastify";

const UpdateWorkOrderEnabledLocationModal = ({ pageState, setPageState }) => {
  const dispatch = useDispatch();
  const userDataObj = useSelector((state) => state.userDataInfo);
  const workOrderDataObj = useSelector((state) => state.workOrderInfo);

  const { workOrderDetails } = workOrderDataObj;

  const EnableLocationBtn = () => {
    setPageState({ ...pageState, apiCallAction: true });
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);

    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          const url =
            "/fibre/getlovs/geolocation?codinates=" +
            position.coords.latitude +
            "," +
            position.coords.longitude;
          DirectLovCalls(url, header)
            .then((locResponse) => {
              const gecodeRespData = locResponse;

              const activityType =
                workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OSI_TYPE
                  ? ApiConstant.WOKR_ORDER_OSI_SORT_TYPE
                  : workOrderDetails?.itemTypeID ===
                    ApiConstant.WOKR_ORDER_OST_TYPE
                  ? ApiConstant.WOKR_ORDER_OST_SORT_TYPE
                  : workOrderDetails?.itemTypeID ===
                    ApiConstant.WOKR_ORDER_BTU_TYPE
                  ? ApiConstant.WOKR_ORDER_BTU_SORT_TYPE
                  : null;

              if (gecodeRespData?.results?.length > 0) {
                const updateLocationRequest = {
                  activityId: workOrderDetails?.itemID,
                  activitySubType: workOrderDetails?.itemSubTypeId,
                  activityType: activityType,
                  lattitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                  location:
                    position.coords.latitude + ", " + position.coords.longitude,
                  streetName:
                    gecodeRespData?.results?.length > 0
                      ? gecodeRespData?.results[0]?.formatted_address
                      : "",
                  meshDevice:
                    workOrderDetails?.ownOrderDetails?.deviceDetails
                      ?.meshDeviceList?.length > 0,
                  installerContact:
                    userDataObj?.userData?.activeUserData?.userData
                      ?.contactNumber,
                  installerId:
                    userDataObj?.userData?.activeUserData?.userData?.userId,
                  installerName:
                    userDataObj?.userData?.activeUserData?.userData?.fullName,
                  status: "ARRIVED",
                  storeId:
                    userDataObj?.userData?.activeUserData?.userData?.outletId,
                  userId:
                    userDataObj?.userData?.activeUserData?.userData?.userId,
                  statusUpdateDateTime: moment().format("YYYY-MM-DD HH:mm"),
                  reservationId:
                    workOrderDetails?.installationDetail?.reservationId,
                };

                UpdateWorkOrderDetails(
                  "/fibre/update/updateactivitydetails",
                  updateLocationRequest,
                  header
                )
                  .then((response) => {
                    setPageState({ ...pageState, apiCallAction: false });
                    /*-- Update all Location Items --*/
                    // console.log(response);
                    if (response?.overallUpdateStatus === "SUCCESS") {
                      dispatch(
                        updateWorkOrderDetailsData({
                          type: ApiConstant.INSTALLER_WORK_ORDER_ACTIVE_TAB,
                          value: 1,
                        })
                      );
                      dispatch(
                        updateWorkOrderDetailsData({
                          type: ApiConstant.INSTALLER_ENABLED_LOCATION_BTN,
                          value: {
                            lattitude: updateLocationRequest?.lattitude || "-",
                            longitude: updateLocationRequest?.longitude || "-",
                            streetName:
                              updateLocationRequest?.streetName || "-",
                            time:
                              updateLocationRequest?.statusUpdateDateTime ||
                              "-",
                          },
                        })
                      );
                    } else {
                      toast.warning(
                        ApiConstant.INSTALLER_LOCATION_ERROR_MESSAGE,
                        {
                          position: toast.POSITION.BOTTOM_RIGHT,
                          autoClose: 10000,
                          theme: "colored",
                        }
                      );
                    }
                  })
                  .catch((error) => {
                    setPageState({ ...pageState, apiCallAction: false });
                    if (
                      error.response.status === 500 ||
                      error.response.status === 503
                    ) {
                      toast.error(
                        ApiConstant.INSTALLER_LOCATION_ERROR_MESSAGE,
                        {
                          position: toast.POSITION.BOTTOM_RIGHT,
                          autoClose: 10000,
                          theme: "colored",
                        }
                      );
                    } else {
                      toast.warning(
                        ApiConstant.INSTALLER_LOCATION_ERROR_MESSAGE,
                        {
                          position: toast.POSITION.BOTTOM_RIGHT,
                          autoClose: 10000,
                          theme: "colored",
                        }
                      );
                    }
                  });
              } else {
                setPageState({ ...pageState, apiCallAction: false });
                toast.warning(
                  gecodeRespData?.error_message ||
                    ApiConstant.INSTALLER_ENABLE_LOCATION_ERROR_MESSAGE,
                  {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 10000,
                    theme: "colored",
                  }
                );
              }
            })
            .catch((err) => {
              toast.warning(
                ApiConstant.INSTALLER_ENABLE_LOCATION_ERROR_MESSAGE,
                {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 10000,
                  theme: "colored",
                }
              );
              setPageState({ ...pageState, apiCallAction: false });
            });
        },
        (error) => {
          setPageState({ ...pageState, apiCallAction: false });
          toast.warning(ApiConstant.INSTALLER_ENABLE_LOCATION_ERROR_MESSAGE, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        }
      );
    } else {
      toast.warning(ApiConstant.INSTALLER_LOCATION_ERROR_MESSAGE, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 10000,
        theme: "colored",
      });
    }

    // axios.post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.REACT_APP_GOOGLE_API_KEY}`).then(locationResponse => {
    //     const locationResp = locationResponse?.data;
    //     locationResp !== null && locationResp?.location !== null
    //         && locationResp?.location?.lat !== null && locationResp?.location?.lng !== null
    //         && axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${locationResp?.location?.lat}, ${locationResp?.location?.lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`).then(gecodeResp => {

    //             const gecodeRespData = gecodeResp?.data;

    //             const activityType = workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OSI_TYPE ? ApiConstant.WOKR_ORDER_OSI_SORT_TYPE :
    //                 workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_OST_TYPE ? ApiConstant.WOKR_ORDER_OST_SORT_TYPE :
    //                     workOrderDetails?.itemTypeID === ApiConstant.WOKR_ORDER_BTU_TYPE ? ApiConstant.WOKR_ORDER_BTU_SORT_TYPE : null

    //             const updateLocationRequest = {
    //                 activityId: workOrderDetails?.itemID,
    //                 activitySubType: workOrderDetails?.itemSubTypeId,
    //                 activityType: activityType,
    //                 lattitude: locationResp?.location?.lat,
    //                 longitude: locationResp?.location?.lng,
    //                 location: locationResp?.location?.lat + ", " + locationResp?.location?.lng,
    //                 streetName: gecodeRespData?.results?.length > 0 ? gecodeRespData?.results[0]?.formatted_address : "",
    //                 meshDevice: workOrderDetails?.ownOrderDetails?.deviceDetails?.meshDeviceList?.length > 0,
    //                 installerContact: userDataObj?.userData?.activeUserData?.userData?.contactNumber,
    //                 installerId: userDataObj?.userData?.activeUserData?.userData?.userId,
    //                 installerName: userDataObj?.userData?.activeUserData?.userData?.fullName,
    //                 status: "ARRIVED",
    //                 storeId: userDataObj?.userData?.activeUserData?.userData?.outletId,
    //                 userId: userDataObj?.userData?.activeUserData?.userData?.userId,
    //                 statusUpdateDateTime: moment().format("YYYY-MM-DD HH:mm"),
    //                 reservationId: workOrderDetails?.installationDetail?.reservationId
    //             }

    //             UpdateWorkOrderDetails("/fibre/update/updateactivitydetails", updateLocationRequest, header).then(response => {
    //                 setPageState({ ...pageState, apiCallAction: false });
    //                 /*-- Update all Location Items --*/
    //                 // console.log(response);
    //                 if (response?.overallUpdateStatus === "SUCCESS") {
    //                     dispatch(updateWorkOrderDetailsData({
    //                         type: ApiConstant.INSTALLER_WORK_ORDER_ACTIVE_TAB,
    //                         value: 1
    //                     }));
    //                     dispatch(updateWorkOrderDetailsData({
    //                         type: ApiConstant.INSTALLER_ENABLED_LOCATION_BTN,
    //                         value: {
    //                             lattitude: updateLocationRequest?.lattitude || "-",
    //                             longitude: updateLocationRequest?.longitude || "-",
    //                             streetName: updateLocationRequest?.streetName || "-",
    //                             time: updateLocationRequest?.statusUpdateDateTime || "-"
    //                         }
    //                     }));
    //                 }
    //                 else {
    //                     toast.warning(ApiConstant.INSTALLER_LOCATION_ERROR_MESSAGE,
    //                         {
    //                             position: toast.POSITION.BOTTOM_RIGHT,
    //                             autoClose: 10000,
    //                             theme: 'colored'
    //                         }
    //                     );
    //                 }

    //             }).catch(error => {
    //                 setPageState({ ...pageState, apiCallAction: false });
    //                 if (error.response.status === 500 || error.response.status === 503) {
    //                     toast.error(ApiConstant.INSTALLER_LOCATION_ERROR_MESSAGE,
    //                         {
    //                             position: toast.POSITION.BOTTOM_RIGHT,
    //                             autoClose: 10000,
    //                             theme: 'colored'
    //                         }
    //                     );
    //                 } else {
    //                     toast.warning(ApiConstant.INSTALLER_LOCATION_ERROR_MESSAGE,
    //                         {
    //                             position: toast.POSITION.BOTTOM_RIGHT,
    //                             autoClose: 10000,
    //                             theme: 'colored'
    //                         }
    //                     );
    //                 }
    //             })
    //         }).catch(() => {
    //             setPageState({ ...pageState, apiCallAction: false });
    //         })
    // }).catch(() => {
    //     setPageState({ ...pageState, apiCallAction: false });
    // });
  };
  return (
    <div
      className="modal fade next-page-confirmation-modal"
      id="updateWorkOrderEnabledLocationModal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby="updateWorkOrderEnabledLocationModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pb-0">
            <div className="wrapper-modal-header pt-5">
              <div className="modal-header-title-section">
                <LocationIcon />
                <h5
                  className="modal-title"
                  id="updateWorkOrderEnabledLocationModalLabel"
                >
                  {Constent.CONFIRMATION_MODAL_TITLE}
                </h5>
                <h5 className="modal-title">
                  Enable your <strong>Live location</strong> and proceed.
                </h5>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <CloseModalIcon />
                </span>
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="wrapper-modal-body"></div>
          </div>
          <div className="modal-footer">
            <div className="refresh-btn-section" data-dismiss="modal">
              <span>{Constent.CANCEL_BTN}</span>
            </div>
            <div
              className="search-btn-section"
              data-dismiss="modal"
              onClick={EnableLocationBtn}
            >
              {Constent.ENABLE_BTN}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateWorkOrderEnabledLocationModal;
