import React from 'react';
import { Constent } from '../../../../Assets/Constants/Constent';
import { SearchIcon } from '../../../../Assets/Images/CelcomSvgImages';
import { BodyBreadcrumbs } from '../../../Common/BodyBreadcrumbs';
import { BackBtn } from '../../../Common/Button';

const SwitchUserBodyHeader = ({ pageState, setPageState, globalState }) => {

    return (
        <div className={globalState?.sidebarState ?
            'switch-user-header-start-section switch-user-header-sidebar-active'
            :
            'switch-user-header-start-section'}>
            <div className="wrapper-switch-user-header-section">
                <BodyBreadcrumbs activeItem={`${Constent.BODY_HEADER_SWITCH_USER}`} />
                <div className="switch-user-header-item-group-section">
                    <div className="switch-user-header-item-section">
                        {Constent.BODY_HEADER_SWITCH_USER}
                    </div>
                </div>
            </div>
            <div className="wrapper-switch-user-header-section">
                <div className="switch-user-header-item">
                    <BackBtn />
                </div>
                <div className="switch-user-header-item">
                    <div className="switch-user-header-inner-item-section">

                        <div className="switch-user-header-inner-item">
                            <div className="form-group m-0 custom-drop-down-form-group">
                                <select className="custom-select"
                                // onChange={(e) => console.log(e)}
                                >
                                    <option defaultValue="">{Constent.SELECT_SUPERVISOR_INPUT_ITEM_VALUE}</option>
                                    <option value="Month">{Constent.SELECT_SUPERVISOR_INPUT_ITEM_VALUE}</option>
                                </select>
                                <label htmlFor="exampleSelect1">{Constent.USER_TYPE_INPUT_ITEM_LABEL}</label>
                            </div>
                        </div>
                        <div className="switch-user-header-inner-item">
                            <div className="form-group mb-0 custom-input-form-group ">
                                <input type="text" className="form-control" id="supervisorId" placeholder=" "
                                    value={pageState?.filterUserName} onChange={e => {
                                        setPageState({ ...pageState, filterUserName: e.target.value });
                                    }} />
                                <label htmlFor="supervisorId">{Constent.SELECT_SUPERVISOR_INPUT_ITEM_LABEL}</label>
                                <span className='invalid-msg-text'>{Constent.INVALID_INPUT}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="switch-user-header-item"></div>
            </div>
        </div>
    )
}

export default SwitchUserBodyHeader;