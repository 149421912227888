import React, { useState } from "react";
import { ApiConstant } from "../../../../Assets/Constants/ApiConstants";
import { Constent } from "../../../../Assets/Constants/Constent";
import {
  CalendarIcon,
  FilterIcon,
  SearchIcon,
} from "../../../../Assets/Images/CelcomSvgImages";
import {
  DonloadExcelFile,
  EmptyValueHandler,
  ViewStockItemDetailsStatusCal,
} from "../../../Common/GenericFunctions";
import { TableRowUpDownArrow } from "../../../Common/Table";
import { CardDetailsLoader } from "../../../Loaders/Loaders";
import ViewStockDetailsCardContent from "./InventoryHelper/ViewStockDetailsCardContent";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import DatePicker from "react-date-picker";
import moment from "moment";
import { NoDataFoundItemGroup } from "../../../Common/CardContent";

const InventoryViewStockBodyContent = ({
  userDataObj,
  globalState,
  pageState,
  setPageState,
  selectedCardItem,
  selectedCardItemDetailsList,
  selectedCardItemDetails,
}) => {
  const [activeTab, setActiveTab] = useState(
    ApiConstant.INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE1
  );

  const filterList = selectedCardItemDetailsList?.filter((item) => {
    if (
      (activeTab?.toLowerCase() === "" ||
        item?.status?.toLowerCase()?.includes(activeTab?.toLowerCase())) &&
      ((pageState?.filterSerialNumber === "" && pageState?.expiryDate === "") ||
        (item?.uin?.includes(pageState?.filterSerialNumber) &&
          item?.expiryDate?.includes(pageState?.expiryDate)))
    ) {
      return item;
    }
  });

  function downloadExcelFun() {
    //Sl, Material Code Item Name, Item Serial Number, Expiry Date, Status
    const downloadData = [];

    selectedCardItemDetailsList?.map((data, index) => {
      if (data?.uin) {
        const { status } = ViewStockItemDetailsStatusCal(data || "");
        downloadData.push({
          SL: index + 1,
          "Material Code": EmptyValueHandler({
            item: selectedCardItem?.sAPMaterialCode,
          }),
          "Item Name": EmptyValueHandler({ item: selectedCardItem?.itemName }),
          "Item Serial Numbers": EmptyValueHandler({ item: data?.uin }),
          "Expiry Date": EmptyValueHandler({ item: data?.expiryDate }),
          Status: EmptyValueHandler({ item: status }),
        });
      }
    });
    const fileName = "View-Stock_" + selectedCardItem?.sAPMaterialCode;

    /* Download Excel Function */
    DonloadExcelFile({
      fileName: fileName,
      downloadData: downloadData,
    });
  }

  return (
    <div className="view-stock-details-body-content-section">
      <div className="view-stock-content-row-section">
        <ViewStockDetailsCardContent
          activeTab={activeTab}
          selectedCardItemDetails={selectedCardItemDetails}
          pageState={pageState}
          setActiveTab={setActiveTab}
          selectedCardItem={selectedCardItem}
          downloadExcelFun={downloadExcelFun}
          selectedCardItemDetailsList={selectedCardItemDetailsList}
        />
      </div>
      <div
        className="view-stock-content-row-section"
        data-aos="fade-right"
        data-aos-easing="ease-in-out"
        data-aos-delay="200"
      >
        <div className="wrapper-view-stock-row-section">
          <div className="view-stock-col-item-section"></div>
          {/* Main Content */}
          {pageState?.dataLoader ? (
            <CardDetailsLoader />
          ) : pageState?.getDataError ? (
            <NoDataFoundItemGroup message={pageState?.getDataErrorMsg} />
          ) : (
            <div className="view-stock-col-item-section">
              <div className="view-stock-col-item-inner-section">
                <div className="view-stock-filter-title-section">
                  <FilterIcon />
                  <span>{Constent.INVENTORY_FILTER_SUB_ITEM_TITLE1}</span>
                </div>
                <div className="view-stock-filter-input-group-section mt-3">
                  <div className="form-group mb-0 custom-input-form-group">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          setPageState({
                            ...pageState,
                            filterSerialNumber: e.target.value,
                          });
                        }}
                        value={pageState?.filterSerialNumber}
                        id={Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL1}
                        placeholder=" "
                      />
                      <label
                        htmlFor={Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL1}
                      >
                        {Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL1}
                      </label>
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <SearchIcon stroke={"#009BDF"} />
                        </span>
                      </div>
                    </div>
                    <span className="invalid-msg-text">
                      {Constent.INVALID_INPUT}
                    </span>
                  </div>

                  <div className="form-group mb-0 custom-input-form-group">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          setPageState({
                            ...pageState,
                            expiryDate: e.target.value,
                          });
                        }}
                        value={pageState?.expiryDate}
                        id={Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL3}
                        placeholder=" "
                      />
                      <label
                        htmlFor={Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL3}
                      >
                        {Constent.INVENTORY_FILTER_INPUT_ITEM_LABEL3}
                      </label>
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <CalendarIcon fill={"#333333"} />
                        </span>
                        {/* <div className="input-group-append-date-picker-section">
                                                        <DatePicker selected={moment()} 
                                                        onChange={date => { setPageState({ ...pageState, expiryDate: moment(date)?.format("MM-DD-YYYY") })}}  />
                                                    </div> */}
                      </div>
                    </div>
                    <span className="invalid-msg-text">
                      {Constent.INVALID_INPUT}
                    </span>
                  </div>
                </div>
              </div>
              <div className="view-stock-col-item-inner-section">
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th>
                        <span>{Constent.VIEW_STOCK_TABLE_ITEM_TITLE1}</span>
                        <TableRowUpDownArrow />
                      </th>
                      <th>
                        <span>{Constent.VIEW_STOCK_TABLE_ITEM_TITLE2}</span>
                        <TableRowUpDownArrow />
                      </th>
                      <th>
                        <span>{Constent.VIEW_STOCK_TABLE_ITEM_TITLE3}</span>
                        <TableRowUpDownArrow />
                      </th>
                      <th>
                        <span>{Constent.VIEW_STOCK_TABLE_ITEM_TITLE4}</span>
                        <TableRowUpDownArrow />
                      </th>
                    </tr>
                  </thead>
                  {
                    <tbody>
                      {filterList?.length > 0 ? (
                        filterList?.map((data, index) => {
                          const { status, className } =
                            ViewStockItemDetailsStatusCal(data || "");

                          return (
                            <tr className={className} key={index}>
                              <th
                                data-title={
                                  Constent.VIEW_STOCK_TABLE_ITEM_TITLE1
                                }
                              >
                                {index + 1}
                              </th>
                              <td
                                data-title={
                                  Constent.VIEW_STOCK_TABLE_ITEM_TITLE2
                                }
                              >
                                {data?.uin || "-"}
                              </td>
                              <td
                                data-title={
                                  Constent.VIEW_STOCK_TABLE_ITEM_TITLE3
                                }
                              >
                                {data?.expiryDate || "-"}
                              </td>
                              <td
                                data-title={
                                  Constent.VIEW_STOCK_TABLE_ITEM_TITLE4
                                }
                              >
                                <span>{status || "-"}</span>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  }
                </table>
              </div>
            </div>
          )}

          <div className="view-stock-col-item-section"></div>
        </div>
      </div>
    </div>
  );
};

export default InventoryViewStockBodyContent;
