import React from "react";
import { useDispatch } from "react-redux";
import { ApiConstant } from "../../../../../Assets/Constants/ApiConstants";
import { Constent } from "../../../../../Assets/Constants/Constent";
import { SearchIcon } from "../../../../../Assets/Images/CelcomSvgImages";
import {
  setStockListData,
  setViewStockFieldValue,
} from "../../../../../ReduxFeature/StateFeatures/InventoryDataState";

const InventoryBodyHeaderManageStockGroup = ({
  userDataObj,
  invCategoryBrandLov,
  pageState,
  setPageState,
  inventoryApiCalls,
  invDataObj,
}) => {
  const {
    viewStockCategoryValue,
    viewStockBrandValue,
    viewStockInputValue,
    brandLov,
  } = invDataObj;

  const dispatch = useDispatch();
  return (
    <div className="inventory-header-search-group-row-section">
      <div className="inventory-search-group-section">
        <div className="form-group m-0 custom-drop-down-form-group">
          <select
            className="custom-select"
            value={viewStockCategoryValue}
            disabled={viewStockInputValue?.length > 0}
            onChange={(e) => {
              const index = invCategoryBrandLov?.findIndex(
                (obj) => obj?.value === e.target.value
              );
              dispatch(
                setViewStockFieldValue({
                  type: "viewStockCategoryValue",
                  value: e.target.value,
                })
              );
              dispatch(
                setViewStockFieldValue({
                  type: "viewStockBrandValue",
                  value: "",
                })
              );

              dispatch(
                setStockListData({
                  type: ApiConstant.INVENTORY_REDUX_STOCK_LIST_TYPE3,
                  list: index === -1 ? [] : invCategoryBrandLov[index]?.brand,
                })
              );
            }}
          >
            <option defaultValue="">-- Select --</option>
            <option defaultValue="" value="">
              -- Select --
            </option>
            {invCategoryBrandLov?.map((categoryData, index) => {
              return (
                <option
                  value={categoryData?.value}
                  key={index + categoryData?.value + categoryData?.display}
                >
                  {categoryData?.display}
                </option>
              );
            })}
          </select>
          <label htmlFor={Constent.PRODUCT_CATAGORY_INPUT_ITEM_LABEL}>
            {Constent.PRODUCT_CATAGORY_INPUT_ITEM_LABEL}
          </label>
        </div>
      </div>
      <div className="inventory-search-group-section">
        <div className="form-group m-0 custom-drop-down-form-group">
          <select
            className="custom-select"
            value={viewStockBrandValue}
            disabled={
              viewStockCategoryValue?.length === 0 ||
              viewStockInputValue?.length > 0
            }
            onChange={(e) => {
              dispatch(
                setViewStockFieldValue({
                  type: "viewStockBrandValue",
                  value: e.target.value,
                })
              );
            }}
          >
            <option defaultValue="">-- Select --</option>
            {brandLov?.map((brandData, index) => {
              return (
                <option
                  value={brandData?.value}
                  key={index + brandData?.value + brandData?.display}
                >
                  {brandData?.display}
                </option>
              );
            })}
          </select>
          <label htmlFor={Constent.BRAND_INPUT_ITEM_LABEL}>
            {Constent.BRAND_INPUT_ITEM_LABEL}
          </label>
        </div>
      </div>
      <div className="inventory-search-group-section">
        {Constent.FREE_CONTENT_TEXT2}
      </div>
      <div className="inventory-search-group-section">
        <div className="form-group mb-0 custom-input-form-group">
          <input
            type="text"
            className="form-control"
            id="metrialCodeOrItemName"
            value={viewStockInputValue}
            placeholder=" "
            onChange={(e) => {
              dispatch(
                setViewStockFieldValue({
                  type: "viewStockInputValue",
                  value: e.target.value,
                })
              );
            }}
          />
          <label htmlFor="metrialCodeOrItemName">
            {Constent.MATERIAL_CODE_OR_ITEM_NAME_INPUT_ITEM_LABEL}
          </label>
          <span className="invalid-msg-text">{Constent.INVALID_INPUT}</span>
        </div>
      </div>
      <div
        className="inventory-search-group-section"
        onClick={inventoryApiCalls}
      >
        <SearchIcon />
        {Constent.SEARCH_BTN}
      </div>
    </div>
  );
};

export default InventoryBodyHeaderManageStockGroup;
