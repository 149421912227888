import React from 'react';
import { FullCalendarDateList } from '../../../Common/GenericFunctions';
import WorkOrderCard from '../../Cards/Supervisor/WorkOrderCard';
import BigCalendar from './DashBordHalper/BigCalendar';
import BigCalendarListView from './DashBordHalper/BigCalendarListView';

const DashbordBodyContent = ({ userDataObj, globalState, pageState, setPageState, index, MoveSearchWorkOrderNav }) => {
    const calendar = FullCalendarDateList(pageState?.calendarDate);
    

    return (
        <div className='body-content-section'>
            {
                pageState?.bodyview === "Day" ?
                    <div className={pageState?.toggleIcon === "List" ?
                        "wrapper-workorder-body-content-section"
                        :
                        globalState?.sidebarState ?
                            "wrapper-workorder-body-content-section custom-card-3"
                            :
                            "wrapper-workorder-body-content-section custom-card-4"} >

                        {
                            index !== -1 ? pageState?.calendarViewData[index]?.workOrderList?.map((workOrderData, workOrderIndex) => {
                                return <WorkOrderCard key={`calendar-day-view${workOrderData?.itemId + workOrderIndex}`}
                                    globalState={globalState} pageState={pageState}
                                    setPageState={setPageState} workOrderData={workOrderData} />
                            })
                                :
                                <>No Work Order Available</>
                        }

                    </div>
                    :
                    pageState?.bodyview === "Month" && pageState?.toggleIcon !== "List" ?
                        <BigCalendar calendar={calendar} pageState={pageState} MoveSearchWorkOrderNav={MoveSearchWorkOrderNav} />
                        :
                        <BigCalendarListView calendar={calendar} globalState={globalState}
                            pageState={pageState} setPageState={setPageState} MoveSearchWorkOrderNav={MoveSearchWorkOrderNav} />

            }

        </div>
    )
}

export default DashbordBodyContent;