import { useNavigate } from "react-router-dom";
import { Constent } from "../../Assets/Constants/Constent";
import { DownloadIcon, LeftArrowIcon } from "../../Assets/Images/CelcomSvgImages";

export const BackBtn = ({ navLink }) => {
    const navigate = useNavigate();
    return (
        <div className="back-btn-section" onClick={() => {
            navigate(navLink || "/dashboard");
        }}>
            <LeftArrowIcon stroke={"#009BDF"} />
            {Constent?.BACK_BTN}
        </div>
    );
}

export const DownloadBtn = () => {
    return (
        <div className="download-btn-section">
            <span>{Constent.DOWNLOAD_BTN}</span>
            <DownloadIcon />
        </div>
    );
}

export const ConfirmationBackBtn = ({ btnItemName, btnRoute }) => {
    const navigate = useNavigate();
    return (
        <div className="confirmation-back-btn-section px-3"  onClick={()=> navigate(btnRoute || "/dashboard")}>
            <span>{`Back to ${btnItemName}`}</span>
        </div>
    );
}

export const PlaceOrderkBtn = ({ btnItemName, btnRoute }) => {
    const navigate = useNavigate();

    return (
        <div className="place-order-btn-section" onClick={()=> navigate(btnRoute || "/dashboard")}>
            <span>{`${btnItemName}`}</span>
        </div>
    );
}
