import moment from 'moment';
import React from 'react';
import { Constent } from '../../../../../Assets/Constants/Constent';
import { RightArrowIcon } from '../../../../../Assets/Images/CelcomSvgImages';

const BigCalendar = ({ calendar, pageState, MoveSearchWorkOrderNav }) => {
    const today = moment();
    const dataObjList = pageState?.calendarViewData;

    return (
        <div className='custom-big-calendar-section'>
            {
                calendar?.map((week, weekIndex) => {
                    return (
                        <div className="custom-big-calendar-week-section" key={`week${weekIndex}`}>
                            {
                                week?.map((day, dayIndex) => {
                                    const index = dataObjList?.findIndex(obj => obj?.date?.split(" ")[0] === day?.format("DD-MM-YYYY"));

                                    return (
                                        <div className={day?.format("YYYY-MM-DD ddd") === today?.format("YYYY-MM-DD ddd") ?
                                            "custom-big-calendar-day-section today-date-section"
                                            :
                                            "custom-big-calendar-day-section"} key={`day${day?.format("YYYY-MM-DD") + dayIndex}`}>
                                            <div className="custom-big-calendar-day-item-section">
                                                <div className="custom-big-calendar-day-item">
                                                    {day?.format("D")}
                                                </div>
                                                <div className="custom-big-calendar-day-item">
                                                    {day?.format("ddd")}
                                                </div>
                                            </div>
                                            <div className="custom-big-calendar-day-item-section">
                                                <div className="custom-big-calendar-day-item">
                                                    {
                                                        index !== -1 && dataObjList[index]?.totalAssignedCount > 0
                                                        && <div className="custom-big-calendar-day-item-group-section"
                                                            onClick={() => MoveSearchWorkOrderNav(dataObjList[index]?.workOrderList, day)}>
                                                            <div className="custom-big-calendar-day-item-group-sub-item">
                                                                {`${dataObjList[index]?.totalAssignedCount} - ${Constent.BIG_CALENDAR_ASSIGN_TYPE_LABEL}`}
                                                            </div>
                                                            <div className="custom-big-calendar-day-item-group-sub-item">
                                                                {
                                                                    dataObjList[index]?.assignedCount?.osiActivityCount > 0
                                                                    && <div className="custom-big-calendar-day-item-group-sub-item-value-section"
                                                                        onClick={() => MoveSearchWorkOrderNav(dataObjList[index]?.workOrderList, day)}>
                                                                        <div className="custom-big-calendar-day-item-group-sub-item-value">
                                                                            {`${dataObjList[index]?.assignedCount?.osiActivityCount}`}
                                                                        </div>
                                                                        <div className="custom-big-calendar-day-item-group-sub-item-value">
                                                                            {Constent.BIG_CALENDAR_OSI_LABEL}<RightArrowIcon stroke={"#009BDF"} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    dataObjList[index]?.assignedCount?.ostActivityCount > 0
                                                                    && <div className="custom-big-calendar-day-item-group-sub-item-value-section"
                                                                        onClick={() => MoveSearchWorkOrderNav(dataObjList[index]?.workOrderList, day)}>
                                                                        <div className="custom-big-calendar-day-item-group-sub-item-value">
                                                                            {`${dataObjList[index]?.assignedCount?.ostActivityCount}`}
                                                                        </div>
                                                                        <div className="custom-big-calendar-day-item-group-sub-item-value">
                                                                            {Constent.BIG_CALENDAR_OST_LABEL}
                                                                            <RightArrowIcon stroke={"#009BDF"} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    dataObjList[index]?.assignedCount?.btuActivityCount > 0
                                                                    && <div className="custom-big-calendar-day-item-group-sub-item-value-section"
                                                                        onClick={() => MoveSearchWorkOrderNav(dataObjList[index]?.workOrderList, day)}>
                                                                        <div className="custom-big-calendar-day-item-group-sub-item-value">
                                                                            {`${dataObjList[index]?.assignedCount?.btuActivityCount}`}
                                                                        </div>
                                                                        <div className="custom-big-calendar-day-item-group-sub-item-value">
                                                                            {Constent.BIG_CALENDAR_BTU_LABEL}<RightArrowIcon stroke={"#009BDF"} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="custom-big-calendar-day-item">
                                                    {
                                                        index !== -1 && dataObjList[index]?.totalUnassignedCount > 0
                                                        && <div className="custom-big-calendar-day-item-group-section"
                                                            onClick={() => MoveSearchWorkOrderNav(dataObjList[index]?.workOrderList, day)}>
                                                            <div className="custom-big-calendar-day-item-group-sub-item">
                                                                {`${dataObjList[index]?.totalUnassignedCount} - ${Constent.BIG_CALENDAR_UNASSIGN_TYPE_LABEL}`}
                                                            </div>
                                                            <div className="custom-big-calendar-day-item-group-sub-item">
                                                                {
                                                                    dataObjList[index]?.unassignedCount?.osiActivityCount > 0
                                                                    && <div className="custom-big-calendar-day-item-group-sub-item-value-section"
                                                                        onClick={() => MoveSearchWorkOrderNav(dataObjList[index]?.workOrderList, day)}>
                                                                        <div className="custom-big-calendar-day-item-group-sub-item-value">
                                                                            {`${dataObjList[index]?.unassignedCount?.osiActivityCount}`}
                                                                        </div>
                                                                        <div className="custom-big-calendar-day-item-group-sub-item-value">
                                                                            {Constent.BIG_CALENDAR_OSI_LABEL}
                                                                            <RightArrowIcon stroke={"#009BDF"} />
                                                                        </div>
                                                                    </div>

                                                                }
                                                                {
                                                                    dataObjList[index]?.unassignedCount?.ostActivityCount > 0
                                                                    &&
                                                                    <div className="custom-big-calendar-day-item-group-sub-item-value-section"
                                                                        onClick={() => MoveSearchWorkOrderNav(dataObjList[index]?.workOrderList, day)}>
                                                                        <div className="custom-big-calendar-day-item-group-sub-item-value">
                                                                            {`${dataObjList[index]?.unassignedCount?.ostActivityCount}`}
                                                                        </div>
                                                                        <div className="custom-big-calendar-day-item-group-sub-item-value">
                                                                            {Constent.BIG_CALENDAR_OST_LABEL}
                                                                            <RightArrowIcon stroke={"#009BDF"} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    dataObjList[index]?.unassignedCount?.btuActivityCount > 0
                                                                    && <div className="custom-big-calendar-day-item-group-sub-item-value-section"
                                                                        onClick={() => MoveSearchWorkOrderNav(dataObjList[index]?.workOrderList, day)}>
                                                                        <div className="custom-big-calendar-day-item-group-sub-item-value">
                                                                            {`${dataObjList[index]?.unassignedCount?.btuActivityCount}`}
                                                                        </div>
                                                                        <div className="custom-big-calendar-day-item-group-sub-item-value">
                                                                            {Constent.BIG_CALENDAR_BTU_LABEL}
                                                                            <RightArrowIcon stroke={"#009BDF"} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default BigCalendar