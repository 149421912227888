import React from 'react';

import '../../Assets/CSS/Loader.css'

export const ApiActionLoader = ({ msg="Loading" }) => {
    return (
        <div className='api-action-loader-item-section'>
            <div className="api-action-loader-item">
            </div>
            <div className="api-action-loader-item">
                {msg}
                <br/>
                <span>Please Wait</span>
            </div>
        </div>
    )
}
