import React from 'react';
import { Constent } from '../../Assets/Constants/Constent';
import { CloseModalIcon, PersonalDetailsIcon, ProfileIcon } from '../../Assets/Images/CelcomSvgImages';
import { EmptyValueHandler } from '../Common/GenericFunctions';

const PersonalDetailsModal = ({ activeUserData }) => {
    const data = activeUserData?.userData?.address.split(",")
    return (
        <div className="modal fade advance-search-work-order-modal" id="personalDetailsModal" data-backdrop="static"
            data-keyboard="false" tabIndex="-1" aria-labelledby="personalDetailsModalLabel"
            aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header pb-0">
                        <div className="wrapper-modal-header pt-5">
                            <div className="modal-header-title-section">
                                <PersonalDetailsIcon />
                                <h5 className="modal-title" id="personalDetailsModalLabel">
                                    {Constent.PROFILE_INFO_PERSONAL_DETAILS}
                                </h5>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <CloseModalIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="wrapper-modal-body">
                            <div className="modal-body-item-section">
                                <div className="navbar-profile-img">
                                    <ProfileIcon />
                                </div>
                            </div>
                            <div className="modal-body-item-section">
                                <div className="profile-details-item">
                                    <div className="profile-details-item-title">
                                        {Constent.PROFILE_INFO_USER_NAME}
                                    </div>
                                    <div className="profile-details-item-value">
                                        {EmptyValueHandler({ item: activeUserData?.userData?.fullName })}
                                    </div>
                                </div>
                                <div className="profile-details-item">
                                    <div className="profile-details-item-title">
                                        {Constent.PROFILE_INFO_USERID}
                                    </div>
                                    <div className="profile-details-item-value">
                                        {EmptyValueHandler({ item: activeUserData?.userData?.userId })}
                                    </div>
                                </div>
                                <div className="profile-details-item">
                                    <div className="profile-details-item-title">
                                        {Constent.PROFILE_INFO_OUTLET}
                                    </div>
                                    <div className="profile-details-item-value">
                                        {EmptyValueHandler({ item: activeUserData?.userData?.outletId })}
                                    </div>
                                </div>
                                <div className="profile-details-item">
                                    <div className="profile-details-item-title">
                                        {Constent.PROFILE_INFO_ADDRESS}
                                    </div>
                                    <div className="profile-details-item-value">
                                        {EmptyValueHandler({ item: data[2] + " " + data[0] + " " + data[1] })}
                                    </div>
                                </div>
                                <div className="profile-details-item">
                                    <div className="profile-details-item-title">
                                        {Constent.PROFILE_INFO_CONTACT}
                                    </div>
                                    <div className="profile-details-item-value">
                                        {EmptyValueHandler({ item: activeUserData?.userData?.contactNumber })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonalDetailsModal;