import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from 'redux';
import storage from "redux-persist/lib/storage";
import { persistReducer } from 'redux-persist';
import userReducer from './StateFeatures/UserDataState.js';
import inventoryReducer from './StateFeatures/InventoryDataState.js';
import cartReducer from './StateFeatures/CartDataState.js';
import workOrderReducer from './StateFeatures/WorkOrderDataState.js';
import lovDataReducer from "./StateFeatures/LovDataState.js";
import trackOrderDataReducer from "./StateFeatures/TrackOrderDataState.js";
import b2cDataReducer from "./StateFeatures/B2CDataState.js";
import installerDataReducer from "./StateFeatures/InstallerDataState.js";


import thunk from "redux-thunk";

const rootReducer = combineReducers({
    userDataInfo: userReducer,
    b2cDataInfo: b2cDataReducer,
    trackOrderDataInfo: trackOrderDataReducer,
    installerDataInfo: installerDataReducer,
    inventoryDataInfo: inventoryReducer,
    cartDataInfo: cartReducer,
    workOrderInfo: workOrderReducer,
    lovDataInfo: lovDataReducer,
});

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
};


const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
});
