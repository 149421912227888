import React, { useState } from "react";
import { Constent } from "../../Assets/Constants/Constent";
import {
  AppoinmentSubTypeConverter,
  AppoinmentTypeConverter,
  AssignedToSameAsUserId,
} from "../Common/GenericFunctions";
import {
  BackArrowIcon,
  CloseModalIcon,
} from "../../Assets/Images/CelcomSvgImages";
import { SupervisorWorkOrderStatusCal } from "../Common/GenericFunctions";
import { AssignWorkOrderModalBodyTextContent } from "../Common/ModalHelper";
import { useEffect } from "react";

const AssignWorkOrderModal = ({
  pageState,
  setPageState,
  workOrderDetails,
  selectedWorkOrderItem,
  assignInstallerLov,
  reassignActivityApiCall,
  userDataObj,
  supervisorListWithUserGroup,
  setSupervisorListWithUserGroup,
}) => {
  const [listOfSupervisors, setlistOfSupervisors] = useState(
    userDataObj?.userData?.activeUserData?.userData?.listOfSupervisors
  );
  const listOfOutletId = Object.keys(supervisorListWithUserGroup);

  const { status } = SupervisorWorkOrderStatusCal(selectedWorkOrderItem) || {
    status: "",
    addClass: "",
  };

  // useEffect(()=>{
  //     if(pageState?.selectedOutletId?.length > 0){
  //         setPageState({...pageState, listOfSupervisorsForAdmin: supervisorListWithUserGroup[pageState?.selectedOutletId]})
  //     }

  // }, [pageState?.selectedOutletId]);

  return (
    <div
      className="modal fade assign-work-order-modal"
      id="assignWorkOrder"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby="assignWorkOrderLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pb-0">
            <div className="wrapper-modal-header">
              <div className="modal-header-title-section">
                <h5 className="modal-title" id="assignWorkOrderLabel">
                  {userDataObj?.userData?.activeUserData?.userRole ===
                  Constent.ADMIN_ROLE
                    ? Constent.ASSIGN_WORK_ORDER_MODAL_SUPERADMIN_TITLE
                    : Constent.ASSIGN_WORK_ORDER_MODAL_TITLE}
                </h5>
                {userDataObj?.userData?.activeUserData?.userRole ===
                Constent.ADMIN_ROLE ? (
                  ""
                ) : (
                  <>
                    <div className="wrapper-custom-control">
                      <span>
                        {Constent.ASSIGN_WORK_ORDER_MODAL_REVERT_BACK_LABEL}
                      </span>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          checked={pageState?.isRevertBack ? true : false}
                          className="custom-control-input"
                          id="revertBackBtn"
                          onChange={(e) => {
                            setPageState({
                              ...pageState,
                              isRevertBack: e.target.checked,
                              state: "",
                              district: "",
                              assignTo: "",
                              comment: "",
                            });
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="revertBackBtn"
                        ></label>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <CloseModalIcon />
                </span>
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="wrapper-modal-body">
              {/* 1st And 2nd Row with information */}
              <AssignWorkOrderModalBodyTextContent
                title1={Constent.WORK_ORDER_CARD_ITEM_TITLE1}
                title2={Constent.WORK_ORDER_CARD_ITEM_TITLE2}
                title3={Constent.WORK_ORDER_CARD_ITEM_TITLE3}
                title4={Constent.WORK_ORDER_CARD_ITEM_TITLE9}
                value1={
                  selectedWorkOrderItem?.itemId
                    ?.replaceAll("FieldActivity-", "")
                    ?.trim() || "-"
                }
                value2={AppoinmentTypeConverter({
                  type:
                    workOrderDetails?.itemTypeID?.replaceAll("_", " ") || "-",
                })}
                value3={AppoinmentSubTypeConverter({
                  subType:
                    workOrderDetails?.itemSubTypeId?.replaceAll("_", " ") ||
                    "-",
                  type:
                    workOrderDetails?.itemTypeID?.replaceAll("_", " ") || "-",
                })}
                value4={
                  AssignedToSameAsUserId(
                    selectedWorkOrderItem,
                    userDataObj?.userData
                  ) ||
                  selectedWorkOrderItem?.assignedTo === null ||
                  selectedWorkOrderItem?.assignedTo === undefined ||
                  selectedWorkOrderItem?.assignedTo === "" ||
                  userDataObj?.userData?.activeUserData?.userRole ===
                    Constent.ADMIN_ROLE
                    ? "-"
                    : selectedWorkOrderItem?.assignedTo
                }
              />
              <AssignWorkOrderModalBodyTextContent
                title1={Constent.WORK_ORDER_CARD_ITEM_TITLE5}
                title2={Constent.WORK_ORDER_CARD_ITEM_TITLE4}
                title3={Constent.WORK_ORDER_CARD_ITEM_TITLE8}
                title4={Constent.WORK_ORDER_CARD_ITEM_TITLE7}
                value1={selectedWorkOrderItem?.district || "-"}
                value2={selectedWorkOrderItem?.resolutionDate || "-"}
                value3={
                  selectedWorkOrderItem?.city ||
                  "" + ", " + selectedWorkOrderItem?.street ||
                  ""
                }
                value4={status || ""}
              />
              {/* 3rd Row Drop Down Section */}
              {
                // sueper admin view show only assign
                userDataObj?.userData?.activeUserData?.userRole ===
                Constent.ADMIN_ROLE ? (
                  <>
                    <div className="revart-back-modal-card-item-group assign-supervisor-modal-card-item-group">
                      <div className="card-item-group">
                        <div className="form-group m-0 custom-drop-down-form-group">
                          <select
                            className="custom-select"
                            value={pageState?.selectedOutletId}
                            onChange={(e) => {
                              setPageState({
                                ...pageState,
                                selectedOutletId: e.target.value,
                                listOfSupervisorsForAdmin:
                                  supervisorListWithUserGroup[e.target.value],
                                supervisor: "",
                              });
                            }}
                          >
                            <option defaultValue="">-- Select --</option>
                            {listOfOutletId?.length > 0 &&
                              listOfOutletId?.sort()?.map((outletId, index) => {
                                return (
                                  <option
                                    key={`outletsupervisorlist${
                                      index + outletId
                                    }`}
                                    value={outletId}
                                  >
                                    {outletId}
                                  </option>
                                );
                              })}
                          </select>
                          <label htmlFor="exampleSelect1">
                            {Constent.SELECT_OUTLET}
                          </label>
                        </div>
                      </div>
                      <div className="card-item-group">
                        <div className="form-group m-0 custom-drop-down-form-group">
                          <select
                            className="custom-select"
                            disabled={pageState?.selectedOutletId?.length === 0}
                            value={pageState?.supervisor}
                            onChange={(e) => {
                              setPageState({
                                ...pageState,
                                supervisor: e.target.value,
                              });
                            }}
                          >
                            <option defaultValue="">-- Select --</option>
                            {pageState?.listOfSupervisorsForAdmin &&
                              pageState?.listOfSupervisorsForAdmin
                                ?.sort()
                                ?.map((supervisor, index) => {
                                  return (
                                    <option
                                      key={`supervisorlist${index}`}
                                      value={supervisor?.userId}
                                    >
                                      {supervisor?.userId}
                                    </option>
                                  );
                                })}
                          </select>
                          <label htmlFor="exampleSelect1">
                            {Constent.REASSIGN_BACK_TO_SUPERVISOR}
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {pageState?.isRevertBack ? (
                      <div className="revart-back-modal-card-item-group">
                        <div className="card-item-group">
                          <div className="form-group m-0 custom-drop-down-form-group">
                            <select
                              className="custom-select"
                              onChange={(e) => {
                                setPageState({
                                  ...pageState,
                                  supervisor: e.target.value,
                                });
                              }}
                            >
                              <option defaultValue="">-- Select --</option>
                              {listOfSupervisors &&
                                listOfSupervisors?.map((supervisor, index) => {
                                  return (
                                    <option
                                      key={`supervisorlist${index}`}
                                      value={supervisor.userId}
                                    >
                                      {supervisor.userId}
                                    </option>
                                  );
                                })}
                            </select>
                            <label htmlFor="exampleSelect1">
                              {Constent.REVERT_BACK_TO_ADMIN}
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="assign-work-order-modal-card-item-group">
                        <div className="card-item-group">
                          <div className="form-group m-0 custom-drop-down-form-group">
                            <select
                              className="custom-select"
                              value={pageState?.state}
                              onChange={(e) => {
                                const index = assignInstallerLov?.findIndex(
                                  (obj) => obj?.lovValue === e.target.value
                                );
                                setPageState({
                                  ...pageState,
                                  state: e.target.value,
                                  district: "",
                                  assignTo: "",
                                  districtList:
                                    index === -1
                                      ? []
                                      : assignInstallerLov[index]?.districtList,
                                  installerList: [],
                                });
                              }}
                            >
                              <option defaultValue="">-- Select --</option>
                              {assignInstallerLov?.map((data, index) => {
                                return (
                                  <option
                                    value={data?.lovValue}
                                    key={
                                      data?.lovDisplay + data?.lovValue + index
                                    }
                                  >
                                    {data?.lovDisplay}
                                  </option>
                                );
                              })}
                            </select>
                            <label htmlFor={Constent.STATES_INPUT_ITEM_LABEL}>
                              {Constent.STATES_INPUT_ITEM_LABEL}
                            </label>
                          </div>
                        </div>
                        <div className="card-item-group">
                          <div className="form-group m-0 custom-drop-down-form-group">
                            <select
                              className="custom-select"
                              value={pageState?.district}
                              onChange={(e) => {
                                const index =
                                  pageState?.districtList?.findIndex(
                                    (obj) => obj?.lovValue === e.target.value
                                  );
                                setPageState({
                                  ...pageState,
                                  district: e.target.value,
                                  installerList:
                                    pageState?.districtList[index]
                                      ?.installerList,
                                  assignTo: "",
                                });
                              }}
                            >
                              <option defaultValue="">-- Select --</option>
                              {pageState?.districtList?.map((data, index) => {
                                return (
                                  <option
                                    value={data?.lovValue}
                                    key={`${
                                      data?.lovDisplay + data?.lovValue + index
                                    }`}
                                  >
                                    {data?.lovDisplay}
                                  </option>
                                );
                              })}
                            </select>
                            <label htmlFor={Constent.DISTRICT_INPUT_ITEM_LABEL}>
                              {Constent.DISTRICT_INPUT_ITEM_LABEL}
                            </label>
                          </div>
                        </div>
                        <div className="card-item-group">
                          <div className="form-group m-0 custom-drop-down-form-group">
                            <select
                              className="custom-select"
                              value={pageState?.assignTo}
                              onChange={(e) =>
                                setPageState({
                                  ...pageState,
                                  assignTo: e.target.value,
                                })
                              }
                            >
                              <option defaultValue="">-- Select --</option>
                              {pageState?.installerList?.map((data, index) => {
                                return (
                                  <option
                                    value={data?.userId}
                                    key={`assignTo${
                                      data?.userId + data?.firstName + index
                                    }`}
                                  >
                                    {data?.userId}
                                  </option>
                                );
                              })}
                            </select>
                            <label
                              htmlFor={Constent.ASSIGN_TO_INPUT_ITEM_LABEL}
                            >
                              {Constent.ASSIGN_TO_INPUT_ITEM_LABEL}
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )
              }

              {/* 4th Row Text area section */}
              <div className="assign-work-order-modal-card-item-group">
                {/* <div className="form-group m-0">
                                    <textarea className="form-control" value={pageState?.comment}
                                        id="assignActivityTextArea" placeholder=" "
                                        spellCheck="false"
                                        onChange={(e) => {
                                            setPageState({ ...pageState, comment: e.target.value })
                                        }}></textarea>
                                    <label htmlFor="assignActivityTextArea">{Constent.COMMENT_INPUT_ITEM_LABEL}</label>
                                </div> */}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div
              className="refresh-btn-section"
              onClick={() => {
                setPageState({
                  ...pageState,
                  isRevertBack: false,
                  state: "",
                  district: "",
                  assignTo: "",
                  comment: "",
                  districtList: [],
                  installerList: [],
                });
              }}
            >
              <span>{Constent.REFRESH_BTN}</span>
            </div>
            {pageState?.isRevertBack ? (
              <div
                className="revert-back-btn-section"
                data-dismiss="modal"
                onClick={reassignActivityApiCall}
              >
                <BackArrowIcon />
                <span>{Constent.REVERT_BTN}</span>
              </div>
            ) : (
              <div
                className="assign-btn-section"
                data-dismiss="modal"
                onClick={reassignActivityApiCall}
              >
                <span>{Constent.ASSIGN_BTN}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignWorkOrderModal;
