import React from "react";
import { ApiConstant } from "../../../../Assets/Constants/ApiConstants";
import { Constent } from "../../../../Assets/Constants/Constent";
import { BackBtn } from "../../../Common/Button";
import { CardItemGroup } from "../../../Common/CardContent";
import {
  DonloadExcelFile,
  EmptyValueHandler,
  TrackStockItemStatusCal,
} from "../../../Common/GenericFunctions";
import { CardDetailsLoader } from "../../../Loaders/Loaders";
import AdminTrackStockRequestDetailsCard from "../../Cards/Admin/AdminTrackStockRequestDetailsCard";

import { useDispatch } from "react-redux";
import { setTrackStockFieldValue } from "../../../../ReduxFeature/StateFeatures/InventoryDataState";
import TrackStockRequestItemContent from "../Supervisor/InventoryHelper/TrackStockRequestItemContent";
import { DownloadIcon } from "../../../../Assets/Images/CelcomSvgImages";

const AdminTrackStockRequestDetailsBodyContent = ({
  globalState,
  pageState,
  setPageState,
  selectedTrackStockItem,
  selectedTrackStockItemDetails,
  trackStockDetailsApproverComment,
}) => {
  const dispatch = useDispatch();

  const { className } = TrackStockItemStatusCal(
    selectedTrackStockItem?.transferStatus
  );

  const isItemClosed = !ApiConstant.TRACK_STOCK_APPROVE_BTN_STATUS.includes(
    selectedTrackStockItemDetails?.transferStatus
  );
  const downloadExcelFun = () => {
    const downloadItemDetails = selectedTrackStockItemDetails?.itemDetails;
    const fileName =
      ApiConstant.INVENTORY_TRACK_STOCK_REQUEST_TYPE?.replaceAll(" ", "-") +
      "_" +
      selectedTrackStockItem?.transferId;

    const downloadData = [];
    downloadItemDetails?.map((data, index) => {
      downloadData.push({
        SL: index + 1,
        "Material Code": EmptyValueHandler({ item: data?.sapMaterialCode }),
        "Item Name": EmptyValueHandler({ item: data?.deviceModelDesc }),
        Status: EmptyValueHandler({
          item: selectedTrackStockItem?.transferStatus,
        }),
        Type: EmptyValueHandler({ item: selectedTrackStockItem?.requestType }),
        Category: EmptyValueHandler({ item: data?.category }),
        Brand: EmptyValueHandler({ item: data?.manufacturer }),
        "Request Quantity": EmptyValueHandler({
          item: data?.requestedQuantity,
        }),
        "Approve Quantity": EmptyValueHandler({ item: data?.approvedQuantity }),
        "Received Quantity": EmptyValueHandler({
          item: data?.receivedQuantity,
        }),
        "Transferred Quantity": EmptyValueHandler({
          item: data?.transferQuantity,
        }),
      });
    });

    /* Download Excel Function */
    DonloadExcelFile({
      fileName: fileName,
      downloadData: downloadData,
    });
  };
  console.log(pageState?.trackStockActiveRadioBtn);

  return (
    <div className="body-content-section pt-0">
      <div
        className={`track-stock-request-details-content-section ${
          globalState?.sidebarState ? "sidebar-open" : ""
        }`}
      >
        <div className="track-stock-request-details-content-item-section"></div>
        {pageState?.dataLoader ? (
          <CardDetailsLoader />
        ) : (
          <div className="track-stock-request-details-content-item-section">
            {/* Title & Btn */}
            <div className="track-stock-request-details-inner-item-section">
              <div className="track-stock-request-details-item-section">
                <BackBtn navLink={"/inventory"} />
                <div className="track-stock-request-details-item">
                  {`${Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE1}: `}
                  <span>{selectedTrackStockItem?.transferId || "-"}</span>
                </div>
              </div>
              <div className="track-stock-request-details-item-section"></div>
              <div className="track-stock-request-details-item-section">
                <div
                  className="download-btn-section"
                  onClick={!pageState?.dataLoader ? downloadExcelFun : null}
                >
                  <span>{Constent.DOWNLOAD_BTN}</span>
                  <DownloadIcon />
                </div>
              </div>
            </div>
            <div className="track-stock-request-details-inner-item-section">
              <div className="track-stock-request-details-item-section">
                <CardItemGroup
                  title={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE1}
                  value={selectedTrackStockItem?.transferId || "-"}
                />
                {pageState?.trackStockActiveRadioBtn ===
                Constent.TRANSFER_STOCK_RADIO_BTN_LABLE ? (
                  <CardItemGroup
                    title={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE2}
                    value={
                      selectedTrackStockItem?.requestType ||
                      ApiConstant.INVENTORY_TRANSFER_STOCK_REQUEST_TYPE
                    }
                  />
                ) : (
                  <CardItemGroup
                    title={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE2}
                    value={
                      selectedTrackStockItem?.requestType ||
                      ApiConstant.INVENTORY_TRACK_STOCK_REQUEST_TYPE
                    }
                  />
                )}
                <CardItemGroup
                  title={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE3}
                  value={selectedTrackStockItem?.createDate || "-"}
                />
                <CardItemGroup
                  title={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE4}
                  value={selectedTrackStockItem?.transferFromStoreId || "-"}
                />
                <CardItemGroup
                  title={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE5}
                  value={selectedTrackStockItem?.transferToStoreId || "-"}
                />
                <CardItemGroup
                  title={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE6}
                  value={selectedTrackStockItem?.transferFromStoreName || "-"}
                />
                <CardItemGroup
                  title={Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE8}
                  value={selectedTrackStockItem?.transferToStoreId || "-"}
                />

                <div className="card-item-group">
                  <div className="card-item-title">
                    {Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE7}
                  </div>
                  <div className={`card-item-value ${className}`}>
                    {selectedTrackStockItem?.transferStatus || "-"}
                  </div>
                </div>
              </div>
              <div className="track-stock-request-details-card-item-section">
                {!isItemClosed ? (
                  selectedTrackStockItemDetails?.itemDetails.map(
                    (data, index) => {
                      return (
                        <AdminTrackStockRequestDetailsCard
                          data={data}
                          index={index + 1}
                          key={`approveCard${index + data?.sapMaterialCode}`}
                        />
                      );
                    }
                  )
                ) : (
                  <TrackStockRequestItemContent
                    selectedTrackStockItemDetails={
                      selectedTrackStockItemDetails?.itemDetails
                    }
                  />
                )}
              </div>
            </div>
            <div
              className="track-stock-request-details-inner-item-section track-stock-request-details-inner-comment-item-section"
              hidden={selectedTrackStockItemDetails?.approverComments}
            >
              <div className="track-stock-request-details-item-section">
                <span>*</span>
                {Constent.COMMENTS}
              </div>
              <div className="track-stock-request-details-item-section">
                <div className="form-group m-0">
                  <textarea
                    className="form-control"
                    id="approverCommentTextArea"
                    placeholder=" "
                    value={trackStockDetailsApproverComment}
                    onChange={(e) => {
                      dispatch(
                        setTrackStockFieldValue({
                          type: ApiConstant.TRACK_STOCK_APPROVER_COMMENT,
                          value: e.target.value,
                        })
                      );
                    }}
                    spellCheck="false"
                    readOnly={isItemClosed}
                  ></textarea>
                  <label htmlFor="approverCommentTextArea">
                    {Constent.COMMENT_INPUT_ITEM_LABEL}
                  </label>
                </div>
              </div>
            </div>

            {/* display Approver's comment when exist */}
            {selectedTrackStockItemDetails?.approverComments ? (
              <div className="track-stock-request-details-inner-item-section track-stock-request-details-inner-comment-item-section">
                <div className="track-stock-request-details-item-section">
                  {Constent.COMMENTS}
                </div>
                <div className="track-stock-request-details-item-section">
                  <div className="form-group m-0">
                    <textarea
                      className="form-control"
                      id="approverCommentTextArea"
                      disabled
                      placeholder=" "
                      value={selectedTrackStockItemDetails?.approverComments}
                      spellCheck="false"
                    ></textarea>
                    <label htmlFor="approverCommentTextArea"></label>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            {isItemClosed ? (
              <div className="track-stock-request-details-inner-item-section"></div>
            ) : (
              <div className="track-stock-request-details-inner-item-section">
                <div className="track-stock-request-details-item-section">
                  <div
                    className="refresh-btn-section"
                    data-toggle="modal"
                    data-target="#requestStockPlaceOrderModal"
                    onClick={() => {
                      setPageState({ ...pageState, isRejected: true });
                    }}
                  >
                    {Constent.REJECT_BTN}
                  </div>
                  <div
                    className="okay-btn-section"
                    data-toggle="modal"
                    data-target="#requestStockPlaceOrderModal"
                    onClick={() => {
                      setPageState({ ...pageState, isRejected: false });
                    }}
                  >
                    {Constent.APPROVE_BTN}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="track-stock-request-details-content-item-section"></div>
      </div>
    </div>
  );
};

export default AdminTrackStockRequestDetailsBodyContent;
