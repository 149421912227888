import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loginUserName: "",
    azToken: ""
}

export const B2CDataState = createSlice({
    name: 'b2cDataState',
    initialState,
    reducers: {
        setLoginUserName: (state, action) => {
            return { ...state, loginUserName: action.payload }
        },
        setLoginUserToken: (state, action) => {
            return { ...state, azToken: action.payload }
        },
        /* ---- Reset Data ---- */
        resetB2CData: () => initialState
    }
})

export const { setLoginUserName, setLoginUserToken, resetB2CData } = B2CDataState.actions;

export default B2CDataState.reducer;