import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../../Components/Footer/Footer';
import { Header } from '../../Components/Header/Header'

import '../../Assets/CSS/InstallerDashbordPage.css'
import InstallerDashbordBodyHeader from '../../Components/MainBody/BodyHeader/Installer/InstallerDashbordBodyHeader';
import InstallerDashbordBodyContent from '../../Components/MainBody/BodyContent/Installer/InstallerDashbordBodyContent';
import InstallerDashbordBodySidebar from '../../Components/MainBody/BodyContent/Installer/DashBordHalper/InstallerDashbordBodySidebar';
import { Constent } from '../../Assets/Constants/Constent';
import { headers, InstallerSummary, WorkOrderPage } from '../../Api/ApiCalls';
import moment from 'moment';
import { ApiConstant } from '../../Assets/Constants/ApiConstants';
import { ToastContainer, toast } from 'react-toastify';
import { setLoginUserName, setLoginUserToken } from '../../ReduxFeature/StateFeatures/B2CDataState';


export const InstallerDashbordPage = ({ globalState, setGlobalState }) => {
  const userDataObj = useSelector((state) => state.userDataInfo);
  const workOrderDataObj = useSelector((state) => state.workOrderInfo);
  const dispatch = useDispatch();

  const [pageState, setPageState] = useState({
    activeSidebarNav: Constent.SUPERVISOR_SIDEBAR_SUBTYPE1,
    dataLoader: false,
    installerSidedarData: null,
    installerCalendatData: null,
    summaryDataError: false,
    summaryDataErrorMessage: ""
  });
  const [installerCalendatData, setInstallerCalendatData] = useState(null);

  const { summaryDate, calendarDate } = workOrderDataObj;

  function ErrorMessageSetUp({ error, message1, message2 }) {
    let errorMessage;
    if (error.response.status === 500 || error.response.status === 503) {
      errorMessage = message1;
      toast.error(errorMessage,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 10000,
          theme: 'colored'
        }
      );
    }
    else if (error.response.status === 404) {
      errorMessage = message2;
      toast.warning(errorMessage,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 10000,
          theme: 'colored'
        }
      );
    }
    else {
      errorMessage = error?.response?.data?.message;
      toast.error(errorMessage,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 10000,
          theme: 'colored'
        }
      );
    }
    return errorMessage;
  }

  function InstallerDashbordApiCall() {
    setPageState({ ...pageState, dataLoader: true, installerSidedarData: null });

    /*-------- Summary and Calendar Api call-----------*/
    //  Header
    const header = headers(
      userDataObj?.userData?.activeUserData?.authToken
    );
    const sidebarRequest = {
      monthYear: moment(summaryDate).format("YYYY-MM") + "-01",
      userId: userDataObj?.userData?.activeUserData?.userData?.userId,
      limit: "500",
      offSet: "0"
    }

    InstallerSummary("/fibre/activity/summary/installer", sidebarRequest, header).then(response => {
      setPageState({
        ...pageState, installerSidedarData: response, dataLoader: false,
        summaryDataError: false, summaryDataErrorMessage: "",
        activeSidebarNav: Constent.SUPERVISOR_SIDEBAR_SUBTYPE1,
      });
    }).catch(error => {
      const errorMsg = ErrorMessageSetUp({
        error: error,
        message1: ApiConstant.INSTALLER_CALENDAR_ERROR_MESSAGE,
        message2: ApiConstant.INSTALLER_SIDEBAR_WARNING_MESSAGE
      });
      setPageState({
        ...pageState, dataLoader: false, summaryDataError: true,
        installerSidedarData: null, summaryDataErrorMessage: errorMsg
      });
    })
  }

  function DashbordCalendarApiCall() {
    setInstallerCalendatData(null)
    //  Header
    const header = headers(
      userDataObj?.userData?.activeUserData?.authToken
    );
    const calendarRequest = {
      fromDate: moment(calendarDate).format("YYYY-MM-DD") + " 00:00",
      toDate: moment(calendarDate).format("YYYY-MM-DD") + " 23:59",
      userId: userDataObj?.userData?.activeUserData?.userData?.userId,
      limit: "500",
      offSet: "0"
    }
    WorkOrderPage("/fibre/activity/installerlist", calendarRequest, header).then(response => {
      setInstallerCalendatData({...installerCalendatData, workQueueVo: response?.workQueueVo})
      // setPageState({ ...pageState, installerCalendatData: { ...pageState.installerCalendatData, workQueueVo: response?.workQueueVo } });
    }).catch(error => {
      setInstallerCalendatData(null)
      ErrorMessageSetUp({
        error: error,
        message1: ApiConstant.INSTALLER_CALENDAR_ERROR_MESSAGE,
        message2: ApiConstant.INSTALLER_CALENDAR_WARNING_MESSAGE
      });
    })
  }

  useEffect(() => {
    dispatch(setLoginUserName(""));
    dispatch(setLoginUserToken(""));
    InstallerDashbordApiCall();
  }, [summaryDate]);

  useEffect(() => {
    DashbordCalendarApiCall();
  }, [calendarDate]);

  return (
    <div className='page-start-section'>
      {/* Header - common for all the users */}
      <Header userDataObj={userDataObj} globalState={globalState} setGlobalState={setGlobalState}
        installerTabFlag={true} />
      {/* Body */}
      <div className="body-start-section">
        <div className="body-full-content-section installer-body-full-content-section">
          <div className="installer-body-content-section">
            <InstallerDashbordBodyHeader userDataObj={userDataObj} />
            <InstallerDashbordBodyContent pageState={pageState} workOrderDataObj={workOrderDataObj} installerCalendatData={installerCalendatData}/>
          </div>
          {/* Sidebar */}
          <div className="installer-body-content-section">
            <InstallerDashbordBodySidebar pageState={pageState} setPageState={setPageState} summaryDate={summaryDate} />
          </div>
        </div>
        {/* Footer */}
        <Footer />
      </div>
      <ToastContainer />
    </div>
  )
}
