import React from "react";
import { useNavigate } from "react-router-dom";
import { headers, TransferStock } from "../../Api/ApiCalls";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { Constent } from "../../Assets/Constants/Constent";
import { toast } from "react-toastify";
import {
  CloseModalIcon,
  ConfirmationIcon,
} from "../../Assets/Images/CelcomSvgImages";
import { RequestPlaceOrderTable } from "../Common/Table";
import {
  removeTransferStockData,
  removeSelectedTransferStockData,
  setConfirmationPageData,
} from "../../ReduxFeature/StateFeatures/CartDataState";
import { useDispatch } from "react-redux";

const TransferStockPlaceOrderModal = ({
  selectedTransferStockList,
  userDataObj,
  transferSerialNumberObj,
  pageState,
  storeTransferValue,
  setPageState,
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  function transferStockApiCall() {
    setPageState({ ...pageState, apiCallAction: true });
    //  Header
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);

    const listOfItemDetailsRequest = [];
    selectedTransferStockList?.map((selectedItem) => {
      listOfItemDetailsRequest.push({
        sapMaterialCode: selectedItem?.sAPMaterialCode,
        transferQuantity: selectedItem?.reqQuantity,
      });
    });

    const request = {
      transferFromStoreId:
        userDataObj?.userData?.activeUserData?.userData?.outletId,
      transferToStoreId: storeTransferValue,
      createdBy: userDataObj?.userData?.activeUserData?.userData?.userId,
      comments: "Create Request From SAP",
      towId: null,
      requestType: "TransferForHSBB",
      approverComments: null,
      listOfItemDetailsRequest: listOfItemDetailsRequest,
    };
    TransferStock(
      "/fibre/inventory/createStockTransferRequest",
      request,
      header
    )
      .then((response) => {
        setPageState({ ...pageState, apiCallAction: false });
        if (response?.transferId?.length > 0) {
          dispatch(
            setConfirmationPageData({
              comment: Constent.CART_TRANSFER_STOCK_SUCESSFULL_MSG,
              listOfIds: [response?.transferId],
            })
          );
          selectedTransferStockList?.map((itemData) => {
            dispatch(removeSelectedTransferStockData(itemData));
            dispatch(removeTransferStockData(itemData));
          }) && navigate("/cart/confirm");
        }
      })
      .catch((error) => {
        setPageState({ ...pageState, apiCallAction: false });
        let errorMessage;
        if (error.response.status === 500 || error.response.status === 503) {
          errorMessage = ApiConstant.SUBMIT_TRANSFER_STOCK_ERROR_MESSAGE;
          toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        } else {
          errorMessage = error.response.statusText;
          toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
            theme: "colored",
          });
        }
      });
  }



  return (
    <div
      className="modal fade request-stock-place-order-modal"
      id="transferStockPlaceOrderModal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby="transferStockPlaceOrderModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pb-0">
            <div className="wrapper-modal-header pt-5">
              <div className="modal-header-title-section">
                <ConfirmationIcon />
                <h5
                  className="modal-title"
                  id="TransferStockPlaceOrderModalLabel"
                >
                  {Constent.CONFIRMATION_MODAL_TITLE}
                </h5>
                <h5 className="modal-title">
                  {Constent.TRANSFER_STOCK_PLACE_ORDER_MODAL_TITLE}
                </h5>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <CloseModalIcon />
                </span>
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="wrapper-modal-body">
              <RequestPlaceOrderTable
                title1={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE1}
                title2={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE2}
                title3={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE3}
                title4={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE4}
                title5={Constent.INVENTORY_CARD_MANAGE_STOCK_ITEM_TITLE8}
                data={selectedTransferStockList}
              />
            </div>
          </div>
          <div className="modal-footer">
            <div className="refresh-btn-section" data-dismiss="modal">
              <span>{Constent.EDIT_QUANTITY_BTN}</span>
            </div>
            <div
              className="search-btn-section"
              data-dismiss="modal"
              onClick={() => {
                storeTransferValue === ""
                  ? toast.error("Store To Transfer To Field is Mandatory", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      autoClose: 10000,
                      theme: "colored",
                    })
                  : transferStockApiCall();
              }}
            >
              {Constent.CONFIRMATION_BTN}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferStockPlaceOrderModal;
