import React, { useState } from 'react';
import { ApiConstant } from '../../../Assets/Constants/ApiConstants';
import { Constent } from '../../../Assets/Constants/Constent';
import { FileUploadIcon } from '../../../Assets/Images/CelcomSvgImages';
import { toast } from 'react-toastify';

const FileUploadModalDragAndDropSection = ({ pageState, setPageState, attachUploadedDocument }) => {
    const [fileDragedOver, setFileDragedOver] = useState(false);

    return (
        <div className="wrapper-modal-body-section">
            <div className="upload-document-modal-body-group-section">
                {Constent.UPLOAD_DOCUMENT_MODAL_BODY_TITLE1}
                
            </div>
            <div className="upload-document-modal-body-group-section">
                <div className="upload-document-modal-body-item-group-section">
                    <div className="form-group m-0 custom-drop-down-form-group">
                        <select className="custom-select" value={pageState?.documentTypeLovValue} onChange={(e) => {
                            /*---- 
                            Either all the file should uploade then drop down value will change 
                            or No document is added
                            ----*/
                            if (pageState?.documentTypeLovValue !== e.target.value) {
                                const index = pageState?.uploadedFileList?.findIndex(obj => obj?.uploadStatus === ApiConstant.UPLOAD_DOCUMENT_ITEM_NOT_COMPLETE_STATUS);
                                if (index === -1 || pageState?.documentTypeLovValue?.length === 0) {
                                    setPageState({ ...pageState, documentTypeLovValue: e.target.value });
                                } else {
                                    toast.warning(Constent.UPLOAD_DOCUMENT_MODAL_BODY_WARNING_MSG1 + '" ' + pageState?.documentTypeLovValue + ' "' +
                                        Constent.UPLOAD_DOCUMENT_MODAL_BODY_WARNING_MSG2,
                                        {
                                            position: toast.POSITION.BOTTOM_RIGHT,
                                            autoClose: 10000,
                                            theme: 'colored'
                                        }
                                    );
                                }
                            }
                        }}>
                            <option defaultValue="select" value="select">--Select--</option>
                            {
                                ApiConstant.UPLOAD_DOCUMENT_TYPE_LOVS?.map((data, index) => {
                                    return <option key={`${Constent.UPLOAD_DOCUMENT_TYPE_INPUT_ITEM_LABEL + index}`}
                                        value={`${data?.value}`}>
                                        {data?.display}
                                    </option>
                                })
                            }
                        </select>
                        <label htmlFor={Constent.UPLOAD_DOCUMENT_TYPE_INPUT_ITEM_LABEL}>
                            {Constent.UPLOAD_DOCUMENT_TYPE_INPUT_ITEM_LABEL}<span>*</span>
                        </label>
                    </div>
                </div>
                <div className="upload-document-modal-body-item-group-section">
                    <div
                        className={`upload-document-modal-body-item-group ${fileDragedOver ? "draged-upload-document-modal-body-item-group" : ""}`}
                        onDragOver={() => {
                            setFileDragedOver(true);
                        }}
                        onDragEnd={() => { setFileDragedOver(false) }} onDragLeave={() => { setFileDragedOver(false) }}>
                        <div className="upload-document-modal-body-item">
                            <div className="upload-document-modal-body-inner-item-section">
                                <FileUploadIcon />
                            </div>
                            <div className="upload-document-modal-body-inner-item-section">
                                <div className="upload-document-modal-body-inner-item">
                                    {
                                        !fileDragedOver ?
                                            Constent.UPLOAD_DOCUMENT_MODAL_BODY_TEXT_MSG1 :
                                            Constent.UPLOAD_DOCUMENT_MODAL_BODY_TEXT_MSG3
                                    }
                                </div>
                                <div className="upload-document-modal-body-inner-item">
                                    {!fileDragedOver && Constent.UPLOAD_DOCUMENT_MODAL_BODY_TEXT_MSG2}
                                </div>
                            </div>
                            <div className="upload-document-modal-body-inner-item-section">
                                <div className="browse-file-btn-section">{Constent.BROWSE_FILE_BTN}</div>
                            </div>
                        </div>
                        <input type="file" accept=".pdf, .jpg, .jpeg, .png, .mp4, .mov, .mkv, .doc, .docx, .xlsx" name="uploadDocumentBtn" value=""
                            id="uploadDocumentBtn" title="" onChange={attachUploadedDocument}  />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FileUploadModalDragAndDropSection;