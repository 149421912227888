import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ApiConstant } from '../../../../../Assets/Constants/ApiConstants';
import { Constent } from '../../../../../Assets/Constants/Constent';

const InstallerUpdateWorkOrderBodyContentHeader = ({ pageState }) => {
    const workOrderDataObj = useSelector((state) => state.workOrderInfo);

    const navigate = useNavigate();
    const { workOrderStatus, activeTab, workOrderDetails } = workOrderDataObj;
    return (
        <div className="update-work-order-content-item-group-section">
            <div className="update-work-order-content-item-group">
                <div className="back-btn-section" onClick={() => navigate("/workorder")}>{Constent.BACK_BTN}</div>
            </div>
            <div className="update-work-order-content-item-group">
                <div className="wrapper-update-work-order-content-item-group"></div>
                <div className="wrapper-update-work-order-content-item-group">
                    <div className="update-work-order-content-item">
                        {Constent.BODY_HEADER_UPDATE_WORKORDER_TEXT} <span>{workOrderDetails?.itemID?.replaceAll("FieldActivity-", "") || "-"}</span>
                    </div>
                    {
                        workOrderStatus !== ApiConstant.WOKR_ORDER_RETURNED &&
                        <div className={`update-work-order-content-item ${(activeTab === 1 && "active-step-1") || (activeTab === 2 && "active-step-2")}`}>
                            <ul>
                                <li>
                                    <div className="update-work-order-content-header-item-value">1</div>
                                    <div className="update-work-order-content-header-item-title">
                                        {Constent.BODY_HEADER_UPDATE_WORKORDER_STEP_TEXT1}
                                    </div>
                                </li>
                                <li>
                                    <div className="update-work-order-content-header-item-value">2</div>
                                    <div className="update-work-order-content-header-item-title">
                                        {Constent.BODY_HEADER_UPDATE_WORKORDER_STEP_TEXT2}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    }
                </div>
                <div className="wrapper-update-work-order-content-item-group"></div>
            </div>
            <div className="update-work-order-content-item-group"></div>
        </div>
    )
}

export default InstallerUpdateWorkOrderBodyContentHeader;