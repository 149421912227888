import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Constent } from '../../../../../Assets/Constants/Constent';
import { ConfirmTickIcon, CopyIcon, AfterCopyIcon, DownloadIcon } from '../../../../../Assets/Images/CelcomSvgImages';
import { ConfirmationBackBtn } from '../../../../Common/Button';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { OtherImages } from '../../../../../Assets/Images/CelcomOtherImages';

const PlaceOrderConfirmationBodyContent = ({ globalState, setGlobalState }) => {
    const userDataObj = useSelector((state) => state.userDataInfo);
    const cartDataObj = useSelector((state) => state.cartDataInfo);

    const { confirmationPageTransferId, confirmationPageMessage } = cartDataObj;

    const navigate = useNavigate();

    const [downloadBtnState, setDownloadBtnState] = useState(false);
    const [copyState, setCopyState] = useState(false);
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            setDownloadBtnState(false);
        }
    });

    const downloadPdfFun = () => {
        setDownloadBtnState(true);
    }

    useEffect(() => {
        if (downloadBtnState) {
            handlePrint();
        }
    }, [downloadBtnState]);
    return (
        <div className='body-content-section pt-0' ref={componentRef}>
            <div className="place-order-confirmation-section">
                <div className="place-order-confirmation-item-section"></div>
                <div className="place-order-confirmation-item-section">
                    {
                        downloadBtnState && <img src={OtherImages.CelcomLogo} style={{ right: 24, top: 20 }} className="position-absolute" />
                    }
                    {
                        !downloadBtnState && <div className="download-btn-section" onClick={downloadPdfFun}>
                            <span>{Constent.DOWNLOAD_BTN}</span>
                            <DownloadIcon />
                        </div>
                    }
                    <div className={`place-order-confirmation-content-section ${downloadBtnState ? "pt-6" : ""}`}>
                        <ConfirmTickIcon />
                        <div className="wrapper-place-order-confirmation-content-section">
                            <div className="confirmation-content-item mt-3">
                                {`${Constent.BODY_HEADER_DASHBORD_BANNAR_SUB_TEXT} ${userDataObj?.userData?.activeUserData?.userData?.firstName || "-"}`}
                            </div>
                            <div className="confirmation-content-item mt-1">
                                {confirmationPageMessage}
                            </div>
                            <div className="confirmation-content-item mt-1 mb-4">
                                {`${Constent.CART_CONFIRMARION_CONTENT_TITLE1}`}
                                <div className="confirmation-content-item-id-section mt-2">
                                    {
                                        confirmationPageTransferId.map(singleId => {
                                            return (
                                                <div className="confirmation-content-id-item" key={`singleId${singleId}`}>
                                                    <span
                                                        onClick={() => {
                                                            setGlobalState({ ...globalState, inventoryActivePill: Constent.BODY_HEADER_INVENTORY_PILL_ITEM2 });
                                                            navigate("/inventory");
                                                        }}>
                                                        {singleId}
                                                    </span>
                                                    &nbsp;&nbsp;
                                                    <span role="button" onClick={() => (navigator.clipboard.writeText(singleId), setCopyState(true))}> 
                                                        {copyState? <AfterCopyIcon/>:<CopyIcon />}
                                                        <span className="confirmation-content-item mt-1 mb-4"></span> 
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            !downloadBtnState && <ConfirmationBackBtn btnItemName={Constent.BODY_HEADER_CART_PATH} btnRoute={"/cart"} />
                        }
                    </div>
                </div>
                <div className="place-order-confirmation-item-section"></div>
            </div>
        </div>
    )
}

export default PlaceOrderConfirmationBodyContent;