import React, { useState, useEffect, useRef } from "react";
import "../../Assets/CSS/Header.css";
import {
  CartIcon,
  DropDownIcon,
  FileSearchIcon,
  ManageInstallerIcon,
  ManageInventoryIcon,
  MenuIcon,
  ProfileIcon,
  SearchIcon,
  SwitchUserIcon,
  TrackOrderIcon,
} from "../../Assets/Images/CelcomSvgImages";
import { OtherImages } from "../../Assets/Images/CelcomOtherImages";
import { Constent } from "../../Assets/Constants/Constent";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeaderSideNavMenu from "./HeaderSideNavMenu";

import SwitchUserModal from "../Modals/SwitchUserModal";
import LogoutModal from "../Modals/LogoutModal";
import IdleTimerContainer from "../Common/IdleTimerContainer";

export const Header = ({
  globalState,
  setGlobalState,
  userDataObj,
  tab0,
  tab1,
  tab2,
  tab3,
  tab4,
  tab5,
  tab6,
  tab7,
  installerTabFlag,
}) => {
  const cartPageState = useSelector((state) => state?.cartDataInfo);
  const [profileTab, setProfileTab] = useState(false);
  const profileTabRef = useRef();
  const navigate = useNavigate();

  const activeUserData = userDataObj?.userData?.activeUserData;

  const changeActiveTab =
    (userDataObj?.userData?.loginUserData?.userRole === Constent.ADMIN_ROLE &&
      userDataObj?.userData?.activeUserData?.userRole ===
        Constent.SUPER_ROLE) ||
    userDataObj?.userData?.loginUserData?.userData?.userId !==
      userDataObj?.userData?.activeUserData?.userData?.userId;

  // console.log(changeActiveTab, "the address field")
  const data = activeUserData?.userData?.address.split(",");

  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      try {
        if (!profileTabRef.current.contains(event.target)) {
          setProfileTab(false);
        }
      } catch {}
    });
  }, []);

  return (
    <>
      <div className="header-start-section fixed-top">
        <div
          className={
            userDataObj?.userData?.loginUserData?.userRole === "ADMIN"
              ? userDataObj?.userData?.loginUserData?.userRole ===
                  Constent.ADMIN_ROLE &&
                userDataObj?.userData?.activeUserData?.userRole ===
                  Constent.SUPER_ROLE
                ? "flex-nowrap header-wraper-section header-wraper-admin-section header-wrapper-admin-super-section"
                : "flex-nowrap header-wraper-section header-wraper-admin-section"
              : userDataObj?.userData?.loginUserData?.userRole === "SUPER"
              ? "flex-nowrap header-wraper-section header-wraper-super-section"
              : "flex-nowrap header-wraper-section"
          }
        >
          {/* Mobile view manu toggle Btn */}
          <div
            className="navbar-mobile-menu-group"
            onClick={() => {
              setGlobalState({
                ...globalState,
                sideMenuOpenState: !globalState?.sideMenuOpenState,
              });
            }}
          >
            <MenuIcon stroke={"#009BDF"} />
          </div>
          <div className="navbar-header-icon-section">
            <img
              src={OtherImages.CelcomLogo}
              alt=""
              onClick={() => {
                navigate("/dashboard");
              }}
            />
          </div>
          <div
            className={
              userDataObj?.userData?.loginUserData?.userRole ===
                Constent.ADMIN_ROLE ||
              (userDataObj?.userData?.activeUserData?.userRole ===
                Constent.SUPER_ROLE &&
                userDataObj?.userData?.loginUserData?.userData?.userId !==
                  userDataObj?.userData?.activeUserData?.userData?.userId)
                ? (userDataObj?.userData?.loginUserData?.userRole ===
                    Constent.ADMIN_ROLE &&
                    userDataObj?.userData?.activeUserData?.userRole ===
                      Constent.SUPER_ROLE) ||
                  (userDataObj?.userData?.activeUserData?.userRole ===
                    Constent.SUPER_ROLE &&
                    userDataObj?.userData?.loginUserData?.userData?.userId !==
                      userDataObj?.userData?.activeUserData?.userData?.userId)
                  ? "navbar-header-content-section admin-super-navbar-header-content-section"
                  : "navbar-header-content-section admin-navbar-header-content-section"
                : "navbar-header-content-section"
            }
          >
            {userDataObj?.userData?.activeUserData?.userRole ===
            Constent.INSTALLER_ROLE ? (
              <div className="navbar-header-search-btn-section">
                {installerTabFlag && (
                  <div
                    className="wrapper-installer-header-nav-btn"
                    onClick={() => {
                      navigate("/workorder");
                    }}
                  >
                    <SearchIcon />
                    {Constent.HEADER_MENU_ITEM1}
                  </div>
                )}
              </div>
            ) : (
              <div
                className={
                  userDataObj?.userData?.activeUserData?.userRole ===
                  Constent.ADMIN_ROLE
                    ? "navbar-header-nav-menu-section super-admin-nav-items"
                    : "navbar-header-nav-menu-section"
                }
              >
                <div
                  className={
                    tab1
                      ? "navbar-header-menu-item active-tab"
                      : "navbar-header-menu-item"
                  }
                  onClick={() => {
                    navigate("/workorder");
                  }}
                >
                  <div className="menu-item-icon">
                    <FileSearchIcon />
                  </div>
                  <div className="menu-item-text">
                    {Constent.HEADER_MENU_ITEM1}
                  </div>
                </div>
                {userDataObj?.userData?.activeUserData?.userRole ===
                  Constent.SUPER_ROLE && (
                  <>
                    <div
                      className={
                        tab2
                          ? "navbar-header-menu-item active-tab"
                          : "navbar-header-menu-item"
                      }
                      onClick={() => {
                        navigate("/trackorder");
                      }}
                    >
                      <div className="menu-item-icon">
                        <TrackOrderIcon />
                      </div>
                      <div className="menu-item-text">
                        {Constent.HEADER_MENU_ITEM2}
                      </div>
                    </div>
                    <div
                      className={
                        tab3
                          ? "navbar-header-menu-item active-tab"
                          : "navbar-header-menu-item"
                      }
                      onClick={() => {
                        navigate("/manageinstaller");
                      }}
                    >
                      <div className="menu-item-icon">
                        <ManageInstallerIcon />
                      </div>
                      <div className="menu-item-text">
                        {Constent.HEADER_MENU_ITEM3}
                      </div>
                    </div>
                  </>
                )}

                <div
                  className={
                    tab4
                      ? "navbar-header-menu-item active-tab"
                      : "navbar-header-menu-item"
                  }
                  onClick={() => {
                    navigate("/inventory");
                  }}
                >
                  <div className="menu-item-icon">
                    <ManageInventoryIcon />
                  </div>
                  <div className="menu-item-text">
                    {Constent.HEADER_MENU_ITEM4}
                  </div>
                </div>
                {userDataObj?.userData?.activeUserData?.userRole ===
                  Constent.SUPER_ROLE && (
                  <div
                    className={
                      tab5
                        ? "navbar-header-menu-item cart-tab cart-active active-tab"
                        : "navbar-header-menu-item cart-tab"
                    }
                    onClick={() => {
                      navigate("/cart");
                    }}
                  >
                    <div className="wrapper-navbar-header-menu-item">
                      <CartIcon />
                      {cartPageState?.requestStockList?.length +
                        cartPageState?.returnStockList?.length +
                        cartPageState?.transferStockList?.length >
                        0 && (
                        <div className="navbar-header-menu-notification">
                          {cartPageState?.requestStockList?.length +
                            cartPageState?.returnStockList?.length +
                            cartPageState?.transferStockList?.length}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {userDataObj?.userData?.activeUserData?.userRole ===
                  Constent.ADMIN_ROLE && (
                  <div
                    className={
                      tab7
                        ? "navbar-header-menu-item active-tab"
                        : "navbar-header-menu-item"
                    }
                    onClick={() => {
                      navigate("/uploadnotes");
                    }}
                  >
                    <div className="menu-item-icon">
                      <FileSearchIcon />
                    </div>
                      <div className="menu-item-text">
                        {Constent.HEADER_MENU_ITEM6}
                      </div>
                  </div>
                )}
                {(userDataObj?.userData?.loginUserData?.userRole ===
                  Constent.ADMIN_ROLE ||
                  userDataObj?.userData?.loginUserData?.userRole ===
                    Constent.SUPER_ROLE) && (
                  <div
                    className="navbar-header-menu-item active-tab"
                    onClick={() => {
                      (userDataObj?.userData?.activeUserData?.userRole ===
                        Constent.ADMIN_ROLE ||
                        (userDataObj?.userData?.loginUserData?.userRole ===
                          Constent.SUPER_ROLE &&
                          userDataObj?.userData?.loginUserData?.userData
                            ?.userId ===
                            userDataObj?.userData?.activeUserData?.userData
                              ?.userId)) &&
                        navigate("/switchuser");
                    }}
                    data-toggle={changeActiveTab && "modal"}
                    data-target={changeActiveTab && "#switchUserModal"}
                  >
                    <div
                      className={
                        userDataObj?.userData?.loginUserData?.userRole ===
                        userDataObj?.userData?.activeUserData?.userRole
                          ? tab6
                            ? "switch-user-btn-section activetab-switch-user-btn"
                            : "switch-user-btn-section"
                          : "switch-user-btn-section"
                      }
                    >
                      <div className="switch-user-btn-icon">
                        <SwitchUserIcon fill={"#FFFFFF"} />
                      </div>
                      <div className="switch-user-btn-text">
                        {`${Constent.HEADER_MENU_ITEM5} ` +
                          (userDataObj?.switchUserBtn
                            ? `${Constent.HEADER_MENU_ITEM5_SUB2}`
                            : `${Constent.HEADER_MENU_ITEM5_SUB1}`)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div
              className={
                profileTab
                  ? "navbar-header-profile-section navbar-header-profile-section-active"
                  : "navbar-header-profile-section"
              }
              ref={profileTabRef}
            >
              <div
                className="wrapper-navbar-header-profile-section"
                onClick={() => {
                  setProfileTab(!profileTab);
                }}
              >
                <div className="navbar-profile-img">
                  <ProfileIcon />
                </div>
                <div className="navbar-profile-content-section">
                  <div className="profile-username">
                    {activeUserData?.userData?.fullName || "-"}
                  </div>
                  <div className="profile-id">
                    {activeUserData?.userData?.userId || "-"}
                  </div>
                </div>
                <DropDownIcon />
              </div>
              <div className="navbar-header-profile-details-section">
                <div className="wrapper-header-profile-details-section">
                  <div className="profile-details-item-group-section">
                    <div className="profile-details-item">
                      {Constent.PROFILE_INFO_PERSONAL_DETAILS}
                    </div>
                    <div className="profile-details-item">
                      <div
                        className="logout-btn-section"
                        data-toggle="modal"
                        data-target="#logoutModal"
                      >
                        {Constent.LOGOUT_BTN}
                      </div>
                    </div>
                  </div>
                  <div className="profile-details-item-group-section">
                    <div className="profile-details-item">
                      <div className="profile-details-item-title">
                        {Constent.PROFILE_INFO_USER_NAME}
                      </div>
                      <div className="profile-details-item-value">
                        {activeUserData?.userData?.fullName || "-"}
                      </div>
                    </div>
                    <div className="profile-details-item">
                      <div className="profile-details-item-title">
                        {Constent.PROFILE_INFO_USERID}
                      </div>
                      <div className="profile-details-item-value">
                        {activeUserData?.userData?.userId || "-"}
                      </div>
                    </div>
                    <div className="profile-details-item">
                      <div className="profile-details-item-title">
                        {Constent.PROFILE_INFO_OUTLET}
                      </div>
                      <div className="profile-details-item-value">
                        {activeUserData?.userData?.outletId || "-"}
                      </div>
                    </div>
                    <div className="profile-details-item">
                      <div className="profile-details-item-title">
                        {Constent.PROFILE_INFO_ADDRESS}
                      </div>
                      <div className="profile-details-item-value">
                        {data[2] + " " + data[0] + " " + data[1] || "-"}
                      </div>
                    </div>
                    <div className="profile-details-item">
                      <div className="profile-details-item-title">
                        {Constent.PROFILE_INFO_CONTACT}
                      </div>
                      <div className="profile-details-item-value">
                        {activeUserData?.userData?.contactNumber || "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HeaderSideNavMenu
        globalState={globalState}
        setGlobalState={setGlobalState}
        activeUserData={activeUserData}
        userDataObj={userDataObj}
        tab0={tab0}
        tab1={tab1}
        tab2={tab2}
        tab3={tab3}
        tab4={tab4}
        tab7={tab7}
      />
      {changeActiveTab && <SwitchUserModal switchBack={changeActiveTab} />}
      <LogoutModal
        showCartPart={
          changeActiveTab ||
          userDataObj?.userData?.loginUserData?.userRole === Constent.SUPER_ROLE
        }
      />
      <IdleTimerContainer
        userData={userDataObj?.userData}
        showCartPart={changeActiveTab}
      />
    </>
  );
};
